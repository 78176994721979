import { Button } from '@chakra-ui/react'
import React from 'react'
import './btn.css'

function BtnModal({ label, ...rest }) {
  return (
    <Button className='btn' {...rest}>
      {label}
    </Button>
  )
}

export { BtnModal }
