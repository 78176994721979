import { db } from 'Config/firebase'
import { useAppContext } from 'Context'
import {
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  query,
  orderBy,
  limit,
  where,
  updateDoc,
} from 'firebase/firestore'
import { useCallback, useState } from 'react'
import _ from 'underscore'

export function usePurchaseOrder() {
  const { setError } = useAppContext()
  const purchaseOrderRef = collection(db, 'PurchaseOrder')
  const [PurchaseOrder, setPurchaseOrder] = useState([])
  const [PurchaseOrderById, setPurchaseOrderById] = useState(null)

  const [isLoading, setIsLoading] = useState(true)

  const getAllPurchaseOrders = useCallback(async (branchId) => {
    setIsLoading(true)
    let queryAll = query(purchaseOrderRef)
    if (branchId) {
      queryAll = query(purchaseOrderRef, where('branch.id', '==', branchId))
    }
    const getData = await getDocs(queryAll)
    const data = await _.map(getData?.docs, (doc) => {
      return {
        id: doc?.id,
        ...doc?.data(),
      }
    })
      .sort((a, b) => b.id.localeCompare(a.id))
      .filter((doc) => doc.orderStatus !== 'canceled')
    setPurchaseOrder(data)
    setIsLoading(false)
    //eslint-disable-next-line
  }, [])

  const getPurchaseOrderById = useCallback(async (id) => {
    setIsLoading(true)
    const queryRef = doc(db, 'PurchaseOrder', id)
    const getData = await getDoc(queryRef)
    setPurchaseOrderById({ id: getData.id, ...getData.data() })
    setIsLoading(false)
    //eslint-disable-next-line
  }, [])

  const createPurchaseOrder = async (data) => {
    try {
      const qureyLastest = query(
        purchaseOrderRef,
        orderBy('createdAt', 'desc'),
        limit(1)
      )
      const orderSnapshotCount = await (
        await getDocs(qureyLastest)
      ).docs.map((doc) => doc.id)

      const number =
        orderSnapshotCount.length > 0
          ? orderSnapshotCount[0].slice(2)
          : orderSnapshotCount

      const id =
        'PO' + String(Number(number) ? Number(number) + 1 : 1).padStart(6, '0')

      return await setDoc(doc(db, 'PurchaseOrder', id), {
        ...data,
      })
    } catch (error) {
      setError({
        code: 'Create Purchase Order',
        message: error.message,
      })
    }
  }

  const cancelPurchaseOrder = async (id) => {
    try {
      return await updateDoc(doc(db, 'PurchaseOrder', id), {
        orderStatus: 'canceled',
      })
    } catch (error) {
      setError({
        code: 'Cancel Purchase Order',
        message: error.message,
      })
    }
  }

  const updatePurchaseOrder = async (id, data) => {
    try {
      return await updateDoc(doc(db, 'PurchaseOrder', id), {
        ...data,
      })
    } catch (error) {
      setError({
        code: 'Update Purchase Order',
        message: error.message,
      })
    }
  }

  return {
    isLoading,
    PurchaseOrder,
    PurchaseOrderById,
    createPurchaseOrder,
    cancelPurchaseOrder,
    getAllPurchaseOrders,
    getPurchaseOrderById,
    updatePurchaseOrder,
  }
}
