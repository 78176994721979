import { Box, Button, Center, Stack } from '@chakra-ui/react'
import InputField from 'Components/Horizons/fields/InputField'
import { useAppContext } from 'Context'
import {
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from 'firebase/auth'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../../Config/firebase'

function ChangePassword({ user }) {
  const { setError } = useAppContext()
  const [isDisabled, setIsDisabled] = useState(false)
  const Navigate = useNavigate()
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm()

  const changePassword = (value) => {
    setIsDisabled(true)
    signInWithEmailAndPassword(auth, user.email, value.oldPassword)
      .then((res) => {
        if (res.user) {
          updatePassword(res.user, value.confirmPassword)
            .then(() => {
              signOut(auth)
              Navigate('/login')
              setIsDisabled(false)
            })
            .catch((err) => {
              setIsDisabled(false)
              setError({
                code: 'change-password-error',
                message: err.message,
              })
            })
        }
      })
      .catch((err) => {
        setIsDisabled(false)
        setError({
          code: 'change-password-error',
          message: err.message,
        })
      })
  }
  return (
    <Box>
      <Center>เปลี่ยนรหัสผ่าน</Center>
      <form onSubmit={handleSubmit(changePassword)} id={'change-password'}>
        <Stack>
          <Stack>
            <InputField
              control={control}
              errors={errors}
              mb='0px'
              id='oldPassword'
              label='รหัสผ่านเก่า'
              name='oldPassword'
              type='password'
              required={{
                value: true,
                message: 'กรุณากรอกรหัสผ่านเก่า',
              }}
            />

            <InputField
              control={control}
              errors={errors}
              mb='0px'
              id='newPassword'
              label='รหัสผ่านใหม่'
              name='newPassword'
              type='password'
              required={{
                value: true,
                message: 'กรุณากรอกรหัสผ่านใหม่',
              }}
              validate={{
                lenght: (v) =>
                  v.length > 5 || 'รหัสผ่านต้องมีมากกว่า 5 ตัวอักษร',
              }}
            />

            <InputField
              control={control}
              errors={errors}
              mb='0px'
              id='confirmPassword'
              label='รหัสผ่านใหม่อีกครั้ง'
              name='confirmPassword'
              type='password'
              required={{
                value: true,
                message: 'กรุณากรอกรหัสผ่านใหม่อีกครั้ง',
              }}
              validate={{
                match: (v) => v === watch('newPassword') || 'รหัสผ่านไม่ตรงกัน',
                lenght: (v) =>
                  v.length > 5 || 'รหัสผ่านต้องมีมากกว่า 5 ตัวอักษร',
              }}
            />
          </Stack>

          <Stack justifyItems={'end'}>
            <Button
              type='submit'
              colorScheme='btn'
              fontSize='sm'
              borderRadius='16px'
              w={{ base: '128px', md: '148px' }}
              h='46px'
              ms='auto'
              variant='solid'
              loadingText={'Loading...'}
              isLoading={isDisabled}
            >
              เปลี่ยนรหัสผ่าน
            </Button>
          </Stack>
        </Stack>
      </form>
    </Box>
  )
}

export default ChangePassword
