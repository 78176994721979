import {
  Center,
  HStack,
  Flex,
  Box,
  Text,
  Input,
  Button,
  Stack,
  Heading,
  Select,
  Badge,
  // VStack,
} from '@chakra-ui/react'
import { useState } from 'react'
import { CustomTable } from '../components/customTable'
import { SearchIcon } from '@chakra-ui/icons'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { calculateCommission, calculateSales } from './functions/calculate'
import { currency } from 'Utils/currency'
import { QueryStaffSales } from './functions/query'

const accounting = require('accounting')
const _ = require('underscore')

function Price(props) {
  const { total, commission } = props
  return (
    <HStack justifyContent='end' spacing='3em'>
      <Stack display='none'>
        <Box>
          <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
            ยอดขายสุทธิรวม
          </Text>
        </Box>
        <Box>
          <Text fontSize={{ base: '12px', md: '16px' }} color='report.500'>
            {accounting.formatNumber(total, 2)}
          </Text>
        </Box>
      </Stack>
      <Stack>
        <Box>
          <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
            รวมค่าคอมมิชชั่น
          </Text>
        </Box>
        <Box>
          <Text fontSize={{ base: '12px', md: '16px' }} color='report.500'>
            {accounting.formatNumber(commission, 2)}
          </Text>
        </Box>
      </Stack>
    </HStack>
  )
}

function StaffSales({ user }) {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [data, setData] = useState([])
  const [onSearch, setOnSearch] = useState(false)
  const [select, setSelect] = useState('')

  async function onSubmitFilter() {
    const res = await QueryStaffSales({
      select: select,
      startDate: startDate,
      endDate: endDate,
      user: user,
    })
    setData(res)
    setOnSearch(true)
  }

  // const fetchData = useCallback(async () => {
  //   let startDateQ = new Date()
  //   startDateQ.setDate(startDateQ.getDate() - 30)
  //   startDateQ.setHours(0)
  //   startDateQ.setMinutes(0)
  //   startDateQ.setSeconds(0)
  //   startDateQ.setMilliseconds(0)
  //   let endDateQ = new Date()
  //   endDateQ.setDate(endDateQ.getDate() + 30)
  //   endDateQ.setHours(0)
  //   endDateQ.setMinutes(0)
  //   endDateQ.setSeconds(0)
  //   endDateQ.setMilliseconds(0)

  //   const orderQuery = query(
  //     collection(db, 'Orders'),
  //     where('branch.id', '==', user?.branchSelect?.id),
  //     where('createdAt', '>=', startDateQ),
  //     where('createdAt', '<=', endDateQ),
  //     orderBy('createdAt', 'desc')
  //   )
  //   const res = (await getDocs(orderQuery)).docs.map((doc) => ({
  //     id: doc.id,
  //     ...doc.data(),
  //     createdAt: doc
  //       .data()
  //       .createdAt.toDate()
  //       .toLocaleString('th-TH', { hour12: false }),
  //     date: doc.data().createdAt.toDate(),
  //   }))

  //   const allData = res.map((item) => ({
  //     ...item,
  //     total:
  //       item.status === 'refund'
  //         ? (parseFloat(item.total) / 100) * -1
  //         : parseFloat(item.total) / 100,
  //     grandTotal:
  //       item.status === 'refund'
  //         ? (parseFloat(item.grandTotal) / 100) * -1
  //         : parseFloat(item.grandTotal) / 100,
  //     items: item.items.map((itm) => ({
  //       ...itm,
  //       amount: parseFloat(itm.amount) / 100,
  //       cost:
  //         item.status === 'refund'
  //           ? (parseFloat(itm.cost) / 100) * -1
  //           : parseFloat(itm.cost) / 100,
  //       commission:
  //         item.status === 'refund'
  //           ? (parseFloat(itm.commission) / 100) * -1
  //           : parseFloat(itm.commission) / 100,
  //       date: item.date,
  //     })),
  //   }))

  //   const comissionData = allData.map((order) => {
  //     const totalCommissionByItem = order.items
  //       .map((item) => {
  //         let sumComission = item.commission * item.amount
  //         if (item.promotion) {
  //           return sumComission
  //         } else {
  //           return sumComission
  //         }
  //       })
  //       .reduce((memo, sum) => memo + sum, 0)

  //     // const calCommission = order.items.reduce((v, i) => {
  //     //   return i.commissionCalType === 'piece'
  //     //     ? v + parseFloat(i.commission) * parseFloat(i.amount)
  //     //     : i.promotion
  //     //     ? i.typeCommission === 'Percentage'
  //     //       ? v +
  //     //         (((parseFloat(i.price) / 100) * parseFloat(i.amount) -
  //     //           parseFloat(i.promotion?.discount)) *
  //     //           (parseFloat(i.price) / 100 / parseFloat(i.commission))) /
  //     //           100
  //     //       : v + parseFloat(i.commission)
  //     //     : i.typeCommission === 'Percentage'
  //     //     ? v +
  //     //       ((parseFloat(i.price) / 100) *
  //     //         parseFloat(i.amount) *
  //     //         (parseFloat(i.price) / 100 / parseFloat(i.commission))) /
  //     //         100
  //     //     : v + parseFloat(i.commission)
  //     // }, 0)

  //     const promotion = parseFloat(
  //       order.items
  //         .map((item) => {
  //           return item.promotion
  //             ? calPromotionDecodeUnitAmountPerItem(item)
  //             : null
  //         })
  //         .reduce((memo, sum) => memo + sum, 0)
  //     )

  //     return {
  //       employee: order.createdBy.name,
  //       createdAt: order.createdAt,
  //       orderId: order.orderId,
  //       status: order.status,
  //       total: parseFloat(order.total),
  //       promotion: promotion,
  //       discount: decodeUnit(order.discountBalance),
  //       grandTotal: parseFloat(order.grandTotal),
  //       commission: totalCommissionByItem,
  //       date: order.date,
  //     }
  //   })

  //   setData(comissionData)
  // }, [user])

  // useEffect(() => {
  //   fetchData()
  // }, [fetchData])

  const allEmp = _.groupBy(data, 'employee')
  return (
    <>
      <HStack
        my='1em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='1vw'
        flexDirection={{ sm: 'column', md: 'row' }}
      >
        <Flex flexDirection='column' w={{ sm: 'full', md: '30vw', lg: '30vw' }}>
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ช่วงเวลา :</Text>
          </Box>
          <Flex bg='#F4F7FE' borderRadius='16px'>
            <Input
              cursor='pointer'
              border='0px'
              w='full'
              textAlign='center'
              as={DatePicker}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText={'วันที่เริ่มต้น'}
            />
            <Input
              cursor='pointer'
              border='0px'
              w='full'
              textAlign='center'
              as={DatePicker}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText={'วันที่สิ้นสุด'}
            />
          </Flex>
        </Flex>
        <Flex
          display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        >
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ค้นหาพนักงาน :</Text>
          </Box>
          <Select
            color={'gray.700'}
            w='full'
            bg={'#F4F7FE'}
            border='0px solid #E8E9E9'
            borderRadius='16px'
            defaultValue=''
            fontWeight='500'
            variant='main'
            placeholder={'ทั้งหมด'}
            _placeholder={{
              fontWeight: '400',
            }}
            h='44px'
            maxh='44px'
            onChange={(event) => setSelect(event.target.value)}
          >
            {Object.keys(allEmp).map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex
          display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        ></Flex>
        <Flex
          flexDirection='column'
          w={{ sm: 'full', md: '19vw', lg: '13vw', xl: '11vw' }}
          alignItems='end'
          pt={{ sm: '1rem', md: '0rem' }}
        >
          <Box
            textAlign='start'
            display={{ sm: 'none', md: 'flex' }}
            p='0.5rem'
            pl='1rem'
            pt='0rem'
          >
            <Text color='#8F9BBA'>&nbsp;</Text>
          </Box>
          <Button
            h='44px'
            maxh='44px'
            w={{ sm: '10rem', md: 'full' }}
            colorScheme='btn'
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            onClick={() => onSubmitFilter()}
          >
            <Text>
              <SearchIcon />
            </Text>
            <Text>แสดงรายงาน</Text>
          </Button>
        </Flex>
      </HStack>
      <Flex
        flexDirection='column'
        p={{ sm: '10px', md: '20px 20px 30px 20px' }}
        bg='white'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
        borderRadius='30px'
      >
        <Stack spacing={{ base: '0em', md: '4em' }}>
          <Stack>
            <Heading
              fontFamily={'Prompt'}
              color='#2B3674'
              fontSize={{ base: '24px', md: '30px' }}
            >
              รายงานยอดขายตามพนักงาน
            </Heading>
            <Box>
              <HStack>
                <Text color='#2B3674'>ณ วันที่:</Text>
                <Text color='#2B3674'>
                  {moment(new Date().getTime()).format('MM/DD/YYYY')}
                </Text>
              </HStack>
              <HStack>
                <Text color='#2B3674'>ช่วงเวลา:</Text>
                {startDate && endDate && (
                  <Text color='#2B3674'>
                    {moment(startDate).format('MM/DD/YYYY')} -{' '}
                    {moment(endDate).format('MM/DD/YYYY')}
                  </Text>
                )}
              </HStack>
            </Box>
          </Stack>
          <Box
            pt={{ base: '1rem', sm: '1rem', md: '0rem', lg: '0rem' }}
            pr={{
              base: '0rem',
              sm: '0rem',
              md: '1rem',
              lg: '1rem',
              xl: '3rem',
            }}
          >
            <Price
              total={onSearch ? calculateSales(data) : 0}
              commission={onSearch ? calculateCommission(data) : 0}
            />
          </Box>
        </Stack>
        <Box mt={{ base: '1rem', sm: '1rem', md: '2rem', lg: '2rem' }}>
          <CustomTable
            columnsData={columnsDataOrders}
            tableData={onSearch ? data : []}
          />
        </Box>
      </Flex>
    </>
  )
}

export default StaffSales

const columnsDataOrders = [
  {
    Header: 'ชื่อพนักงาน',
    accessor: 'employee',
    extra: (data, row) => (
      <Center key={row} w='full'>
        <Text>{row.employee}</Text>
      </Center>
    ),
  },
  {
    Header: 'วันที่',
    accessor: 'createdAt',
    extra: (data, row) => <Center key={row}>{row.createdAt}</Center>,
  },
  {
    Header: 'เลขที่รายการขาย',
    accessor: 'orderId',
    extra: (data, row) => (
      <Center key={row} maxW={'145px'}>
        {row.orderId}
      </Center>
    ),
  },
  {
    Header: 'สถานะ',
    accessor: 'หะฟะีห',
    extra: (data, row) => (
      <Center key={row}>
        {row?.status === 'paid' ? (
          <Badge colorScheme={'green'}>ชำระเงินแล้ว</Badge>
        ) : row?.status === 'refund' ? (
          <Badge colorScheme={'red'}>คืนเงิน</Badge>
        ) : row?.status === 'payLater' ? (
          '0'
        ) : null}
      </Center>
    ),
  },
  {
    Header: 'ยอดขาย',
    accessor: 'total',
    extra: (data, row) => (
      <Center justifyContent='flex-end' pr='5em'>
        <Text>{row?.total}</Text>
      </Center>
    ),
  },
  {
    Header: 'ส่วนลดสินค้า',
    accessor: 'promotion',
    extra: (data, row) => (
      <Center justifyContent='flex-end' pr='5em'>
        <Text>{currency(row.promotion, 2, true)}</Text>
      </Center>
    ),
  },
  {
    Header: 'ส่วนลดร้าน',
    accessor: 'discount',
    extra: (data, row) => {
      return (
        <Center justifyContent='flex-end' pr='5em'>
          <Text>{currency(row.discount, 2, true)}</Text>
        </Center>
      )
    },
  },
  {
    Header: 'ยอดขายสุทธิ',
    accessor: 'grandTotal',
    extra: (data, row) => (
      <Center key={row} justifyContent='flex-end' textAlign='end' w={'100px'}>
        {currency(row.grandTotal, 2, true)}
      </Center>
    ),
  },
  {
    Header: 'คอมมิชชั่น',
    accessor: 'commission',
    extra: (data, row) => (
      <Center key={row} justifyContent='flex-end' textAlign='end' w={'100px'}>
        {currency(row.commission, 2, true)}
      </Center>
    ),
  },
]
