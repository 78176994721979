import { Stack } from '@chakra-ui/react'
import { FormExpensesCategory } from 'Components/form/formExpensesCategory'
import React from 'react'

function AddExpensesCategory() {
  return (
    <Stack direction='column'>
      <FormExpensesCategory id={null} data={null} />
    </Stack>
  )
}

export default AddExpensesCategory
