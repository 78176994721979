import React, { useEffect, useState } from 'react'
import {
  HStack,
  Select,
  Flex,
  Text,
  Box,
  Button,
  Heading,
  Stack,
  Center,
} from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { useExpenses } from 'Hooks'
import { CustomTable } from './components/CustomTable'
import SearchDate from 'Components/Search/date'
import { useAppContext } from 'Context'
import { QueryExpenses } from './functions/qExpenses'
const accounting = require('accounting')
const _ = require('underscore')

function Price(props) {
  const { grandTotal } = props
  return (
    <HStack justifyContent='end' spacing='1em'>
      <Stack>
        <Box>
          <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
            รวมรายจ่าย
          </Text>
        </Box>
        <Box>
          <Text fontSize={{ base: '12px', md: '16px' }} color='report.500'>
            {accounting.formatNumber(grandTotal, 2)}
          </Text>
        </Box>
      </Stack>
    </HStack>
  )
}

function ExpenseExpenses() {
  const { user } = useAppContext()
  const [data, setData] = useState([])
  const { Category, getExpensesCategorys } = useExpenses()
  const [select, setSelect] = useState('')
  const [onSearch, setOnSearch] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [startDateTime, setStartDateTime] = useState(null)
  const [endDateTime, setEndDateTime] = useState(null)
  const [dateTime, setDateTime] = useState(false)
  const [currentDate, setCurrentDate] = useState(true)
  const [currentMonth, setCurrentMonth] = useState(false)
  const [between, setBetween] = useState(false)
  const [prevMonth, setPrevMonth] = useState(false)
  const [month, setMonth] = useState(new Date())

  useEffect(() => {
    getExpensesCategorys()
  }, [getExpensesCategorys])

  function calGrandTotal(item) {
    const value = _.reduce(item, (v, i) => v + parseFloat(i.grandTotal), 0)
    return value
  }

  async function onSubmitFilter() {
    setIsLoading(true)
    const res = await QueryExpenses({
      startDate: startDate,
      endDate: endDate,
      month: month,
      between: between,
      prevMonth: prevMonth,
      currentDate: currentDate,
      currentMonth: currentMonth,
      user: user,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      dateTime: dateTime,
      select: select,
    })
    if (startDateTime && endDateTime) {
      setStartDate(startDateTime)
      setEndDate(endDateTime)
    } else {
      setStartDateTime(null)
      setEndDateTime(null)
    }
    setOnSearch(true)
    setData(res)
    setIsLoading(false)
  }

  // useEffect(() => {
  //   const allData = _.filter(
  //     Expenses,
  //     (value) => value.branch === 'HQ' && value.status === 'approvel'
  //   )
  //   setData(
  //     allData.map((item) => ({
  //       ...item,
  //       createdAt: item.createdAt
  //         .toDate()
  //         .toLocaleString('en-US', { hour12: false }),
  //       date: item.createdAt.toDate(),
  //     }))
  //   )
  // }, [Expenses])
  return (
    <>
      <HStack
        my='1em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='1vw'
        flexDirection={{ sm: 'column', md: 'row' }}
        alignItems='start'
      >
        <Flex flexDirection='column' w={{ sm: 'full', md: '30vw', lg: '30vw' }}>
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ช่วงเวลา :</Text>
          </Box>
          <SearchDate
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            month={month}
            setMonth={setMonth}
            setBetween={setBetween}
            setPrevMonth={setPrevMonth}
            setCurrentDate={setCurrentDate}
            setCurrentMonth={setCurrentMonth}
            setStartDateTime={setStartDateTime}
            setEndDateTime={setEndDateTime}
            setDateTime={setDateTime}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
          />
        </Flex>
        <Flex
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        >
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>หมวดหมู่ :</Text>
          </Box>
          <Select
            color={'gray.700'}
            w='full'
            bg={'#F4F7FE'}
            border='0px solid #E8E9E9'
            borderRadius='16px'
            defaultValue=''
            fontWeight='500'
            variant='main'
            placeholder={'ทั้งหมด'}
            _placeholder={{
              fontWeight: '400',
            }}
            h='44px'
            maxh='44px'
            onChange={(event) => setSelect(event.target.value)}
          >
            {Category.map((item, index) => (
              <option value={item.name} key={index}>
                {item.name}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex
          display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        ></Flex>
        <Flex
          flexDirection='column'
          w={{ sm: 'full', md: '19vw', lg: '13vw', xl: '11vw' }}
          alignItems='end'
          pt={{ sm: '1rem', md: '0rem' }}
        >
          <Box
            textAlign='start'
            display={{ sm: 'none', md: 'flex' }}
            p='0.5rem'
            pl='1rem'
            pt='0rem'
          >
            <Text color='#8F9BBA'>&nbsp;</Text>
          </Box>
          <Button
            h='44px'
            maxh='44px'
            w={{ sm: '10rem', md: 'full' }}
            colorScheme='btn'
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            onClick={() => onSubmitFilter()}
            disabled={isLoading}
            isLoading={isLoading}
          >
            <Text>
              <SearchIcon />
            </Text>
            <Text>แสดงรายงาน</Text>
          </Button>
        </Flex>
      </HStack>
      <Flex
        flexDirection='column'
        p={{ sm: '10px', md: '20px 20px 30px 20px' }}
        bg='white'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
        borderRadius='30px'
      >
        <Stack spacing={{ base: '0em', md: '4em' }}>
          <HStack justifyContent={'space-between'} w='full'>
            <Stack>
              <Heading
                fontFamily={'Prompt'}
                color='#2B3674'
                fontSize={{ base: '24px', md: '30px' }}
              >
                รายงานรายจ่าย
              </Heading>
              <Box>
                <HStack>
                  <Text color='#2B3674'>ณ วันที่ :</Text>
                  <Text color='#2B3674'>
                    {moment(new Date().getTime()).format('MM/DD/YYYY')}
                  </Text>
                </HStack>
                <HStack>
                  <Text color='#2B3674'>ช่วงเวลา :</Text>
                  {startDate && endDate && (
                    <Text color='#2B3674'>
                      {moment(startDate).format('MM/DD/YYYY')} -{' '}
                      {moment(endDate).format('MM/DD/YYYY')}
                    </Text>
                  )}
                </HStack>
              </Box>
            </Stack>
            {/* <HStack>
              <CSVDownloadForm
                buttonTitle={'รายงานรายจ่าย'}
                // data={csvData}
                filename={'expenseReport'}
                // headers={csvHeaders}
              />
            </HStack> */}
          </HStack>
          <Box
            pt={{ base: '1rem', sm: '1rem', md: '0rem', lg: '0rem' }}
            pr={{
              base: '0rem',
              sm: '0rem',
              md: '1rem',
              lg: '1rem',
              xl: '1rem',
            }}
          >
            <Price grandTotal={onSearch ? calGrandTotal(data) : 0} />
          </Box>
        </Stack>
        <CustomTable
          columnsData={columnsData}
          tableData={onSearch ? data : []}
          closeSearch={true}
        />
      </Flex>
    </>
  )
}

export default ExpenseExpenses

const columnsData = [
  {
    Header: 'วันที่',
    accessor: 'createdAt',
    extra: (data, row) => (
      <Box>
        <Text>{row.createdAt}</Text>
      </Box>
    ),
  },
  {
    Header: 'เลขที่รายจ่าย',
    accessor: 'id',
    extra: (data, row) => (
      <Center>
        <Text>{row.id}</Text>
      </Center>
    ),
  },
  {
    Header: 'ชื่อผู้เบิกจ่าย / ชื่อโปรเจค',
    accessor: 'name',
    extra: (data, row) => (
      <Center>
        <Text>{row.name}</Text>
      </Center>
    ),
  },
  {
    Header: 'หมวดหมู่',
    accessor: 'category',
    extra: (data, row) => (
      <Center>
        <Text>{row.category}</Text>
      </Center>
    ),
  },
  {
    Header: (
      <Text w='100%' textAlign='end'>
        รายจ่าย
      </Text>
    ),
    accessor: 'grandTotal',
    extra: (data, row) => (
      <Center justifyContent='flex-end'>
        <Text>
          {new Intl.NumberFormat('th-TH', {
            minimumFractionDigits: 2,
          }).format(row.grandTotal)}
        </Text>
      </Center>
    ),
  },
]
