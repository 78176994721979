import { db, storage } from 'Config/firebase'
import { useAppContext } from 'Context'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
} from 'firebase/firestore'
import { getDownloadURL, ref, uploadString } from 'firebase/storage'
import { useCallback, useEffect, useState } from 'react'
import _ from 'underscore'

export function usePayment() {
  const { setError, setMessage, user } = useAppContext()
  const [allData, setAllData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [allBankForBranch, setAllBankForBranch] = useState([])
  const paymentRef = collection(db, 'Payment')

  const getPayments = useCallback(async () => {
    const paymentSnapshotRef = query(paymentRef, orderBy('createdAt', 'desc'))
    const getData = await getDocs(paymentSnapshotRef)
    const data = await _.map(getData?.docs, (doc) => {
      return {
        id: doc?.id,
        ...doc?.data(),
      }
    })
    const bankBranch = []
    Promise.all(
      await _.map(data, (item) => {
        if (item.paymentBranchType === 'branch') {
          const findData = _.findWhere(item.branchs, {
            id: user?.branchSelect.id,
          })
          if (findData) {
            bankBranch.push(item)
          }
        } else if (item.paymentBranchType === 'default') {
          bankBranch.push(item)
        }
      })
    )
    setAllBankForBranch(bankBranch)
    setAllData(data)
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const createBankPayment = (val, image) => {
    try {
      return addDoc(paymentRef, {
        paymentType: 'บัญชีธนาคาร',
        ...val,
        createdAt: new Date(),
        disabled: false,
        edit: true,
      }).then(async (res) => {
        const id = res.id
        const PaymentDoc = doc(db, 'Payment', id)
        if (image) {
          try {
            const paymentRef = ref(
              storage,
              `Payment/${id}:${new Date().getTime()}`
            )
            return uploadString(paymentRef, image, 'data_url').then(
              async () => {
                const downloadURL = await getDownloadURL(paymentRef)
                return updateDoc(PaymentDoc, { img: downloadURL })
              }
            )
          } catch (error) {
            setError({
              code: 'Payment',
              message: error.message,
            })
          }
        }
        setMessage({
          type: 'success',
          message: 'เพิ่มช่องทางการชำระเงินสำเร็จ',
        })
      })
    } catch (error) {
      setError({
        code: 'Payment',
        message: 'ไม่สามารถเพิ่มช่องทางการชำระเงินได้',
      })
    }
  }

  const getPaymentByID = async (id) => {
    const paymentDoc = doc(paymentRef, id)
    return (await getDoc(paymentDoc)).data()
  }

  const updateBankPaymentById = (id, data, image) => {
    const paymentDoc = doc(paymentRef, id)
    try {
      return updateDoc(paymentDoc, {
        ...data,
        updatedAt: new Date(),
        updatedBy: user?.uid,
      }).then(async () => {
        const PaymentDoc = doc(db, 'Payment', id)
        if (image) {
          try {
            const paymentRef = ref(
              storage,
              `Payment/${id}:${new Date().getTime()}`
            )
            return uploadString(paymentRef, image, 'data_url').then(
              async () => {
                const downloadURL = await getDownloadURL(paymentRef)
                return updateDoc(PaymentDoc, { img: downloadURL }).then(() => {
                  setMessage({
                    type: 'success',
                    message: 'แก้ไขธนาคารสำเร็จ',
                  })
                })
              }
            )
          } catch (error) {
            setError({
              code: 'Payment',
              message: error.message,
            })
          }
        } else {
          setMessage({
            type: 'success',
            message: 'แก้ไขธนาคารสำเร็จ.',
          })
        }
      })
    } catch (err) {
      setError({
        code: 'Payment',
        message: err.message,
      })
    }
  }

  const updateCashPaymentById = (id, data, image) => {
    const paymentDoc = doc(paymentRef, id)
    try {
      return updateDoc(paymentDoc, {
        ...data,
        updatedAt: new Date(),
        updatedBy: user?.uid,
      }).then(async () => {
        const PaymentDoc = doc(db, 'Payment', id)
        if (image) {
          try {
            const paymentRef = ref(
              storage,
              `Payment/${id}:${new Date().getTime()}`
            )
            return uploadString(paymentRef, image, 'data_url').then(
              async () => {
                const downloadURL = await getDownloadURL(paymentRef)
                return updateDoc(PaymentDoc, { img: downloadURL })
              }
            )
          } catch (error) {
            setError({
              code: 'Payment',
              message: error.message,
            })
          }
        }
      })
    } catch (err) {
      setError({
        code: 'Payment',
        message: err.message,
      })
    }
  }

  const removeBankPaymentById = (id) => {
    const paymentDoc = doc(db, 'Payment', id)
    try {
      return deleteDoc(paymentDoc).then(() => {
        setMessage({
          type: 'success',
          message: 'ลบธนาคารสำเร็จ',
        })
      })
    } catch (e) {
      setError({
        code: 'Payment',
        message: e.message,
      })
    }
  }

  useEffect(() => {
    getPayments()
  }, [getPayments])

  return {
    isLoading,
    allData,
    allBankForBranch,
    removeBankPaymentById,
    createBankPayment,
    getPaymentByID,
    updateCashPaymentById,
    updateBankPaymentById,
  }
}
