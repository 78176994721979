import React, { useEffect, useState } from 'react'
import {
  HStack,
  Input,
  Select,
  Flex,
  Text,
  Box,
  Button,
  Stack,
  Heading,
  Icon,
  Center,
  Badge,
  VStack,
} from '@chakra-ui/react'
import DatePicker from 'react-datepicker'
import { ChevronDownIcon, ChevronUpIcon, SearchIcon } from '@chakra-ui/icons'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import ExpandTable from './components/ExpandTable'
import {
  calculateCommissionStaff,
  calculateSalesStaff,
} from './functions/calculate'
import { useBranch, useOrder } from 'Hooks'
import { QueryReportSalesStaff } from './functions/query'

const accounting = require('accounting')
const _ = require('underscore')

function Price(props) {
  const { sales, commission } = props

  return (
    <HStack justifyContent='end' spacing='3em'>
      <Stack w='max-content' display={'none'}>
        <Box>
          <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
            ยอดขายสุทธิรวม
          </Text>
        </Box>
        <Box>
          <Text fontSize={{ base: '12px', md: '16px' }} color='report.500'>
            {accounting.formatNumber(sales, 2)}
          </Text>
        </Box>
        <Box></Box>
      </Stack>
      <Stack w='max-content'>
        <Box>
          <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
            คอมมิชชั่นรวม
          </Text>
        </Box>
        <Box>
          <Text fontSize={{ base: '12px', md: '16px' }} color='report.500'>
            {accounting.formatNumber(commission, 2)}
          </Text>
        </Box>
        <Box></Box>
      </Stack>
    </HStack>
  )
}

function ReportStaff() {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const { ordersReport, getAllOrdersWithReport } = useOrder()
  const { Branch } = useBranch()
  const [select, setSelect] = useState('')
  const [selectEmp, setSelectEmp] = useState('')
  const [filterData, setFilterData] = useState([])
  const [onSearch, setOnSearch] = useState(false)

  useEffect(() => {
    getAllOrdersWithReport()
  }, [getAllOrdersWithReport])

  async function onSubmitFilter() {
    const res = await QueryReportSalesStaff({
      select: select,
      selectEmp: selectEmp,
      startDate: startDate,
      endDate: endDate,
      Branch: Branch,
    })
    setOnSearch(true)
    setFilterData(res)
  }
  const employee = _.groupBy(_.pluck(ordersReport, 'createdBy'), 'name')
  const allEmployee = Object.keys(employee).map((item) => employee[item][0])
  return (
    <>
      <HStack
        my='1em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='1vw'
        flexDirection={{ sm: 'column', md: 'row' }}
      >
        <Flex flexDirection='column' w={{ sm: 'full', md: '30vw', lg: '30vw' }}>
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ช่วงเวลา :</Text>
          </Box>
          <Flex bg='#F4F7FE' borderRadius='16px'>
            <Input
              cursor='pointer'
              border='0px'
              w='full'
              textAlign='center'
              as={DatePicker}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText={'วันที่เริ่มต้น'}
            />
            <Input
              cursor='pointer'
              border='0px'
              w='full'
              textAlign='center'
              as={DatePicker}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText={'วันที่สิ้นสุด'}
            />
          </Flex>
        </Flex>
        <Flex
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        >
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>สาขา :</Text>
          </Box>
          <Select
            color={'gray.700'}
            w='full'
            bg={'#F4F7FE'}
            border='0px solid #E8E9E9'
            borderRadius='16px'
            defaultValue=''
            fontWeight='500'
            variant='main'
            placeholder={'ทั้งหมด'}
            _placeholder={{
              fontWeight: '400',
            }}
            h='44px'
            maxh='44px'
            onChange={(event) => setSelect(event.target.value)}
          >
            {Branch.map((item, index) => (
              <option value={item.id} key={index}>
                {item.name}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          // display={{ sm: 'none', md: 'flex' }}
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        >
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ค้นหาพนักงาน :</Text>
          </Box>
          <Select
            color={'gray.700'}
            w='full'
            bg={'#F4F7FE'}
            border='0px solid #E8E9E9'
            borderRadius='16px'
            defaultValue=''
            fontWeight='500'
            variant='main'
            placeholder={'ทั้งหมด'}
            _placeholder={{
              fontWeight: '400',
            }}
            h='44px'
            maxh='44px'
            onChange={(event) => setSelectEmp(event.target.value)}
          >
            {allEmployee.map((item, index) => (
              <option value={item.id} key={index}>
                {item.name}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex
          flexDirection='column'
          w={{ sm: 'full', md: '19vw', lg: '13vw', xl: '11vw' }}
          alignItems='end'
          pt={{ sm: '1rem', md: '0rem' }}
        >
          <Box
            textAlign='start'
            display={{ sm: 'none', md: 'flex' }}
            p='0.5rem'
            pl='1rem'
            pt='0rem'
          >
            <Text color='#8F9BBA'>&nbsp;</Text>
          </Box>
          <Button
            h='44px'
            maxh='44px'
            w={{ sm: '10rem', md: 'full' }}
            colorScheme='btn'
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            onClick={() => onSubmitFilter()}
          >
            <Text>
              <SearchIcon />
            </Text>
            <Text>แสดงรายงาน</Text>
          </Button>
        </Flex>
      </HStack>
      <Flex
        flexDirection='column'
        p={{ sm: '10px', md: '20px 20px 30px 20px' }}
        bg='white'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
        borderRadius='30px'
      >
        <Stack spacing={{ base: '0em', md: '4em' }}>
          <Stack>
            <Heading
              fontFamily={'Prompt'}
              color='#2B3674'
              fontSize={{ base: '24px', md: '30px' }}
            >
              รายงานยอดขายตามพนักงาน
            </Heading>
            <Box>
              <HStack>
                <Text color='#2B3674'>ณ วันที่:</Text>
                <Text color='#2B3674'>
                  {moment(new Date().getTime()).format('DD/MM/YY')}
                </Text>
              </HStack>
              <HStack>
                <Text color='#2B3674'>ช่วงเวลา:</Text>
                {startDate && endDate && (
                  <Text color='#2B3674'>
                    {moment(startDate).format('MM/DD/YYYY')} -{' '}
                    {moment(endDate).format('MM/DD/YYYY')}
                  </Text>
                )}
              </HStack>
            </Box>
          </Stack>
          <Box
            pt={{ base: '1rem', sm: '1rem', md: '0rem', lg: '0rem' }}
            pr={{
              base: '0rem',
              sm: '0rem',
              md: '1rem',
              lg: '1rem',
              xl: '1rem',
              '2xl': '3rem',
            }}
          >
            <Price
              commission={onSearch ? calculateCommissionStaff(filterData) : 0}
              sales={onSearch ? calculateSalesStaff(filterData) : 0}
            />
          </Box>
        </Stack>
        <ExpandTable
          columnsData={columnsData}
          tableData={onSearch ? filterData : []}
        />
      </Flex>
    </>
  )
}

export default ReportStaff

const columnsData = [
  {
    // Build our expander column
    id: 'expander', // Make sure it has an ID
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      <Text {...getToggleAllRowsExpandedProps()}>
        {isAllRowsExpanded ? (
          <Icon as={ChevronUpIcon} />
        ) : (
          <Icon as={ChevronDownIcon} />
        )}
      </Text>
    ),
    Cell: ({ row }) =>
      row.canExpand ? (
        <Center>
          <Text
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            {row.isExpanded ? (
              <Icon as={ChevronUpIcon} />
            ) : (
              <Icon as={ChevronDownIcon} />
            )}
          </Text>
        </Center>
      ) : null,
  },
  {
    Header: 'สาขา',
    accessor: 'branch',
    Cell: ({ row }) => (
      <Box>
        <Text>{row.original.branch}</Text>
      </Box>
    ),
  },
  {
    Header: 'ชื่อพนักงาน',
    accessor: 'employee',
    Cell: ({ row }) => (
      <Box>
        <Text>{row.original.employee?.name}</Text>
      </Box>
    ),
  },
  {
    Header: 'วันที่',
    accessor: 'createdAt',
    Cell: ({ row }) => (
      <Center>
        <Text>{row.original.createdAt}</Text>
      </Center>
    ),
  },
  {
    Header: 'เลขที่ใบสั่งซื้อ',
    accessor: 'ordernumber',
    Cell: ({ row }) => (
      <Center>
        <Text>{row.original.ordernumber}</Text>
      </Center>
    ),
  },
  {
    Header: 'สถานะ',
    accessor: 'status',
    Cell: ({ row }) => (
      <Center>
        {row?.original?.status === 'paid' ? (
          <Badge colorScheme={'green'}>ชำระเงินแล้ว</Badge>
        ) : row?.original?.status === 'refund' ? (
          <Badge colorScheme={'red'}>คืนเงิน</Badge>
        ) : row?.original?.status === 'payLater' ? (
          '0'
        ) : null}
      </Center>
    ),
  },
  {
    Header: 'ยอดขาย',
    accessor: 'total',
    Cell: ({ row }) => (
      <Center justifyContent='flex-end' pr='5em'>
        <Text>{row?.original?.total}</Text>
      </Center>
    ),
  },
  {
    Header: 'ส่วนลดสินค้า',
    accessor: 'discount',
    Cell: ({ row }) => (
      <VStack justifyContent='flex-end' pr='5em'>
        {row?.original?.discount?.map((item) => (
          <Text>{item}</Text>
        ))}
      </VStack>
    ),
  },
  {
    Header: 'ยอดขายสุทธิ',
    accessor: 'grandTotal',
    Cell: ({ row }) => (
      <Center justifyContent='flex-end' pr='5em'>
        <Text>
          {new Intl.NumberFormat('th-TH', {
            minimumFractionDigits: 2,
          }).format(row?.original?.grandTotal)}
        </Text>
      </Center>
    ),
  },
  {
    Header: 'คอมมิชชั่น',
    accessor: 'commission',
    Cell: ({ row }) => (
      <Center justifyContent='flex-end' pr='5em'>
        <Text>
          {new Intl.NumberFormat('th-TH', {
            minimumFractionDigits: 2,
          }).format(row?.original?.commission)}
        </Text>
      </Center>
    ),
  },
]
