import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Input,
  Text,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from 'react-icons/ai'

import { IoTrashBin } from 'react-icons/io5'
import { currency, decodeUnit, encodeUnit } from 'Utils/currency'

function PosProductCardSelect(props) {
  const {
    item,
    addCart,
    removeCart,
    removeItemCart,
    addCartCount,
    // total,
    removePromotionCartFreeItem,
  } = props
  const [addItemCart, setAddItemCart] = useState(0)

  function blurHandler() {
    addCartCount(item, parseFloat(addItemCart))

    // if (parseFloat(addItemCart) <= parseFloat(item.total)) {
    //   addCartCount(item, parseFloat(addItemCart))
    // } else {
    //   addCartCount(item, parseFloat(item.total))
    // }
  }
  useEffect(() => {
    setAddItemCart(parseFloat(item?.amount))
    // if (item && item.amount <= parseFloat(item.total)) {
    //   setAddItemCart(parseFloat(item?.amount))
    // } else {
    //   setAddItemCart(parseFloat(item.total))
    // }
  }, [item])
  return (
    <Box borderBottom='1px dashed' borderColor='transparent' w='100%'>
      <Grid
        pb={{ base: '0.5rem', md: '0.5rem' }}
        templateColumns={{
          base: 'repeat(3, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(2, 1fr)',
        }}
        gap={{ base: '0px', sm: '10px', md: '0px' }}
        bg='white'
        w='100%'
      >
        <GridItem
          w={{ base: '6rem', sm: '6rem', md: '100%' }}
          justifyContent='flex-start'
          display={{
            base: 'GridItem',
            md: 'none',
            lg: 'none',
            xl: 'none',
            '2xl': 'none',
          }}
          px={{ sm: '0.2rem', md: '0rem' }}
        >
          <Image
            borderRadius='10px'
            w={{ base: '100%', md: '100%', lg: '15rem' }}
            h={{ base: '5rem', md: '7rem', lg: '15rem' }}
            objectFit='contain'
            alt='product'
            src={item?.img || '/product_Thumbnail.png'}
          />
        </GridItem>
        <GridItem
          pl={{ md: '0rem', lg: '0rem' }}
          justify={{
            base: 'center',
            md: 'center',
            lg: 'center',
            xl: 'center',
            '2xl': 'space-between',
          }}
          flexDirection={{
            base: 'row',
            md: 'column',
            lg: 'column',
            xl: 'column',
            '2xl': 'row',
          }}
          align={'center'}
          width='full'
          gap={0}
          w='full'
        >
          <Flex flexDirection='column' justifyContent='flex-start'>
            <Flex
              flexDirection={'row'}
              w='100%'
              textAlign={{
                base: 'left',
                md: 'center',
                lg: 'center',
                xl: 'center',
                '2xl': 'left',
              }}
            >
              <HStack flexDirection='column'>
                <Text
                  userSelect='none'
                  fontSize={{ base: '15px', md: '15px', lg: '15px', xl: 'md' }}
                  noOfLines='1'
                  w={{ sm: 'auto', md: 'auto' }}
                >
                  {item?.name} &nbsp; {item?.nameOption}
                </Text>

                {item?.free && (
                  <HStack spacing='1rem'>
                    <Text
                      userSelect='none'
                      fontSize={{
                        base: '14px',
                        md: '14px',
                        lg: '14px',
                        xl: 'md',
                      }}
                      color='black'
                    >
                      x &nbsp; {item?.amount}
                    </Text>
                    <IoTrashBin
                      cursor={'pointer'}
                      color={'red'}
                      onClick={() => {
                        removePromotionCartFreeItem(item, true, 1)
                      }}
                    />
                  </HStack>
                )}
              </HStack>
            </Flex>

            {item?.unit === 'piece' && !item?.free && (
              <Flex justifyContent={{ md: 'space-evenly', lg: 'flex-start' }}>
                <Button
                  w={{ lg: '2.5rem', xl: '2.3rem' }}
                  h={{ lg: '2.5rem', xl: '2.3rem' }}
                  borderRadius='full'
                  color='black'
                  onClick={() => {
                    removeCart(item)
                  }}
                >
                  <Center position='absolute' fontSize='20px'>
                    <AiOutlineMinusSquare />
                  </Center>
                </Button>
                <HStack
                  spacing={'30px'}
                  w={{ sm: '2.8rem', md: '2.8rem', lg: '2.8rem', xl: '2.8rem' }}
                  justifyContent='center'
                >
                  <Text
                    userSelect='none'
                    fontSize={{
                      base: '14px',
                      md: '14px',
                      lg: '14px',
                      xl: 'md',
                    }}
                    color='black'
                  >
                    x {item?.amount}
                  </Text>
                </HStack>
                <Button
                  w='2.5rem'
                  h='2.5rem'
                  borderRadius='full'
                  color='black'
                  onClick={() => {
                    addCart(item)
                  }}
                  // disabled={
                  //   // parseFloat(total) <=
                  //   // item?.amount + (item?.unit === 'piece' ? 1 : 0.5)
                  //   item.amount + (item?.unit === 'piece' ? 1 : 0.5) <=
                  //   parseFloat(total)
                  //     ? false
                  //     : true
                  // }
                >
                  <Center position='absolute' fontSize='20px'>
                    <AiOutlinePlusSquare />
                  </Center>
                </Button>
              </Flex>
            )}
            {item?.unit === 'weight' && !item?.free && (
              <>
                <HStack>
                  <Input
                    type={'number'}
                    value={addItemCart}
                    onChange={(e) => {
                      setAddItemCart(e.target.value)
                    }}
                    onBlur={blurHandler}
                  />
                  <Box display={{ base: 'none', sm: 'none', md: 'block' }}>
                    <IoTrashBin
                      cursor={'pointer'}
                      color={'red'}
                      onClick={() => {
                        removeItemCart(item)
                      }}
                    />
                  </Box>
                </HStack>
              </>
            )}
          </Flex>

          <Flex
            display={{
              base: 'flex',
              md: 'flex',
              lg: 'flex',
              xl: 'flex',
              '2xl': 'none',
            }}
            flexDirection={'column'}
            justifyContent='center'
            alignItems='center'
            w='full'
            px='2rem'
          />
        </GridItem>
        <GridItem w='100%'>
          <HStack
            w='100%'
            spacing={'0px'}
            color='black'
            userSelect='none'
            justifyContent='flex-end'
            alignItems='flex-start'
            h='full'
            pr='0.5rem'
            textAlign='end'
          >
            <Text fontSize={{ md: '14px', lg: '14px', xl: 'md' }} w='full'>
              {item?.amount !== undefined
                ? currency(
                    decodeUnit(
                      decodeUnit(item?.price) * encodeUnit(item?.amount)
                    ),
                    2,
                    true
                  )
                : currency(0, 2, true)}
            </Text>
          </HStack>
        </GridItem>
      </Grid>
    </Box>
  )
}

export default PosProductCardSelect
