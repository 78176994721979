import { Stack } from '@chakra-ui/react'
import { FormExpenses } from 'Components/form'
import { useGenerateId } from 'Hooks'
import React from 'react'

function AddExpenses({ user }) {
  const { IdOnlyNumber } = useGenerateId()
  const id = `EXP${IdOnlyNumber(10)}`
  return (
    <Stack direction='column'>
      <FormExpenses id={null} data={null} ids={id} />
    </Stack>
  )
}

export default AddExpenses
