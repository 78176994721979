// Chakra imports
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
// Custom components
import React from 'react'
import { Controller } from 'react-hook-form'

export default function InputField({
  id,
  label,
  extra,
  placeholder,
  type,
  mb,
  name,
  control,
  errors,
  validate,
  req,
  required,
  pattern,
  Length,
  defaultValue,
  min,
  ...rest
}) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')

  return (
    <FormControl id={name} isInvalid={errors[name]} key={name}>
      <Flex direction='column' mb={mb ? mb : '30px'}>
        <FormLabel
          display='flex'
          ms='10px'
          htmlFor={id}
          fontSize='sm'
          color={textColorPrimary}
          fontWeight='bold'
          _hover={{ cursor: 'pointer' }}
        >
          {label}
          <Text fontSize='sm' fontWeight='400' ms='2px'>
            {extra}
          </Text>
        </FormLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={{
            required,
            pattern,
            validate,
            maxLength: Length,
            min: min,
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              {...rest}
              required={req}
              value={value}
              onChange={onChange}
              type={type}
              id={id}
              borderRadius={{
                base: '20px',
                sm: '20px',
                md: '20px',
                lg: '20px',
              }}
              defaultValue=''
              fontWeight='500'
              variant='main'
              placeholder={placeholder}
              _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
              h='44px'
              maxh='44px'
              onWheel={(e) => e.target.blur()}
            />
          )}
        />
      </Flex>
      <FormErrorMessage>
        {errors[name] && errors[name]?.message}
      </FormErrorMessage>
    </FormControl>
  )
}
