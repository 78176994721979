import { Box, Button, Center, Input, Text } from '@chakra-ui/react'
import { calReceived } from 'Pages/Pos/Utils/calcurate'
import { calPromotionItems } from 'Pages/Pos/Utils/calPromotion'
import { currency } from 'Utils/currency'

function PosCashReceived(props) {
  const {
    setReceived,
    received,
    confirmPayment,
    confirmLoading,
    cartItems,
    posUseDiscount,
  } = props
  return (
    <>
      <Center
        mt={{ base: '2rem', sm: '2rem', md: '1rem' }}
        w='100%'
        flexDirection='column'
      >
        <Center flexDirection='column' w='100%'>
          <Box>
            <Text
              color='pos.500'
              fontSize={{ base: '24px', sm: '24px', md: '20px', lg: '20px' }}
            >
              เงินสดรับ
            </Text>
          </Box>
          <Box
            mt={{ base: '1rem', sm: '1rem', md: '0.5rem' }}
            mb={{ base: '1rem', sm: '1rem', md: '0.5rem' }}
            px={{ base: '1.5rem', sm: '1.5rem', md: '2.5rem' }}
          >
            <Input
              zIndex={{
                base: 'overlay',
                sm: 'overlay',
                md: 'overlay',
                lg: 'base',
              }}
              fontSize={{ base: '20px', sm: '20px', md: '18px' }}
              variant='flushed'
              w='100%'
              textAlign='center'
              placeholder='ระบุจำนวนเงิน'
              type='number'
              value={received}
              // colorScheme='green'
              pattern={`/[0-9]/`}
              onChange={(e) => {
                setReceived(e.target.value)
              }}
            />
          </Box>
        </Center>
        <Center py={{ base: '2rem', sm: '2rem', md: '1rem' }} w='100%'>
          <Box w='100%' borderTop='1px solid #E0E5F2' h='1px' m='0.2rem'></Box>
        </Center>
        <Center mt='0rem' flexDirection='column' w='100%'>
          <Box>
            <Text
              color='pos.500'
              fontSize={{ base: '24px', sm: '24px', md: '20px', lg: '20px' }}
            >
              เงินทอน
            </Text>
          </Box>
          <Center mt={{ base: '1rem', sm: '1rem', md: '0.5rem' }}>
            <Text fontSize={{ base: '22px', sm: '22px', md: '20px' }}>
              {currency(
                calReceived(
                  calPromotionItems(cartItems, posUseDiscount)?.sum,
                  parseFloat(received)
                ),
                2
              )}
            </Text>
          </Center>
        </Center>
        <Center
          py={{ base: '2rem', sm: '2rem', md: '1rem' }}
          pb='0rem'
          w='100%'
        >
          <Box w='100%' borderTop='1px solid #E0E5F2' h='1px' m='0.2rem'></Box>
        </Center>
      </Center>

      <Center
        pt='6rem'
        display={{
          base: 'none',
          sm: 'none',
          md: 'flex',
          lg: 'flex',
          xl: 'flex',
          '2xl': 'flex',
        }}
        bottom='0px'
        w='100%'
      >
        <Box p='0.3rem' w='100%'>
          <Button
            w='100%'
            colorScheme={'btn'}
            py='1.7rem'
            borderRadius='5px'
            // disabled={
            //   posUseDiscount
            //     ? !(
            //         parseFloat(received) >=
            //         calAmount(cartItems, posUseDiscount) -
            //           calPromotionAmount(cartItems)
            //       ) || confirmLoading
            //     : !(
            //         parseFloat(received) >=
            //         calAmount(cartItems) - calPromotionAmount(cartItems)
            //       ) || confirmLoading
            // }
            disabled={
              !(
                parseFloat(received) >=
                calPromotionItems(cartItems, posUseDiscount)?.sum
              ) || confirmLoading
            }
            loadingText={'Loading...'}
            isLoading={confirmLoading}
            onClick={() => {
              confirmPayment('cash')
            }}
          >
            ยืนยันการชำระเงิน
          </Button>
        </Box>
      </Center>
      <Center
        display={{
          base: 'flex',
          sm: 'flex',
          md: 'none',
          lg: 'none',
          xl: 'none',
          '2xl': 'none',
        }}
        position='absolute'
        bottom='0px'
        w='100%'
        h='100vh'
        zIndex='base'
        alignItems='flex-end'
      >
        <Box p='0.3rem' w='100%'>
          <Button
            colorScheme={'btn'}
            w='100%'
            py='1.7rem'
            borderRadius='5px'
            // disabled={
            //   posUseDiscount
            //     ? !(
            //         parseFloat(received) >=
            //         calAmount(cartItems, posUseDiscount) -
            //           calPromotionAmount(cartItems)
            //       ) || confirmLoading
            //     : !(
            //         parseFloat(received) >=
            //         calAmount(cartItems) - calPromotionAmount(cartItems)
            //       ) || confirmLoading
            // }
            disabled={
              !(
                parseFloat(received) >=
                calPromotionItems(cartItems, posUseDiscount)?.sum
              ) || confirmLoading
            }
            loadingText={'Loading...'}
            isLoading={confirmLoading}
            onClick={() => {
              confirmPayment('cash')
            }}
          >
            ยืนยันการชำระเงิน
          </Button>
        </Box>
      </Center>
    </>
  )
}

export default PosCashReceived
