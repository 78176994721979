const _ = require('underscore')
export function calculateCost(item) {
  const subRow = _.flatten(_.pluck(item, 'subRows'))
  const calSubRow = _.reduce(subRow, (value, item) => value + item.cost, 0)

  return calSubRow
}

export function calculateSales(item) {
  const subRow = _.flatten(_.pluck(item, 'subRows'))
  const calSubRow = _.reduce(
    subRow,
    (value, item) => value + item.grandTotal,
    0
  )

  return calSubRow
}

export function calculateCommission(item) {
  const subRow = _.flatten(_.pluck(item, 'subRows'))
  const calSubRow = _.reduce(
    subRow,
    (value, item) => value + item.commission,
    0
  )

  return calSubRow
}

export function calculateDiff(item) {
  const subRow = _.flatten(_.pluck(item, 'subRows'))
  const calSubRow = _.reduce(subRow, (value, item) => value + item.diff, 0)

  return calSubRow
}

export function calculateGrandTotal(item) {
  const subRow = _.flatten(_.pluck(item, 'subRows'))
  const calSubRow = _.reduce(
    subRow,
    (value, item) => value + item.grandTotal,
    0
  )

  return calSubRow
}

export function calculateSalesStaff(item) {
  const subRow = _.flatten(_.pluck(item, 'subRows'))
  const calSubRow = _.reduce(subRow, (value, item) => value + item.sales, 0)

  return calSubRow
}

export function calculateCommissionStaff(item) {
  const subRow = _.flatten(_.pluck(item, 'subRows'))
  const calSubRow = _.reduce(
    subRow,
    (value, item) => value + item.commission,
    0
  )

  return calSubRow
}
