import { useState, useEffect, useRef, useMemo } from 'react'
import { DeleteIcon, SearchIcon } from '@chakra-ui/icons'

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Stack,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  InputGroup,
  Input,
  InputRightElement,
  Spinner,
  Fade,
  IconButton,
  SimpleGrid,
  Tag,
  TagLabel,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Card from 'Components/Horizons/card/Card'
import { search } from 'ss-search'
import { Link } from 'react-router-dom'
import CustomTable from 'Components/Horizons/table/customTable'

import { usePurchaseOrder } from 'Hooks'

function Manage({ value, refetch, cancelOrder }) {
  const [isLoading, setIsLoading] = useState(false)
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure()
  const cancelRef = useRef()

  async function handleDelete(id) {
    await setIsLoading(true)
    await cancelOrder(id).then(() => {
      refetch()
    })
  }

  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={deleteOnClose}
        isOpen={deleteIsOpen}
        isCentered
        size='auto'
      >
        <AlertDialogOverlay />

        <AlertDialogContent
          w={{ sm: '20.5rem', md: '25rem' }}
          fontFamily={'Prompt'}
        >
          <AlertDialogHeader>คุณแน่ใจ?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            คุณยืนยันลบรายการใบสั่งสินค้านี้ใช่หรือไม่ ?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={deleteOnClose}>
              ไม่
            </Button>
            <Button
              colorScheme='red'
              ml={3}
              onClick={() => {
                handleDelete(value.id)
              }}
              isLoading={isLoading}
            >
              ใช่
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Button
        leftIcon={<DeleteIcon />}
        variant='ghost'
        colorScheme='blackAlpha'
        onClick={deleteOnOpen}
        isDisabled={value.orderStatus !== 'pending'}
      >
        ลบ
      </Button>
    </>
  )
}

const Status = {
  pending: 'รอดำเนินการ',
  processing: 'ดำเนินการ',
  completed: 'เสร็จสิ้น',
  rejected: 'ถูกยกเลิก',
}

const StatusCouter = (data, status) =>
  data.reduce((a, d) => (d.orderStatus === status ? a + 1 : a), 0)

const PurchaseOrderPage = ({ user }) => {
  const navigate = useNavigate()
  const {
    PurchaseOrder,
    getAllPurchaseOrders,
    cancelPurchaseOrder,
    isLoading,
  } = usePurchaseOrder()
  const [isFadePage, setIsFadePage] = useState(true)
  const [optionsSearch, setOptionsSearch] = useState({
    search: '',
    status: '',
  })
  const tableData = useMemo(() => {
    const { status, search: searchText } = optionsSearch
    let array = PurchaseOrder
    if (status !== '') {
      array = array.filter((p) => p.orderStatus === status)
    }

    if (searchText !== '') {
      const searchKey = ['id', 'createdBy.name']
      array = search(array, searchKey, searchText)
    }

    return array
  }, [PurchaseOrder, optionsSearch])

  useEffect(() => {
    setIsFadePage(false)
    getAllPurchaseOrders(user?.branchSelect?.id)
  }, [getAllPurchaseOrders, user?.branchSelect?.id])

  const columnsDataOrders = [
    {
      Header: 'เลขที่ใบสั่งสินค้า',
      accessor: 'name',
      extra: (id, row) => {
        return (
          <Button variant='link' as={Link} to={`/purchase-order/${row.id}`}>
            {row?.id}
          </Button>
        )
      },
    },
    {
      Header: 'ผู้สั่ง',
      accessor: 'createdBy.name',
    },
    {
      Header: 'วันที่สั่ง',
      accessor: 'createdAt',
      extra: (date) => {
        return date?.toDate().toLocaleDateString()
      },
    },
    {
      Header: 'สถานะ',
      accessor: 'orderStatus',
      extra: (status, row) => {
        return (
          <Center fontSize={16} fontWeight={500}>
            {Status[status]}
          </Center>
        )
      },
    },
    {
      Header: 'จัดการ',
      accessor: 'id',
      extra: (id, row) => {
        return (
          <Center pl='1rem'>
            <Manage
              value={row}
              refetch={getAllPurchaseOrders}
              cancelOrder={cancelPurchaseOrder}
            />
          </Center>
        )
      },
    },
  ]

  return (
    <>
      <Stack as={Fade} in={!isFadePage}>
        {!isLoading ? (
          <>
            <Card>
              <Box zIndex='base'>
                <HStack
                  my='1em'
                  spacing={{ base: '0px', sm: '0px', md: '10px' }}
                  justifyContent='space-between'
                  flexDirection={{
                    base: 'column',
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                  }}
                >
                  <InputGroup w={{ base: '100%', md: '440px', lg: '500px' }}>
                    <InputRightElement
                      children={
                        <IconButton
                          bg='inherit'
                          borderRadius='inherit'
                          _hover='none'
                          _active={{
                            bg: 'inherit',
                            transform: 'none',
                            borderColor: 'transparent',
                          }}
                          _focus={{
                            boxShadow: 'none',
                          }}
                          icon={
                            <SearchIcon
                              _hover='none'
                              color={'gray.700'}
                              w='15px'
                              h='15px'
                            />
                          }
                        />
                      }
                    />
                    <Input
                      variant='search'
                      fontSize='sm'
                      bg={'secondaryGray.300'}
                      color={'gray.700'}
                      fontWeight='500'
                      _placeholder={{
                        color: 'gray.400',
                        fontSize: '14px',
                        opacity: 1,
                      }}
                      value={optionsSearch.search}
                      borderRadius={'30px'}
                      placeholder={'ค้นหา'}
                      onChange={(e) => {
                        setOptionsSearch({
                          ...optionsSearch,
                          search: e.target.value,
                        })
                      }}
                    />
                  </InputGroup>
                  <Flex
                    flexDirection='column'
                    w={{ sm: 'full', md: '19vw', lg: '13vw', xl: '11vw' }}
                    alignItems='end'
                    pt={{ sm: '1rem', md: '0rem' }}
                  >
                    <Button
                      h='44px'
                      maxh='44px'
                      w={{ sm: '10rem', md: 'full' }}
                      colorScheme='btn'
                      borderRadius='16px'
                      display='flex'
                      justifyContent={'space-around'}
                      color='white'
                      onClick={() => navigate('/purchase-order/create')}
                    >
                      สร้าง
                    </Button>
                  </Flex>
                </HStack>
              </Box>
              <SimpleGrid
                minChildWidth='125px'
                spacing='10px'
                mb={4}
                maxW={{ sm: 'full', lg: '800px', xl: '850px' }}
              >
                <Button
                  colorScheme='product'
                  variant={optionsSearch.status === '' ? 'solid' : 'outline'}
                  onClick={() =>
                    setOptionsSearch({ ...optionsSearch, status: '' })
                  }
                >
                  ทั้งหมด
                  <Tag
                    size='sm'
                    colorScheme='green'
                    variant={optionsSearch.status === '' ? 'subtle' : 'solid'}
                    borderRadius='full'
                    ml={2}
                  >
                    <TagLabel>{PurchaseOrder.length}</TagLabel>
                  </Tag>
                </Button>
                <Button
                  colorScheme='product'
                  variant={
                    optionsSearch.status === 'pending' ? 'solid' : 'outline'
                  }
                  onClick={() =>
                    setOptionsSearch({ ...optionsSearch, status: 'pending' })
                  }
                >
                  รอดำเนินการ
                  <Tag
                    size='sm'
                    colorScheme='green'
                    variant={
                      optionsSearch.status === 'pending' ? 'subtle' : 'solid'
                    }
                    borderRadius='full'
                    ml={2}
                  >
                    <TagLabel>
                      {StatusCouter(PurchaseOrder, 'pending')}
                    </TagLabel>
                  </Tag>
                </Button>
                <Button
                  colorScheme='product'
                  variant={
                    optionsSearch.status === 'processing' ? 'solid' : 'outline'
                  }
                  onClick={() =>
                    setOptionsSearch({ ...optionsSearch, status: 'processing' })
                  }
                >
                  ดำเนินการ
                  <Tag
                    size='sm'
                    colorScheme='green'
                    variant={
                      optionsSearch.status === 'processing' ? 'subtle' : 'solid'
                    }
                    borderRadius='full'
                    ml={2}
                  >
                    <TagLabel>
                      {StatusCouter(PurchaseOrder, 'processing')}
                    </TagLabel>
                  </Tag>
                </Button>
                <Button
                  colorScheme='product'
                  variant={
                    optionsSearch.status === 'completed' ? 'solid' : 'outline'
                  }
                  onClick={() =>
                    setOptionsSearch({ ...optionsSearch, status: 'completed' })
                  }
                >
                  เสร็จสิ้น
                  <Tag
                    size='sm'
                    colorScheme='green'
                    variant={
                      optionsSearch.status === 'completed' ? 'subtle' : 'solid'
                    }
                    borderRadius='full'
                    ml={2}
                  >
                    <TagLabel>
                      {StatusCouter(PurchaseOrder, 'completed')}
                    </TagLabel>
                  </Tag>
                </Button>
                <Button
                  colorScheme='product'
                  variant={
                    optionsSearch.status === 'rejected' ? 'solid' : 'outline'
                  }
                  onClick={() =>
                    setOptionsSearch({ ...optionsSearch, status: 'rejected' })
                  }
                >
                  ถูกยกเลิก
                  <Tag
                    size='sm'
                    colorScheme='green'
                    variant={
                      optionsSearch.status === 'rejected' ? 'subtle' : 'solid'
                    }
                    borderRadius='full'
                    ml={2}
                  >
                    <TagLabel>
                      {StatusCouter(PurchaseOrder, 'rejected')}
                    </TagLabel>
                  </Tag>
                </Button>
              </SimpleGrid>
              <CustomTable
                columnsData={columnsDataOrders}
                tableData={tableData}
                disabledSearch
              />
            </Card>
          </>
        ) : (
          <Card>
            <Center>
              <Spinner size='lg' />
            </Center>
          </Card>
        )}
      </Stack>
    </>
  )
}

export default PurchaseOrderPage
