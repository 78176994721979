import { useState, useEffect, useRef } from 'react'
import {
  Box,
  Button,
  Center,
  HStack,
  Stack,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Fade,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { FiCheckCircle } from 'react-icons/fi'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { encodeUnit } from 'Utils'

import Card from 'Components/Horizons/card/Card'
import CustomTable from 'Components/Horizons/table/customTable'

import { usePurchaseOrder, useInventory } from 'Hooks'

function Manage({ order, value, refetch, updateOrder, user }) {
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure()
  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useDisclosure()
  const cancelRef = useRef()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [reason, setReason] = useState('')
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const isError = input === ''
  const { transferInventory } = useInventory()

  async function handleReject(no) {
    setIsLoading(true)

    const newTableData = await order.products.map((data) => {
      if (data.no === no) {
        delete data.sendingFromBranch
        return { ...data, status: 'rejected', rejectedNote: input }
      }
      return data
    })
    const completed = await newTableData.every(
      (data) => data.status === 'completed' || data.status === 'rejected'
    )
    const rejected = await newTableData.every(
      (data) => data.status === 'rejected'
    )
    const data = {
      ...order,
      products: newTableData,
      orderStatus: completed
        ? 'completed'
        : rejected
        ? 'rejected'
        : 'processing',
      updatedAt: new Date(),
      updatedBy: { id: user?.uid, name: user?.name },
    }

    updateOrder(order?.id, data).then(() => {
      setIsLoading(false)
      refetch()
      deleteOnClose()
    })
  }

  async function handleConfirm(no) {
    setIsLoading(true)

    const newTableData = await order.products.map((data) => {
      if (data.no === no) {
        return { ...data, status: 'completed' }
      }
      return data
    })
    const completed = await newTableData.every(
      (data) => data.status === 'completed' || data.status === 'rejected'
    )

    const data = {
      ...order,
      products: newTableData,
      orderStatus: completed ? 'completed' : 'processing',
      updatedAt: new Date(),
      updatedBy: { id: user?.uid, name: user?.name },
    }

    const selectedProduct = order.products.find((data) => data.no === no)

    updateOrder(order?.id, data)
      .then(() => {
        const allValue = {
          ...selectedProduct,
          stock: parseFloat(encodeUnit(selectedProduct?.amount)).toString(),
          branch: selectedProduct?.sendingFromBranch?.id,
          description: `โอนย้ายไปยังสาขา ${order?.branch?.name} จากระบบสั่งสินค้า`,
        }
        const secoundValue = {
          ...selectedProduct,
          stock: parseFloat(encodeUnit(selectedProduct?.amount)).toString(),
          branch: order?.branch.id,
          description:
            selectedProduct?.sendingFromBranch?.id === 'HQ'
              ? `ได้รับการโอนย้ายสินค้าจาก ${selectedProduct?.sendingFromBranch?.name} จากระบบสั่งสินค้า`
              : `ได้รับการโอนย้ายสินค้าจากสาขา ${selectedProduct?.sendingFromBranch?.name} จากระบบสั่งสินค้า`,
        }
        return transferInventory(
          allValue,
          selectedProduct?.sendingFromBranch?.id,
          secoundValue
        )
      })
      .then(() => {
        setIsLoading(false)
        refetch()
        onCloseConfirm()
      })
  }

  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onCloseConfirm()
        }}
        isOpen={isOpenConfirm}
        isCentered
        size='auto'
      >
        <AlertDialogOverlay />
        <AlertDialogContent
          w={{ sm: '20.5rem', md: '25rem' }}
          fontFamily={'Prompt'}
        >
          <AlertDialogHeader>รับสินค้า</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>ต้องการยืนยันรับสินค้า?</AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onCloseConfirm()
              }}
            >
              ยกเลิก
            </Button>
            <Button
              ml={3}
              ref={cancelRef}
              onClick={() => {
                handleConfirm(value?.no)
              }}
              variant='solid'
              colorScheme='btn'
              isLoading={isLoading}
            >
              ยืนยัน
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose()
          setReason('')
        }}
        isOpen={isOpen}
        isCentered
        size='auto'
      >
        <AlertDialogOverlay />
        <AlertDialogContent
          w={{ sm: '20.5rem', md: '25rem' }}
          fontFamily={'Prompt'}
        >
          <AlertDialogHeader>เหตุผลที่ยกเลิก</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Text fontSize={16}>{reason}</Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose()
                setReason('')
              }}
            >
              ปิด
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        motionPreset='slideInBottom'
        onClose={() => {
          deleteOnClose()
          setInput('')
        }}
        isOpen={deleteIsOpen}
        isCentered
        size='auto'
      >
        <AlertDialogOverlay />

        <AlertDialogContent
          w={{ sm: '20.5rem', md: '25rem' }}
          fontFamily={'Prompt'}
        >
          <AlertDialogHeader>คุณแน่ใจ?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <FormControl isRequired isInvalid={isError}>
              <FormLabel>เหตุผลที่ยกเลิกรายการ</FormLabel>
              <Input
                onChange={(e) => setInput(e.target.value)}
                variant='main'
              />
              {isError && (
                <FormErrorMessage>
                  กรุณาใส่เหตุผลที่ยกเลิกรายการ
                </FormErrorMessage>
              )}
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              ไม่
            </Button>
            <Button
              colorScheme='red'
              ml={3}
              onClick={() => {
                !isError && handleReject(value?.no)
              }}
              isLoading={isLoading}
            >
              ใช่
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {value.status === 'processing' ? (
        <HStack spacing={0} mr={2}>
          <Button
            variant='ghost'
            colorScheme='blackAlpha'
            onClick={onOpenConfirm}
          >
            รับสินค้า
          </Button>
          <Button
            variant='ghost'
            colorScheme='blackAlpha'
            onClick={deleteOnOpen}
          >
            ยกเลิก
          </Button>
        </HStack>
      ) : value.status === 'completed' || value.status === 'rejected' ? (
        <HStack mr={2}>
          {value?.status === 'completed' ? (
            <FiCheckCircle fontSize={20} />
          ) : value?.status === 'rejected' ? (
            <AiOutlineCloseCircle fontSize={20} />
          ) : null}
        </HStack>
      ) : null}
      {value?.rejectedNote && (
        <Button
          variant='link'
          onClick={() => {
            setReason(value?.rejectedNote)
            onOpen()
          }}
        >
          หมายเหตุ
        </Button>
      )}
    </>
  )
}

const Status = {
  pending: 'รอดำเนินการ',
  processing: 'ดำเนินการ',
  completed: 'เสร็จสิ้น',
  rejected: 'ถูกยกเลิก',
}

const PurchaseOrderDetail = ({ user }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { PurchaseOrderById, getPurchaseOrderById, updatePurchaseOrder } =
    usePurchaseOrder()
  const { transferInventoryBatch } = useInventory()

  const [isFadePage, setIsFadePage] = useState(false)
  const [note, setNote] = useState('')
  const [tableData, setTableData] = useState([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const [isSubmiting, setIsSubmit] = useState(false)
  const [input, setInput] = useState('')
  const isError = input === ''
  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useDisclosure()

  useEffect(() => {
    if (id) {
      setIsFadePage(false)
      getPurchaseOrderById(id)
    }
  }, [getPurchaseOrderById, id])

  useEffect(() => {
    if (PurchaseOrderById) {
      setNote(PurchaseOrderById?.note)
      if (PurchaseOrderById?.products) {
        setTableData(
          PurchaseOrderById?.products.map((p, i) => ({ ...p, no: i + 1 })) || []
        )
      }
    }
  }, [PurchaseOrderById])

  async function handleCancelAllProduct() {
    setIsSubmit(true)
    const newTableData = await tableData.map((data) => {
      if (data.status === 'processing') {
        delete data.sendingFromBranch
        return { ...data, status: 'rejected', rejectedNote: input }
      }
      return data
    })

    const status = await newTableData.every(
      (data) => data.status === 'rejected'
    )

    const data = {
      ...PurchaseOrderById,
      products: newTableData,
      orderStatus: status ? 'rejected' : 'processing',
      updatedAt: new Date(),
      updatedBy: { id: user?.uid, name: user?.name },
    }

    updatePurchaseOrder(id, data).then(() => {
      getPurchaseOrderById(id)
      setIsSubmit(false)
      onClose()
    })
  }

  async function handleConfirmAll() {
    setIsSubmit(true)
    const newTableData = await tableData.map((data) => {
      if (data.status === 'processing') {
        return { ...data, status: 'completed' }
      }
      return data
    })

    const completed = await newTableData.every(
      (data) => data.status === 'completed' || data.status === 'rejected'
    )

    const data = {
      ...PurchaseOrderById,
      products: newTableData,
      orderStatus: completed ? 'completed' : 'processing',
      updatedAt: new Date(),
      updatedBy: { id: user?.uid, name: user?.name },
    }

    const completedProducts = newTableData.filter(
      (product) => product.status === 'completed'
    )

    // transferInventoryBatch(PurchaseOrderById, completedProducts)
    updatePurchaseOrder(id, data)
      .then(() => transferInventoryBatch(PurchaseOrderById, completedProducts))
      .then(() => {
        getPurchaseOrderById(id)
        setIsSubmit(false)
        onCloseConfirm()
      })
  }

  const columnsDataOrders = [
    {
      Header: 'No.',
      accessor: 'no',
    },
    {
      Header: 'สินค้า',
      accessor: 'name',
      extra: (name, row) => {
        return (
          <Center fontSize={14}>{`${name} ${row?.nameOption || ''}`}</Center>
        )
      },
    },
    {
      Header: 'จำนวน/ปริมาณ',
      accessor: 'amount',
    },
    {
      Header: 'หน่วย',
      accessor: 'unit',
      extra: (unit, row) => {
        return (
          <Text fontSize={14}>
            {unit === 'piece' ? 'ชิ้น' : unit === 'weight' && 'กรัม'}
          </Text>
        )
      },
    },

    {
      Header: 'จัดการ',
      accessor: 'id',
      extra: (data, row) => {
        return (
          <Center>
            <Manage
              order={PurchaseOrderById}
              value={row}
              refetch={() => getPurchaseOrderById(id)}
              updateOrder={updatePurchaseOrder}
              user={user}
            />
          </Center>
        )
      },
    },
  ]

  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onCloseConfirm()
        }}
        isOpen={isOpenConfirm}
        isCentered
        size='auto'
      >
        <AlertDialogOverlay />
        <AlertDialogContent
          w={{ sm: '20.5rem', md: '25rem' }}
          fontFamily={'Prompt'}
        >
          <AlertDialogHeader>รับสินค้าทั้งหมด</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>ต้องการยืนยันรับสินค้าทั้งหมด?</AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onCloseConfirm()
              }}
            >
              ยกเลิก
            </Button>
            <Button
              ml={3}
              ref={cancelRef}
              onClick={() => {
                handleConfirmAll()
              }}
              variant='solid'
              colorScheme='btn'
              isLoading={isSubmiting}
            >
              ยืนยัน
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        motionPreset='slideInBottom'
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size='auto'
      >
        <AlertDialogOverlay />

        <AlertDialogContent
          w={{ sm: '20.5rem', md: '25rem' }}
          fontFamily={'Prompt'}
        >
          <AlertDialogHeader>คุณแน่ใจ?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <FormControl isRequired isInvalid={isError}>
              <FormLabel>เหตุผลที่ยกเลิกรายการทั้งหมด</FormLabel>
              <Input
                onChange={(e) => setInput(e.target.value)}
                variant='main'
              />
              {isError && (
                <FormErrorMessage>
                  กรุณาใส่เหตุผลที่ยกเลิกรายการทั้งหมด
                </FormErrorMessage>
              )}
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              ไม่
            </Button>
            <Button
              colorScheme='red'
              ml={3}
              onClick={() => {
                !isError && handleCancelAllProduct()
              }}
              isLoading={isSubmiting}
            >
              ใช่
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Stack as={Fade} in={!isFadePage}>
        <>
          <Card>
            <Box zIndex='base'>
              <Text
                textAlign={{
                  base: 'center',
                  sm: 'center',
                  md: 'left',
                  lg: 'left',
                }}
                color='#1B2559'
                fontSize='2xl'
                fontWeight='700'
              >
                {`เลขที่ใบสั่งสินค้า ${
                  PurchaseOrderById?.id ? PurchaseOrderById?.id : ''
                }`}
              </Text>
              <Stack
                my='1em'
                spacing={{ base: '0px', sm: '0px', md: '10px' }}
                justifyContent='space-between'
                flexDirection={{
                  base: 'column',
                  sm: 'column',
                  md: 'row',
                  lg: 'row',
                }}
              >
                <SimpleGrid
                  columns={[2, 2, 2, 4]}
                  w='full'
                  gap={2}
                  alignItems='flex-end'
                >
                  <HStack>
                    <Text fontWeight={800}>ผู้สั่ง</Text>
                    <Text>{PurchaseOrderById?.createdBy?.name}</Text>
                  </HStack>
                  <HStack>
                    <Text fontWeight={800}>วันที่สั่ง</Text>
                    {PurchaseOrderById?.createdAt && (
                      <Text>
                        {PurchaseOrderById?.createdAt
                          .toDate()
                          .toLocaleDateString()}
                      </Text>
                    )}
                  </HStack>
                  <HStack>
                    <Text fontWeight={800}>วันที่อัปเดท</Text>
                    {PurchaseOrderById?.updatedAt && (
                      <Text>
                        {PurchaseOrderById?.updatedAt
                          ? PurchaseOrderById?.updatedAt
                              .toDate()
                              .toLocaleDateString()
                          : PurchaseOrderById?.createdAt
                              .toDate()
                              .toLocaleDateString()}
                      </Text>
                    )}
                  </HStack>
                  <HStack>
                    <Text fontWeight={800}>สถานะ</Text>
                    <Text>{Status[PurchaseOrderById?.orderStatus]}</Text>
                  </HStack>
                </SimpleGrid>
                {PurchaseOrderById?.orderStatus !== 'completed' &&
                  PurchaseOrderById?.orderStatus !== 'rejected' && (
                    <HStack
                      display={
                        PurchaseOrderById?.orderStatus === 'pending'
                          ? 'none'
                          : 'flex'
                      }
                      flexDirection='row'
                      alignItems='center'
                      pt={{ sm: '1rem', md: '0rem' }}
                    >
                      <Button
                        h='44px'
                        maxh='44px'
                        w={{ sm: 'full', md: 'full' }}
                        borderRadius='16px'
                        display='flex'
                        colorScheme='blackAlpha'
                        justifyContent={'space-around'}
                        onClick={onOpen}
                      >
                        ยกเลิกทั้งหมด
                      </Button>
                      <Button
                        h='44px'
                        maxh='44px'
                        w={{ sm: 'full', md: 'full' }}
                        colorScheme='btn'
                        borderRadius='16px'
                        display='flex'
                        justifyContent={'space-around'}
                        color='white'
                        onClick={() => onOpenConfirm()}
                      >
                        รับสินค้าทั้งหมด
                      </Button>
                    </HStack>
                  )}
              </Stack>
            </Box>

            <CustomTable
              columnsData={columnsDataOrders}
              tableData={tableData}
              disabledSearch
            />
            {PurchaseOrderById?.orderStatus !== 'completed' &&
              PurchaseOrderById?.orderStatus !== 'rejected' && (
                <FormControl mt={6} w={{ lg: '50%', xl: '30%' }}>
                  <FormLabel>หมายเหตุ</FormLabel>
                  <Textarea
                    id='note'
                    name='note'
                    isReadOnly
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </FormControl>
              )}
            <Box mt={10}>
              <Button
                borderRadius='16px'
                display='flex'
                colorScheme='blackAlpha'
                variant='outline'
                onClick={() => navigate(-1)}
              >
                ย้อนกลับ
              </Button>
            </Box>
          </Card>
        </>
        )
      </Stack>
    </>
  )
}

export default PurchaseOrderDetail
