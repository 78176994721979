// import { calCommission } from 'Utils/calStock'
import moment from 'moment'
import { decodeUnit } from 'Utils/currency'
import { calNetSalesPaidRefund } from './netsales'

const _ = require('underscore')

export function calCommission(item) {
  const total = decodeUnit(item?.grandTotal)
  const commission = decodeUnit(item?.commission)
  if (item?.typeCommission === 'Bath') {
    if (item?.commissionCalType === 'receipt') {
      return commission
    } else if (item?.commissionCalType === 'piece') {
      const amount = decodeUnit(item?.amount)
      return commission * amount
    }
  } else if (item?.typeCommission === 'Percentage') {
    const price = decodeUnit(item?.price)
    const amount = decodeUnit(item?.amount)

    if (item?.commissionCalType === 'receipt') {
      return total * (commission / 100)
    } else if (item?.commissionCalType === 'piece') {
      const calCom = price * (commission / 100)
      return calCom * amount
    }
  }
}

function itemDiscount(i, e) {
  const calDiscount = _.reduce(
    e.discount,
    (memo, num) =>
      num?.valueDiscountType === 'Percentage'
        ? memo + (e?.total * decodeUnit(num?.valueDiscount)) / 100
        : memo + decodeUnit(num?.valueDiscount),
    0
  )

  // const price = decodeUnit(i.grandTotal)
  // const calPercen = price / e.total
  // const calDiscount =
  //   parseFloat(parseFloat(calPercen).toFixed(2)) * e.totalDiscount
  // const totalPrice = parseFloat(parseFloat(calDiscount).toFixed(2))
  // const orderPrice = price - totalPrice
  // const value = Commission(i, orderPrice).toFixed(2)

  // const res = parseFloat(parseFloat(value).toFixed(2))
  const res = calCommissionItem(i, calDiscount, e.grandTotal)

  return res
}

export function Commission(item, value) {
  const commission = decodeUnit(item?.commission)
  if (item?.typeCommission === 'Bath') {
    if (item?.commissionCalType === 'receipt') {
      return commission
    } else if (item?.commissionCalType === 'piece') {
      const amount = decodeUnit(item?.amount)
      return commission * amount
    }
  } else if (item?.typeCommission === 'Percentage') {
    if (item?.commissionCalType === 'receipt') {
      return value * (commission / 100)
    } else if (item?.commissionCalType === 'piece') {
      const calCom = value * (commission / 100)
      return calCom
    }
  }
}

export function calCommissionPaidRefund(order) {
  const paid = _.filter(order, (f) => f.status === 'paid')
  const refund = _.filter(order, (f) => f.status === 'refund')

  // ---------- start paid ---------- //
  const paidDiscount = _.filter(paid, (f) => f.discount)
  const calPaidDiscount = _.map(paidDiscount, (e) =>
    e.items.map((i) => itemDiscount(i, e))
  )
  const resPaidDiscount = parseFloat(
    _.reduce(_.flatten(calPaidDiscount), (memo, num) => memo + num, 0).toFixed(
      2
    )
  )
  const paidNoDiscount = _.filter(paid, (f) => !f.discount)
  const pluckPaidNoDiscount = _.flatten(_.pluck(paidNoDiscount, 'items'))
  const calCommissionPaidNoDiscount = _.reduce(
    pluckPaidNoDiscount.map((e) => calCommission(e)),
    (memo, num) => memo + num,
    0
  )

  // ---------- end paid ---------- //

  // ---------- start refund ---------- //

  const refundDiscount = _.filter(refund, (f) => f.discount)
  const calRefundDiscount = _.map(refundDiscount, (e) =>
    e.items.map((i) => itemDiscount(i, e))
  )
  const resRefundDiscount = parseFloat(
    _.reduce(
      _.flatten(calRefundDiscount),
      (memo, num) => memo + num,
      0
    ).toFixed(2)
  )

  const refundNoDiscount = _.filter(refund, (f) => !f.discount)
  const pluckRefundNoDiscount = _.flatten(_.pluck(refundNoDiscount, 'items'))
  const calCommissionRefundNoDiscount = _.reduce(
    pluckRefundNoDiscount.map((e) => calCommission(e)),
    (memo, num) => memo + num,
    0
  )

  // ---------- end refund ---------- //

  const totalPaid = calCommissionPaidNoDiscount + resPaidDiscount
  const totalRefund = calCommissionRefundNoDiscount + resRefundDiscount
  const total = totalPaid - totalRefund
  return total
}

export function calCommissionItem(item, discount, grand) {
  const ograndTotal = decodeUnit(item?.grandTotal)
  const commission = decodeUnit(item?.commission)
  const amount = decodeUnit(item?.amount)

  const tGrandTotal = grand
  const calGrandTotal = tGrandTotal + discount
  const pPercent = parseFloat(((ograndTotal / calGrandTotal) * 100).toFixed(2))
  const calValue = parseFloat(((pPercent * discount) / 100).toFixed(2))
  const grandTotal = calValue ? ograndTotal - calValue : ograndTotal

  if (item?.typeCommission === 'Bath') {
    if (item?.commissionCalType === 'receipt') {
      return commission
    } else if (item?.commissionCalType === 'piece') {
      return parseFloat((commission * amount).toFixed(2))
    }
  } else if (item?.typeCommission === 'Percentage') {
    if (item?.commissionCalType === 'receipt') {
      return parseFloat(((grandTotal * commission) / 100).toFixed(2))
    } else if (item?.commissionCalType === 'piece') {
      return parseFloat(((grandTotal * commission) / 100).toFixed(2))
    }
  }
}

export function commissionEmployee(order, work, employee) {
  const indexBy = _.groupBy(work, 'uid')

  const groupDate = _.groupBy(order, 'date')
  const date = _.keys(groupDate).map((date) => {
    const groupEmployee = _.groupBy(groupDate[date], 'empId')
    const res = _.keys(groupEmployee)?.map((id) => {
      const time = indexBy[id]
      const groupTimeUser = _.groupBy(time, 'date')

      const groupTimeFilterInSort = _.filter(
        groupTimeUser[date],
        (e) => e.method === 'in'
      ).sort((a, b) => a.time - b.time)[0]

      const groupTimeFilterOutSort = _.filter(
        groupTimeUser[date],
        (e) => e.method === 'out'
      ).sort((a, b) => a.time - b.time)[0]

      const data = groupEmployee[id]?.map((e) => ({
        ...e,
      }))
      const user = employee?.find((i) => i.id === id)
      const commission = user?.commission ? calCommissionPaidRefund(data) : 0
      const grandTotal = calNetSalesPaidRefund(data)
      const grandCommission =
        grandTotal >= 2000 ? commission : commission <= -1 ? commission : 0
      const totalWage = user?.salary + grandCommission

      return {
        id: id,
        date: date,
        timeIn: groupTimeFilterInSort ? groupTimeFilterInSort.dateTime : '-',
        timeOut: groupTimeFilterOutSort ? groupTimeFilterOutSort.dateTime : '-',
        name: user?.name,
        salary: user?.salary,
        grandCommission: grandCommission,
        totalWage: totalWage,
        salaryType: user?.salaryType,
      }
    })
    return res
  })
  // console.log('commissionEmployee res: ', _.flatten(date))
  const data = _.flatten(date)

  return data
}

export const calCommissionEmployeeDailyReport = (orders, employees) => {
  const groupDate = _.groupBy(orders, (i) => {
    return i.empId
  })
  const resTest = _.keys(groupDate)?.map((id) => {
    const data = groupDate[id]?.map((e) => ({
      ...e,
    }))
    const sortOrderDate = _.sortBy(data, 'date')
    const user = employees?.find((i) => i.id === id)
    const commission = user?.commission ? calCommissionPaidRefund(data) : 0
    const grandTotal = calNetSalesPaidRefund(data)
    const grandCommission =
      decodeUnit(grandTotal) >= 2000
        ? commission
        : commission <= -1
        ? commission
        : 0
    const totalWage = user?.salary + grandCommission

    return {
      id: id,
      name: user?.name,
      salary: user?.salary,
      grandCommission: grandCommission,
      totalWage: totalWage,
      date: sortOrderDate[0].date,
      endDate: sortOrderDate.slice(-1)[0].date,
    }
  })
  const data = _.flatten(resTest)

  return data
}

export const calCommissionEmployeeDailyWithEncode = (
  order,
  employee,
  workTime
) => {
  const groupWork = _.groupBy(workTime, 'date')
  // const groupWork = _.groupBy(workTime, (i) => {
  //   return i.date + '#' + i.branch.id
  // })
  const groupDate = _.groupBy(order, (i) => {
    return i.date + '#' + i.branch.id
  })
  const date = _.keys(groupDate).map((date) => {
    const groupEmployee = _.groupBy(groupDate[date], 'empId')
    const res = _.keys(groupEmployee)?.map((id) => {
      const data = groupEmployee[id]?.map((e) => ({
        ...e,
        grandTotal: decodeUnit(e.grandTotal),
        total: decodeUnit(e.total),
        subTotal: decodeUnit(e.subTotal),
        totalDiscount: decodeUnit(e.totalDiscount),
        vat: decodeUnit(e.vat),
      }))
      const user = employee?.find((i) => i.id === id)
      const commission = user?.commission ? calCommissionPaidRefund(data) : 0
      const grandTotal = calNetSalesPaidRefund(data)
      const splitDateAndBranch = date.split('#')
      const filterWorkByUserId = _.filter(
        groupWork[splitDateAndBranch[0]],
        (i) => i.user?.id === id
      )
      const pluckDiff = _.pluck(filterWorkByUserId, 'diff')
      const sumDiff = _.reduce(pluckDiff, (memo, num) => memo + num, 0)
      const grandCommission =
        grandTotal >= 2000 ? commission : commission <= -1 ? commission : 0
      const calSalary =
        sumDiff > 0
          ? sumDiff < 8
            ? (user?.salary / 8) * sumDiff
            : user?.salary
          : 0
      const totalWage = parseFloat(
        parseFloat(
          (sumDiff > 0
            ? calSalary + grandCommission
            : 0 + grandCommission
          ).toFixed(2)
        )
      )

      return {
        id: id,
        date: splitDateAndBranch[0],
        name: user?.name,
        salary: calSalary,
        grandCommission: grandCommission,
        commission: commission,
        totalWage: totalWage,
        branch: splitDateAndBranch[1],
        work: {
          diff: sumDiff > 8 ? 8 : sumDiff,
          in: _.first(groupWork[splitDateAndBranch[0]])?.inTime?.toDate()
            ? _.first(groupWork[splitDateAndBranch[0]])?.inTime?.toDate()
            : null,
          out:
            sumDiff > 8
              ? _.first(groupWork[splitDateAndBranch[0]])?.outTime?.toDate()
                ? moment(
                    _.first(groupWork[splitDateAndBranch[0]])?.outTime?.toDate()
                  ).subtract(sumDiff - 8, 'h')
                : null
              : _.first(groupWork[splitDateAndBranch[0]])?.outTime?.toDate()
              ? _.first(groupWork[splitDateAndBranch[0]])?.outTime?.toDate()
              : null,
        },
        salaryType: user?.salaryType,
      }
    })
    return res
  })

  const data = _.flatten(date)

  return data
}

export const calCommissionEmployeeDailyWithDecode = (
  order,
  employee,
  workTime
) => {
  const groupWork = _.groupBy(workTime, 'date')
  const groupDate = _.groupBy(order, (i) => {
    return i.date + '#' + i.branch.id
  })
  const date = _.keys(groupDate).map((date) => {
    const groupEmployee = _.groupBy(groupDate[date], 'empId')
    const res = _.keys(groupEmployee)?.map((id) => {
      const data = groupEmployee[id]?.map((e) => ({
        ...e,
        grandTotal: decodeUnit(e.grandTotal),
        total: decodeUnit(e.total),
        subTotal: decodeUnit(e.subTotal),
        totalDiscount: decodeUnit(e.totalDiscount),
        vat: decodeUnit(e.vat),
      }))
      const user = employee?.find((i) => i.id === id)
      const commission = user?.commission ? calCommissionPaidRefund(data) : 0
      const grandTotal = calNetSalesPaidRefund(data)
      const splitDateAndBranch = date.split('#')
      const filterWorkByUserId = _.filter(
        groupWork[splitDateAndBranch[0]],
        (i) => {
          return i.user?.id === id
        }
      )
      const pluckDiff = _.pluck(filterWorkByUserId, 'diff')
      const sumDiff = _.reduce(
        pluckDiff,
        (memo, num) => {
          return memo + num
        },
        0
      )

      const grandCommission =
        decodeUnit(grandTotal) >= 2000
          ? commission
          : commission <= -1
          ? commission
          : 0
      const calSalary =
        sumDiff > 0
          ? sumDiff < 8
            ? (user?.salary / 8) * sumDiff
            : user?.salary
          : 0
      const totalWage = parseFloat(
        parseFloat(
          (sumDiff > 0
            ? calSalary + grandCommission
            : 0 + grandCommission
          ).toFixed(2)
        )
      )

      return {
        id: id,
        date: splitDateAndBranch[0],
        name: user?.name,
        salary: calSalary,
        grandCommission: grandCommission,
        commission: commission,
        totalWage: totalWage,
        branch: splitDateAndBranch[1],
        work: {
          diff: sumDiff > 8 ? 8 : sumDiff,
          in: _.first(groupWork[splitDateAndBranch[0]])?.inTime?.toDate()
            ? _.first(groupWork[splitDateAndBranch[0]])?.inTime?.toDate()
            : null,
          out:
            sumDiff > 8
              ? _.first(groupWork[splitDateAndBranch[0]])?.outTime?.toDate()
                ? moment(
                    _.first(groupWork[splitDateAndBranch[0]])?.outTime?.toDate()
                  ).subtract(sumDiff - 8, 'h')
                : null
              : _.first(groupWork[splitDateAndBranch[0]])?.outTime?.toDate()
              ? _.first(groupWork[splitDateAndBranch[0]])?.outTime?.toDate()
              : null,
        },
        salaryType: user?.salaryType,
      }
    })
    return res
  })
  const data = _.flatten(date)
  // console.log('data Decode: ', data)

  return data
}
