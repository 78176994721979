import { auth, db, functions, storage } from 'Config/firebase'
import { useAppContext } from 'Context'
import { sendPasswordResetEmail, signOut } from 'firebase/auth'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { getDownloadURL, ref, uploadString } from 'firebase/storage'
import { useCallback, useState } from 'react'
import { decodeUnit, encodeUnit } from 'Utils'
import _ from 'underscore'
import { useWork } from './work'

const CreateUser = httpsCallable(functions, 'createUser')
const DisabledUser = httpsCallable(functions, 'disabledUser')
const EnabledUser = httpsCallable(functions, 'enabledUser')
const ChangeUserRole = httpsCallable(functions, 'changeUserRole')

export function useAuth() {
  const { setError, setMessage, setUser, user } = useAppContext()
  const { userOffWork } = useWork()
  const [userList, setUserList] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [fetchUserIsLoading, setFetchUserIsLoading] = useState(true)

  const getAllUsers = useCallback(async () => {
    const data = query(
      collection(db, 'Users'),
      where('status', '==', true),
      orderBy('createdAt', 'asc')
    )
    const getData = _.map((await getDocs(data))?.docs, (doc) => {
      return {
        id: doc?.id,
        ...doc?.data(),
        salary: decodeUnit(doc?.data()?.salary),
        rawSalary: doc?.data()?.salary,
      }
    })
    setUserList(getData)
    setFetchUserIsLoading(false)
    //eslint-disable-next-line
  }, [])

  async function getUser(id) {
    const userDoc = doc(db, 'Users', id)
    return (await getDoc(userDoc)).data()
  }

  function createUser(value, avatar) {
    const AllValue = { ...value }
    try {
      return CreateUser(AllValue)
        .then(async (res) => {
          const data = res?.data
          if (data) {
            const userDoc = doc(db, 'Users', data?.id)
            if (avatar) {
              const ImageRef = ref(
                storage,
                `Users/${data?.id}/${new Date().getTime()}`
              )
              uploadString(ImageRef, avatar, 'data_url')
                .then(async () => {
                  return await getDownloadURL(ImageRef)
                    .then((URLs) => {
                      return updateDoc(userDoc, { avatar: URLs })
                        .then(() => {
                          setMessage({
                            type: 'success',
                            message: 'สร้างพนักงานสำเร็จ',
                          })
                        })
                        .catch((errors) => {
                          setError({
                            code: 'staff-update-image-error',
                            message: 'สร้างพนักงานไม่สำเร็จ' + errors.message,
                          })
                        })
                    })
                    .catch((errors) => {
                      setError({
                        code: 'staff-link-image-error',
                        message: 'ไม่สามารถอัปโหลดลิงค์รูปภาพได้',
                      })
                    })
                })
                .catch((errors) => {
                  setError({
                    code: 'staff-upload-image-error',
                    message: 'ไม่สามารถอัปโหลดรูปภาพได้',
                  })
                })
            } else {
              setMessage({
                type: 'success',
                message: 'สร้างพนักงานสำเร็จ',
              })
            }
          } else {
            setError({
              code: 'User Error',
              message: 'สร้างพนักงานไม่สำเร็จ',
            })
          }
        })
        .catch((errors) => {
          switch (errors.message) {
            case 'auth/email-already-exists':
              setError({
                code: 'User Error',
                message: 'อีเมลนี้ถูกใช้งานแล้ว',
              })
              break

            default:
              setError({
                code: 'User Error',
                message: errors.message,
              })
          }
        })
    } catch (error) {
      setError({ code: 'User Error', message: error?.message })
    }
  }

  async function updateUser(value, avatar, id) {
    const AllValue = {
      ...value,
      salary: encodeUnit(value?.salary),
      updatedAt: new Date(),
    }
    const UserDocs = doc(db, 'Users', id)
    const getUser = await (await getDoc(UserDocs)).data()
    if (getUser && value.role !== getUser.role) {
      ChangeUserRole({ userId: id, role: value.role }).then(() => {
        if (getUser?.branchSelect?.id === 'HQ') {
          updateDoc(UserDocs, { branchSelect: null })
        }
      })
    }
    try {
      return updateDoc(UserDocs, { ...AllValue }).then(async () => {
        const userDoc = doc(db, 'Users', id)
        if (avatar) {
          try {
            const ImageRef = ref(storage, `Users/${id}/${new Date().getTime()}`)
            uploadString(ImageRef, avatar, 'data_url').then(async () => {
              const downloadURL = await getDownloadURL(ImageRef)
              return updateDoc(userDoc, { avatar: downloadURL }).then(() => {
                return setMessage({
                  type: 'success',
                  message: 'แก้ไขข้อมูลพนักงานสำเร็จ',
                })
              })
            })
          } catch (error) {
            setError({ code: 'User Error', message: error?.message })
          }
        } else {
          return setMessage({
            type: 'success',
            message: 'แก้ไขข้อมูลพนักงานสำเร็จ',
          })
        }
      })
    } catch (error) {
      setError({ code: 'User Error', message: error?.message })
    }
  }

  function disabledUser(id) {
    try {
      return DisabledUser(id).then(async () => {
        const userDoc = doc(db, 'Users', id)
        return updateDoc(userDoc, { status: false })
      })
    } catch (error) {
      setError({ code: 'User Error', message: error?.message })
    }
  }

  function enabledUser(id) {
    try {
      return EnabledUser(id).then(async () => {
        const userDoc = doc(db, 'Users', id)
        return updateDoc(userDoc, { status: true })
      })
    } catch (error) {
      setError({ code: 'User Error', message: error?.message })
    }
  }

  function logout() {
    const userDoc = doc(db, 'Users', user?.uid)
    if (userDoc.get) {
      try {
        if (user?.role === 'Administrator') {
          return signOut(auth).then(() => {
            return setUser()
          })
        } else {
          if (user?.branchSelect) {
            return updateDoc(userDoc, {
              branchSelect: null,
              updatedAt: new Date(),
            }).then(() => {
              return signOut(auth).then(() => {
                return setUser()
              })
            })
          } else {
            return updateDoc(userDoc, {
              branchSelect: null,
              updatedAt: new Date(),
            }).then(() => {
              return signOut(auth).then(() => {
                return setUser()
              })
            })
          }
        }
      } catch (error) {
        setError({ code: 'Logout Error', message: error })
      }
    } else {
      return signOut(auth).then(() => {
        return setUser()
      })
    }
  }

  const userSignOutBranch = async () => {
    const userDoc = doc(db, 'Users', user?.uid)
    try {
      if (user?.role === 'Administrator') {
        return updateDoc(userDoc, {
          branchSelect: null,
          updatedAt: new Date(),
        })
      } else {
        if (user?.workRef) {
          userOffWork()
        }
        return updateDoc(userDoc, {
          branchSelect: null,
          updatedAt: new Date(),
        })
      }
    } catch (error) {
      setError({
        code: 'User',
        message: error.message,
      })
    }
  }

  async function userSelectBranch(
    userid,
    branchId,
    branchName,
    branchType,
    wholeSale
  ) {
    const userDoc = doc(db, 'Users', userid)
    try {
      if (user?.role === 'Administrator') {
        return await updateDoc(userDoc, {
          branchSelect: {
            id: branchId,
            name: branchName,
            type: branchType,
            wholeSale: wholeSale,
          },
          updatedAt: new Date(),
        })
      } else {
        return await updateDoc(userDoc, {
          branchSelect: {
            id: branchId,
            name: branchName,
            type: branchType,
            wholeSale: wholeSale,
          },
          updatedAt: new Date(),
        })
      }
    } catch (error) {
      setError({
        code: 'User',
        message: error.message,
      })
    }
  }

  const resetPassword = async (email) => {
    try {
      return await sendPasswordResetEmail(auth, email).then(() => {
        setMessage({
          type: 'success',
          message: 'Password reset email sent!',
        })
      })
    } catch (error) {}
  }

  return {
    userList,
    isLoading,
    fetchUserIsLoading,
    getAllUsers,
    setLoading,
    logout,
    createUser,
    updateUser,
    disabledUser,
    enabledUser,
    getUser,
    userSelectBranch,
    resetPassword,
    userSignOutBranch,
  }
}
