import {
  Badge,
  Box,
  Center,
  Flex,
  HStack,
  Icon,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Divider } from 'antd'
import moment from 'moment'
import { CgLoadbarDoc } from 'react-icons/cg'
import { Link } from 'react-router-dom'
import { currency, decodeUnit } from 'Utils/currency'
import { billStatus } from 'Utils/status'
import { BsCashCoin } from 'react-icons/bs'
import { RiBankFill } from 'react-icons/ri'
import { useOrder } from 'Hooks'
import { posType } from 'Utils/json'

export const OrderCard = (props) => {
  const { statusSuccessOrder } = useOrder()
  const { statusMessage } = billStatus()
  const { indexInItem, inItem, path } = props

  const handleSuccess = (item) => {
    return statusSuccessOrder({ ...item, success: !item.success })
  }
  return (
    <>
      <Flex
        key={indexInItem}
        flexDirection='column'
        state={{ page: 'weekHistory' }}
        _hover={{
          bg: '#F2F2F2',
          transition: 'all 0.4s',
        }}
        transition='all 0.4s'
        px={{ sm: '0rem', md: '1rem' }}
      >
        <HStack // flexDirection='column'
          mt={{
            base: '1.5rem',
            sm: '1.5rem',
            md: '1.5rem',
          }}
          w='100%'
          justifyContent='space-between'
        >
          <Flex
            w='100%'
            as={Link}
            to={
              path
                ? `/pos/history/datahistory/${inItem?.id}/?path=${path}`
                : `/pos/history/datahistory/${inItem?.id}`
            }
            justifyContent={'space-between'}
            alignItems={{
              base: 'flex-start',
              sm: 'flex-start',
              md: 'center',
            }}
          >
            <HStack
              alignItems='center'
              spacing={{
                base: '0.5rem',
                sm: '0.5rem',
                md: '1rem',
              }}
            >
              <Center w={{ sm: '3rem', md: 'auto' }}>
                <Center
                  border='2px'
                  borderColor={
                    inItem?.payment === 'cash'
                      ? '#60101C'
                      : inItem?.payment === 'bank'
                      ? '#c99e6e'
                      : '#c99e6e'
                  }
                  w={{
                    base: '2.5rem',
                    sm: '2.5rem',
                    md: '3rem',
                  }}
                  h={{
                    base: '2.5rem',
                    sm: '2.5rem',
                    md: '3rem',
                  }}
                  borderRadius='full'
                >
                  <Icon
                    as={
                      inItem?.payment === 'cash'
                        ? BsCashCoin
                        : inItem?.payment === 'bank'
                        ? RiBankFill
                        : CgLoadbarDoc
                    }
                    w={{
                      base: '1.3rem',
                      sm: '1.3rem',
                      md: '1.5rem',
                    }}
                    h={{
                      base: '1.3rem',
                      sm: '1.3rem',
                      md: '1.5rem',
                    }}
                    // color='#c99e6e'
                    color={
                      inItem?.payment === 'cash'
                        ? '#60101C'
                        : inItem?.payment === 'bank'
                        ? '#c99e6e'
                        : '#c99e6e'
                    }
                  />
                </Center>
              </Center>
              <Flex flexDirection='column' w={{ sm: 'auto', md: 'auto' }}>
                <HStack>
                  <Text
                    fontSize={{
                      base: '14px',
                      sm: '14px',
                      md: '16px',
                    }}
                    fontWeight={{
                      sm: 'normal',
                      md: 'bold',
                    }}
                  >
                    เลขที่ใบสั่งซื้อ: {inItem?.orderId}
                  </Text>
                  <Text
                    display={{
                      base: 'none',
                      sm: 'none',
                      md: 'flex',
                      lg: 'flex',
                    }}
                  >
                    {inItem?.status === 'refund' && (
                      <Text color='red.300' w='max-content' fontSize='xs'>
                        <Badge colorScheme={'red'}>
                          {statusMessage(inItem?.status)}
                        </Badge>
                      </Text>
                    )}
                  </Text>
                </HStack>
                <Text
                  fontSize={{
                    base: '12px',
                    sm: '12px',
                    md: '14px',
                  }}
                  color='#A3AED0'
                >
                  {moment(inItem?.createdAt?.toDate()).format(
                    'MM/DD/YYYY HH:mm:ss'
                  )}{' '}
                  ({inItem?.createdBy?.name})
                </Text>

                {inItem?.note && (
                  <Text
                    fontSize={{
                      base: '14px',
                      sm: '14px',
                      md: '16px',
                    }}
                    color='#A3AED0'
                  >
                    Note: {inItem?.note}
                  </Text>
                )}

                {inItem?.customerType && (
                  <Text
                    fontSize={{
                      base: '14px',
                      sm: '14px',
                      md: '16px',
                    }}
                    color='#A3AED0'
                  >
                    ประเภทผู้ซื้อ:{' '}
                    {inItem?.customerType
                      ? posType?.default?.find(
                          (x) => x.id === inItem?.customerType
                        )?.name
                      : ''}
                  </Text>
                )}

                <Text color='#A3AED0'>
                  สถานะ:&nbsp;
                  <Badge colorScheme={inItem?.success ? 'green' : 'yellow'}>
                    {inItem?.success ? 'ดำเนินการแล้ว' : 'รอดำเนินการ'}
                  </Badge>
                </Text>
              </Flex>
            </HStack>

            <HStack
              display={{
                base: 'none',
                md: 'block',
              }}
            >
              <HStack
                // spacing={{
                //   base: '0.5rem',
                //   sm: '0.5rem',
                //   md: '2rem',
                // }}
                flexDirection='column'
                alignItems='flex-end'
              >
                <Text
                  color={inItem.status === 'refund' ? '#FF6501' : 'black'}
                  fontWeight='700'
                  fontSize={{
                    base: '14px',
                    sm: '14px',
                    md: '16px',
                  }}
                >
                  {inItem?.status === 'refund'
                    ? `-${currency(decodeUnit(inItem?.grandTotal), 2, true)}`
                    : currency(decodeUnit(inItem?.grandTotal), 2, true)}
                </Text>
                <Text
                  display={{
                    base: 'flex',
                    sm: 'flex',
                    md: 'none',
                    lg: 'none',
                  }}
                >
                  {inItem?.status === 'refund' && (
                    <Text color='red.300' w='max-content' fontSize='xs'>
                      <Badge colorScheme={'red'}>
                        {statusMessage(inItem?.status)}
                      </Badge>
                    </Text>
                  )}
                </Text>
              </HStack>
            </HStack>
          </Flex>

          <Center>
            <VStack>
              <HStack
                display={{
                  base: 'block',
                  md: 'none',
                }}
              >
                <HStack
                  // spacing={{
                  //   base: '0.5rem',
                  //   sm: '0.5rem',
                  //   md: '2rem',
                  // }}
                  flexDirection='column'
                  alignItems='flex-end'
                >
                  <Text
                    color={inItem.status === 'refund' ? '#FF6501' : 'black'}
                    fontWeight='700'
                    fontSize={{
                      base: '14px',
                      sm: '14px',
                      md: '16px',
                    }}
                  >
                    {inItem?.status === 'refund'
                      ? `-${currency(decodeUnit(inItem?.grandTotal), 2, true)}`
                      : currency(decodeUnit(inItem?.grandTotal), 2, true)}
                  </Text>
                  <Text
                    display={{
                      base: 'flex',
                      sm: 'flex',
                      md: 'none',
                      lg: 'none',
                    }}
                  >
                    {inItem?.status === 'refund' && (
                      <Text color='red.300' w='max-content' fontSize='xs'>
                        <Badge colorScheme={'red'}>
                          {statusMessage(inItem?.status)}
                        </Badge>
                      </Text>
                    )}
                  </Text>
                </HStack>
              </HStack>
              <Box pl={{ base: '0rem', sm: '0rem', md: '1rem', lg: '1.5rem' }}>
                <Switch
                  size='lg'
                  mt='-0.65rem'
                  colorScheme='btn'
                  isChecked={inItem?.success}
                  onChange={() => handleSuccess(inItem)}
                  isDisabled={
                    inItem?.refundAmount >= inItem?.totalAmount
                      ? true
                      : inItem?.status === 'refund'
                  }
                />
              </Box>
            </VStack>
          </Center>
        </HStack>
        <Divider mt={'1em'} />
      </Flex>
    </>
  )
}
