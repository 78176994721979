import { ChevronDownIcon } from '@chakra-ui/icons'
import { HStack, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import ReactDatePicker from 'react-datepicker'

export default function DateTime({
  setStartDateTime,
  setEndDateTime,
  startDateTime,
  endDateTime,
}) {
  return (
    <HStack spacing='0.5rem' w='full' mt='0.5rem'>
      <InputGroup>
        <Input
          bg='#F4F7FE'
          variant={'no-effects'}
          _focus={{ border: '0px solid #F4F7FE' }}
          borderRadius='16px'
          cursor='pointer'
          border='0px'
          w='full'
          textAlign='center'
          as={ReactDatePicker}
          selected={startDateTime}
          onChange={(date) => setStartDateTime(date)}
          selectsStart
          startDate={startDateTime}
          endDate={endDateTime}
          placeholderText={' '}
          timeInputLabel='Time:'
          dateFormat='MM/dd/yyyy h:mm aa'
          showTimeSelect
        />
        <InputRightElement
          zIndex='base'
          children={<ChevronDownIcon color='#8F9BB' zIndex='base' />}
        />
      </InputGroup>
      <InputGroup>
        <Input
          bg='#F4F7FE'
          borderRadius='16px'
          cursor='pointer'
          border='0px'
          variant={'no-effects'}
          _focus={{ border: '0px solid #F4F7FE' }}
          w='full'
          textAlign='center'
          as={ReactDatePicker}
          selected={endDateTime}
          onChange={(date) => setEndDateTime(date)}
          selectsEnd
          startDate={startDateTime}
          endDate={endDateTime}
          minDate={startDateTime}
          placeholderText={' '}
          timeInputLabel='Time:'
          dateFormat='MM/dd/yyyy h:mm aa'
          showTimeSelect
        />
        <InputRightElement
          zIndex='base'
          children={<ChevronDownIcon zIndex='base' color='#8F9BB' />}
        />
      </InputGroup>
    </HStack>
  )
}
