import { calCommissionPaidRefund } from './commission'
import { calOrderCostPaidRefund } from './cost'
import { calNetSales } from './netsales'
import { calSellingPriceOrder, calSP } from './sellingPrice'

const _ = require('underscore')

export function calProfit(order) {
  const cost = calSellingPriceOrder(order).cost
  const netSell = calNetSales(order).total

  // ขาด Commission

  return {
    total: netSell - cost,
  }
}

export function calProfitPaidRefund(order) {
  const paid = _.filter(order, (itm) => itm.status === 'paid')
  const refund = _.filter(order, (itm) => itm.status === 'refund')

  // ---------------- start paid ---------------- //
  const costPaid = calSP(paid).cost
  const netSellPaid = calSP(paid).total
  // ---------------- end paid ---------------- //

  // ---------------- start refund ---------------- //
  const costRefund = calSP(refund).cost
  const netSellRefund = calSP(refund).total
  // ---------------- end refund ---------------- //

  const commission = calCommissionPaidRefund(order)

  const totalCost = costPaid - costRefund
  const totalNetSell = netSellPaid - netSellRefund

  const total = parseFloat((totalNetSell - (totalCost + commission)).toFixed(2))

  return total
}

export function calProfitPaidRefundNoCommission(order) {
  const paid = _.filter(order, (itm) => itm.status === 'paid')
  const refund = _.filter(order, (itm) => itm.status === 'refund')

  // ---------------- start paid ---------------- //
  const costPaid = calOrderCostPaidRefund(paid)
  const netSellPaid = calSP(paid).total
  // ---------------- end paid ---------------- //

  // ---------------- start refund ---------------- //
  const costRefund = calOrderCostPaidRefund(refund)
  const netSellRefund = calSP(refund).total
  // ---------------- end refund ---------------- //

  const totalCost = costPaid - costRefund
  const totalNetSell = netSellPaid - netSellRefund

  const total = parseFloat((totalNetSell - totalCost).toFixed(2))

  return total
}
