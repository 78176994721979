export const billStatus = () => {
  const statusMessage = (status) => {
    switch (status) {
      case 'paid':
        return 'ชำระเงินแล้ว'
      case 'refund':
        return 'คืนเงิน'
      case 'payLater':
        return 'ชำระภายหลัง'
      default:
        return ''
    }
  }

  const paymentMessage = (status) => {
    switch (status) {
      case 'cash':
        return 'เงินสด'
      case 'bank':
        return 'ธนาคาร'
      case 'payLater':
        return 'ชำระภายหลัง'
      case 'online':
        return 'ช่องทางออนไลน์'
      default:
        return ''
    }
  }

  return {
    statusMessage,
    paymentMessage,
  }
}

export const poStatus = (status) => {
  switch (status) {
    case 'backOrder':
      return 'รอเติมสินค้า'
    case 'discontinues':
      return 'ยกเลิกการขาย'
    default:
      return ''
  }
}

export const currencyMessage = () => {
  const unitMessage = (status) => {
    switch (status) {
      case 'free':
        return 'ฟรี'
      case 'Bath':
        return 'บาท'
      case 'Percentage':
        return 'เปอร์เซ็นต์'
      default:
        return ''
    }
  }
  return {
    unitMessage,
  }
}
