import {
  Box,
  Center,
  Button,
  VStack,
  Stack,
  Text,
  HStack,
  SkeletonText,
} from '@chakra-ui/react'
import { useProduct } from 'Hooks'
import { useEffect, useState } from 'react'

import { currency, decodeUnit, currencyMessage } from 'Utils'
import {
  checkPromotionCondition,
  checkPromotionToUse,
} from 'Utils/calFreePromotion'
import { promotionCheckFreeCondition } from 'Utils/promotion'
const _ = require('underscore')

function PromotionCard({ data, addPromotion, promotionCount }) {
  const { unitMessage } = currencyMessage()
  return data?.map((item, itemKey) => (
    <Box
      as='label'
      key={itemKey}
      px={{ base: '0.3rem', sm: '0,3rem', md: '0rem' }}
    >
      <Box
        onClick={() => addPromotion(item)}
        fontSize={'14px'}
        w={'full'}
        textAlign='start'
        cursor='pointer'
        borderBottom='1px'
        //borderRadius='0.5rem'
        //boxShadow='md'
        borderLeft='0px'
        borderRight='0px'
        //color={promotionCount[item.id] ? 'pos.500' : '#8F8F8F'}
        borderBottomColor={promotionCount[item?.id] ? '#EBF8FF' : '#EBF8FF'}
        _checked={{
          bg: '#EBF8FF',
          color: 'pos.100',
          //borderColor: 'pos.100',
        }}
        _focus={{
          borderColor: 'none',
        }}
        px={5}
        py={2}
      >
        <HStack justifyContent={'space-between'}>
          <HStack spacing='1rem'>
            <Text w={'40px'} h='100%' color='black'>
              {promotionCount[item?.id] ? `x${promotionCount[item?.id]}` : 0}
            </Text>
            <VStack alignItems={'start'} spacing={1} w='100%'>
              <Text
                // fontWeight='bold'
                fontSize='16px'
                w='100%'
                textAlign='start'
                color='#60101C'
              >
                ชื่อส่วนลด :&nbsp;{item?.discountName}
              </Text>
              <Text color='	#808080' w='100%' textAlign='start'>
                รายละเอียด :&nbsp;
                {item?.discountDetail ? item?.discountDetail : '-'}
              </Text>
            </VStack>
          </HStack>
          <VStack w='5rem'>
            <Text color='#FF6501' w='100%' textAlign='end'>
              {item?.type === 'discount'
                ? item?.valueDiscountType === 'Bath'
                  ? currency(
                      parseFloat(decodeUnit(item?.valueDiscount)),
                      2,
                      true
                    )
                  : item?.valueDiscountType === 'Percentage'
                  ? `${currency(
                      parseFloat(decodeUnit(item?.valueDiscount)),
                      0
                    )}%`
                  : null
                : item?.type === 'free'
                ? unitMessage(item?.type)
                : '-'}
            </Text>
          </VStack>
        </HStack>
      </Box>
    </Box>
  ))
}
function PosCoupons(props) {
  const {
    setPosUseDiscount,
    setPaymentStep,
    posUseDiscount,
    activePromotion,
    cartItems,
    stockProduct,
    addPromotionCartFreeItems,
  } = props
  const [promotionItems, setPromotionItems] = useState([])
  const [isLoadingPromotion, setIsLoadingPromotion] = useState(true)
  const [promotionsUseCount, setPromotionUseCount] = useState([])
  const { branchProductStock } = useProduct()

  useEffect(() => {
    const getCount = _.countBy(posUseDiscount, 'id')
    setPromotionUseCount(getCount)
  }, [posUseDiscount])

  useEffect(() => {
    if (activePromotion) {
      const filterPromoCodeStatus = _.filter(activePromotion, (promoItem) => {
        return !promoItem.discountCodeStatus
      })

      if (filterPromoCodeStatus && cartItems.length > 0) {
        const filterData = _.filter(filterPromoCodeStatus, (i) => {
          if (checkPromotionToUse(cartItems, i)) {
            return i
          }
        })
        const filterFreeItem = _.filter(filterData, (i) => {
          return i?.productFree
        })
        const filterNoFreeItem = _.filter(filterData, (i) => {
          return !i?.productFree
        })
        const newFreeItems = _.map(filterFreeItem, (i) => {
          const findItemInStock = _.find(branchProductStock, (item) => {
            return item?.id === i?.productFree?.id
          })
          if (
            parseFloat(findItemInStock?.total) >=
            parseFloat(decodeUnit(i?.productAmount))
          ) {
            return i
          }
        })
        const dataPromotion = [...newFreeItems, ...filterNoFreeItem]
        setPromotionItems(dataPromotion)
      }
    }
    setIsLoadingPromotion(false)
  }, [activePromotion, cartItems, branchProductStock])

  const addPromotion = async (promotion) => {
    if (promotionCheckFreeCondition(promotion)) {
      const promotionCheck = checkPromotionCondition(
        cartItems,
        stockProduct,
        promotion,
        posUseDiscount
      )

      if (promotionCheck) {
        addPromotionCartFreeItems(
          promotionCheck,
          promotionCheck.cart.promotionsToCart,
          promotionCheck.cart.free,
          promotionCheck.cart.amount
        )

        const fltternPromotions = _.flatten(promotionCheck.discounts)
        setPosUseDiscount([...fltternPromotions, { ...promotion }])
      }
    } else {
      setPosUseDiscount([...posUseDiscount, { ...promotion }])
    }
  }

  return (
    <>
      <VStack
        w={'full'}
        mt={'0.5rem'}
        p={'0.25rem'}
        spacing={'2rem'}
        pb={{ base: '3.5rem', sm: '3.5rem', md: '0.5rem' }}
      >
        {/* <InputGroup>
          <Input
            variant='search'
            fontSize='sm'
            bg={'secondaryGray.300'}
            color={'gray.700'}
            fontWeight='500'
            _placeholder={{
              color: 'gray.400',
              fontSize: '14px',
              opacity: 1,
            }}
            borderRadius={'30px'}
            placeholder={'กรอกรหัสโปรโมชั่น'}
            value={searchPromotion}
            onChange={(e) => {
              setSearchPromotion(e.target.value)
            }}
          />

          <InputRightAddon
            w='max-content'
            bg={'secondaryGray.300'}
            borderRadius='30px'
            border='0px'
            children={
              <IconButton
                onClick={() => {}}
                border='0px'
                borderLeft='1px solid #E0E5F2'
                borderLeftRadius='0px'
                bg={'secondaryGray.300'}
                _hover='none'
                w='max-content'
                _focus={{
                  boxShadow: 'none',
                }}
                icon={<AiOutlineSearch />}
                fontSize='22px'
              />
            }
          />
        </InputGroup> */}

        <VStack
          zIndex={{ base: 'overlay', sm: 'overlay', md: 'overlay', lg: 'base' }}
          h={{ base: '72%', sm: '72%', md: '50vh' }}
          overflowX='auto'
          mb={{ base: '0rem', sm: '0rem', md: '0rem', lg: '1rem' }}
          w={'full'}
        >
          {!isLoadingPromotion ? (
            promotionItems?.length > 0 ? (
              <Stack w={'full'}>
                <PromotionCard
                  data={promotionItems}
                  addPromotion={addPromotion}
                  promotionCount={promotionsUseCount}
                />
              </Stack>
            ) : (
              <Box>
                <Text>ไม่มีโปรโมชั่นส่วนลด</Text>
              </Box>
            )
          ) : (
            <Box
              fontSize={'14px'}
              w={'full'}
              textAlign='start'
              borderRadius='0.5rem'
            >
              <SkeletonText mt='4' noOfLines={2} spacing='4' />
              <SkeletonText mt='4' noOfLines={2} spacing='4' />
              <SkeletonText mt='4' noOfLines={2} spacing='4' />
            </Box>
          )}
        </VStack>
      </VStack>
      <Center
        pt={{ base: 'none', md: '0rem' }}
        display={{
          base: 'flex',
          sm: 'flex',
          md: 'flex',
          lg: 'flex',
          xl: 'flex',
          '2xl': 'flex',
        }}
        position={{ base: 'fixed', md: 'relative' }}
        h={{ base: 'auto', md: 'auto' }}
        bottom='0px'
        w='100%'
        alignItems={{ base: 'flex-end', md: 'none' }}
        zIndex='overlay'
      >
        <Box p='0.3rem' w='100%'>
          <Button
            w='100%'
            colorScheme='btn'
            py='1.7rem'
            borderRadius='5px'
            onClick={() => {
              setPaymentStep({ cart: true })
            }}
          >
            ยืนยันโปรโมชั่นส่วนลด
          </Button>
        </Box>
      </Center>
    </>
  )
}

export default PosCoupons
