import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react'
import { db } from 'Config/firebase'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'
import { useCategory } from 'Hooks'
import { useEffect, useState } from 'react'
import { MdHideImage } from 'react-icons/md'
import { search } from 'ss-search'
import { decodeUnit } from 'Utils'
import { BtnModal } from './btnmodal'

const _ = require('underscore')
function RadioCard({ discount, onClose, product, handleClick, ...props }) {
  const data = product.find((item) => item.id === props.value)
  const { getInputProps, getCheckboxProps } = useRadio(props)
  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Center as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        borderWidth='1px'
        borderRadius='6px'
        boxShadow='md'
        opacity='1'
        _checked={{
          bg: 'table.100',
          color: 'white',
          borderColor: 'table.100',
          opacity: '1',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        w='full'
        h='auto'
        onClick={() => {
          handleClick(data)
          //   setDiscount({
          //     ...discount,
          //     product: data,
          //     productAmount: data.unit === 'weight' ? 0 : 1,
          //   })
          onClose()
        }}
      >
        <Box w='full'>
          {data.img !== null ? (
            <Image
              borderTopRadius={'6px'}
              h={{
                base: '6rem',
                sm: '6rem',
                md: '6rem',
                lg: '6rem',
                xl: '9rem',
              }}
              w='full'
              objectFit='cover'
              src={data?.img ? data?.img : '/gogreen/product_Thumbnail.png'}
            />
          ) : (
            <Center
              borderTopRadius={'6px'}
              h={{
                base: '6rem',
                sm: '6rem',
                md: '6rem',
                lg: '6rem',
                xl: '9rem',
              }}
              w='full'
            >
              <MdHideImage />
            </Center>
          )}
        </Box>
        <Flex flexDirection='column'>
          <Text textAlign='start' pl='0.3rem'>
            {data?.name}
          </Text>
          <Text textAlign='start' w='100%' pl='0.3rem' mt='-0.3rem'>
            รหัสสินค้า : {data?.sku}
          </Text>
          <Text textAlign='start' color='green' w='100%' pl='0.3rem'>
            {new Intl.NumberFormat('th-TH', {
              minimumFractionDigits: 2,
            }).format(decodeUnit(data?.price))}
          </Text>
        </Flex>
      </Box>
    </Center>
  )
}

export function InfoModal({ discount, label, handleClick, children }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [onSearch, setOnSearch] = useState('')
  const [product, setProduct] = useState([])
  const [data, setData] = useState(null)
  const [optionsSearch, setOptionsSearch] = useState({
    name: '',
    category: '',
  })
  const { category } = useCategory()
  useEffect(() => {
    const qProduct = query(
      collection(db, 'Products'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(qProduct, (snapShot) => {
      let productValue = []
      snapShot.docs.forEach((doc) => {
        return productValue.push({
          ...doc.data(),
          id: doc.id,
          name: doc.data()?.nameOption
            ? doc.data()?.nameOption
            : doc.data()?.name,
        })
      })
      const searchText = onSearch
      const searchKey = ['name', 'nameOption', 'sku']
      const searchResuts = optionsSearch?.category
        ? productValue?.filter((f) => f.category === optionsSearch.category)
        : productValue
      const results = search(searchResuts, searchKey, searchText)
      setProduct(results)
    })
    return () => {
      unsubscribe()
    }
  }, [onSearch, optionsSearch])

  const options = _.pluck(product, 'id')

  const handleChange = (value) => {
    const dataValue = product.find((item) => item.id === value)
    setData(dataValue)
  }
  const { getRootProps, getRadioProps } = useRadioGroup({
    onChange: handleChange,
    defaultValue: discount?.product?.id || '',
  })

  const group = getRootProps()
  return (
    <Grid templateColumns='repeat(2, 1fr)' gap={6}>
      <GridItem>{children}</GridItem>
      <GridItem h='100%'>
        <Center h='100%'>
          <BtnModal
            color='form.500'
            onClick={() => {
              setOnSearch('')
              setOptionsSearch('')
              onOpen()
            }}
            label={label}
          />
        </Center>
      </GridItem>
      <Modal isOpen={isOpen} onClose={onClose} size='auto'>
        <ModalOverlay />
        <ModalContent w={{ base: '100%', sm: '100%', md: '35rem' }}>
          <ModalHeader color='form.500'>
            เลือกสินค้าจัดโปรโมชั่นส่วนลด
            <HStack
              spacing={{ base: '0rem', sm: '0rem', md: '0rem', lg: '1rem' }}
              mt='20px'
              mb={{ md: '0px', lg: '0px', xl: '10px', '2xl': '10px' }}
              flexDirection={{
                base: 'column',
                sm: 'column',
                md: 'column',
                lg: 'row',
              }}
              align={{ base: 'start', md: 'center' }}
              justifyContent='flex-start'
              w='100%'
              zIndex='base'
            >
              <InputGroup w='100%'>
                <InputLeftElement
                  children={
                    <IconButton
                      bg='inherit'
                      borderRadius='inherit'
                      _hover='none'
                      _active={{
                        bg: 'inherit',
                        transform: 'none',
                        borderColor: 'transparent',
                      }}
                      _focus={{
                        boxShadow: 'none',
                      }}
                      icon={<SearchIcon color={'gray.700'} w='15px' h='15px' />}
                    ></IconButton>
                  }
                />
                <Input
                  variant='search'
                  fontSize='sm'
                  bg={'secondaryGray.300'}
                  color={'gray.700'}
                  fontWeight='500'
                  _placeholder={{
                    color: 'gray.400',
                    fontSize: '14px',
                    opacity: 1,
                  }}
                  borderRadius={'30px'}
                  placeholder={'Search...'}
                  onChange={(e) => {
                    setOnSearch(e.target.value)
                  }}
                />
              </InputGroup>
              <Flex
                align='center'
                pt={{ base: '10px', sm: '10px', md: '10px', lg: '0px' }}
                w='100%'
              >
                <Select
                  color={'gray.700'}
                  w='100%'
                  bg={'secondaryGray.300'}
                  border='0px'
                  fontWeight='500'
                  borderRadius={'30px'}
                  defaultValue=''
                  variant='main'
                  placeholder={'สินค้าทั้งหมด'}
                  _placeholder={{
                    fontWeight: '400',
                  }}
                  value={optionsSearch?.category}
                  h='44px'
                  maxh='44px'
                  onChange={(e) => {
                    setOptionsSearch({
                      ...optionsSearch,
                      category: e.target.value,
                    })
                  }}
                >
                  {category?.map((item, i) => {
                    return (
                      <option value={item?.id} key={i}>
                        {item?.name}
                      </option>
                    )
                  })}
                </Select>
              </Flex>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            overflowY='auto'
            maxH={{ base: '25rem', sm: '25rem', md: '40rem' }}
          >
            <Box h={{ base: '25rem', sm: '25rem', md: '40rem' }}>
              <Grid
                templateColumns='repeat(2, 1fr)'
                gap={{ base: '15px', sm: '15px', md: '20px' }}
                {...group}
              >
                {options.map((value) => {
                  const radio = getRadioProps({ value })
                  return (
                    <GridItem key={value}>
                      <RadioCard
                        {...radio}
                        onClose={onClose}
                        product={product}
                        discount={discount}
                        data={data}
                        handleClick={handleClick}
                      />
                    </GridItem>
                  )
                })}
              </Grid>
            </Box>
          </ModalBody>

          <ModalFooter w='100%'></ModalFooter>
        </ModalContent>
      </Modal>
    </Grid>
  )
}
