import { Center } from '@chakra-ui/react'
import ChangePassword from 'Components/form/Account/changePassword'
import Card from 'Components/Horizons/card/Card'

function AccountSettings({ user }) {
  return (
    <>
      <Center>
        <Card
          w={{ sm: '100vw', md: '670px', lg: '850px' }}
          mx={{ base: 'auto', md: '0px', lg: 'auto' }}
          p={{ base: '15px', sm: '15px', md: '30px' }}
          px={{ base: '30px', sm: '30px', md: '30px' }}
        >
          <ChangePassword user={user} />
        </Card>
      </Center>
    </>
  )
}

export default AccountSettings
