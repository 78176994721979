import { Center, Flex, HStack, Icon, Text } from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import React from 'react'
import { currency } from 'Utils'

function CardCardpayment({
  colorIcon,
  colorText,
  icon,
  value,
  label,
  balance,
}) {
  return (
    <Card py='2rem' pl={{ base: '60px', sm: '60px', md: '0px' }} pr='0px'>
      <Center
        justifyContent={{
          base: 'flex-start',
          sm: 'flex-start',
          md: 'center',
        }}
      >
        <HStack spacing='1.5rem'>
          <Icon
            fontSize={{
              base: '5xl',
              sm: '5xl',
              md: '5xl',
              lg: '5xl',
              xl: '4xl',
              '2xl': '5xl',
            }}
            as={icon}
            color={colorIcon}
          />
          <Flex flexDirection='column' alignItems='flex-start' spacing='0px'>
            <Text fontSize='md' mt='0.5rem'>
              {label} (
              {new Intl.NumberFormat('th-TH').format(parseFloat(value))})
            </Text>
            <Text
              fontSize={{
                base: 'xl',
                sm: 'xl',
                md: 'xl',
                lg: 'xl',
                xl: 'xl',
                '2xl': '2xl',
              }}
              mt='0rem'
              color={colorText}
            >
              {currency(balance ? balance : 0, 2, true)}
            </Text>
          </Flex>
        </HStack>
      </Center>
    </Card>
  )
}

export { CardCardpayment }
