import {
  Box,
  Button,
  Center,
  Fade,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import InputField from 'Components/Horizons/fields/InputField'
import TextField from 'Components/Horizons/fields/TextField'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { IoPricetagsOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { useBranch, useProduct } from 'Hooks'
import RadioField from 'Components/Horizons/fields/RadioField'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { useAppContext } from 'Context'
import { usePromotion } from 'Hooks/promotion'
import { decodeUnit, encodeUnit } from 'Utils/currency'

const _ = require('underscore')

export default function FormDiscountProduct({ id, data }) {
  const navigate = useNavigate()
  const { user } = useAppContext()
  const { createPromotion, updatePromotion } = usePromotion()
  const { Branch } = useBranch()
  const { Product } = useProduct()
  const [allBranch, setAllBranch] = useState([])
  const [allProduct, setAllProduct] = useState([])
  const [isSubmit, setIsSubmit] = useState(false)
  const [isFadePage, setIsFadePage] = useState(true)
  const [options, setOptions] = useState([])
  const [products, setProducts] = useState([])
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const scroll = useRef(null)

  const animatedComponents = makeAnimated()

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      discountType: 'Bath',
      type: user?.branchSelect?.id === 'HQ' ? 'default' : 'branch',
      status: true,
      detail: '',
      typeDiscount: 'receipt',
    },
  })

  useEffect(() => {
    if (data) {
      setValue('name', data?.name)
      setValue('detail', data?.detail)
      setValue('discount', decodeUnit(data?.discount))
      setValue('discountType', data?.discountType)
      setValue('status', data?.status)
      setValue('type', data?.type)
      setValue('typeProduct', data?.typeProduct)
      setValue('typeDiscount', data?.typeDiscount)
      setOptions(
        data?.branch.map((item) => ({
          id: item.id,
          name: item.name,
        }))
      )
      setProducts(
        data?.product.map((item) => ({
          id: item.id,
          name: item.name,
        }))
      )
    }

    const BranchValue = []
    const ProductValue = []

    _.forEach(
      Branch.filter((branch) => branch.id !== 'HQ'),
      (doc) => {
        BranchValue.push({
          value: doc.id,
          label: doc.name,
          isdisabled: false,
        })
      }
    )
    _.forEach(Product, (doc) => {
      ProductValue.push({
        value: doc.id,
        label: doc.nameOption ? doc.name + ' ' + doc.nameOption : doc.name,
        isdisabled: false,
      })
    })
    setAllBranch(BranchValue)
    setAllProduct(ProductValue)
    setTimeout(() => {
      setIsFadePage(false)
    }, 500)
  }, [data, setValue, Branch, Product])
  const typeBranch = watch('type')
  const typeProduct = watch('typeProduct')

  function handleChange(event) {
    const value = event
    setOptions(value?.map((item) => ({ id: item.value, name: item.label })))
    clearErrors()
  }

  function handleChangeProduct(event) {
    const value = event
    setProducts(value?.map((item) => ({ id: item.value, name: item.label })))
    clearErrors()
  }

  function onSubmit(value) {
    const branch =
      typeBranch === 'default'
        ? allBranch.map((item) => ({ id: item.value, name: item.label }))
        : options.map((item) => ({ id: item.id, name: item.name }))

    const product =
      typeProduct === 'default'
        ? allProduct.map((item) => ({ id: item.value, name: item.label }))
        : products.map((item) => ({ id: item.id, name: item.name }))
    setIsSubmit(true)

    if (branch.length < 1 && product.length < 1) {
      setError('branch', { type: 'required', message: 'กรุณาเลือกสาขา' })
      setError('product', { type: 'required', message: 'กรุณาเลือกสินค้า' })
      setIsSubmit(false)
    } else if (branch.length > 0) {
      if (id) {
        try {
          updatePromotion(id, {
            ...value,
            discount: encodeUnit(value.discount).toString(),
            branch: branch,
            product: product,
          }).then(() => {
            navigate('/discount/product')
            setIsSubmit(false)
          })
        } catch (error) {
          setIsSubmit(false)
        }
      } else {
        try {
          createPromotion({
            ...value,
            discount: encodeUnit(value.discount).toString(),
            branch: branch,
            product: product,
          }).then(() => {
            navigate('/discount/product')
            setIsSubmit(false)
          })
        } catch (error) {
          setIsSubmit(false)
          setError('branch', 'กรุณาเลือกสาขา')
        }
      }
    }
  }
  return (
    <Fade in={!isFadePage}>
      <Flex
        direction='column'
        minH='100vh'
        h='100vh'
        align='center'
        position='relative'
      >
        <Box
          h={{ base: '0px', sm: '0px', md: '25vh' }}
          bg='discount.700'
          position='absolute'
          w={{ sm: '100vw', md: '100%', lg: '100%' }}
          borderRadius={{ sm: '0px', md: '30px', lg: '30px' }}
        ></Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            w={{ sm: '100vw', md: '670px', lg: '850px' }}
            h={{ sm: 'full', md: 'auto' }}
            p='0px'
            mx='auto'
          >
            <Card
              p={{ base: '15px', sm: '15px', md: '30px' }}
              px={{ base: '30px', sm: '30px', md: '30px' }}
              mt={{ sm: '-10px', md: '4rem', lg: '4rem' }}
              h={{ sm: 'full', md: 'auto' }}
              pb={{ base: '40px', sm: '40px', md: '30px' }}
            >
              <Box
                pb='0.3rem'
                zIndex='base'
                color='discount.500'
                w='max-content'
                display={{ sm: 'flex', md: 'none' }}
                onClick={() => navigate(-1)}
              >
                <Text
                  display={{ sm: 'flex', md: 'none' }}
                  fontSize='24px'
                  cursor='pointer'
                >
                  <ChevronLeftIcon />
                </Text>
              </Box>
              <Text
                textAlign={{
                  base: 'center',
                  sm: 'center',
                  md: 'left',
                  lg: 'left',
                }}
                fontSize='2xl'
                color='#1B2559'
                fontWeight='700'
                pb={{ base: '1rem', lg: '0rem' }}
              >
                ส่วนลดสินค้า
              </Text>
              <Center>
                <Center
                  color='white'
                  bg={'discount.100'}
                  borderRadius={'full'}
                  h='7rem'
                  w='7rem'
                >
                  <Icon as={IoPricetagsOutline} w='4.5rem' h='4.5rem' />
                </Center>
              </Center>
              <Flex direction='column' w='100%' pt={'1em'}>
                <Stack direction='column' spacing='20px'>
                  <Stack
                    pt={'1em'}
                    direction={{ base: 'column', md: 'row' }}
                    color='#1B2559'
                    h='auto'
                  >
                    <Stack direction='column' w='full'>
                      <InputField
                        control={control}
                        errors={errors}
                        mb='0px'
                        id='name'
                        label='ชื่อส่วนลด'
                        name='name'
                        type='text'
                        required={{
                          value: true,
                          message: 'กรุณากรอกชื่อส่วนลด',
                        }}
                      />
                      <Stack direction='column' gap='0px'>
                        <RadioField
                          control={control}
                          errors={errors}
                          mb='0px'
                          id={'discountType'}
                          name={'discountType'}
                          label={'ส่วนลด'}
                          options={[
                            { value: 'Bath', name: 'บาท' },
                            { value: 'Percentage', name: 'เปอร์เซ็นต์' },
                          ]}
                          required={{
                            value: true,
                            message: 'กรุณาเลือกประเภทส่วนลด',
                          }}
                        />
                        <InputField
                          id={'discount'}
                          type='number'
                          name={'discount'}
                          control={control}
                          errors={errors}
                          placeholder={'ระบุราคา/เปอร์เซ็นต์'}
                          required={{
                            value: true,
                            message: 'กรุณากรอกส่วนลด',
                          }}
                          pattern={{
                            value: /[0-9]/,
                            message: 'กรุณากรอกเป็นตัวเลขเท่านั้น',
                          }}
                          mb={'0px'}
                          onWheel={(e) => e.target.blur()}
                        />
                      </Stack>
                    </Stack>
                    <Stack direction='column' w='full'>
                      <TextField
                        control={control}
                        errors={errors}
                        mb='0px'
                        variant={'no-effects'}
                        id='detail'
                        name='detail'
                        label='รายละเอียดส่วนลด'
                        h='165px'
                      />
                    </Stack>
                  </Stack>
                </Stack>
                {/* <Stack direction='column' spacing='20px'>
                  <Stack
                    pt={'1em'}
                    direction={{ base: 'column', md: 'row' }}
                    color='#1B2559'
                    h='auto'
                  >
                    <Stack direction='column' w='full'>
                      <InputField
                        control={control}
                        errors={errors}
                        mb='0px'
                        id='minimumPrice'
                        label='ยอดขั้นต่ำ'
                        name='minimumPrice'
                        type='text'
                        required={{
                          value: true,
                          message: 'กรุณากรอกยอดขั้นต่ำ',
                        }}
                      />
                    </Stack>
                    <Stack direction='column' w='full'>
                      <InputField
                        control={control}
                        errors={errors}
                        mb='0px'
                        id='Quota'
                        label='โควตา'
                        name='Quota'
                        type='text'
                        required={{
                          value: true,
                          message: 'กรุณากรอกโคต้า',
                        }}
                      />
                    </Stack>
                  </Stack>
                </Stack> */}

                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  justifyContent='space-between'
                  alignItems='end'
                >
                  <VStack
                    // h='full'
                    // px={{ base: '0rem', sm: '0rem', md: '0.5rem', lg: '1rem' }}
                    // justify='space-between'
                    w={'full'}
                  >
                    <Controller
                      control={control}
                      errors={errors}
                      name={'status'}
                      render={({ field: { onChange, value } }) => (
                        <FormControl w='full' h='full'>
                          <HStack
                            w='full'
                            h='full'
                            alignItems='start'
                            flexDirection='column'
                            mt='30px'
                          >
                            <FormLabel
                              display='flex'
                              ms='10px'
                              fontSize='sm'
                              color={textColorPrimary}
                              fontWeight='bold'
                              _hover={{ cursor: 'pointer' }}
                              w='full'
                            >
                              เปิด-ปิด ส่วนลดสินค้า
                            </FormLabel>
                            <Switch
                              pt='10px'
                              onChange={onChange}
                              isChecked={value}
                              colorScheme='green'
                            />
                          </HStack>
                        </FormControl>
                      )}
                    />
                  </VStack>
                  <VStack w={'full'}>
                    <RadioField
                      control={control}
                      errors={errors}
                      name={'typeDiscount'}
                      label={'ประเภทส่วนลด'}
                      mb={'0px'}
                      options={[
                        { name: 'ต่อชิ้น', value: 'piece' },
                        { name: 'ยอดขาย', value: 'receipt' },
                      ]}
                      required={{
                        value: true,
                        message: 'กรุณาเลือกประเภทส่วนลด',
                      }}
                    />
                  </VStack>
                </Stack>
                <SimpleGrid columns={[1, 2]} gap={'1em'} alignItems='start'>
                  <VStack w='full' pt={'25px'}>
                    {user?.branchSelect?.id === 'HQ' && (
                      <Stack
                        w='full'
                        h='full'
                        pr={{
                          base: '0rem',
                          sm: '0rem',
                          md: '0.5rem',
                          lg: '1rem',
                        }}
                        justify='space-between'
                        alignItems='end'
                      >
                        <RadioField
                          name={'type'}
                          control={control}
                          errors={errors}
                          id={'type'}
                          label={'ใช้ได้กับสาขาใด'}
                          mb={'6px'}
                          options={[
                            { name: 'ทุกสาขา', value: 'default' },
                            { name: 'บางสาขา', value: 'branch' },
                          ]}
                        />
                      </Stack>
                    )}
                    <Stack
                      w='full'
                      direction={{ base: 'column', md: 'row' }}
                      // pt={{ sm: '10px', md: '20px', lg: '20px' }}
                    >
                      {user.branchSelect?.id === 'HQ' &&
                        typeBranch === 'branch' && (
                          <Box pt='1em' w='100%'>
                            <FormControl isInvalid={errors['branch']}>
                              <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                onChange={handleChange}
                                options={allBranch}
                                placeholder='เลือกสาขา...'
                                defaultValue={options.map((item) => ({
                                  value: item.id,
                                  label: item.name,
                                }))}
                              />
                              <FormErrorMessage>
                                {errors['branch'] && errors['branch']?.message}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>
                        )}
                    </Stack>
                  </VStack>
                  <VStack w='full' pt={'25px'}>
                    {user?.branchSelect?.id === 'HQ' && (
                      <Stack
                        w='full'
                        h='full'
                        pr={{
                          base: '0rem',
                          sm: '0rem',
                          md: '0.5rem',
                          lg: '1rem',
                        }}
                        justify='space-between'
                        alignItems='end'
                      >
                        <RadioField
                          name={'typeProduct'}
                          control={control}
                          errors={errors}
                          id={'typeProduct'}
                          label={'ใช้ได้กับสินค้าใด'}
                          mb={'6px'}
                          options={[
                            { name: 'ทุกสินค้า', value: 'default' },
                            { name: 'บางสินค้า', value: 'product' },
                          ]}
                        />
                      </Stack>
                    )}
                    <Stack
                      w='full'
                      direction={{ base: 'column', md: 'row' }}
                      // pt={{ sm: '10px', md: '20px', lg: '20px' }}
                    >
                      {user.branchSelect?.id === 'HQ' &&
                        typeProduct === 'product' && (
                          <Box pt='1em' w='100%'>
                            <FormControl isInvalid={errors['branch']}>
                              <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                onChange={handleChangeProduct}
                                options={allProduct}
                                placeholder='เลือกสินค้า...'
                                defaultValue={products.map((item) => ({
                                  value: item.id,
                                  label: item.name,
                                }))}
                              />
                              <FormErrorMessage>
                                {errors['branch'] && errors['branch']?.message}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>
                        )}
                    </Stack>
                  </VStack>
                </SimpleGrid>
              </Flex>
              <Flex justify='space-between' mt='30px'>
                <Button
                  variant='light'
                  fontSize='sm'
                  borderRadius='16px'
                  w={{ base: '128px', md: '148px' }}
                  h='46px'
                  onClick={() => navigate(-1)}
                >
                  ย้อนกลับ
                </Button>
                <Button
                  type='submit'
                  colorScheme='btn'
                  fontSize='sm'
                  borderRadius='16px'
                  w={{ base: '128px', md: '148px' }}
                  h='46px'
                  ms='auto'
                  isLoading={isSubmit}
                  variant='solid'
                  loadingText={'Loading...'}
                >
                  บันทึก
                </Button>
              </Flex>
            </Card>
          </Box>
        </form>
      </Flex>
      <Box ref={scroll}></Box>
    </Fade>
  )
}
