const _ = require('underscore')

export function onFilterReportProduct(item, selectBranch, startDate, endDate) {
  const SD = startDate ? startDate.getTime() : new Date()
  const ED = endDate ? endDate.getTime() : new Date()

  const NextSD = SD + 24 * 60 * 60 * 1000
  const PrevSD = SD - 24 * 60 * 60 * 1000
  const NextED = ED + 24 * 60 * 60 * 1000
  const PrevED = ED - 24 * 60 * 60 * 1000
  // Branch //
  if (selectBranch) {
    const branch = item.filter((item) => item.branch === selectBranch)
    // startDate //
    if (startDate) {
      // endDate //
      if (endDate) {
        return branch.map((item) => ({
          ...item,
          grandTotal: _.reduce(
            item.subRows.filter(
              (value) =>
                value.date.getTime() >= PrevSD && value.date.getTime() <= NextED
            ),
            (v, i) => v + i.grandTotal,
            0
          ),
          diff: _.reduce(
            item.subRows.filter(
              (value) =>
                value.date.getTime() >= PrevSD && value.date.getTime() <= NextED
            ),
            (v, i) => v + i.diff,
            0
          ),
          subRows: item.subRows.filter(
            (value) =>
              value.date.getTime() >= PrevSD && value.date.getTime() <= NextED
          ),
        }))
      }

      return branch.map((item) => ({
        ...item,
        grandTotal: _.reduce(
          item.subRows.filter(
            (value) =>
              value.date.getTime() >= PrevSD && value.date.getTime() <= NextSD
          ),
          (v, i) => v + i.grandTotal,
          0
        ),
        diff: _.reduce(
          item.subRows.filter(
            (value) =>
              value.date.getTime() >= PrevSD && value.date.getTime() <= NextSD
          ),
          (v, i) => v + i.diff,
          0
        ),
        subRows: item.subRows.filter(
          (value) =>
            value.date.getTime() >= PrevSD && value.date.getTime() <= NextSD
        ),
      }))
    }
    return branch
  }

  // startDate //
  if (startDate) {
    // endDate //
    if (endDate) {
      return item.map((item) => ({
        ...item,
        grandTotal: _.reduce(
          item.subRows.filter(
            (value) =>
              value.date.getTime() >= PrevSD && value.date.getTime() <= NextED
          ),
          (v, i) => v + i.grandTotal,
          0
        ),
        diff: _.reduce(
          item.subRows.filter(
            (value) =>
              value.date.getTime() >= PrevSD && value.date.getTime() <= NextED
          ),
          (v, i) => v + i.diff,
          0
        ),
        subRows: item.subRows.filter(
          (value) =>
            value.date.getTime() >= PrevSD && value.date.getTime() <= NextED
        ),
      }))
    }

    return item.map((item) => ({
      ...item,
      grandTotal: _.reduce(
        item.subRows.filter(
          (value) =>
            value.date.getTime() >= PrevSD && value.date.getTime() <= NextSD
        ),
        (v, i) => v + i.grandTotal,
        0
      ),
      diff: _.reduce(
        item.subRows.filter(
          (value) =>
            value.date.getTime() >= PrevSD && value.date.getTime() <= NextSD
        ),
        (v, i) => v + i.diff,
        0
      ),
      subRows: item.subRows.filter(
        (value) =>
          value.date.getTime() >= PrevSD && value.date.getTime() <= NextSD
      ),
    }))
  }

  // endDate //
  if (endDate) {
    return item.map((item) => ({
      ...item,
      grandTotal: _.reduce(
        item.subRows.filter(
          (value) =>
            value.date.getTime() >= PrevED && value.date.getTime() <= NextED
        ),
        (v, i) => v + i.grandTotal,
        0
      ),
      diff: _.reduce(
        item.subRows.filter(
          (value) =>
            value.date.getTime() >= PrevED && value.date.getTime() <= NextED
        ),
        (v, i) => v + i.diff,
        0
      ),
      subRows: item.subRows.filter(
        (value) =>
          value.date.getTime() >= PrevED && value.date.getTime() <= NextED
      ),
    }))
  }

  return item
}

export function onFilterReportBranch(
  data,
  selectBranch,
  selectStatus,
  startDate,
  endDate
) {
  const SD = startDate ? startDate.getTime() : new Date()
  const ED = endDate ? endDate.getTime() : new Date()

  const NextSD = SD + 24 * 60 * 60 * 1000
  const PrevSD = SD - 24 * 60 * 60 * 1000
  const NextED = ED + 24 * 60 * 60 * 1000
  const PrevED = ED - 24 * 60 * 60 * 1000

  if (selectBranch) {
    const branch = data.filter((item) => item.branch === selectBranch)
    if (selectStatus) {
      const status = branch.map((item) => ({
        ...item,
        grandTotal: _.reduce(
          item.subRows.filter((sub) => sub.status === selectStatus),
          (v, i) => v + i.grandTotal,
          0
        ),
        subRows: item.subRows.filter((sub) => sub.status === selectStatus),
      }))
      if (startDate) {
        if (endDate) {
          return status.map((item) => ({
            ...item,
            grandTotal: _.reduce(
              item.subRows.filter(
                (sub) =>
                  sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextED
              ),
              (v, i) => v + i.grandTotal,
              0
            ),
            subRows: item.subRows.filter(
              (sub) =>
                sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextED
            ),
          }))
        }
        return status.map((item) => ({
          ...item,
          grandTotal: _.reduce(
            item.subRows.filter(
              (sub) =>
                sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextSD
            ),
            (v, i) => v + i.grandTotal,
            0
          ),
          subRows: item.subRows.filter(
            (sub) =>
              sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextSD
          ),
        }))
      }

      return status
    }
    return branch
  }
  if (selectStatus) {
    const status = data.map((item) => ({
      ...item,
      grandTotal: _.reduce(
        item.subRows.filter((sub) => sub.status === selectStatus),
        (v, i) => v + i.grandTotal,
        0
      ),
      subRows: item.subRows.filter((sub) => sub.status === selectStatus),
    }))
    if (startDate) {
      if (endDate) {
        return status.map((item) => ({
          ...item,
          grandTotal: _.reduce(
            item.subRows.filter(
              (sub) =>
                sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextED
            ),
            (v, i) => v + i.grandTotal,
            0
          ),
          subRows: item.subRows.filter(
            (sub) =>
              sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextED
          ),
        }))
      }
      return status.map((item) => ({
        ...item,
        grandTotal: _.reduce(
          item.subRows.filter(
            (sub) =>
              sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextSD
          ),
          (v, i) => v + i.grandTotal,
          0
        ),
        subRows: item.subRows.filter(
          (sub) => sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextSD
        ),
      }))
    }
    return status
  }
  if (startDate) {
    if (endDate) {
      return data.map((item) => ({
        ...item,
        grandTotal: _.reduce(
          item.subRows.filter(
            (sub) =>
              sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextED
          ),
          (v, i) => v + i.grandTotal,
          0
        ),
        subRows: item.subRows.filter(
          (sub) => sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextED
        ),
      }))
    }
    return data.map((item) => ({
      ...item,
      grandTotal: _.reduce(
        item.subRows.filter(
          (sub) => sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextSD
        ),
        (v, i) => v + i.grandTotal,
        0
      ),
      subRows: item.subRows.filter(
        (sub) => sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextSD
      ),
    }))
  }
  if (endDate) {
    return data.map((item) => ({
      ...item,
      grandTotal: _.reduce(
        item.subRows.filter(
          (sub) => sub.date.getTime() >= PrevED && sub.date.getTime() <= NextED
        ),
        (v, i) => v + i.grandTotal,
        0
      ),
      subRows: item.subRows.filter(
        (sub) => sub.date.getTime() >= PrevED && sub.date.getTime() <= NextED
      ),
    }))
  }

  return data
}
