import {
  Avatar,
  Box,
  Center,
  Fade,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import { FormInventory } from 'Components/form'
import Card from 'Components/Horizons/card/Card'
import { useProduct } from 'Hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'
import { currency } from 'Utils/currency'

function WithdrawalInventory({ user }) {
  const { id } = useParams()
  const { getProductAndStockByProductId } = useProduct()
  const [isFadePage, setIsFadePage] = useState(true)
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const Navigate = useNavigate()
  const [product, setProductData] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const productData = useMemo(() => {
    return product
  }, [product])

  const getItemData = useCallback(async () => {
    const datas = await getProductAndStockByProductId(id)
    setProductData(datas)
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    getItemData()
    setIsFadePage(false)
    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getItemData])
  return (
    <>
      {!isLoading ? (
        <>
          <Stack direction='column'>
            <Flex
              direction='column'
              minH='100vh'
              h='100vh'
              align='center'
              position='relative'
            >
              <Box
                h={{ base: '0px', sm: '0px', md: '25vh' }}
                bg='inventory.700'
                position='absolute'
                w={{ sm: '100vw', md: '100%', lg: '100%' }}
                borderRadius={{ sm: '0px', md: '30px', lg: '30px' }}
              ></Box>
              <Box
                variant='unstyled'
                mt={{ sm: '0rem', md: '4rem', lg: '4rem' }}
                zIndex='0'
                display='flex'
                flexDirection='column'
                h={{ sm: 'full', md: 'auto' }}
              >
                <Box
                  mt={{ base: '-10px', sm: '-10px', md: '24px' }}
                  maxW={{ md: '90%', lg: '100%' }}
                  mx={{ base: 'auto', md: '0px', lg: 'auto' }}
                  h={{ sm: 'full', md: 'auto' }}
                >
                  <Box
                    w={{ sm: '100vw', md: '670px', lg: '850px' }}
                    p='0px'
                    mx={{ base: 'auto', md: '0px', lg: 'auto' }}
                    h={{ sm: 'full', md: 'auto' }}
                  >
                    <Card
                      p={{ base: '15px', sm: '15px', md: '30px' }}
                      px={{ base: '30px', sm: '30px', md: '30px' }}
                      as={Fade}
                      h={{ sm: 'full', md: 'auto' }}
                      in={!isFadePage && productData}
                    >
                      <Box
                        pb='0.3rem'
                        zIndex='overlay'
                        color='inventory.500'
                        onClick={() => Navigate(-1)}
                        w='max-content'
                        display={{ sm: 'flex', md: 'none' }}
                      >
                        <Text
                          display={{ sm: 'flex', md: 'none' }}
                          fontSize='24px'
                          cursor='pointer'
                        >
                          <ChevronLeftIcon />
                        </Text>
                      </Box>
                      <Text
                        color={textColor}
                        fontSize='2xl'
                        fontWeight='700'
                        mb='20px'
                        textAlign={{
                          base: 'center',
                          sm: 'center',
                          md: 'left',
                          lg: 'left',
                        }}
                      >
                        รายละเอียด
                      </Text>
                      <Center>
                        <VStack w='full'>
                          <Avatar
                            src={
                              productData?.product?.img ||
                              '/product_Thumbnail.png'
                            }
                            size={'2xl'}
                            borderRadius='20px'
                          />
                          <VStack>
                            <Heading
                              mt='0.5rem'
                              fontFamily={'Prompt'}
                              size={'lg'}
                            >
                              {productData?.product?.unit === 'piece'
                                ? `${currency(productData?.stock)} ชิ้น`
                                : `${currency(productData?.stock)} กรัม`}
                            </Heading>
                            <VStack alignItems={'start'}>
                              <Text>
                                รหัสสินค้า : {productData?.product?.sku}
                              </Text>
                              <Text>
                                ชื่อสินค้า : {productData?.product?.name}{' '}
                                {productData?.product?.nameOption}
                              </Text>
                            </VStack>
                          </VStack>
                          <Box w='full'>
                            <FormInventory
                              method={'withdrawal'}
                              id={id}
                              data={productData?.product}
                            />
                          </Box>
                        </VStack>
                      </Center>
                    </Card>
                  </Box>
                </Box>
              </Box>
            </Flex>
          </Stack>
        </>
      ) : (
        <>
          <Center
            h={{ base: '70vh', sm: '70vh', md: '50vh', lg: '50vh' }}
            flexDirection='column'
            w='100%'
          >
            <Center flexDirection='column'>
              <Text pb='1rem' fontSize='xl' color='loading.100'>
                รอดำเนินการ
              </Text>
              <Box className='loading' color='loading.100'></Box>
            </Center>
          </Center>
        </>
      )}
    </>
  )
}

export default WithdrawalInventory
