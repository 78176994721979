import React, { useEffect, useState } from 'react'
import {
  Center,
  Text,
  Icon,
  Button,
  Spinner,
  Flex,
  IconButton,
  Input,
  InputLeftElement,
  InputGroup,
  HStack,
  Select,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import { Link } from 'react-router-dom'
import { BsPlus } from 'react-icons/bs'
// import { useDiscount } from 'Hooks'
import CardDiscount from './components/card'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'
import { db } from 'Config/firebase'
import { decodeUnit } from 'Utils/currency'
import { SearchIcon } from '@chakra-ui/icons'
import { search } from 'ss-search'

// const _ = require('underscore')
function Discount({ user }) {
  const [onSearch, setOnSearch] = useState(null)
  const [onSelect, setOnSelect] = useState(null)
  const [promotion, setPromotion] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const qPromotion = query(
      collection(db, 'Promotion'),
      orderBy('createdAt', 'desc')
    )

    const unsubscribe = onSnapshot(qPromotion, (snapShot) => {
      let dataValue = []
      snapShot.docs.forEach((doc) => {
        return dataValue.push({
          ...doc.data(),
          id: doc.id,
          minimumOrder: decodeUnit(doc.data()?.minimumOrder),
          valueDiscount: decodeUnit(doc.data()?.valueDiscount),
        })
      })
      const searchText = onSearch
      const searchKey = ['discountName']
      const result = search(dataValue, searchKey, searchText)

      const resultSearch = onSelect
        ? result?.filter((f) => f?.type === onSelect)
        : result
      if (resultSearch?.length > 0) {
        setPromotion(resultSearch)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        setPromotion([])
      }
    })

    return () => {
      unsubscribe()
    }
  }, [onSelect, onSearch])

  return (
    <>
      <Card px={{ base: '10px', sm: '10px', md: '10px', lg: '30px' }}>
        <Flex>
          <HStack
            spacing={{ base: '0px', sm: '0px', md: '15px' }}
            mb={{
              base: '15px',
              sm: '15px',
              md: '15px',
              lg: '15px',
              xl: '10px',
              '2xl': '10px',
            }}
            flexDirection={{
              base: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
            }}
            align={{ base: 'start', md: 'conter' }}
            justifyContent='flex-start'
            w='100%'
            zIndex='base'
          >
            <Flex w={{ base: '100%', sm: '100%', md: 'auto' }}>
              <InputGroup
                w={{ base: '100%', sm: '100%', md: '220px', lg: '250px' }}
              >
                <InputLeftElement
                  children={
                    <IconButton
                      bg='inherit'
                      borderRadius='inherit'
                      _hover='none'
                      _active={{
                        bg: 'inherit',
                        transform: 'none',
                        borderColor: 'transparent',
                      }}
                      _focus={{
                        boxShadow: 'none',
                      }}
                      icon={<SearchIcon color={'gray.700'} w='15px' h='15px' />}
                    ></IconButton>
                  }
                />
                <Input
                  variant='search'
                  fontSize='sm'
                  bg={'secondaryGray.300'}
                  color={'gray.700'}
                  fontWeight='500'
                  _placeholder={{
                    color: 'gray.400',
                    fontSize: '14px',
                    opacity: 1,
                  }}
                  borderRadius={'30px'}
                  placeholder={'Search...'}
                  onChange={(e) => {
                    const keyword = e.target.value
                    setOnSearch(keyword)
                  }}
                />
              </InputGroup>
            </Flex>
            <Flex
              pt={{ base: '0.5rem', sm: '0.5rem', md: '0rem' }}
              align='center'
              w={{
                base: '100%',
                sm: '100%',
                md: '16rem',
              }}
            >
              <Select
                color={'gray.700'}
                w='100%'
                bg={'secondaryGray.300'}
                border='0px'
                fontWeight='500'
                borderRadius={'30px'}
                defaultValue=''
                variant='main'
                placeholder={'โปรโมชั่นทั้งหมด'}
                _placeholder={{
                  fontWeight: '400',
                }}
                value={onSelect}
                h='40px'
                maxh='40px'
                onChange={(e) => {
                  setOnSelect(e.target.value)
                }}
              >
                <option value={'discount'}>
                  ส่วนลดเป็นเปอร์เซ็นต์, จำนวนเงิน
                </option>
                <option value={'free'}>โปรโมชั่นส่วนลดแบบมีเงื่อนไข</option>
              </Select>
            </Flex>
          </HStack>
        </Flex>
        {!isLoading && promotion?.length > 0 ? (
          promotion?.map((item, index) => (
            <CardDiscount itm={item} key={index} setIsLoading={setIsLoading} />
          ))
        ) : !isLoading && promotion?.length <= 0 ? (
          <Center>
            <Text>ไม่มีพบรายการส่วนลด</Text>
          </Center>
        ) : (
          <Center>
            <Spinner size='lg' />
          </Center>
        )}
      </Card>
      <Button
        as={Link}
        to='/discount/add'
        borderRadius='full'
        position='fixed'
        right={5}
        bottom={5}
        w={'50px'}
        h={'50px'}
        colorScheme='btn'
      >
        <Icon as={BsPlus} fontSize={'45px'} />
      </Button>
    </>
  )
}

export default Discount
