import {
  Avatar,
  Button,
  Center,
  Fade,
  HStack,
  Icon,
  SimpleGrid,
  SkeletonCircle,
  SkeletonText,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import { useBranch } from 'Hooks'
import React, { useEffect, useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { RiPencilFill } from 'react-icons/ri'

function Branch({ user }) {
  const { Branch, getBranchData } = useBranch()
  const [isFade, setIsFade] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getBranchData()
  }, [getBranchData])

  useEffect(() => {
    if (Branch) {
      setIsLoading(false)
      setIsFade(false)
    }
  }, [Branch])

  return (
    <Stack direction='column'>
      {!isLoading ? (
        Branch?.length > 0 ? (
          <SimpleGrid
            as={Fade}
            in={!isFade}
            columns={{ base: 1, md: 2, lg: 3, xl: 3, '2xl': 4 }}
            gap={'2'}
          >
            {Branch?.map(
              (itm, index) =>
                itm?.id !== 'HQ' && (
                  <Card
                    borderRadius={{ base: '20px', sm: '20px', md: '25px' }}
                    px={'2em'}
                    py={{ sm: '1.5em', md: '1.5em', lg: '1rem' }}
                    key={index}
                    border={'1px'}
                    justifyContent='center'
                    borderColor={'blackAlpha.200'}
                    as={Link}
                    to={itm.id === 'HQ' ? '#' : `/branchs/view/${itm?.id}`}
                    cursor={itm.id === 'HQ' ? 'default' : 'pointer'}
                  >
                    <HStack justifyContent={'space-between'}>
                      <HStack spacing={'.75em'}>
                        <Avatar src={itm.img} name={itm.name} />
                        <VStack alignItems={'start'} spacing='-0.5'>
                          <Text
                            fontWeight={'bold'}
                            fontSize={{ base: '12px', md: '14px', lg: '16px' }}
                          >
                            {itm?.name}
                          </Text>
                          <Text
                            color={'blackAlpha.500'}
                            fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                          >
                            {itm?.typeOfBranch}
                          </Text>
                        </VStack>
                      </HStack>
                      {itm?.id !== 'HQ' && (
                        <Button
                          as={Link}
                          to={`/branchs/edit/${itm.id}`}
                          variant={'none'}
                        >
                          <Icon
                            as={RiPencilFill}
                            color={'branchs.500'}
                            fontSize={{ base: '16px', md: '14px', lg: '16px' }}
                          />
                        </Button>
                      )}
                    </HStack>
                  </Card>
                )
            )}
          </SimpleGrid>
        ) : (
          <Center>
            <Text as={Fade} in={!isFade}>
              No data available.
            </Text>
          </Center>
        )
      ) : (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 4 }} gap={'3'}>
          {new Array(5).fill(null).map((itm, index) => (
            <Card
              borderRadius={{ base: '20px', sm: '20px', md: '25px' }}
              px={'2em'}
              py={{ sm: '1.5em', md: '1.5em', lg: '1rem' }}
              key={index}
              border={'1px'}
              borderColor={'blackAlpha.200'}
              justifyContent='center'
            >
              <HStack justifyContent={'space-between'}>
                <HStack spacing={'.75em'}>
                  <SkeletonCircle>
                    <Avatar src={''} />
                  </SkeletonCircle>
                  <VStack alignItems={'start'} spacing='2'>
                    <SkeletonText noOfLines={1} w={'full'}>
                      <Text
                        fontWeight={'bold'}
                        fontSize={{ base: '12px', md: '14px', lg: '16px' }}
                      >
                        xxxx
                      </Text>
                    </SkeletonText>
                    <HStack>
                      <SkeletonText noOfLines={1} w={'75%'}>
                        <Text
                          color={'blackAlpha.500'}
                          fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                        >
                          ตัวเลือกสินค้า: {itm?.name ? itm.name : '-'}
                        </Text>
                      </SkeletonText>
                    </HStack>
                  </VStack>
                </HStack>
                <SkeletonCircle>
                  <Button variant={'none'}>
                    <Icon
                      as={RiPencilFill}
                      color={'branchs.500'}
                      fontSize={{ base: '16px', md: '14px', lg: '16px' }}
                    />
                  </Button>
                </SkeletonCircle>
              </HStack>
            </Card>
          ))}
        </SimpleGrid>
      )}
      <Button
        as={Link}
        to='/branchs/add'
        borderRadius='full'
        position='fixed'
        right={5}
        bottom={5}
        w={'50px'}
        h={'50px'}
        colorScheme='btn'
        color='#ffff'
      >
        <Icon as={BsPlus} fontSize={'45px'} />
      </Button>
    </Stack>
  )
}

export default Branch
