import { ChevronLeftIcon, SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react'
import { db } from 'Config/firebase'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { currency, decodeUnit } from 'Utils/currency'
import { search } from 'ss-search'
import ButtonResponsive from 'Components/Button/btn'
import { First } from './list/first'
import { MdHideImage } from 'react-icons/md'
import Card from 'Components/Horizons/card/Card'
import { useCategory } from 'Hooks'

const _ = require('underscore')
function RadioCard({ discount, setDiscount, onClose, product, ...props }) {
  const data = product.find((item) => item.id === props.value)
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Center as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        borderWidth='1px'
        borderRadius='6px'
        boxShadow='md'
        opacity='1'
        _checked={{
          bg: 'table.100',
          color: 'white',
          borderColor: 'table.100',
          opacity: '1',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        w='full'
        onClick={() => {
          setDiscount({
            ...discount,
            product: data,
            productAmount: 0,
          })
          onClose()
        }}
      >
        <Box w='full'>
          {data.img !== null ? (
            <Image
              borderTopRadius={'6px'}
              h={{
                base: '6rem',
                sm: '6rem',
                md: '6rem',
                lg: '6rem',
                xl: '9rem',
              }}
              w='full'
              objectFit='cover'
              src={data?.img ? data?.img : '/gogreen/product_Thumbnail.png'}
            />
          ) : (
            <Center
              borderTopRadius={'6px'}
              h={{
                base: '6rem',
                sm: '6rem',
                md: '6rem',
                lg: '6rem',
                xl: '9rem',
              }}
              w='full'
            >
              <MdHideImage />
            </Center>
          )}
        </Box>
        <Flex flexDirection='column'>
          <Text textAlign='start' w='100%' pl='0.3rem'>
            {data?.name}
          </Text>
          <Text textAlign='start' w='100%' pl='0.3rem' mt='-0.3rem'>
            รหัสสินค้า : {data?.sku}
          </Text>
          <Text textAlign='start' color='green' w='100%' pl='0.3rem'>
            {currency(decodeUnit(data?.price), 2, true)}
          </Text>
        </Flex>
      </Box>
    </Center>
  )
}

function InfoModal({ setDiscount, discount }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [optionsSearch, setOptionsSearch] = useState({
    name: '',
    category: '',
  })
  const [onSearch, setOnSearch] = useState('')
  const [product, setProduct] = useState([])
  const [data, setData] = useState(null)
  const { category, category: DBCategory } = useCategory()
  useEffect(() => {
    const qProduct = query(
      collection(db, 'Products'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(qProduct, (snapShot) => {
      let productValue = []
      snapShot.docs.forEach((doc) => {
        return productValue.push({
          ...doc.data(),
          id: doc.id,
          name: doc.data()?.nameOption
            ? doc.data()?.nameOption
            : doc.data()?.name,
        })
      })
      const searchText = onSearch
      const searchKey = ['name', 'nameOption', 'sku']

      const filterCategory = (category, product) => {
        const findCategory = _.find(DBCategory, (i) => {
          return i.id === category || i.name === category
        })

        const filterCategoryData = _.filter(product, (item) => {
          return (
            item?.category === findCategory?.id ||
            item?.category === findCategory?.name
          )
        })
        return filterCategoryData
      }

      const searchResuts = optionsSearch?.category
        ? filterCategory(optionsSearch?.category, productValue)
        : productValue
      const results = search(searchResuts, searchKey, searchText)
      setProduct(results)
    })
    return () => {
      unsubscribe()
    }
  }, [onSearch, optionsSearch, DBCategory])

  const options = _.pluck(product, 'id')

  const handleChange = (value) => {
    const dataValue = product.find((item) => item.id === value)
    setData(dataValue)
  }
  const { getRootProps, getRadioProps } = useRadioGroup({
    onChange: handleChange,
    defaultValue: discount?.product?.id || '',
  })

  const group = getRootProps()
  return (
    <>
      <Text
        color='form.500'
        onClick={() => {
          setOnSearch('')
          setOptionsSearch('')
          onOpen()
        }}
        cursor='pointer'
        _hover={{ textDecoration: 'underline' }}
      >
        {discount?.product ? `เปลี่ยน` : `+ เลือกรายการสินค้า`}
      </Text>
      <Modal isOpen={isOpen} onClose={onClose} size='auto'>
        <ModalOverlay />
        <ModalContent w={{ base: '100%', sm: '100%', md: '35rem' }}>
          <ModalHeader color='form.500'>
            เลือกสินค้าจัดโปรโมชั่นส่วนลด
            <HStack
              spacing={{ base: '0rem', sm: '0rem', md: '1rem' }}
              mt='20px'
              mb={{ md: '0px', lg: '0px', xl: '10px', '2xl': '10px' }}
              flexDirection={{
                base: 'column',
                sm: 'column',
                md: 'column',
                lg: 'row',
              }}
              align={{ base: 'start', md: 'center' }}
              justifyContent='flex-start'
              w='100%'
              zIndex='base'
            >
              <InputGroup w='100%' h='44px' maxh='44px'>
                <InputLeftElement
                  children={
                    <IconButton
                      bg='inherit'
                      borderRadius='10px'
                      _hover='none'
                      _active={{
                        bg: 'inherit',
                        transform: 'none',
                        borderColor: 'transparent',
                      }}
                      _focus={{
                        boxShadow: 'none',
                      }}
                      icon={<SearchIcon color={'gray.700'} w='15px' h='15px' />}
                    ></IconButton>
                  }
                />
                <Input
                  h='44px'
                  maxh='44px'
                  variant='search'
                  fontSize='sm'
                  bg={'secondaryGray.300'}
                  border='0px solid #E8E9E9'
                  color={'gray.700'}
                  fontWeight='500'
                  _placeholder={{
                    color: 'gray.400',
                    fontSize: '14px',
                    opacity: 1,
                  }}
                  borderRadius={'30px'}
                  placeholder={'Search...'}
                  onChange={(e) => {
                    setOnSearch(e.target.value)
                  }}
                />
              </InputGroup>
              <Flex
                align='center'
                pt={{ base: '10px', sm: '10px', md: '0px' }}
                w='100%'
              >
                <Select
                  color={'gray.700'}
                  w='100%'
                  bg={'secondaryGray.300'}
                  border='0px'
                  fontWeight='500'
                  borderRadius={'30px'}
                  defaultValue=''
                  variant='main'
                  placeholder={'สินค้าทั้งหมด'}
                  _placeholder={{
                    fontWeight: '400',
                  }}
                  value={optionsSearch?.category}
                  h='44px'
                  maxh='44px'
                  onChange={(e) => {
                    setOptionsSearch({
                      ...optionsSearch,
                      category: e.target.value,
                    })
                  }}
                >
                  {category?.map((item, i) => {
                    return (
                      <option value={item?.id} key={i}>
                        {item?.name}
                      </option>
                    )
                  })}
                </Select>
              </Flex>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            overflowY='auto'
            maxH={{ base: '25rem', sm: '25rem', md: '40rem' }}
          >
            <Box h={{ base: '25rem', sm: '25rem', md: '40rem' }}>
              <Grid
                templateColumns='repeat(2, 1fr)'
                gap={{ base: '15px', sm: '15px', md: '20px' }}
                {...group}
              >
                {options.map((value) => {
                  const radio = getRadioProps({ value })
                  return (
                    <GridItem key={value}>
                      <RadioCard
                        {...radio}
                        onClose={onClose}
                        product={product}
                        discount={discount}
                        setDiscount={setDiscount}
                        data={data}
                      />
                    </GridItem>
                  )
                })}
              </Grid>
            </Box>
          </ModalBody>

          <ModalFooter w='100%'></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default function FirstList({
  setList,
  discount,
  setDiscount,
  isSubmit,
}) {
  // const [onSearch, setOnSearch] = useState('')

  // const [product, setProduct] = useState([])
  useEffect(() => {
    const qProduct = query(
      collection(db, 'Products'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(qProduct, (snapShot) => {
      let productValue = []
      snapShot.docs.forEach((doc) => {
        return productValue.push({
          ...doc.data(),
          id: doc.id,
          name: doc.data()?.nameOption
            ? doc.data()?.nameOption
            : doc.data()?.name,
          price: decodeUnit(doc.data()?.price),
          cost: decodeUnit(doc.data()?.cost),
          commission: decodeUnit(doc.data()?.commission),
          minimum: decodeUnit(doc.data()?.minimum),
        })
      })
      // const searchText = onSearch
      // const searchKey = ['name', 'nameOption']

      // const results = search( searchKey, searchText)

      // setProduct(results)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  // const options = _.pluck(product, 'id')

  // const handleChange = (value) => {
  //   const data = product.find((item) => item.id === value)
  //   setDiscount({ ...discount, product: data })
  // }
  // const { getRootProps, getRadioProps } = useRadioGroup({
  //   onChange: handleChange,
  //   defaultValues: discount?.product?.id || '',
  // })
  // const group = getRootProps()
  const [first, setFirst] = useState(false)
  return (
    <>
      <Box display={first ? 'none' : 'block'} pb='1.5rem'>
        <Center>
          <Card
            padding={{
              base: '10px 0px 30px 0px',
              sm: '10px 0px 30px 0px',
              md: '10px 20px 30px 20px',
            }}
            w={{ base: '100%', sm: '100%', md: '30rem' }}
            boxShadow={{
              base: 'none',
              sm: 'none',
              md: '14px 17px 40px 4px rgb(112 144 176 / 8%)',
            }}
          >
            <HStack>
              <ChevronLeftIcon
                cursor='pointer'
                color='form.500'
                fontSize={'2xl'}
                onClick={() => {
                  setList(false)
                }}
              />
              <Text
                fontSize={{ base: 'md', sm: 'md', md: 'lg' }}
                fontWeight='bold'
              >
                เลือกสินค้าจัดโปรโมชั่นส่วนลด
              </Text>
            </HStack>

            <Center
              display={
                discount?.product === undefined || null ? 'block' : 'none'
              }
            >
              <Grid
                borderBottom='1px'
                borderColor='#E8E9E9'
                py='2em'
                w='full'
                templateColumns='repeat(2, 1fr)'
                gap={4}
              >
                <GridItem>
                  <Center>
                    <Text>รายการสินค้าที่เลือก</Text>
                  </Center>
                </GridItem>
                <GridItem>
                  <Center>
                    <InfoModal setDiscount={setDiscount} discount={discount} />
                  </Center>
                </GridItem>
              </Grid>
            </Center>
            <Center
              display={
                discount?.product !== undefined || null ? 'block' : 'none'
              }
            >
              <HStack mt='1rem'>
                <Text>รายการสินค้าที่เลือก</Text>
              </HStack>
              <Grid
                borderBottom='1px'
                borderColor='#E8E9E9'
                py='2em'
                w='full'
                templateColumns='repeat(2, 1fr)'
                gap={4}
              >
                <GridItem h='100%'>
                  <Box
                    borderWidth='1px'
                    borderRadius='6px'
                    boxShadow='md'
                    w='full'
                  >
                    <Center flexDirection='column' h='100%'>
                      <Image
                        borderTopRadius={'6px'}
                        h={{
                          base: '6rem',
                          sm: '6rem',
                          md: '6rem',
                          lg: '6rem',
                          xl: '9rem',
                        }}
                        w='full'
                        objectFit='cover'
                        src={
                          discount?.product?.img
                            ? discount?.product?.img
                            : '/gogreen/product_Thumbnail.png'
                        }
                        alt={discount?.product?.sku}
                      />
                      <Text pl='0.5rem' w='100%' textAlign='start' pt='0.5rem'>
                        {discount?.product?.name}
                      </Text>
                      <Text pl='0.5rem' w='100%' textAlign='start'>
                        รหัสสินค้า : {discount?.product?.sku}
                      </Text>
                      <Text
                        pl='0.5rem'
                        w='100%'
                        textAlign='start'
                        color='green'
                      >
                        {currency(
                          decodeUnit(discount?.product?.price),
                          2,
                          true
                        )}
                      </Text>
                    </Center>
                  </Box>
                </GridItem>
                <GridItem h='100%'>
                  <Center h='100%'>
                    <InfoModal setDiscount={setDiscount} discount={discount} />
                  </Center>
                </GridItem>
              </Grid>
            </Center>

            <Flex
              w='100%'
              mt={{ base: '0rem', sm: '0rem', md: '1.5rem' }}
              justifyContent='flex-end'
            >
              <ButtonResponsive
                label={'ถัดไป'}
                disabled={discount?.product ? false : true}
                onClick={() => {
                  setFirst(true)
                  setDiscount({ ...discount, status: true })
                }}
              />
            </Flex>
          </Card>
        </Center>
      </Box>
      {first && (
        <First
          setFirst={setFirst}
          setDiscount={setDiscount}
          discount={discount}
          isSubmit={isSubmit}
        />
      )}
    </>
  )
}
