import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  // IconButton,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useAuth, useBranch, useOrder } from 'Hooks'
import { useEffect, useMemo, useState } from 'react'
import _ from 'underscore'
import SearchDateMonth from 'Components/Search/dateMonth'
import { calCommissionEmployeeDailyWithEncode, currency } from 'Utils'
import moment from 'moment'
import { useWork } from 'Hooks/work'
import CSVDownloadForm from 'Components/csvDownload'
import { BsFillPrinterFill } from 'react-icons/bs'
import { Table } from 'antd'
import 'antd/dist/antd.css'
import { useNavigate } from 'react-router-dom'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'Config/firebase'
import { URL } from 'Utils/downloadWithURL'
// import { httpsCallable } from 'firebase/functions'

const createPDFEmployeeIncome = httpsCallable(
  functions,
  'createPDFEmployeeIncome'
)

function StaffCommissionDaily(props) {
  const Navigate = useNavigate()
  const { getAllOrdersWithDate } = useOrder()
  const { getWorkByDate } = useWork()
  const { BranchNoHQ, getBranchData } = useBranch()
  const { userList: userFormDB, getAllUsers } = useAuth()
  const [data, setData] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(new Date())
  const [startDateTime, setStartDateTime] = useState(null)
  const [endDateTime, setEndDateTime] = useState(null)
  const [dateTime, setDateTime] = useState(false)
  const [currentDate, setCurrentDate] = useState(true)
  const [currentMonth, setCurrentMonth] = useState(false)
  const [between, setBetween] = useState(false)
  const [prevMonth, setPrevMonth] = useState(false)
  const [month, setMonth] = useState(new Date())
  const [isLoading, setIsLoading] = useState(false)
  const [csvData, setCSVData] = useState([])
  const userList = useMemo(() => {
    return _.filter(userFormDB, (i) => {
      return i.salaryType === 'daily'
    })
  }, [userFormDB])

  const [filter, setFilter] = useState({
    branch: '',
    employee: '',
  })

  useEffect(() => {
    getBranchData()
    getAllUsers()
  }, [getBranchData, getAllUsers])

  const csvHeaders = [
    { label: 'ชื่อพนักงาน', key: 'name' },
    { label: 'วันที่', key: 'date' },
    { label: 'ค่าจ้าง', key: 'salary' },
    { label: 'ค่าคอมมิชชั่น', key: 'commission' },
    { label: 'ค่าจ้างรวม', key: 'totalWage' },
  ]

  const columnsAntdData = [
    {
      title: 'ชื่อพนักงาน',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (data, record) => (
        <Box
          cursor={record.href ? 'pointer' : 'default'}
          onClick={() => {
            Navigate(record.href)
          }}
        >
          {data}
        </Box>
      ),
    },
    {
      title: 'ช่วงวันที่',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      render: (data, record) => (
        <Box
          cursor={record.href ? 'pointer' : 'default'}
          onClick={() => {
            Navigate(record.href)
          }}
        >
          {data}
        </Box>
      ),
    },
    {
      title: 'ค่าจ้าง',
      dataIndex: 'salary',
      key: 'salary',
      align: 'center',
      render: (data, record) => (
        <Box
          cursor={record.href ? 'pointer' : 'default'}
          onClick={() => {
            Navigate(record.href)
          }}
        >
          {currency(data ? data : 0, 2)}
        </Box>
      ),
    },
    {
      title: 'ค่าคอมมิชชั่น',
      dataIndex: 'commission',
      key: 'commission',
      align: 'center',
      render: (data, record) => (
        <Box
          cursor={record.href ? 'pointer' : 'default'}
          onClick={() => {
            Navigate(record.href)
          }}
        >
          {currency(data ? data : 0, 2)}
        </Box>
      ),
    },
    {
      title: 'ค่าจ้างรวม',
      dataIndex: 'totalWage',
      key: 'totalWage',
      align: 'center',
      render: (data, record) => (
        <Box
          cursor={record.href ? 'pointer' : 'default'}
          onClick={() => {
            Navigate(record.href)
          }}
        >
          {currency(data ? data : 0, 2)}
        </Box>
      ),
    },
    {
      // title: 'พิมใบแจ้งรายได้',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (data, record) => (
        <Center>
          <PrintPDfIncome record={record} />
        </Center>
      ),
    },
  ]

  const calCommssionData = async (orders, users, works) => {
    const calCom = calCommissionEmployeeDailyWithEncode(orders, users, works)
    const mapEmpData = _.map(users, (emp) => {
      const findCommission = _.filter(calCom, (i) => {
        return emp?.id === i?.id
      })
      const pluckSalary = _.pluck(findCommission, 'salary')
      const pluckGrandCommission = _.pluck(findCommission, 'grandCommission')
      const sumSalary = _.reduce(pluckSalary, (memo, num) => memo + num, 0)
      const sumCommission = _.reduce(
        pluckGrandCommission,
        (memo, num) => memo + num,
        0
      )

      return {
        // ...findCommission,
        id: emp.id,
        date: currentDate
          ? moment().endOf('D').format('MM/DD/YYYY')
          : currentMonth
          ? `${
              moment().startOf('M').format('MM/DD/YYYY') +
              ' - ' +
              moment().endOf('D').format('MM/DD/YYYY')
            }`
          : prevMonth
          ? `${
              moment(month).startOf('M').format('MM/DD/YYYY') +
              ' - ' +
              moment(month).endOf('M').format('MM/DD/YYYY')
            }`
          : between
          ? moment(endDate).endOf('D').format('MM/DD/YYYY')
          : '-',
        salary: sumSalary,
        totalWage: sumSalary + sumCommission,
        commission: sumCommission ? sumCommission : 0,
        name: emp?.name,
        branch: emp?.branch,
        href: `/employees/commission/daily/${emp.id}/${
          between
            ? 'between'
            : currentDate
            ? 'currentDate'
            : currentMonth
            ? 'currentMonth'
            : dateTime
            ? 'dateTime'
            : prevMonth
            ? 'prevMonth'
            : ''
        }${
          prevMonth
            ? `/?month=${new Date(moment(month).startOf('M')).toISOString()}`
            : between
            ? `/?endDate=${new Date(moment(endDate).endOf('D')).toISOString()}`
            : dateTime
            ? `/?dateTime=${
                new Date(moment(startDateTime).startOf('D')).toISOString() +
                '#' +
                new Date(moment(endDateTime).endOf('D')).toISOString()
              }`
            : ''
        }`,
      }
    })
    return mapEmpData
  }

  async function onSubmitFilter() {
    setIsLoading(true)
    setData([])
    const getWorkTime = await getWorkByDate({
      type: between
        ? 'between'
        : currentDate
        ? 'currentDate'
        : currentMonth
        ? 'currentMonth'
        : dateTime
        ? 'dateTime'
        : prevMonth
        ? 'prevMonth'
        : '',
      month: month ? new Date(moment(month).startOf('M')).toISOString() : null,
      endDate: endDate
        ? new Date(moment(endDate).startOf('D')).toISOString()
        : null,
      dateTime: dateTime
        ? new Date(moment(startDateTime).startOf('D')).toISOString() +
          '#' +
          new Date(moment(endDateTime).endOf('D')).toISOString()
        : '',
    })

    const res = await getAllOrdersWithDate({
      startDate: startDate,
      endDate: endDate,
      month: month,
      between: between,
      prevMonth: prevMonth,
      currentDate: currentDate,
      currentMonth: currentMonth,
      dateTime: dateTime,
    })
    if (filter?.branch !== '') {
      if (filter?.employee !== '') {
        const filterOrderBranch = await _.filter(res, (order) => {
          return (
            order?.branch?.id === filter?.branch &&
            order?.createdBy?.id === filter?.employee
          )
        })
        const filteEmp = await _.find(userList, { id: filter?.employee })
        const getCommission = await calCommssionData(
          filterOrderBranch,
          [filteEmp],
          getWorkTime
        )
        const dataToCSV = _.map(getCommission, (items) => {
          return {
            ...items,
            commission: currency(items.commission),
            date: items.date,
            salary: currency(items.salary, 2),
            totalWage: currency(items.totalWage, 2),
          }
        })
        setCSVData(dataToCSV)
        setData(getCommission)
        setIsLoading(false)
      } else {
        const filterOrderBranch = _.filter(res, (order) => {
          return order?.branch?.id === filter?.branch
        })
        const getCommission = await calCommssionData(
          filterOrderBranch,
          userList,
          getWorkTime
        )
        // const filterUseBranch = findUserBranchInBranch(userList, filter?.branch)
        const filterUserBranch = _.filter(getCommission, (i) => {
          if (
            _.find(i.branch, (e) => {
              return e.id === filter?.branch
            })
          ) {
            return i
          }
        })
        const dataToCSV = _.map(filterUserBranch, (items) => {
          return {
            ...items,
            commission: currency(items.commission),
            date: items.date,
            salary: currency(items.salary, 2),
            totalWage: currency(items.totalWage, 2),
          }
        })

        setCSVData(dataToCSV)
        setData(filterUserBranch)
        setIsLoading(false)
      }
    } else {
      if (filter?.employee !== '') {
        const filterOrderWithEmployee = _.filter(res, (emp) => {
          return emp?.createdBy?.id === filter?.employee
        })
        const filterUser = _.find(userList, { id: filter?.employee })
        const getCommission = await calCommssionData(
          filterOrderWithEmployee,
          [filterUser],
          getWorkTime
        )
        const dataToCSV = _.map(getCommission, (items) => {
          return {
            ...items,
            commission: currency(items.commission),
            date: items.date,
            salary: currency(items.salary, 2),
            totalWage: currency(items.totalWage, 2),
          }
        })
        setCSVData(dataToCSV)
        setData(getCommission)
        setIsLoading(false)
      } else {
        const getCommission = await calCommssionData(res, userList, getWorkTime)
        const dataToCSV = _.map(getCommission, (items) => {
          return {
            ...items,
            commission: currency(items.commission),
            date: items.date,
            salary: currency(items.salary, 2),
            totalWage: currency(items.totalWage, 2),
          }
        })
        setCSVData(dataToCSV)
        setData(getCommission)
        setIsLoading(false)
      }
    }
  }

  // <--------------หน้าดูรายละเอียดพนักงาน---------------->
  //   /employees/commission/view
  // <--------------หน้าดูรายละเอียดพนักงาน---------------->

  return (
    <>
      <HStack
        my='1em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='0vw'
        flexDirection={{ sm: 'column', md: 'column' }}
      >
        <Stack alignItems={'end'} w='full'>
          <CSVDownloadForm
            data={csvData}
            filename={'dailyEmployees'}
            headers={csvHeaders}
            buttonTitle={'ดาวน์โหลดไฟล์พนักงานรายวัน'}
          />
        </Stack>
        <HStack
          alignItems={{ sm: 'flex-end', md: 'start' }}
          flexDirection={{ sm: 'column', md: 'row' }}
          spacing={{ sm: '0vh', md: '1vw' }}
          w='100%'
        >
          <Flex flexDirection='column' w='100%'>
            <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
              <Text color='#8F9BBA'>ช่วงเวลา :</Text>
            </Box>
            <SearchDateMonth
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              month={month}
              setMonth={setMonth}
              setBetween={setBetween}
              setPrevMonth={setPrevMonth}
              setCurrentDate={setCurrentDate}
              setCurrentMonth={setCurrentMonth}
              setStartDateTime={setStartDateTime}
              setEndDateTime={setEndDateTime}
              setDateTime={setDateTime}
              startDateTime={startDateTime}
              endDateTime={endDateTime}
            />
          </Flex>
          <Flex pt={{ sm: '1rem', md: '0rem' }} flexDirection='column' w='100%'>
            <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
              <Text color='#8F9BBA'>สาขา :</Text>
            </Box>
            <Select
              color={'gray.700'}
              w='full'
              bg={'#F4F7FE'}
              border='0px solid #E8E9E9'
              borderRadius='16px'
              defaultValue=''
              fontWeight='500'
              variant='main'
              placeholder={'ทั้งหมด'}
              _placeholder={{
                fontWeight: '400',
              }}
              h='44px'
              maxh='44px'
              onChange={(event) =>
                setFilter({ ...filter, branch: event.target.value })
              }
            >
              {BranchNoHQ?.map((branch, index) => {
                return (
                  <option value={branch?.id} key={index}>
                    {branch?.name}
                  </option>
                )
              })}
            </Select>
          </Flex>
          <Flex pt={{ sm: '1rem', md: '0rem' }} flexDirection='column' w='100%'>
            <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
              <Text color='#8F9BBA'>ชื่อพนักงาน :</Text>
            </Box>
            <Select
              color={'gray.700'}
              w='full'
              bg={'#F4F7FE'}
              border='0px solid #E8E9E9'
              borderRadius='16px'
              defaultValue=''
              fontWeight='500'
              variant='main'
              placeholder={'ทั้งหมด'}
              _placeholder={{
                fontWeight: '400',
              }}
              h='44px'
              maxh='44px'
              onChange={(event) =>
                setFilter({ ...filter, employee: event.target.value })
              }
            >
              {userList?.map((emp, index) => {
                return (
                  <option value={emp?.id} key={index}>
                    {emp?.name}
                  </option>
                )
              })}
            </Select>
          </Flex>
          <Flex
            flexDirection='column'
            w='max-content'
            alignItems='end'
            pt={{ sm: '1rem', md: '0rem' }}
          >
            <Box
              textAlign='start'
              display={{ sm: 'none', md: 'flex' }}
              p='0.5rem'
              pl='1rem'
              pt='0rem'
            >
              <Text color='#8F9BBA'>&nbsp;</Text>
            </Box>
            <HStack>
              <Button
                h='44px'
                maxh='44px'
                w={{ sm: '10rem', md: '10rem' }}
                colorScheme='btn'
                borderRadius='16px'
                display='flex'
                justifyContent={'space-around'}
                color='white'
                onClick={() => onSubmitFilter()}
                disabled={isLoading}
                isLoading={isLoading}
              >
                <Text>
                  <SearchIcon />
                </Text>
                <Text>แสดงรายงาน</Text>
              </Button>
            </HStack>
          </Flex>
        </HStack>
      </HStack>

      <Box w={'full'}>
        <Table
          loading={isLoading}
          columns={columnsAntdData}
          dataSource={data}
          size={'large'}
        />
      </Box>
    </>
  )
}

export default StaffCommissionDaily

const PrintPDfIncome = ({ record }) => {
  const [pendingLoading, setPendingLoading] = useState(false)

  const handlePrintIncome = async () => {
    setPendingLoading(true)
    createPDFEmployeeIncome(record)
      .then((e) => {
        URL(e.data.pdfRef).then(() => {
          setPendingLoading(false)
        })
        setPendingLoading(false)
      })
      .catch((err) => {
      })
  }

  return (
    <IconButton
      isLoading={pendingLoading}
      disabled={pendingLoading}
      icon={<BsFillPrinterFill />}
      onClick={() => {
        handlePrintIncome()
      }}
    />
  )
}
