import { Stack } from '@chakra-ui/react'
import FormDiscountProduct from 'Components/form/discountProduct/formDiscountProduct'

export default function AddDiscountProduct({ user }) {
  return (
    <Stack direction='column'>
      <FormDiscountProduct data={null} />
    </Stack>
  )
}
