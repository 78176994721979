import {
  Box,
  Button,
  Image,
  HStack,
  Icon,
  Stack,
  Text,
  Center,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { useAuth, useBranch } from 'Hooks'
import { IoLogOutOutline } from 'react-icons/io5'
import _ from 'underscore'

function ChooseMerchant({ user }) {
  const { userSelectBranch, logout } = useAuth()
  const { Branch, getBranchData } = useBranch()
  const [myBranch, setMyBranch] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [logoutIsLoading, setLogoutIsLoading] = useState(false)
  const Navigate = useNavigate()

  useEffect(() => {
    getBranchData()
  }, [getBranchData])

  useEffect(() => {
    setMyBranch(
      user?.branch?.map((item) => Branch?.find((itm) => itm?.id === item?.id))
    )
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [Branch, user])

  const handleLogout = () => {
    setLogoutIsLoading(true)
    logout()
      .then(() => {
        Navigate('/login')
        setLogoutIsLoading(false)
      })
      .catch((e) => {
        setLogoutIsLoading(false)
      })
  }

  function handleBranch(id) {
    const filBranch = _.find(Branch, (item) => {
      if (item?.id === id) {
        return item
      }
    })

    userSelectBranch(
      user?.uid,
      id,
      filBranch?.name,
      filBranch?.typeOfBranch,
      filBranch?.wholeSale
    )
    // .then(() => {
    //   Navigate(
    //     user?.role === 'Administrator'
    //       ? '/dashboard/admin'
    //       : user?.role === 'Mananger'
    //       ? '/dashboard/manager'
    //       : user?.role === 'Cashier' && '/pos/cashier'
    //   )
    // })
  }

  return !isLoading ? (
    <Stack
      pt='5em'
      direction={'column'}
      justifyContent='start'
      alignItems={'center'}
      minH='100vh'
    >
      <Center w='100%' bg='none' h='auto' top='5rem'>
        <Image
          w='15rem'
          h='auto'
          alt='Logo'
          objectFit='cover'
          src='/goMaew/LOGO_GOMAEW-02.png'
          boxSize={'12rem'}
        />
      </Center>

      <Center pb='1rem' w='100%' fontFamily='Prompt'>
        <Text
          fontSize={{
            base: '18px',
            sm: '18px',
            md: '20px',
            lg: '22px',
            xl: '24px',
            '2xl': '26px',
          }}
          color='login.500'
        >
          เลือกสาขาที่คุณต้องการใช้งาน
        </Text>
      </Center>

      <Box h='full' w='full' pb='4rem'>
        {user?.role === 'Administrator' &&
          Branch?.filter((item) => item?.typeOfBranch === 'HQ')?.map(
            (itm, index) => {
              return (
                <Stack
                  py='0.5rem'
                  direction={'column'}
                  alignItems={'center'}
                  justifyContent='space-between'
                  w='full'
                  h='full'
                  spacing='2em'
                  key={index}
                >
                  <HStack
                    as={Link}
                    to={'/dashboard/admin'}
                    p='2em'
                    py='1'
                    justifyContent={'space-between'}
                    borderRadius={'0.5em'}
                    border='1px'
                    borderColor='login.500'
                    w={{
                      base: '90%',
                      sm: '90%',
                      md: '30rem',
                      lg: '35rem',
                      xl: '35rem',
                    }}
                    transition='all 0.4s'
                    _hover={{
                      cursor: 'pointer',
                      bg: 'blue.50',
                      color: 'login.500',
                      transition: 'all 0.4s',
                    }}
                    onClick={() => handleBranch(itm?.id)}
                  >
                    <Text>{itm?.name}</Text>
                    <Icon>
                      <ChevronRightIcon />
                    </Icon>
                  </HStack>
                </Stack>
              )
            }
          )}
        {myBranch
          ?.filter((item) => item?.typeOfBranch !== 'HQ')
          ?.map((itm, index) => (
            <Stack
              py='0.5rem'
              direction={'column'}
              alignItems={'center'}
              justifyContent='space-between'
              w='full'
              h='full'
              spacing='2em'
              key={index}
            >
              <HStack
                as={Link}
                to={
                  user?.role === 'Administrator'
                    ? '/dashboard/admin'
                    : user?.role === 'Mananger'
                    ? '/dashboard/manager'
                    : user?.role === 'Cashier' && '/pos/cashier'
                }
                p='2em'
                justifyContent={'space-between'}
                borderRadius={'0.5em'}
                border='1px'
                py='1rem'
                borderColor='login.500'
                transition='all 0.4s'
                w={{
                  base: '90%',
                  sm: '90%',
                  md: '30rem',
                  lg: '35rem',
                  xl: '35rem',
                }}
                _hover={{
                  cursor: 'pointer',
                  bg: 'blue.50',
                  color: 'login.500',
                  transition: 'all  0.4s',
                }}
                onClick={() => handleBranch(itm?.id)}
              >
                <Text>{itm?.name}</Text>
                <Icon>
                  <ChevronRightIcon />
                </Icon>
              </HStack>
            </Stack>
          ))}
      </Box>
      <Center position='fixed' bottom='15px' right='10px'>
        <Button
          onClick={() => handleLogout()}
          borderRadius='10px'
          _hover={{ color: 'red' }}
          isLoading={logoutIsLoading}
          disabled={logoutIsLoading}
          loadingText={'SignOut ....'}
        >
          <HStack>
            <IoLogOutOutline />
            <Text>logout</Text>
          </HStack>
        </Button>
      </Center>
    </Stack>
  ) : (
    <Center
      h={{ base: '70vh', sm: '70vh', md: '50vh', lg: '50vh' }}
      flexDirection='column'
      w='100%'
    >
      <Center flexDirection='column'>
        <Text pb='1rem' fontSize='xl' color='loading.100'>
          รอดำเนินการ
        </Text>
        <Box className='loading' color='loading.100'></Box>
      </Center>
    </Center>
  )
}

export default ChooseMerchant
