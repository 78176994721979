import { Center, Fade, Spinner, Stack } from '@chakra-ui/react'
import FormPurchaseOrder from 'Components/form/formPurchaseOrder'
import { useEffect, useState } from 'react'

export default function CreatePO({ user }) {
  const [isLoading, setIsLoading] = useState(true)
  const [isFade, setIsFade] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
      setIsFade(false)
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Stack direction='column'>
      {!isLoading ? (
        <Fade in={!isFade}>
          <FormPurchaseOrder user={user} />
        </Fade>
      ) : (
        <Center>
          <Spinner size='md' />
        </Center>
      )}
    </Stack>
  )
}
