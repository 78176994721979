import React from 'react'
import {
  Grid,
  GridItem,
  Image,
  Flex,
  Text,
  Center,
  HStack,
  VStack,
} from '@chakra-ui/react'
import { decodeUnit } from 'Utils/currency'
const _ = require('underscore')
export function CardRefund({
  data,
  inCart,
  isCheckItem,
  setItems,
  setSecond,
  setFirst,
  orderRef,
}) {
  const onRefund = _.filter(isCheckItem, (itm) => itm.id === data.id)
  const onRefundAmount = _.reduce(
    onRefund,
    (value, itm) => value + itm.amount,
    0
  )
  const amountOnCart = _.pluck(
    _.filter(inCart, (itm) => itm.id === data.id),
    'amount'
  )[0]
  const total = _.filter(inCart, (itm) => itm.id === data.id)[0]
  const flatten = _.flatten(orderRef)
  const filter = _.filter(flatten, (itm) => itm.id === data.id)
  const amountRefund = _.reduce(
    filter,
    (value, itm) => value + parseFloat(itm.amount) / 100,
    0
  )
  return (
    <>
      <Grid
        w='100%'
        transition='all 0.4s'
        _hover={{ bg: '#E9EDF7', transition: 'all 0.4s' }}
        borderBottom='1px dashed'
        borderColor='#E9EDF7'
        pb={{ base: '0.5rem', md: '0.5rem' }}
        templateColumns={{
          base: 'repeat(3, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(3, 1fr)',
        }}
        gap={{ base: '0px', sm: '10px', md: '0px' }}
        bg='white'
        cursor='pointer'
        onClick={() => {
          if (amountOnCart.toString() !== amountRefund.toString()) {
            setItems({
              ...data,
              amount: data.amount - amountRefund,
            })
            setSecond(true)
            setFirst(false)
          }
        }}
      >
        <GridItem
          w={{ base: '5rem', sm: '5rem', md: '100%' }}
          justifyContent='flex-start'
          display={{
            base: 'GridItem',
            md: 'GridItem',
            lg: 'GridItem',
            xl: 'GridItem',
            '2xl': 'GridItem',
          }}
          px={{ sm: '0.2rem', md: '0rem' }}
        >
          <Center alignItems='center'>
            <Image
              borderRadius='10px'
              w={{ base: '5rem', md: '5rem', lg: '5rem' }}
              h={{ base: '4rem', md: '4rem', lg: '5rem' }}
              objectFit='cover'
              alt={data?.img}
              src={data?.img}
            />
          </Center>
        </GridItem>
        <GridItem
          pl={{ md: '0rem', lg: '0rem' }}
          justify={{
            base: 'center',
            md: 'center',
            lg: 'center',
            xl: 'center',
            '2xl': 'space-between',
          }}
          flexDirection={{
            base: 'row',
            md: 'column',
            lg: 'column',
            xl: 'column',
            '2xl': 'row',
          }}
          align={'center'}
          width='full'
          gap={0}
          w='full'
        >
          <Flex flexDirection='column' justifyContent='center' h='full'>
            <Flex
              flexDirection={'row'}
              w='100%'
              textAlign={{
                base: 'left',
                md: 'center',
                lg: 'center',
                xl: 'center',
                '2xl': 'left',
              }}
            >
              <Text
                userSelect='none'
                fontSize={{
                  base: '14px',
                  md: '14px',
                  lg: '14px',
                  xl: 'md',
                }}
                noOfLines='1'
                w={{ sm: 'auto', md: 'auto' }}
              >
                {data?.name}
              </Text>
              <Text
                userSelect='none'
                fontSize={{
                  base: '14px',
                  md: '14px',
                  lg: '14px',
                  xl: 'md',
                }}
                noOfLines='1'
              ></Text>
            </Flex>
            <VStack alignItems='start'>
              <Flex justifyContent={{ md: 'space-evenly', lg: 'flex-start' }}>
                <HStack
                  spacing={'30px'}
                  w={{ sm: 'full', md: 'full', lg: 'full', xl: 'full' }}
                  justifyContent='center'
                >
                  <Text
                    userSelect='none'
                    fontSize={{
                      base: '14px',
                      md: '14px',
                      lg: '14px',
                      xl: 'md',
                    }}
                    color='pos.500'
                    w='full'
                  >
                    {amountOnCart} x {data?.price / 100}
                  </Text>
                </HStack>
              </Flex>
              {filter.length > 0 && (
                <Text color='red.500'>คืนเงินแล้ว x {amountRefund}</Text>
              )}
              {onRefund.length > 0 && (
                <Text color='yellow.400'>คืนเงิน x {onRefundAmount}</Text>
              )}
            </VStack>
          </Flex>
          <Flex
            display={{
              base: 'flex',
              md: 'flex',
              lg: 'flex',
              xl: 'flex',
              '2xl': 'none',
            }}
            flexDirection={'column'}
            justifyContent='center'
            alignItems='center'
            w='full'
            px='2rem'
          ></Flex>
        </GridItem>
        <GridItem w='100%'>
          <HStack
            justifyContent='center'
            w='full'
            spacing={'0px'}
            color='pos.500'
            userSelect='none'
            alignItems='center'
            h='full'
            pr='0.5rem'
            textAlign='end'
          >
            <Text fontSize={{ md: '14px', lg: '14px', xl: 'md' }} w='full'>
              {new Intl.NumberFormat('th-TH', {
                minimumFractionDigits: 2,
              }).format(total.amount * decodeUnit(total.price))}
            </Text>
          </HStack>
        </GridItem>
      </Grid>
    </>
  )
}

export default CardRefund
