import { db } from 'Config/firebase'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import moment from 'moment'
import {
  calCommissionEmployeeDailyWithDecode,
  calCommissionItem,
  // calCommissionPaidRefund,
  commissionEmployee,
  findUserBranchInBranch,
} from 'Utils'
import { decodeUnit } from 'Utils/currency'

const _ = require('underscore')

const prevDate = new Date()
prevDate.setHours(0)
prevDate.setMinutes(0)
prevDate.setSeconds(0)
prevDate.setMilliseconds(0)

const nextDate = new Date()
nextDate.setDate(nextDate.getDate() + 1)
nextDate.setHours(0)
nextDate.setMinutes(0)
nextDate.setSeconds(0)
nextDate.setMilliseconds(0)

const prevMonthDate = new Date()
prevMonthDate.setDate(1)
prevMonthDate.setHours(0)
prevMonthDate.setMinutes(0)
prevMonthDate.setSeconds(0)
prevMonthDate.setMilliseconds(0)

const nextMonth = new Date()
nextMonth.setMonth(nextMonth.getMonth() + 1)
nextMonth.setDate(0)
nextMonth.setHours(23)
nextMonth.setMinutes(59)
nextMonth.setSeconds(59)
nextMonth.setMilliseconds(59)

export async function salesReport({
  month,
  startDate,
  endDate,
  between,
  prevMonth,
  currentDate,
  currentMonth,
  user,
  status,
  emp,
  startDateTime,
  endDateTime,
  dateTime,
}) {
  const start = new Date(startDate)
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  start.setMilliseconds(0)

  const dateEnd = new Date(endDate)
  dateEnd.setDate(dateEnd.getDate() + 1)
  dateEnd.setHours(0)
  dateEnd.setMinutes(0)
  dateEnd.setSeconds(0)
  dateEnd.setMilliseconds(0)

  const startMonth = new Date(month)
  startMonth.setDate(1)
  startMonth.setHours(0)
  startMonth.setMinutes(0)
  startMonth.setSeconds(0)
  startMonth.setMilliseconds(0)

  const endMonth = new Date(month)
  endMonth.setMonth(endMonth.getMonth() + 1)
  endMonth.setDate(0)
  endMonth.setHours(23)
  endMonth.setMinutes(59)
  endMonth.setSeconds(59)
  endMonth.setMilliseconds(59)

  const qOrder = prevMonth
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', startMonth),
        where('createdAt', '<=', endMonth),
        orderBy('createdAt', 'desc')
      )
    : between
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', start),
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )
    : currentMonth
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', prevMonthDate),
        where('createdAt', '<=', nextMonth),
        orderBy('createdAt', 'desc')
      )
    : dateTime
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', startDateTime),
        where('createdAt', '<=', endDateTime),
        orderBy('createdAt', 'desc')
      )
    : currentDate &&
      query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', prevDate),
        where('createdAt', '<=', nextDate),
        orderBy('createdAt', 'desc')
      )

  const results = await (
    await getDocs(qOrder)
  ).docs.map((doc) => ({
    ...doc.data(),
    id: doc?.id,
    branch: doc.data()?.branch?.name,
    totalDiscount: decodeUnit(doc.data()?.totalDiscount),
    total: decodeUnit(doc.data()?.total),
    grandTotal: decodeUnit(doc.data()?.grandTotal),
    vat: decodeUnit(doc.data()?.vat),
    subTotal: decodeUnit(doc.data()?.subTotal),
    date: moment(doc.data().createdAt?.toDate()).format('MM/DD/YYYY HH:mm:ss'),
  }))

  const statusResults = _.map(results, (item) => {
    const calCost = _.reduce(
      item?.items,
      (memo, num) => memo + decodeUnit(num?.cost) * decodeUnit(num?.amount),
      0
    )

    const calProfit = item?.grandTotal - calCost

    const DataItem = item?.items
    const FlatternPluckDiscountItems = _.flatten(
      _.pluck(
        _.filter(DataItem, (f) => f.discount),
        'discount'
      )
    )
    const discount = item?.discount ? item?.discount : []
    const joinDiscount = [discount, FlatternPluckDiscountItems]
    const flatternJoin = _.flatten(joinDiscount)
    return {
      ...item,
      totalDiscount:
        item?.status === 'refund'
          ? item?.totalDiscount * -1
          : item?.totalDiscount,
      totalCost: item?.status === 'refund' ? calCost * -1 : calCost,
      totalProfit: item?.status === 'refund' ? calProfit * -1 : calProfit,
      grandTotal:
        item?.status === 'refund' ? item?.grandTotal * -1 : item?.grandTotal,
      allOrder: flatternJoin?.length > 0 ? flatternJoin : [],
    }
  })

  const searchResults = status
    ? status && emp
      ? statusResults?.length > 0
        ? statusResults?.filter(
            (item) => item?.createdBy?.id === emp || item?.status === status
          )
        : []
      : statusResults?.filter((item) => item?.status === status)
    : statusResults?.length > 0
    ? statusResults
    : []

  return {
    searchResults: searchResults,
    results: results,
  }
}

export async function productReport({
  month,
  startDate,
  endDate,
  between,
  prevMonth,
  currentDate,
  currentMonth,
  user,
  category,
  startDateTime,
  endDateTime,
  dateTime,
}) {
  const start = new Date(startDate)
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  start.setMilliseconds(0)

  const dateEnd = new Date(endDate)
  dateEnd.setDate(dateEnd.getDate() + 1)
  dateEnd.setHours(0)
  dateEnd.setMinutes(0)
  dateEnd.setSeconds(0)
  dateEnd.setMilliseconds(0)

  const startMonth = new Date(month)
  startMonth.setDate(1)
  startMonth.setHours(0)
  startMonth.setMinutes(0)
  startMonth.setSeconds(0)
  startMonth.setMilliseconds(0)

  const endMonth = new Date(month)
  endMonth.setMonth(endMonth.getMonth() + 1)
  endMonth.setDate(0)
  endMonth.setHours(23)
  endMonth.setMinutes(59)
  endMonth.setSeconds(59)
  endMonth.setMilliseconds(59)

  const qOrder = category
    ? prevMonth
      ? query(
          collection(db, 'Orders'),
          where('branch.id', '==', user?.branchSelect?.id),
          where('category', '==', category),
          where('createdAt', '>=', startMonth),
          where('createdAt', '<=', endMonth),
          orderBy('createdAt', 'desc')
        )
      : between
      ? query(
          collection(db, 'Orders'),
          where('branch.id', '==', user?.branchSelect?.id),
          where('category', '==', category),
          where('createdAt', '>=', start),
          where('createdAt', '<=', dateEnd),
          orderBy('createdAt', 'desc')
        )
      : currentMonth
      ? query(
          collection(db, 'Orders'),
          where('branch.id', '==', user?.branchSelect?.id),
          where('category', '==', category),
          where('createdAt', '>=', prevMonthDate),
          where('createdAt', '<=', nextMonth),
          orderBy('createdAt', 'desc')
        )
      : dateTime
      ? query(
          collection(db, 'Orders'),
          where('branch.id', '==', user?.branchSelect?.id),
          where('category', '==', category),
          where('createdAt', '>=', startDateTime),
          where('createdAt', '<=', endDateTime),
          orderBy('createdAt', 'desc')
        )
      : currentDate &&
        query(
          collection(db, 'Orders'),
          where('branch.id', '==', user?.branchSelect?.id),
          where('category', '==', category),
          where('createdAt', '>=', prevDate),
          where('createdAt', '<=', nextDate),
          orderBy('createdAt', 'desc')
        )
    : prevMonth
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', startMonth),
        where('createdAt', '<=', endMonth),
        orderBy('createdAt', 'desc')
      )
    : between
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', start),
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )
    : currentMonth
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', prevMonthDate),
        where('createdAt', '<=', nextMonth),
        orderBy('createdAt', 'desc')
      )
    : dateTime
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', startDateTime),
        where('createdAt', '<=', endDateTime),
        orderBy('createdAt', 'desc')
      )
    : currentDate &&
      query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', prevDate),
        where('createdAt', '<=', nextDate),
        orderBy('createdAt', 'desc')
      )

  const res = await (
    await getDocs(qOrder)
  ).docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
    branch: doc.data()?.branch?.name,
    totalDiscount: decodeUnit(doc.data()?.totalDiscount),
    total: decodeUnit(doc.data()?.total),
    grandTotal: decodeUnit(doc.data()?.grandTotal),
    vat: decodeUnit(doc.data()?.vat),
    subTotal: decodeUnit(doc.data()?.subTotal),
    date: moment(doc.data().createdAt?.toDate()).format('MM/DD/YYYY'),
    items: doc.data()?.items?.map((i) => ({
      ...i,
      status: doc?.data()?.status,
    })),
  }))

  const cal = _.map(res, (e) => {
    return {
      ...e,
      items: e.discount
        ? e.items?.map((i) => {
            const calDiscount = _.reduce(
              e.discount,
              (memo, num) =>
                num?.valueDiscountType === 'Percentage'
                  ? memo + (e?.total * decodeUnit(num?.valueDiscount)) / 100
                  : memo + decodeUnit(num?.valueDiscount),
              0
            )
            // const tDiscount = e.totalDiscount
            // const tTotal = e.total
            const tGrandTotal = e.grandTotal
            const calGrandTotal = tGrandTotal + calDiscount
            // const oTotal = decodeUnit(i.total)
            const oAmount = decodeUnit(i.amount)
            // const oPrice = decodeUnit(i.price)
            const ograndTotal = decodeUnit(i.grandTotal)

            const pPercent = parseFloat(
              ((ograndTotal / calGrandTotal) * 100).toFixed(2)
            )
            const calValue = parseFloat(
              ((pPercent * calDiscount) / 100).toFixed(2)
            )
            const grandTotal = ograndTotal - calValue
            // const aPrice = oPrice * oAmount
            // const pPrice = parseFloat(((aPrice / tTotal) * 100).toFixed(2))
            // const cPrice = parseFloat(((pPrice / 100) * tDiscount).toFixed(2))
            // const cTotal = oTotal - cPrice
            // const caPrice = cTotal
            //   ? parseFloat((cTotal / oAmount).toFixed(2))
            //   : oPrice
            // const caTotal = caPrice
            //   ? parseFloat((caPrice * oAmount).toFixed(2))
            //   : oTotal
            // const grandTotal = caTotal
            //   ? parseFloat((oTotal - decodeUnit(i.totalDiscount)).toFixed(2))
            //   : ograndTotal
            return {
              ...i,
              amount: oAmount,
              totalDiscount: decodeUnit(i.totalDiscount),
              grandTotal: grandTotal,
              cost: decodeUnit(i.cost),
            }
          })
        : e.items?.map((i) => ({
            ...i,
            price: decodeUnit(i.price),
            grandTotal: decodeUnit(i.grandTotal),
            total: decodeUnit(i.total),
            totalDiscount: decodeUnit(i.totalDiscount),
            amount: decodeUnit(i.amount),
            cost: decodeUnit(i.cost),
            commission: decodeUnit(i.commission),
          })),
    }
  })
  return cal
}

export async function employeeSaleReport({
  month,
  startDate,
  endDate,
  between,
  prevMonth,
  currentDate,
  currentMonth,
  user,
  emp,
  startDateTime,
  endDateTime,
  dateTime,
}) {
  const start = new Date(startDate)
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  start.setMilliseconds(0)

  const dateEnd = new Date(endDate)
  dateEnd.setDate(dateEnd.getDate() + 1)
  dateEnd.setHours(0)
  dateEnd.setMinutes(0)
  dateEnd.setSeconds(0)
  dateEnd.setMilliseconds(0)

  const startMonth = new Date(month)
  startMonth.setDate(1)
  startMonth.setHours(0)
  startMonth.setMinutes(0)
  startMonth.setSeconds(0)
  startMonth.setMilliseconds(0)

  const endMonth = new Date(month)
  endMonth.setMonth(endMonth.getMonth() + 1)
  endMonth.setDate(0)
  endMonth.setHours(23)
  endMonth.setMinutes(59)
  endMonth.setSeconds(59)
  endMonth.setMilliseconds(59)

  const qOrder = prevMonth
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', startMonth),
        where('createdAt', '<=', endMonth),
        orderBy('createdAt', 'desc')
      )
    : between
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', start),
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )
    : currentMonth
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', prevMonthDate),
        where('createdAt', '<=', nextMonth),
        orderBy('createdAt', 'desc')
      )
    : dateTime
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', startDateTime),
        where('createdAt', '<=', endDateTime),
        orderBy('createdAt', 'desc')
      )
    : currentDate &&
      query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', prevDate),
        where('createdAt', '<=', nextDate),
        orderBy('createdAt', 'desc')
      )

  const results = await (
    await getDocs(qOrder)
  ).docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
    branch: doc.data()?.branch?.name,
    totalDiscount: decodeUnit(doc.data()?.totalDiscount),
    total: decodeUnit(doc.data()?.total),
    vat: decodeUnit(doc.data()?.vat),
    subTotal: decodeUnit(doc.data()?.subTotal),
    grandTotal: decodeUnit(doc.data()?.grandTotal),
    date: moment(doc.data().createdAt?.toDate()).format('MM/DD/YYYY HH:mm:ss'),
  }))

  const res = emp
    ? results?.filter((item) => item?.createdBy?.id === emp)
    : results

  const paidOrder = _.filter(res, (item) => item?.status === 'paid')
  const refundOrder = _.filter(res, (item) => item?.status === 'refund')

  const resultsAllOrder = paidOrder.map((item) => {
    const refund = refundOrder?.filter((ref) => ref?.ref === item?.id)
    const refundGrandTotal = _.pluck(refund, 'grandTotal')
    const amountRefund = refundGrandTotal?.reduce((memo, num) => memo + num, 0)
    const refundItems = _.flatten(_.pluck(refund, 'items'))

    const calDiscountPaid = _.reduce(
      item.discount,
      (memo, num) =>
        num?.valueDiscountType === 'Percentage'
          ? memo + (item?.total * decodeUnit(num?.valueDiscount)) / 100
          : memo + decodeUnit(num?.valueDiscount),
      0
    )

    const refundCommission = _.reduce(
      refundItems.map((itm) =>
        calCommissionItem(itm, calDiscountPaid, item.grandTotal)
      ),
      (memo, num) => memo + num,
      0
    )
    const commission = _.reduce(
      item?.items.map((itm) =>
        calCommissionItem(itm, calDiscountPaid, item.grandTotal)
      ),
      (memo, num) => memo + num,
      0
    )

    const grandCommission = commission - refundCommission
    const grandTotal = item?.grandTotal - amountRefund
    return {
      ...item,
      paymentAmount: item?.grandTotal,
      refundAmount: amountRefund > 0 ? amountRefund : 0,
      grandTotal: grandTotal,
      grandCommission: grandCommission,
    }
  })

  return resultsAllOrder
}

export async function employeeWagesReport({
  month,
  startDate,
  endDate,
  between,
  prevMonth,
  currentDate,
  currentMonth,
  user,
  emp,
  startDateTime,
  endDateTime,
  dateTime,
}) {
  const start = new Date(startDate)
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  start.setMilliseconds(0)

  const dateEnd = new Date(endDate)
  dateEnd.setDate(dateEnd.getDate() + 1)
  dateEnd.setHours(0)
  dateEnd.setMinutes(0)
  dateEnd.setSeconds(0)
  dateEnd.setMilliseconds(0)

  const startMonth = new Date(month)
  startMonth.setDate(1)
  startMonth.setHours(0)
  startMonth.setMinutes(0)
  startMonth.setSeconds(0)
  startMonth.setMilliseconds(0)

  const endMonth = new Date(month)
  endMonth.setMonth(endMonth.getMonth() + 1)
  endMonth.setDate(0)
  endMonth.setHours(23)
  endMonth.setMinutes(59)
  endMonth.setSeconds(59)
  endMonth.setMilliseconds(59)

  const qOrder = prevMonth
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', startMonth),
        where('createdAt', '<=', endMonth),
        orderBy('createdAt', 'desc')
      )
    : between
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', start),
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )
    : currentMonth
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', prevMonthDate),
        where('createdAt', '<=', nextMonth),
        orderBy('createdAt', 'desc')
      )
    : dateTime
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', startDateTime),
        where('createdAt', '<=', endDateTime),
        orderBy('createdAt', 'desc')
      )
    : currentDate &&
      query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', prevDate),
        where('createdAt', '<=', nextDate),
        orderBy('createdAt', 'desc')
      )

  const results = await (
    await getDocs(qOrder)
  ).docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
    branch: doc.data()?.branch?.name,
    totalDiscount: decodeUnit(doc.data()?.totalDiscount),
    total: decodeUnit(doc.data()?.total),
    vat: decodeUnit(doc.data()?.vat),
    subTotal: decodeUnit(doc.data()?.subTotal),
    grandTotal: decodeUnit(doc.data()?.grandTotal),
    date: moment(doc.data().createdAt?.toDate()).format('MM/DD/YYYY'),
    empId: doc?.data()?.createdBy?.id,
  }))

  const qUser = query(collection(db, 'Users'), where('status', '==', true))
  const UserResults = await (
    await getDocs(qUser)
  ).docs.map((doc) => ({
    ...doc.data(),
    id: doc?.id,
    salary: doc.data()?.salary ? decodeUnit(doc.data()?.salary) : 0,
  }))

  // const qWork = query(
  //   collection(db, 'Works'),
  //   where('branch.id', '==', user?.branchSelect?.id),
  //   orderBy('createdAt', 'desc')
  // )
  var qWork
  if (currentDate) {
    // วันนี้
    qWork = query(
      collection(db, 'Works'),
      where('createdAt', '>=', new Date(moment().startOf('D'))),
      where('createdAt', '<=', new Date(moment().endOf('D'))),
      where('branch.id', '==', user?.branchSelect?.id),
      orderBy('createdAt', 'desc')
    )
  } else if (currentMonth) {
    //เดือนนี้
    qWork = query(
      collection(db, 'Works'),
      where('createdAt', '>=', new Date(moment().startOf('M'))),
      where('createdAt', '<=', new Date(moment().endOf('M'))),
      where('branch.id', '==', user?.branchSelect?.id),
      orderBy('createdAt', 'desc')
    )
  } else if (prevMonth) {
    //เดือนก่อน currentMonth,month
    const startMonth = new Date(moment(new Date(month)).startOf('M'))
    const endMonth = new Date(moment(new Date(month)).endOf('M'))
    qWork = query(
      collection(db, 'Works'),
      where('createdAt', '>=', startMonth),
      where('createdAt', '<=', endMonth),
      where('branch.id', '==', user?.branchSelect?.id),
      orderBy('createdAt', 'desc')
    )
  } else if (between) {
    // between, startDate, endDate
    qWork = query(
      collection(db, 'Works'),
      where('createdAt', '>=', new Date(moment(endDate).startOf('D'))),
      where('createdAt', '<=', new Date(moment(endDate).endOf('D'))),
      where('branch.id', '==', user?.branchSelect?.id),
      orderBy('createdAt', 'desc')
    )
  } else if (dateTime) {
    //dateTime , startDateTime, endDateTime
    qWork = query(
      collection(db, 'Works'),
      where('createdAt', '>=', new Date(startDateTime)),
      where('createdAt', '<=', new Date(endDateTime)),
      where('branch.id', '==', user?.branchSelect?.id),
      orderBy('createdAt', 'desc')
    )
  }

  // const getWorkData = await (await getDocs(qWork)).docs
  // const workData = await _.map(getWorkData, (doc) => {
  //   return {
  //     id: doc?.id,
  //     ...doc?.data(),
  //     date: moment(doc.data().createdAt?.toDate()).format('MM/DD/YYYY'),
  //   }
  // })
  // const filterRef = _.filter(workData, (i) => {
  //   return i.ref
  // })
  // const filterNoRef = _.filter(workData, (i) => {
  //   return !i.ref
  // })
  // const matchInOutWorkTime = await Promise.all(
  //   await _.map(filterNoRef, async (item) => {
  //     const getOffWork = await _.find(filterRef, (i) => {
  //       return item.id === i.ref
  //     })
  //     return {
  //       outTime: getOffWork?.createdAt ? getOffWork?.createdAt : null,
  //       inTime: item?.createdAt,
  //       branch: {
  //         id: item?.branch?.id,
  //         name: item?.branch?.name,
  //       },
  //       role: item?.role,
  //       user: {
  //         id: item?.user?.id,
  //         name: item?.user?.name,
  //         email: item?.user?.email,
  //       },
  //       date: item?.date,
  //       dateTime: item?.date,
  //       time: new Date(item?.createdAt).getTime(),
  //       diff: getOffWork?.createdAt
  //         ? moment(getOffWork?.createdAt?.toDate()).diff(
  //             item?.createdAt?.toDate(),
  //             'hours'
  //           )
  //         : 0,
  //     }
  //   }),
  //   []
  // )
  const workResults = await (
    await getDocs(qWork)
  ).docs.map((doc) => ({
    ...doc.data(),
    id: doc?.id,
    uid: doc.data()?.user?.id,
    date: moment(doc.data().createdAt?.toDate()).format('MM/DD/YYYY'),
    dateTime: moment(doc.data().createdAt?.toDate()).format(
      'MM/DD/YYYY HH:mm:ss'
    ),
    time: new Date(doc.data().createdAt?.toDate()).getTime(),
  }))

  const userOnBranch = findUserBranchInBranch(
    UserResults,
    user?.branchSelect?.id
  )
  const data = commissionEmployee(results, workResults, userOnBranch)
  const resDaily = data?.filter((e) => e.salaryType === 'daily')
  const res = emp ? resDaily?.filter((e) => e.id === emp) : resDaily

  return {
    res: res,
    results: results,
    user: userOnBranch,
  }
}

export async function branchPaymentReport({
  month,
  startDate,
  endDate,
  between,
  prevMonth,
  currentDate,
  currentMonth,
  user,
  typePayment,
  bankSelect,
  startDateTime,
  endDateTime,
  dateTime,
}) {
  const start = new Date(startDate)
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  start.setMilliseconds(0)

  const dateEnd = new Date(endDate)
  dateEnd.setDate(dateEnd.getDate() + 1)
  dateEnd.setHours(0)
  dateEnd.setMinutes(0)
  dateEnd.setSeconds(0)
  dateEnd.setMilliseconds(0)

  const startMonth = new Date(month)
  startMonth.setDate(1)
  startMonth.setHours(0)
  startMonth.setMinutes(0)
  startMonth.setSeconds(0)
  startMonth.setMilliseconds(0)

  const endMonth = new Date(month)
  endMonth.setMonth(endMonth.getMonth() + 1)
  endMonth.setDate(0)
  endMonth.setHours(23)
  endMonth.setMinutes(59)
  endMonth.setSeconds(59)
  endMonth.setMilliseconds(59)

  const qOrder = prevMonth
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', startMonth),
        where('createdAt', '<=', endMonth),
        orderBy('createdAt', 'desc')
      )
    : between
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', start),
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )
    : currentMonth
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', prevMonthDate),
        where('createdAt', '<=', nextMonth),
        orderBy('createdAt', 'desc')
      )
    : dateTime
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', startDateTime),
        where('createdAt', '<=', endDateTime),
        orderBy('createdAt', 'desc')
      )
    : currentDate &&
      query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', prevDate),
        where('createdAt', '<=', nextDate),
        orderBy('createdAt', 'desc')
      )

  const results = await (
    await getDocs(qOrder)
  ).docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
    branch: doc.data()?.branch?.name,
    discount:
      doc.data()?.discount === null
        ? {
            discount: 0,
          }
        : {
            ...doc.data()?.discount,
            discount: decodeUnit(doc.data()?.discount.discount),
          },
    discountBalance: decodeUnit(doc.data()?.discountBalance),
    total: decodeUnit(doc.data()?.total),
    grandTotal: decodeUnit(doc.data()?.grandTotal),
    date: moment(doc.data().createdAt?.toDate()).format('MM/DD/YYYY HH:mm:ss'),
  }))

  const resultsType =
    typePayment === 'cash'
      ? results?.filter((item) => item?.payment === 'cash')
      : typePayment === 'bank'
      ? results?.filter((item) => item?.payment === 'bank')
      : results

  const searchResults =
    typePayment === 'bank' && bankSelect
      ? resultsType?.filter((item) => item?.bank?.id === bankSelect)
      : resultsType

  const paidOrder = _.filter(searchResults, (item) => item?.status === 'paid')
  const refundOrder = _.filter(
    searchResults,
    (item) => item?.status === 'refund'
  )

  const resultsAllOrder = paidOrder.map((item) => {
    const refund = refundOrder?.filter((ref) => ref?.ref === item?.id)
    const refundGrandTotal = _.pluck(refund, 'grandTotal')
    const amountRefund = refundGrandTotal?.reduce((memo, num) => memo + num, 0)
    const grandTotal = item?.grandTotal - amountRefund
    return {
      ...item,
      paymentAmount: item?.grandTotal,
      refundAmount: amountRefund > 0 ? amountRefund : 0,
      grandTotal: grandTotal,
      bankAccount: item?.bank
        ? `${item?.bank?.bank?.name}-${item?.bank?.accountingNumber}`
        : '-',
    }
  })

  return {
    searchResults: resultsAllOrder,
    results: paidOrder,
  }
}

export async function calBranchReportBranchEmployee({
  month,
  startDate,
  endDate,
  between,
  prevMonth,
  currentDate,
  currentMonth,
  user,
  startDateTime,
  endDateTime,
  dateTime,
}) {
  const start = new Date(moment(startDate).startOf('day'))
  const dateEnd = new Date(moment(endDate).endOf('day'))
  const startMonth = new Date(moment(month).startOf('month'))
  const endMonth = new Date(moment(month).endOf('month'))

  const queryOrder = prevMonth
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', startMonth),
        where('createdAt', '<=', endMonth),
        orderBy('createdAt', 'desc')
      )
    : between
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', start),
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )
    : currentMonth
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', prevMonthDate),
        where('createdAt', '<=', nextMonth),
        orderBy('createdAt', 'desc')
      )
    : dateTime
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', startDateTime),
        where('createdAt', '<=', endDateTime),
        orderBy('createdAt', 'desc')
      )
    : currentDate &&
      query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', prevDate),
        where('createdAt', '<=', nextDate),
        orderBy('createdAt', 'desc')
      )

  const queryOrderResult = await (
    await getDocs(queryOrder)
  ).docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
    // branch: doc.data()?.branch?.name,
    totalDiscount: decodeUnit(doc.data()?.totalDiscount),
    total: decodeUnit(doc.data()?.total),
    vat: decodeUnit(doc.data()?.vat),
    subTotal: decodeUnit(doc.data()?.subTotal),
    grandTotal: decodeUnit(doc.data()?.grandTotal),
    date: moment(doc.data().createdAt?.toDate()).format('MM/DD/YYYY'),
    empId: doc?.data()?.createdBy?.id,
  }))

  const qUser = query(collection(db, 'Users'))
  const queryUserResult = await (
    await getDocs(qUser)
  ).docs.map((doc) => ({
    ...doc.data(),
    id: doc?.id,
    salary: doc.data()?.salary ? decodeUnit(doc.data()?.salary) : 0,
  }))

  var qWork
  if (currentDate) {
    // วันนี้
    qWork = query(
      collection(db, 'Works'),
      where('createdAt', '>=', new Date(moment().startOf('D'))),
      where('createdAt', '<=', new Date(moment().endOf('D'))),
      where('branch.id', '==', user?.branchSelect?.id),
      orderBy('createdAt', 'desc')
    )
  } else if (currentMonth) {
    //เดือนนี้
    qWork = query(
      collection(db, 'Works'),
      where('createdAt', '>=', new Date(moment().startOf('M'))),
      where('createdAt', '<=', new Date(moment().endOf('M'))),
      where('branch.id', '==', user?.branchSelect?.id),
      orderBy('createdAt', 'desc')
    )
  } else if (prevMonth) {
    //เดือนก่อน currentMonth,month
    const startMonth = new Date(moment(new Date(month)).startOf('M'))
    const endMonth = new Date(moment(new Date(month)).endOf('M'))
    qWork = query(
      collection(db, 'Works'),
      where('createdAt', '>=', startMonth),
      where('createdAt', '<=', endMonth),
      where('branch.id', '==', user?.branchSelect?.id),
      orderBy('createdAt', 'desc')
    )
  } else if (between) {
    // between, startDate, endDate
    qWork = query(
      collection(db, 'Works'),
      where('createdAt', '>=', new Date(moment(endDate).startOf('D'))),
      where('createdAt', '<=', new Date(moment(endDate).endOf('D'))),
      where('branch.id', '==', user?.branchSelect?.id),
      orderBy('createdAt', 'desc')
    )
  } else if (dateTime) {
    //dateTime , startDateTime, endDateTime
    qWork = query(
      collection(db, 'Works'),
      where('createdAt', '>=', new Date(startDateTime)),
      where('createdAt', '<=', new Date(endDateTime)),
      where('branch.id', '==', user?.branchSelect?.id),
      orderBy('createdAt', 'desc')
    )
  }

  const getWorkData = await (await getDocs(qWork)).docs
  const workData = await _.map(getWorkData, (doc) => {
    return {
      id: doc?.id,
      ...doc?.data(),
      date: moment(doc.data().createdAt?.toDate()).format('MM/DD/YYYY'),
    }
  })
  const filterRef = _.filter(workData, (i) => {
    return i.ref
  })
  const filterNoRef = _.filter(workData, (i) => {
    return !i.ref
  })
  const matchInOutWorkTime = await Promise.all(
    await _.map(filterNoRef, async (item) => {
      const getOffWork = await _.find(filterRef, (i) => {
        return item.id === i.ref
      })
      return {
        outTime: getOffWork?.createdAt ? getOffWork?.createdAt : null,
        inTime: item?.createdAt,
        branch: {
          id: item?.branch?.id,
          name: item?.branch?.name,
        },
        role: item?.role,
        user: {
          id: item?.user?.id,
          name: item?.user?.name,
          email: item?.user?.email,
        },
        salaryType: item?.salaryType,
        date: item?.date,
        diff: getOffWork?.createdAt
          ? moment(getOffWork?.createdAt?.toDate()).diff(
              item?.createdAt?.toDate(),
              'hours'
            )
          : 0,
      }
    }),
    []
  )
  const userOnBranch = await findUserBranchInBranch(
    queryUserResult,
    user?.branchSelect?.id
  )

  const calCom = await calCommissionEmployeeDailyWithDecode(
    queryOrderResult,
    userOnBranch,
    matchInOutWorkTime
  )
  const sumTotal = await _.map(
    calCom,
    (i) => {
      return {
        ...i,
        totalWage: i.commission + i.salary,
        in: i.work?.in ? moment(i.work?.in).format('MM/DD/YYYY') : null,
        out: i.work?.out ? moment(i.work?.out).format('MM/DD/YYYY') : null,
      }
    },
    []
  )
  const sortByDate = await _.sortBy(sumTotal, 'Date')
  return sortByDate
}
