import {
  Center,
  HStack,
  Flex,
  Box,
  Text,
  Button,
  Stack,
  Heading,
  Select,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import CustomTable from 'Components/Horizons/table/customTable'
import { SearchIcon } from '@chakra-ui/icons'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from 'Config/firebase'
// import { decodeUnit } from 'Utils/currency'
// import { calPromotionDecodeUnitAmountMultipleItems } from 'Utils/calStock'
import SearchDate from 'Components/Search/date'
import { calBranchReportBranchEmployee } from './query'
import { currency, findUserBranchInBranch } from 'Utils'

const _ = require('underscore')
// const { paymentMessage } = billStatus()

function Price(props) {
  const { totalWage, grandCommission } = props
  return (
    <HStack justifyContent='end' spacing='4em'>
      <Stack>
        <Box>
          <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
            คอมมิชชั่นสุทธิ
          </Text>
        </Box>
        <Box>
          <Text
            fontSize={{ base: '12px', md: '16px' }}
            color='report.500'
            textAlign='right'
          >
            {currency(grandCommission, 2, true)}
          </Text>
        </Box>
      </Stack>
      <Stack>
        <Box>
          <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
            ค่าจ้างรวมสุทธิ
          </Text>
        </Box>
        <Box>
          <Text
            fontSize={{ base: '12px', md: '16px' }}
            color='report.500'
            textAlign='right'
          >
            {currency(totalWage, 2, true)}
          </Text>
        </Box>
      </Stack>
    </HStack>
  )
}

function EmployeeWages({ user }) {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [filterData, setFilterData] = useState([])
  const [onSearch, setOnSearch] = useState(false)
  const [select, setSelect] = useState('')
  const [month, setMonth] = useState(new Date())
  const [between, setBetween] = useState(false)
  const [prevMonth, setPrevMonth] = useState(false)
  const [currentDate, setCurrentDate] = useState(true)
  const [currentMonth, setCurrentMonth] = useState(false)
  const [userList, setUserList] = useState([])
  const [startDateTime, setStartDateTime] = useState(null)
  const [endDateTime, setEndDateTime] = useState(null)
  const [dateTime, setDateTime] = useState(false)

  useEffect(() => {
    const qUser = query(collection(db, 'Users'), where('status', '==', true))
    getDocs(qUser).then((doc) => {
      const userData = doc.docs.map((e) => ({ ...e.data(), id: e.id }))

      const userOnBranch = findUserBranchInBranch(
        userData,
        user?.branchSelect?.id
      )
      const data = userOnBranch
        ?.filter((f) => f.salaryType === 'daily')
        ?.map((e) => ({
          id: e.id,
          name: e.name,
          isBranch: e.isBranch,
        }))
      setUserList(data)
    })
  }, [user])

  function calGrandTotal(item) {
    const arrGrandTotal = _.pluck(item, 'totalWage')
    return _.reduce(arrGrandTotal, (memo, num) => memo + num, 0)
  }
  function calCommission(item) {
    const arrCommission = _.pluck(item, 'commission')
    return _.reduce(arrCommission, (memo, num) => memo + num, 0)
  }

  async function onSubmitFilter() {
    // const res = await employeeWagesReport({
    //   startDate: startDate,
    //   endDate: endDate,
    //   between: between,
    //   prevMonth: prevMonth,
    //   currentDate: currentDate,
    //   currentMonth: currentMonth,
    //   emp: select,
    //   user: user,
    //   startDateTime: startDateTime,
    //   endDateTime: endDateTime,
    //   dateTime: dateTime,
    //   month: month,
    // })
    const res = await calBranchReportBranchEmployee({
      startDate: startDate,
      endDate: endDate,
      between: between,
      prevMonth: prevMonth,
      currentDate: currentDate,
      currentMonth: currentMonth,
      emp: select,
      user: user,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      dateTime: dateTime,
      month: month,
    })
    if (startDateTime && endDateTime) {
      setStartDate(startDateTime)
      setEndDate(endDateTime)
    } else {
      setStartDateTime(null)
      setEndDateTime(null)
    }
    if (select) {
      const filterEmp = _.find(res, (i) => {
        return i.id === select && i.salaryType === 'daily'
      })
      setOnSearch(true)
      setFilterData([filterEmp])
    } else {
      const filterSalary = _.filter(res, (i) => {
        return i.salaryType === 'daily'
      })
      setOnSearch(true)
      setFilterData(filterSalary)
    }
  }
  return (
    <>
      <HStack
        mt='1rem'
        mb='0.5em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='1vw'
        flexDirection={{ sm: 'column', md: 'row' }}
        alignItems='flex-start'
      >
        <Flex flexDirection='column' w={{ sm: 'full', md: '30vw', lg: '30vw' }}>
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ช่วงเวลา :</Text>
          </Box>
          <SearchDate
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            month={month}
            setMonth={setMonth}
            setBetween={setBetween}
            setPrevMonth={setPrevMonth}
            setCurrentDate={setCurrentDate}
            setCurrentMonth={setCurrentMonth}
            setStartDateTime={setStartDateTime}
            setEndDateTime={setEndDateTime}
            setDateTime={setDateTime}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
          />
        </Flex>
        <Flex
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '20vw', lg: '29vw' }}
        >
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ชื่อพนักงาน :</Text>
          </Box>
          <Select
            color={'gray.700'}
            w='full'
            bg={'#F4F7FE'}
            border='0px solid #E8E9E9'
            borderRadius='16px'
            defaultValue=''
            fontWeight='500'
            variant='main'
            placeholder={'ทั้งหมด'}
            _placeholder={{
              fontWeight: '400',
            }}
            h='44px'
            maxh='44px'
            onChange={(event) => setSelect(event.target.value)}
          >
            {userList?.map((item, index) => (
              <option value={item.id} key={index}>
                {item?.name}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex
          display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        ></Flex>
        <Flex
          flexDirection='column'
          w={{ sm: 'full', md: '19vw', lg: '13vw', xl: '11vw' }}
          alignItems='end'
          pt={{ sm: '1rem', md: '0rem' }}
        >
          <Box
            textAlign='start'
            display={{ sm: 'none', md: 'flex' }}
            p='0.5rem'
            pl='1rem'
            pt='0rem'
          >
            <Text color='#8F9BBA'>&nbsp;</Text>
          </Box>
          <Button
            h='44px'
            maxh='44px'
            w={{ sm: '10rem', md: 'full' }}
            colorScheme='btn'
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            onClick={() => onSubmitFilter()}
            disabled={
              between
                ? !startDate || !endDate
                : dateTime
                ? !startDateTime || !endDateTime
                : false
            }
          >
            <Text>
              <SearchIcon />
            </Text>
            <Text>แสดงรายงาน</Text>
          </Button>
        </Flex>
      </HStack>
      <Flex
        flexDirection='column'
        p={{ sm: '10px', md: '20px 20px 30px 20px' }}
        bg='white'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
        borderRadius='30px'
      >
        <Stack spacing={{ base: '0em', md: '4em' }}>
          <Stack>
            <Heading
              fontFamily={'Prompt'}
              color='#2B3674'
              fontSize={{ base: '24px', md: '30px' }}
            >
              รายงานค่าจ้างพนักงานรายวัน
            </Heading>
            <Box>
              <HStack>
                <Text color='#2B3674'>ณ วันที่ :</Text>
                <Text color='#2B3674'>
                  {moment(new Date().getTime()).format('MM/DD/YYYY')}
                </Text>
              </HStack>
              <HStack>
                <Text color='#2B3674'>ช่วงเวลา :</Text>
                {startDate && endDate && (
                  <Text color='#2B3674'>
                    {moment(startDate).format('MM/DD/YYYY')} -{' '}
                    {moment(endDate).format('MM/DD/YYYY')}
                  </Text>
                )}
              </HStack>
            </Box>
          </Stack>
          <Box
            pt={{ base: '1rem', sm: '1rem', md: '0rem', lg: '0rem' }}
            pr={{
              base: '0rem',
              sm: '0rem',
              md: '1rem',
              lg: '1rem',
              xl: '1rem',
            }}
          >
            <Price
              totalWage={onSearch ? calGrandTotal(filterData) : 0}
              grandCommission={onSearch ? calCommission(filterData) : 0}
            />
          </Box>
        </Stack>
        <CustomTable
          columnsData={columnsDataOrders}
          tableData={onSearch ? filterData : []}
          closeSearch={true}
        />
      </Flex>
    </>
  )
}

export default EmployeeWages

const columnsDataOrders = [
  {
    Header: 'วันที่',
    accessor: 'date',
    extra: (data, row) => <Center key={row}>{row.date}</Center>,
  },
  {
    Header: 'พนักงานขาย',
    accessor: 'name',
    extra: (data, row) => <Center key={row}>{row.name}</Center>,
  },
  {
    Header: 'เวลาเข้างาน',
    accessor: 'timeIn',
    extra: (data, row) => (
      <Center key={row}>
        {row.work?.in
          ? moment(row.work?.in).format('MM/DD/YYYY HH:mm:ss')
          : '-'}
      </Center>
    ),
  },
  {
    Header: 'เวลาออกงาน',
    accessor: 'timeOut',
    extra: (data, row) => (
      <Center key={row}>
        {row.work?.out
          ? moment(row.work?.out).format('MM/DD/YYYY HH:mm:ss')
          : '-'}
      </Center>
    ),
  },
  {
    Header: 'ชั่วโมงการทำงาน',
    accessor: 'timeDiff',
    extra: (data, row) => <Center key={row}>{row.work?.diff}</Center>,
  },
  {
    Header: (
      <Text w='100%' textAlign='end'>
        ค่าจ้างรายวัน
      </Text>
    ),
    accessor: 'salary',
    extra: (data, row) => (
      <HStack justifyContent={'end'} w='full'>
        <Text key={row} textAlign='right'>
          {currency(row.salary, 2)}
        </Text>
      </HStack>
    ),
  },
  {
    Header: (
      <Text w='100%' textAlign='end'>
        คอมมิชชั่น
      </Text>
    ),
    accessor: 'commission',
    extra: (data, row) => (
      <HStack justifyContent={'end'} w='full'>
        <Text key={row} textAlign='right'>
          {currency(row.commission, 2)}
        </Text>
      </HStack>
    ),
  },
  {
    Header: (
      <Text w='100%' textAlign='end'>
        ค่าจ้างรวม
      </Text>
    ),
    accessor: 'totalWage',
    extra: (data, row) => (
      <HStack justifyContent={'end'} w='full'>
        <Text key={row} textAlign='right'>
          {currency(row.totalWage, 2)}
        </Text>
      </HStack>
    ),
  },
]
