// Chakra imports
import {
  Flex,
  FormLabel,
  Textarea,
  Text,
  useColorModeValue,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react'
// Custom components
import React from 'react'
import { Controller } from 'react-hook-form'

export default function TextField({
  mb,
  id,
  label,
  extra,
  placeholder,
  name,
  control,
  required,
  errors,
  defaultValue,
  ...rest
}) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const bgPrimary = useColorModeValue('transparent', 'navy.800')
  const borderPrimary = useColorModeValue('secondaryGray.100', 'whiteAlpha.100')
  return (
    <FormControl id={name} isInvalid={errors[name]} key={name}>
      <Flex direction='column' mb={mb ? mb : '30px'}>
        <FormLabel
          display='flex'
          ms='10px'
          htmlFor={id}
          fontSize='sm'
          color={textColorPrimary}
          fontWeight='bold'
          _hover={{ cursor: 'pointer' }}
        >
          {label}
          <Text fontSize='sm' fontWeight='normal' ms='2px'>
            {extra}
          </Text>
        </FormLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={{
            required,
          }}
          render={({ field: { value, onChange } }) => (
            <Textarea
              id={id}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              h='44px'
              maxh='44px'
              color={textColorPrimary}
              fontSize='sm'
              bg={bgPrimary}
              border='1px solid '
              borderColor={borderPrimary}
              borderRadius={{base:'10px',sm:'10px',md:'10px',lg:'16px'}}
              {...rest}
              _placeholder={{ color: 'secondaryGray.500' }}
            />
          )}
        />
      </Flex>
      <FormErrorMessage>
        {errors[name] && errors[name]?.message}
      </FormErrorMessage>
    </FormControl>
  )
}
