// Chakra imports
import {
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  useColorModeValue,
  Button,
  Box,
  HStack,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Card from 'Components/Horizons/card/Card'
import InputField from 'Components/Horizons/fields/InputField'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
export default function Contact({ data, LinkToEdit }) {
  // Chakra Color Mode
  const navigate = useNavigate()
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const {
    setValue,
    control,
    formState: { errors },
  } = useForm()
  useEffect(() => {
    let isMounted = true
    const fetchValue = () => {
      if (isMounted) {
        setValue('branchTel', data?.branchTel)
        setValue('fax', data?.fax)

        setValue('webSite', data?.webSite)
        setValue('tel', data?.tel)
      }
    }
    fetchValue()
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  return (
    <FormControl>
      <Card mb='20px'>
        <Flex direction='column' mb='40px' ms='10px'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            ข้อมูลการติดต่อ
          </Text>
        </Flex>
        <SimpleGrid columns={{ sm: 1 }} spacing={{ base: '20px', xl: '20px' }}>
          <Flex direction='column'>
            <SimpleGrid
              mb='20px'
              columns={{ sm: 1, md: 2 }}
              spacing={{ base: '20px', xl: '20px' }}
            >
              <InputField
                control={control}
                errors={errors}
                readOnly
                mb='0px'
                id='branchTel'
                label='เบอร์สำนักงาน'
                name='branchTel'
                type='text'
                maxLength={10}
                defaultValue={''}
                pattern={{
                  value: /[0-9]/,
                  message: 'กรุณากรอกตัวเลขเท่านั้น',
                }}
              />
              <InputField
                control={control}
                errors={errors}
                readOnly
                mb='0px'
                id='tel'
                label='เบอร์มือถือ'
                name='tel'
                type='text'
                maxLength={10}
                defaultValue={''}
                required={{
                  value: true,
                  message: 'กรุณากรอกเบอร์มือถือ',
                }}
                pattern={{
                  value: /[0-9]/,
                  message: 'กรุณากรอกตัวเลขเท่านั้น',
                }}
              />
              <InputField
                control={control}
                errors={errors}
                readOnly
                mb='0px'
                id='fax'
                label='เบอร์โทรสาร'
                name='fax'
                type='text'
                maxLength={10}
                defaultValue=''
                pattern={{
                  value: /[0-9]/,
                  message: 'กรุณากรอกตัวเลขเท่านั้น',
                }}
              />
              <InputField
                control={control}
                errors={errors}
                readOnly
                mb='0px'
                id='webSite'
                label='เว็บไซต์'
                name='webSite'
                type='text'
                defaultValue=''
              />
              <Box></Box>
              <HStack w='100%' justifyContent='end'>
                <Button
                  w='10rem'
                  h='44px'
                  colorScheme='btn'
                  color='white'
                  onClick={LinkToEdit}
                >
                  แก้ไขข้อมูล
                </Button>
                <Button
                  w='10rem'
                  h='44px'
                  variant='light'
                  onClick={() => navigate(-1)}
                >
                  ปิด
                </Button>
              </HStack>
            </SimpleGrid>
          </Flex>
        </SimpleGrid>
      </Card>
    </FormControl>
  )
}
