import {
  Box,
  Button,
  Center,
  HStack,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react'
import { calPromotionItems } from 'Pages/Pos/Utils/calPromotion'
import { currency } from 'Utils/currency'
import { useRef } from 'react'
import { BsCheck, BsReceiptCutoff } from 'react-icons/bs'
import Resizer from 'react-image-file-resizer'

function PosBankReceived(props) {
  const {
    paymentStep,
    // setPaymentStep,
    // posUseBank,
    cartItems,
    confirmPayment,
    setFile,
    file,
    posUseDiscount,
  } = props

  const hiddenFileInput = useRef(null)

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri)
        },
        'base64'
      )
    })

  async function handleUploadChange(event) {
    const ThisFile = await resizeFile(event.target.files[0])
    return setFile(ThisFile)
  }

  return (
    <>
      {paymentStep.bankReceived && (
        <>
          <Center display={{ base: 'none', sm: 'none', md: 'flex' }}>
            <Text fontSize='18px' pt='1rem' color='pos.500' fontWeight='700'>
              {/* บัญชีรับเงิน */}ชำระผ่านธนาคาร
            </Text>
          </Center>

          {/* <Center py={{ base: '2rem', sm: '2rem', md: '1rem' }} w='100%'>
            <HStack
              space='0px'
              w='100%'
              justifyContent='space-around'
              onClick={() => {
                setPaymentStep({ bankSelect: true })
              }}
              cursor={'pointer'}
            >
              <Text
                display={{ base: 'flex', sm: 'flex', md: 'none' }}
                fontSize='14px'
              >
                บัญชีรับเงิน :{' '}
              </Text>
              {posUseBank ? (
                <>
                  <Box>
                    <Image
                      alt='bank'
                      w='3.5rem'
                      h='3.5rem'
                      src={posUseBank?.bank?.imgUrl}
                      borderRadius='full'
                      objectFit='cover'
                    />
                  </Box>
                  <Flex flexDirection='column' fontSize='14px'>
                    <Text>{posUseBank?.bank?.name}</Text>
                    <Text>{posUseBank?.accountingNumber}</Text>
                    <Text>{posUseBank?.accountName}</Text>
                  </Flex>
                </>
              ) : (
                <Center>
                  <Text>กรุณาเลือกธนาคาร</Text>
                </Center>
              )}
              <Text
                zIndex={{
                  base: 'overlay',
                  sm: 'overlay',
                  md: 'base',
                  lg: 'base',
                }}
                px='0.5rem'
                color='pos.500'
                fontSize='18px'
                cursor='pointer'
              >
                <IoChevronForwardOutline />
              </Text>
            </HStack>
          </Center> */}
          <VStack w={'full'}>
            <HStack
              mt={{ base: '0rem', sm: '0rem', md: '1rem' }}
              spacing={{ base: '5px', sm: '5px', md: '0px' }}
              flexDirection={{ base: 'row', sm: 'row', md: 'column' }}
              w={'full'}
            >
              <Box
                pb={{ base: '0px', sm: '0px', md: '10px' }}
                pl={{ base: '5px', sm: '5px', md: '0px' }}
                w='100%'
              >
                <Input
                  display={'none'}
                  type={'file'}
                  onChange={handleUploadChange}
                  ref={hiddenFileInput}
                  multiple
                />

                <Button
                  zIndex={{
                    base: 'overlay',
                    sm: 'overlay',
                    md: 'base',
                    lg: 'base',
                  }}
                  w='100%'
                  colorScheme={'btn'}
                  boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
                  borderRadius='6px'
                  h='40px'
                  fontSize='14px'
                  // disabled={!posUseBank}
                  onClick={() => hiddenFileInput.current?.click()}
                >
                  <Text px='0.5rem' fontSize='18px'>
                    {file ? <BsCheck /> : <BsReceiptCutoff />}
                  </Text>
                  <Text>ถ่ายสลิป</Text>
                </Button>
              </Box>

              {/* <Box pr={{ base: '5px', sm: '5px', md: '0px' }} w='100%'>
                <Button
                  zIndex={{
                    base: 'overlay',
                    sm: 'overlay',
                    md: 'base',
                    lg: 'base',
                  }}
                  w='100%'
                  h='40px'
                  colorScheme={'btn'}
                  boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
                  borderRadius='6px'
                  fontSize='14px'
                  onClick={() => {
                    setPaymentStep({ bankGetQR: true })
                  }}
                  // disabled={!posUseBank}
                >
                  <Text fontSize='18px'>
                    <TbQrcode />
                  </Text>
                  <Text>QR Code ธนาคาร</Text>
                </Button>
              </Box> */}
            </HStack>
            {/* <Box>
              <Text textColor={'red'} fontSize={'13px'}>
                * กรุณาถ่ายหลักฐานการโอนเงินทุกครั้ง *
              </Text>
            </Box> */}
          </VStack>
          <Center h={{ base: '35vh', sm: '35vh', md: '18vh' }} w='100%'>
            <VStack>
              <Text color='pos.500'>เงินโอนรับ</Text>
              <Text fontSize='30px' fontWeight='700'>
                {currency(calPromotionItems(cartItems, posUseDiscount)?.sum, 2)}
              </Text>
            </VStack>
          </Center>

          <Center
            pt={{ base: '6rem', sm: '6rem', md: '0rem' }}
            display={{
              base: 'none',
              sm: 'none',
              md: 'flex',
              lg: 'flex',
              xl: 'flex',
              '2xl': 'flex',
            }}
            bottom='0px'
            w='100%'
          >
            <Box p='0.3rem' w='100%'>
              <Button
                w='100%'
                py='1.7rem'
                colorScheme={'btn'}
                borderRadius='5px'
                // disabled={!posUseBank}
                onClick={() => {
                  confirmPayment('bank')
                }}
              >
                ยืนยันการชำระเงิน
              </Button>
            </Box>
          </Center>
          <Center
            display={{
              base: 'flex',
              sm: 'flex',
              md: 'none',
              lg: 'none',
              xl: 'none',
              '2xl': 'none',
            }}
            position='absolute'
            bottom='0px'
            w='100%'
            h='100vh'
            alignItems='flex-end'
          >
            <Box p='0.3rem' w='100%'>
              <Button
                w='100%'
                py='1.7rem'
                colorScheme={'btn'}
                borderRadius='5px'
                // disabled={!posUseBank}
                onClick={() => {
                  confirmPayment('bank')
                }}
              >
                ยืนยันการชำระเงิน
              </Button>
            </Box>
          </Center>
        </>
      )}
    </>
  )
}

export { PosBankReceived }
