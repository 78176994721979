import { Box, Button } from '@chakra-ui/react'
import React from 'react'

export default function ButtonResponsive({ label, ...props }) {
  return (
    <Box
      position={{ sm: 'fixed', md: 'relative' }}
      bottom={{ sm: '5px', md: '0px' }}
      w={{ base: '100%', sm: '100%', md: '148px' }}
      h='46px'
      left={{ sm: '0px', md: '0px' }}
      px={{ sm: '5px', md: '0px' }}
    >
      <Button
        colorScheme='btn'
        color='#ffff'
        variant='solid'
        fontSize='sm'
        borderRadius={{ sm: '5px', md: '16px' }}
        w='100%'
        h='100%'
        ms='auto'
        {...props}
      >
        {label}
      </Button>
    </Box>
  )
}
