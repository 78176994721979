import { db, functions } from 'Config/firebase'
import { useAppContext } from 'Context'
import {
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
  where,
  writeBatch,
  doc,
} from 'firebase/firestore'
import { useCallback, useState } from 'react'
import { calStockTotal } from 'Utils'
import _ from 'underscore'
import { encodeUnit } from 'Utils'
import { httpsCallable } from 'firebase/functions'

export function useInventory() {
  const { setError, setMessage, user } = useAppContext()
  const [data, setData] = useState()
  const [Alldata, setAllData] = useState()
  const [branchInventoryData, setBranchInventoryData] = useState([])
  const [branchInventoryLoading, setBranchInventoryLoading] = useState(true)
  //------- ref -------//
  const InventoryRef = collection(db, 'Inventory')
  const getStockInOut = httpsCallable(functions, 'getInventoryStocksInOut')

  //------- ref -------//

  function createInventory(value) {
    try {
      return addDoc(InventoryRef, {
        ...value,
        branch: 'HQ',
        createdAt: new Date(),
        createdBy: {
          uid: user?.uid,
          name: user?.name,
        },
      }).then(() => {
        setMessage({
          type: 'success',
          message: 'เพิ่มสินค้าเรียบร้อยแล้ว',
        })
      })
    } catch (error) {
      setError({
        code: 'Product',
        message: error.message,
      })
    }
  }

  function getInventoryInOutTotal({
    branch,
    month,
    between,
    prevMonth,
    currentDate,
    currentMonth,
    endDate,
  }) {
    return new Promise(async (resolve, _reject) => {
      const getStock = await getStockInOut({
        branch: branch,
        month: month,
        between: between,
        prevMonth: prevMonth,
        currentDate: currentDate,
        currentMonth: currentMonth,
        endDate: endDate,
      })
      resolve(getStock)
    })
  }

  function transferInventory(value, nameBranch, secoundValue) {
    try {
      return addDoc(InventoryRef, {
        ...secoundValue,
        method: 'deposit',
        createdAt: new Date(),
        createdBy: {
          uid: user?.uid,
          name: user?.name,
        },
      }).then(() => {
        return addDoc(InventoryRef, {
          ...value,
          branch: nameBranch,
          method: 'withdrawal',
          createdAt: new Date(),
          createdBy: {
            uid: user?.uid,
            name: user?.name,
          },
        })
      })
    } catch (error) {
      setError({
        code: 'Inventory',
        message: error.message,
      })
    }
  }

  async function transferInventoryBatch(order, products) {
    try {
      const batch = writeBatch(db)
      await products.map((product) => {
        batch.set(doc(InventoryRef), {
          ...product,
          stock: parseFloat(encodeUnit(product.amount)).toString(),
          branch: order?.branch.id,
          description:
            product.sendingFromBranch?.id === 'HQ'
              ? `ได้รับการโอนย้ายสินค้าจาก ${product.sendingFromBranch?.name} จากระบบสั่งสินค้า`
              : `ได้รับการโอนย้ายสินค้าจากสาขา ${product.sendingFromBranch?.name} จากระบบสั่งสินค้า`,
          method: 'deposit',
          createdAt: new Date(),
          createdBy: {
            uid: user?.uid,
            name: user?.name,
          },
        })

        return batch.set(doc(InventoryRef), {
          ...product,
          stock: parseFloat(encodeUnit(product.amount)).toString(),
          branch: product.sendingFromBranch?.id,
          description: `โอนย้ายไปยังสาขา ${order?.branch?.name} จากระบบสั่งสินค้า`,
          method: 'withdrawal',
          createdAt: new Date(),
          createdBy: {
            uid: user?.uid,
            name: user?.name,
          },
        })
      })

      return await batch.commit()
    } catch (error) {
      setError({
        code: 'Inventory',
        message: error.message,
      })
    }
  }

  const getSKUInventory = useCallback(
    async (itemId) => {
      const invSnapshotRef = query(
        InventoryRef,
        where('branch', '==', user?.branchSelect?.id),
        where('id', '==', itemId),
        orderBy('createdAt', 'desc')
      )
      const getData = await getDocs(invSnapshotRef)
      const data = await _.map(getData?.docs, (doc) => {
        return {
          id: doc?.id,
          ...doc?.data(),
        }
      })
      const calStock = await calStockTotal(data)
      setData({ data: data, total: calStock })
    },
    //eslint-disable-next-line
    [user]
  )

  const getAllInventory = useCallback(async () => {
    const getDocsData = await getDocs(InventoryRef)
    const data = await _.map(getDocsData?.docs, (doc) => {
      return {
        ids: doc?.id,
        ...doc?.data(),
      }
    })
    return setAllData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getBranchInv = useCallback(async () => {
    const invSnapshotRef = query(
      InventoryRef,
      where('branch', '==', user?.branchSelect?.id),
      orderBy('createdAt', 'desc')
    )
    const getData = await getDocs(invSnapshotRef)
    const data = _.map(getData?.docs, (doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      }
    })
    setBranchInventoryData(data)
    setBranchInventoryLoading(false)
    //eslint-disable-next-line
  }, [user])

  const getAllInventoryByBranchId = useCallback(
    async () => {
      const queryInventoryBranchRef = await query(
        InventoryRef,
        where('branch', '==', user?.branchSelect?.id),
        orderBy('createdAt', 'desc')
      )
      return await (
        await getDocs(queryInventoryBranchRef)
      ).docs?.map((doc) => {
        return {
          id: doc?.id,
          ...doc?.data(),
        }
      })

      // return data
    },
    //eslint-disable-next-line
    [user]
  )

  // const getProductInventory = () => {}

  // useEffect(() => {
  //   getAllInventory()
  //   getBranchInv()
  // }, [getAllInventory, getBranchInv])

  return {
    data,
    Alldata,
    branchInventoryData,
    branchInventoryLoading,
    createInventory,
    transferInventory,
    getSKUInventory,
    getAllInventoryByBranchId,
    getAllInventory,
    getBranchInv,
    transferInventoryBatch,
    getInventoryInOutTotal,
  }
}
