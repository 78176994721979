import { DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  SimpleGrid,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import DropzoneOption from 'Components/form/components/dropzoneOption'
import React, { useEffect, useState } from 'react'
import { MdOutlineCloudUpload } from 'react-icons/md'

const _ = require('underscore')

export function ProductOptions({ data, i, setOption, option }) {
  const [cal, setCal] = useState()
  const brand = useColorModeValue('brand.500', 'brand.400')
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const bg = useColorModeValue('gray.100', 'navy.700')
  const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100')
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')

  useEffect(() => {
    if (data) {
      setCal(data?.commission)
    }
  }, [data])

  return (
    <Box p='1em' pt='0px' mt={i > 0 ? '24px' : '0px'}>
      {data.img ? (
        <Center>
          <DropzoneOption
            setOption={setOption}
            option={option}
            i={i}
            w='20rem'
            content={
              <Box>
                <Center
                  align='center'
                  justify='center'
                  bg={bg}
                  borderColor={borderColor}
                  w='100%'
                  maxW='100%'
                  h='max-content'
                  maxH='200px'
                  cursor='pointer'
                  flexDirection='column'
                >
                  <Text
                    fontSize='sm'
                    fontWeight='500'
                    color='secondaryGray.500'
                  >
                    {' '}
                    <Text
                      fontSize='sm'
                      pt='1.5rem'
                      fontWeight='500'
                      color='secondaryGray.500'
                    >
                      {' '}
                    </Text>
                  </Text>
                  <Image maxH='200px' src={data.img} objectFit='cover' />
                </Center>
              </Box>
            }
          />
        </Center>
      ) : (
        <Center>
          <DropzoneOption
            setOption={setOption}
            option={option}
            i={i}
            w='20rem'
            content={
              data.img ? (
                <Box>
                  <Center
                    align='center'
                    justify='center'
                    bg={bg}
                    borderColor={borderColor}
                    w='100%'
                    maxW='100%'
                    maxH='150px'
                    cursor='pointer'
                    flexDirection='column'
                  >
                    <Text
                      fontSize='sm'
                      fontWeight='500'
                      color='secondaryGray.500'
                    >
                      {' '}
                      <Text
                        fontSize='sm'
                        pt='1.5rem'
                        fontWeight='500'
                        color='secondaryGray.500'
                      >
                        {' '}
                      </Text>
                    </Text>
                    <Image maxH='150px' src={data.img} objectFit='cover' />
                  </Center>
                </Box>
              ) : (
                <Box>
                  <Text
                    fontSize='sm'
                    fontWeight='500'
                    color='secondaryGray.500'
                  >
                    {' '}
                    <Text
                      fontSize='sm'
                      mt='1.5rem'
                      fontWeight='500'
                      color='secondaryGray.500'
                    >
                      {' '}
                    </Text>
                  </Text>
                  <Icon
                    as={MdOutlineCloudUpload}
                    w='80px'
                    h='80px'
                    color={textColor}
                  />
                  <Text
                    mx='auto'
                    mb='4px'
                    fontSize='lg'
                    fontWeight='700'
                    whiteSpace='pre-wrap'
                    color={textColor}
                  >
                    อัพโหลดรูปภาพสินค้า
                  </Text>
                  <Text
                    mx='auto'
                    fontSize={{ base: '16px', md: '16px' }}
                    fontWeight='700'
                    whiteSpace='pre-wrap'
                    color={brand}
                  >
                    คลิก
                  </Text>
                </Box>
              )
            }
          />
        </Center>
      )}
      <SimpleGrid py={'1em'} columns={{ base: '1', md: '2' }} gap='20px'>
        <FormControl>
          <FormLabel
            display='flex'
            ms='10px'
            fontSize='sm'
            color={textColorPrimary}
            fontWeight='bold'
            _hover={{ cursor: 'pointer' }}
          >
            ชื่อตัวเลือก {i + 1}
          </FormLabel>
          <Input
            value={data.nameOption}
            fontWeight='500'
            variant='main'
            defaultValue={''}
            borderRadius={{
              base: '10px',
              sm: '10px',
              md: '10px',
              lg: '16px',
            }}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              let value = e.target.value
              setOption(
                option?.map((item, index) =>
                  index === i ? { ...item, nameOption: value } : item
                )
              )
            }}
          />
          <FormErrorMessage></FormErrorMessage>
        </FormControl>
        <FormControl>
          <FormLabel
            display='flex'
            ms='10px'
            // htmlFor={id}
            fontSize='sm'
            color={textColorPrimary}
            fontWeight='bold'
            _hover={{ cursor: 'pointer' }}
          >
            รหัส SKU
          </FormLabel>
          <Input
            readOnly
            value={data.sku}
            borderRadius={{
              base: '10px',
              sm: '10px',
              md: '10px',
              lg: '16px',
            }}
            fontWeight='500'
            variant='main'
            defaultValue={''}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              let value = e.target.value
              setOption(
                option?.map((item, index) =>
                  index === i ? { ...item, sku: value } : item
                )
              )
            }}
          />
          <FormErrorMessage></FormErrorMessage>
        </FormControl>
        <FormControl display='flex' alignItems='center'>
          <FormLabel
            display='flex'
            p='0px'
            m='0px'
            // htmlFor={id}
            fontSize='sm'
            color={textColorPrimary}
            fontWeight='bold'
            _hover={{ cursor: 'pointer' }}
          >
            <Text pl='0px' pr='15px'>
              หน่วยสินค้า
            </Text>
          </FormLabel>
          <RadioGroup
            value={data.unit}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              let value = e
              setOption(
                option?.map((item, index) =>
                  index === i ? { ...item, unit: value } : item
                )
              )
            }}
          >
            <HStack>
              <Radio colorScheme='green' value={'piece'}>
                ชิ้น
              </Radio>
              <Radio colorScheme='green' value={'weight'}>
                น้ำหนัก
              </Radio>
            </HStack>
          </RadioGroup>
          <FormErrorMessage></FormErrorMessage>
        </FormControl>
        <Box></Box>
        <FormControl>
          <FormLabel
            display='flex'
            ms='10px'
            fontSize='sm'
            color={textColorPrimary}
            fontWeight='bold'
            _hover={{ cursor: 'pointer' }}
          >
            ราคาต้นทุน
          </FormLabel>
          <Input
            value={data.cost}
            borderRadius={{
              base: '10px',
              sm: '10px',
              md: '10px',
              lg: '16px',
            }}
            fontWeight='500'
            variant='main'
            type={'number'}
            step={'any'}
            min={0}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              let value = parseFloat(parseFloat(e.target.value)?.toFixed(2))
              setOption(
                option?.map((item, index) =>
                  index === i ? { ...item, cost: value } : item
                )
              )
            }}
          />
          <FormErrorMessage></FormErrorMessage>
        </FormControl>
        <FormControl>
          <FormLabel
            display='flex'
            ms='10px'
            fontSize='sm'
            color={textColorPrimary}
            fontWeight='bold'
            _hover={{ cursor: 'pointer' }}
          >
            ราคาขาย
          </FormLabel>
          <Input
            value={data.price}
            borderRadius={{
              base: '10px',
              sm: '10px',
              md: '10px',
              lg: '16px',
            }}
            fontWeight='500'
            variant='main'
            type={'number'}
            min={0}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              let value = _.isNaN(e.target.value)
                ? 0
                : parseFloat(parseFloat(e.target.value)?.toFixed(2))
              setOption(
                option?.map((item, index) =>
                  index === i ? { ...item, price: value } : item
                )
              )
            }}
          />
          <FormErrorMessage></FormErrorMessage>
        </FormControl>

        <VStack alignItems='start'>
          <FormLabel
            display='flex'
            ms='10px'
            mb='0px'
            fontSize='sm'
            color={textColorPrimary}
            fontWeight='bold'
            _hover={{ cursor: 'pointer' }}
          >
            ค่าคอมมิชชั่นในการขาย
          </FormLabel>
          <HStack alignItems='end' w='full'>
            <FormControl>
              <InputGroup>
                <Input
                  value={cal}
                  type='number'
                  step='any'
                  borderRadius={{
                    base: '10px',
                    sm: '10px',
                    md: '10px',
                    lg: '16px',
                  }}
                  defaultValue=''
                  fontWeight='500'
                  variant='main'
                  _placeholder={{
                    fontWeight: '400',
                    color: 'secondaryGray.600',
                  }}
                  h='44px'
                  maxh='44px'
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    let value = _.isNaN(e.target.value)
                      ? 0
                      : parseFloat(parseFloat(e.target.value)?.toFixed(2))

                    setCal(parseFloat(value))
                    setOption(
                      option?.map((item, index) =>
                        index === i
                          ? { ...item, commission: parseFloat(value) }
                          : item
                      )
                    )
                  }}
                />
                <InputRightElement width='4.5rem' zIndex='base'>
                  <Button
                    colorScheme='btn'
                    h='1.75rem'
                    fontWeight='normal'
                    size='sm'
                    onClick={() => {
                      if (data.typeCommission === 'Percentage') {
                        setOption(
                          option?.map((item, index) =>
                            index === i
                              ? {
                                  ...item,
                                  typeCommission: 'Bath',
                                }
                              : item
                          )
                        )
                      }
                      if (data.typeCommission === 'Bath') {
                        setOption(
                          option?.map((item, index) =>
                            index === i
                              ? {
                                  ...item,
                                  typeCommission: 'Percentage',
                                }
                              : item
                          )
                        )
                      }
                    }}
                  >
                    {data.typeCommission === 'Bath' ? (
                      <Text>&#3647;</Text>
                    ) : (
                      <Text>%</Text>
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl
              display={data.typeCommission === 'Percentage' ? 'flex' : 'none'}
            >
              <HStack alignItems='center'>
                <Input
                  value={(data.price * (data.commission / 100)).toFixed(2)}
                  borderRadius={{
                    base: '10px',
                    sm: '10px',
                    md: '10px',
                    lg: '16px',
                  }}
                  defaultValue=''
                  fontWeight='500'
                  variant='main'
                  _placeholder={{
                    fontWeight: '400',
                    color: 'secondaryGray.600',
                  }}
                  readOnly
                  h='44px'
                  maxh='44px'
                />
                <Text fontWeight='bold'>&#3647;</Text>
              </HStack>
            </FormControl>
          </HStack>
        </VStack>

        <FormControl>
          <FormLabel
            display='flex'
            ms='10px'
            fontSize='sm'
            color={textColorPrimary}
            fontWeight='bold'
            _hover={{ cursor: 'pointer' }}
          >
            แจ้งเตือนสินค้าใกล้หมด
          </FormLabel>
          <Input
            value={data.minimum}
            borderRadius={{
              base: '10px',
              sm: '10px',
              md: '10px',
              lg: '16px',
            }}
            fontWeight='500'
            variant='main'
            type={'number'}
            min={0}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              let value = _.isNaN(e.target.value)
                ? 0
                : parseFloat(parseFloat(e.target.value)?.toFixed(2))
              setOption(
                option?.map((item, index) =>
                  index === i ? { ...item, minimum: value } : item
                )
              )
            }}
          />
          <FormErrorMessage></FormErrorMessage>
        </FormControl>

        <FormControl display='flex' alignItems='center'>
          <FormLabel
            display='flex'
            p='0px'
            m='0px'
            // htmlFor={id}
            fontSize='sm'
            color={textColorPrimary}
            fontWeight='bold'
            _hover={{ cursor: 'pointer' }}
          >
            <Text pl='0px' pr='15px'>
              สถานะสินค้า
            </Text>
          </FormLabel>
          <RadioGroup
            value={data.statusPo}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              let value = e
              setOption(
                option?.map((item, index) =>
                  index === i ? { ...item, statusPo: value } : item
                )
              )
            }}
          >
            <HStack>
              <Radio colorScheme='green' value={'backOrder'}>
                วางขาย
              </Radio>
              <Radio colorScheme='green' value={'discontinues'}>
                ยกเลิกการขาย
              </Radio>
            </HStack>
          </RadioGroup>
          <FormErrorMessage></FormErrorMessage>
        </FormControl>

        <FormControl display='flex' alignItems='center'>
          <FormLabel
            display='flex'
            p='0px'
            m='0px'
            // htmlFor={id}
            fontSize='sm'
            color={textColorPrimary}
            fontWeight='bold'
            _hover={{ cursor: 'pointer' }}
          >
            <Text pl='0px' pr='15px'>
              ประเภทคอมมิชชั่น
            </Text>
          </FormLabel>
          <RadioGroup
            value={data.commissionCalType}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              let value = e
              setOption(
                option?.map((item, index) =>
                  index === i ? { ...item, commissionCalType: value } : item
                )
              )
            }}
          >
            <HStack>
              <Radio colorScheme='green' value={'piece'}>
                ต่อชิ้น
              </Radio>
              <Radio colorScheme='green' value={'receipt'}>
                ยอดขาย
              </Radio>
            </HStack>
          </RadioGroup>
          <FormErrorMessage></FormErrorMessage>
        </FormControl>

        <Flex alignItems={'end'} justifyContent={'end'} h='full'>
          <Button
            bg='none'
            fontSize='auto'
            borderRadius='full'
            w='2.5rem'
            h='2.5rem'
            ms='auto'
            color='red'
            isLoading={false}
            _focus={{
              bg: 'red',
              color: '#ffff',
            }}
            _hover={{
              bg: 'red',
              color: '#ffff',
            }}
            _active={{
              bg: 'red',
              color: '#ffff',
            }}
            variant='solid'
            colorScheme={'blue'}
            onClick={() => {
              setOption(option.filter((item, index) => index !== i))
            }}
          >
            <DeleteIcon />
          </Button>
        </Flex>
      </SimpleGrid>
      <Divider
        mt={{ sm: '0px', md: '24px' }}
        pt={{ sm: '0rem', md: '0.5rem' }}
        borderColor={'blackAlpha.400'}
      />
    </Box>
  )
}
