import { calAmount } from 'Pages/Pos/Utils/calcurate'
import _ from 'underscore'
import { decodeUnit } from './currency'

/**
 * @interface
 * @param {array} carts
 * @param {array} inventoryStock
 * @param {array} promotions
 * @param {object} promotion
 * @return {object} {cart: {free: true, amount: 1, promotionsToCart:{...}}, discounts: [ ]}
 */
export const checkPromotionCondition = (
  carts,
  inventoryStock,
  promotion,
  promotions
) => {
  if (promotion?.type === 'free') {
    if (promotion?.typeDiscount === 'list' && promotion?.typeFree === 'list') {
      const findItemInCart = _.find(carts, (i) => {
        return i.id === promotion.product.id && !i.free
      })
      if (
        findItemInCart?.amount >=
        parseFloat(decodeUnit(promotion.productAmount))
      ) {
        const promotionsToCart = {
          ...promotion.productFree,
          promotionId: promotion.id,
          price: (0).toString(),
        }
        const promotionAmount = promotion.freeProductAmount
          ? parseFloat(decodeUnit(promotion.freeProductAmount))
          : 1

        return {
          cart: {
            promotionsToCart: promotionsToCart,
            free: true,
            amount: promotionAmount,
          },
          discounts: [...promotions],
          promotion: promotion,
        }
      }
    } else if (
      promotion?.typeDiscount === 'list' &&
      promotion?.typeFree === 'category'
    ) {
      const findItemInCart = _.find(carts, (i) => {
        return i.id === promotion.product.id && !i.free
      })

      if (
        findItemInCart?.amount >=
        parseFloat(decodeUnit(promotion.productAmount))
      ) {
        const filterItemInStock = _.filter(inventoryStock, (i) => {
          return (
            (i.category === promotion.categoryFree.name ||
              i.category === promotion.categoryFree.id) &&
            parseFloat(i.total) > 0
          )
        })
        const sortTotal = _.sortBy(filterItemInStock, 'price')
        const firstItem = _.first(sortTotal)
        const promotionsToCart = {
          ...firstItem,
          promotionId: promotion.id,
          price: (0).toString(),
        }
        const promotionAmount = promotion.freeProductAmount
          ? parseFloat(decodeUnit(promotion.freeProductAmount))
          : 1
        return {
          cart: {
            promotionsToCart: promotionsToCart,
            free: true,
            amount: promotionAmount,
          },
          discounts: [...promotions],
          promotion: promotion,
        }
      }
    } else if (
      promotion?.typeDiscount === 'receipt' &&
      promotion?.typeFree === 'list'
    ) {
      const totalAmount = parseFloat(calAmount(carts))
      if (totalAmount >= parseFloat(decodeUnit(promotion.minimumOrder))) {
        const promotionsToCart = {
          ...promotion.productFree,
          promotionId: promotion.id,
          price: (0).toString(),
        }
        const promotionAmount = promotion.freeProductAmount
          ? parseFloat(decodeUnit(promotion.freeProductAmount))
          : 1

        return {
          cart: {
            promotionsToCart: promotionsToCart,
            free: true,
            amount: promotionAmount,
          },
          discounts: [...promotions],
          promotion: promotion,
        }
      }
    } else if (
      promotion?.typeDiscount === 'receipt' &&
      promotion?.typeFree === 'category'
    ) {
      const totalAmount = parseFloat(calAmount(carts))
      if (totalAmount >= parseFloat(decodeUnit(promotion.minimumOrder))) {
        const filterItemInStock = _.filter(inventoryStock, (i) => {
          return (
            (i.category === promotion.categoryFree.name ||
              i.category === promotion.categoryFree.id) &&
            parseFloat(i.total) > 0
          )
        })
        const sortTotal = _.sortBy(filterItemInStock, 'price')
        const firstItem = _.first(sortTotal)
        const promotionsToCart = {
          ...firstItem,
          promotionId: promotion.id,
          price: (0).toString(),
        }
        const promotionAmount = promotion.freeProductAmount
          ? parseFloat(decodeUnit(promotion.freeProductAmount))
          : 1
        return {
          cart: {
            promotionsToCart: promotionsToCart,
            free: true,
            amount: promotionAmount,
          },
          discounts: [...promotions],
          promotion: promotion,
        }
      }
    } else if (
      promotion?.typeDiscount === 'category' &&
      promotion?.typeFree === 'category'
    ) {
      const filterCategoryProduct = _.filter(carts, (i) => {
        return (
          (i.category === promotion.category.id ||
            i.category === promotion.category.name) &&
          !i.free
        )
      })
      const itemAmount = _.reduce(
        _.pluck(filterCategoryProduct, 'amount'),
        (memo, num) => {
          return memo + num
        }
      )
      // เช็ค items และ แถม item
      if (itemAmount >= parseFloat(decodeUnit(promotion.categoryAmount))) {
        const filterItemInStock = _.filter(carts, (i) => {
          return (
            (i.category === promotion.categoryFree.name ||
              i.category === promotion.categoryFree.id) &&
            parseFloat(i.total) > 0
          )
        })
        const lastItem = _.last(filterItemInStock)
        const promotionsToCart = {
          ...lastItem,
          promotionId: promotion.id,
          price: (0).toString(),
        }
        const promotionAmount = promotion.freeProductAmount
          ? parseFloat(decodeUnit(promotion.freeProductAmount))
          : 1
        return {
          cart: {
            promotionsToCart: promotionsToCart,
            free: true,
            amount: promotionAmount,
          },
          discounts: [...promotions],
          promotion: promotion,
        }
      }
    } else if (
      promotion?.typeDiscount === 'category' &&
      promotion?.typeFree === 'list'
    ) {
      const filterCategoryProduct = _.filter(carts, (i) => {
        return (
          (i.category === promotion.category.id ||
            i.category === promotion.category.name) &&
          !i.free
        )
      })
      const itemAmount = _.reduce(
        _.pluck(filterCategoryProduct, 'amount'),
        (memo, num) => {
          return memo + num
        }
      )
      if (itemAmount >= parseFloat(decodeUnit(promotion.categoryAmount))) {
        const promotionsToCart = {
          ...promotion.productFree,
          promotionId: promotion.id,
          price: (0).toString(),
        }
        const promotionAmount = promotion.freeProductAmount
          ? parseFloat(decodeUnit(promotion.freeProductAmount))
          : 1

        return {
          cart: {
            promotionsToCart: promotionsToCart,
            free: true,
            amount: promotionAmount,
          },
          discounts: [...promotions],
          promotion: promotion,
        }
      }
    }
  }
}

export const checkPromotionToUse = (carts, promotion) => {
  if (promotion.type === 'free') {
    if (promotion?.typeDiscount === 'list' && promotion?.typeFree === 'list') {
      const findItemInCart = _.find(carts, (i) => {
        return i.id === promotion?.product?.id && !i.free
      })
      if (
        findItemInCart?.amount >=
        parseFloat(decodeUnit(promotion.productAmount))
      ) {
        return true
      } else {
        return false
      }
    } else if (
      promotion?.typeDiscount === 'list' &&
      promotion?.typeFree === 'category'
    ) {
      const findItemInCart = _.find(carts, (i) => {
        return i.id === promotion.product.id && !i.free
      })
      const findFreeItem = _.find(carts, (i) => {
        return (
          i.category === promotion?.categoryFree?.id ||
          i.category === promotion?.categoryFree?.name
        )
      })
      if (
        findItemInCart?.amount >=
          parseFloat(decodeUnit(promotion.productAmount)) &&
        findFreeItem
      ) {
        return true
      } else {
        return false
      }
    } else if (
      promotion?.typeDiscount === 'list' &&
      promotion?.typeFree === 'receipt'
    ) {
      const findIteminCart = _.find(carts, (i) => {
        return i.id === promotion.product.id && !i.free
      })
      if (
        findIteminCart &&
        findIteminCart.amount >= parseFloat(decodeUnit(promotion.productAmount))
      ) {
        return true
      }
      return false
    } else if (
      promotion?.typeDiscount === 'receipt' &&
      promotion?.typeFree === 'list'
    ) {
      const calTotal = calAmount(carts)
      if (
        parseFloat(calTotal) >= parseFloat(decodeUnit(promotion.minimumOrder))
      ) {
        return true
      } else {
        return false
      }
    } else if (
      promotion?.typeDiscount === 'receipt' &&
      promotion?.typeFree === 'category'
    ) {
      const calTotal = calAmount(carts)
      if (
        parseFloat(calTotal) >= parseFloat(decodeUnit(promotion.minimumOrder))
      ) {
        return true
      } else {
        return false
      }
    } else if (
      promotion?.typeDiscount === 'receipt' &&
      promotion?.typeFree === 'receipt'
    ) {
      const calTotal = calAmount(carts)
      if (
        parseFloat(calTotal) >= parseFloat(decodeUnit(promotion.minimumOrder))
      ) {
        return true
      } else {
        return false
      }
    } else if (
      promotion?.typeDiscount === 'category' &&
      promotion?.typeFree === 'category'
    ) {
      const filterItemCategory = _.filter(carts, (i) => {
        return (
          (i.category === promotion.category.id ||
            i.category === promotion.category.name) &&
          !i.free
        )
      })
      if (filterItemCategory) {
        const pluckAmount = _.pluck(filterItemCategory, 'amount')
        const sum = _.reduce(pluckAmount, (memo, num) => {
          return memo + num
        })
        if (sum > parseFloat(decodeUnit(promotion.categoryAmount))) {
          return true
        }
      }
      return false
    } else if (
      promotion?.typeDiscount === 'category' &&
      promotion?.typeFree === 'list'
    ) {
      const filterItemCategory = _.filter(carts, (i) => {
        return (
          (i.category === promotion.category.id ||
            i.category === promotion.category.name) &&
          !i.free
        )
      })
      if (filterItemCategory) {
        const pluckAmount = _.pluck(filterItemCategory, 'amount')
        const sum = _.reduce(pluckAmount, (memo, num) => {
          return memo + num
        })
        if (sum >= parseFloat(decodeUnit(promotion.categoryAmount))) {
          return true
        }
      } else {
        return false
      }
    } else if (
      promotion?.typeDiscount === 'category' &&
      promotion?.typeFree === 'receipt'
    ) {
      const filterItemCategory = _.filter(carts, (i) => {
        return (
          (i.category === promotion.category.id ||
            i.category === promotion.category.name) &&
          !i.free
        )
      })
      if (filterItemCategory) {
        const pluckAmount = _.pluck(filterItemCategory, 'amount')
        const sum = _.reduce(pluckAmount, (memo, num) => {
          return memo + num
        })
        if (sum >= parseFloat(decodeUnit(promotion.categoryAmount))) {
          return true
        }
      }
      return false
    }
  } else if (promotion.type === 'discount') {
    if (promotion.typeDiscount === 'receipt') {
      return true
    } else if (promotion.typeDiscount === 'list') {
      const filterItemsInCart = _.find(carts, (i) => {
        return i.id === promotion.product.id
      })
      if (filterItemsInCart) {
        return true
      } else {
        return false
      }
    } else if (promotion.typeDiscount === 'category') {
      const filterItemsInCart = _.filter(carts, (i) => {
        return (
          i?.category === promotion?.category?.id ||
          i?.category === promotion?.category?.name
        )
      })
      if (filterItemsInCart?.length > 0) {
        return true
      } else {
        return false
      }
    }
  }
  return false
}
