import { DeleteIcon } from '@chakra-ui/icons'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Center,
  Fade,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import CustomTable from 'Components/Horizons/table/customTable'
import { useExpenses } from 'Hooks'
import { useEffect, useRef, useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { HiPencil } from 'react-icons/hi'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

const Manage = ({ id }) => {
  const { deleteExpensesCategory } = useExpenses()
  const Navigate = useNavigate()
  const cancelRef = useRef()

  async function handleDelete(id) {
    await deleteExpensesCategory(id)
    await onClose()
  }

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure()
  const { isOpen, onOpen, onClose } = useDisclosure()

  // const textHover = useColorModeValue(
  //   { color: 'secondaryGray.900', bg: 'unset' },
  //   { color: 'secondaryGray.500', bg: 'unset' }
  // )
  const textColor2 = useColorModeValue('secondaryGray.500', 'white')
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100')
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' }
  )
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' }
  )
  const iconColor = useColorModeValue('#494D51', 'white')
  const bgList = useColorModeValue('white', 'whiteAlpha.100')
  const bgShadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    'unset'
  )

  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent
          fontFamily='Prompt'
          w={{ sm: '20.5rem', md: '25rem' }}
        >
          <AlertDialogHeader>คุณแน่ใจ?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            คุณแน่ใจที่จะลบหมวดหมู่นี้ใช่หรือไม่ ?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              ไม่
            </Button>
            <Button
              colorScheme='red'
              ml={3}
              onClick={() => {
                handleDelete(id)
              }}
            >
              ใช่
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Menu isOpen={isOpen1} onClose={onClose1}>
        <MenuButton
          align='center'
          justifyContent='center'
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w='37px'
          h='37px'
          lineHeight='100%'
          onClick={onOpen1}
          borderRadius='10px'
        >
          <Icon as={MdOutlineMoreHoriz} color={iconColor} w='24px' h='24px' />
        </MenuButton>
        <MenuList
          w='150px'
          minW='unset'
          maxW='150px !important'
          border='transparent'
          backdropFilter='blur(63px)'
          bg={bgList}
          boxShadow={bgShadow}
          borderRadius='20px'
          p='15px'
        >
          <MenuItem
            transition='0.2s linear'
            color={textColor2}
            _hover={{
              color: 'discount.500',
            }}
            p='0px'
            borderRadius='8px'
            _active={{
              bg: 'transparent',
            }}
            _focus={{
              bg: 'transparent',
            }}
            mb='10px'
          >
            <Flex align='center'>
              <HStack
                id={id}
                cursor='pointer'
                onClick={() => {
                  Navigate(`/expenses/category/edit/${id}`)
                }}
              >
                <Icon as={HiPencil} h='16px' w='16px' me='8px' />
                <Text fontSize='sm' fontWeight='400'>
                  แก้ไข
                </Text>
              </HStack>
            </Flex>
          </MenuItem>
          <MenuItem
            transition='0.2s linear'
            p='0px'
            borderRadius='8px'
            color={textColor2}
            _hover={{
              color: 'red',
            }}
            _active={{
              bg: 'transparent',
            }}
            _focus={{
              bg: 'transparent',
            }}
            mb='10px'
          >
            <Flex align='center'>
              <HStack onClick={onOpen}>
                <Icon as={DeleteIcon} h='1F6px' w='16px' me='8px' />
                <Text fontSize='sm' fontWeight='400'>
                  ลบ
                </Text>
              </HStack>
            </Flex>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}

function ExpensesCategory() {
  const {
    Category,
    isLoading: isLoadingCategory,
    getExpensesCategorys,
  } = useExpenses()
  const Navigate = useNavigate()
  const [isFadePage, setIsFadePage] = useState(true)

  useEffect(() => {
    setIsFadePage(false)
    getExpensesCategorys()
  }, [getExpensesCategorys])

  const columnsDataOrders = [
    {
      Header: 'ชื่อหมวดหมู่',
      accessor: 'name',
    },
    {
      Header: 'จัดการ',
      accessor: 'id',
      extra: (data, row) => {
        return (
          <Center pl='1rem'>
            <Manage id={row?.id} />
          </Center>
        )
      },
    },
  ]

  return (
    <>
      <Stack as={Fade} in={!isFadePage}>
        {!isLoadingCategory ? (
          <CustomTable columnsData={columnsDataOrders} tableData={Category} />
        ) : (
          <Card>
            <Center>
              <Spinner size='lg' />
            </Center>
          </Card>
        )}
      </Stack>
      <Button
        colorScheme='btn'
        color='#ffff'
        to='/branchs/add'
        borderRadius='full'
        position='fixed'
        right={5}
        bottom={5}
        w={'50px'}
        h={'50px'}
        onClick={() => {
          Navigate('/expenses/category/add')
        }}
      >
        <Icon as={BsPlus} fontSize={'45px'} />
      </Button>
    </>
  )
}

export default ExpensesCategory
