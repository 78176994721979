import { Box, Button, Flex, Stack, Fade, Text } from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import InputField from 'Components/Horizons/fields/InputField'
import { useCategory } from 'Hooks/category'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ChevronLeftIcon } from '@chakra-ui/icons'

function FormCategory({ id, data, refId }) {
  const { createCategory, updateCategory } = useCategory()
  const Navigate = useNavigate()
  const [isSubmit, setIsSubmit] = useState(false)
  const [isFadePage, setIsFadePage] = useState(true)

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm()

  function onSubmit(value) {
    setIsSubmit(true)
    if (id) {
      updateCategory(value, id).then(() => {
        Navigate(-1)
        setIsSubmit(false)
      })
    } else {
      createCategory(value).then(() => {
        Navigate(-1)
        setIsSubmit(false)
      })
    }
  }

  useEffect(() => {
    if (data) {
      setValue('idCategory', data?.idCategory)
      setValue('name', data?.name)
    } else {
      setValue('idCategory', refId)
    }
    setTimeout(() => {
      setIsFadePage(false)
    }, 200)
  }, [data, setValue, refId])
  return (
    <Fade in={!isFadePage}>
      <Flex direction='column' minH='100vh' align='center' position='relative'>
        <Box
          h={{ base: '0px', sm: '0px', md: '25vh' }}
          bg='product.700'
          position='absolute'
          w={{ sm: '100vw', md: '100%', lg: '100%' }}
          borderRadius={{ sm: '0px', md: '30px', lg: '30px' }}
        ></Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            variant='unstyled'
            mt={{ sm: '-10px', md: '4rem', lg: '4rem' }}
            zIndex='0'
            display='flex'
            flexDirection='column'
          >
            <Box
              mt={{ base: '0px', sm: '0px', md: '24px' }}
              maxW={{ md: '90%', lg: '100%' }}
              mx={{ base: 'auto', md: '0px', lg: 'auto' }}
            >
              <Box
                w={{ sm: '100vw', md: '670px', lg: '850px' }}
                p='0px'
                mx={{ base: 'auto', md: '0px', lg: 'auto' }}
              >
                <Card p='30px' mt={{ base: '-1rem', sm: '-1rem', md: '0rem' }}>
                  <Box
                    pb='0.3rem'
                    zIndex='base'
                    color='product.500'
                    onClick={() => Navigate(-1)}
                    w='max-content'
                    display={{ sm: 'flex', md: 'none' }}
                  >
                    <Text
                      display={{ sm: 'flex', md: 'none' }}
                      fontSize='24px'
                      cursor='pointer'
                    >
                      <ChevronLeftIcon />
                    </Text>
                  </Box>
                  <Text
                    fontSize='2xl'
                    textAlign={{
                      base: 'center',
                      sm: 'center',
                      md: 'left',
                      lg: 'left',
                    }}
                    fontWeight='bold'
                    color='#1B2559'
                  >
                    ข้อมูลหมวดหมู่สินค้า
                  </Text>
                  <Flex direction='column' w='100%' pt={'1em'}>
                    <Stack direction='column' spacing='20px' color='#1B2559'>
                      <InputField
                        control={control}
                        errors={errors}
                        mb='0px'
                        id='idCategory'
                        label='รหัสหมวดหมู่'
                        name='idCategory'
                        type='text'
                        defaultValue={''}
                        readOnly
                        required={{
                          value: true,
                          message: 'กรุณากรอกรหัสหมวดหมู่',
                        }}
                      />
                      <InputField
                        control={control}
                        errors={errors}
                        mb='0px'
                        id='name'
                        label='ชื่อ'
                        name='name'
                        type='text'
                        defaultValue={''}
                        required={{
                          value: true,
                          message: 'กรุณากรอกชื่อหมวดหมู่',
                        }}
                      />
                    </Stack>
                    <Flex justify='space-between' mt='30px'>
                      <Button
                        variant='light'
                        fontSize='sm'
                        borderRadius='16px'
                        w={{ base: '128px', md: '148px' }}
                        h='46px'
                        onClick={() => Navigate('/product/category')}
                        color='#1B2559'
                      >
                        ย้อนกลับ
                      </Button>
                      <Button
                        type='submit'
                        fontSize='sm'
                        borderRadius='16px'
                        w={{ base: '128px', md: '148px' }}
                        h='46px'
                        ms='auto'
                        isLoading={isSubmit}
                        variant='solid'
                        colorScheme={'btn'}
                        loadingText={'Loading...'}
                      >
                        บันทึก
                      </Button>
                    </Flex>
                  </Flex>
                </Card>
              </Box>
            </Box>
          </Box>
        </form>
      </Flex>
    </Fade>
  )
}

export { FormCategory }
