import {
  Center,
  HStack,
  Flex,
  Box,
  Text,
  Button,
  Stack,
  Heading,
  Select,
  Grid,
  GridItem,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Image,
  Divider,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import CustomTable from 'Components/Horizons/table/customTable'
import { SearchIcon } from '@chakra-ui/icons'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { db } from 'Config/firebase'
import { currency, decodeUnit } from 'Utils/currency'
// import { onFilterReportBranchBranchSales } from './functions/filter'
// import { calPromotionDecodeUnitAmountMultipleItems } from 'Utils/calStock'
import {
  AiOutlineFileText,
  AiOutlineFileSync,
  AiOutlineFileDone,
} from 'react-icons/ai'
import { CardReport } from 'Components/CardReport/card'
import SearchDate from 'Components/Search/date'
import { salesReport } from './query'
import { billStatus } from 'Utils/status'
import { StatusOrderBadge } from 'Components/Badge/statusBadge'
import { vatIncluding } from 'Utils/vat'
import CSVDownloadForm from 'Components/csvDownload'
import { posType } from 'Utils/json'

const _ = require('underscore')

function ModalOrder({ order, discount }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { statusMessage } = billStatus()

  return (
    <>
      <Text
        onClick={onOpen}
        as='ins'
        color='discount.100'
        fontWeight='bold'
        cursor='pointer'
        letterSpacing={['0px', '0px', '0px', '1px', '1px', '1px']}
      >
        {order?.orderId}
      </Text>

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        size='auto'
        isCentered
      >
        <ModalOverlay />
        <ModalContent w={{ base: '90%', sm: '90%', md: '40rem' }}>
          <ModalHeader fontSize={{ base: 'xl', sm: 'xl', md: '2xl' }}>
            <HStack>
              <Text>เลขที่ใบสั่งซื้อ : {order?.orderId}</Text>
              {order?.status === 'refund' && (
                <StatusOrderBadge
                  message={order?.status}
                  variant={'solid'}
                  py='1em'
                  w='max-content'
                />
              )}
            </HStack>
            <Text></Text>
            <Text
              fontSize={{
                base: '12px',
                sm: '12px',
                md: '14px',
              }}
              color='#A3AED0'
              fontWeight={'light'}
            >
              {order?.date}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            w='100%'
            overflowY='auto'
            maxH={{ base: '30rem', sm: '30rem', md: '50rem' }}
            px='2rem'
          >
            <VStack
              alignItems='flex-start'
              spacing={{ sm: '0.5rem', md: '1rem' }}
              w='100%'
            >
              {order?.items?.map((item, i) => {
                return (
                  <Flex flexDirection='column' key={i} w='100%'>
                    <Box w='100%'>
                      <Grid
                        templateColumns={{
                          sm: 'repeat(3, 1fr)',
                          md: 'repeat(4, 1fr)',
                        }}
                        w='100%'
                        gap={0}
                        py={{ sm: '0.5rem', md: '0.5rem' }}
                      >
                        <GridItem
                          w={{ base: '100%', sm: '100%', md: '7rem' }}
                          display={{ sm: 'none', md: 'GridItem' }}
                        >
                          <Center>
                            <Image
                              w={{ sm: '100%', md: '5rem' }}
                              h={{ sm: '100%', md: '5rem' }}
                              objectFit='cover'
                              borderRadius='15px'
                              display={{ sm: 'none', md: 'flex' }}
                              src={
                                item?.img
                                  ? item?.img
                                  : '/gogreen/product_Thumbnail.png'
                              }
                              alt={item?.name}
                            />
                          </Center>
                        </GridItem>
                        <GridItem
                          w={{ sm: '100%', md: '15rem' }}
                          fontWeight='normal'
                        >
                          <Flex
                            h='full'
                            justifyContent='center'
                            flexDirection='column'
                            w='100%'
                          >
                            <VStack alignItems={'start'}>
                              <Text>
                                {item?.name}{' '}
                                {item?.nameOption && item?.nameOption}
                              </Text>
                              {item?.promotion && (
                                <Text color='#FF6501'>
                                  {item?.promotion?.name}
                                </Text>
                              )}
                              <Text color='green'>
                                {currency(decodeUnit(item?.price), 2, true)}
                              </Text>
                            </VStack>
                          </Flex>
                        </GridItem>

                        <GridItem
                          w={{ sm: 'auto', md: '100%' }}
                          fontWeight='normal'
                        >
                          <Center h='full' color='black' w='100%'>
                            <VStack>
                              <Text>x{decodeUnit(item?.amount)}</Text>
                              {item?.promotion && <Text></Text>}
                            </VStack>
                          </Center>
                        </GridItem>

                        <GridItem w={{ sm: 'auto', md: '100%' }}>
                          <Flex
                            alignItems='center'
                            justifyContent='end'
                            h='full'
                            textAlign='right'
                            color='black'
                            w='100%'
                          >
                            <VStack fontWeight='normal' alignItems={'end'}>
                              <Text>
                                {currency(
                                  decodeUnit(item?.price) *
                                    decodeUnit(item?.amount),
                                  2,
                                  true
                                )}
                              </Text>
                            </VStack>
                          </Flex>
                        </GridItem>
                      </Grid>
                    </Box>
                  </Flex>
                )
              })}
            </VStack>

            <Box
              pb={{ sm: '0.5rem', md: '0.5rem' }}
              pt={{ sm: '1rem', md: '1.5rem' }}
              px='0rem'
              w={{ sm: '100%', md: '100%' }}
            >
              <VStack
                alignItems='flex-start'
                spacing={{ sm: '1rem', md: '1.5rem' }}
                w={{ sm: '100%', md: '100%' }}
              >
                <Divider />
                <Flex
                  fontSize={{ sm: '16px', md: '16px' }}
                  w='full'
                  flexDirection='row'
                  fontWeight='normal'
                  justifyContent='space-between'
                >
                  <Text
                    textAlign='left'
                    color='black'
                    w={{ sm: '100%', md: '15rem' }}
                  >
                    ยอดรวม
                  </Text>
                  <Text
                    textAlign='right'
                    color='black'
                    w={{ sm: '100%', md: '20rem' }}
                    fontWeight='normal'
                  >
                    {currency(order?.total, 2, true)}
                  </Text>
                </Flex>
                {discount?.length > 0 && (
                  <>
                    <Text
                      fontSize={{ sm: '16px', md: '16px' }}
                      // fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                    >
                      รายการส่วนลด
                    </Text>
                    <VStack w='full'>
                      {_.map(discount, (item, index) => {
                        return (
                          <HStack
                            key={index}
                            justifyContent={'space-between'}
                            w='full'
                          >
                            <Text color='#FF6501'>{item?.discountName}</Text>
                          </HStack>
                        )
                      })}
                    </VStack>

                    <Flex
                      fontSize={{ sm: '16px', md: '16px' }}
                      w='full'
                      flexDirection='row'
                      // fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                      justifyContent='space-between'
                    >
                      <HStack justifyContent={'none'}>
                        <Text textAlign='left' color='black'>
                          รวมส่วนลด
                        </Text>
                      </HStack>
                      <Text
                        textAlign='right'
                        w={{ sm: '100%', md: '20rem' }}
                        color='#FF6501'
                      >
                        {discount
                          ? `-${currency(order?.totalDiscount, 2, true)}`
                          : '-'}
                      </Text>
                    </Flex>
                  </>
                )}
                <Flex
                  fontSize={{ sm: '16px', md: '16px' }}
                  w='full'
                  flexDirection='row'
                  fontWeight='normal'
                  justifyContent='space-between'
                >
                  <Text
                    textAlign='left'
                    color='black'
                    w={{ sm: '100%', md: '15rem' }}
                  >
                    ยอดไม่รวมภาษีมูลค่าเพิ่ม
                  </Text>
                  <Text
                    textAlign='right'
                    color='black'
                    w={{ sm: '100%', md: '20rem' }}
                    fontWeight='bold'
                  >
                    {order?.subTotal
                      ? currency(order?.subTotal, 2, true)
                      : currency(
                          vatIncluding(order?.grandTotal).total,
                          2,
                          true
                        )}
                  </Text>
                </Flex>

                <Flex
                  fontSize={{ sm: '16px', md: '16px' }}
                  w='full'
                  flexDirection='row'
                  fontWeight='normal'
                  justifyContent='space-between'
                >
                  <Text
                    textAlign='left'
                    color='black'
                    w={{ sm: '100%', md: '15rem' }}
                  >
                    ภาษีมูลค่าเพิ่ม (7%)
                  </Text>
                  <Text
                    textAlign='right'
                    color='black'
                    w={{ sm: '100%', md: '20rem' }}
                    fontWeight='bold'
                  >
                    {order?.subTotal
                      ? currency(order?.vat, 2, true)
                      : currency(vatIncluding(order?.grandTotal).vat, 2, true)}
                  </Text>
                </Flex>

                <Flex
                  fontSize={{ sm: '16px', md: '16px' }}
                  w='full'
                  flexDirection='row'
                  // fontWeight='normal'
                  justifyContent='space-between'
                  fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                >
                  <Text
                    textAlign='left'
                    color='black'
                    w={{ sm: '100%', md: '15rem' }}
                  >
                    ยอดชำระ
                  </Text>
                  <Text
                    textAlign='right'
                    color='black'
                    w={{ sm: '100%', md: '20rem' }}
                    fontWeight='bold'
                  >
                    {order?.status === 'refund'
                      ? order?.subTotal
                        ? currency(decodeUnit(order?.subTotal), 2, true)
                        : currency(
                            vatIncluding(decodeUnit(order?.grandTotal)).total,
                            2,
                            true
                          )
                      : currency(order?.grandTotal, 2, true)}
                  </Text>
                </Flex>

                <Flex
                  fontSize={{ sm: '16px', md: '16px' }}
                  w='full'
                  flexDirection='row'
                  fontWeight='normal'
                  justifyContent='space-between'
                >
                  <Text
                    textAlign='left'
                    color='black'
                    w={{ sm: '100%', md: '15rem' }}
                  >
                    สถานะ
                  </Text>
                  <Text
                    textAlign='right'
                    color={order?.status === 'refund' ? 'red.300' : '#60101C'}
                    w={{ sm: '100%', md: '20rem' }}
                  >
                    {statusMessage(order?.status)}
                  </Text>
                </Flex>
                <Flex
                  fontSize={{ sm: '16px', md: '16px' }}
                  flexDirection='row'
                  textAlign='right'
                  w='full'
                  justifyContent='space-between'
                >
                  <Text
                    textAlign='left'
                    fontWeight='normal'
                    color='black'
                    w={{ sm: '100%', md: '15rem' }}
                  >
                    ช่องทางการชำระ
                  </Text>
                  <Flex
                    fontSize={{ sm: '16px', md: '16px' }}
                    w='20rem'
                    flexDirection='column'
                    justifyContent='space-between'
                    textAlign='right'
                  >
                    {order?.payment === 'cash' ? (
                      <Text>เงินสด</Text>
                    ) : order?.payment === 'bank' ? (
                      <>
                        <Text>{order?.bank?.name}</Text>
                        <Text mt='-0.2rem'>
                          {order?.bank?.accountingNumber}
                        </Text>
                        <Text mt='-0.2rem'>{order?.bank?.accountName}</Text>
                      </>
                    ) : (
                      '-'
                    )}
                  </Flex>
                </Flex>
                <Flex
                  justifyContent='space-between'
                  fontSize={{ sm: '16px', md: '16px' }}
                  w='full'
                  flexDirection='row'
                >
                  <Text
                    textAlign='left'
                    fontWeight='normal'
                    color='black'
                    w={{ sm: '100%', md: '15rem' }}
                  >
                    พนักงานขาย
                  </Text>
                  <Text textAlign='right' w={{ sm: '100%', md: '20rem' }}>
                    {order?.createdBy?.name}
                  </Text>
                </Flex>

                <Flex
                  justifyContent='space-between'
                  fontSize={{ sm: '16px', md: '16px' }}
                  w='full'
                  flexDirection='row'
                >
                  <Text
                    textAlign='left'
                    fontWeight='normal'
                    color='black'
                    w={{ sm: '100%', md: '15rem' }}
                  >
                    ประเภทผู้ซื้อ
                  </Text>
                  <Text textAlign='right' w={{ sm: '100%', md: '20rem' }}>
                    {order?.customerType
                      ? posType?.default?.find(
                          (x) => x.id === order?.customerType
                        )?.name
                      : '-'}
                  </Text>
                </Flex>
              </VStack>
              {order?.img && (
                <Center
                  flexDirection='column'
                  textAlign='left'
                  w={{ sm: '100%', md: '37rem' }}
                  mt={{ sm: '1.5rem', md: '1.5rem' }}
                >
                  <Text
                    fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                    w='full'
                    fontSize={{ sm: '16px', md: '16px' }}
                    color='black'
                  >
                    สลิป
                  </Text>
                  <Image src={order?.img} w={'100%'} h={'auto'} />
                </Center>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

function Price({ grandTotal, grandDiscount, grandCost, grandProfit }) {
  return (
    <Flex w='100%' justifyContent='flex-end'>
      <HStack
        display={{ base: 'none', sm: 'none', md: 'none', lg: 'flex' }}
        pb='0.5rem'
        w={{ base: '100%', sm: '100%', md: '100%', lg: '50rem' }}
        justifyContent='flex-end'
        spacing='3rem'
      >
        <Stack
          direction={{
            base: 'row',
            sm: 'row',
            md: 'row',
            lg: 'column',
            xl: 'column',
            '2xl': 'column',
          }}
          justifyContent='space-between'
        >
          <Box>
            <Text
              fontSize={{ base: '14px', md: '18px' }}
              color='#2B3674'
              textAlign={{
                base: 'left',
                sm: 'left',
                md: 'left',
                lg: 'right',
                xl: 'right',
                '2xl': 'right',
              }}
            >
              ส่วนลด
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: '12px', md: '16px' }}
              color='report.500'
              textAlign='right'
            >
              {currency(grandDiscount, 2, true)}
            </Text>
          </Box>
        </Stack>
        <Stack
          direction={{
            base: 'row',
            sm: 'row',
            md: 'row',
            lg: 'column',
            xl: 'column',
            '2xl': 'column',
          }}
          justifyContent='space-between'
        >
          <Box>
            <Text
              fontSize={{ base: '14px', md: '18px' }}
              color='#2B3674'
              textAlign={{
                base: 'left',
                sm: 'left',
                md: 'left',
                lg: 'right',
                xl: 'right',
                '2xl': 'right',
              }}
            >
              ยอดขายสุทธิรวม
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: '12px', md: '16px' }}
              color='report.500'
              textAlign='right'
            >
              {currency(grandTotal, 2, true)}
            </Text>
          </Box>
        </Stack>
        <Stack
          direction={{
            base: 'row',
            sm: 'row',
            md: 'row',
            lg: 'column',
            xl: 'column',
            '2xl': 'column',
          }}
          justifyContent='space-between'
        >
          <Box>
            <Text
              fontSize={{ base: '14px', md: '18px' }}
              color='#2B3674'
              textAlign={{
                base: 'left',
                sm: 'left',
                md: 'left',
                lg: 'right',
                xl: 'right',
                '2xl': 'right',
              }}
            >
              ต้นทุน
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: '12px', md: '16px' }}
              color='report.500'
              textAlign='right'
            >
              {currency(grandCost, 2, true)}
            </Text>
          </Box>
        </Stack>
        <Stack
          direction={{
            base: 'row',
            sm: 'row',
            md: 'row',
            lg: 'column',
            xl: 'column',
            '2xl': 'column',
          }}
          justifyContent='space-between'
        >
          <Box>
            <Text
              fontSize={{ base: '14px', md: '18px' }}
              color='#2B3674'
              textAlign={{
                base: 'left',
                sm: 'left',
                md: 'left',
                lg: 'right',
                xl: 'right',
                '2xl': 'right',
              }}
            >
              กำไร
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: '12px', md: '16px' }}
              color='report.500'
              textAlign='right'
            >
              {currency(grandProfit, 2, true)}
            </Text>
          </Box>
        </Stack>
      </HStack>
      <Grid
        display={{ base: 'grid', sm: 'grid', md: 'grid', lg: 'none' }}
        pb='0.5rem'
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(4, 1fr)',
          xl: 'repeat(4, 1fr)',
          '2xl': 'repeat(4, 1fr)',
        }}
        gap={{ base: '12px', sm: '12px', md: '30px', lg: '20px' }}
        w={{ base: '100%', sm: '100%', md: '100%', lg: '45rem' }}
      >
        <GridItem>
          <Stack
            direction={{
              base: 'row',
              sm: 'row',
              md: 'row',
              lg: 'column',
              xl: 'column',
              '2xl': 'column',
            }}
            justifyContent='space-between'
          >
            <Box>
              <Text
                fontSize={{ base: '14px', md: '18px' }}
                color='#2B3674'
                textAlign={{
                  base: 'left',
                  sm: 'left',
                  md: 'left',
                  lg: 'right',
                  xl: 'right',
                  '2xl': 'right',
                }}
              >
                ส่วนลด
              </Text>
            </Box>
            <Box>
              <Text
                fontSize={{ base: '12px', md: '16px' }}
                color='report.500'
                textAlign='right'
              >
                {currency(grandDiscount, 2, true)}
              </Text>
            </Box>
          </Stack>
        </GridItem>
        <GridItem>
          <Stack
            direction={{
              base: 'row',
              sm: 'row',
              md: 'row',
              lg: 'column',
              xl: 'column',
              '2xl': 'column',
            }}
            justifyContent='space-between'
          >
            <Box>
              <Text
                fontSize={{ base: '14px', md: '18px' }}
                color='#2B3674'
                textAlign={{
                  base: 'left',
                  sm: 'left',
                  md: 'left',
                  lg: 'right',
                  xl: 'right',
                  '2xl': 'right',
                }}
              >
                ยอดขายสุทธิรวม
              </Text>
            </Box>
            <Box>
              <Text
                fontSize={{ base: '12px', md: '16px' }}
                color='report.500'
                textAlign='right'
              >
                {currency(grandTotal, 2, true)}
              </Text>
            </Box>
          </Stack>
        </GridItem>
        <GridItem>
          <Stack
            direction={{
              base: 'row',
              sm: 'row',
              md: 'row',
              lg: 'column',
              xl: 'column',
              '2xl': 'column',
            }}
            justifyContent='space-between'
          >
            <Box>
              <Text
                fontSize={{ base: '14px', md: '18px' }}
                color='#2B3674'
                textAlign={{
                  base: 'left',
                  sm: 'left',
                  md: 'left',
                  lg: 'right',
                  xl: 'right',
                  '2xl': 'right',
                }}
              >
                ต้นทุน
              </Text>
            </Box>
            <Box>
              <Text
                fontSize={{ base: '12px', md: '16px' }}
                color='report.500'
                textAlign='right'
              >
                {currency(grandCost, 2, true)}
              </Text>
            </Box>
          </Stack>
        </GridItem>
        <GridItem>
          <Stack
            direction={{
              base: 'row',
              sm: 'row',
              md: 'row',
              lg: 'column',
              xl: 'column',
              '2xl': 'column',
            }}
            justifyContent='space-between'
          >
            <Box>
              <Text
                fontSize={{ base: '14px', md: '18px' }}
                color='#2B3674'
                textAlign={{
                  base: 'left',
                  sm: 'left',
                  md: 'left',
                  lg: 'right',
                  xl: 'right',
                  '2xl': 'right',
                }}
              >
                กำไร
              </Text>
            </Box>
            <Box>
              <Text
                fontSize={{ base: '12px', md: '16px' }}
                color='report.500'
                textAlign='right'
              >
                {currency(grandProfit, 2, true)}
              </Text>
            </Box>
          </Stack>
        </GridItem>
      </Grid>
    </Flex>
  )
}

function Sales({ user }) {
  const { paymentMessage, statusMessage } = billStatus()
  const [currentDate, setCurrentDate] = useState(true)
  const [currentMonth, setCurrentMonth] = useState(false)
  const [between, setBetween] = useState(false)
  const [prevMonth, setPrevMonth] = useState(false)
  const [month, setMonth] = useState(new Date())
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [filterData, setFilterData] = useState([])
  const [onSearch, setOnSearch] = useState(false)
  const [select, setSelect] = useState('')
  const [selectEmp, setSelectEmp] = useState('')
  const [allEmp, setAllEmp] = useState([])
  const [allOrder, setAllOrder] = useState(0)
  const [dataToDownload, setDataToDownload] = useState([])
  const [itemsToDownload, setItemsToDownload] = useState([])
  const [startDateTime, setStartDateTime] = useState(null)
  const [endDateTime, setEndDateTime] = useState(null)
  const [dateTime, setDateTime] = useState(false)

  function calGrandTotal(item) {
    const arrGrandTotal = _.pluck(item, 'grandTotal')
    return _.reduce(arrGrandTotal, (memo, num) => memo + num, 0)
  }

  function calGrandCost(item) {
    const arrGrandCost = _.pluck(item, 'totalCost')
    return _.reduce(arrGrandCost, (memo, num) => memo + num, 0)
  }

  function calGrandDiscount(item) {
    const arrGrandCost = _.pluck(item, 'totalDiscount')
    return _.reduce(arrGrandCost, (memo, num) => memo + num, 0)
  }

  function calGrandProfit(item) {
    const arrGrandCost = _.pluck(item, 'totalProfit')
    return _.reduce(arrGrandCost, (memo, num) => memo + num, 0)
  }

  const scvHeaders = [
    { label: 'หมายเลขใบสั่งซื้อ', key: 'orderId' },
    { label: 'วันที่', key: 'date' },
    { label: 'สาขา', key: 'branch' },
    { label: 'ยอดขาย', key: 'total' },
    { label: 'ส่วนลดทั้งหมด', key: 'totalDiscount' },
    { label: 'ยอดไม่รวมภาษีมูลค่าเพิ่ม', key: 'subTotal' },
    { label: 'ภาษี(7%)', key: 'vat' },
    { label: 'ยอดรวม vat', key: 'vatInclude' },
    { label: 'note', key: 'note' },
    { label: 'ประเภทผู้ซื้อ', key: 'customerType' },
    { label: 'ต้นทุน', key: 'totalCost' },
    { label: 'กำไร', key: 'totalProfit' },
    { label: 'ช่องทางการชำระ', key: 'payment' },
  ]

  const scvItemsHeaders = [
    { label: 'วันที่', key: 'date' },
    { label: 'สาขา', key: 'branch' },
    { label: 'หมายเลขใบสั่งซื้อ', key: 'orderId' },
    { label: 'พนักงาน', key: 'createdBy' },
    { label: 'note', key: 'note' },
    { label: 'ประเภทผู้ซื้อ', key: 'customerType' },
    { label: 'รหัสสินค้า', key: 'sku' },
    { label: 'ชื่อสินค้า', key: 'name' },
    { label: 'ชื่อตัวเลือกสินค้า', key: 'nameOption' },
    { label: 'หมวดหมู่', key: 'category' },
    { label: 'จำนวน', key: 'amount' },
    { label: 'หน่วยสินค้า', key: 'unit' },
    { label: 'ต้นทุน', key: 'cost' },
    { label: 'ราคา', key: 'price' },
    { label: 'รวมส่วนลด', key: 'totalDiscount' },
    { label: 'จำนวน', key: 'amount' },
    { label: 'ยอดรวม', key: 'grandTotal' },
  ]

  async function onSubmitFilter() {
    const res = await salesReport({
      month: month,
      startDate: startDate,
      endDate: endDate,
      between: between,
      prevMonth: prevMonth,
      currentDate: currentDate,
      currentMonth: currentMonth,
      user: user,
      status: select,
      emp: selectEmp,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      dateTime: dateTime,
    })
    // const resIndex = _.indexBy(res.searchResults, 'id')
    // const dataCal = await Promise.all(
    //   _.map(resIndex, (order) => {
    //     const amount = calPromotionDecodeUnitAmountMultipleItems(order.items)
    //     const discount = decodeUnit(order.discountBalance)
    //     const cal = discount > 0 ? amount + discount : amount
    //     return {
    //       ...order,
    //       total: order.total,
    //       promotionAndDiscount: cal,
    //       grandTotal: order.grandTotal,
    //     }
    //   })
    // )
    setOnSearch(true)

    if (res.searchResults?.length > 0 && res?.results?.length > 0) {
      const items = []
      const dataDownload = _.map(res?.searchResults, (i) => {
        const orderItems = _.map(i.items, (d) => {
          return {
            ...d,
            createdBy: i.createdBy.name,
            date: i.date,
            branch: i.branch,
            cost: currency(decodeUnit(d.cost), 2),
            grandTotal: currency(decodeUnit(d.grandTotal), 2),
            total: currency(decodeUnit(d.total), 2),
            price: currency(decodeUnit(d.price), 2),
            amount: currency(decodeUnit(d.amount), 2),
            totalDiscount: currency(decodeUnit(d.totalDiscount), 2),
            orderId: i.orderId,
            unit:
              d.unit === 'piece'
                ? 'ชิ้น'
                : d.unit === 'weight'
                ? 'น้ำหนัก'
                : '-',
            note: i.note,
            customerType: i?.customerType
              ? posType?.default?.find((x) => x.id === i?.customerType)?.name
              : '',
          }
        })
        items.push(...orderItems)
        return {
          orderId: i.orderId,
          payment: paymentMessage(i.payment),
          branch: i.branch,
          date: i.date,
          totalDiscount: i.totalDiscount,
          status: statusMessage(i.status),
          total: currency(i.total, 2),
          note: i?.note ? i.note : '',
          customerType: i?.customerType
            ? posType?.default?.find((x) => x.id === i?.customerType)?.name
            : '',
          totalCost: currency(i.totalCost, 2),
          totalProfit: currency(i.totalProfit, 2),
          vat: currency(i.vat, 2),
          subTotal: currency(i.subTotal, 2),
          vatInclude: currency(i.subTotal + i.vat, 2),
        }
      })
      setItemsToDownload(items)
      setDataToDownload(dataDownload)
      setFilterData(res?.searchResults)
      setAllOrder(res?.results?.length)
    } else {
      setFilterData([])
      setAllOrder(0)
    }
    if (startDateTime && endDateTime) {
      setStartDate(startDateTime)
      setEndDate(endDateTime)
    } else {
      setStartDateTime(null)
      setEndDateTime(null)
    }
  }

  useEffect(() => {
    const qOrder = query(
      collection(db, 'Orders'),
      where('branch.id', '==', user?.branchSelect?.id)
    )
    const unsubscribe = onSnapshot(qOrder, (snapShot) => {
      let dataValue = []
      snapShot.forEach((doc) => {
        return dataValue.push({
          ...doc.data()?.createdBy,
        })
      })
      const emp = _.groupBy(dataValue, 'name')
      setAllEmp(_.map(emp, (item) => item[0]))
    })
    return () => {
      unsubscribe()
    }
  }, [user])

  const paid = _.filter(filterData, (e) => e?.status === 'paid')
  const refund = _.filter(filterData, (e) => e?.status === 'refund')

  const dataCard = [
    {
      label: 'รายการขายทั้งหมด',
      value: allOrder,
      colorIcon: '#2B3674',
      colorText: '#2B3674',
      icon: AiOutlineFileText,
    },
    {
      label: 'ยอดขาย',
      value: paid?.length,
      colorIcon: '#01B574',
      colorText: '#2B3674',
      icon: AiOutlineFileDone,
    },
    {
      label: 'คืนเงิน',
      value: refund?.length,
      colorIcon: '#FF5722',
      colorText: '#2B3674',
      icon: AiOutlineFileSync,
    },
  ]

  const columnsDataOrders = [
    {
      Header: 'วันที่',
      accessor: 'date',
      extra: (data, row) => (
        <Center key={row} w='full'>
          <Text>{row.date}</Text>
        </Center>
      ),
    },
    {
      Header: 'พนักงานขาย',
      accessor: 'staff',
      extra: (data, row) => <Center key={row}>{row?.createdBy?.name}</Center>,
    },
    {
      Header: 'เลขที่รายการขาย',
      accessor: 'orderId',
      extra: (data, row) => {
        return <ModalOrder order={row} discount={row.allOrder} />
      },
    },
    {
      Header: 'ชนิดบิล',
      accessor: 'status',
      extra: (data, row) => (
        <Center key={row} w='7rem'>
          <StatusOrderBadge
            message={row?.status}
            variant={'solid'}
            py='1em'
            w='full'
          />
        </Center>
      ),
    },
    {
      Header: (
        <Text w='100%' textAlign='end'>
          ส่วนลด
        </Text>
      ),
      accessor: 'totalDiscount',
      extra: (data, row) => (
        <Text
          w='100%'
          textAlign='end'
          key={row}
          color={row?.status === 'refund' ? 'red.300' : '#1A2020'}
        >
          {currency(row.totalDiscount, 2)}
        </Text>
      ),
    },
    {
      Header: (
        <Text w='100%' textAlign='end'>
          ยอดขายสุทธิ
        </Text>
      ),
      accessor: 'grandTotal',
      extra: (data, row) => {
        return (
          <Text
            textAlign='end'
            w='100%'
            key={row}
            // justifyContent='end'
            // w='full'
            color={row?.status === 'refund' ? 'red.300' : '#1A2020'}
          >
            {currency(row?.grandTotal, 2)}
          </Text>
        )
      },
    },
    {
      Header: (
        <Text w='100%' textAlign='end'>
          ต้นทุน
        </Text>
      ),
      accessor: 'totalCost',
      extra: (data, row) => (
        <Text
          textAlign='end'
          w='100%'
          key={row}
          color={row?.status === 'refund' ? 'red.300' : '#1A2020'}
        >
          {currency(row?.totalCost, 2)}
        </Text>
      ),
    },
    {
      Header: <Text textAlign='end'>กำไร</Text>,
      accessor: 'totalProfit',
      extra: (data, row) => (
        <Text
          textAlign='end'
          w='100%'
          key={row}
          color={row?.status === 'refund' ? 'red.300' : '#1A2020'}
        >
          {currency(row?.totalProfit, 2)}
        </Text>
      ),
    },
    // {
    //   Header: 'ค่าคอมมิชชั่น',
    //   accessor: 'totalCommission',
    //   extra: (data, row) => (
    //     <Center
    //       key={row}
    //       color={row?.status === 'refund' ? 'red.300' : '#1A2020'}
    //     >
    //       {currency(row.totalProfit, 2)}
    //     </Center>
    //   ),
    // },
    // {
    //   Header: 'กำไรหลังหักคอมมิชชั่น',
    //   accessor: 'grandTotalProfit',
    //   extra: (data, row) => (
    //     <Center
    //       key={row}
    //       color={row?.status === 'refund' ? 'red.300' : '#1A2020'}
    //     >
    //       {currency(row.totalProfit, 2)}
    //     </Center>
    //   ),
    // },
  ]

  return (
    <>
      <HStack
        mt='1rem'
        mb='0.5em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='1vw'
        flexDirection={{ sm: 'column', md: 'row' }}
        alignItems='flex-start'
      >
        <Flex flexDirection='column' w={{ sm: 'full', md: '30vw', lg: '30vw' }}>
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ช่วงเวลา :</Text>
          </Box>
          <SearchDate
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            month={month}
            setMonth={setMonth}
            setBetween={setBetween}
            setPrevMonth={setPrevMonth}
            setCurrentDate={setCurrentDate}
            setCurrentMonth={setCurrentMonth}
            setStartDateTime={setStartDateTime}
            setEndDateTime={setEndDateTime}
            setDateTime={setDateTime}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
          />
        </Flex>
        <Flex
          // display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        >
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ชนิดบิล :</Text>
          </Box>
          <Select
            color={'gray.700'}
            w='full'
            bg={'#F4F7FE'}
            border='0px solid #E8E9E9'
            borderRadius='16px'
            defaultValue=''
            fontWeight='500'
            variant='main'
            placeholder={'ทั้งหมด'}
            _placeholder={{
              fontWeight: '400',
            }}
            h='44px'
            maxh='44px'
            onChange={(event) => setSelect(event.target.value)}
          >
            <option value='paid'>ขาย</option>
            <option value='refund'>คืนเงิน</option>
          </Select>
        </Flex>
        <Flex
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        >
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>พนักงาน :</Text>
          </Box>
          <Select
            color={'gray.700'}
            w='full'
            bg={'#F4F7FE'}
            border='0px solid #E8E9E9'
            borderRadius='16px'
            defaultValue=''
            fontWeight='500'
            variant='main'
            placeholder={'ทั้งหมด'}
            _placeholder={{
              fontWeight: '400',
            }}
            h='44px'
            maxh='44px'
            onChange={(event) => setSelectEmp(event.target.value)}
          >
            {allEmp?.map((item, index) => (
              <option value={item.id} key={index}>
                {item?.name}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex
          flexDirection='column'
          w={{ sm: 'full', md: '19vw', lg: '13vw', xl: '11vw' }}
          alignItems='end'
          pt={{ sm: '1rem', md: '0rem' }}
        >
          <Box
            textAlign='start'
            display={{ sm: 'none', md: 'flex' }}
            p='0.5rem'
            pl='1rem'
            pt='0rem'
          >
            <Text color='#8F9BBA'>&nbsp;</Text>
          </Box>
          <Button
            h='44px'
            maxh='44px'
            w={{ sm: '10rem', md: 'full' }}
            colorScheme='btn'
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            onClick={() => onSubmitFilter()}
          >
            <Text>
              <SearchIcon />
            </Text>
            <Text>แสดงรายงาน</Text>
          </Button>
        </Flex>
      </HStack>
      <Grid
        pb='0.5rem'
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(2, 1fr)',
          xl: 'repeat(3, 1fr)',
          '2xl': 'repeat(3, 1fr)',
        }}
        gap={{ base: '12px', sm: '12px', md: '20px' }}
      >
        {dataCard?.map((item, index) => (
          <GridItem key={index}>
            <CardReport
              value={item?.value}
              colorIcon={item?.colorIcon}
              colorText={item?.colorText}
              icon={item?.icon}
              label={item?.label}
            />
          </GridItem>
        ))}
      </Grid>
      <Flex
        flexDirection='column'
        p={{ sm: '10px', md: '20px 20px 30px 20px' }}
        bg='white'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
        borderRadius='30px'
      >
        <Stack spacing={{ base: '0em', md: '4em' }}>
          <HStack
            justifyContent={'space-between'}
            w='full'
            display={{ sm: 'block', md: 'flex' }}
          >
            <Stack>
              <Heading
                fontFamily={'Prompt'}
                color='#2B3674'
                fontSize={{ base: '24px', md: '30px' }}
              >
                รายงานรายการขาย
              </Heading>
              <Box>
                <HStack>
                  <Text color='#2B3674'>ณ วันที่ :</Text>
                  <Text color='#2B3674'>
                    {currentDate && (
                      <>{moment(new Date().getTime())?.format('MM/DD/YYYY')} </>
                    )}
                    {currentMonth && (
                      <>{moment(new Date().getTime())?.format('MM/DD/YYYY')} </>
                    )}
                    {between && (
                      <>{moment(new Date().getTime())?.format('MM/DD/YYYY')} </>
                    )}
                    {/* {startDate && endDate && (
                    <Text color='#2B3674'>
                      {moment(startDate).format('MM/DD/YYYY')} -{' '}
                      {moment(endDate).format('MM/DD/YYYY')}
                    </Text>
                  )} */}
                  </Text>
                </HStack>
                <HStack>
                  <Text color='#2B3674'>ช่วงเวลา :</Text>
                  {startDate && endDate && (
                    <Text color='#2B3674'>
                      {moment(startDate)?.format('MM/DD/YYYY')} -{' '}
                      {moment(endDate)?.format('MM/DD/YYYY')}
                    </Text>
                  )}
                </HStack>
              </Box>
            </Stack>

            <HStack
              display={{ sm: 'block', md: 'flex' }}
              justifyContent={{ sm: 'start', md: 'end' }}
            >
              <CSVDownloadForm
                mt={{ sm: '10px', md: '0px' }}
                buttonTitle={'ดาวน์โหลดไฟล์รายงานการขาย'}
                data={dataToDownload}
                filename={'salesReport'}
                headers={scvHeaders}
              />
              <CSVDownloadForm
                mt={{ sm: '10px', md: '0px' }}
                buttonTitle={'ดาวน์โหลดไฟล์รายการขาย'}
                data={itemsToDownload}
                filename={'orderList'}
                headers={scvItemsHeaders}
              />
            </HStack>
          </HStack>
          <Box
            pt={{ base: '1rem', sm: '1rem', md: '0rem', lg: '0rem' }}
            pr={{
              base: '0rem',
              sm: '0rem',
              md: '1rem',
              lg: '1rem',
              xl: '1rem',
            }}
          >
            <Price
              grandTotal={onSearch ? calGrandTotal(filterData) : 0}
              grandCost={onSearch ? calGrandCost(filterData) : 0}
              grandDiscount={onSearch ? calGrandDiscount(filterData) : 0}
              grandProfit={onSearch ? calGrandProfit(filterData) : 0}
            />
          </Box>
        </Stack>
        <Box>
          <CustomTable
            columnsData={columnsDataOrders}
            tableData={onSearch ? filterData : []}
            closeSearch={true}
          />
        </Box>
      </Flex>
    </>
  )
}

export default Sales
