import {
  Box,
  Button,
  Center,
  Flex,
  SimpleGrid,
  Spinner,
} from '@chakra-ui/react'
import InputField from 'Components/Horizons/fields/InputField'
import SelectField from 'Components/Horizons/fields/SelectField'
import TextField from 'Components/Horizons/fields/TextField'
import { useAppContext } from 'Context'
import { useBranch } from 'Hooks'
import { useInventory } from 'Hooks/inventory'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import _ from 'underscore'
import { encodeUnit } from 'Utils'
function FormInventory(props) {
  const { method, id, data, nameBranch, pathname } = props
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      note: '',
    },
  })
  const { user } = useAppContext()
  const { Branch, getBranchData } = useBranch()
  const [isSubmit, setIsSubmit] = useState(false)
  const { createInventory, transferInventory } = useInventory()
  const [isLoad, setIsLoad] = useState(true)
  const Navigate = useNavigate()
  function onSubmit(value) {
    setIsSubmit(true)
    const { stock, note, branch } = value
    const filterBranch = _.filter(Branch, (item) => {
      if (item.id === value.branch) {
        return item
      }
    })
    delete data.total

    if (branch) {
      const allValue = {
        ...data,
        stock: parseFloat(encodeUnit(stock)).toString(),
        note: note,
        branch: branch,
        description:
          filterBranch[0]['id'] === 'HQ'
            ? `โอนย้ายไปยัง ${filterBranch[0]['name']}`
            : `โอนย้ายไปยังสาขา ${filterBranch[0]['name']}`,
      }
      const secoundValue = {
        ...data,
        stock: parseFloat(encodeUnit(stock)).toString(),
        note: note,
        branch: branch,
        description:
          user?.branchSelect?.id === 'HQ'
            ? `ได้รับการโอนย้ายสินค้าจาก ${user?.branchSelect?.name}`
            : `ได้รับการโอนย้ายสินค้าจากสาขา ${user?.branchSelect?.name}`,
      }
      transferInventory(allValue, nameBranch, secoundValue).then(() => {
        if (user?.branchSelect?.id === 'HQ') {
          Navigate(pathname ? pathname : `/inventory/view/${id}`)
        } else {
          Navigate(pathname ? pathname : `/inventory/branch/view/${id}`)
        }
        setIsSubmit(false)
      })
    } else {
      const allValue = {
        ...data,
        method: method,
        stock: parseFloat(encodeUnit(stock)).toString(),
        note: note,
        description:
          method === 'deposit' ? `สินค้าได้ถูกนำเข้า` : `สินค้าได้ถูกนำออก`,
      }
      createInventory(allValue).then(() => {
        if (user?.branchSelect?.id === 'HQ') {
          Navigate(pathname ? pathname : `/inventory/view/${id}`)
        } else {
          Navigate(pathname ? pathname : `/inventory/branch/view/${id}`)
        }
        setIsSubmit(false)
      })
    }
  }
  useEffect(() => {
    getBranchData()
    setTimeout(() => {
      setIsLoad(false)
    }, 500)
  }, [getBranchData])

  return (
    <>
      {!isLoad ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt='0.8rem'>
            <SimpleGrid
              columns={method === 'transfer' ? { base: 1, sm: 2 } : { base: 1 }}
              gap={'1em'}
            >
              {method === 'transfer' && (
                <SelectField
                  control={control}
                  errors={errors}
                  label={'โอนย้ายไป'}
                  name={'branch'}
                  id={'branch'}
                  mb={'0px'}
                  borderRadius={{
                    base: '10px',
                    sm: '10px',
                    md: '10px',
                    lg: '16px',
                  }}
                  placeholder={'เลือกสาขา'}
                  path={'/branchs/add'}
                  defaultValue={''}
                  options={
                    Branch.length > 0
                      ? Branch.filter(
                          (itm) => itm?.id !== user?.branchSelect?.id
                        )?.map((item) => ({ id: item?.id, name: item?.name }))
                      : []
                  }
                  required={{
                    value: true,
                    message: 'กรุณาเลือกสาขาที่จะโอนย้ายไป',
                  }}
                />
              )}
              <InputField
                control={control}
                errors={errors}
                name={'stock'}
                id={'stock'}
                label={
                  method === 'deposit'
                    ? 'นำเข้าสินค้า'
                    : method === 'withdrawal'
                    ? 'นำออกสินค้า'
                    : 'โอนย้ายสินค้า'
                }
                placeholder={'ระบุจำนวนสินค้า'}
                mb={'1em'}
                type={'number'}
                // pattern={{
                //   value: /[0-9]/,
                //   message: 'กรุณากรอกตัวเลขเท่านั้น',
                // }}
                required={{
                  value: true,
                  message: 'กรุณากรอกจำนวนสินค้า',
                }}
                min={{
                  value: 0,
                  message: 'กรุณากรอกจำนวนที่มากกว่า 0',
                }}
              />
            </SimpleGrid>
            <TextField
              variant={'no-effects'}
              control={control}
              errors={errors}
              id={'note'}
              name={'note'}
              label={'หมายเหตุ'}
              mb={'0px'}
              defaultValue={''}
            />
          </Box>
          <Flex
            justify={{ sm: 'center', md: 'space-between' }}
            mt={{ sm: '0px', md: '30px' }}
          >
            <Button
              display={{ sm: 'none', md: 'flex' }}
              onClick={() => {
                Navigate(-1)
              }}
              variant='light'
              fontSize='sm'
              borderRadius='16px'
              w={{ base: '128px', md: '148px' }}
              h='46px'
            >
              ย้อนกลับ
            </Button>
            <Button
              position={{ sm: 'fixed', md: 'relative' }}
              bottom={{ sm: '5px', md: '0px' }}
              type='submit'
              fontSize='sm'
              borderRadius={{ sm: '5px', md: '16px' }}
              w={{ sm: '98%', md: '148px' }}
              h='46px'
              ms='auto'
              isLoading={isSubmit}
              variant='solid'
              colorScheme={'btn'}
              loadingText={'Loading...'}
            >
              บันทึก
            </Button>
          </Flex>
        </form>
      ) : (
        <Box>
          <Center>
            <Spinner />
          </Center>
        </Box>
      )}
    </>
  )
}

export { FormInventory }
