import { db, storage } from 'Config/firebase'
import { useAppContext } from 'Context'
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
} from 'firebase/firestore'
import { getDownloadURL, ref, uploadString } from 'firebase/storage'
import { useCallback, useState } from 'react'
import _ from 'underscore'

export function useBranch() {
  const [Branch, setBranch] = useState([])
  const [BranchNoHQ, setBranchNoHQ] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { setError, setMessage, user } = useAppContext()

  //------- ref -------//
  const BranchRef = collection(db, 'Branch')

  //------- ref -------//

  function createBranch(data, branchImage) {
    try {
      if (user?.role === 'Administrator') {
        return addDoc(BranchRef, {
          ...data,
          createdAt: new Date(),
        }).then((res) => {
          const id = res.id
          const BranchDoc = doc(db, 'Branch', id)
          if (branchImage) {
            try {
              const ImageRef = ref(
                storage,
                `Branch/${id}/${new Date().getTime()}`
              )
              return uploadString(ImageRef, branchImage, 'data_url').then(
                async () => {
                  const downloadURL = await getDownloadURL(ImageRef)
                  return updateDoc(BranchDoc, { img: downloadURL }).then(() => {
                    setMessage({
                      type: 'success',
                      message: 'เพิ่มสาขาสำเร็จ',
                    })
                  })
                }
              )
            } catch (error) {
              setError({
                code: 'Branch',
                message: error.message,
              })
            }
          } else {
            setMessage({
              type: 'success',
              message: 'เพิ่มสาขาสำเร็จ',
            })
          }
        })
      } else {
        setMessage({ type: 'error', message: 'ไม่สามารถเพิ่มสาขาได้.' })
      }
    } catch (error) {
      setError({
        code: 'Branch',
        message: error.message,
      })
    }
  }

  function updateBranch(id, data, branchImage) {
    const BranchDoc = doc(db, 'Branch', id)
    try {
      return updateDoc(BranchDoc, {
        ...data,
        updatedAt: new Date(),
      }).then(async () => {
        const BranchDoc = doc(db, 'Branch', id)
        try {
          if (branchImage) {
            const ImageRef = ref(
              storage,
              `Branch/${id}/${new Date().getTime()}`
            )
            return uploadString(ImageRef, branchImage, 'data_url').then(
              async () => {
                const downloadURL = await getDownloadURL(ImageRef)
                return updateDoc(BranchDoc, { img: downloadURL }).then(
                  async () => {
                    setMessage({
                      type: 'success',
                      message: 'อัปเดตสาขาสำเร็จ.',
                    })
                  }
                )
              }
            )
          } else {
            setMessage({
              type: 'success',
              message: 'อัปเดตสาขาสำเร็จ.',
            })
          }
        } catch (error) {
          setError({
            code: 'Branch',
            message: error.message,
          })
        }
      })
    } catch (error) {
      setError({
        code: 'Branch',
        message: error.message,
      })
    }
  }

  async function getBranch(id) {
    const BranchDoc = doc(db, 'Branch', id)
    return (await getDoc(BranchDoc)).data()
  }

  const getBranchData = useCallback(async () => {
    const QueryBranch = query(BranchRef, orderBy('createdAt', 'asc'))
    const getBranchData = await getDocs(QueryBranch)
    const data = await _.map(getBranchData?.docs, (doc) => {
      return {
        id: doc?.id,
        ...doc?.data(),
      }
    })
    setBranch(data)
    setBranchNoHQ(
      _.reject(data, (i) => {
        return i.id === 'HQ'
      })
    )
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getBranchs = useCallback(async () => {
    const QueryBranch = query(BranchRef, orderBy('createdAt', 'asc'))
    const getBranchData = await getDocs(QueryBranch)
    const data = await _.map(getBranchData?.docs, (doc) => {
      return {
        id: doc?.id,
        ...doc?.data(),
      }
    })
    const filterHQ = await _.reject(data, (i) => {
      return i.id === 'HQ'
    })
    return filterHQ
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    Branch,
    BranchNoHQ,
    isLoading,
    createBranch,
    updateBranch,
    getBranchData,
    getBranch,
    getBranchs,
  }
}
