export function switchTextPromotionType(message) {
  switch (message) {
    case 'free':
      return 'โปรโมชั่นส่วนลดแบบมีเงื่อนไข'
    case 'discount':
      return 'ส่วนลดเป็นเปอร์เซ็นต์, จำนวนเงิน'
    default:
      return message
  }
}

export function switchTextPromotionTypeSecond(message) {
  switch (message) {
    case 'list':
      return 'รายการสินค้า'
    case 'category':
      return 'หมวดหมู่สินค้า'
    case 'receipt':
      return 'รายการขายทั้งบิลยอดขั้นต่ำที่กำหนด'
    default:
      return message
  }
}

export function switchTextPromotionTypeThird(message) {
  switch (message) {
    case 'list':
      return 'รายการสินค้า'
    case 'category':
      return 'หมวดหมู่สินค้า'
    case 'receipt':
      return 'ส่วนลด บาท, เปอร์เซ็นต์'
    default:
      return message
  }
}

export function switchTextPromotionDiscountTypeSecond(message) {
  switch (message) {
    case 'list':
      return 'รายการสินค้า'
    case 'category':
      return 'หมวดหมู่สินค้า'
    case 'receipt':
      return 'รายการขายทั้งบิล'
    default:
      return message
  }
}

/**
 * @interface
 * @param {array} promotion
 * @return true | false
 */
export const promotionCheckFreeCondition = (promotion) => {
  if (
    (promotion.typeDiscount === 'list' && promotion.typeFree === 'list') ||
    (promotion.typeDiscount === 'list' && promotion.typeFree === 'category') ||
    (promotion.typeDiscount === 'receipt' && promotion.typeFree === 'list') ||
    (promotion.typeDiscount === 'receipt' &&
      promotion.typeFree === 'category') ||
    (promotion.typeDiscount === 'category' &&
      promotion.typeFree === 'category') ||
    (promotion.typeDiscount === 'category' && promotion.typeFree === 'list')
  ) {
    return true
  } else {
    return false
  }
}

/**
 * @interface
 * @param {array} promotion
 * @return true | false
 */
export const promotionCheckDiscountCondition = (promotion) => {
  if (
    (promotion?.type === 'free' &&
      promotion?.typeDiscount === 'list' &&
      promotion?.typeFree === 'receipt') ||
    (promotion?.type === 'discount' && promotion?.typeDiscount === 'list')
  ) {
    return true
  } else {
    return false
  }
}

/**
 * @interface
 * @param {array} promotion
 * @return true | false
 */
export const promotionCheckCondition = (promotion) => {
  if (
    promotion.typeDiscount === 'receipt' ||
    promotion.typeDiscount === 'list' ||
    promotion.typeDiscount === 'category'
  ) {
    return true
  } else {
    return false
  }
}

/**
 * @interface
 * @param {array} promotion
 * @return true | false
 */
export const promotionCondition = (promotion) => {
  if (promotion.type === 'free') {
    return promotionCheckFreeCondition(promotion)
  } else if (promotion.type === 'discount') {
    return promotionCheckCondition(promotion)
  } else {
    return false
  }
}
