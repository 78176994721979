import { db, storage } from 'Config/firebase'
import { useAppContext } from 'Context'
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import { getDownloadURL, ref, uploadString } from 'firebase/storage'
import { useCallback, useState } from 'react'
import { useGenerateId } from './IdGenerate'

export function useExpenses() {
  const [isLoading, setIsLoading] = useState(false)
  const [isGetAllLoading, setIsGetAllLoading] = useState(false)
  const { IdOnlyNumber } = useGenerateId()
  const [Expenses, setExpenses] = useState([])
  const [Category, setCategory] = useState([])
  const { setMessage, setError, user } = useAppContext()

  //------- ref -------//

  const ExpensesRef = collection(db, 'Expenses')
  const ExpensesCategoryRef = collection(db, 'ExpensesCategory')

  //------- ref -------//

  const getExpensesCategorys = useCallback(async () => {
    const ExpensesCategorySnapRef = query(
      ExpensesCategoryRef,
      orderBy('createdAt', 'desc')
    )

    const unsubscribe = onSnapshot(ExpensesCategorySnapRef, (snap) => {
      let data = []
      snap.forEach((doc) => {
        data.push({ ...doc?.data(), id: doc?.id })
      })
      setCategory(data)
      setIsLoading(false)
    })

    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAallExpenses = useCallback(() => {
    setIsGetAllLoading(true)
    const expensesSnapRef = query(ExpensesRef, orderBy('createdAt', 'desc'))
    const unsubscribe = onSnapshot(expensesSnapRef, (snap) => {
      let data = []
      snap.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id })
      })
      setExpenses(data)
      setIsGetAllLoading(false)
    })
    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const AllExpenses = async () => {
    var date = new Date()
    date.setDate(date.getDate() - 6)
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    const getOrderLastWeek = query(
      ExpensesRef,
      where('branch', '==', user?.branchSelect?.id),
      where('createdAt', '>=', date),
      orderBy('createdAt', 'desc')
    )
    const data = await (
      await getDocs(getOrderLastWeek)
    ).docs.map((e) => ({
      id: e.id,
      ...e.data(),
      date: e.data().createdAt.toDate().toLocaleString('th-TH').split(' ')[0],
    }))
    return data
  }

  const AllExpensesInHQ = async (startDate, endDate) => {
    if (!startDate) {
      startDate = new Date().setDate(new Date().getDate() - 6)
    }
    if (!endDate) {
      endDate = new Date()
    }
    const start = new Date(startDate)
    start.setHours(0)
    start.setMinutes(0)
    start.setSeconds(0)
    start.setMilliseconds(0)

    const dateEnd = new Date(endDate)
    dateEnd.setDate(dateEnd.getDate() + 1)
    dateEnd.setHours(0)
    dateEnd.setMinutes(0)
    dateEnd.setSeconds(0)
    dateEnd.setMilliseconds(0)

    const getOrderLastWeek = query(
      ExpensesRef,
      where('createdAt', '>=', start),
      where('createdAt', '<=', dateEnd),
      orderBy('createdAt', 'desc')
    )
    const data = await (
      await getDocs(getOrderLastWeek)
    ).docs.map((e) => ({
      id: e.id,
      ...e.data(),
      date: e.data().createdAt.toDate().toLocaleString('th-TH').split(' ')[0],
    }))
    return data
  }

  function createExpensesCategory(id, value) {
    const ExpensesCategoryDoc = doc(db, 'ExpensesCategory', id)
    try {
      return setDoc(ExpensesCategoryDoc, {
        name: value.name,
        createdAt: new Date(),
        createdBy: {
          id: user?.uid,
          name: user?.name,
        },
      }).then(() => {
        setMessage({
          type: 'success',
          message: 'เพิ่มหมวดหมู่สำเร็จ.',
        })
      })
    } catch (error) {
      setError({
        code: 'Expenses Category',
        message: error.message,
      })
    }
  }

  function updateExpensesCategory(id, value) {
    const Doc = doc(db, 'ExpensesCategory', id)
    try {
      return updateDoc(Doc, {
        name: value.name,
        updatedBy: {
          id: user?.uid,
          name: user?.name,
        },
        updatedAt: new Date(),
      }).then(() => {
        setMessage({
          type: 'success',
          message: 'อัปเดตหมวดหมู่สำเร็จ.',
        })
      })
    } catch (error) {
      setError({
        code: 'Expenses Category',
        message: error.message,
      })
    }
  }

  function deleteExpensesCategory(id) {
    const Doc = doc(db, 'ExpensesCategory', id)
    try {
      return deleteDoc(Doc).then(() => {
        setMessage({
          type: 'success',
          message: 'ลบหมวดหมู่สำเร็จ.',
        })
      })
    } catch (error) {
      setError({
        code: 'Expenses Category',
        message: error.message,
      })
    }
  }

  async function getExpensesCategoryId(id) {
    const CateDoc = doc(db, 'ExpensesCategory', id)
    const res = (await getDoc(CateDoc)).data()
    return res
  }

  function updateImage(id, file) {
    const Doc = doc(db, 'Expenses', id)
    return updateDoc(Doc, { image: file })
  }

  async function createExpenses(id, value, expensesImage) {
    const ExpensesRef = doc(db, 'Expenses', id)
    const file = []
    try {
      return await setDoc(ExpensesRef, {
        ...value,
        status: 'waiting',
        createdAt: new Date(),
        createdBy: {
          id: user?.uid,
          name: user?.name,
        },
      }).then(() => {
        if (expensesImage) {
          try {
            return expensesImage.map(async (item, index) => {
              const ImageRef = ref(
                storage,
                `Expenses/${id}/${new Date().getTime() + index}`
              )
              await uploadString(ImageRef, item, 'data_url')
                .then(async () => {
                  await getDownloadURL(ImageRef).then((URLs) => {
                    return file.push(URLs)
                  })
                })
                .then(() => {
                  updateImage(id, file)
                })
            })
          } catch (error) {
            setError({
              code: 'Expenses',
              message: error.message,
            })
          }
        }
      })
    } catch (error) {
      setError({
        code: 'Expenses',
        message: error.message,
      })
    }
  }

  function reItem(value) {
    const id = `EXP${IdOnlyNumber(10)}`
    const ExpensesRef = doc(db, 'Expenses', id)
    delete value?.id
    try {
      return setDoc(ExpensesRef, {
        ...value,
        status: 'waiting',
        createdAt: new Date(),
      })
    } catch (error) {
      setError({
        code: 'Expenses',
        message: error.message,
      })
    }
  }

  function deleteExpenses(id) {
    const Doc = doc(db, 'Expenses', id)
    try {
      return deleteDoc(Doc)
    } catch (errors) {
      setError({
        code: 'Expenses',
        message: errors.message,
      })
    }
  }
  async function updateExpenses(id, value, expensesImage) {
    const ExpensesRef = doc(db, 'Expenses', id)
    const file = []

    try {
      return await updateDoc(ExpensesRef, {
        ...value,
        status: 'waiting',
        updatedAt: new Date(),
        updatedBy: {
          id: user?.uid,
          name: user?.name,
        },
      }).then(() => {
        if (expensesImage) {
          try {
            return expensesImage.map(async (item, index) => {
              if (item?.slice(0, 4) !== 'http') {
                const ImageRef = ref(
                  storage,
                  `Expenses/${id}/${new Date().getTime() + index}`
                )
                await uploadString(ImageRef, item, 'data_url').then(
                  async () => {
                    await getDownloadURL(ImageRef).then((URLs) => {
                      return file.push(URLs)
                    })
                  }
                )
              } else if (item?.slice(0, 4) !== 'data') {
                return file.push(item)
              }
              return updateImage(id, file)
            })
          } catch (error) {
            setError({
              code: 'Expenses',
              message: error.message,
            })
          }
        }
      })
    } catch (error) {
      setError({
        code: 'Expenses',
        message: error.message,
      })
    }
  }

  async function getExpensesId(id) {
    const Doc = doc(db, 'Expenses', id)
    return (await getDoc(Doc)).data()
  }

  function expensesApproveOrReject(id, value) {
    const { status } = value
    const Doc = doc(db, 'Expenses', id)
    return updateDoc(Doc, {
      status: status,
      updatedAt: new Date(),
      updatedBy: {
        id: user?.uid,
        name: user?.name,
      },
    })
  }

  return {
    isGetAllLoading,
    isLoading,
    Category,
    Expenses,
    getAallExpenses,
    getExpensesCategorys,
    createExpenses,
    updateExpenses,
    deleteExpenses,
    reItem,
    getExpensesId,
    createExpensesCategory,
    updateExpensesCategory,
    deleteExpensesCategory,
    getExpensesCategoryId,
    expensesApproveOrReject,
    AllExpenses,
    AllExpensesInHQ,
  }
}
