export const paymentThumbnail = [
  {
    id: 'kasikornBank',
    name: 'ธนาคารกสิกรไทย',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Fkasikorn.png?alt=media&token=580a8e0e-989d-4ec2-a971-5ae44d0bdd0c',
  },
  {
    id: 'krungThaiBank',
    name: 'ธนาคารกรุงไทย',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Fkrung-thai.png?alt=media&token=c7b766f5-255c-4ee5-8652-85dc502f7606',
  },
  {
    id: 'siamCommercialBank',
    name: 'ธนาคารไทยพาณิชย์',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Fscb.png?alt=media&token=c614cd69-d408-49c5-b06f-31ba6013f3d1',
  },
  {
    id: 'bangkokBank',
    name: 'ธนาคารกรุงเทพ',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Fbangkok.png?alt=media&token=e29dd13f-42af-4fd0-850f-e0b16d1caebf',
  },
  {
    id: 'aomsin',
    name: 'ธนาคารออมสิน',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Faomsin.png?alt=media&token=519ba856-3504-4e39-89b8-291324c3506e',
  },
  {
    id: 'bankOfAyudhya',
    name: 'ธนาคารกรุงศรีอยุธยา',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Fayudhya.png?alt=media&token=55a19aa8-54a4-42ba-bf0e-820a9674b5dd',
  },
  {
    id: 'thaiThanachartMilitaryBank',
    name: 'ธนาคารทหารไทยธนชาต',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Ftmb.jpg?alt=media&token=925cdd13-d61c-48d9-90f9-cc7ec97d365f',
  },
  {
    id: 'cimbThaiBank',
    name: 'ธนาคารซีไอเอ็มบีไทย',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Fcimb.png?alt=media&token=031fd5f3-f7e8-4ee0-87ce-11fd1307dfa5',
  },
  {
    id: 'standardCharterBank',
    name: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Fstandard-chartered.png?alt=media&token=2854fb5b-f1af-4f28-82b4-8ccdef260c8a',
  },
  {
    id: 'uobBank',
    name: 'ธนาคารยูโอบี',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Fuob.png?alt=media&token=ce4da5e7-c20c-4ef8-a434-52270a3d0d97',
  },
  {
    id: 'tiscoBank',
    name: 'ธนาคารทิสโก้',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Ftisco.jpg?alt=media&token=434bc41e-a791-4a0b-9fe2-d6455f3fa978',
  },
  {
    id: 'kiatNakinkaBank',
    name: 'ธนาคารเกียรตินาคินภัทร',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Fkiatnakin-phatra.jpg?alt=media&token=0da2d574-cdcf-472f-b671-f8408d0a60ff',
  },
  {
    id: 'landAndHouseBank',
    name: 'ธนาคารแลนด์ แอนด์ เฮ้าส์',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Fland-houses.png?alt=media&token=7716ee00-acb7-4972-a24f-dc19eea43aae',
  },
  {
    id: 'icbc',
    name: 'ธนาคารไอซีบีซี',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Ficbc.png?alt=media&token=7aea65a3-105b-4c0c-8437-4d8096407103',
  },
  {
    id: 'thaiCreditBankForSmall',
    name: 'ธนาคารไทยเครดิต เพื่อรายย่อย',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Fthai-credit-retail.png?alt=media&token=bc7b7b84-eafe-4e83-a730-776c6702609d',
  },
  {
    id: 'megaBankInternationalCommerce',
    name: 'ธนาคารเมกะ สากลพาณิชย์',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Fmega-international.png?alt=media&token=a0edc906-c9cb-4fcb-8ab3-96722ff31fae',
  },
  {
    id: 'bankOfChina',
    name: 'ธนาคารแห่งประเทศจีน',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Fchina.jpg?alt=media&token=3bfbe963-2577-4122-94e6-4bf2c4d2d91a',
  },
  {
    id: 'anz',
    name: 'ธนาคารเอเอ็นแซด',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Fanz.png?alt=media&token=9f978848-c3cc-4308-8149-e5dd42fc01d4',
  },
  {
    id: 'sumitomoMitsuiTrust',
    name: 'ธนาคารซูมิโตโม มิตซุย ทรัสต์',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/gogreeninventory.appspot.com/o/Thumbnail%2FBank%2Fsumitomo-mitsui-trust.png?alt=media&token=1b876b28-c404-4850-b081-e35697f26e1b',
  },
]
