// Chakra imports
import {
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import InputField from 'Components/Horizons/fields/InputField'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
export default function Info({ data }) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const {
    setValue,
    control,
    formState: { errors },
  } = useForm()
  useEffect(() => {
    let isMounted = true
    const fetchValue = () => {
      if (isMounted) {
        setValue('name', data?.name)
        setValue('taxNumber', data?.taxNumber)
        setValue('typeOfBranch', data?.typeOfBranch)
        setValue('typeOfBusiness', data?.typeOfBusiness)
        setValue('vat', data?.vat)
        setValue('wholeSale', data?.wholeSale ? 'ขายส่ง' : 'ขายปลีก')
      }
    }
    fetchValue()
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  return (
    <FormControl h='full'>
      <Card h='full' mb={{ base: '0px', xl: '20px' }}>
        <Flex direction='column' mb='40px' ms='10px'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            ข้อมูลสาขา
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          <Flex direction='column'>
            <InputField
              control={control}
              errors={errors}
              readOnly
              name='typeOfBusiness'
              mb='25px'
              me='30px'
              id='typeOfBusiness'
              label='ประเภทธุรกิจ'
              placeholder='ประเภทธุรกิจ'
            />
            <InputField
              control={control}
              errors={errors}
              readOnly
              name='vat'
              mb='25px'
              id='vat'
              label='จดภาษีมูลค่าเพิ่ม'
              placeholder='จดภาษีมูลค่าเพิ่ม'
            />
            <InputField
              control={control}
              errors={errors}
              readOnly
              name='taxNumber'
              mb='25px'
              id='taxNumber'
              label='เลขประจำตัวผู้เสียภาษี'
              placeholder='เลขประจำตัวผู้เสียภาษี'
            />
          </Flex>
          <Flex direction='column'>
            <InputField
              control={control}
              errors={errors}
              readOnly
              name='name'
              mb='25px'
              me='30px'
              id='name'
              label='ชื่อสาขา'
              placeholder='ชื่อสาขา'
            />

            <InputField
              control={control}
              errors={errors}
              readOnly
              name='typeOfBranch'
              mb='25px'
              id='typeOfBranch'
              label='สำนักงาน / สาขา'
              placeholder='สำนักงาน / สาขา'
            />

            <InputField
              control={control}
              errors={errors}
              readOnly
              name='wholeSale'
              mb='25px'
              id='wholeSale'
              label='ประภาทสาขา'
              placeholder='ประเภทสาขา'
            />
          </Flex>
        </SimpleGrid>
      </Card>
    </FormControl>
  )
}
