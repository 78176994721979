import { Center, Flex, Grid, GridItem, Text, VStack } from '@chakra-ui/react'
import { currency } from 'Utils/currency'
import {
  calDiscount,
  calProfit,
  calSellingPrice,
  calRefund,
  calNetSales,
  calCommissionEmp,
  calProfitCommission,
  calOrderCost,
  calEmployeeSalary,
  calExpenses,
  calTotalProfit,
} from './functions/card'

export function DashboardCard({ order, userList, work, expenses }) {
  return (
    <Grid
      mt={{ base: '1.5rem', sm: '1.5rem', md: '0rem', lg: '0rem' }}
      templateColumns={{
        base: 'repeat(2, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(3, 1fr)',
        lg: 'repeat(3, 1f r)',
        xl: 'repeat(4, 1fr)',
        '2xl': 'repeat(4, 1fr)',
      }}
      fontFamily='DM Sans'
      gap={0}
      w='100%'
      py='1rem'
      borderRadius='15px'
    >
      <GridItem w='100%' h='auto'>
        <Center
          justifyContent={{
            base: 'start',
            sm: 'start',
            md: 'center',
            lg: 'center',
          }}
        >
          <Flex
            flexDirection='column'
            fontSize={{
              md: '14px',
              lg: '16px',
              xl: '14px',
              '2xl': '16px',
            }}
          >
            <Text fontWeight='700' fontFamily='Prompt'>
              ยอดขาย
            </Text>
            <Text
              fontSize={{
                md: '20px',
                lg: '22px',
                xl: '22px',
                '2xl': '24px',
              }}
              color='#90EE90'
            >
              {currency(calSellingPrice(order).total, 2, true)}
            </Text>
          </Flex>
        </Center>
      </GridItem>

      <GridItem w='100%' h='auto' borderLeft='1px solid #E0E5F2'>
        <Center
          justifyContent={{
            base: 'start',
            sm: 'start',
            md: 'center',
            lg: 'center',
          }}
          pl={{ base: '15px', sm: '15px', md: '0px', lg: '0px' }}
        >
          <Flex
            flexDirection='column'
            fontSize={{
              md: '14px',
              lg: '16px',
              xl: '14px',
              '2xl': '16px',
            }}
          >
            <Text fontWeight='700' fontFamily='Prompt'>
              คืนเงิน
            </Text>
            <Text
              fontSize={{
                md: '20px',
                lg: '22px',
                xl: '22px',
                '2xl': '24px',
              }}
              color='#E64136'
            >
              {currency(calRefund(order).total, 2, true)}
            </Text>
          </Flex>
        </Center>
      </GridItem>
      <GridItem
        w='100%'
        h='auto'
        borderLeft={{
          base: '0px solid #E0E5F2',
          sm: '0px solid #E0E5F2',
          md: '1px solid #E0E5F2',
          lg: '1px solid #E0E5F2',
        }}
        mt={{ base: '2rem', sm: '2rem', md: '0rem', lg: '0rem' }}
      >
        <Center
          justifyContent={{
            base: 'start',
            sm: 'start',
            md: 'center',
            lg: 'center',
          }}
        >
          <Flex
            flexDirection='column'
            fontSize={{
              md: '14px',
              lg: '16px',
              xl: '14px',
              '2xl': '16px',
            }}
          >
            <Text fontWeight='700' fontFamily='Prompt'>
              ส่วนลด
            </Text>
            <Text
              fontSize={{
                md: '20px',
                lg: '22px',
                xl: '22px',
                '2xl': '24px',
              }}
              color='#FF6501'
            >
              {currency(calDiscount(order).total?.toFixed(2), 2, true)}
            </Text>
          </Flex>
        </Center>
      </GridItem>

      <GridItem
        mt={{
          base: '2rem',
          sm: '2rem',
          md: '2rem',
          lg: '2rem',
          xl: '0rem',
          '2xl': '0rem',
        }}
        w='100%'
        h='auto'
        borderLeft={{
          base: '1px solid #E0E5F2',
          sm: '1px solid #E0E5F2',
          md: '0px solid #E0E5F2',
          lg: '0px solid #E0E5F2',
          xl: '1px solid #E0E5F2',
          '2xl': '1px solid #E0E5F2',
        }}
      >
        <Center
          pl={{ base: '15px', sm: '15px', md: '0px', lg: '0px' }}
          justifyContent={{
            base: 'start',
            sm: 'start',
            md: 'center',
            lg: 'center',
          }}
        >
          <Flex
            flexDirection='column'
            fontSize={{
              md: '14px',
              lg: '16px',
              xl: '14px',
              '2xl': '16px',
            }}
          >
            <Text fontWeight='700' fontFamily='Prompt'>
              ต้นทุน
            </Text>
            <Text
              fontSize={{
                md: '20px',
                lg: '22px',
                xl: '22px',
                '2xl': '24px',
              }}
              color='#FFEA00'
            >
              {currency(calOrderCost(order), 2, true)}
            </Text>
          </Flex>
        </Center>
      </GridItem>
      <GridItem
        mt={{
          base: '2rem',
          sm: '2rem',
          md: '2rem',
          lg: '2rem',
          xl: '2rem',
          '2xl': '2rem',
        }}
        w='100%'
        h='auto'
        borderLeft={{
          base: '0px solid #E0E5F2',
          sm: '0px solid #E0E5F2',
          md: '1px solid #E0E5F2',
          lg: '1px solid #E0E5F2',
          xl: '0px solid #E0E5F2',
          '2xl': '0px solid #E0E5F2',
        }}
      >
        <Center
          pl={{ base: '0px', sm: '0px', md: '0px', lg: '0px' }}
          justifyContent={{
            base: 'start',
            sm: 'start',
            md: 'center',
            lg: 'center',
          }}
        >
          <Flex
            flexDirection='column'
            fontSize={{
              md: '14px',
              lg: '16px',
              xl: '14px',
              '2xl': '16px',
            }}
          >
            <Text fontWeight='700' fontFamily='Prompt'>
              ยอดขายสุทธิ
            </Text>
            <Text
              fontSize={{
                md: '20px',
                lg: '22px',
                xl: '22px',
                '2xl': '24px',
              }}
              color='#01B574'
            >
              {currency(calNetSales(order).total, 2, true)}
            </Text>
          </Flex>
        </Center>
      </GridItem>
      <GridItem
        w='100%'
        h='auto'
        mt={{
          base: '2rem',
          sm: '2rem',
          md: '2rem',
          lg: '2rem',
          xl: '2rem',
          '2xl': '2rem',
        }}
        borderLeft={{
          base: '1px solid #E0E5F2',
          sm: '1px solid #E0E5F2',
          md: '1px solid #E0E5F2',
          lg: '1px solid #E0E5F2',
          xl: '1px solid #E0E5F2',
          '2xl': '1px solid #E0E5F2',
        }}
      >
        <Center
          pl={{ base: '15px', sm: '15px', md: '0px', lg: '0px' }}
          justifyContent={{
            base: 'start',
            sm: 'start',
            md: 'center',
            lg: 'center',
          }}
        >
          <Flex
            flexDirection='column'
            fontSize={{
              md: '14px',
              lg: '16px',
              xl: '14px',
              '2xl': '16px',
            }}
          >
            <Text fontWeight='700' fontFamily='Prompt'>
              กำไรสินค้า
            </Text>
            <Text
              fontSize={{
                md: '20px',
                lg: '22px',
                xl: '22px',
                '2xl': '24px',
              }}
              color='#32CD32'
            >
              {currency(calProfit(order).total, 2, true)}
            </Text>
          </Flex>
        </Center>
      </GridItem>

      <GridItem
        w='100%'
        h='auto'
        mt={{
          base: '2rem',
          sm: '2rem',
          md: '2rem',
          lg: '2rem',
          xl: '2rem',
          '2xl': '2rem',
        }}
        borderLeft={{
          base: '0px solid #E0E5F2',
          sm: '0px solid #E0E5F2',
          md: '0px solid #E0E5F2',
          lg: '0px solid #E0E5F2',
          xl: '1px solid #E0E5F2',
          '2xl': '1px solid #E0E5F2',
        }}
      >
        <Center
          pl={{ base: '0px', sm: '0px', md: '0px', lg: '0px' }}
          justifyContent={{
            base: 'start',
            sm: 'start',
            md: 'center',
            lg: 'center',
          }}
        >
          <Flex
            flexDirection='column'
            fontSize={{
              md: '14px',
              lg: '16px',
              xl: '14px',
              '2xl': '16px',
            }}
          >
            <Text fontWeight='700' fontFamily='Prompt'>
              คอมมิชชั่น
            </Text>
            <Text
              fontSize={{
                md: '20px',
                lg: '22px',
                xl: '22px',
                '2xl': '24px',
              }}
              color='#CC5500'
            >
              {currency(calCommissionEmp(order, work, userList), 2, true)}
            </Text>
          </Flex>
        </Center>
      </GridItem>

      <GridItem
        w='100%'
        h='auto'
        mt={{
          base: '2rem',
          sm: '2rem',
          md: '2rem',
          lg: '2rem',
          xl: '2rem',
          '2xl': '2rem',
        }}
        borderLeft={{
          base: '1px solid #E0E5F2',
          sm: '1px solid #E0E5F2',
          md: '1px solid #E0E5F2',
          lg: '1px solid #E0E5F2',
          xl: '1px solid #E0E5F2',
          '2xl': '1px solid #E0E5F2',
        }}
      >
        <Center
          pl={{ base: '15px', sm: '15px', md: '0px', lg: '0px' }}
          justifyContent={{
            base: 'start',
            sm: 'start',
            md: 'center',
            lg: 'center',
          }}
        >
          <VStack
            flexDirection='column'
            fontSize={{
              md: '14px',
              lg: '16px',
              xl: '14px',
              '2xl': '16px',
            }}
            spacing={0}
            alignItems={{ base: 'start', md: 'center' }}
          >
            <Text fontWeight='700' fontFamily='Prompt'>
              กำไรหลังหักคอมมิชชั่น
            </Text>
            <Text
              fontSize={{
                md: '20px',
                lg: '22px',
                xl: '22px',
                '2xl': '24px',
              }}
              color='#4F7942'
            >
              {currency(calProfitCommission(order, work, userList), 2, true)}
            </Text>
          </VStack>
        </Center>
      </GridItem>

      <GridItem
        mt={{
          base: '2rem',
          sm: '2rem',
          md: '2rem',
          lg: '2rem',
          xl: '2rem',
          '2xl': '2rem',
        }}
        w='100%'
        h='auto'
        borderLeft={{
          base: '0px solid #E0E5F2',
          sm: '0px solid #E0E5F2',
          md: '1px solid #E0E5F2',
          lg: '1px solid #E0E5F2',
          xl: '0px solid #E0E5F2',
          '2xl': '0px solid #E0E5F2',
        }}
      >
        <Center
          pl={{ base: '0px', sm: '0px', md: '0px', lg: '0px' }}
          justifyContent={{
            base: 'start',
            sm: 'start',
            md: 'center',
            lg: 'center',
          }}
        >
          <VStack
            spacing={0}
            fontSize={{
              md: '14px',
              lg: '16px',
              xl: '14px',
              '2xl': '16px',
            }}
            alignItems={{ base: 'start', md: 'center' }}
          >
            <Text fontWeight='700' fontFamily='Prompt'>
              ค่าจ้างพนักงานรายวัน
            </Text>
            <Text
              fontSize={{
                md: '20px',
                lg: '22px',
                xl: '22px',
                '2xl': '24px',
              }}
              color='#01B574'
            >
              {currency(
                calEmployeeSalary(order, userList, work)?.total,
                2,
                true
              )}
            </Text>
          </VStack>
        </Center>
      </GridItem>

      <GridItem
        w='100%'
        h='auto'
        mt={{
          base: '2rem',
          sm: '2rem',
          md: '2rem',
          lg: '2rem',
          xl: '2rem',
          '2xl': '2rem',
        }}
        borderLeft={{
          base: '1px solid #E0E5F2',
          sm: '1px solid #E0E5F2',
          md: '1px solid #E0E5F2',
          lg: '1px solid #E0E5F2',
          xl: '1px solid #E0E5F2',
          '2xl': '1px solid #E0E5F2',
        }}
      >
        <Center
          pl={{ base: '15px', sm: '15px', md: '0px', lg: '0px' }}
          justifyContent={{
            base: 'start',
            sm: 'start',
            md: 'center',
            lg: 'center',
          }}
        >
          <VStack
            flexDirection='column'
            fontSize={{
              md: '14px',
              lg: '16px',
              xl: '14px',
              '2xl': '16px',
            }}
            spacing={0}
            alignItems={{ base: 'start', md: 'center' }}
          >
            <Text fontWeight='700' fontFamily='Prompt'>
              ค่าใช้จ่าย
            </Text>
            <Text
              fontSize={{
                md: '20px',
                lg: '22px',
                xl: '22px',
                '2xl': '24px',
              }}
              color='#4F7942'
            >
              {currency(calExpenses(expenses), 2, true)}
            </Text>
          </VStack>
        </Center>
      </GridItem>

      <GridItem
        w='100%'
        h='auto'
        mt={{
          base: '2rem',
          sm: '2rem',
          md: '2rem',
          lg: '2rem',
          xl: '2rem',
          '2xl': '2rem',
        }}
        borderLeft={{
          base: '1px solid #E0E5F2',
          sm: '1px solid #E0E5F2',
          md: '1px solid #E0E5F2',
          lg: '1px solid #E0E5F2',
          xl: '1px solid #E0E5F2',
          '2xl': '1px solid #E0E5F2',
        }}
      >
        <Center
          pl={{ base: '15px', sm: '15px', md: '0px', lg: '0px' }}
          justifyContent={{
            base: 'start',
            sm: 'start',
            md: 'center',
            lg: 'center',
          }}
        >
          <VStack
            flexDirection='column'
            fontSize={{
              md: '14px',
              lg: '16px',
              xl: '14px',
              '2xl': '16px',
            }}
            spacing={0}
            alignItems={{ base: 'start', md: 'center' }}
          >
            <Text fontWeight='700' fontFamily='Prompt'>
              กำไรรวม
            </Text>
            <Text
              fontSize={{
                md: '20px',
                lg: '22px',
                xl: '22px',
                '2xl': '24px',
              }}
              color='#4F7942'
            >
              {currency(
                calTotalProfit(
                  calProfitCommission(order, work, userList),
                  calEmployeeSalary(order, userList, work)?.total,
                  calExpenses(expenses)
                ),
                2,
                true
              )}
            </Text>
          </VStack>
        </Center>
      </GridItem>
    </Grid>
  )
}
