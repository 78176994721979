export function height(os) {
  switch (os) {
    case 'iOS':
      return '87vh'
    case 'Android':
      return '90vh'
    default:
      return '87vh'
  }
}
