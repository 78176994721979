//ราคาสินค้าที่แสดงไว้ได้บวกภาษีมูลค่าเพิ่มไว้แล้ว ไม่ต้องจ่ายเพิ่มจากป้าย
export const vatIncluding = (balance) => {
  const cal = parseFloat((balance * 7) / 107)
  const calGrand = parseFloat(balance - cal).toFixed(2)
  return {
    vat: parseFloat(cal.toFixed(2)),
    total: parseFloat(calGrand),
  }
}

//ราคาสินค้าที่แสดงไว้ยังไม่รวมภาษีมูลค่าเพิ่ม ต้องจ่ายเงินเพิ่มตามภาษี vat จากป้าย
export const vatExcluding = (balance) => {
  const cal = (balance * 7) / 100
  const calGrand = parseFloat(balance + cal).toFixed(2)
  return {
    vat: parseFloat(cal.toFixed(2)),
    total: parseFloat(calGrand),
  }
}
