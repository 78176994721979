import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Spinner,
  Fade,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import { useAppContext } from 'Context'
import { useExpenses } from 'Hooks'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { onSnapshot, collection } from 'firebase/firestore'
import { db } from 'Config/firebase'
import { decodeUnit } from 'Utils/currency'

function ExpensesReItem({ value, setIsFadeCard, setIsLoading }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const navigate = useNavigate()

  function handleReCreate(item) {
    navigate(`/expenses/new/${item}`)
  }
  return (
    <>
      <Text w='full' onClick={onOpen}>
        สร้างซ้ำ
      </Text>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size='auto'
      >
        <AlertDialogOverlay />

        <AlertDialogContent w={{ sm: '20.5rem', md: '25rem' }}>
          <AlertDialogHeader>คุณแน่ใจ?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            คุณแน่ใจที่จะสร้างรายการนี้ซ้ำใช่หรือไม่ ?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              ไม่
            </Button>
            <Button
              colorScheme='green'
              ml={3}
              onClick={() => {
                handleReCreate(value.id)
              }}
            >
              ใช่
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

function ExpensesReStatus({ id, setIsFadeCard, setIsLoading }) {
  const { setMessage } = useAppContext()
  const { expensesApproveOrReject } = useExpenses()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  function handleReset(id) {
    setIsLoading(true)
    setIsFadeCard(true)
    expensesApproveOrReject(id, { status: 'waiting' }).then(() => {
      setMessage({
        type: 'success',
        message: 'รีเซ็ตสถานะรายการสำเร็จ',
      })
      setIsLoading(false)
      setIsFadeCard(false)
      onClose()
    })
  }
  return (
    <>
      <Text w='full' onClick={onOpen}>
        รีเซ็ตสถานะ
      </Text>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size='auto'
      >
        <AlertDialogOverlay />

        <AlertDialogContent
          fontFamily={'Prompt'}
          w={{ sm: '20.5rem', md: '25rem' }}
        >
          <AlertDialogHeader>อนุมัติรายการ {id}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            คุณต้องการที่จะรีเซ็ตสถานะรายการนี้หรือไม่ ?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme={'red'} w='5em' onClick={() => onClose}>
              ยกเลิก
            </Button>
            <Button
              colorScheme='green'
              w='5em'
              ml={3}
              onClick={() => {
                handleReset(id)
              }}
            >
              รีเซ็ต
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

function ExpensesStatus({ id, setIsFadeCard, setIsLoading }) {
  const { setMessage } = useAppContext()
  const { expensesApproveOrReject } = useExpenses()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  function handleReject(id) {
    setIsLoading(true)
    setIsFadeCard(true)
    expensesApproveOrReject(id, { status: 'reject' }).then(() => {
      setMessage({
        type: 'success',
        message: 'ยกเลิกรายการสำเร็จ',
      })
      setIsLoading(false)
      setIsFadeCard(false)
      onClose()
    })
  }
  function handleApprovel(id) {
    setIsLoading(true)
    setIsFadeCard(true)
    expensesApproveOrReject(id, { status: 'approvel' }).then(() => {
      setMessage({
        type: 'success',
        message: 'อนุมัติรายการสำเร็จ',
      })
      setIsLoading(false)
      setIsFadeCard(false)
      onClose()
    })
  }
  return (
    <>
      <Text w='full' onClick={onOpen}>
        เปลี่ยนสถานะ
      </Text>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size='auto'
      >
        <AlertDialogOverlay />

        <AlertDialogContent
          fontFamily={'Prompt'}
          w={{ sm: '20.5rem', md: '25rem' }}
        >
          <AlertDialogHeader>อนุมัติรายการ {id}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            คุณต้องการที่จะอนุมัติรายการนี้หรือไม่ ?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              colorScheme={'red'}
              w='5em'
              onClick={() => {
                handleReject(id)
              }}
            >
              ไม่อนุมัติ
            </Button>
            <Button
              colorScheme='green'
              w='5em'
              ml={3}
              onClick={() => {
                handleApprovel(id)
              }}
            >
              อนุมัติ
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
function ExpensesCard({ item, user, setIsFadeCard, setIsLoading }) {
  const { deleteExpenses } = useExpenses()
  const { setMessage } = useAppContext()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const cancelRef = useRef()

  const textColor2 = useColorModeValue('secondaryGray.500', 'white')
  const textHover = useColorModeValue(
    { color: 'secondaryGray.900', bg: 'unset' },
    { color: 'secondaryGray.500', bg: 'unset' }
  )
  const iconColor = useColorModeValue('#494D51', 'white')
  const bgList = useColorModeValue('white', 'whiteAlpha.100')
  const bgShadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    'unset'
  )
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100')
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' }
  )
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' }
  )

  function handleDelete(id) {
    setIsLoading(true)
    setIsFadeCard(true)
    deleteExpenses(id).then(() => {
      setMessage({
        type: 'success',
        message: 'ลบรายการ',
      })
      setIsLoading(false)
      setIsFadeCard(false)
      onClose()
    })
  }
  return (
    <>
      <Box
        w='full'
        px='1em'
        pt='.25em'
        transition='all 0.3s'
        _hover={{
          transition: 'all 0.3s',
          bg: '#F9F9F9',
        }}
        bg='white'
      >
        <HStack justifyContent={'space-between'}>
          <Box as={Link} to={`/expenses/view/${item?.id}`} w='full'>
            <Text>{item?.category}</Text>
            <HStack color={'#A3AED0'}>
              <Text fontSize={'xs'}>
                {moment(item?.Date).format('MM/DD/YYYY')}
              </Text>
              <Text fontSize={'xs'}>({item?.name})</Text>
            </HStack>
            <Text
              color={
                item?.status === 'waiting'
                  ? '#FFB547'
                  : item?.status === 'approvel'
                  ? '#23A979'
                  : item?.status === 'reject' && '#FF3E3E'
              }
            >
              {item?.status === 'waiting'
                ? 'รอดำเนินการ'
                : item?.status === 'approvel'
                ? 'ชำระเงินแล้ว'
                : item?.status === 'reject' && 'ยกเลิก'}
            </Text>
          </Box>
          <HStack>
            <Text color={'expenses.500'}>
              {item?.vatType === 'รวมภาษี'
                ? new Intl.NumberFormat('th-TH', {
                    minimumFractionDigits: 2,
                  }).format(decodeUnit(item?.total))
                : new Intl.NumberFormat('th-TH', {
                    minimumFractionDigits: 2,
                  }).format(decodeUnit(item?.grandTotal))}
            </Text>
            <Menu>
              <MenuButton
                as={Button}
                align='center'
                justifyContent='center'
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                w='37px'
                h='37px'
                lineHeight='100%'
                borderRadius='10px'
                p='0px'
              >
                <Icon
                  as={MdOutlineMoreHoriz}
                  color={iconColor}
                  w='24px'
                  h='24px'
                />
              </MenuButton>
              <MenuList
                w='150px'
                minW='unset'
                maxW='150px !important'
                border='transparent'
                backdropFilter='blur(63px)'
                bg={bgList}
                boxShadow={bgShadow}
                borderRadius='20px'
                p='15px'
              >
                {user?.role !== 'Cashier' && (
                  <>
                    {item?.status !== 'approvel' && (
                      <MenuItem
                        transition='0.2s linear'
                        color={textColor2}
                        _hover={textHover}
                        p='0px'
                        borderRadius='8px'
                        _active={{
                          bg: 'transparent',
                        }}
                        _focus={{
                          bg: 'transparent',
                        }}
                        mb='10px'
                      >
                        <Flex align='center' w='full'>
                          <ExpensesStatus
                            id={item?.id}
                            setIsFadeCard={setIsFadeCard}
                            setIsLoading={setIsLoading}
                          />
                        </Flex>
                      </MenuItem>
                    )}
                    {item?.status !== 'waiting' && (
                      <MenuItem
                        transition='0.2s linear'
                        color={textColor2}
                        _hover={textHover}
                        p='0px'
                        borderRadius='8px'
                        _active={{
                          bg: 'transparent',
                        }}
                        _focus={{
                          bg: 'transparent',
                        }}
                        mb='10px'
                      >
                        <Flex align='center' w='full'>
                          <ExpensesReStatus
                            id={item?.id}
                            setIsFadeCard={setIsFadeCard}
                            setIsLoading={setIsLoading}
                          />
                        </Flex>
                      </MenuItem>
                    )}
                  </>
                )}
                <>
                  <MenuItem
                    transition='0.2s linear'
                    p='0px'
                    borderRadius='8px'
                    color={textColor2}
                    _hover={textHover}
                    _active={{
                      bg: 'transparent',
                    }}
                    _focus={{
                      bg: 'transparent',
                    }}
                    mb='10px'
                  >
                    <Flex align='center' w='full'>
                      <ExpensesReItem
                        value={item}
                        setIsFadeCard={setIsFadeCard}
                        setIsLoading={setIsLoading}
                      />
                    </Flex>
                  </MenuItem>
                  {item?.status === 'waiting' && (
                    <>
                      <MenuItem
                        transition='0.2s linear'
                        p='0px'
                        borderRadius='8px'
                        color={textColor2}
                        _active={{
                          bg: 'transparent',
                        }}
                        _focus={{
                          bg: 'transparent',
                        }}
                        mb='10px'
                        as={Link}
                        to={`/expenses/edit/${item?.id}`}
                        _hover={{
                          color: 'discount.500',
                        }}
                      >
                        <Flex align='center' w='full'>
                          <Text w='full'>แก้ไข</Text>
                        </Flex>
                      </MenuItem>
                      <MenuItem
                        onClick={onOpen}
                        transition='0.2s linear'
                        p='0px'
                        borderRadius='8px'
                        color={textColor2}
                        _hover={{
                          color: 'red',
                        }}
                        _active={{
                          bg: 'transparent',
                        }}
                        _focus={{
                          bg: 'transparent',
                        }}
                        mb='10px'
                      >
                        <Flex align='center' w='full'>
                          <Text w='full'>ลบ</Text>
                        </Flex>
                      </MenuItem>
                    </>
                  )}
                </>
              </MenuList>
            </Menu>
          </HStack>
        </HStack>
        <Divider mt={'.5em'} />
      </Box>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>คุณแน่ใจ?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            คุณแน่ใจที่จะลบรายการนี้ใช่หรือไม่ ?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              ไม่
            </Button>
            <Button
              colorScheme='red'
              ml={3}
              onClick={() => {
                handleDelete(item?.id)
              }}
            >
              ใช่
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

function Expenses({ user }) {
  const [Expenses, setExpenses] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isFadeCard, setIsFadeCard] = useState(true)
  const ExpensesCollection = collection(db, 'Expenses')

  useEffect(() => {
    setIsLoading(false)
    setIsFadeCard(false)
    const unsubscribe = onSnapshot(ExpensesCollection, (Snapshot) =>
      setExpenses(Snapshot.docs?.map((doc) => ({ id: doc.id, ...doc.data() })))
    )
    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Stack spacing='0px' p='0px' m='0px'>
      <Box>
        <Card
          pb={{ sm: '60px', md: '60px', lg: '50px' }}
          bg='white'
          p={{ sm: '0px', md: '0px', lg: '10px' }}
          m='0px'
          boxShadow={{
            sm: 'none',
            md: 'none',
            lg: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
          }}
        >
          {!isLoading ? (
            Expenses?.filter((itm) => itm?.branch === user?.branchSelect?.id)
              ?.length > 0 ? (
              <VStack as={Fade} in={!isFadeCard} spacing={'20px'} w='full'>
                {user?.role === 'Cashier'
                  ? Expenses?.filter(
                      (itm) =>
                        itm?.branch === user?.branchSelect?.id &&
                        itm?.createdBy?.id === user?.uid
                    )
                      ?.sort(
                        (a, b) =>
                          (b.updatedAt ? b?.updatedAt : b?.createdAt) -
                          a?.createdAt
                      )
                      ?.map((item, index) => (
                        <ExpensesCard
                          key={index}
                          item={item}
                          user={user}
                          index={index}
                          setIsFadeCard={setIsFadeCard}
                          setIsLoading={setIsLoading}
                        />
                      ))
                  : Expenses?.filter(
                      (itm) => itm?.branch === user?.branchSelect?.id
                    )
                      ?.sort(
                        (a, b) =>
                          (b.updatedAt ? b?.updatedAt : b?.createdAt) -
                          a?.createdAt
                      )
                      ?.map((item, index) => (
                        <ExpensesCard
                          key={index}
                          item={item}
                          user={user}
                          index={index}
                          setIsFadeCard={setIsFadeCard}
                          setIsLoading={setIsLoading}
                        />
                      ))}
              </VStack>
            ) : (
              <Center>
                <Text>ไม่มีรายการ</Text>
              </Center>
            )
          ) : (
            <Center>
              <Spinner size={'lg'} />
            </Center>
          )}
        </Card>
        <Button
          as={Link}
          to='/expenses/add'
          borderRadius='full'
          position='fixed'
          right={5}
          bottom={5}
          w={'50px'}
          h={'50px'}
          colorScheme='btn'
        >
          <Icon as={BsPlus} fontSize={'45px'} />
        </Button>
      </Box>
    </Stack>
  )
}

export default Expenses
