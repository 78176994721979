import { Flex, Box, Text } from '@chakra-ui/react'
import Chart from 'react-apexcharts'

const _ = require('underscore')
const accounting = require('accounting')
function ExpensesGraph({ allData, ...rest }) {
  const branch = _.groupBy(allData, 'branch')

  // ยอดรายจ่าย
  const totalExpenses = Object.keys(branch).map((item) => ({
    branch: item,
    item: _.reduce(branch[item], (v, i) => v + parseFloat(i.grandTotal), 0),
  }))

  const chartOptions = {
    legend: {
      show: false,
    },
    position: 'bottom',
    colors: ['#E64136'],
    chart: {
      id: 'basic-bar',
      animations: {
        speed: 200,
      },
      fontFamily: 'DM Sans',
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    xaxis: {
      categories: Object.keys(branch),
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return accounting.formatNumber(value, 2)
        },
      },
    },
  }

  return (
    <Box w='full'>
      <Flex p='1rem' pb='0rem' w='full' textAlign='start'>
        <Text as='b'>รายจ่าย</Text>
      </Flex>
      <Box w='full' mt='auto' h='30rem' zIndex='base'>
        <Chart
          options={chartOptions}
          series={[
            {
              name: '',
              data: totalExpenses.map((itm) => itm.item),
            },
          ]}
          type='bar'
          width='100%'
          height='100%'
        />
      </Box>
    </Box>
  )
}

export default ExpensesGraph
