import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useAuth, useOrder } from 'Hooks'
import { useEffect, useMemo, useState } from 'react'
import _ from 'underscore'
import SearchDateMonthSelect from 'Components/Search/dateMonthSelect'
import { calCommissionEmployeeDailyReport, currency } from 'Utils'
import moment from 'moment'
import CSVDownloadForm from 'Components/csvDownload'
import { Table } from 'antd'
import 'antd/dist/antd.css'
import { useNavigate } from 'react-router-dom'
import { BsFillPrinterFill } from 'react-icons/bs'
import { functions } from 'Config/firebase'
import { httpsCallable } from 'firebase/functions'
import { URL } from 'Utils/downloadWithURL'

const createPDFEmployeeIncome = httpsCallable(
  functions,
  'createPDFEmployeeIncomeMonthly'
)

function StaffCommissionMonthly(props) {
  const { getAllOrdersWithDate } = useOrder()
  const { getAllUsers, userList } = useAuth()
  const Navigate = useNavigate()
  const [data, setData] = useState([])
  const [currentMonth, setCurrentMonth] = useState(true)
  const [prevMonth, setPrevMonth] = useState(false)
  const [month, setMonth] = useState(new Date())
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState({
    employee: '',
  })
  const [csvData, setCSVData] = useState([])
  const employees = useMemo(() => {
    const filterUserDaily = _.filter(userList, (emp) => {
      return emp?.salaryType === 'monthly'
    })
    return filterUserDaily
  }, [userList])

  const csvHeaders = [
    { label: 'ชื่อพนักงาน', key: 'name' },
    { label: 'ช่วงวันที่', key: 'date' },
    { label: 'ค่าจ้าง', key: 'salary' },
    { label: 'ค่าคอมมิชชั่น', key: 'grandCommission' },
    { label: 'ค่าจ้างรวม', key: 'totalWage' },
  ]

  const columnsAntdData = [
    {
      title: 'ชื่อพนักงาน',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (data, record) => (
        <Box
          cursor={record.href ? 'pointer' : 'default'}
          onClick={() => {
            Navigate(record.href)
          }}
        >
          {data}
        </Box>
      ),
    },
    {
      title: 'ช่วงวันที่',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      render: (data, record) => (
        <Box
          cursor={record.href ? 'pointer' : 'default'}
          onClick={() => {
            Navigate(record.href)
          }}
        >
          {data}
        </Box>
      ),
    },
    {
      title: 'ค่าจ้าง',
      dataIndex: 'salary',
      key: 'salary',
      align: 'center',
      render: (data, record) => (
        <Box
          cursor={record.href ? 'pointer' : 'default'}
          onClick={() => {
            Navigate(record.href)
          }}
        >
          {currency(data ? data : 0, 2)}
        </Box>
      ),
    },
    {
      title: 'ค่าคอมมิชชั่น',
      dataIndex: 'grandCommission',
      key: 'grandCommission',
      align: 'center',
      render: (data, record) => (
        <Box
          cursor={record.href ? 'pointer' : 'default'}
          onClick={() => {
            Navigate(record.href)
          }}
        >
          {currency(data ? data : 0, 2)}
        </Box>
      ),
    },
    {
      title: 'ค่าจ้างรวม',
      dataIndex: 'totalWage',
      key: 'totalWage',
      align: 'center',
      render: (data, record) => (
        <Box
          cursor={record.href ? 'pointer' : 'default'}
          onClick={() => {
            Navigate(record.href)
          }}
        >
          {currency(data ? data : 0, 2)}
        </Box>
      ),
    },
    {
      // title: 'พิมใบแจ้งรายได้',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (data, record) => (
        <Center>
          <PrintPDfIncome record={record} />
        </Center>
      ),
    },
  ]

  const calCommssionData = async (orders, users) => {
    const calCom = calCommissionEmployeeDailyReport(orders, users)
    const filterNotNull = _.filter(calCom, (com) => {
      return com.name
    })
    const indexOrder = _.indexBy(filterNotNull, 'id')
    const addItems = await _.map(users, (i) => {
      if (i.id === indexOrder[i.id]?.id) {
        return {
          ...i,
          ...indexOrder[i.id],
          date: currentMonth
            ? moment().format('MM/YYYY')
            : prevMonth
            ? moment(month).format('MM/YYYY')
            : '-',
          href: `/employees/commission/monthly/${i.id}/${
            prevMonth ? 'prevMonth' : currentMonth ? 'currentMonth' : ''
          }${
            prevMonth
              ? `/?month=${new Date(moment(month).startOf('M')).toISOString()}`
              : ''
          }`,
        }
      } else {
        return {
          ...i,
          date: currentMonth
            ? moment().format('MM/YYYY')
            : prevMonth
            ? moment(month).format('MM/YYYY')
            : '-',
          href: `/employees/commission/monthly/${i.id}/${
            prevMonth ? 'prevMonth' : currentMonth ? 'currentMonth' : ''
          }${
            prevMonth
              ? `/?month=${new Date(moment(month).startOf('M')).toISOString()}`
              : ''
          }`,
        }
      }
    })
    return addItems
  }

  useEffect(() => {
    getAllUsers()
  }, [getAllUsers])

  async function onSubmitFilter() {
    setIsLoading(true)
    setData([])
    const res = await getAllOrdersWithDate({
      month: month,
      prevMonth: prevMonth,
      currentMonth: currentMonth,
    })
    if (filter?.employee) {
      const findEmp = _.find(employees, (i) => {
        return i.id === filter.employee
      })
      const getCommission = await calCommssionData(res, [findEmp])
      const dataToCSV = _.map(getCommission, (items) => {
        return {
          ...items,
          salary: currency(items.salary ? items.salary : 0, 2),
          grandCommission: currency(
            items.grandCommission ? items.grandCommission : 0,
            2
          ),
          totalWage: currency(items.totalWage ? items.totalWage : 0, 2),
        }
      })
      setCSVData(dataToCSV)
      setData(getCommission)
      setIsLoading(false)
    } else {
      const getCommission = await calCommssionData(res, employees)
      const dataToCSV = _.map(getCommission, (items) => {
        return {
          ...items,
          salary: currency(items.salary ? items.salary : 0, 2),
          grandCommission: currency(
            items.grandCommission ? items.grandCommission : 0,
            2
          ),
          totalWage: currency(items.totalWage ? items.totalWage : 0, 2),
        }
      })
      setCSVData(dataToCSV)
      setData(getCommission)
      setIsLoading(false)
    }
  }

  // <--------------หน้าดูรายละเอียดพนักงาน---------------->
  //   /employees/commission/view
  // <--------------หน้าดูรายละเอียดพนักงาน---------------->

  return (
    <>
      <HStack
        my='1em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='0vw'
        flexDirection={{ sm: 'column', md: 'column' }}
      >
        <Stack alignItems={'end'} w='full'>
          <CSVDownloadForm
            data={csvData}
            filename={'monthlyEmployees'}
            headers={csvHeaders}
            buttonTitle={'ดาวน์โหลดไฟล์พนักงานรายเดือน'}
          />
        </Stack>
        <HStack
          alignItems={{ sm: 'flex-end', md: 'start' }}
          flexDirection={{ sm: 'column', md: 'row' }}
          spacing={{ sm: '0vh', md: '1vw' }}
          w='100%'
        >
          <Flex flexDirection='column' w='100%'>
            <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
              <Text color='#8F9BBA'>ช่วงเวลา :</Text>
            </Box>
            <SearchDateMonthSelect
              month={month}
              setMonth={setMonth}
              setPrevMonth={setPrevMonth}
              setCurrentMonth={setCurrentMonth}
            />
          </Flex>
          {/* <Flex pt={{ sm: '1rem', md: '0rem' }} flexDirection='column' w='100%'>
            <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
              <Text color='#8F9BBA'>สาขา :</Text>
            </Box>
            <Select
              color={'gray.700'}
              w='full'
              bg={'#F4F7FE'}
              border='0px solid #E8E9E9'
              borderRadius='16px'
              defaultValue=''
              fontWeight='500'
              variant='main'
              placeholder={'ทั้งหมด'}
              _placeholder={{
                fontWeight: '400',
              }}
              h='44px'
              maxh='44px'
              onChange={(event) =>
                setFilter({ ...filter, branch: event.target.value })
              }
            >
              {BranchNoHQ?.map((branch) => {
                return <option value={branch?.id}>{branch?.name}</option>
              })}
            </Select>
          </Flex> */}
          <Flex pt={{ sm: '1rem', md: '0rem' }} flexDirection='column' w='100%'>
            <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
              <Text color='#8F9BBA'>ชื่อพนักงาน :</Text>
            </Box>
            <Select
              color={'gray.700'}
              w='full'
              bg={'#F4F7FE'}
              border='0px solid #E8E9E9'
              borderRadius='16px'
              defaultValue=''
              fontWeight='500'
              variant='main'
              placeholder={'ทั้งหมด'}
              _placeholder={{
                fontWeight: '400',
              }}
              h='44px'
              maxh='44px'
              onChange={(event) =>
                setFilter({ ...filter, employee: event.target.value })
              }
            >
              {employees?.map((emp) => {
                return (
                  <option value={emp?.id} key={emp}>
                    {emp?.name}
                  </option>
                )
              })}
            </Select>
          </Flex>
          <Flex
            flexDirection='column'
            w='max-content'
            alignItems='end'
            pt={{ sm: '1rem', md: '0rem' }}
          >
            <Box
              textAlign='start'
              display={{ sm: 'none', md: 'flex' }}
              p='0.5rem'
              pl='1rem'
              pt='0rem'
            >
              <Text color='#8F9BBA'>&nbsp;</Text>
            </Box>
            <HStack>
              <Button
                h='44px'
                maxh='44px'
                w={{ sm: '10rem', md: '10rem' }}
                colorScheme='btn'
                borderRadius='16px'
                display='flex'
                justifyContent={'space-around'}
                color='white'
                onClick={() => onSubmitFilter()}
                disabled={isLoading}
                isLoading={isLoading}
              >
                <Text>
                  <SearchIcon />
                </Text>
                <Text>แสดงรายงาน</Text>
              </Button>
            </HStack>
          </Flex>
        </HStack>
        {/* <CustomTable
          columnsData={columnsData}
          tableData={data.length > 0 ? data : []}
          closeSearch={true}
        /> */}
      </HStack>
      <Box w='full'>
        <Table
          loading={isLoading}
          columns={columnsAntdData}
          dataSource={data}
          size={'large'}
        />
      </Box>
    </>
  )
}

export default StaffCommissionMonthly

const PrintPDfIncome = ({ record }) => {
  const [pendingLoading, setPendingLoading] = useState(false)

  const handlePrintIncome = async () => {
    setPendingLoading(true)
    createPDFEmployeeIncome(record)
      .then((e) => {
        URL(e.data.pdfRef).then(() => {
          setPendingLoading(false)
        })
        setPendingLoading(false)
      })
      .catch((err) => {
        console.log('error: ', err)
      })
  }

  return (
    <IconButton
      isLoading={pendingLoading}
      disabled={pendingLoading}
      icon={<BsFillPrinterFill />}
      onClick={() => {
        handlePrintIncome()
      }}
    />
  )
}
