import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  HStack,
  Select,
  useColorModeValue,
} from '@chakra-ui/react'

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

function generateArrayOfYears() {
  let year = new Date().getFullYear()
  let max = year - 10
  let min = max - 70
  let years = []

  for (var i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}

const InputDoBField = ({
  id,
  errors,
  control,
  name,
  required,
  defaultValue,
  label,
  extra,
}) => {
  const [memoYear, setMemoYear] = useState(null)
  const [memoMonth, setMemoMonth] = useState(null)
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  function daysInMonth() {
    let numberOfMonth = months.indexOf(memoMonth) + 1
    let maxDay = new Date(memoYear, numberOfMonth, 0).getDate()
    let days = []
    for (let i = 1; i <= maxDay; i++) {
      days.push(i)
    }
    return days
  }
  return (
    <>
      <FormControl
        my={3}
        id={name}
        isInvalid={
          errors[`${name}Year`] ||
          errors[`${name}Month`] ||
          errors[`${name}Day`]
        }
        key={name}
        w={'100%'}
      >
        <FormLabel
          display='flex'
          ms='10px'
          htmlFor={id}
          fontSize='sm'
          color={textColorPrimary}
          fontWeight='bold'
          _hover={{ cursor: 'pointer' }}
        >
          {label}
          <Text fontSize='sm' fontWeight='400' ms='2px'>
            {extra}
          </Text>
        </FormLabel>
        <HStack spacing={2}>
          <Controller
            name={`${name}Day`}
            control={control}
            rules={{
              required,
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                onChange={onChange}
                value={value}
                placeholder={`วัน`}
                borderRadius={{base:'10px',sm:'10px',md:'10px',lg:'16px'}}
                _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                variant='main'
                h='44px'
                maxh='44px'
              >
                {daysInMonth().map((day, index) => (
                  <option key={`select-${day}-${index}`} value={day}>
                    {day}
                  </option>
                ))}
              </Select>
            )}
          />
          <Controller
            name={`${name}Month`}
            control={control}
            rules={{
              required,
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  onChange={(e) => {
                    setMemoMonth(e.currentTarget.value)
                    onChange(e)
                  }}
                  borderRadius={{base:'10px',sm:'10px',md:'10px',lg:'16px'}}
                  value={value}
                  placeholder={`เดือน`}
                  _placeholder={{
                    fontWeight: '400',
                    color: 'secondaryGray.600',
                  }}
                  variant='main'
                  h='44px'
                  maxh='44px'
                >
                  {months.map((month, index) => (
                    <option key={`select-${month}-${index}`} value={month}>
                      {month}
                    </option>
                  ))}
                </Select>
              )
            }}
          />
          <Controller
            name={`${name}Year`}
            control={control}
            rules={{
              required,
            }}
            render={({ field: { onChange, value } }) => (
              <Select
              borderRadius={{base:'10px',sm:'10px',md:'10px',lg:'16px'}}
                onChange={(e) => {
                  setMemoYear(e.currentTarget.value)
                  onChange(e)
                }}
                value={value}
                placeholder={`ปี`}
                _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                variant='main'
                h='44px'
                maxh='44px'
              >
                {generateArrayOfYears().map((year, index) => (
                  <option key={`select-${year}-${index}`} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            )}
          />
        </HStack>
        <FormErrorMessage>
          {(errors[`${name}Year`] ||
            errors[`${name}Month`] ||
            errors[`${name}Day`]) &&
            `กรุณากรอก${label} `}
        </FormErrorMessage>
      </FormControl>
    </>
  )
}

export { InputDoBField }
