import React, { useCallback, useEffect, useRef } from 'react'
import {
  Box,
  Text,
  Flex,
  HStack,
  VStack,
  Image,
  Center,
  Spinner,
  GridItem,
  Grid,
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Badge,
  Switch,
} from '@chakra-ui/react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ArrowBackIcon, ChevronLeftIcon } from '@chakra-ui/icons'
import { useOrder } from 'Hooks'
import { useState } from 'react'
import { currency, decodeUnit } from 'Utils/currency'
import { billStatus } from 'Utils/status'
import { vatIncluding } from 'Utils/vat'
import moment from 'moment'
import { collection, doc, onSnapshot } from 'firebase/firestore'
import { db } from 'Config/firebase'
import { posType } from 'Utils/json'
const _ = require('underscore')

function AlertRefund({ item, id, orderAmount, itemAmount, getOrderByRefId }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isSubmit, setIsSubmit] = useState(false)
  const { refundOrder } = useOrder()
  const cancelRef = useRef()
  const navigate = useNavigate()

  function handleRefund() {
    setIsSubmit(true)
    const value = {
      ...item,
      ref: id,
      status: 'refund',
      createdAt: new Date(),
    }

    refundOrder(value).then(() => {
      setIsSubmit(false)
      getOrderByRefId(id)
      onClose()
      navigate('/pos/history')
    })
  }
  return (
    <>
      <Button
        colorScheme='btn'
        borderRadius='5px'
        fontWeight='normal'
        disabled={
          orderAmount.toString() !== itemAmount.toString() ? false : true
        }
        onClick={() => {
          if (orderAmount.toString() !== itemAmount.toString()) {
            onOpen()
          }
        }}
      >
        {orderAmount.toString() !== itemAmount.toString()
          ? 'คืนเงิน'
          : 'คืนเงินครบจำนวนแล้ว'}
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              fontSize='lg'
              fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
            >
              คืนเงิน
            </AlertDialogHeader>

            <AlertDialogBody>
              เนื่องจากรายการมีโปรโมชั่นส่วนลด หากต้องการคืนเงินต้องคืนทั้งบิล
              คุณยืนยันจะทำการคืนเงินใช่หรือไม่{' '}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                ยกเลิก
              </Button>
              <Button
                colorScheme='red'
                onClick={() => handleRefund()}
                ml={3}
                isLoading={isSubmit}
                disabled={isSubmit}
                loadingText={'กำลังทำรายการคืนเงิน...'}
              >
                คืนเงิน
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

function DataHistoty({ user }) {
  const Navigate = useNavigate()
  const { id } = useParams()
  const { statusSuccessOrder } = useOrder()
  const [search] = useSearchParams()
  const { getOrderByRefId } = useOrder()
  const [orderData, setOrderData] = useState(null)
  const [orderRef, setOrderRef] = useState(null)
  const { statusMessage } = billStatus()
  const [orders, setOrders] = useState([])

  const fetchOrderRef = useCallback(async () => {
    const getOrders = await getOrderByRefId(id)
    setOrders(getOrders)
    //eslint-disable-next-line
  }, [id])

  useEffect(() => {
    fetchOrderRef()
  }, [fetchOrderRef])

  const handleSuccess = (item) => {
    return statusSuccessOrder({ ...item, success: !item.success, id: id })
  }

  // const fetchData = useCallback(async () => {
  //   getOrderById(id).then(async (data) => {
  //     if (data) {
  //       if (data?.branch?.id === user?.branchSelect?.id) {
  //         const filterDataInItem = await _.filter(data?.items, (i) => {
  //           return i?.discount
  //         })
  //         if (filterDataInItem.length > 0) {
  //           const getDiscountInItems = await _.map(filterDataInItem, (i) => {
  //             return i?.discount
  //           })
  //           const flatternData = await _.flatten(getDiscountInItems)
  //           const discountItem = [
  //             ...flatternData,
  //             data?.discount?.length > 0 ? data?.discount : null,
  //           ]
  //           const dataShow = {
  //             ...data,
  //             discount: discountItem,
  //           }
  //           return setOrderData(dataShow)
  //         }
  //         return setOrderData(data)
  //       } else {
  //         return Navigate('/pos/history')
  //       }
  //     }
  //   })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getOrderById, id, user])

  useEffect(() => {
    const orderRef = doc(collection(db, 'Orders'), id)
    const unsubscribe = onSnapshot(orderRef, async (onSnap) => {
      const data = onSnap.data()
      if (data) {
        if (data?.branch?.id === user?.branchSelect?.id) {
          const filterDataInItem = await _.filter(data?.items, (i) => {
            return i?.discount
          })
          if (filterDataInItem.length > 0) {
            const getDiscountInItems = await _.map(filterDataInItem, (i) => {
              return i?.discount
            })
            const flatternData = await _.flatten(getDiscountInItems)
            const discountItem = [
              ...flatternData,
              data?.discount?.length > 0 ? data?.discount : null,
            ]
            const dataShow = {
              ...data,
              discount: discountItem,
            }
            return setOrderData(dataShow)
          }
          return setOrderData(data)
        } else {
          return Navigate('/pos/history')
        }
      }
    })
    return () => {
      if (id) {
        unsubscribe()
      }
    }
    //eslint-disable-next-line
  }, [id])

  const orderItem = orderData?.items
  const orderAmount = _.reduce(orderItem, (v, i) => v + parseFloat(i.amount), 0)
  const items = orderRef ? _.flatten(_.pluck(orderRef, 'items')) : []
  const itemsAmount = _.reduce(
    items,
    (value, num) => value + parseFloat(num.amount),
    0
  )

  useEffect(() => {
    // fetchData()
    setOrderRef(_.filter(orders, (itm) => itm.ref === id))
    //eslint-disable-next-line
  }, [orders])
  return (
    <>
      {orderData ? (
        <Center
          bg='white'
          w='full'
          flexDirection='column'
          p={{ sm: '0px', md: '15px' }}
          mt={{ base: '-10px', sm: '-10px', md: '0px' }}
        >
          <Box
            textAlign='start'
            w={{ sm: '100%', md: '39rem' }}
            py='1rem'
            color='black'
            onClick={() => {
              if (search.get('path') === 'historyAll') {
                Navigate(`/pos/history/all/`)
              } else {
                Navigate('/pos/history')
              }
            }}
          >
            <Text
              display={{ sm: 'none', md: 'flex' }}
              fontSize='18px'
              cursor='pointer'
            >
              <ArrowBackIcon />
            </Text>
            <Text
              display={{ sm: 'flex', md: 'none' }}
              fontSize='24px'
              cursor='pointer'
            >
              <ChevronLeftIcon />
            </Text>
          </Box>
          <Flex justifyContent='space-between' w={{ sm: '100%', md: '39rem' }}>
            <HStack>
              <Text
                fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                color='black'
              >
                การสั่งซื้อ
              </Text>
              <Text pt='0.2rem' color='#818182'>
                #{orderData?.orderId}&nbsp;&nbsp;&nbsp;วันที่:&nbsp;
                {moment(orderData?.createdAt?.toDate()).format('MM/DD/YYYY')}
              </Text>
              {orderData?.status === 'refund' && (
                <Text color='red.300' fontSize='xs'>
                  ({statusMessage(orderData?.status)})
                </Text>
              )}
            </HStack>
            {orderData?.status !== 'refund' ? (
              orderData?.discount ? (
                <AlertRefund
                  item={orderData}
                  id={id}
                  orderAmount={orderAmount}
                  itemAmount={itemsAmount}
                  getOrderByRefId={getOrderByRefId}
                />
              ) : orderAmount.toString() !== itemsAmount.toString() ? (
                <Button
                  colorScheme='btn'
                  borderRadius='5px'
                  // to={`/pos/history/refund/${id}`}
                  // as={Link}
                  onClick={() => {
                    Navigate(`/pos/history/refund/${id}`)
                  }}
                  fontWeight='normal'
                  isDisabled={
                    moment(orderData?.createdAt?.toDate()).diff(
                      new Date(),
                      'd'
                    ) <= -14
                      ? true
                      : false
                  }
                >
                  คืนเงิน
                </Button>
              ) : (
                <Button
                  colorScheme='btn'
                  borderRadius='5px'
                  disabled
                  fontWeight='normal'
                >
                  คืนเงินครบจำนวนแล้ว
                </Button>
              )
            ) : null}
          </Flex>
          <Box
            p='1rem'
            px={{ sm: '0rem', md: '1.5rem' }}
            py={{ sm: '1rem', md: '2rem' }}
          >
            <VStack
              alignItems='flex-start'
              spacing={{ sm: '0.5rem', md: '1rem' }}
            >
              {orderData?.items?.map((item, i) => {
                return (
                  <Flex flexDirection='column' key={i}>
                    <Box>
                      <Grid
                        templateColumns={{
                          sm: 'repeat(3, 1fr)',
                          md: 'repeat(4, 1fr)',
                        }}
                        w={{ sm: '100%', md: '39rem' }}
                        gap={0}
                        py={{ sm: '0.5rem', md: '0.5rem' }}
                      >
                        <GridItem
                          w='7rem'
                          display={{ sm: 'none', md: 'GridItem' }}
                        >
                          <Center>
                            <Image
                              w={{ sm: '4rem', md: '5rem' }}
                              h={{ sm: '4rem', md: '5rem' }}
                              objectFit='cover'
                              borderRadius='15px'
                              display={{ sm: 'none', md: 'flex' }}
                              src={item?.img || '/product_Thumbnail.png'}
                              alt={item?.name}
                            />
                          </Center>
                        </GridItem>
                        <GridItem
                          w={{ sm: '10rem', md: '15rem' }}
                          fontWeight={{
                            base: 'normal',
                            sm: 'normal',
                            md: 'bold',
                          }}
                        >
                          <Flex
                            h='full'
                            justifyContent='center'
                            flexDirection='column'
                            w='100%'
                          >
                            <VStack alignItems={'start'}>
                              <HStack>
                                <Text>
                                  {item?.name}{' '}
                                  {item?.nameOption ? item.nameOption : ''}
                                </Text>
                                {item?.free && (
                                  <Text>
                                    <Badge
                                      color={'#FF6501'}
                                      ml='1'
                                      colorScheme={'#FF6501'}
                                    >
                                      แถมฟรี
                                    </Badge>
                                  </Text>
                                )}
                              </HStack>
                              <Text color='green'>
                                {currency(decodeUnit(item?.price), 2, true)}
                              </Text>
                            </VStack>
                          </Flex>
                        </GridItem>

                        <GridItem
                          w={{ sm: 'auto', md: '5rem' }}
                          fontWeight={{
                            base: 'normal',
                            sm: 'normal',
                            md: 'bold',
                          }}
                        >
                          <Center h='full' color='black' w='100%'>
                            <VStack>
                              <Text>x{decodeUnit(item?.amount)}</Text>
                            </VStack>
                          </Center>
                        </GridItem>

                        <GridItem
                          w={{ sm: 'auto', md: '10rem' }}
                          fontWeight={{
                            base: 'normal',
                            sm: 'normal',
                            md: 'bold',
                          }}
                        >
                          <Flex
                            alignItems='center'
                            justifyContent='end'
                            h='full'
                            textAlign='right'
                            color='black'
                            w='100%'
                          >
                            <VStack alignItems={'end'}>
                              <Text>
                                {currency(decodeUnit(item?.total), 2, true)}
                              </Text>
                            </VStack>
                          </Flex>
                        </GridItem>
                      </Grid>
                    </Box>
                  </Flex>
                )
              })}
            </VStack>
          </Box>
          <Box
            ml={{ sm: '0rem', md: '1rem' }}
            borderBottom='1px solid #E0E5F2'
            w={{ sm: '100%', md: '38rem' }}
          ></Box>
          <Box
            p={{ sm: '0rem', md: '1.5rem' }}
            pt={{ sm: '1rem', md: '1.5rem' }}
            px='0rem'
            w={{ sm: '100%', md: '40rem' }}
          >
            <VStack
              alignItems='flex-start'
              spacing={{ sm: '1rem', md: '1.5rem' }}
              w={{ sm: '100%', md: '38rem' }}
            >
              <Flex
                fontSize={{ sm: '16px', md: '18px' }}
                w='full'
                flexDirection='row'
                // fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                fontWeight={'normal'}
                justifyContent='space-between'
              >
                <Text
                  textAlign='left'
                  color='black'
                  w={{ sm: '100%', md: '15rem' }}
                >
                  ยอดรวม
                </Text>
                <Text
                  textAlign='right'
                  color='black'
                  w={{ sm: '100%', md: '20rem' }}
                >
                  {currency(decodeUnit(orderData?.total), 2, true)}
                </Text>
              </Flex>
              {orderData?.discount && (
                <>
                  <Box w={'full'}>
                    <Text
                      fontSize={{ sm: '16px', md: '18px' }}
                      // fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                      fontWeight={'normal'}
                    >
                      โปรโมชั่นส่วนลด
                    </Text>
                    <VStack pt='0.5rem'>
                      {_.map(orderData?.discount, (item, index) => {
                        return (
                          <HStack
                            key={index}
                            justifyContent={'space-between'}
                            w='full'
                            color={'#FF6501'}
                          >
                            <Text>{item?.discountName}</Text>
                          </HStack>
                        )
                      })}
                    </VStack>
                  </Box>
                  <Flex
                    fontSize={{ sm: '16px', md: '16px' }}
                    w='full'
                    flexDirection='row'
                    // fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                    fontWeight={'normal'}
                    justifyContent='space-between'
                  >
                    <HStack justifyContent={'none'}>
                      <Text textAlign='left' color='black'>
                        รวมส่วนลด
                      </Text>
                    </HStack>
                    <Text
                      textAlign='right'
                      w={{ sm: '100%', md: '20rem' }}
                      color='#FF6501'
                    >
                      {orderData?.discount
                        ? `-${currency(
                            decodeUnit(orderData?.totalDiscount),
                            2,
                            true
                          )}`
                        : '-'}
                    </Text>
                  </Flex>
                </>
              )}

              <Flex
                fontSize={{ sm: '16px', md: '18px' }}
                w='full'
                flexDirection='row'
                // fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                fontWeight={'normal'}
                justifyContent='space-between'
              >
                <Text
                  textAlign='left'
                  color='black'
                  w={{ sm: '100%', md: '15rem' }}
                >
                  ยอดไม่รวมภาษีมูลค่าเพิ่ม
                </Text>
                <Text
                  textAlign='right'
                  color='black'
                  w={{ sm: '100%', md: '20rem' }}
                >
                  {/* {currency(decodeUnit(orderData?.subTotal), 2, true)} */}
                  {orderData?.subTotal
                    ? currency(decodeUnit(orderData?.subTotal), 2, true)
                    : currency(
                        vatIncluding(decodeUnit(orderData?.grandTotal)).total,
                        2,
                        true
                      )}
                </Text>
              </Flex>

              <Flex
                fontSize={{ sm: '16px', md: '18px' }}
                w='full'
                flexDirection='row'
                // fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                fontWeight={'normal'}
                justifyContent='space-between'
              >
                <Text
                  textAlign='left'
                  color='black'
                  w={{ sm: '100%', md: '15rem' }}
                >
                  ภาษีมูลค่าเพิ่ม (7%)
                </Text>
                <Text
                  textAlign='right'
                  color='black'
                  w={{ sm: '100%', md: '20rem' }}
                >
                  {orderData?.vat
                    ? currency(decodeUnit(orderData?.vat), 2, true)
                    : currency(
                        vatIncluding(decodeUnit(orderData?.grandTotal)).vat,
                        2,
                        true
                      )}
                </Text>
              </Flex>

              <Flex
                fontSize={{ sm: '16px', md: '18px' }}
                w='full'
                flexDirection='row'
                fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                justifyContent='space-between'
              >
                <Text
                  textAlign='left'
                  color='black'
                  w={{ sm: '100%', md: '15rem' }}
                >
                  ยอดชำระ
                </Text>
                <Text
                  textAlign='right'
                  color='black'
                  w={{ sm: '100%', md: '20rem' }}
                >
                  {currency(decodeUnit(orderData?.grandTotal), 2, true)}
                </Text>
              </Flex>

              <Flex
                fontSize={{ sm: '16px', md: '18px' }}
                w='full'
                flexDirection='row'
                // fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                fontWeight={'normal'}
                justifyContent='space-between'
              >
                <Text
                  textAlign='left'
                  color='black'
                  w={{ sm: '100%', md: '15rem' }}
                >
                  สถานะการชำระเงิน
                </Text>
                <Text
                  textAlign='right'
                  color={orderData?.status === 'payLater' ? 'red' : 'black'}
                  w={{ sm: '100%', md: '20rem' }}
                >
                  {statusMessage(orderData?.status)}
                </Text>
              </Flex>
              <Flex
                fontSize={{ sm: '16px', md: '18px' }}
                flexDirection='row'
                textAlign='right'
                w='full'
                justifyContent='space-between'
              >
                <Text
                  textAlign='left'
                  // fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                  fontWeight={'normal'}
                  color='black'
                  w={{ sm: '100%', md: '15rem' }}
                >
                  ช่องทางการชำระ
                </Text>
                <Flex
                  fontSize={{ sm: '16px', md: '18px' }}
                  w='20rem'
                  flexDirection='column'
                  justifyContent='space-between'
                  textAlign='right'
                >
                  {orderData?.payment === 'cash' ? (
                    <Text>เงินสด</Text>
                  ) : orderData?.payment === 'bank' ? (
                    <>
                      <Text>{orderData?.bank?.name}</Text>
                      <Text mt='-0.2rem'>
                        {orderData?.bank?.accountingNumber}
                      </Text>
                      <Text mt='-0.2rem'>{orderData?.bank?.accountName}</Text>
                    </>
                  ) : (
                    '-'
                  )}
                </Flex>
              </Flex>
              <Flex
                justifyContent='space-between'
                fontSize={{ sm: '16px', md: '18px' }}
                w='full'
                flexDirection='row'
              >
                <Text
                  textAlign='left'
                  // fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                  fontWeight={'normal'}
                  color='black'
                  w={{ sm: '100%', md: '15rem' }}
                >
                  พนักงานขาย
                </Text>
                <Text textAlign='right' w={{ sm: '100%', md: '20rem' }}>
                  {orderData?.createdBy?.name}
                </Text>
              </Flex>
              <Flex
                justifyContent='space-between'
                fontSize={{ sm: '16px', md: '18px' }}
                w='full'
                flexDirection='row'
              >
                <Box>
                  <Text
                    textAlign='left'
                    // fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                    color='black'
                    w={{ sm: '100%', md: '15rem' }}
                  >
                    สถานะ
                    <Badge
                      colorScheme={orderData?.success ? 'green' : 'yellow'}
                    >
                      {orderData?.success ? 'ดำเนินการแล้ว' : 'รอดำเนินการ'}
                    </Badge>
                  </Text>
                </Box>
                <Switch
                  size='lg'
                  mt='-0.65rem'
                  colorScheme='btn'
                  isChecked={orderData?.success}
                  onChange={() => handleSuccess(orderData)}
                  isDisabled={orderData?.status === 'refund'}
                />
                {/* <Text textAlign='right' w={{ sm: '100%', md: '20rem' }}>
                  {orderData?.note ? orderData?.note : ''}
                </Text> */}
              </Flex>

              <Flex
                justifyContent='space-between'
                fontSize={{ sm: '16px', md: '18px' }}
                w='full'
                flexDirection='row'
              >
                <Text
                  textAlign='left'
                  // fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                  color='black'
                  w={{ sm: '100%', md: '15rem' }}
                >
                  Note
                </Text>
                <Text textAlign='right' w={{ sm: '100%', md: '20rem' }}>
                  {orderData?.note ? orderData?.note : ''}
                </Text>
              </Flex>

              <Flex
                justifyContent='space-between'
                fontSize={{ sm: '16px', md: '18px' }}
                w='full'
                flexDirection='row'
              >
                <Text
                  textAlign='left'
                  // fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                  color='black'
                  w={{ sm: '100%', md: '15rem' }}
                >
                  ประเภทผู้ซื้อ
                </Text>
                <Text textAlign='right' w={{ sm: '100%', md: '20rem' }}>
                  {orderData?.customerType
                    ? posType?.default?.find(
                        (x) => x.id === orderData?.customerType
                      )?.name
                    : ''}
                </Text>
              </Flex>
            </VStack>
            {orderData?.img && (
              <Center
                flexDirection='column'
                textAlign='left'
                w={{ sm: '100%', md: '37rem' }}
                mt={{ sm: '1.5rem', md: '1.5rem' }}
              >
                <Text
                  fontWeight={{ base: 'normal', sm: 'normal', md: 'bold' }}
                  w='full'
                  fontSize={{ sm: '16px', md: '18px' }}
                  color='black'
                >
                  สลิป
                </Text>
                <Image src={orderData?.img} w={'100%'} h={'auto'} />
              </Center>
            )}
          </Box>
        </Center>
      ) : (
        <>
          <Center>
            <Spinner />
          </Center>
        </>
      )}
    </>
  )
}

export default DataHistoty
