import { storage } from 'Config/firebase'
import { getDownloadURL, ref } from 'firebase/storage'

const getDownloadURLToken = async (pdfRef) => {
  try {
    return await getDownloadURL(ref(storage, pdfRef))
  } catch (err) {
    console.log(`${err}`)
  }
}

export async function URL(pdfRef) {
  const downloadUrl = await getDownloadURLToken(pdfRef)
  const newWindow = window.open(
    downloadUrl?.replace('%252', '%2'),
    '_blank',
    'noopener,noreferrer'
  )
  if (newWindow) newWindow.opener = null
}
