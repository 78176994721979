import { Badge } from '@chakra-ui/react'
import { billStatus } from 'Utils'

const { statusMessage } = billStatus()
export function StatusOrderBadge({ message, variant, ...rest }) {
  return (
    <Badge
      variant={variant ? variant : 'outline'}
      color={switchColorOrderBadgeText(message)}
      bg={switchColorOrderBadge(message)}
      {...rest}
    >
      {statusMessage(message)}
    </Badge>
  )
}

function switchColorOrderBadgeText(message) {
  switch (message) {
    case 'paid':
      return '#60101C'
    case 'refund':
      return '#F35667'
    default:
      return '#60101C'
  }
}

function switchColorOrderBadge(message) {
  switch (message) {
    case 'paid':
      return '#EBF5E1'
    case 'refund':
      return '#FEEDEE'
    default:
      return 'yellow'
  }
}
