import { useEffect, useRef, useState } from 'react'
import {
  HStack,
  Flex,
  Text,
  Box,
  Button,
  Heading,
  Stack,
  Center,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  IconButton,
  Image,
  VStack,
  Divider,
  Select,
} from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import SearchDate from 'Components/Search/date'
import { BsImage } from 'react-icons/bs'
import { useWork } from 'Hooks/work'
import { useBranch } from 'Hooks'
import CustomTable from 'Components/Horizons/table/customTable'
import _ from 'underscore'
import CSVDownloadForm from 'Components/csvDownload'

function ExpenseSaffWork({ user }) {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [data, setData] = useState([])
  const { getUserAllWorkData } = useWork()
  const { BranchNoHQ, getBranchData } = useBranch()
  const [fetchDataLoading, setFetchDataLoading] = useState(false)
  const [startDateTime, setStartDateTime] = useState(null)
  const [selectBranch, setSelectBranch] = useState('')
  const [endDateTime, setEndDateTime] = useState(null)
  const [dateTime, setDateTime] = useState(false)
  const [currentDate, setCurrentDate] = useState(true)
  const [currentMonth, setCurrentMonth] = useState(false)
  const [between, setBetween] = useState(false)
  const [prevMonth, setPrevMonth] = useState(false)
  const [month, setMonth] = useState(new Date())
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const [viewData, setViewData] = useState()
  const [csvData, setCsvData] = useState([])

  useEffect(() => {
    getBranchData()
    //eslint-disable-next-line
  }, [])

  const csvHeaders = [
    { label: 'Email', key: 'email' },
    { label: 'Employee Name', key: 'name' },
    { label: 'Role', key: 'role' },
    { label: 'Branch', key: 'branch' },
    { label: 'Time to attend', key: 'timeIn' },
    { label: 'Time off', key: 'timeOut' },
    { label: 'Work Time', key: 'diff' },
    { label: 'Detail', key: 'detail' },
  ]

  function handleOpenAlert(rowdata) {
    onOpen()
    setViewData(rowdata)
  }

  const onSubmitFilter = async () => {
    setFetchDataLoading(true)
    const result = await getUserAllWorkData({
      selectBranch,
      startDate,
      endDate,
      month,
      between,
      prevMonth,
      currentDate,
      currentMonth,
      startDateTime,
      endDateTime,
      dateTime,
    })
    if (startDateTime && endDateTime) {
      setStartDate(startDateTime)
      setEndDate(endDateTime)
    } else {
      setStartDateTime(null)
      setEndDateTime(null)
    }
    const dataToCsv = _.map(result, (i) => {
      return {
        email: i?.user?.email,
        name: i?.user?.name,
        role: i?.role,
        branch: i?.branch?.name,
        timeIn: i?.inTime
          ? moment(i?.inTime?.toDate()).format('MM/DD/YYYY HH:mm:ss')
          : '-',
        timeOut: i?.outTime
          ? moment(i?.outTime?.toDate()).format('MM/DD/YYYY HH:mm:ss')
          : '-',
        diff: `${i?.diff}.${i?.diffMinute}`,
        detail: i?.detailLogout,
      }
    })
    setCsvData(dataToCsv)
    setData(result.length > 0 ? result : [])
    setFetchDataLoading(false)
    // setOnSearch(true)
  }

  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>รายงานเข้า - ออก พนักงาน</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <VStack w='full' alignItems={'start'}>
              <Box>
                <VStack alignItems={'start'}>
                  <Text>พนักงาน:&nbsp;{viewData?.user?.name}</Text>
                  <Text>สาขา:&nbsp;{viewData?.branch?.name}</Text>
                  <Text>
                    เวลาเข้า:{' '}
                    {moment(viewData?.inTime?.toDate()).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )}
                  </Text>
                  <Text>
                    เวลาออก:{' '}
                    {viewData?.outTime
                      ? moment(viewData?.outTime?.toDate()).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                      : '-'}
                  </Text>
                  <Text>
                    ชั่วโมงการทำงาน:&nbsp;{viewData?.diff}.
                    {viewData?.diffMinute}
                  </Text>
                  <Text>
                    หมายเหตุ:&nbsp;
                    {viewData?.detailLogout ? viewData?.detailLogout : '-'}
                  </Text>
                </VStack>
              </Box>
              {/* <HStack> */}
              <VStack justifyItems={'center'} w='full'>
                <Text>รูปเข้างาน</Text>
                <Image src={viewData?.img} />
              </VStack>
              <Divider />
              <VStack justifyItems={'center'} w='full'>
                <Text>รูปออกงาน</Text>
                <Image src={viewData?.imgLogout} />
              </VStack>
              {/* </HStack> */}
            </VStack>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              ปิด
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <HStack
        my='1em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='1vw'
        flexDirection={{ sm: 'column', md: 'row' }}
        justifyContent='space-between'
      >
        <Flex flexDirection='column' w={{ sm: 'full', md: '30vw', lg: '30vw' }}>
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ช่วงเวลา :</Text>
          </Box>
          <SearchDate
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            month={month}
            setMonth={setMonth}
            setBetween={setBetween}
            setPrevMonth={setPrevMonth}
            setCurrentDate={setCurrentDate}
            setCurrentMonth={setCurrentMonth}
            setStartDateTime={setStartDateTime}
            setEndDateTime={setEndDateTime}
            setDateTime={setDateTime}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
          />
        </Flex>
        <Flex
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        >
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>สาขา :</Text>
          </Box>
          <Select
            color={'gray.700'}
            w='full'
            bg={'#F4F7FE'}
            border='0px solid #E8E9E9'
            borderRadius='16px'
            defaultValue=''
            fontWeight='500'
            variant='main'
            placeholder={'ทั้งหมด'}
            _placeholder={{
              fontWeight: '400',
            }}
            h='44px'
            maxh='44px'
            onChange={(event) => setSelectBranch(event.target.value)}
          >
            {BranchNoHQ?.map((item, index) => (
              <option value={item.id} key={index}>
                {item.name}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex
          flexDirection='column'
          w={{ sm: 'full', md: '10rem', lg: '10rem', xl: '10rem' }}
          alignItems='end'
          pt={{ sm: '1rem', md: '0rem' }}
        >
          <Box
            textAlign='start'
            display={{ sm: 'none', md: 'flex' }}
            p='0.5rem'
            pl='1rem'
            pt='0rem'
          >
            <Text color='#8F9BBA'>&nbsp;</Text>
          </Box>

          <Button
            h='44px'
            maxh='44px'
            w={{ sm: '10rem', md: 'full' }}
            colorScheme='btn'
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            onClick={() => onSubmitFilter()}
            isLoading={fetchDataLoading}
            isDisabled={fetchDataLoading}
          >
            <Text>
              <SearchIcon />
            </Text>
            <Text>แสดงรายงาน</Text>
          </Button>
        </Flex>
      </HStack>
      <Flex
        flexDirection='column'
        p={{ sm: '10px', md: '20px 20px 30px 20px' }}
        bg='white'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
        borderRadius='30px'
      >
        <Stack spacing={{ base: '0em', md: '4em' }}>
          <HStack
            justifyContent={'space-between'}
            w='full'
            display={{ sm: 'block', md: 'flex' }}
          >
            <Stack>
              <Heading
                fontFamily={'Prompt'}
                color='#2B3674'
                fontSize={{ base: '24px', md: '30px' }}
              >
                รายงาน login logout พนักงาน
              </Heading>
              <Box>
                <HStack>
                  <Text color='#2B3674'>ณ วันที่:</Text>
                  <Text color='#2B3674'>
                    {moment(new Date().getTime()).format('MM/DD/YYYY')}
                  </Text>
                </HStack>
                <HStack>
                  <Text color='#2B3674'>ช่วงเวลา:</Text>
                  {startDate && endDate && (
                    <Text color='#2B3674'>
                      {moment(startDate).format('MM/DD/YYYY')} -{' '}
                      {moment(endDate).format('MM/DD/YYYY')}
                    </Text>
                  )}
                </HStack>
              </Box>
            </Stack>
            <HStack justifyContent={{ sm: 'start', md: 'end' }}>
              <CSVDownloadForm
                mt={{ sm: '10px', md: '0px' }}
                buttonTitle={'ดาวน์โหลดไฟล์รายงานการเข้างาน-ออก พนักงาน'}
                data={csvData}
                filename={`${user?.branchSelect?.name}_LoginLogoutreport`}
                headers={csvHeaders}
              />
            </HStack>
          </HStack>
        </Stack>
        <Box mt='4rem'>
          <CustomTable
            disabledSearch={true}
            columnsData={columnsData({
              handleOpenAlert: handleOpenAlert,
            })}
            tableData={data?.length > 0 ? data : []}
          />
        </Box>
      </Flex>
    </>
  )
}

export default ExpenseSaffWork

const columnsData = ({ handleOpenAlert }) => [
  {
    Header: 'Email พนักงาน',
    accessor: 'email',
    extra: (data, row) => (
      <Center key={row} w='full'>
        <Text>{row?.user?.email}</Text>
      </Center>
    ),
  },
  {
    Header: 'ชื่อพนักงาน',
    accessor: 'name',
    extra: (data, row) => <Center key={row}>{row?.user?.name}</Center>,
  },
  {
    Header: 'สิทธ์การเข้าถึง',
    accessor: 'access',
    extra: (data, row) => (
      <Flex textAlign='flex-start' w='auto' key={row}>
        <Text>{row?.role}</Text>
      </Flex>
    ),
  },
  {
    Header: 'สาขา',
    accessor: 'branch.id',
    extra: (data, row) => (
      <Flex textAlign='flex-start' w='auto' key={row}>
        <Text>{row?.branch?.name}</Text>
      </Flex>
    ),
  },
  {
    Header: 'เวลา login',
    accessor: 'startDate',
    extra: (data, row) => (
      <Center w='auto' key={row}>
        <Text>
          {moment(row?.inTime?.toDate()).format('MM/DD/YYYY HH:mm:ss')}
        </Text>
      </Center>
    ),
  },
  {
    Header: 'เวลา logout',
    accessor: 'endDate',
    extra: (data, row) => (
      <Center w='auto' key={row}>
        <Text>
          {row?.outTime
            ? moment(row?.outTime?.toDate()).format('MM/DD/YYYY HH:mm:ss')
            : '-'}
        </Text>
      </Center>
    ),
  },
  {
    Header: 'ชั่วโมงการทำงาน',
    accessor: 'diff',
    extra: (data, row) => (
      <Center w='auto' key={row}>
        <Text>{row?.diff}</Text>
      </Center>
    ),
  },
  {
    Header: 'นาทีการทำงาน',
    accessor: 'diffMinute',
    extra: (data, row) => (
      <Center w='auto' key={row}>
        <Text>{row?.diffMinute}</Text>
      </Center>
    ),
  },
  {
    Header: 'หมายเหตุ',
    accessor: 'detailLogout',
    extra: (data, row) => (
      <Center w='auto' key={row}>
        <Text>{row?.detailLogout ? row?.detailLogout : ''}</Text>
      </Center>
    ),
  },
  {
    Header: 'รูปการเข้างาน',
    accessor: 'img',
    extra: (data, row) => (
      <Center w='auto' key={row}>
        <IconButton
          icon={<BsImage />}
          disabled={!row?.img}
          onClick={() => {
            handleOpenAlert(row)
          }}
        >
          Discard
        </IconButton>
        {/* <Text>{row?.diff}</Text> */}
      </Center>
    ),
  },
]
