import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  useColorModeValue,
} from '@chakra-ui/react'
import React from 'react'
import { Controller } from 'react-hook-form'

export default function RadioField({
  id,
  control,
  errors,
  options,
  name,
  required,
  label,
  mb,
  ...rest
}) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  return (
    <FormControl id={name} isInvalid={errors[name]} key={name}>
      <Flex direction='column' mb={mb ? mb : '30px'}>
        <FormLabel
          display='flex'
          ms='10px'
          htmlFor={id}
          fontSize='sm'
          color={textColorPrimary}
          fontWeight='bold'
          _hover={{ cursor: 'pointer' }}
        >
          {label}
        </FormLabel>
        <Controller
          name={name}
          control={control}
          rules={{
            required: required,
          }}
          render={({ field: { value, onChange } }) => (
            <RadioGroup value={value} onChange={onChange} {...rest} colorScheme="green">
              <HStack>
                {options.map((itm) => (
                  <Radio value={itm.value}>{itm.name}</Radio>
                ))}
              </HStack>
            </RadioGroup>
          )}
        />
      </Flex>
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
    </FormControl>
  )
}
