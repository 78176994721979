// import { calPromotionDecodeUnitAmountMultipleItems } from 'Utils/calStock'
import {
  calCommissionEmployeeDailyWithDecode,
  calDiscountPaidRefund,
  // calNetSalesPaidRefund,
  calOrderCostPaidRefund,
  calProfitPaidRefundNoCommission,
  calRefundOrder,
  calSellingPriceOrder,
  calSellingPriceOrderNoRefund,
  commissionEmployee,
} from 'Utils'
const _ = require('underscore')

export function calSellingPrice(order) {
  const res = calSellingPriceOrderNoRefund(order)
  return {
    total: res.total,
    cost: res.cost,
  }
}

export function calRefund(order) {
  const res = calRefundOrder(order)
  return {
    total: res.total,
    cost: res.cost,
  }
}

export function calDiscount(order) {
  const res = calDiscountPaidRefund(order)

  return {
    total: res,
  }
  // const refund = _.filter(order, (itm) => itm.status === 'refund')
  // const nonRefund = _.filter(order, (itm) => itm.status !== 'refund')

  // const promotionRefund = refund
  //   .map((data) => calPromotionDecodeUnitAmountMultipleItems(data.items))
  //   .reduce((memo, num) => memo + num, 0)

  // const promotionNonRefund = nonRefund
  //   .map((data) => calPromotionDecodeUnitAmountMultipleItems(data.items))
  //   .reduce((memo, num) => memo + num, 0)

  // const discountRefund = refund
  //   .map((data) => data.discountBalance)
  //   .reduce((memo, num) => memo + num, 0)
  // const discountNonRefund = nonRefund
  //   .map((data) => data.discountBalance)
  //   .reduce((memo, num) => memo + num, 0)

  // const totalPromotion = promotionNonRefund - promotionRefund
  // const totalDiscount = discountNonRefund - discountRefund

  // return {
  //   total: totalPromotion + totalDiscount,
  // }
}

export function calNetSales(order) {
  const totalSell = calSellingPriceOrder(order).total
  const totalDiscount = calDiscount(order).total
  return {
    total: totalSell - totalDiscount,
  }
}

export function calProfit(order) {
  const res = calProfitPaidRefundNoCommission(order)
  const discount = calDiscountPaidRefund(order)
  const totalProfit = res - discount
  return {
    total: totalProfit,
  }
}

export function calOrderCost(orders) {
  const paid = _.filter(orders, (itm) => itm.status === 'paid')
  const refund = _.filter(orders, (itm) => itm.status === 'refund')

  // ---------------- start paid ---------------- //
  const costPaid = calOrderCostPaidRefund(paid)

  // ---------------- end paid ---------------- //

  // ---------------- start refund ---------------- //
  const costRefund = calOrderCostPaidRefund(refund)

  // ---------------- end refund ---------------- //
  const totalCost = costPaid - costRefund

  return totalCost
}

export function calCommissionEmp(orders, work, user) {
  const res = commissionEmployee(orders, work, user)
  const pluckCommission = _.pluck(res, 'grandCommission')
  const total = _.reduce(pluckCommission, (memo, num) => memo + num, 0)
  return total
}

export function calProfitCommission(order, work, user) {
  const profit = calProfit(order).total
  const commission = calCommissionEmp(order, work, user)
  const res = profit - commission

  return res
}

export const calEmployeeSalary = (orders, employees, work) => {
  const calCom = calCommissionEmployeeDailyWithDecode(orders, employees, work)
  const filterEmpSalary = _.filter(calCom, (i) => {
    return i.salaryType === 'daily'
  })
  const pluckSalary = _.pluck(filterEmpSalary, 'salary')
  const sumSalary = _.reduce(pluckSalary, (memo, num) => memo + num, 0)
  return {
    total: sumSalary.toFixed(2),
    data: calCom,
  }
}

export const calExpenses = (expenses) => {
  const pluckTotal = _.pluck(expenses, 'total')
  const sumTotal = _.reduce(pluckTotal, (memo, num) => memo + num, 0)
  return sumTotal.toFixed(2)
}

export const calTotalProfit = (
  profitAfterDeductingCommissions,
  dailyEmployeeWage,
  expenses
) => {
  const sum = profitAfterDeductingCommissions - dailyEmployeeWage - expenses
  return sum.toFixed(2)
}
