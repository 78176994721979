import { ChevronLeftIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Flex,
  HStack,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from '@chakra-ui/react'
import ButtonResponsive from 'Components/Button/btn'
import Card from 'Components/Horizons/card/Card'
import { useCategory } from 'Hooks'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { First } from './category/first'

export default function FirstCategory({
  setCategory,
  discount,
  setDiscount,
  isSubmit,
}) {
  const { category, getCategoryAll } = useCategory()
  const [first, setFirst] = useState(false)

  useEffect(() => {
    getCategoryAll()
  }, [getCategoryAll])

  return (
    <>
      <Box display={first ? 'none' : 'block'}>
        <Center pb='3rem'>
          <Card
            padding={{
              base: '10px 0px 30px 0px',
              sm: '10px 0px 30px 0px',
              md: '10px 20px 30px 20px',
            }}
            w={{ base: '100%', sm: '100%', md: '30rem' }}
            boxShadow={{
              base: 'none',
              sm: 'none',
              md: '14px 17px 40px 4px rgb(112 144 176 / 8%)',
            }}
          >
            <VStack w='100%' alignItems='flex-start'>
              <HStack>
                <ChevronLeftIcon
                  cursor='pointer'
                  color='form.500'
                  fontSize={'2xl'}
                  onClick={() => {
                    setCategory(false)
                  }}
                />
                <Text
                  fontSize={{ base: 'md', sm: 'md', md: 'lg' }}
                  fontWeight='bold'
                >
                  เลือกหมวดหมู่จัดโปรโมชั่นส่วนลด
                </Text>
              </HStack>
              <Box borderBottom='1px solid #E8E9E9' w='100%'></Box>
              <RadioGroup
                w='100%'
                value={discount?.category?.id}
                onChange={(e) => {
                  const data = category.find((item) => item.id === e)
                  setDiscount({ ...discount, category: data })
                }}
              >
                <VStack alignItems='flex-start' w='100%' direction='row'>
                  {category.map((item, index) => (
                    <Flex
                      w='100%'
                      h='5rem'
                      borderBottom='1px'
                      borderColor='#E8E9E9'
                      key={index}
                    >
                      <Radio
                        colorScheme='green'
                        value={item.id}
                        w='100%'
                        h='100%'
                        pl='1.5rem'
                      >
                        <Text pl='1rem'>{item.name}</Text>
                      </Radio>
                    </Flex>
                  ))}
                </VStack>
              </RadioGroup>
              <Flex
                w='100%'
                pt={{ base: '0rem', sm: '0rem', md: '1rem' }}
                justifyContent='flex-end'
              >
                <ButtonResponsive
                  disabled={discount?.category ? false : true}
                  label={'ถัดไป'}
                  onClick={() => {
                    setFirst(true)
                    setDiscount({ ...discount, status: true })
                  }}
                />
              </Flex>
            </VStack>
          </Card>
        </Center>
      </Box>
      {first && (
        <First
          setFirst={setFirst}
          discount={discount}
          setDiscount={setDiscount}
          isSubmit={isSubmit}
        />
      )}
    </>
  )
}
