import { Box } from '@chakra-ui/react'
import { FormSettingPaymentBank } from 'Components/form/Payment/formPaymentBank'
import { usePayment } from 'Hooks'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { paymentThumbnail } from '../../Utils/paymentThumbnail'

function SettingPaymentEditBank({ user }) {
  const { id } = useParams()
  const [data, setData] = useState()
  const { getPaymentByID } = usePayment()

  const getPaymentData = useCallback(async () => {
    await getPaymentByID(id).then((data) => {
      setData(data)
    })
  }, [getPaymentByID, id])

  useEffect(() => {
    getPaymentData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      <FormSettingPaymentBank
        id={id}
        data={data}
        user={user}
        paymentThumbnail={paymentThumbnail}
      />
    </Box>
  )
}

export default SettingPaymentEditBank
