import React, { useEffect, useState } from 'react'
import {
  HStack,
  Input,
  Select,
  Flex,
  Text,
  Box,
  Button,
  Heading,
  Stack,
  Icon,
  Center,
  Badge,
} from '@chakra-ui/react'
import DatePicker from 'react-datepicker'
import { ChevronDownIcon, ChevronUpIcon, SearchIcon } from '@chakra-ui/icons'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import ExpandTable from './components/ExpandTable'
import { calculateGrandTotal } from './functions/calculate'
import { useBranch, useOrder } from 'Hooks'
import { onFilterReportBranch } from './functions/filter'
const accounting = require('accounting')
const _ = require('underscore')

function Price(props) {
  const { grandTotal } = props
  return (
    <HStack justifyContent='end' spacing='1em'>
      <Stack>
        <Box>
          <Text fontSize={{ base: '14px', md: '18px' }} color='black'>
            ยอดขายสุทธิรวม
          </Text>
        </Box>
        <Box>
          <Text fontSize={{ base: '12px', md: '16px' }} color='report.500'>
            {accounting.formatNumber(grandTotal, 2)}
          </Text>
        </Box>
      </Stack>
    </HStack>
  )
}

function ReportBranch() {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const { ordersReport, getAllOrdersWithReport } = useOrder()
  const { Branch } = useBranch()
  const [selectBranch, setSelectBranch] = useState('')
  const [selectStatus, setSelectStatus] = useState('')
  const [filterData, setFilterData] = useState([])
  const [onSearch, setOnSearch] = useState(false)

  useEffect(() => {
    getAllOrdersWithReport()
  }, [getAllOrdersWithReport])

  const AllBranch = _.groupBy(
    ordersReport.map((item) => ({
      ...item,
      branch: item?.branch?.name,
      discountBalance:
        item.status === 'refund'
          ? (parseFloat(item.discountBalance) / 100) * -1
          : parseFloat(item.discountBalance) / 100,
      total:
        item.status === 'refund'
          ? (parseFloat(item.total) / 100) * -1
          : parseFloat(item.total) / 100,
      grandTotal:
        item.status === 'refund'
          ? (parseFloat(item.grandTotal) / 100) * -1
          : parseFloat(item.grandTotal) / 100,
    })),
    'branch'
  )

  const filterBranch = Object.keys(AllBranch).map((item) => {
    // const dataCal = await Promise.all(
    //   _.map(AllBranch[item], (item, i) => {
    //     const cal = calPromotionDecodeUnitAmountMultipleItems(item.items)
    //     return {
    //       promotionAndDiscount: currencyDecimal(cal),
    //     }
    //   })
    // )

    return {
      branch: item,
      createdAt: null,
      orderId: null,
      type: null,
      total: _.reduce(AllBranch[item], (v, i) => v + i.total, 0),
      grandTotal: _.reduce(AllBranch[item], (v, i) => v + i.grandTotal, 0),
      status: null,
      subRows: AllBranch[item].map((itm) => ({
        createdAt: itm.createdAt
          .toDate()
          .toLocaleString('en-US', { hour12: false }),
        date: itm.createdAt.toDate(),
        orderId: itm.orderId,
        type: itm.payment.type,
        total: itm.total,
        grandTotal: itm.grandTotal,
        status: itm.status,
      })),
    }
  })

  function onSubmitFilter() {
    const res = onFilterReportBranch(
      filterBranch,
      selectBranch,
      selectStatus,
      startDate,
      endDate
    )
    setOnSearch(true)
    setFilterData(res)
  }
  return (
    <>
      <HStack
        my='1em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='1vw'
        flexDirection={{ sm: 'column', md: 'row' }}
      >
        <Flex flexDirection='column' w={{ sm: 'full', md: '30vw', lg: '30vw' }}>
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ช่วงเวลา :</Text>
          </Box>
          <Flex bg='#F4F7FE' borderRadius='16px'>
            <Input
              cursor='pointer'
              border='0px'
              w='full'
              textAlign='center'
              as={DatePicker}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText={'วันที่เริ่มต้น'}
            />
            <Input
              cursor='pointer'
              border='0px'
              w='full'
              textAlign='center'
              as={DatePicker}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText={'วันที่สิ้นสุด'}
            />
          </Flex>
        </Flex>
        <Flex
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        >
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>สาขา :</Text>
          </Box>
          <Select
            color={'gray.700'}
            w='full'
            bg={'#F4F7FE'}
            border='0px solid #E8E9E9'
            borderRadius='16px'
            defaultValue=''
            fontWeight='500'
            variant='main'
            placeholder={'ทั้งหมด'}
            _placeholder={{
              fontWeight: '400',
            }}
            h='44px'
            maxh='44px'
            onChange={(event) => setSelectBranch(event.target.value)}
          >
            {Branch.map((item, index) => (
              <option value={item.name} key={index}>
                {item.name}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        >
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>การชำระเงิน :</Text>
          </Box>
          <Select
            color={'gray.700'}
            w='full'
            bg={'#F4F7FE'}
            border='0px solid #E8E9E9'
            borderRadius='16px'
            defaultValue=''
            fontWeight='500'
            variant='main'
            placeholder={'ทั้งหมด'}
            _placeholder={{
              fontWeight: '400',
            }}
            h='44px'
            maxh='44px'
            onChange={(event) => setSelectStatus(event.target.value)}
          >
            <option value='paid'>ชำระเงินแล้ว</option>
            <option value='payLater'>รอการชำระ</option>
            <option value='refund'>คืนเงิน</option>
          </Select>
        </Flex>
        <Flex
          flexDirection='column'
          w={{ sm: 'full', md: '19vw', lg: '13vw', xl: '11vw' }}
          alignItems='end'
          pt={{ sm: '1rem', md: '0rem' }}
        >
          <Box
            textAlign='start'
            display={{ sm: 'none', md: 'flex' }}
            p='0.5rem'
            pl='1rem'
            pt='0rem'
          >
            <Text color='#8F9BBA'>&nbsp;</Text>
          </Box>
          <Button
            h='44px'
            maxh='44px'
            w={{ sm: '10rem', md: 'full' }}
            colorScheme='btn'
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            onClick={() => onSubmitFilter()}
          >
            <Text>
              <SearchIcon />
            </Text>
            <Text>แสดงรายงาน</Text>
          </Button>
        </Flex>
      </HStack>
      <Flex
        flexDirection='column'
        p={{ sm: '10px', md: '20px 20px 30px 20px' }}
        bg='white'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
        borderRadius='30px'
      >
        <Stack spacing={{ base: '0em', md: '4em' }}>
          <Stack>
            <Heading
              fontFamily={'Prompt'}
              color='#2B3674'
              fontSize={{ base: '24px', md: '30px' }}
            >
              รายงานยอดขายตามสาขา
            </Heading>
            <Box>
              <HStack>
                <Text color='#2B3674'>ณ วันที่:</Text>
                <Text color='#2B3674'>
                  {moment(new Date().getTime()).format('MM/DD/YYYY')}
                </Text>
              </HStack>
              <HStack>
                <Text color='#2B3674'>ช่วงเวลา:</Text>
                {startDate && endDate && (
                  <Text color='#2B3674'>
                    {moment(startDate).format('MM/DD/YYYY')} -{' '}
                    {moment(endDate).format('MM/DD/YYYY')}
                  </Text>
                )}
              </HStack>
            </Box>
          </Stack>
          <Box
            pt={{ base: '1rem', sm: '1rem', md: '0rem', lg: '0rem' }}
            pr={{
              base: '0rem',
              sm: '0rem',
              md: '1rem',
              lg: '1rem',
              xl: '3rem',
            }}
          >
            <Price
              grandTotal={onSearch ? calculateGrandTotal(filterData) : 0}
            />
          </Box>
        </Stack>
        <ExpandTable
          columnsData={columnsData}
          tableData={onSearch ? filterData : []}
        />
      </Flex>
    </>
  )
}

export default ReportBranch

const columnsData = [
  {
    // Build our expander column
    id: 'expander', // Make sure it has an ID
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      <Text {...getToggleAllRowsExpandedProps()}>
        {isAllRowsExpanded ? (
          <Icon as={ChevronUpIcon} />
        ) : (
          <Icon as={ChevronDownIcon} />
        )}
      </Text>
    ),
    Cell: ({ row }) =>
      row.canExpand ? (
        <Center>
          <Text
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            {row.isExpanded ? (
              <Icon as={ChevronUpIcon} />
            ) : (
              <Icon as={ChevronDownIcon} />
            )}
          </Text>
        </Center>
      ) : null,
  },
  {
    Header: 'สาขา',
    accessor: 'branch',
    Cell: ({ row }) => {
      return (
        <Box>
          <Text>{row.original.branch}</Text>
        </Box>
      )
    },
  },
  {
    Header: 'วันที่',
    accessor: 'createdAt',
    Cell: ({ row }) => (
      <Center>
        <Text>{row.original.createdAt}</Text>
      </Center>
    ),
  },
  {
    Header: 'เลขที่รายการขาย',
    accessor: 'orderId',
    Cell: ({ row }) => (
      <Center>
        <Text>{row.original.orderId}</Text>
      </Center>
    ),
  },
  {
    Header: 'ช่องทางการชำระ',
    accessor: 'type',
    Cell: ({ row }) => (
      <Center>
        <Text>
          {row.original.type === 'bank'
            ? 'ธนาคาร'
            : row.original.type === 'cash'
            ? 'เงินสด'
            : row.original.type === 'payLater'
            ? 'ชำระภายหลัง'
            : row.original.type === 'online'
            ? 'ช่องทางออนไลน์'
            : ''}
        </Text>
      </Center>
    ),
  },
  {
    Header: 'สถานะ',
    accessor: 'status',
    Cell: ({ row }) => (
      <Center>
        {row?.original?.status === 'paid' ? (
          <Badge colorScheme={'green'}>ชำระเงินแล้ว</Badge>
        ) : row?.original?.status === 'refund' ? (
          <Badge colorScheme={'red'}>คืนเงิน</Badge>
        ) : row?.original?.status === 'payLater' ? (
          '0'
        ) : null}
      </Center>
    ),
  },
  {
    Header: 'ยอดขาย',
    accessor: 'total',
    Cell: ({ row }) => (
      <Center justifyContent='flex-end' pr='5em'>
        <Text>
          {new Intl.NumberFormat('th-TH', {
            minimumFractionDigits: 2,
          }).format(row?.original?.total)}
        </Text>
      </Center>
    ),
  },
  // {
  //   Header: 'ส่วนลด',
  //   accessor: 'promotionAndDiscount',
  //   Cell: ({ row }) => (
  //     <Center justifyContent='flex-end' pr='5em'>
  //       <Text>{currencyDecimal(0)}</Text>
  //     </Center>
  //   ),
  // },
  {
    Header: 'ยอดขายสุทธิ',
    accessor: 'grandTotal',
    Cell: ({ row }) => (
      <Center justifyContent='flex-end' pr='5em'>
        <Text>
          {new Intl.NumberFormat('th-TH', {
            minimumFractionDigits: 2,
          }).format(row?.original?.grandTotal)}
        </Text>
      </Center>
    ),
  },
]
