import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Fade,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import InputField from 'Components/Horizons/fields/InputField'
import TextField from 'Components/Horizons/fields/TextField'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { IoPricetagsOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { useBranch, useProduct } from 'Hooks'
import RadioField from 'Components/Horizons/fields/RadioField'
import moment from 'moment'

const _ = require('underscore')

export function ViewForm({ data, id }) {
  const navigate = useNavigate()
  const { Branch } = useBranch()
  const { Product } = useProduct()
  const [allBranch, setAllBranch] = useState([])
  const [allProduct, setAllProduct] = useState([])
  const [isFadePage, setIsFadePage] = useState(true)
  const [type, setType] = useState('Bath')
  const [options, setOptions] = useState([])
  const [products, setProducts] = useState([])
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const scroll = useRef(null)
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      type: 'default',
      status: true,
      detail: '',
    },
  })
  useEffect(() => {
    if (data) {
      setValue('name', data?.name)
      setValue('detail', data?.detail)
      setValue('discount', data?.discount)
      setValue('discountType', data?.discountType)
      setValue('status', data?.status)
      setValue('type', data?.type)
      setValue('typeProduct', data?.typeProduct)
      setOptions(data?.branch)
      setProducts(data?.product)
      setType(data?.discountType)
    } else {
      setValue('discountType', type)
    }

    const BranchValue = []
    const ProductValue = []

    _.forEach(
      Branch.filter((branch) => branch.id !== 'HQ'),
      (doc) => {
        BranchValue.push({ id: doc.id, ...doc })
      }
    )
    _.forEach(Product, (doc) => {
      ProductValue.push({ id: doc.id, ...doc })
    })
    setAllBranch(BranchValue)
    setAllProduct(ProductValue)
    setTimeout(() => {
      setIsFadePage(false)
    }, 500)
  }, [data, type, setValue, Branch, Product])
  const typeBranch = watch('type')
  const typeProduct = watch('typeProduct')

  function onSubmit(value) {}
  const branch = _.flatten(
    options.map((itm) =>
      allBranch?.filter((item) => item.id === itm.id)?.map((items) => items)
    )
  )

  const product = _.flatten(
    products.map((itm) =>
      allProduct?.filter((item) => item.id === itm.id)?.map((items) => items)
    )
  )
  return (
    <Fade in={!isFadePage}>
      <Flex
        direction='column'
        minH='100vh'
        h='100vh'
        align='center'
        position='relative'
      >
        <Box
          h={{ base: '0px', sm: '0px', md: '25vh' }}
          bg='discount.700'
          position='absolute'
          w={{ sm: '100vw', md: '100%', lg: '100%' }}
          borderRadius={{ sm: '0px', md: '30px', lg: '30px' }}
        ></Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            w={{ sm: '100vw', md: '670px', lg: '850px' }}
            h={{ sm: 'full', md: 'auto' }}
            p='0px'
            mx='auto'
          >
            <Card
              p={{ base: '15px', sm: '15px', md: '30px' }}
              px={{ base: '30px', sm: '30px', md: '30px' }}
              mt={{ sm: '-10px', md: '4rem', lg: '4rem' }}
              h={{ sm: 'full', md: 'auto' }}
              pb={{ base: '40px', sm: '40px', md: '30px' }}
            >
              <Box
                pb='0.3rem'
                zIndex='base'
                color='discount'
                onClick={() => navigate(-1)}
                w='max-content'
                display={{ sm: 'flex', md: 'none' }}
              >
                <Text
                  display={{ sm: 'flex', md: 'none' }}
                  fontSize='24px'
                  cursor='pointer'
                >
                  <ChevronLeftIcon />
                </Text>
              </Box>
              <Text
                textAlign={{
                  base: 'center',
                  sm: 'center',
                  md: 'left',
                  lg: 'left',
                }}
                fontSize='2xl'
                color='#1B2559'
                fontWeight='700'
                pb={{ base: '1rem', lg: '0rem' }}
              >
                ส่วนลดร้านค้า
              </Text>
              <Center>
                <Center
                  color='white'
                  bg={'discount.500'}
                  borderRadius={'full'}
                  h='7rem'
                  w='7rem'
                >
                  <Icon as={IoPricetagsOutline} w='4.5rem' h='4.5rem' />
                </Center>
              </Center>
              <Flex direction='column' w='100%' pt={'1em'}>
                <Stack direction='column' spacing='20px'>
                  <SimpleGrid
                    pt={'1em'}
                    columns={{ base: '1', md: '2' }}
                    gap='20px'
                    color='#1B2559'
                  >
                    <Stack direction='column' gap='20px'>
                      <InputField
                        readOnly
                        control={control}
                        errors={errors}
                        mb='0px'
                        id='name'
                        label='ชื่อส่วนลด'
                        name='name'
                        type='text'
                        required={{
                          value: true,
                          message: 'กรุณากรอกชื่อส่วนลด',
                        }}
                      />
                      <FormControl isInvalid={!!errors['discount']}>
                        <FormLabel
                          display='flex'
                          ms='10px'
                          mb='0px'
                          fontSize='sm'
                          color={textColorPrimary}
                          fontWeight='bold'
                          _hover={{ cursor: 'pointer' }}
                        >
                          ส่วนลด
                        </FormLabel>
                        <InputGroup>
                          <Input
                            readOnly
                            {...register('discount', {
                              required: {
                                value: true,
                                message: 'กรุณากรอกส่วนลด',
                              },
                              pattern: {
                                value: /[0-9]/,
                                message: 'กกรุณากรอกเป็นตัวเลข',
                              },
                            })}
                            type='number'
                            borderRadius={{
                              base: '10px',
                              sm: '10px',
                              md: '10px',
                              lg: '16px',
                            }}
                            defaultValue=''
                            fontWeight='500'
                            variant='main'
                            _placeholder={{
                              fontWeight: '400',
                              color: 'secondaryGray.600',
                            }}
                            h='44px'
                            maxh='44px'
                          />
                          <InputRightElement width='4.5rem'>
                            <Button
                              disabled
                              h='1.75rem'
                              size='sm'
                              onClick={() => {
                                if (type === 'Bath') {
                                  setType('Percentage')
                                } else if (type === 'Percentage') {
                                  setType('Bath')
                                }
                              }}
                            >
                              {type === 'Bath' ? (
                                <Text>&#3647;</Text>
                              ) : (
                                <Text>%</Text>
                              )}
                            </Button>
                          </InputRightElement>
                        </InputGroup>

                        <FormErrorMessage>
                          {errors['discount'] && errors['discount']?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Stack>
                    <Stack direction='column' gap='20px'>
                      <TextField
                        readOnly
                        control={control}
                        errors={errors}
                        mb='0px'
                        id='detail'
                        name='detail'
                        label='รายละเอียดส่วนลด'
                        h='140px'
                      />
                    </Stack>
                  </SimpleGrid>
                </Stack>
                <SimpleGrid columns={{ base: 1, md: 2 }}>
                  <Box w='full' h='full' mt='30px'>
                    <Controller
                      control={control}
                      errors={errors}
                      name={'status'}
                      render={({ field: { onChange, value } }) => (
                        <FormControl w='full' h='full'>
                          <HStack
                            w='full'
                            h='full'
                            flexDirection='column'
                            alignItems='start'
                          >
                            <FormLabel
                              display='flex'
                              ms='10px'
                              fontSize='sm'
                              color={textColorPrimary}
                              fontWeight='bold'
                              _hover={{ cursor: 'pointer' }}
                              w='full'
                            >
                              เปิด-ปิด ส่วนลดร้านค้า
                            </FormLabel>
                            <Switch
                              readOnly
                              colorScheme='green'
                              onChange={onChange}
                              isChecked={value}
                            />
                          </HStack>
                        </FormControl>
                      )}
                    />
                  </Box>
                </SimpleGrid>
                <SimpleGrid mt='1rem' columns={{ base: 1, md: 2 }}>
                  <RadioField
                    name={'type'}
                    control={control}
                    errors={errors}
                    id={'type'}
                    label={'ใช้ได้กับสาขาใด'}
                    mb={'0px'}
                    isDisabled
                    options={[
                      { name: 'ทุกสาขา', value: 'default' },
                      { name: 'บางสาขา', value: 'branch' },
                    ]}
                  />
                  {typeBranch === 'branch' && (
                    <VStack pt='1em' alignItems={'start'}>
                      {branch.map((items, index) => (
                        <Box key={index} w='full'>
                          <HStack w='full'>
                            <Avatar src={items?.img} />
                            <Text>
                              {items?.nameOption
                                ? items?.name + ' ' + items.nameOption
                                : items?.name}
                            </Text>
                          </HStack>
                          <Divider mt='.75em' />
                        </Box>
                      ))}
                    </VStack>
                  )}
                </SimpleGrid>

                <SimpleGrid columns={[1, 2]} gap={'1em'} alignItems='start'>
                  <VStack w='full' pt={'25px'}>
                    <RadioField
                      name={'type'}
                      control={control}
                      errors={errors}
                      id={'type'}
                      label={'ใช้ได้กับสาขาใด'}
                      mb={'6px'}
                      options={[
                        { name: 'ทุกสาขา', value: 'default' },
                        { name: 'บางสาขา', value: 'branch' },
                      ]}
                    />
                    <Stack
                      w='full'
                      direction={{ base: 'column', md: 'row' }}
                      // pt={{ sm: '10px', md: '20px', lg: '20px' }}
                    >
                      {typeBranch === 'branch' && (
                        <VStack pt='1em' alignItems={'start'}>
                          {branch.map((items, index) => (
                            <Box key={index} w='full'>
                              <HStack w='full'>
                                <Avatar src={items?.img} />
                                <Text>{items?.name}</Text>
                              </HStack>
                              <Divider mt='.75em' />
                            </Box>
                          ))}
                        </VStack>
                      )}
                    </Stack>
                  </VStack>
                  <VStack w='full' pt={'25px'}>
                    <RadioField
                      name={'typeProduct'}
                      control={control}
                      errors={errors}
                      id={'typeProduct'}
                      label={'ใช้ได้กับสินค้าใด'}
                      mb={'6px'}
                      options={[
                        { name: 'ทุกสินค้า', value: 'default' },
                        { name: 'บางสินค้า', value: 'product' },
                      ]}
                    />
                    <Stack
                      w='full'
                      direction={{ base: 'column', md: 'row' }}
                      // pt={{ sm: '10px', md: '20px', lg: '20px' }}
                    >
                      {typeProduct === 'product' && (
                        <VStack pt='1em' alignItems={'start'}>
                          {product.map((items, index) => (
                            <Box key={index} w='full'>
                              <HStack w='full'>
                                <Avatar src={items?.img} />
                                <Text>
                                  {items?.nameOption
                                    ? items?.name + ' ' + items.nameOption
                                    : items?.name}
                                </Text>
                              </HStack>
                              <Divider mt='.75em' />
                            </Box>
                          ))}
                        </VStack>
                      )}
                    </Stack>
                  </VStack>
                </SimpleGrid>
                <VStack
                  mt={{ base: '1.5rem', sm: '1.5rem', md: '1rem', lg: '1rem' }}
                  spacing='10px'
                  flexDirection='column'
                  alignItems='start'
                >
                  <HStack
                    textAlign='left'
                    flexDirection={{
                      base: 'column',
                      sm: 'column',
                      md: 'row',
                      lg: 'row',
                    }}
                    spacing={{
                      base: '0rem',
                      sm: '0rem',
                      md: '1.5rem',
                      lg: '1.5rem',
                    }}
                  >
                    <Text>Create by : {data?.createdBy?.name}</Text>
                    <Text pr='0.7rem'>
                      Date :{' '}
                      {data?.createdAt
                        ? moment(data?.createdAt?.toDate()).format(
                            'DD-MM-YYYY HH:mm:ss'
                          )
                        : '-'}
                    </Text>
                  </HStack>
                  <HStack
                    textAlign='left'
                    flexDirection={{
                      base: 'column',
                      sm: 'column',
                      md: 'row',
                      lg: 'row',
                    }}
                    spacing={{
                      base: '0rem',
                      sm: '0rem',
                      md: '1.5rem',
                      lg: '1.5rem',
                    }}
                  >
                    <Text>Modify by : {data?.updatedBy?.name} </Text>
                    <Text pr='0.7rem'>
                      Date :{' '}
                      {data?.updatedAt
                        ? moment(data?.updatedAt?.toDate()).format(
                            'DD-MM-YYYY HH:mm:ss'
                          )
                        : '-'}
                    </Text>
                  </HStack>
                </VStack>
              </Flex>
              <Flex justify='space-between' mt='30px'>
                <Button
                  variant='light'
                  fontSize='sm'
                  borderRadius='16px'
                  w={{ base: '128px', md: '148px' }}
                  h='46px'
                  onClick={() => navigate(-1)}
                >
                  ย้อนกลับ
                </Button>
                <Button
                  colorScheme='btn'
                  fontSize='sm'
                  borderRadius='16px'
                  w={{ base: '128px', md: '148px' }}
                  h='46px'
                  onClick={() => navigate(`/discount/product/edit/${id}`)}
                >
                  แก้ไขข้อมูล
                </Button>
              </Flex>
            </Card>
          </Box>
        </form>
      </Flex>
      <Box ref={scroll}></Box>
    </Fade>
  )
}
