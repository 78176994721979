const _ = require('underscore')

export function calDiscountPaidRefund(order) {
  const paid = _.filter(order, (itm) => itm.status === 'paid')
  const totalDiscount = _.pluck(paid, 'totalDiscount')
  const totalPaid = _.reduce(totalDiscount, (memo, num) => memo + num, 0)

  const refund = _.filter(order, (itm) => itm.status === 'refund')
  const totalDiscountRefund = _.pluck(refund, 'totalDiscount')
  const totalRefund = _.reduce(
    totalDiscountRefund,
    (memo, num) => memo + num,
    0
  )
  const total = totalPaid - totalRefund

  return total
}
