import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Center, HStack, Stack, Text } from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import { usePromotion } from 'Hooks/promotion'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { IoPricetagsOutline } from 'react-icons/io5'
import { TbDiscount2 } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { encodeUnit } from 'Utils/currency'
import FirstDiscount from './components/discount/first'
import SecondFreeForm from './components/free/second'

export default function FormDiscount({ id, data }) {
  const { createPromotion, updatePromotion } = usePromotion()
  const [isSubmit, setIsSubmit] = useState(false)
  const [discountForm, setDiscountForm] = useState(false)
  const [freeForm, setFreeForm] = useState(false)
  const [discount, setDiscount] = useState(null)
  const navigate = useNavigate()

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (data) {
      setValue('typeDiscount', data?.typeDiscount ? data?.typeDiscount : null)
      setValue('typeFree', data?.typeFree ? data?.typeFree : null)
      setDiscount({
        product: data?.product ? data?.product : null,
        category: data?.category ? data?.category : null,
        categoryAmount: data?.categoryAmount ? data?.categoryAmount : null,
        discountCode: data?.discountCode ? data?.discountCode : null,
        discountCodeStatus: data?.discountCodeStatus
          ? data?.discountCodeStatus
          : null,
        discountDetail: data?.discountDetail ? data?.discountDetail : null,
        discountName: data?.discountName ? data?.discountName : null,
        status: data?.status,
        type: data?.type,
        valueDiscount: data?.valueDiscount ? data?.valueDiscount : null,
        valueDiscountType: data?.valueDiscountType
          ? data?.valueDiscountType
          : null,
        freeProductAmount: data?.freeProductAmount
          ? data?.freeProductAmount
          : null,
        productAmount: data?.productAmount ? data?.productAmount : null,
        productFree: data?.productFree ? data?.productFree : null,
        categoryFree: data?.categoryFree ? data?.categoryFree : null,
        minimumOrder: data?.minimumOrder ? data?.minimumOrder : null,
        freeCategoryAmount: data?.freeCategoryAmount
          ? data?.freeCategoryAmount
          : null,
      })
    }
  }, [data, setValue])

  function onSubmit(data) {
    setIsSubmit(true)
    const allValue = {
      ...data,
      ...discount,
      product: discount.product ? discount.product : null,
      category: discount.category ? discount.category : null,
      categoryAmount: discount.categoryAmount
        ? encodeUnit(discount.categoryAmount).toString()
        : null,
      productFree: discount?.productFree ? discount?.productFree : null,
      categoryFree: discount?.categoryFree ? discount?.categoryFree : null,
      freeCategoryAmount: discount?.freeCategoryAmount
        ? encodeUnit(discount?.freeCategoryAmount).toString()
        : null,
      valueDiscount: discount.valueDiscount
        ? encodeUnit(discount.valueDiscount).toString()
        : null,
      freeProductAmount: discount.freeProductAmount
        ? encodeUnit(discount.freeProductAmount).toString()
        : null,
      minimumOrder: discount?.minimumOrder
        ? encodeUnit(discount?.minimumOrder)
        : null,
      productAmount: discount.productAmount
        ? encodeUnit(discount.productAmount).toString()
        : null,
    }
    if (id) {
      updatePromotion(id, allValue).then(() => {
        setIsSubmit(false)
        navigate('/discount/store')
      })
    } else {
      createPromotion(allValue).then(() => {
        setIsSubmit(false)
        navigate('/discount/store')
      })
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display={discountForm || freeForm ? 'none' : 'block'}>
        <HStack pt='0.5rem' w='100%'>
          <ChevronLeftIcon
            cursor='pointer'
            color='form.500'
            fontSize={'2xl'}
            onClick={() => navigate('/discount/store')}
          />
          <Text fontSize={{ base: 'md', sm: 'md', md: 'lg' }} fontWeight='bold'>
            สร้างโปรโมชั่นส่วนลด
          </Text>
        </HStack>
        <Center
          mt={{ base: '2.5rem', sm: '2.5rem', md: '1rem' }}
          flexDirection='column'
        >
          <Text
            fontSize={{ base: '18px', sm: '18px', md: '20px' }}
            fontWeight='bold'
            color={'table.100'}
            pb={{ base: '0.5rem', sm: '0.5rem', md: '0.5rem' }}
          >
            รูปแบบโปรโมชั่นส่วนลด
          </Text>
          <Stack
            pt={{ base: '0rem', sm: '0rem', md: '1rem' }}
            direction='column'
            mt={{ base: '0.5rem', sm: '0.5rem', md: '0rem' }}
            spacing={{ base: '0px', sm: '0px', md: '15px', lg: '20px' }}
            // borderTop={{ base: '0px', sm: '0px', md: '1px solid #E8E9E9' }}
            justifyContent='center'
            w='100%'
            alignItems='center'
          >
            <Card
              w={{ base: 'full', sm: 'full', md: '40rem' }}
              bg={discount?.type === 'discount' ? 'table.100' : '#fff'}
              onClick={() => {
                setDiscount({ ...discount, type: 'discount' })
                setDiscountForm(true)
              }}
              cursor='pointer'
              transition='all 0.4s'
              fontSize={'md'}
              _hover={{ bg: '#F2F2F2', transition: 'all 0.4s' }}
              py={{ base: '2rem', sm: '2rem', md: '2rem' }}
              px={{ base: '0.5rem', sm: '0.5rem', md: '2rem' }}
              borderTop={{
                base: '1px solid #E8E9E9',
                sm: '1px solid #E8E9E9',
                md: '0px',
              }}
              borderBottom={{
                base: '1px solid #E8E9E9',
                sm: '1px solid #E8E9E9',
                md: '0px',
              }}
              borderRadius={{ base: '0px', sm: '0px', md: '20px' }}
            >
              <HStack justifyContent={'space-between'}>
                <HStack>
                  <Box w={'2em'}>
                    <Text
                      fontSize={'3xl'}
                      color={
                        discount?.type === 'discount' ? '#fff' : 'table.100'
                      }
                    >
                      <IoPricetagsOutline />
                    </Text>
                  </Box>
                  <Text color={discount?.type === 'discount' ? '#fff' : '#000'}>
                    ส่วนลดเป็นเปอร์เซ็นต์, จำนวนเงิน
                  </Text>
                </HStack>
                <Text
                  fontSize={'3xl'}
                  color={discount?.type === 'discount' ? '#fff' : 'table.100'}
                >
                  <ChevronRightIcon />
                </Text>
              </HStack>
            </Card>
            <Card
              w={{ base: '100%', sm: '100%', md: '40rem' }}
              bg={discount?.type === 'free' ? 'table.100' : '#fff'}
              onClick={() => {
                setDiscount({ ...discount, type: 'free' })
                setFreeForm(true)
              }}
              cursor='pointer'
              transition='all 0.4s'
              fontSize={'md'}
              _hover={{ bg: '#F2F2F2', transition: 'all 0.4s' }}
              py={{ base: '2rem', sm: '2rem', md: '2rem' }}
              px={{ base: '0.5rem', sm: '0.5rem', md: '2rem' }}
              borderBottom={{
                base: '1px solid #E8E9E9',
                sm: '1px solid #E8E9E9',
                md: '0px',
              }}
              borderRadius={{ base: '0px', sm: '0px', md: '20px' }}
              borderColor='#E8E9E9'
            >
              <HStack justifyContent={'space-between'}>
                <HStack>
                  <Box w='2em'>
                    <Text
                      fontSize={'3xl'}
                      color={discount?.type === 'free' ? '#fff' : 'table.100'}
                    >
                      <TbDiscount2 />
                    </Text>
                  </Box>
                  <Text color={discount?.type === 'free' ? '#fff' : '#000'}>
                    โปรโมชั่นส่วนลดแบบมีเงื่อนไข
                  </Text>
                </HStack>
                <Text
                  fontSize={'3xl'}
                  color={discount?.type === 'free' ? '#fff' : 'table.100'}
                >
                  <ChevronRightIcon />
                </Text>
              </HStack>
            </Card>
          </Stack>
        </Center>
      </Box>
      {discountForm && (
        <FirstDiscount
          id={id}
          control={control}
          errors={errors}
          discount={discount}
          setDiscount={setDiscount}
          watch={watch}
          isSubmit={isSubmit}
          setDiscountForm={setDiscountForm}
          reset={reset}
        />
      )}
      {freeForm && (
        <SecondFreeForm
          id={id}
          control={control}
          errors={errors}
          discount={discount}
          setDiscount={setDiscount}
          watch={watch}
          isSubmit={isSubmit}
          setFreeForm={setFreeForm}
          reset={reset}
        />
      )}
    </form>
  )
}
