import { Box, Center, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { BsCashCoin } from 'react-icons/bs'
import { MdNavigateNext, MdOutbox } from 'react-icons/md'
import { RiBankFill } from 'react-icons/ri'

function PosSelectPayment(props) {
  const { setPaymentStep, user } = props
  return (
    <>
      <Flex p='1rem' flexDirection='column'>
        <Text
          color='pos.500'
          pb='0.5rem'
          pt={{ base: '2rem', sm: '2rem', md: '1rem' }}
        >
          เลือกช่องทางการชำระเงิน
        </Text>
        <Box
          borderTop='1px solid #E8E9E9'
          borderBottom='1px solid #E8E9E9'
          onClick={() => {
            setPaymentStep({
              cashReceived: true,
            })
          }}
          cursor='pointer'
          transition='all .5s'
          _hover={{ bg: '#E8E9E9', transition: 'all .5s' }}
        >
          <Grid templateColumns='repeat(3, 1fr)' h='6rem' gap={0}>
            <GridItem w='100%' h='full'>
              <Center h='full'>
                <BsCashCoin color='pos.500' fontSize='20px' />
              </Center>
            </GridItem>
            <GridItem
              w={{ sm: '10rem', md: '8rem', lg: '8rem', xl: '8rem' }}
              h='full'
            >
              <Center h='full' fontSize='14px' color={'black'}>
                เงินสด
              </Center>
            </GridItem>
            <GridItem w='100%' h='full'>
              <Center h='full'>
                <MdNavigateNext color='pos.500' fontSize='22px' />
              </Center>
            </GridItem>
          </Grid>
        </Box>
        <Box
          borderBottom='1px solid #E8E9E9'
          onClick={() => {
            setPaymentStep({
              bankReceived: true,
            })
          }}
          cursor='pointer'
          transition='all .5s'
          _hover={{ bg: '#E8E9E9', transition: 'all .5s' }}
        >
          <Grid templateColumns='repeat(3, 1fr)' h='6rem' gap={0}>
            <GridItem w='100%' h='full'>
              <Center h='full'>
                <RiBankFill color='pos.500' fontSize='20px' />
              </Center>
            </GridItem>
            <GridItem
              w={{ sm: '10rem', md: '8rem', lg: '8rem', xl: '8rem' }}
              h='full'
            >
              <Center h='full' fontSize='14px' color={'black'}>
                โอนผ่านบัญชีธนาคาร
              </Center>
            </GridItem>
            <GridItem w='100%' h='full'>
              <Center h='full'>
                <MdNavigateNext color='pos.500' fontSize='22px' />
              </Center>
            </GridItem>
          </Grid>
        </Box>

        {user?.branchSelect?.wholeSale && (
          <Box
            borderBottom='1px solid #E8E9E9'
            onClick={() => {
              setPaymentStep({
                laterPayment: true,
              })
            }}
            cursor='pointer'
            transition='all .5s'
            _hover={{ bg: '#E8E9E9', transition: 'all .5s' }}
          >
            <Grid templateColumns='repeat(3, 1fr)' h='6rem' gap={0}>
              <GridItem w='100%' h='full'>
                <Center h='full'>
                  <MdOutbox color='pos.500' fontSize='20px' />
                </Center>
              </GridItem>
              <GridItem
                w={{ sm: '10rem', md: '8rem', lg: '8rem', xl: '8rem' }}
                h='full'
              >
                <Center h='full' fontSize='14px' color={'black'}>
                  ชำระภายหลัง
                </Center>
              </GridItem>
              <GridItem w='100%' h='full'>
                <Center h='full'>
                  <MdNavigateNext color='pos.500' fontSize='22px' />
                </Center>
              </GridItem>
            </Grid>
          </Box>
        )}
      </Flex>
    </>
  )
}

export default PosSelectPayment
