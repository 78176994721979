export function findUserBranchInBranch(userList, branchId) {
  const onBranch =
    userList.map((e) => {
      const findBranch = e?.branch?.find((f) => f?.id === branchId)
        ? true
        : false

      return {
        ...e,
        isBranch: findBranch,
      }
    }) || []

  return onBranch?.filter((f) => f.isBranch === true)
}

export function findUserBranch(userList, branchId) {
  return userList?.filter((f) => f?.branch.id === branchId)
}
