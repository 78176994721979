import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  TabList,
  Tabs,
  Tab,
  Text,
  TabPanel,
  TabPanels,
  Icon,
  HStack,
  Fade,
  Input,
  Avatar,
  FormControl,
  FormLabel,
  useColorModeValue,
  useRadio,
  useRadioGroup,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
  Image,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import InputField from 'Components/Horizons/fields/InputField'
import SelectField from 'Components/Horizons/fields/SelectField'
import TextField from 'Components/Horizons/fields/TextField'
import { useExpenses } from 'Hooks'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdAddCircle } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import Resizer from 'react-image-file-resizer'
import { useAppContext } from 'Context'
import { CloseIcon } from '@chakra-ui/icons'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { decodeUnit, encodeUnit } from 'Utils/currency'

const _ = require('underscore')

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      'base64'
    )
  })
function ModalImage({ item, handleRemoveItem, number }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Center
        position='relative'
        overflow='hidden'
        height='auto'
        borderRadius='20px'
        box-shadow='0 3px 10px rgb(0 0 0 / 0.3)'
      >
        <Avatar
          src={item}
          w={'100px'}
          h={'100px'}
          borderRadius={'1em'}
          onClick={onOpen}
        />
        <Center
          position='absolute'
          borderRadius='20px'
          z-index='1'
          transition='all 0.4s ease 0s'
        >
          <Icon
            w={'15px'}
            h={'15px'}
            position={'absolute'}
            top={'-9em'}
            left={'5.5em'}
            borderRadius={'full'}
            px={'0'}
            as={CloseIcon}
            color={'white'}
            fontSize={'5px'}
            bg={'red.300'}
            p={'.25em'}
            cursor='pointer'
            onClick={() => handleRemoveItem(number)}
          />
        </Center>
      </Center>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <Image src={item} />
        </ModalContent>
      </Modal>
    </>
  )
}
function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        borderWidth='1px'
        borderColor={'blackAlpha.300'}
        bgColor={'white'}
        fontSize={{ base: '14px', md: '16px', lg: '16px' }}
        color={'#1B2559'}
        textAlign={'center'}
        _checked={{
          bg: 'inventory.500',
          color: 'white',
          borderColor: 'inventory.500',
        }}
        _focus={{
          boxShadow: 'outline',
          borderColor: 'none',
        }}
        borderRadius={'.55em'}
        p={'.45em'}
        px={'1em'}
      >
        {props.children}
      </Box>
    </Box>
  )
}

function FormExpenses({ id, data, ids }) {
  const { user, setMessage, setError } = useAppContext()
  const { Category, createExpenses, updateExpenses, getExpensesCategorys } =
    useExpenses()
  const [vat, setVat] = useState(0)
  const [grandTotal, setGrandTotal] = useState(0)
  const [isSubmit, setIsSubmit] = useState(false)
  const [file, setFile] = useState([])
  const [total, setTotal] = useState()
  const [vatType, setVatType] = useState()
  const [isFadePage, setIsFadePage] = useState(true)
  const Navigate = useNavigate()
  const [activeBullets, setActiveBullets] = useState({
    product: true,
    media: false,
  })

  useEffect(() => {
    getExpensesCategorys()
  }, [getExpensesCategorys])

  const productTab = useRef()
  const mediaTab = useRef()
  const hiddenFileInput = useRef(null)

  async function handleUploadChange(event) {
    _.forEach(event.target.files, async (file) => {
      const ThisFile = await resizeFile(file)
      if (ThisFile) {
        setFile((prev) => {
          return [...prev, ThisFile]
        })
      }
    })
  }
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')

  function handleRemoveItem(itm) {
    setFile(file?.filter((item, index) => index !== itm))
  }
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      detail: '',
      reason: '',
    },
  })

  function onSubmit(value) {
    setIsSubmit(true)
    const ExpensesImage = file || ''
    if (id) {
      updateExpenses(
        id,
        {
          ...value,
          total: encodeUnit(value.total).toString(),
          grandTotal: encodeUnit(value.grandTotal).toString(),
          totalVat: encodeUnit(value.totalVat).toString(),
        },
        ExpensesImage
      )
        .then(() => {
          Navigate('/expenses/list')
          setMessage({
            type: 'success',
            message: 'อัพเดตรายการสำเร็จ',
          })
          setIsSubmit(false)
        })
        .catch((e) => {
          setError({
            code: 'Expenses',
            message: e?.message,
          })
          setIsSubmit(false)
        })
    } else {
      createExpenses(
        ids,
        {
          ...value,
          total: encodeUnit(value.total).toString(),
          grandTotal: encodeUnit(value.grandTotal).toString(),
          totalVat: encodeUnit(value.totalVat).toString(),
        },
        ExpensesImage
      )
        .then(() => {
          Navigate('/expenses/list')
          setMessage({
            type: 'success',
            message: 'เพิ่มรายการสำเร็จ',
          })
          setIsSubmit(false)
        })
        .catch((e) => {
          setError({
            code: 'Expenses',
            message: e?.message,
          })
          setIsSubmit(false)
        })
    }
  }

  function CalVat({ total, vatType }) {
    if (vatType === 'รวมภาษี') {
      const cal = (total * 7) / 107
      const calGrand =
        parseFloat(parseFloat(total)?.toFixed(2)) - parseFloat(cal?.toFixed(2))

      setVat(`${cal?.toFixed(2)}`)
      setGrandTotal(`${parseFloat(calGrand)?.toFixed(2)}`)
      setValue(`totalVat`, cal?.toFixed(2))
      setValue(`grandTotal`, parseFloat(calGrand)?.toFixed(2))
    } else if (vatType === 'ไม่รวมภาษี') {
      const cal = (total * 7) / 100
      const calGrand =
        parseFloat(parseFloat(total)?.toFixed(2)) + parseFloat(cal?.toFixed(2))

      setVat(`${cal?.toFixed(2)}`)
      setGrandTotal(`${parseFloat(calGrand)?.toFixed(2)}`)
      setValue(`totalVat`, cal?.toFixed(2))
      setValue(`grandTotal`, parseFloat(calGrand)?.toFixed(2))
    } else if (vatType === 'ไม่มีภาษี') {
      const cal = total * 0
      const calGrand =
        parseFloat(parseFloat(total)?.toFixed(2)) + parseFloat(cal?.toFixed(2))

      setVat(`${cal?.toFixed(2)}`)
      setGrandTotal(`${parseFloat(calGrand)?.toFixed(2)}`)
      setValue(`totalVat`, cal?.toFixed(2))
      setValue(`grandTotal`, parseFloat(calGrand)?.toFixed(2))
    }
  }

  function handleChange(e) {
    setValue('vatType', e)
    setVatType(e)
    CalVat({ total: total, vatType: e })
  }

  const { getRootProps, getRadioProps } = useRadioGroup({
    defaultValue: 'ไม่มีภาษี',
    onChange: handleChange,
    value: vatType ? vatType : 'ไม่มีภาษี',
  })
  const group = getRootProps()

  useEffect(() => {
    if (data) {
      setValue('category', data?.category)
      setValue('Date', data?.Date)
      setValue('name', data?.name)
      setValue('detail', data?.detail)
      setValue('reason', data?.reason)
      setValue('vatType', data?.vatType)
      setValue('total', decodeUnit(data?.total))
      setValue('grandTotal', decodeUnit(data?.grandTotal))
      setValue('totalVat', decodeUnit(data?.totalVat))
      setValue('branch', data?.branch)
      setFile(data?.image)
      setVatType(data?.vatType)
      setTotal(decodeUnit(data?.total))
      setVat(decodeUnit(data?.totalVat))
      setGrandTotal(decodeUnit(data?.grandTotal))
    }
    setValue('branch', user?.branchSelect?.id)
    setValue('createdBy', user?.uid)
    setVatType('ไม่มีภาษี')
    CalVat({ total: total, vatType: vatType })
    setIsFadePage(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, user])
  const options = ['รวมภาษี', 'ไม่รวมภาษี', 'ไม่มีภาษี']
  return (
    <>
      <Flex direction='column' minH='100vh' align='center' position='relative'>
        <Box
          h={{ base: '0px', sm: '0px', md: '25vh' }}
          bg='expenses.700'
          position='absolute'
          w={{ sm: '100vw', md: '100%', lg: '100%' }}
          borderRadius={{ sm: '0px', md: '30px', lg: '30px' }}
        ></Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            variant='unstyled'
            mt={{ sm: '-10px', md: '4rem', lg: '4rem' }}
            zIndex='0'
            display='flex'
            flexDirection='column'
          >
            <TabList
              display={{ sm: 'none', md: 'flex' }}
              align='center'
              alignSelf='center'
              justifySelf='center'
            >
              <Tab
                ref={productTab}
                _focus='none'
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    product: true,
                    media: false,
                    pricing: false,
                  })
                }
              >
                <Flex
                  zIndex='base'
                  direction='column'
                  justify='center'
                  align='center'
                  position='relative'
                  _before={{
                    content: "''",
                    width: { sm: '120px', md: '250px', lg: '300px' },
                    height: '3px',
                    bg: activeBullets.media ? 'white' : '#60101C',
                    left: { sm: '12px', md: '28px' },
                    top: {
                      sm: activeBullets.product ? '6px' : '4px',
                      md: null,
                    },
                    position: 'absolute',
                    bottom: activeBullets.product ? '40px' : '38px',

                    transition: 'all .3s ease',
                  }}
                >
                  <Box
                    zIndex='base'
                    border='2px solid'
                    borderColor={activeBullets.product ? 'white' : '#375C38'}
                    bg='expenses.900'
                    w='16px'
                    h='16px'
                    mb='8px'
                    borderRadius='50%'
                  />
                  <Text
                    color={activeBullets.product ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.product ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    ค่าใช้จ่าย
                  </Text>
                </Flex>
              </Tab>
              <Tab
                disabled
                ref={mediaTab}
                _focus='none'
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    product: true,
                    media: true,
                    pricing: false,
                  })
                }
              >
                <Flex
                  direction='column'
                  justify='center'
                  align='center'
                  position='relative'
                >
                  <Box
                    zIndex='base'
                    border='2px solid'
                    borderColor={activeBullets.media ? 'white' : 'white'}
                    bg='expenses.900'
                    w='16px'
                    h='16px'
                    mb='8px'
                    borderRadius='50%'
                  />
                  <Text
                    color={activeBullets.media ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.media ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    รายละเอียดค่าใช้จ่าย
                  </Text>
                </Flex>
              </Tab>
            </TabList>
            <TabPanels
              mt={{ base: '0px', sm: '0px', md: '24px' }}
              maxW={{ md: '90%', lg: '100%' }}
              mx={{ base: 'auto', md: '0px', lg: 'auto' }}
            >
              <TabPanel
                w={{ sm: '100vw', md: '670px', lg: '850px' }}
                p='0px'
                mx='auto'
              >
                <Card
                  p={{ base: '15px', sm: '15px', md: '30px' }}
                  px={{ base: '30px', sm: '30px', md: '30px' }}
                  as={Fade}
                  in={!isFadePage}
                  pb={{ base: '30px', sm: '30px', md: '30px' }}
                >
                  <Box
                    pb='0.3rem'
                    zIndex='base'
                    color='expenses.500'
                    onClick={() => Navigate('/expenses/list')}
                    w='max-content'
                    display={{ sm: 'flex', md: 'none' }}
                  >
                    <Text
                      display={{ sm: 'flex', md: 'none' }}
                      fontSize='24px'
                      cursor='pointer'
                    >
                      <ChevronLeftIcon />
                    </Text>
                  </Box>
                  <HStack>
                    <Box>
                      <Text fontSize={'xl'} color={'expenses.500'}>
                        ค่าใช้จ่าย
                      </Text>
                      <Text fontSize={'sm'} color={'blackAlpha.600'}>
                        {id ? id : ids}
                      </Text>
                    </Box>
                  </HStack>
                  <Box mt={'1em'}>
                    <FormControl
                      id={'total'}
                      isInvalid={!total}
                      key={'total'}
                      mb={'1em'}
                    >
                      <Flex direction='column' mb={'0px'}>
                        <FormLabel
                          display='flex'
                          ms='10px'
                          htmlFor={id}
                          fontSize='sm'
                          color={textColorPrimary}
                          fontWeight='bold'
                          _hover={{ cursor: 'pointer' }}
                        >
                          ยอดรวมทั้งหมด
                        </FormLabel>
                        <Input
                          value={total}
                          onChange={(e) => {
                            const value = e.target.value
                            setValue('total', value)
                            setTotal(value)
                            CalVat({ total: value, vatType: vatType })
                          }}
                          type={'number'}
                          id={id}
                          defaultValue=''
                          fontWeight='500'
                          borderRadius={{
                            base: '10px',
                            sm: '10px',
                            md: '10px',
                            lg: '16px',
                          }}
                          placeholder='กรุณากรอกยอดรวมทั้งหมด'
                          _placeholder={{
                            fontWeight: '400',
                            color: 'secondaryGray.600',
                          }}
                          variant='main'
                          h='44px'
                          maxh='44px'
                        />
                      </Flex>
                    </FormControl>
                    <SimpleGrid columns={{ base: 1, md: 2 }} gap={'1em'}>
                      <HStack
                        w='full'
                        justifyContent={{ base: 'center', md: 'start' }}
                        {...group}
                      >
                        {options?.map((value) => {
                          const radio = getRadioProps({ value })
                          return (
                            <RadioCard key={value} {...radio}>
                              {value}
                            </RadioCard>
                          )
                        })}
                      </HStack>
                      <SelectField
                        control={control}
                        errors={errors}
                        id={'category'}
                        name={'category'}
                        borderRadius={{
                          base: '10px',
                          sm: '10px',
                          md: '10px',
                          lg: '16px',
                        }}
                        label={'หมวดหมู่'}
                        placeholder='เลือกหมวดหมู่'
                        path={'/expenses/category/add'}
                        required={{
                          value: true,
                          message: 'กรุณาเลือกหมวดหมู่',
                        }}
                        options={
                          Category.length > 0
                            ? Category?.map((item) => item?.name)
                            : []
                        }
                      />
                    </SimpleGrid>
                    <Box>
                      <HStack justifyContent={'space-between'}>
                        <Text fontSize={'sm'}>ภาษีมูลค่าเพิ่ม 7%</Text>
                        <Text fontSize={'sm'}>{vat > 1 ? vat : `0.00`}</Text>
                      </HStack>
                      <HStack justifyContent={'space-between'}>
                        <Text fontSize={'sm'}>ยอดไม่รวมภาษีมูลค่าเพิ่ม</Text>
                        <Text fontSize={'sm'} fontWeight={'extrabold'}>
                          {grandTotal > 1 ? grandTotal : `0.00`}
                        </Text>
                      </HStack>
                    </Box>
                    <Flex justify='space-between' mt='30px'>
                      <Button
                        variant='light'
                        fontSize='sm'
                        borderRadius='16px'
                        w={{ base: '128px', md: '148px' }}
                        h='46px'
                        onClick={() => Navigate('/expenses/list')}
                      >
                        ย้อนกลับ
                      </Button>
                      <Button
                        colorScheme='btn'
                        color='#ffff'
                        fontSize='sm'
                        borderRadius='16px'
                        w={{ base: '128px', md: '148px' }}
                        h='46px'
                        ms='auto'
                        isLoading={isSubmit}
                        variant='solid'
                        onClick={async () => {
                          const resultType = await trigger('total')
                          const resultVat = await trigger('category')
                          if (!resultType || !resultVat) {
                            return errors
                          } else {
                            return mediaTab.current.click()
                          }
                        }}
                      >
                        ถัดไป
                      </Button>
                    </Flex>
                  </Box>
                </Card>
              </TabPanel>
              <TabPanel
                w={{ sm: '100vw', md: '670px', lg: '850px' }}
                p='0px'
                mx='auto'
              >
                <Card
                  p={{ base: '15px', sm: '15px', md: '30px' }}
                  px={{ base: '30px', sm: '30px', md: '30px' }}
                  as={Fade}
                  in={!isFadePage}
                  pb={{ base: '30px', sm: '30px', md: '30px' }}
                >
                  <Box>
                    <Box
                      pb='0.3rem'
                      zIndex='base'
                      color='expenses.500'
                      onClick={() => Navigate('/expenses/list')}
                      w='max-content'
                      display={{ sm: 'flex', md: 'none' }}
                    >
                      <Text
                        display={{ sm: 'flex', md: 'none' }}
                        fontSize='24px'
                        cursor='pointer'
                      >
                        <ChevronLeftIcon />
                      </Text>
                    </Box>
                    <Text fontSize={'xl'} color={'expenses.500'}>
                      รายละเอียดค่าใช้จ่าย
                    </Text>
                    <Box>
                      <SimpleGrid
                        mt='0.5em'
                        columns={{ base: 1, md: 2 }}
                        gap={{ sm: '0rem', md: '1em', lg: '1em' }}
                      >
                        <InputField
                          control={control}
                          errors={errors}
                          type={'date'}
                          required={{
                            value: true,
                            message: 'กรุณากรอกวัน',
                          }}
                          label={'วันที่'}
                          name={'Date'}
                        />
                        <InputField
                          control={control}
                          errors={errors}
                          type={'text'}
                          name={'name'}
                          label={'ชื่อผู้เบิกจ่าย/ชื่อโปรเจค'}
                          required={{
                            value: true,
                            message: 'กรุณากรอกชื่อผู้เบิกจ่าย/ชื่อโปรเจค',
                          }}
                        />
                        <TextField
                          variant={'no-effects'}
                          control={control}
                          errors={errors}
                          name={'detail'}
                          label={'รายละเอียด'}
                          required={{
                            value: false,
                          }}
                        />
                        <TextField
                          variant={'no-effects'}
                          control={control}
                          errors={errors}
                          name={'reason'}
                          label={'หมายเหตุ'}
                          required={{
                            value: false,
                          }}
                        />
                      </SimpleGrid>
                    </Box>
                    <SimpleGrid
                      columns={{ base: 2, md: 5, lg: 7 }}
                      justifyItems={{ base: 'center', md: 'start' }}
                      gap={'1em'}
                    >
                      <Button
                        w={'100px'}
                        h={'100px'}
                        border={'1px solid #E0E5F2'}
                        borderStyle={'dashed'}
                        borderRadius={'1em'}
                        onClick={() => hiddenFileInput.current?.click()}
                      >
                        <Icon
                          fontSize={'2xl'}
                          as={MdAddCircle}
                          color={'expenses.500'}
                        />
                      </Button>
                      {file?.length > 0 &&
                        file?.map((item, index) => (
                          <ModalImage
                            key={index}
                            item={item}
                            number={index}
                            handleRemoveItem={handleRemoveItem}
                          />
                        ))}
                    </SimpleGrid>
                    <Input
                      display={'none'}
                      key={file}
                      type={'file'}
                      onChange={handleUploadChange}
                      ref={hiddenFileInput}
                      multiple
                    />
                  </Box>
                  <Flex justify='space-between' mt='30px'>
                    <Button
                      variant='light'
                      fontSize='sm'
                      borderRadius='16px'
                      w={{ base: '128px', md: '148px' }}
                      h='46px'
                      onClick={() => productTab.current.click()}
                    >
                      ย้อนกลับ
                    </Button>
                    <Button
                      type='submit'
                      color='#ffff'
                      fontSize='sm'
                      borderRadius='16px'
                      w={{ base: '128px', md: '148px' }}
                      h='46px'
                      ms='auto'
                      isLoading={isSubmit}
                      variant='solid'
                      colorScheme={'btn'}
                      loadingText={'Loading...'}
                    >
                      บันทึก
                    </Button>
                  </Flex>
                </Card>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </form>
      </Flex>
    </>
  )
}

export { FormExpenses }
