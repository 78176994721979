import { calOrderCostPaidRefund } from './cost'

const _ = require('underscore')

export function calRefundOrder(order) {
  const refund = _.filter(order, (itm) => itm.status === 'refund')
  const totalRefund = _.pluck(refund, 'total').reduce(
    (memo, num) => memo + num,
    0
  )
  const refundCost = calOrderCostPaidRefund(refund)
  return {
    total: totalRefund,
    cost: refundCost,
  }
}
