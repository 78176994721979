import React, { useState, useCallback, useEffect } from 'react'
import {
  Flex,
  Box,
  Text,
  Center,
  Button,
  VStack,
  Spinner,
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import Card from 'Components/Horizons/card/Card'
import { IoChevronBackOutline } from 'react-icons/io5'
import { useOrder } from 'Hooks'
import { CardRefund } from './components/card'
import { RefundItem } from './components/refundItem'
import { calAmountTotal, calGrandTotal } from './Utils/calculate'
import { decodeUnit, encodeUnit } from 'Utils/currency'

const _ = require('underscore')
function Refund({ user }) {
  const [first, setFirst] = useState(true)
  const [second, setSecond] = useState(false)
  const navigate = useNavigate()

  const { id } = useParams()
  const { getOrderById, refundOrder, getOrderByRefId } = useOrder()
  const [orderData, setOrderData] = useState(null)
  const [orderRef, setOrderRef] = useState(null)
  const [InCart, setInCart] = useState([])
  const [cartItems, setCartItems] = useState([])
  const [items, setItems] = useState(null)
  const [isCheckItem, setIsCheckItem] = useState([])
  const [orders, setOrder] = useState()
  const [isSubmit, setIsSubmit] = useState(false)
  const addCart = (product, pAmount) => {
    let inCart = _.pluck(cartItems, 'id')
    if (product.unit === 'weight' && parseFloat(pAmount) === 0) {
      let inCart = _.pluck(cartItems, 'id')
      if (_.contains(inCart, product.id)) {
        let removeItem = _.reject(cartItems, (item) => item.id === product.id)
        return setCartItems(removeItem)
      }
    }

    if (_.contains(inCart, product.id)) {
      let items = cartItems.map((item) => {
        if (item.id === product.id) {
          return product?.unit === 'piece'
            ? { ...item, amount: item.amount + 1 }
            : { ...item, amount: pAmount ? pAmount : 0.01 }
        } else {
          return { ...item }
        }
      })
      setCartItems(items)
    } else {
      const dataToState =
        product?.unit === 'piece'
          ? [...cartItems, { ...product, amount: 1 }]
          : [...cartItems, { ...product, amount: pAmount ? pAmount : 0.01 }]
      setCartItems(dataToState)
    }
  }

  const removeCart = (product) => {
    setCartItems(
      _.reduce(
        cartItems,
        (val, item) => {
          if (item.id === product.id) {
            return [...val, { ...item, amount: item.amount - 1 }]
          } else {
            return [...val, item]
          }
        },
        []
      )
    )
  }

  //fetch Data
  const getOrderRef = useCallback(() => {
    if (id) {
      getOrderByRefId(id).then((e) => {
        setOrder(e)
      })
    }
    //eslint-disable-next-line
  }, [id])

  const fetchData = useCallback(async () => {
    await getOrderById(id).then(async (data) => {
      if (data) {
        setOrderData(data)
        setCartItems(
          data?.items?.map((item) => ({
            ...item,
            amount: parseFloat(item.amount) / 100,
          }))
        )
        setInCart(
          data?.items?.map((item) => ({
            ...item,
            amount: parseFloat(item.amount) / 100,
          }))
        )
        if (data?.branch?.id === user?.branchSelect?.id) {
        } else {
          return navigate('/pos/history')
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrderById, id, user])

  useEffect(() => {
    if (orders) {
      setOrderRef(_.filter(orders, (itm) => itm.ref === id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders])

  useEffect(() => {
    fetchData()
    getOrderRef()
    //eslint-disable-next-line
  }, [])

  function handleIsCheck(item) {
    setIsCheckItem((prev) => {
      const isCheck = _.find(prev, (itm) => itm.id === item.id)
      if (isCheck) {
        return prev?.map((itm) => (itm.id === item.id ? { ...item } : itm))
      }
      return [...prev, { ...item }]
    })
  }

  function handleCancelCheck(id) {
    setIsCheckItem(isCheckItem.filter((itm) => itm.id !== id))
  }

  function onSubmit() {
    setIsSubmit(true)
    const value = {
      ...orderData,
      items: isCheckItem.map((item) => ({
        ...item,
        amount: (item.amount * 100).toString(),
        total: encodeUnit(
          item.amount * parseFloat(decodeUnit(item.price))
        ).toString(),
        grandTotal: encodeUnit(
          item.amount * parseFloat(decodeUnit(item.price))
        ).toString(),
      })),
      total: encodeUnit(calAmountTotal(isCheckItem)).toString(),
      grandTotal: encodeUnit(calGrandTotal(isCheckItem)).toString(),
      ref: id,
      status: 'refund',
      createdAt: new Date(),
    }

    refundOrder(value).then(() => {
      setIsCheckItem([])
      navigate('/pos/history')
      setIsSubmit(false)
    })
  }
  return (
    <>
      <Center>
        <Card
          h={{ base: 'auto', sm: 'auto', md: 'auto', lg: 'auto' }}
          w={{ base: '100%', sm: '100%', md: '37rem', lg: '37rem' }}
          borderRadius={{ base: '0px', sm: '0px', md: '20px', lg: '20px' }}
          p={{ base: '0px', sm: '0px', md: '30px', lg: '30px' }}
        >
          <Flex flexDirection='column'>
            <Box w='100%' h='7rem' bg='pos.100' color='white'>
              <Center
                position={'absolute'}
                h='7rem'
                pl='1rem'
                cursor={'pointer'}
                onClick={() => {
                  if (first === true) {
                    navigate('/pos/history')
                  } else if (second === true) {
                    setFirst(true)
                    setSecond(false)
                  }
                }}
              >
                <Text zIndex='overlay'>
                  <IoChevronBackOutline fontSize='28px' />
                </Text>
              </Center>
              <Center w='100%' h='7rem' flexDirection='column'>
                <Text fontWeight='normal'>คืนเงิน</Text>
              </Center>
            </Box>
            {first === true && (
              <>
                <Flex
                  justifyContent='flex-end'
                  mt='1em'
                  pb='10px'
                  px='10px'
                  color='#03A9F4'
                  fontWeight='normal'
                ></Flex>
                {cartItems.length > 0 ? (
                  <VStack spacing='1em' w='100%'>
                    {cartItems?.map((item, index) => (
                      <CardRefund
                        data={item}
                        inCart={InCart}
                        isCheckItem={isCheckItem}
                        setFirst={setFirst}
                        setSecond={setSecond}
                        setItems={setItems}
                        orderRef={_.pluck(orderRef, 'items')}
                        key={index}
                      />
                    ))}
                  </VStack>
                ) : (
                  <Center>
                    <Spinner size='md' />
                  </Center>
                )}
                <Flex
                  flexDirection={'row'}
                  justify='space-between'
                  px={{ base: '10px', sm: '10px', md: '0px' }}
                  py={{ base: '10px', sm: '10px', md: '0px' }}
                >
                  <Text fontSize={{ md: 'xl', lg: 'xl', xl: 'xl' }}>
                    รวมเป็นเงิน
                  </Text>
                  <Text fontSize={{ md: 'xl', lg: 'xl', xl: 'xl' }}>
                    {new Intl.NumberFormat('th-th', {
                      style: 'currency',
                      currency: 'THB',
                    }).format(calAmountTotal(isCheckItem))}
                  </Text>
                </Flex>
                <Button
                  display={{ base: 'none', sm: 'none', md: 'flex' }}
                  colorScheme='btn'
                  w='full'
                  mt={'1.25rem'}
                  borderRadius={'5px'}
                  fontSize={'16px'}
                  isLoading={isSubmit}
                  disabled={isSubmit || isCheckItem.length > 0 ? false : true}
                  onClick={() => {
                    if (!isSubmit) {
                      onSubmit()
                    }
                  }}
                >
                  คืนเงิน
                </Button>
                <Center
                  display={{ base: 'flex', sm: 'flex', md: 'none' }}
                  position='fixed'
                  bottom='0px'
                  left='0px'
                  p='0.3rem'
                  w='100%'
                >
                  <Button
                    colorScheme='btn'
                    w='full'
                    mt={'1.25rem'}
                    borderRadius={'5px'}
                    fontSize={'16px'}
                    isLoading={isSubmit}
                    disabled={isSubmit || isCheckItem.length > 0 ? false : true}
                    onClick={() => {
                      if (!isSubmit) {
                        onSubmit()
                      }
                    }}
                  >
                    คืนเงิน
                  </Button>
                </Center>
              </>
            )}
            {second === true && (
              <>
                <RefundItem
                  data={items}
                  addCart={addCart}
                  removeCart={removeCart}
                  setFirst={setFirst}
                  setSecond={setSecond}
                  handleIsCheck={handleIsCheck}
                  order={InCart}
                  orderRef={_.pluck(orderRef, 'items')}
                  handleCancelCheck={handleCancelCheck}
                  isSubmit={isSubmit}
                />
              </>
            )}
          </Flex>
        </Card>
      </Center>
    </>
  )
}

export default Refund
