import { ChevronLeftIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Flex,
  HStack,
  Image,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import ButtonResponsive from 'Components/Button/btn'
import Card from 'Components/Horizons/card/Card'
import { db } from 'Config/firebase'
import { useAppContext } from 'Context'
import { doc, updateDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  currency,
  decodeUnit,
  encodeUnit,
  switchTextPromotionType,
  switchTextPromotionTypeSecond,
  switchTextPromotionTypeThird,
} from 'Utils'
import { AmountProduct } from './components/amountProduct'
import { InfoModal } from './components/productModal'

export default function FreeListReceipt({ data, id }) {
  const [discount, setDiscount] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)
  const { user } = useAppContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      setDiscount(data)
    }
  }, [data, id])
  function handleClick(data) {
    setDiscount({
      ...discount,
      product: data,
      productAmount: data.unit === 'weight' ? 0 : 1,
    })
    return discount
  }

  function handleChangeInput(e) {
    e.preventDefault()
    let value = parseFloat(parseFloat(e.target.value)?.toFixed(2))
    setDiscount({
      ...discount,
      productAmount: parseFloat(value),
    })
  }

  function handleClickAdd() {
    setDiscount({
      ...discount,
      productAmount: discount?.productAmount + 1,
    })
  }

  function handleClickRemove() {
    setDiscount({
      ...discount,
      productAmount:
        discount?.productAmount <= 0 ? 0 : discount?.productAmount - 1,
    })
  }

  const { handleSubmit } = useForm()

  function onSubmit() {
    setIsSubmit(true)
    updateDoc(doc(db, 'Promotion', id), {
      ...discount,
      productAmount: encodeUnit(discount?.productAmount)?.toString(),
      valueDiscount: encodeUnit(discount?.valueDiscount)?.toString(),
      updatedAt: new Date(),
      updatedBy: {
        id: user?.uid,
        name: user?.name,
      },
    }).then(() => {
      setIsSubmit(false)
      navigate('/discount/store')
    })
  }

  return (
    <Center>
      <Card
        padding={{
          base: '10px 0px 30px 0px',
          sm: '10px 0px 30px 0px',
          md: '10px 20px 30px 20px',
        }}
        w={{ base: '100%', sm: '100%', md: '40rem' }}
        boxShadow={{
          base: 'none',
          sm: 'none',
          md: '14px 17px 40px 4px rgb(112 144 176 / 8%)',
        }}
      >
        <HStack w='100%'>
          <ChevronLeftIcon
            cursor='pointer'
            color='form.500'
            fontSize={'2xl'}
            onClick={() => navigate('/discount/store')}
          />
        </HStack>
        <VStack
          alignItems='start'
          px={{ base: '0em', sm: '0em', md: '2em' }}
          borderBottom='1px'
          borderColor='#E0E5F2'
          py='2em'
        >
          <HStack alignItems='flex-start'>
            <Text minW='5.8rem'>รูปแบบส่วนลด</Text>
            <Text>:</Text>
            <Text color='discount.500'>
              {switchTextPromotionType(data?.type)}
            </Text>
          </HStack>
          <HStack alignItems='flex-start'>
            <Text minW='5.8rem'>ระดับส่วนลด</Text>
            <Text>:</Text>
            <Text color='discount.500'>
              {switchTextPromotionTypeSecond(data?.typeDiscount)}
            </Text>
          </HStack>
          <HStack alignItems='flex-start'>
            <Text minW='5.8rem'>ฟรีสินค้าประเภท</Text>
            <Text>:</Text>
            <Text color='discount.500'>
              {switchTextPromotionTypeThird(data?.typeFree)}
            </Text>
          </HStack>
        </VStack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            borderBottom='1px'
            borderColor='#E0E5F2'
            py='2em'
            px={{ base: '0em', sm: '0em', md: '2em' }}
          >
            <HStack>
              <Text
                fontSize={{ base: 'md', sm: 'md', md: 'lg' }}
                fontWeight='bold'
              >
                มูลค่าส่วนลด
              </Text>
            </HStack>
            <Text color='table.100' mt='1rem'>
              โปรดระบุส่วนลด
            </Text>
            <VStack alignItems='flex-start' w='100%' mt='1rem'>
              <Text>ชื่อโปรโมชั่น</Text>
              <Input
                variant='main'
                value={discount?.discountName || null}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  e.preventDefault()
                  let value = e.target.value
                  setDiscount({ ...discount, discountName: value })
                }}
              />
            </VStack>
            <VStack mt='1rem' justifyContent='start' alignItems='flex-start'>
              <RadioGroup
                onChange={(e) => {
                  setDiscount({ ...discount, valueDiscountType: e })
                }}
                value={discount?.valueDiscountType}
              >
                <Stack direction='row'>
                  <Radio colorScheme='green' value='Percentage'>
                    เปอร์เซ็นต์
                  </Radio>
                  <Radio colorScheme='green' value='Bath'>
                    บาท
                  </Radio>
                </Stack>
              </RadioGroup>
              <Input
                variant={'main'}
                type={'number'}
                step={'any'}
                min={0}
                value={discount?.valueDiscount || null}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  e.preventDefault()
                  let value = parseFloat(parseFloat(e.target.value)?.toFixed(2))
                  setDiscount({ ...discount, valueDiscount: value })
                }}
              />
            </VStack>

            <Center w='100%' mt='1rem'>
              <VStack spacing='25px' w='100%'>
                <VStack alignItems='flex-start' w='100%'>
                  <Text>รายละเอียดโปรโมชั่น</Text>
                  <Textarea
                    border='1px solid #e0e5f2'
                    borderRadius={{
                      base: '10px',
                      sm: '10px',
                      md: '10px',
                      lg: '16px',
                    }}
                    variant={'no-effects'}
                    value={discount?.discountDetail || null}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      e.preventDefault()
                      let value = e.target.value
                      setDiscount({ ...discount, discountDetail: value })
                    }}
                  />
                </VStack>
                <Flex justifyContent='space-between' w='100%'>
                  <Text>เปิด-ปิด </Text>
                  <Switch
                    defaultChecked={discount?.status || false}
                    isChecked={discount?.status}
                    colorScheme='teal'
                    onChange={(e) => {
                      let value = e.target.checked
                      setDiscount({ ...discount, status: value })
                    }}
                  />
                </Flex>
              </VStack>
            </Center>
          </Box>

          <Box
            borderBottom='1px'
            borderColor='#E0E5F2'
            py='2em'
            px={{ base: '0em', sm: '0em', md: '2em' }}
          >
            <Text
              pb='1rem'
              fontSize={{ base: 'md', sm: 'md', md: 'lg' }}
              fontWeight='bold'
            >
              เงื่อนไข
            </Text>
            <InfoModal
              handleClick={handleClick}
              discount={discount}
              setDiscount={setDiscount}
              label={discount?.product ? 'เปลี่ยน' : '+ เลือกรายการสินค้า'}
              children={
                discount?.product ? (
                  <Box
                    borderWidth='1px'
                    borderRadius='6px'
                    boxShadow='md'
                    w='full'
                  >
                    <Center flexDirection='column' h='100%'>
                      <Image
                        borderTopRadius={'6px'}
                        h={{
                          base: '6rem',
                          sm: '6rem',
                          md: '6rem',
                          lg: '6rem',
                          xl: '9rem',
                        }}
                        w='full'
                        objectFit='cover'
                        src={discount?.product?.img}
                        alt={discount?.product?.sku}
                      />
                      <Text pl='0.5rem' w='100%' textAlign='start' pt='0.5rem'>
                        {discount?.product?.name}
                      </Text>
                      <Text pl='0.5rem' w='100%' textAlign='start'>
                        รหัสสินค้า : {discount?.product?.sku}
                      </Text>
                      <Text
                        pl='0.5rem'
                        w='100%'
                        textAlign='start'
                        color='green'
                      >
                        {currency(
                          decodeUnit(discount?.product?.price),
                          2,
                          true
                        )}
                      </Text>
                    </Center>
                  </Box>
                ) : null
              }
            />
            <Center mt='1.5rem'>
              <AmountProduct
                disabled={discount?.product ? false : true}
                handleAdd={handleClickAdd}
                handleRemove={handleClickRemove}
                handleChangeInput={handleChangeInput}
                label={
                  discount?.productAmount > 0 ? discount?.productAmount : 0
                }
                unit={discount?.product?.unit}
                value={discount?.productAmount}
              />
            </Center>
          </Box>
          <Box pt='1rem'>
            <HStack
              spacing={{ base: '0rem', sm: '0rem', md: '2rem' }}
              flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
              alignItems='flex-start'
            >
              <Text>สร้างโดย : {data?.createdBy?.name} </Text>
              <Text>
                วันที่ : {data?.createdAt?.toDate().toLocaleString('en-US')}
              </Text>
            </HStack>
            <HStack
              spacing={{ base: '0rem', sm: '0rem', md: '2rem' }}
              flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
              alignItems='flex-start'
            >
              <Text>
                แก้ไขโดย : {data?.updatedBy ? data?.updatedBy?.name : '-'}
              </Text>
              <Text>
                วันที่ :{' '}
                {data?.updatedAt
                  ? data?.updatedAt?.toDate().toLocaleString('en-US')
                  : '-'}
              </Text>
            </HStack>
          </Box>
          <HStack py='2em' justifyContent='end'>
            <ButtonResponsive
              disabled={discount?.discountName ? false : true}
              label={'บันทึก'}
              type={'submit'}
              isLoading={isSubmit}
              loadingText={'กำลังบันทึก...'}
            />
          </HStack>
        </form>
      </Card>
    </Center>
  )
}
