import { db } from 'Config/firebase'
import { useAppContext } from 'Context'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
} from 'firebase/firestore'
import { useCallback, useState } from 'react'
import _ from 'underscore'

export function useCategory() {
  const [category, setCategory] = useState([])
  const [isLoading, setLoading] = useState(true)
  const { setError, setMessage, user } = useAppContext()

  //------- ref -------//
  const CategoryRef = collection(db, 'Category')

  //------- ref -------//

  const getCategoryAll = useCallback(async () => {
    const categoryRef = query(CategoryRef, orderBy('createdAt', 'asc'))
    const getData = await getDocs(categoryRef)
    const data = await _.map(getData.docs, (doc) => {
      return {
        id: doc?.id,
        ...doc?.data(),
      }
    })
    setCategory(data)
    setLoading(false)
    // eslint-disable-next-line
  }, [])

  function createCategory(data) {
    try {
      return addDoc(CategoryRef, {
        ...data,
        createdAt: new Date(),
        createdBy: { id: user?.uid, name: user?.name },
      }).then(() => {
        setMessage({
          type: 'success',
          message: 'เพิ่มหมวดหมู่สินค้าสำเร็จ',
        })
      })
    } catch (error) {
      setError({
        code: 'Category',
        message: error.message,
      })
    }
  }

  function updateCategory(data, id) {
    const CategoryDoc = doc(db, 'Category', id)
    try {
      return updateDoc(CategoryDoc, {
        ...data,
        updatedAt: new Date(),
        updatedBy: { id: user?.uid, name: user?.name },
      }).then(() => {
        setMessage({
          type: 'success',
          message: 'อัปเดตหมวดหมู่สำเร็จ',
        })
      })
    } catch (error) {
      setError({
        code: 'Category',
        message: error.message,
      })
    }
  }

  function deleteCategory(id) {
    const CategoryDoc = doc(db, 'Category', id)
    try {
      return deleteDoc(CategoryDoc).then(() => {
        setMessage({
          type: 'success',
          message: 'ลบหมวดหมู่สินค้าสำเร็จ',
        })
      })
    } catch (error) {
      setError({
        code: 'Category',
        message: error.message,
      })
    }
  }

  async function getCategory(id) {
    const CategoryDoc = doc(db, 'Category', id)
    return (await getDoc(CategoryDoc)).data()
  }

  return {
    category,
    isLoading,
    createCategory,
    updateCategory,
    deleteCategory,
    getCategory,
    getCategoryAll,
  }
}
