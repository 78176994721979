import { Box, Flex, Stack } from '@chakra-ui/react'
import SidebarBrand from './brand'
import SidebarLinks from './link'

function SidebarContent(props) {
  const { links, user, onClose, responsive, setOpenStage, openStage } = props

  return (
    <Flex
      direction='column'
      minH='100%'
      height='max-content'
      pt={{ sm: '5px', md: '5px', lg: '5px', xl: '25px' }}
      borderRadius='30px'
    >
      <Box>
        <SidebarBrand user={user} />
      </Box>
      <Stack
        direction='column'
        mb='auto'
        mt={{ base: '8px', sm: '8px', md: '8px', lg: '8px', xl: '8px' }}
        overflowX='auto'
        maxH={{ base: '80vh', md: '80vh', lg: '75vh', xl: '75vh' }}
      >
        <Box ps='20px' pe={{ md: '16px', '2xl': '1px' }} pb='1rem'>
          <SidebarLinks
            onClose={onClose}
            links={links}
            user={user}
            responsive={responsive}
            setOpenStage={setOpenStage}
            openStage={openStage}
          />
        </Box>
      </Stack>
    </Flex>
  )
}

export default SidebarContent
