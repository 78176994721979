import { db } from 'Config/firebase'
import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import moment from 'moment'
import { decodeUnit } from 'Utils/currency'
import _ from 'underscore'

const prevDate = new Date()
prevDate.setHours(0)
prevDate.setMinutes(0)
prevDate.setSeconds(0)
prevDate.setMilliseconds(0)

const nextDate = new Date()
nextDate.setDate(nextDate.getDate() + 1)
nextDate.setHours(0)
nextDate.setMinutes(0)
nextDate.setSeconds(0)
nextDate.setMilliseconds(0)

const prevMonthDate = new Date()
prevMonthDate.setDate(1)
prevMonthDate.setHours(0)
prevMonthDate.setMinutes(0)
prevMonthDate.setSeconds(0)
prevMonthDate.setMilliseconds(0)

const nextMonth = new Date()
nextMonth.setMonth(nextMonth.getMonth() + 1)
nextMonth.setDate(0)
nextMonth.setHours(23)
nextMonth.setMinutes(59)
nextMonth.setSeconds(59)
nextMonth.setMilliseconds(59)

export async function queryOrderBranch({
  month,
  startDate,
  endDate,
  between,
  prevMonth,
  setData,
  currentDate,
  currentMonth,
  user,
  startDateTime,
  endDateTime,
  dateTime,
  setWork,
  setUserList,
  setExpenses,
}) {
  const start = new Date(moment(startDate).startOf('day'))
  const dateEnd = new Date(moment(endDate).endOf('day'))
  const startMonth = new Date(moment(month).startOf('month'))
  const endMonth = new Date(moment(month).endOf('month'))

  const qOrder = prevMonth
    ? query(
        collection(db, 'Orders'),
        where('createdAt', '>=', startMonth),
        where('createdAt', '<=', endMonth),
        where('branch.id', '==', user.branchSelect?.id),
        orderBy('createdAt', 'desc')
      )
    : between
    ? query(
        collection(db, 'Orders'),
        where('createdAt', '>=', start),
        where('createdAt', '<=', dateEnd),
        where('branch.id', '==', user.branchSelect?.id),
        orderBy('createdAt', 'desc')
      )
    : currentMonth
    ? query(
        collection(db, 'Orders'),
        where('createdAt', '>=', prevMonthDate),
        where('createdAt', '<=', nextMonth),
        where('branch.id', '==', user.branchSelect?.id),
        orderBy('createdAt', 'desc')
      )
    : dateTime
    ? query(
        collection(db, 'Orders'),
        where('createdAt', '>=', startDateTime),
        where('createdAt', '<=', endDateTime),
        where('branch.id', '==', user.branchSelect?.id),
        orderBy('createdAt', 'desc')
      )
    : currentDate &&
      query(
        collection(db, 'Orders'),
        where('createdAt', '>=', prevDate),
        where('createdAt', '<=', nextDate),
        where('branch.id', '==', user.branchSelect?.id),
        orderBy('createdAt', 'desc')
      )

  if (currentDate || currentMonth) {
    onSnapshot(qOrder, (snapShot) => {
      let dataValue = []
      snapShot.forEach((doc) => {
        return dataValue.push({
          ...doc.data(),
          // branch: doc.data()?.branch?.name,
          totalDiscount: decodeUnit(doc.data()?.totalDiscount),
          total: decodeUnit(doc.data()?.total),
          grandTotal: decodeUnit(doc.data()?.grandTotal),
          date: moment(doc.data().createdAt?.toDate()).format('MM/DD/YYYY'),
          empId: doc?.data()?.createdBy?.id,
        })
      })
      setData(dataValue)
    })
  } else {
    const getdata = await getDocs(qOrder)
    const mapdata = _.map(await getdata.docs, (doc) => {
      return {
        ...doc.data(),
        totalDiscount: decodeUnit(doc.data()?.totalDiscount),
        total: decodeUnit(doc.data()?.total),
        grandTotal: decodeUnit(doc.data()?.grandTotal),
        date: moment(doc.data().createdAt?.toDate()).format('MM/DD/YYYY'),
        empId: doc?.data()?.createdBy?.id,
      }
    })
    setData(mapdata)
  }

  const qUser = query(collection(db, 'Users'))
  if (currentDate || currentMonth) {
    onSnapshot(qUser, (snapShot) => {
      let dataValue = []
      snapShot.forEach((doc) => {
        return dataValue.push({
          ...doc.data(),
          id: doc?.id,
          salary: doc.data()?.salary ? decodeUnit(doc.data()?.salary) : 0,
        })
      })
      setUserList(dataValue)
    })
  } else {
    const getData = await getDocs(qUser)
    const dataValue = await _.map(await getData.docs, (doc) => {
      return {
        ...doc.data(),
        id: doc?.id,
        salary: doc.data()?.salary ? decodeUnit(doc.data()?.salary) : 0,
      }
    })
    setUserList(dataValue)
  }

  // const qWork = query(collection(db, 'Works'), orderBy('createdAt', 'desc'))
  const qWork = prevMonth
    ? query(
        collection(db, 'Works'),
        where('createdAt', '>=', startMonth),
        where('createdAt', '<=', endMonth),
        where('branch.id', '==', user.branchSelect?.id),
        orderBy('createdAt', 'desc')
      )
    : between
    ? query(
        collection(db, 'Works'),
        where('createdAt', '>=', start),
        where('createdAt', '<=', dateEnd),
        where('branch.id', '==', user.branchSelect?.id),
        orderBy('createdAt', 'desc')
      )
    : currentMonth
    ? query(
        collection(db, 'Works'),
        where('createdAt', '>=', prevMonthDate),
        where('createdAt', '<=', nextMonth),
        where('branch.id', '==', user.branchSelect?.id),
        orderBy('createdAt', 'desc')
      )
    : dateTime
    ? query(
        collection(db, 'Works'),
        where('createdAt', '>=', startDateTime),
        where('createdAt', '<=', endDateTime),
        where('branch.id', '==', user.branchSelect?.id),
        orderBy('createdAt', 'desc')
      )
    : currentDate &&
      query(
        collection(db, 'Works'),
        where('createdAt', '>=', prevDate),
        where('createdAt', '<=', nextDate),
        where('branch.id', '==', user.branchSelect?.id),
        orderBy('createdAt', 'desc')
      )

  if (currentDate || currentMonth) {
    onSnapshot(qWork, async (snapShot) => {
      const data = []
      snapShot.forEach((doc) => {
        return data.push({
          id: doc.id,
          ...doc.data(),
        })
      })
      const filterRef = _.filter(data, (i) => {
        return i.ref
      })
      const filterNoRef = _.filter(data, (i) => {
        return !i.ref
      })

      const matchInOutWorkTime = await Promise.all(
        _.map(filterNoRef, async (item) => {
          const getOffWork = await _.find(filterRef, (i) => {
            return item.id === i.ref
          })
          return {
            outTime: getOffWork?.createdAt ? getOffWork?.createdAt : null,
            inTime: item?.createdAt,
            branch: {
              id: item?.branch?.id,
              name: item?.branch?.name,
            },
            role: item?.role,
            user: {
              id: item?.user?.id,
              name: item?.user?.name,
              email: item?.user?.email,
            },
            diff: getOffWork?.createdAt
              ? moment(getOffWork?.createdAt?.toDate()).diff(
                  item?.createdAt?.toDate(),
                  'h'
                )
              : 0,
            uid: item?.user?.id,
            date: moment(item.createdAt?.toDate()).format('MM/DD/YYYY'),
            dateTime: moment(item.createdAt?.toDate()).format(
              'MM/DD/YYYY HH:mm:ss'
            ),
            time: new Date(item.createdAt?.toDate()).getTime(),
          }
        }),
        []
      )
      setWork(matchInOutWorkTime)
    })
  } else {
    const getData = await getDocs(qWork)
    const data = await _.map(await getData.docs, (doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      }
    })
    const filterRef = _.filter(data, (i) => {
      return i.ref
    })
    const filterNoRef = _.filter(data, (i) => {
      return !i.ref
    })
    const matchInOutWorkTime = await Promise.all(
      _.map(filterNoRef, async (item) => {
        const getOffWork = await _.find(filterRef, (i) => {
          return item.id === i.ref
        })
        return {
          outTime: getOffWork?.createdAt ? getOffWork?.createdAt : null,
          inTime: item?.createdAt,
          branch: {
            id: item?.branch?.id,
            name: item?.branch?.name,
          },
          role: item?.role,
          user: {
            id: item?.user?.id,
            name: item?.user?.name,
            email: item?.user?.email,
          },
          diff: getOffWork?.createdAt
            ? moment(getOffWork?.createdAt?.toDate()).diff(
                item?.createdAt?.toDate(),
                'h'
              )
            : 0,
          uid: item?.user?.id,
          date: moment(item.createdAt?.toDate()).format('MM/DD/YYYY'),
          dateTime: moment(item.createdAt?.toDate()).format(
            'MM/DD/YYYY HH:mm:ss'
          ),
          time: new Date(item.createdAt?.toDate()).getTime(),
        }
      }),
      []
    )
    setWork(matchInOutWorkTime)
  }

  const qExpenses = prevMonth
    ? query(
        collection(db, 'Expenses'),
        where('updatedAt', '>=', startMonth),
        where('updatedAt', '<=', endMonth),
        where('branch', '==', user.branchSelect?.id),
        where('status', '==', 'approvel'),
        orderBy('updatedAt', 'desc')
      )
    : between
    ? query(
        collection(db, 'Expenses'),
        where('updatedAt', '>=', start),
        where('updatedAt', '<=', dateEnd),
        where('branch', '==', user.branchSelect?.id),
        where('status', '==', 'approvel'),
        orderBy('updatedAt', 'desc')
      )
    : currentMonth
    ? query(
        collection(db, 'Expenses'),
        where('updatedAt', '>=', prevMonthDate),
        where('updatedAt', '<=', nextMonth),
        where('branch', '==', user.branchSelect?.id),
        where('status', '==', 'approvel'),
        orderBy('updatedAt', 'desc')
      )
    : dateTime
    ? query(
        collection(db, 'Expenses'),
        where('updatedAt', '>=', startDateTime),
        where('updatedAt', '<=', endDateTime),
        where('branch', '==', user.branchSelect?.id),
        where('status', '==', 'approvel'),
        orderBy('updatedAt', 'desc')
      )
    : currentDate &&
      query(
        collection(db, 'Expenses'),
        where('updatedAt', '>=', prevDate),
        where('updatedAt', '<=', nextDate),
        where('branch', '==', user.branchSelect?.id),
        where('status', '==', 'approvel'),
        orderBy('updatedAt', 'desc')
      )

  if (currentDate || currentMonth) {
    onSnapshot(qExpenses, (snapShot) => {
      let dataValue = []
      snapShot.forEach((doc) => {
        return dataValue.push({
          ...doc.data(),
          id: doc?.id,
          rawTotal: doc.data().total,
          total: decodeUnit(doc.data().total),
        })
      })
      setExpenses(dataValue)
    })
  } else {
    const getData = await getDocs(qExpenses)
    const data = await _.map(await getData.docs, (doc) => {
      return {
        ...doc.data(),
        id: doc?.id,
        rawTotal: doc.data().total,
        total: decodeUnit(doc.data().total),
      }
    })
    setExpenses(data)
  }
}
