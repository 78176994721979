import { Stack } from '@chakra-ui/react'
import { FormCategory } from 'Components/form/formCategory'
import { db } from 'Config/firebase'
import { collection, getDocs, orderBy, query } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'

function AddCategory() {
  const [categoryId, setCategoryId] = useState(null)

  useEffect(() => {
    getVoucherId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getVoucherId() {
    const qCategory = query(
      collection(db, 'Category'),
      orderBy('createdAt', 'asc')
    )
    const typeSnapshot = await (await getDocs(qCategory)).docs
      .map((item) => item.data()?.idCategory?.match(/\d+/g))
      .slice(-1)

    const CategoryId = `C${String(
      typeSnapshot.length > 0 ? parseFloat(typeSnapshot[0]) + 1 : 1
    ).padStart(4, '0')}`

    setCategoryId(CategoryId)
  }

  return (
    <Stack direction='column'>
      <FormCategory id={null} data={null} refId={categoryId} />
    </Stack>
  )
}

export default AddCategory
