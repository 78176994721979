import { Stack } from '@chakra-ui/react'
import { FormStaff } from 'Components/form/formStaff'
import { useAuth } from 'Hooks'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

function EditStaff() {
  const { id } = useParams()
  const [ThisUser, setThisUser] = useState({})
  const { getUser } = useAuth()

  const fetchUser = useCallback(async () => {
    getUser(id).then((res) => {
      setThisUser(res)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  return (
    <Stack direction='column'>
      <FormStaff id={id} data={ThisUser} />
    </Stack>
  )
}

export default EditStaff
