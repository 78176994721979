import {
  Center,
  HStack,
  Flex,
  Box,
  Text,
  Input,
  Button,
  Stack,
  Heading,
  Select,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { CustomTable } from '../components/customTable'
import { SearchIcon } from '@chakra-ui/icons'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { calculateSales } from './functions/calculate'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { db } from 'Config/firebase'
import { onFilterReportBranchDiscount } from './functions/filter'

const _ = require('underscore')

function Price(props) {
  const { discount } = props
  return (
    <HStack justifyContent='end' spacing='1em'>
      <Stack>
        <Box>
          <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
            ส่วนลดรวม
          </Text>
        </Box>
        <Box>
          <Text fontSize={{ base: '12px', md: '16px' }} color='report.500'>
            {new Intl.NumberFormat('th-TH', {
              minimumFractionDigits: 2,
            }).format(discount)}
          </Text>
        </Box>
      </Stack>
    </HStack>
  )
}

function DiscountProductBranchExpense({ user }) {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [data, setData] = useState([])
  const [filterData, setFilterData] = useState([])
  const [onSearch, setOnSearch] = useState(false)
  const [select, setSelect] = useState('')

  function onSubmitFilter() {
    const res = onFilterReportBranchDiscount(data, startDate, endDate, select)
    setOnSearch(true)
    setFilterData(res)
  }

  const fetchData = useCallback(async () => {
    let startDateQ = new Date()
    startDateQ.setDate(startDateQ.getDate() - 30)
    startDateQ.setHours(0)
    startDateQ.setMinutes(0)
    startDateQ.setSeconds(0)
    startDateQ.setMilliseconds(0)
    let endDateQ = new Date()
    endDateQ.setDate(endDateQ.getDate() + 30)
    endDateQ.setHours(0)
    endDateQ.setMinutes(0)
    endDateQ.setSeconds(0)
    endDateQ.setMilliseconds(0)

    const orderQuery = query(
      collection(db, 'Orders'),
      where('branch.id', '==', user?.branchSelect?.id),
      where('createdAt', '>=', startDateQ),
      where('createdAt', '<=', endDateQ),
      orderBy('createdAt', 'desc')
    )
    const res = (await getDocs(orderQuery)).docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      total:
        doc.data().status === 'refund'
          ? (parseFloat(doc.data().total) / 100) * -1
          : parseFloat(doc.data().total) / 100,
      grandTotal:
        doc.data().status === 'refund'
          ? (parseFloat(doc.data().grandTotal) / 100) * -1
          : parseFloat(doc.data().grandTotal) / 100,
      createdAt: doc
        .data()
        .createdAt.toDate()
        .toLocaleString('en-US', { hour12: false }),
      date: doc.data().createdAt.toDate(),
    }))
    const allData = _.filter(res, (item) => item.status !== 'refund').map(
      (item) => ({
        ...item,
        items: item.items.map((itm) => {
          const commission = _.isNaN(itm.commission)
            ? 0
            : parseFloat(itm.commission)
          const cost = _.isNaN(itm.cost) ? 0 : parseFloat(itm.cost)
          return { ...itm, commission: commission, cost: cost }
        }),
      })
    )

    const allItem = _.flatten(
      allData.map((item) =>
        item.items
          ?.filter((i) => i.promotion !== null)
          ?.map((i) => ({ ...i, ...item }))
      )
    )
    setData(
      allItem.map((item) => ({
        createdAt: item.createdAt,
        discountName: item.promotion?.name,
        orderId: item.orderId,
        employee: item.createdBy?.name,
        total: parseFloat(item.promotion?.discount),
      }))
    )
  }, [user])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <>
      <HStack
        my='1em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='1vw'
        flexDirection={{ sm: 'column', md: 'row' }}
      >
        <Flex flexDirection='column' w={{ sm: 'full', md: '30vw', lg: '30vw' }}>
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ช่วงเวลา :</Text>
          </Box>
          <Flex bg='#F4F7FE' borderRadius='16px'>
            <Input
              cursor='pointer'
              border='0px'
              w='full'
              textAlign='center'
              as={DatePicker}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText={'วันที่เริ่มต้น'}
            />
            <Input
              cursor='pointer'
              border='0px'
              w='full'
              textAlign='center'
              as={DatePicker}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText={'วันที่สิ้นสุด'}
            />
          </Flex>
        </Flex>
        <Flex
          display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        >
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ค้นหาชื่อส่วนลด :</Text>
          </Box>
          <Select
            color={'gray.700'}
            w='full'
            bg={'#F4F7FE'}
            border='0px solid #E8E9E9'
            borderRadius='16px'
            defaultValue=''
            fontWeight='500'
            variant='main'
            placeholder={'ทั้งหมด'}
            _placeholder={{
              fontWeight: '400',
            }}
            h='44px'
            maxh='44px'
            onChange={(event) => setSelect(event.target.value)}
          >
            {data?.map((item, index) => (
              <option value={item.discountName} key={index}>
                {item.discountName}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex
          display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        ></Flex>
        <Flex
          flexDirection='column'
          w={{ sm: 'full', md: '19vw', lg: '13vw', xl: '11vw' }}
          alignItems='end'
          pt={{ sm: '1rem', md: '0rem' }}
        >
          <Box
            textAlign='start'
            display={{ sm: 'none', md: 'flex' }}
            p='0.5rem'
            pl='1rem'
            pt='0rem'
          >
            <Text color='#8F9BBA'>&nbsp;</Text>
          </Box>
          <Button
            h='44px'
            maxh='44px'
            w={{ sm: '10rem', md: 'full' }}
            colorScheme='btn'
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            onClick={() => onSubmitFilter()}
          >
            <Text>
              <SearchIcon />
            </Text>
            <Text>แสดงรายงาน</Text>
          </Button>
        </Flex>
      </HStack>
      <Flex
        flexDirection='column'
        p={{ sm: '10px', md: '20px 20px 30px 20px' }}
        bg='white'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
        borderRadius='30px'
      >
        <Stack spacing={{ base: '0em', md: '4em' }}>
          <Stack>
            <Heading
              fontFamily={'Prompt'}
              color='#2B3674'
              fontSize={{ base: '24px', md: '30px' }}
            >
              รายการส่วนลดสินค้า
            </Heading>
            <Box>
              <HStack>
                <Text color='#2B3674'>ณ วันที่:</Text>
                <Text color='#2B3674'>
                  {moment(new Date().getTime()).format('MM/DD/YYYY')}
                </Text>
              </HStack>
              <HStack>
                <Text color='#2B3674'>ช่วงเวลา:</Text>
                {startDate && endDate && (
                  <Text color='#2B3674'>
                    {moment(startDate).format('MM/DD/YYYY')} -{' '}
                    {moment(endDate).format('MM/DD/YYYY')}
                  </Text>
                )}
              </HStack>
            </Box>
          </Stack>
          <Box
            pt={{ base: '1rem', sm: '1rem', md: '0rem', lg: '0rem' }}
            pr={{
              base: '0rem',
              sm: '0rem',
              md: '2rem',
              lg: '2rem',
              xl: '4rem',
            }}
          >
            <Price discount={onSearch ? calculateSales(filterData) : 0} />
          </Box>
        </Stack>
        <Box mt={{ base: '1rem', sm: '1rem', md: '2rem', lg: '2rem' }}>
          <CustomTable
            columnsData={columnsDataOrders}
            tableData={onSearch ? filterData : []}
          />
        </Box>
      </Flex>
    </>
  )
}

export default DiscountProductBranchExpense

const columnsDataOrders = [
  {
    Header: 'วันที่',
    accessor: 'createdAt',
    extra: (data, row) => (
      <Center key={row} w='full'>
        <Text>{row.createdAt}</Text>
      </Center>
    ),
  },
  {
    Header: 'ชื่อส่วนลด',
    accessor: 'discountName',
    extra: (data, row) => <Center key={row}>{row.discountName}</Center>,
  },
  {
    Header: 'เลขที่รายการขาย',
    accessor: 'orderId',
    extra: (data, row) => (
      <Center key={row} maxW={'145px'}>
        {row.orderId}
      </Center>
    ),
  },
  {
    Header: 'พนักงาน',
    accessor: 'employee',
    Cell: ({ row }) => (
      <Center>
        <Text>{row.employee}</Text>
      </Center>
    ),
  },
  {
    Header: 'ส่วนลด',
    accessor: 'total',
    extra: (data, row) => (
      <Center pl='1rem' justifyContent='flex-end' textAlign='end' w={'100px'}>
        {new Intl.NumberFormat('th-TH', {
          minimumFractionDigits: 2,
        }).format(row.total)}
      </Center>
    ),
  },
]
