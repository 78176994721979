import {
  HStack,
  Input,
  Flex,
  Text,
  Box,
  Button,
  Stack,
  Heading,
  Center,
} from '@chakra-ui/react'
import { useState } from 'react'
import { CustomTable } from '../components/customTable'
import { SearchIcon } from '@chakra-ui/icons'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { calStockTotal } from 'Utils/calStock'
import { useInventory, useOrder } from 'Hooks'
import { currency, decodeUnit } from 'Utils/currency'
const _ = require('underscore')

function Pricedesktop(props) {
  const { data: priecData } = props
  const calGrandTotal = _.reduce(
    _.pluck(priecData, 'grandTotal'),
    (memo, num) => {
      return memo + num
    },
    0
  )

  return (
    <HStack spacing='2rem'>
      <Box>
        <Stack
          alignItems={{ sm: 'center', md: 'left' }}
          w={{ sm: 'full', md: 'max-content' }}
        >
          <Box>
            <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
              ยอดขายสุทธิ
            </Text>
          </Box>
          <Box>
            <Text fontSize={{ base: '12px', md: '16px' }} color='report.500'>
              {calGrandTotal ? currency(calGrandTotal) : currency(0)}
            </Text>
          </Box>
          <Box></Box>
        </Stack>
      </Box>
    </HStack>
  )
}

function Price(props) {
  const { data: priecData } = props
  const calGrandTotal = _.reduce(
    _.pluck(priecData, 'grandTotal'),
    (memo, num) => {
      return memo + num
    },
    0
  )

  return (
    <HStack spacing='2rem'>
      <Box>
        <Stack
          alignItems={{ sm: 'center', md: 'left' }}
          w={{ sm: 'full', md: 'max-content' }}
        >
          <Box>
            <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
              ยอดขายสุทธิ
            </Text>
          </Box>
          <Box>
            <Text fontSize={{ base: '12px', md: '16px' }} color='report.500'>
              {calGrandTotal ? currency(calGrandTotal) : currency(0)}
            </Text>
          </Box>
          <Box></Box>
        </Stack>
      </Box>
    </HStack>
  )
}

function ProductSales({ user }) {
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [filterData, setFilterData] = useState([])
  const [onSearch, setOnSearch] = useState(false)
  const { getAllOrder } = useOrder()
  const { getAllInventoryByBranchId } = useInventory()

  async function onSubmitFilter() {
    return calData().then(async () => {
      const cal1 = await new Promise(async (resolve) => {
        const dataCal = await calData()
        return resolve(dataCal)
      })
      setOnSearch(true)
      setFilterData(cal1)
    })
  }

  const calData = async () => {
    const inveBranchData = await getAllInventoryByBranchId()
    let getOrderData = null
    if (startDate || endDate) {
      getOrderData = await getAllOrder(startDate, endDate)
    } else {
      getOrderData = await getAllOrder(new Date(), new Date())
    }

    const groupInvInventory = _.groupBy(inveBranchData, (e) => {
      return e.id
    })

    const inventoryStockAmount =
      (await Promise.all(
        await _.map(groupInvInventory, async (e, i) => {
          const total = await calStockTotal(e)
          return {
            item: i,
            total: total,
          }
        })
      )) || []

    const ivnentoryDataKeys = _.indexBy(inventoryStockAmount, 'item')
    const itemsOrders = []
    _.map(getOrderData, (item) => {
      if (item?.status !== 'refund') {
        return itemsOrders.push(...item.items)
      }
    })
    const groupOrderItem = _.groupBy(itemsOrders, 'id')
    const productsAmount = await Promise.all(
      _.map(groupOrderItem, async (items, itemKey) => {
        const calculate = _.reduce(
          _.pluck(items, 'amount'),
          (memo, num) => {
            return memo + parseFloat(decodeUnit(num))
          },
          0
        )

        return {
          idProduct: items[0]['sku'],
          inventory: parseFloat(ivnentoryDataKeys[itemKey]?.total),
          amount: calculate,
          total: parseFloat(decodeUnit(items[0]['price']) * calculate),
          cost: parseFloat(decodeUnit(items[0]['cost']) * calculate),
          productName:
            items[0]?.name + (items[0]?.nameOption ? items[0]?.nameOption : ''),
          commission: parseFloat(
            decodeUnit(items[0]['commission']) * calculate
          ),
          grandTotal: parseFloat(decodeUnit(items[0]['price']) * calculate),
        }
      })
    )
    return _.sortBy(productsAmount, 'amount').reverse()
  }
  return (
    <>
      <HStack
        my='1em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='1vw'
        flexDirection={{ sm: 'column', md: 'row' }}
      >
        <Flex flexDirection='column' w={{ sm: 'full', md: '30vw', lg: '30vw' }}>
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ช่วงเวลา :</Text>
          </Box>
          <Flex bg='#F4F7FE' borderRadius='16px'>
            <Input
              cursor='pointer'
              border='0px'
              w='full'
              textAlign='center'
              as={DatePicker}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText={'วันที่เริ่มต้น'}
              dateFormat='MM/dd/yyyy'
            />
            <Input
              cursor='pointer'
              border='0px'
              w='full'
              textAlign='center'
              as={DatePicker}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText={'วันที่สิ้นสุด'}
              dateFormat='MM/dd/yyyy'
            />
          </Flex>
        </Flex>
        <Flex
          display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        ></Flex>
        <Flex
          display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        ></Flex>
        <Flex
          flexDirection='column'
          w={{ sm: 'full', md: '19vw', lg: '13vw', xl: '11vw' }}
          alignItems='end'
          pt={{ sm: '1rem', md: '0rem' }}
        >
          <Box
            textAlign='start'
            display={{ sm: 'none', md: 'flex' }}
            p='0.5rem'
            pl='1rem'
            pt='0rem'
          >
            <Text color='#8F9BBA'>&nbsp;</Text>
          </Box>
          <Button
            h='44px'
            maxh='44px'
            w={{ sm: '10rem', md: 'full' }}
            colorScheme='btn'
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            onClick={() => onSubmitFilter()}
          >
            <Text>
              <SearchIcon />
            </Text>
            <Text>แสดงรายงาน</Text>
          </Button>
        </Flex>
      </HStack>
      <Flex
        flexDirection='column'
        p={{ sm: '10px', md: '20px 20px 30px 20px' }}
        bg='white'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
        borderRadius='30px'
      >
        <Stack spacing={{ base: '0em', md: '4em' }}>
          <Stack>
            <Heading
              fontFamily={'Prompt'}
              color='#2B3674'
              fontSize={{ base: '24px', md: '30px' }}
            >
              รายงานยอดขายตามสินค้า
            </Heading>
            <Box>
              <HStack>
                <Text color='#2B3674'>ณ วันที่:</Text>
                <Text color='#2B3674'>
                  {moment(new Date().getTime()).format('MM/DD/YYYY')}
                </Text>
              </HStack>
              <HStack>
                <Text color='#2B3674'>ช่วงเวลา:</Text>
                {startDate && endDate && (
                  <Text color='#2B3674'>
                    {moment(startDate).format('MM/DD/YYYY')} -{' '}
                    {moment(endDate).format('MM/DD/YYYY')}
                  </Text>
                )}
              </HStack>
            </Box>
          </Stack>
          <Box
            display={{ base: 'none', sm: 'none', md: 'flex' }}
            pt={{ base: '1rem', sm: '1rem', md: '0rem', lg: '0rem' }}
            pr={{
              base: '0rem',
              sm: '0rem',
              md: '1rem',
              lg: '1rem',
              xl: '2rem',
            }}
            justifyContent='end'
            w='100%'
          >
            <Pricedesktop data={filterData} />
          </Box>
          <Box
            w='100%'
            display={{ base: 'flex', sm: 'flex', md: 'none' }}
            pt={{ base: '1rem', sm: '1rem', md: '0rem', lg: '0rem' }}
            pr={{
              base: '0rem',
              sm: '0rem',
              md: '1rem',
              lg: '1rem',
              xl: 'rem',
            }}
          >
            <Price data={filterData} />
          </Box>
        </Stack>
        <Box mt={{ base: '1rem', sm: '1rem', md: '2rem', lg: '2rem' }}>
          <CustomTable
            columnsData={columnsDataOrders}
            tableData={onSearch ? filterData : []}
          />
        </Box>
      </Flex>
    </>
  )
}

export default ProductSales

const columnsDataOrders = [
  {
    Header: 'รหัสสินค้า',
    accessor: 'idProduct',
    extra: (data, row) => (
      <Center key={row} w='full'>
        <Text>{row.idProduct}</Text>
      </Center>
    ),
  },
  {
    Header: 'ชื่อสินค้า',
    accessor: 'productName',
    extra: (data, row) => <Center key={row}>{row.productName}</Center>,
  },
  {
    Header: 'จำนวนที่ขาย',
    accessor: 'amount',
    extra: (data, row) => (
      <Center key={row} maxW={'145px'}>
        {currency(row.amount, 2)}
      </Center>
    ),
  },
  {
    Header: 'คลัง',
    accessor: 'inventory',
    extra: (data, row) => (
      <Center key={row} maxW={'145px'}>
        {currency(row.inventory, 2)}
      </Center>
    ),
  },
  {
    Header: 'ราคาต้นทุน',
    accessor: 'cost',
    extra: (data, row) => (
      <Center key={row} justifyContent='flex-end' textAlign='end' w={'100px'}>
        {currency(row.cost, 2)}
      </Center>
    ),
  },
  {
    Header: 'ยอดขาย',
    accessor: 'total',
    extra: (data, row) => (
      <Center key={row} justifyContent='flex-end' textAlign='end' w={'100px'}>
        {currency(row.total)}
      </Center>
    ),
  },
  {
    Header: 'ยอดขายสุทธิ',
    accessor: 'grandTotal',
    extra: (data, row) => (
      <Center key={row} justifyContent='flex-end' textAlign='end' w={'100px'}>
        {currency(row.grandTotal)}
      </Center>
    ),
  },
]
