import { Box, Button, Center, Image, Text } from '@chakra-ui/react'
import React from 'react'

function PosbankGetRQ(props) {
  const { defaultBank, posUseBank, setPaymentStep } = props
  return (
    <>
      <Center
        mt={{ base: '2rem', sm: '2rem', md: '1rem',lg: '1rem'}}
        h='auto'
        w='full'
        flexDirection='column'
      >
        <Image
          alt='qr'
          w={{base:'auto',sm:'auto',md:'100%'}}
          h={{base:'50vh',sm:'50vh',md:'auto'}}
          src={posUseBank ? posUseBank?.img : defaultBank?.img}
        />
        <Center pt='1rem' flexDirection='column'>
          <Text>
            {posUseBank ? posUseBank?.bank?.name : defaultBank?.bank?.name}
          </Text>
          <Text mt='-0.2rem'>
            {posUseBank
              ? posUseBank?.accountingNumber
              : defaultBank?.accountingNumber}
          </Text>
          <Text mt='-0.2rem'>
            {posUseBank ? posUseBank?.accountName : defaultBank?.accountName}
          </Text>
        </Center>
      </Center>
      <Center
        pt={{ base: '6rem', sm: '6rem', md: '2rem' }}
        display={{
          base: 'none',
          sm: 'none',
          md: 'flex',
          lg: 'flex',
          xl: 'flex',
          '2xl': 'flex',
        }}
        bottom='0px'
        w='100%'
      >
        <Box p='0.3rem' w='100%'>
          <Button
            w='100%'
            colorScheme={'btn'}
            py='1.7rem'
            borderRadius='5px'
            onClick={() => {
              setPaymentStep({ bankReceived: true })
            }}
          >
            กลับหน้าชำระเงิน
          </Button>
        </Box>
      </Center>
      <Center
        display={{
          base: 'flex',
          sm: 'flex',
          md: 'none',
          lg: 'none',
          xl: 'none',
          '2xl': 'none',
        }}
        position='absolute'
        bottom='0px'
        w='100%'
        h='100vh'
        alignItems='flex-end'
      >
        <Box p='0.3rem' w='100%'>
          <Button
            w='100%'
            colorScheme={'btn'}
            py='1.7rem'
            borderRadius='5px'
            onClick={() => {
              setPaymentStep({ bankReceived: true })
            }}
          >
            กลับหน้าชำระเงิน
          </Button>
        </Box>
      </Center>
    </>
  )
}

export default PosbankGetRQ
