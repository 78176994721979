import React, { useEffect, useState } from 'react'
import { Center, Text, Icon, Button, Spinner } from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import { Link } from 'react-router-dom'
import { BsPlus } from 'react-icons/bs'
import CardDiscount from './components/card'
import { usePromotion } from 'Hooks/promotion'

function DiscountProduct({ user }) {
  const { allPromotion } = usePromotion()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    setIsLoading(false)
  }, [])
  return (
    <>
      <Card px={{ base: '10px', sm: '10px', md: '10px', lg: '30px' }}>
        {!isLoading && allPromotion ? (
          allPromotion.length > 0 ? (
            allPromotion.map((itm, index) => (
              <CardDiscount itm={itm} key={index} />
            ))
          ) : (
            <Center>
              <Text>ไม่มีรายการส่วนลดร้านค้า</Text>
            </Center>
          )
        ) : (
          <Center>
            <Spinner size='lg' />
          </Center>
        )}
      </Card>
      <Button
        as={Link}
        to='/discount/product/add'
        borderRadius='full'
        position='fixed'
        right={5}
        bottom={5}
        w={'50px'}
        h={'50px'}
        colorScheme='btn'
      >
        <Icon as={BsPlus} fontSize={'45px'} />
      </Button>
    </>
  )
}

export default DiscountProduct
