import React, { useEffect, useState } from 'react'
import {
  HStack,
  Input,
  Flex,
  Text,
  Box,
  Button,
  Heading,
  Stack,
  Icon,
  Center,
  Select,
} from '@chakra-ui/react'
import DatePicker from 'react-datepicker'
import { ChevronDownIcon, ChevronUpIcon, SearchIcon } from '@chakra-ui/icons'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import ExpandTable from './components/ExpandTable.js'
import { calculateGrandTotal } from './functions/calculate'
import { useOrder } from 'Hooks/order.js'
import { onFilterReportExpensesDiscount } from './functions/filter.js'
const accounting = require('accounting')
const _ = require('underscore')

function Price(props) {
  const { grandTotal } = props
  return (
    <HStack justifyContent='end' spacing='1em'>
      <Stack>
        <Box>
          <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
            ส่วนลดรวม
          </Text>
        </Box>
        <Box>
          <Text fontSize={{ base: '12px', md: '16px' }} color='report.500'>
            {accounting.formatNumber(grandTotal, 2)}
          </Text>
        </Box>
      </Stack>
    </HStack>
  )
}

function ExpenseDiscount() {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const { ordersReport, getAllOrdersWithReport } = useOrder()
  const [filterData, setFilterData] = useState([])
  const [onSearch, setOnSearch] = useState(false)
  const [select, setSelect] = useState('')

  useEffect(() => {
    getAllOrdersWithReport()
  }, [getAllOrdersWithReport])

  const AllBranch = _.groupBy(
    ordersReport
      ?.filter((item) => item.status !== 'refund' && item.discount !== null)
      .map((item) => ({
        ...item,
        branch: item?.branch?.name,
        discountBalance:
          item.status === 'refund'
            ? (parseFloat(item.discountBalance) / 100) * -1
            : parseFloat(item.discountBalance) / 100,
        total:
          item.status === 'refund'
            ? (parseFloat(item.total) / 100) * -1
            : parseFloat(item.total) / 100,
        grandTotal:
          item.status === 'refund'
            ? (parseFloat(item.grandTotal) / 100) * -1
            : parseFloat(item.grandTotal) / 100,
      })),
    'branch'
  )
  const filterBranch = Object.keys(AllBranch).map((item) => ({
    branch: item,
    createdAt: null,
    discountName: null,
    orderId: null,
    grandTotal: _.reduce(AllBranch[item], (v, i) => v + i.discountBalance, 0),
    subRows: AllBranch[item].map((itm) => ({
      createdAt: itm.createdAt
        .toDate()
        .toLocaleString('en-US', { hour12: false }),
      date: itm.createdAt.toDate(),
      orderId: itm.orderId,
      discountName: itm.discount?.name,
      employee: itm.createdBy?.name,
      items: itm.items,
      grandTotal: itm.discountBalance,
    })),
  }))

  const AllDiscount = _.groupBy(
    _.flatten(_.pluck(filterBranch, 'subRows')),
    'discountName'
  )

  function onSubmitFilter() {
    const res = onFilterReportExpensesDiscount(
      filterBranch,
      startDate,
      endDate,
      select
    )
    setOnSearch(true)
    setFilterData(res)
  }
  return (
    <>
      <HStack
        my='1em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='1vw'
        flexDirection={{ sm: 'column', md: 'row' }}
      >
        <Flex flexDirection='column' w={{ sm: 'full', md: '30vw', lg: '30vw' }}>
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ช่วงเวลา :</Text>
          </Box>
          <Flex bg='#F4F7FE' borderRadius='16px'>
            <Input
              cursor='pointer'
              border='0px'
              w='full'
              textAlign='center'
              as={DatePicker}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText={'วันที่เริ่มต้น'}
            />
            <Input
              cursor='pointer'
              border='0px'
              w='full'
              textAlign='center'
              as={DatePicker}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText={'วันที่สิ้นสุด'}
            />
          </Flex>
        </Flex>
        <Flex
          display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        >
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ค้นหาชื่อส่วนลด :</Text>
          </Box>
          <Select
            color={'gray.700'}
            w='full'
            bg={'#F4F7FE'}
            border='0px solid #E8E9E9'
            borderRadius='16px'
            defaultValue=''
            fontWeight='500'
            variant='main'
            placeholder={'ทั้งหมด'}
            _placeholder={{
              fontWeight: '400',
            }}
            h='44px'
            maxh='44px'
            onChange={(event) => setSelect(event.target.value)}
          >
            {Object.keys(AllDiscount).map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex
          display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        ></Flex>
        <Flex
          flexDirection='column'
          w={{ sm: 'full', md: '19vw', lg: '13vw', xl: '11vw' }}
          alignItems='end'
          pt={{ sm: '1rem', md: '0rem' }}
        >
          <Box
            textAlign='start'
            display={{ sm: 'none', md: 'flex' }}
            p='0.5rem'
            pl='1rem'
            pt='0rem'
          >
            <Text color='#8F9BBA'>&nbsp;</Text>
          </Box>
          <Button
            h='44px'
            maxh='44px'
            w={{ sm: '10rem', md: 'full' }}
            colorScheme='btn'
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            onClick={() => onSubmitFilter()}
          >
            <Text>
              <SearchIcon />
            </Text>
            <Text>แสดงรายงาน</Text>
          </Button>
        </Flex>
      </HStack>
      <Flex
        flexDirection='column'
        p={{ sm: '10px', md: '20px 20px 30px 20px' }}
        bg='white'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
        borderRadius='30px'
      >
        <Stack spacing={{ base: '0em', md: '4em' }}>
          <Stack>
            <Heading
              fontFamily={'Prompt'}
              color='#2B3674'
              fontSize={{ base: '24px', md: '30px' }}
            >
              รายการส่วนลด
            </Heading>
            <Box>
              <HStack>
                <Text color='#2B3674'>ณ วันที่:</Text>
                <Text color='#2B3674'>
                  {moment(new Date().getTime()).format('MM/DD/YYYY')}
                </Text>
              </HStack>
              <HStack>
                <Text color='#2B3674'>ช่วงเวลา:</Text>
                {startDate && endDate && (
                  <Text color='#2B3674'>
                    {moment(startDate).format('MM/DD/YYYY')} -{' '}
                    {moment(endDate).format('MM/DD/YYYY')}
                  </Text>
                )}
              </HStack>
            </Box>
          </Stack>
          <Box
            pt={{ base: '1rem', sm: '1rem', md: '0rem', lg: '0rem' }}
            pr={{
              base: '0rem',
              sm: '0rem',
              md: '2rem',
              lg: '2rem',
              xl: '4rem',
            }}
          >
            <Price
              grandTotal={onSearch ? calculateGrandTotal(filterData) : 0}
            />
          </Box>
        </Stack>
        <ExpandTable
          columnsData={columnsData}
          tableData={onSearch ? filterData : []}
        />
      </Flex>
    </>
  )
}

export default ExpenseDiscount

const columnsData = [
  {
    // Build our expander column
    id: 'expander', // Make sure it has an ID
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      <Text {...getToggleAllRowsExpandedProps()}>
        {isAllRowsExpanded ? (
          <Icon as={ChevronUpIcon} />
        ) : (
          <Icon as={ChevronDownIcon} />
        )}
      </Text>
    ),
    Cell: ({ row }) =>
      row.canExpand ? (
        <Center>
          <Text
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            {row.isExpanded ? (
              <Icon as={ChevronUpIcon} />
            ) : (
              <Icon as={ChevronDownIcon} />
            )}
          </Text>
        </Center>
      ) : null,
  },
  {
    Header: 'สาขา',
    accessor: 'branch',
    Cell: ({ row }) => {
      return (
        <Box>
          <Text>{row.original.branch}</Text>
        </Box>
      )
    },
  },
  {
    Header: 'วันที่',
    accessor: 'createdAt',
    Cell: ({ row }) => {
      return (
        <Box>
          <Text>{row.original.createdAt}</Text>
        </Box>
      )
    },
  },
  {
    Header: 'ชื่อส่วนลด',
    accessor: 'discountName',
    Cell: ({ row }) => (
      <Center>
        <Text>{row.original.discountName}</Text>
      </Center>
    ),
  },
  {
    Header: 'เลขที่รายการขาย',
    accessor: 'orderId',
    Cell: ({ row }) => (
      <Center>
        <Text>{row.original.orderId}</Text>
      </Center>
    ),
  },
  {
    Header: 'พนักงาน',
    accessor: 'employee',
    Cell: ({ row }) => (
      <Center>
        <Text>{row.original.employee}</Text>
      </Center>
    ),
  },
  {
    Header: 'ส่วนลด',
    accessor: 'grandTotal',
    Cell: ({ row }) => (
      <Center justifyContent='flex-end' pr='5em'>
        <Text>
          {new Intl.NumberFormat('th-TH', {
            minimumFractionDigits: 2,
          }).format(row.original.grandTotal)}
        </Text>
      </Center>
    ),
  },
]
