import { db } from 'Config/firebase'
import { doc, getDoc } from 'firebase/firestore'
// import { FormDiscount } from 'Components/form/formDiscount'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { decodeUnit } from 'Utils/currency'
import { FreeListList } from './components'
import { DiscountCategory } from './components/discountcategory'
import { DiscountList } from './components/discountlist'
import { DiscountRecipt } from './components/discountrecipt'
import FreeCategoryCategory from './components/freecategorycategory'
import FreeCategoryList from './components/freecategorylist'
import FreeCategoryReceipt from './components/freecategoryreceipt'
import FreeListCategory from './components/freelistcategory'
import FreeListReceipt from './components/freelistreceipt'
import FreeReceiptCategory from './components/freereceiptcategory'
import FreeReceiptList from './components/freereceiptlist'
import FreeReceiptReceipt from './components/freereceiptreceipt'

export default function EditDiscount({ user }) {
  const { id } = useParams()
  const [data, setData] = useState(null)

  useEffect(() => {
    getDoc(doc(db, 'Promotion', id)).then((res) => {
      setData({
        ...res.data(),
        valueDiscount: res.data()?.valueDiscount
          ? decodeUnit(res.data()?.valueDiscount)
          : null,
        productAmount: res.data()?.productAmount
          ? decodeUnit(res.data()?.productAmount)
          : null,
        freeProductAmount: res.data()?.freeProductAmount
          ? decodeUnit(res.data()?.freeProductAmount)
          : null,
        freeCategoryAmount: res.data()?.freeCategoryAmount
          ? decodeUnit(res.data()?.freeCategoryAmount)
          : null,
        categoryAmount: res.data()?.categoryAmount
          ? decodeUnit(res.data()?.categoryAmount)
          : null,
        minimumOrder: res.data()?.minimumOrder
          ? decodeUnit(res.data()?.minimumOrder)
          : null,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (data?.type === 'discount' && data?.typeDiscount === 'list') {
    return <DiscountList data={data} id={id} />
  } else if (data?.type === 'discount' && data?.typeDiscount === 'category') {
    return <DiscountCategory data={data} id={id} />
  } else if (data?.type === 'discount' && data?.typeDiscount === 'receipt') {
    return <DiscountRecipt data={data} id={id} />
  } else if (data?.type === 'free' && data?.typeDiscount === 'list') {
    if (data?.typeFree === 'list') {
      return <FreeListList data={data} id={id} />
    } else if (data?.typeFree === 'category') {
      return <FreeListCategory data={data} id={id} />
    } else if (data?.typeFree === 'receipt') {
      return <FreeListReceipt data={data} id={id} />
    }
  } else if (data?.type === 'free' && data?.typeDiscount === 'category') {
    if (data?.typeFree === 'list') {
      return <FreeCategoryList data={data} id={id} />
    } else if (data?.typeFree === 'category') {
      return <FreeCategoryCategory data={data} id={id} />
    } else if (data?.typeFree === 'receipt') {
      return <FreeCategoryReceipt data={data} id={id} />
    }
  } else if (data?.type === 'free' && data?.typeDiscount === 'receipt') {
    if (data?.typeFree === 'list') {
      return <FreeReceiptList data={data} id={id} />
    } else if (data?.typeFree === 'category') {
      return <FreeReceiptCategory data={data} id={id} />
    } else if (data?.typeFree === 'receipt') {
      return <FreeReceiptReceipt data={data} id={id} />
    }
  }

  // return (
  //   <Stack direction='column'>
  //     {data && id && <FormDiscount data={data} id={id} />}
  //     {/* {data && id && <FormDiscount data={data} id={id} />} */}
  //   </Stack>
  // )
}
