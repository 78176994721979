import {
  Box,
  // Breadcrumb,
  // BreadcrumbItem,
  Flex,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import AdminNavLink from './Components/Admin/adminNavLink'
import ClientNav from './Components/Admin/clientNav'

export function Header(props) {
  const { user, title } = props

  useEffect(() => {}, [])
  let secondary = true

  let mainText = useColorModeValue('navy.700', 'white')
  // let secondaryText = useColorModeValue('gray.700', 'white')
  let navbarPosition = 'fixed'
  let navbarFilter = 'none'
  let navbarBackdrop = 'blur(0px)'
  let navbarShadow = 'none'
  let navbarBg = useColorModeValue('#fff', 'rgba(11,20,55,0.5)')
  let navbarBorder = 'transparent'
  let secondaryMargin = '0px'
  let gap = '0px'

  return (
    <Box
      position={navbarPosition}
      top='-1px'
      w={{
        base: '100%',
        sm: '100%',
        md: '100%',
        lg: '100%',
        xl: 'calc(100vw)',
        '2xl': 'calc(100vw)',
      }}
      zIndex='base'
      left={{ base: '0px', md: '0px', lg: '0px', xl: '18.8rem' }}
      bg={'navbar.100'}
      h={{
        base: '55px',
        sm: '55px',
        md: '55px',
        lg: '55px',
        xl: '55px',
      }}
    >
      <Box
        position={navbarPosition}
        boxShadow={navbarShadow}
        bg={navbarBg}
        borderColor={navbarBorder}
        filter={navbarFilter}
        backdropFilter={navbarBackdrop}
        backgroundPosition='center'
        backgroundSize='cover'
        borderRadius='5px'
        borderWidth='1.5px'
        borderStyle='solid'
        transitionDelay='0s, 0s, 0s, 0s'
        transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
        transition-property='box-shadow, background-color, filter, border'
        transitionTimingFunction='linear, linear, linear, linear'
        alignItems={{ xl: 'center' }}
        display={secondary ? 'block' : 'flex'}
        minH='75px'
        justifyContent={{ xl: 'center' }}
        lineHeight='25.6px'
        mx={{ md: '0px', lg: '0px' }}
        mt={secondaryMargin}
        pb='8px'
        right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
        px={{
          sm: '1px',
          md: '5px',
          lg: '5px',
          xl: '0px',
        }}
        ps={{
          xl: '12px',
        }}
        pt='8px'
        top={{ base: '12px', md: '16px', xl: '18px' }}
        w={{
          base: 'calc(100vw - 24px)',
          sm: 'calc(100vw - 24px)',
          md: 'calc(100vw - 60px)',
          lg: 'calc(100vw - 60px)',
          xl: 'calc(100vw - 365px)',
          '2xl': 'calc(100vw - 365px)',
        }}
        zIndex='overlay'
      >
        <Flex
          w='100%'
          flexDirection={{
            sm: 'column',
            md: 'row',
          }}
          alignItems={{ xl: 'center' }}
          mb={gap}
        >
          <Box
            mb={{ sm: '8px', md: '0px' }}
            display={{ base: 'none', md: 'flex' }}
            flexDirection='column'
            fontFamily='Prompt'
          >
            <Text
              color={mainText}
              bg='inherit'
              borderRadius='inherit'
              fontWeight='bold'
              fontSize='30px'
              pt='4px'
              _hover={{ color: { mainText } }}
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent',
              }}
              _focus={{
                boxShadow: 'none',
              }}
            >
              {title?.slice(-1)}
            </Text>
          </Box>
          <Box
            px={{ sm: '0.2rem', md: '0.2rem', lg: '0px' }}
            ms='auto'
            w={{ sm: '100%', md: '39%', lg: '27%', xl: '27%' }}
            zIndex='overlay'
          >
            {user?.role === 'Administrator' ? (
              <AdminNavLink user={user} />
            ) : (
              <ClientNav user={user} />
            )}
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}
