import {
  Box,
  HStack,
  Stack,
  Text,
  VStack,
  Center,
  useColorModeValue,
  InputGroup,
  InputLeftElement,
  IconButton,
  Input,
  Spinner,
  InputRightElement,
  Flex,
} from '@chakra-ui/react'
import ReactDatePicker from 'react-datepicker'
import { useCallback, useEffect, useState } from 'react'
import Card from 'Components/Horizons/card/Card'
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons'
import { useOrder } from 'Hooks'
import _ from 'underscore'
import { search as ssSearch } from 'ss-search'
import moment from 'moment'
import { OrderCard } from './Components/orderCard'

function PosAllHistory({ user }) {
  const { isLoadingAllOrderHistory: orderIsLoading, getOrderByDate } =
    useOrder()
  const [SearchResults, setSearchResults] = useState([])
  const [orders, setOrders] = useState([])
  const searchIconColor = useColorModeValue('gray.700', 'white')
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900')
  const inputText = useColorModeValue('gray.700', 'gray.100')
  const [startDate, setStartDate] = useState(
    new Date(moment(new Date()).subtract(7, 'days').format('MM/DD/YYYY'))
  )
  const [endDate, setEndDate] = useState(new Date())
  const [optionsSearch, setOptionsSearch] = useState('')

  // useEffect(() => {
  //   getAllOrders()
  // }, [getAllOrders])

  const getData = useCallback(
    async (start, end) => {
      const dataResult = await getOrderByDate(start, end)
      if (user?.role === 'Cashier') {
        const filterOrderData = _.filter(dataResult, (e) => {
          return e?.createdBy?.id === user?.uid
        })
        setOrders(filterOrderData)
      } else {
        setOrders(dataResult)
      }
    },
    //eslint-disable-next-line
    [getOrderByDate, user]
  )

  useEffect(() => {
    getData(startDate, endDate)
    //eslint-disable-next-line
  }, [startDate, endDate])

  useEffect(() => {
    const searchText = optionsSearch
    const searchKey = ['createdBy.name', 'orderId']

    const results = ssSearch(orders, searchKey, searchText)
    if (results?.length > 0) {
      const ordersGroup = _.groupBy(results, 'date')
      return setSearchResults(ordersGroup)
    } else {
      return setSearchResults([])
    }
    //eslint-disable-next-line
  }, [optionsSearch, orders])

  return (
    <Stack>
      <Box>
        <Card>
          <VStack spacing={'20px'} w='full'>
            <Box
              w='full'
              px={{ base: '0em', sm: '0em', md: '1em' }}
              pt='.25em'
              borderRadius={'1.2em'}
              bg={'#ffff'}
            >
              <Flex
                justifyContent={{
                  base: 'space-between',
                  sm: 'space-between',
                  md: 'space-between',
                }}
                alignItems={{
                  base: 'start',
                  sm: 'start',
                  md: 'start',
                  lg: 'end',
                }}
                flexDirection={{
                  base: 'column-reverse',
                  sm: 'column-reverse',
                  md: 'column-reverse',
                  lg: 'row',
                }}
                w='100%'
                pb='1.5rem'
              >
                {/* <Box> */}
                <InputGroup
                  mt={{
                    base: '1.5rem',
                    sm: '1.5rem',
                    md: '1.5rem',
                    lg: '0rem',
                  }}
                  pt='0.25rem'
                  h='44px'
                  w={{
                    base: 'full',
                    sm: 'full',
                    md: 'full',
                    lg: '350px',
                  }}
                  borderRadius='16px'
                  zIndex='base'
                >
                  <InputLeftElement
                    pt='0.25rem'
                    children={
                      <IconButton
                        bg='inherit'
                        borderRadius='inherit'
                        _hover='none'
                        _active={{
                          bg: 'inherit',
                          transform: 'none',
                          borderColor: 'transparent',
                        }}
                        _focus={{
                          boxShadow: 'none',
                        }}
                        icon={
                          <SearchIcon
                            color={searchIconColor}
                            w='15px'
                            h='15px'
                          />
                        }
                      ></IconButton>
                    }
                  />
                  <Input
                    variant='search'
                    fontSize='sm'
                    bg={inputBg}
                    color={inputText}
                    fontWeight='500'
                    _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                    borderRadius={{
                      base: '10px',
                      sm: '10px',
                      md: '10px',
                      lg: '30px',
                    }}
                    placeholder={'Search...'}
                    onChange={(e) => {
                      let keyword = e.target.value
                      setOptionsSearch(keyword)
                    }}
                  />
                </InputGroup>
                <HStack
                  pl={{ base: '0rem', sm: '0rem', md: '0.5rem' }}
                  flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
                  w={{ base: '100%', sm: '100%', md: '100%', lg: 'auto' }}
                  alignItems='end'
                >
                  <Box w='100%'>
                    <Box textAlign='start' p='0.5rem' pl='1rem'>
                      <Text color='#8F9BBA'>จากวันที่ :</Text>
                    </Box>
                    <InputGroup
                      w={{
                        base: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '250px',
                      }}
                    >
                      <Input
                        bg='#F4F7FE'
                        borderRadius='16px'
                        cursor='pointer'
                        border='0px'
                        variant={'no-effects'}
                        _focus={{ border: '0px solid #F4F7FE' }}
                        w='full'
                        textAlign='center'
                        as={ReactDatePicker}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText={' '}
                      />
                      <InputRightElement
                        zIndex='base'
                        children={<ChevronDownIcon color='#8F9BB' />}
                      />
                    </InputGroup>
                  </Box>
                  <Box w='100%'>
                    <Box textAlign='start' p='0.5rem' pl='1rem'>
                      <Text color='#8F9BBA'>ถึงวันที่ :</Text>
                    </Box>
                    <InputGroup
                      w={{
                        base: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '250px',
                      }}
                    >
                      <Input
                        bg='#F4F7FE'
                        borderRadius='16px'
                        cursor='pointer'
                        border='0px'
                        variant={'no-effects'}
                        _focus={{ border: '0px solid #F4F7FE' }}
                        w='full'
                        textAlign='center'
                        as={ReactDatePicker}
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        placeholderText={' '}
                      />
                      <InputRightElement
                        zIndex='base'
                        children={<ChevronDownIcon color='#8F9BB' />}
                      />
                    </InputGroup>
                  </Box>
                </HStack>
              </Flex>
              <Box>
                <Text fontSize='22px' fontWeight='bold' color='#1B2559'>
                  รายการขาย
                </Text>
              </Box>
              {!orderIsLoading ? (
                _.keys(SearchResults).length > 0 ? (
                  _.keys(SearchResults).map((item, i) => {
                    const itemsData = SearchResults[item]
                    return (
                      <>
                        <Box key={i} mt={'20px'}>
                          <Text>วันที่: {item}</Text>
                        </Box>
                        <Box>
                          {itemsData?.map((inItem, indexInItem) => {
                            return (
                              <OrderCard
                                inItem={inItem}
                                indexInItem={indexInItem}
                                key={indexInItem}
                                path={'historyAll'}
                                startDate={new Date(
                                  moment(startDate).startOf('D')
                                ).toISOString()}
                                endDate={new Date(
                                  moment(endDate).endOf('D')
                                ).toISOString()}
                              />
                            )
                          })}
                        </Box>
                      </>
                    )
                  })
                ) : (
                  <>
                    <Center>
                      <Text>ไม่มีรายการขาย</Text>
                    </Center>
                  </>
                )
              ) : (
                <Center>
                  <Spinner />
                </Center>
              )}
            </Box>
          </VStack>
        </Card>
      </Box>
    </Stack>
  )
}

export default PosAllHistory
