import { db } from 'Config/firebase'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { calPromotionDecodeUnitAmountPerItem } from 'Utils/calStock'
import { decodeUnit } from 'Utils/currency'

const prevDate = new Date()
prevDate.setHours(0)
prevDate.setMinutes(0)
prevDate.setSeconds(0)
prevDate.setMilliseconds(0)

const nextDate = new Date()
nextDate.setDate(nextDate.getDate() + 1)
nextDate.setHours(0)
nextDate.setMinutes(0)
nextDate.setSeconds(0)
nextDate.setMilliseconds(0)

export async function QueryStaffSales({ select, startDate, endDate, user }) {
  const start = new Date(startDate)
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  start.setMilliseconds(0)

  const dateEnd = new Date(endDate)
  dateEnd.setDate(dateEnd.getDate() + 1)
  dateEnd.setHours(0)
  dateEnd.setMinutes(0)
  dateEnd.setSeconds(0)
  dateEnd.setMilliseconds(0)

  const qOrders = select
    ? startDate
      ? endDate
        ? query(
            collection(db, 'Orders'),
            where('branch.id', '==', user?.branchSelect?.id),
            where('createdBy.name', '==', select),
            where('createdAt', '>=', start),
            where('createdAt', '<=', dateEnd),
            orderBy('createdAt', 'desc')
          )
        : query(
            collection(db, 'Orders'),
            where('branch.id', '==', user?.branchSelect?.id),
            where('createdBy.name', '==', select),
            where('createdAt', '>=', start),
            orderBy('createdAt', 'desc')
          )
      : query(
          collection(db, 'Orders'),
          where('branch.id', '==', user?.branchSelect?.id),
          where('createdBy.name', '==', select),
          orderBy('createdAt', 'desc')
        )
    : startDate
    ? endDate
      ? query(
          collection(db, 'Orders'),
          where('branch.id', '==', user?.branchSelect?.id),
          where('createdAt', '>=', start),
          where('createdAt', '<=', dateEnd),
          orderBy('createdAt', 'desc')
        )
      : query(
          collection(db, 'Orders'),
          where('branch.id', '==', user?.branchSelect?.id),
          where('createdAt', '>=', start),
          orderBy('createdAt', 'desc')
        )
    : endDate
    ? query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )
    : query(
        collection(db, 'Orders'),
        where('branch.id', '==', user?.branchSelect?.id),
        orderBy('createdAt', 'desc')
      )

  return await (
    await getDocs(qOrders)
  ).docs.map((doc) => {
    const promotion = parseFloat(
      doc
        .data()
        ?.items.map((item) => {
          return item.promotion
            ? calPromotionDecodeUnitAmountPerItem(item)
            : null
        })
        .reduce((memo, sum) => memo + sum, 0)
    )
    return {
      ...doc.data(),
      id: doc.id,
      employee: doc.data()?.createdBy.name,
      discount: decodeUnit(doc.data()?.discountBalance),
      total: decodeUnit(doc.data()?.total),
      grandTotal: decodeUnit(doc.data()?.grandTotal),
      commission: 0,
      promotion: promotion,
      createdAt: doc
        .data()
        .createdAt.toDate()
        .toLocaleString('en-US', { hour12: false }),
    }
  })
}
