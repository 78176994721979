import { Center, Flex, Text, Image } from '@chakra-ui/react'
import { HSeparator } from 'Components/Horizons/separator/Separator'

export function SidebarBrand(props) {
  const { user } = props
  return (
    <Flex
      align='center'
      direction='column'
      borderTopRadius={{
        base: '15px',
        sm: '15px',
        md: '15px',
        lg: '15px',
        xl: '0px',
      }}
      bg={{
        base: 'white',
        sm: 'white',
        md: 'white',
        lg: 'white',
        xl: 'white',
      }}
      mt={{
        base: '-0.9rem',
        sm: '-0.9rem',
        md: '-0.9rem',
        lg: '-0.9rem',
        xl: '0rem',
      }}
      pt={{ base: '1rem', sm: '1rem', md: '1rem', lg: '0.5rem', xl: '0rem' }}
      pb={{ base: '1rem', sm: '1rem', md: '1rem', lg: '0.5rem', xl: '0rem' }}
    >
      <Center
        color={{
          base: 'white',
          sm: 'white',
          md: 'white',
          lg: 'white',
          xl: 'navbar.500',
        }}
        fontWeight='bold'
        mb={{ base: '5px', sm: '5px', md: '5px', lg: '10px', xl: '20px' }}
        zIndex='banner'
        flexDirection='column'
        letterSpacing={['0px', '0px', '1px', '1px', '1px', '1px']}
      >
        {/* <Text fontSize={{ base: '22px', sm: '22px', md: '22px', lg: '40px' }}>
          B-4
        </Text> */}
        <Center
          mt={{
            base: '.5rem',
            sm: '.5rem',
            md: '.5rem',
            lg: '.5rem',
            xl: '.5rem',
          }}
        >
          <Image
            w='8rem'
            h='full'
            alt='Logo'
            objectFit='cover'
            src='/goMaew/LOGO_GOMAEW-02.png'
          />
        </Center>
        <Text
          fontSize={{ base: '14px', sm: '14px', md: '14px', lg: '20px' }}
          fontFamily={'Prompt'}
          textAlign='center'
          maxW='250px'
          mt='.5rem'
          color='navbar.500'
        >
          {user?.branchSelect?.id === 'HQ'
            ? `${user?.branchSelect?.name}`
            : `สาขา ${user?.branchSelect?.name}`}
        </Text>
      </Center>
      <HSeparator
        display={{
          base: 'flex',
          sm: 'flex',
          md: 'flex',
          lg: 'flex',
          xl: 'flex',
        }}
        mt={{ base: '10px', sm: '10px', md: '10px', lg: '10px', xl: '10px' }}
        mb={{ base: '0px', sm: '0px', md: '0px', lg: '0px', xl: '10px' }}
      />
    </Flex>
  )
}

export default SidebarBrand
