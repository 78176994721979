import { Box, Portal, Stack } from '@chakra-ui/react'
import { Header } from 'Components/Core/header'
import Sidebar from 'Components/Core/sidebar'

function MainLayout({ children, user, title }) {
  return (
    <Box>
      <Sidebar user={user} />
      <Box
        float='right'
        minHeight='100vh'
        height='100%'
        overflow='auto'
        position='relative'
        maxHeight='100%'
        w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
        maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
        transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
        transitionDuration='.2s, .2s, .35s'
        transitionProperty='top, bottom, width'
        transitionTimingFunction='linear, linear, ease'
      >
        <Portal>
          <Box>
            <Header user={user} title={title} />
          </Box>
        </Portal>
        <Box
          mx='auto'
          p={{ base: '20px', md: '30px' }}
          pe='20px'
          minH='100vh'
          pt='50px'
        >
          <Stack
            pt={{ base: '3rem', md: '5rem', xl: '5rem' }}
            ml={{ base: '0', md: '0px', lg: '0px', xl: '20px' }}
          >
            {children}
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}

export default MainLayout
