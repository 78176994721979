import { Box, Center, Flex, Image, Text } from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import { currency, decodeUnit } from 'Utils/currency'
import { useRef } from 'react'
import '../../../Pages/Pos/components/cart.css'

function PosProductCard(props) {
  const {
    img,
    title,
    price,
    addCart,
    item,
    nameOption,
    // total,
    cartItems,
    mobile,
  } = props
  const ref = useRef()
  const handleClick = () => {
    if (mobile) {
      ref.current.classList.add('send-to-cart')
      setTimeout(() => {
        ref.current.classList.remove('send-to-cart')
      }, 1000)
    }
  }
  const handleAddCart = (itm) => {
    if (cartItems[item?.id]?.amount === undefined) {
      addCart(itm)
    }
    if (
      cartItems[item?.id]?.amount + (itm?.unit === 'piece' ? 1 : 0.5)
      // cartItems[item?.id]?.amount + (itm?.unit === 'piece' ? 1 : 0.5) <=
      // parseFloat(total)
    ) {
      addCart(itm)
    }
  }
  return (
    <Card
      p={{ md: '0px', lg: '0px', xl: '0px', '2xl': '0px' }}
      // cursor={
      //   cartItems[item?.id]?.amount + (item?.unit === 'piece' ? 1 : 0.5) <=
      //     parseFloat(total) || cartItems[item?.id]?.amount === undefined
      //     ? 'pointer'
      //     : 'not-allowed'
      // }
      cursor={'pointer'}
      w='auto'
      h='max-content'
      onClick={() => {
        handleAddCart(item)
        handleClick()
      }}
      bg='transparent'
      borderRadius={{ base: '10px', lg: '10px', '2xl': '15px' }}
    >
      <Text display='none'>{title}</Text>
      <Flex direction={{ base: 'column' }} justify='center'>
        <Box position='relative'>
          <Box
            w='full'
            h={{
              base: '7rem',
              sm: '7rem',
              md: '7rem',
              lg: '7rem',
              xl: '10rem',
              '2xl': '15rem',
            }}
            borderTopRadius={{ base: '10px', lg: '10px', '2xl': '15px' }}
          >
            {' '}
            <Image
              borderTopRadius={{ base: '10px', lg: '10px', '2xl': '15px' }}
              src={img || '/product_Thumbnail.png'}
              alt={title}
              w='full'
              h={{
                base: '7rem',
                sm: '7rem',
                md: '7rem',
                lg: '7rem',
                xl: '10rem',
                '2xl': '15rem',
              }}
              objectFit='cover'
              zIndex='base'
            />
            <Image
              display={{ base: 'flex', sm: 'flex', md: 'none', lg: 'none' }}
              borderTopRadius={{ base: '5px', lg: '5px', '2xl': '10px' }}
              src={img || '/product_Thumbnail.png'}
              alt={title}
              w='full'
              h={{
                base: '7rem',
                sm: '7rem',
                md: '7rem',
                lg: '7rem',
                xl: '10rem',
                '2xl': '15rem',
              }}
              objectFit='cover'
              ref={ref}
              className='cart-item'
            />
          </Box>

          <Center
            px='10px'
            flexDirection='column'
            justify='space-between'
            h='auto'
            py='10px'
            w='100%'
            bg='#fff'
            borderBottomRadius={{ base: '10px', lg: '10px', '2xl': '15px' }}
          >
            <Center flexDirection='column' alignItems='flex-start' w='100%'>
              <Text
                fontSize={{
                  base: '16px',
                  md: '16px',
                  lg: '16px',
                  xl: '16px',
                  '2xl': 'md',
                  '3xl': 'lg',
                }}
                userSelect='none'
                noOfLines='2'
              >
                {title}&nbsp;{nameOption}
              </Text>
              <Text
                fontSize={{
                  base: '16px',
                  md: '16px',
                  lg: '16px',
                  xl: '16px',
                  '2xl': 'md',
                  '3xl': 'lg',
                }}
                userSelect='none'
              >
                {currency(decodeUnit(price), 2, true)}
              </Text>
            </Center>
          </Center>
        </Box>
      </Flex>
    </Card>
  )
}

export default PosProductCard
