import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Center,
  Fade,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useAuth } from 'Hooks'
import Card from 'Components/Horizons/card/Card'
import { useNavigate } from 'react-router-dom'
import { BsPlus } from 'react-icons/bs'
import CustomTable from 'Components/Horizons/table/customTable'
import { HiPencil } from 'react-icons/hi'
import { useEffect, useRef, useState } from 'react'
import { DeleteIcon } from '@chakra-ui/icons'
import { useAppContext } from 'Context'

const DeleteUser = (props) => {
  const [loading, setLoading] = useState(false)
  const { setMessage, setError } = useAppContext()
  const { name, disabledUser, id, user, getAllUsers } = props
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure()
  const deleteUserRef = useRef()

  const handleDelete = () => {
    setLoading(true)
    disabledUser(id)
      .then(() => {
        setLoading(false)
        deleteOnClose()
        setMessage({
          type: 'success',
          message: 'ลบผู้ใช้งานสำเร็จ',
        })
        getAllUsers()
      })
      .catch((e) => {
        setLoading(false)
        deleteOnClose()
        setError({
          code: 'Delete User',
          message: e.message,
        })
      })
  }

  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={deleteUserRef}
        onClose={deleteOnClose}
        isOpen={deleteIsOpen}
        isCentered
        size='auto'
      >
        <AlertDialogOverlay />
        <AlertDialogContent
          w={{ sm: '20.5rem', md: '25rem' }}
          fontFamily={'Prompt'}
        >
          <AlertDialogHeader>คุณแน่ใจ?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>ต้องการลบผู้ใช้งาน {name}</AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={deleteUserRef}
              onClick={deleteOnClose}
              disabled={loading}
            >
              ไม่
            </Button>
            <Button
              colorScheme='red'
              ml={3}
              onClick={() => {
                handleDelete(id)
              }}
              isLoading={loading}
            >
              ใช่
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <IconButton
        color={'red'}
        icon={<DeleteIcon />}
        disabled={id === user?.uid && true}
        onClick={deleteOnOpen}
      />
    </>
  )
}

function StaffPage({ user }) {
  const { userList, disabledUser, fetchUserIsLoading, getAllUsers } = useAuth()
  const Navigate = useNavigate()
  const [isFadePage, setIsFadePage] = useState(true)

  useEffect(() => {
    setIsFadePage(false)
    getAllUsers()
    //eslint-disable-next-line
  }, [getAllUsers])

  const columnsDataOrders = [
    {
      Header: 'ชื่อ',
      accessor: 'name',
    },
    {
      Header: 'อีเมล',
      accessor: 'email',
    },
    {
      Header: 'เบอร์มือถือ',
      accessor: 'tel',
      extra: (data, row) => <Center>{row?.tel ? row?.tel : '-'}</Center>,
    },
    {
      Header: 'สิทธิ์การเข้าถึง',
      accessor: 'role',
    },
    {
      Header: 'สถานะ',
      accessor: 'status',
      extra: (data, row) => (
        <Center>
          <Badge
            colorScheme={row?.status === true ? 'green' : 'red'}
            color={row?.status === true ? 'green.500' : 'red.500'}
            fontSize='md'
            fontWeight='500'
          >
            {row?.status === true ? 'ใช้งาน' : 'ปิดการใช้งาน'}
          </Badge>
        </Center>
      ),
    },
    {
      Header: 'การจัดการ',
      accessor: 'id',
      extra: (data, row) => (
        <>
          <HStack spacing={'10px'}>
            <HiPencil
              color={'green.400'}
              fontSize={'20px'}
              onClick={() => {
                if (row?.status === true) {
                  Navigate(`/employees/edit/${row?.id}`)
                }
              }}
              cursor='pointer'
              fontWeight='500'
              id={row?.id}
            />
            <DeleteUser
              {...row}
              disabledUser={disabledUser}
              user={user}
              getAllUsers={getAllUsers}
            />
          </HStack>
        </>
      ),
    },
  ]

  return (
    <>
      <Stack direction='column'>
        {!fetchUserIsLoading ? (
          userList.length > 0 ? (
            <Card as={Fade} in={!isFadePage}>
              <CustomTable
                columnsData={columnsDataOrders}
                tableData={userList}
              />
            </Card>
          ) : (
            <Center>
              <Text>ไม่มีข้อมูลผู้ใช้งาน</Text>
            </Center>
          )
        ) : (
          <Box>
            <Center>
              <Spinner />
            </Center>
          </Box>
        )}
      </Stack>
      <Button
        colorScheme='btn'
        color='#ffff'
        to='/branchs/add'
        borderRadius='full'
        position='fixed'
        right={5}
        bottom={5}
        w={'50px'}
        h={'50px'}
        onClick={() => {
          Navigate('/employees/add')
        }}
      >
        <Icon as={BsPlus} fontSize={'45px'} />
      </Button>
    </>
  )
}

export default StaffPage
