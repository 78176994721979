import { Box, Flex, SimpleGrid, Stack } from '@chakra-ui/react'
import Profile from 'Components/Horizons/profile'
import React from 'react'

function UserProfile({ user }) {
  return (
    <Stack direction='column'>
      <Box>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, lg: 1 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          <Flex direction='column'>
            <Profile name='Vlad Mihalache' avatar={user?.img} />
          </Flex>
        </SimpleGrid>
      </Box>
    </Stack>
  )
}

export default UserProfile
