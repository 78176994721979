import { QrReader } from '@blackbox-vision/react-qr-reader'
import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import PosProductCard from 'Components/Product/Pos/card'
import PosProductCardSelect from 'Components/Product/Pos/itemSelect'
import { ViewFinder } from 'Components/viewFinder'
import { currency, decodeUnit } from 'Utils/currency'
import { useEffect, useMemo, useState } from 'react'
import { AiOutlineScan } from 'react-icons/ai'
import {
  IoChevronBackOutline,
  IoChevronForwardOutline,
  IoPricetagsOutline,
  IoTrashBin,
} from 'react-icons/io5'
import { search } from 'ss-search'
import _ from 'underscore'
import { calPromotionItems } from '../Utils/calPromotion'
import { calAmount, calPromotionAmount } from '../Utils/calcurate'
import PosCoupons from './Coupons'
import PosMultiplePaymentCount from './Payment/Multiple/posMultiplePaymentCount'
import PosbankGetRQ from './Payment/bankGetRQ'
import { PosBankReceived } from './Payment/bankReceived'
import PosBankSelect from './Payment/bankSelect'
import PosBankTakeSlip from './Payment/bankTakeSlip'
import PosCashReceived from './Payment/cashReceived'
import DateLaterPayment from './Payment/dateLaterPayment'
import PosSelectPayment from './Payment/selectPayment'
import PosSuccessCashSinglePayment from './Payment/successCashSinglePayment'

function MainPos(props) {
  const {
    cartItems,
    addCart,
    category,
    paymentStep,
    backStep,
    posUseDiscount,
    branchProductStock: productData,
    branchStockIsLoading: productIsLoading,
  } = props
  const [SearchResults, setSearchResults] = useState([])
  const [optionsSearch, setOptionsSearch] = useState({
    name: '',
    category: '',
  })
  const {
    isOpen: qrScanIsOpen,
    onOpen: qrScanOnOpen,
    onClose: qrScanOnClose,
  } = useDisclosure()

  const product = useMemo(() => {
    return productData
  }, [productData])

  const calItemCartTotalAmount = (carts) => {
    const pluckItemAmount = _.pluck(carts, 'amount')
    return _.reduce(pluckItemAmount, (memo, num) => {
      return memo + num
    }).toFixed(2)
  }

  //use effect ค้นหา
  useEffect(() => {
    const searchText = optionsSearch?.name
    const searchKey = ['name', 'nameOption', 'price']
    const findCategory = _.find(category, (i) => {
      return (
        i.id === optionsSearch?.category || i.name === optionsSearch?.category
      )
    })
    const searchQueryArr = product?.filter((item) => {
      return optionsSearch?.category !== ''
        ? item?.category === findCategory?.id ||
            item?.category === findCategory?.name
        : item
    })

    const result = search(searchQueryArr, searchKey, searchText)
    setSearchResults(result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsSearch])

  return (
    <>
      <Modal size='sm' isOpen={qrScanIsOpen} onClose={qrScanOnClose}>
        <ModalOverlay />
        <ModalContent minHeight='450px'>
          <ModalHeader>SCAN QR</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <QrReader
              constraints={{ facingMode: 'environment' }}
              ViewFinder={() => <ViewFinder />}
              onResult={(result, error) => {
                if (result) {
                  let itemId = (result?.text).split('/')
                  if (itemId) {
                    const filterData = _.filter(productData, (item) => {
                      return item.id === itemId[5]
                    })
                    if (filterData) {
                      addCart(filterData[0])
                      qrScanOnClose()
                    }
                  }
                  // let route = (result?.text).split('passport')
                  // history.push(`/passport${route[1]}`)
                }
              }}
              style={{ width: '100%' }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex
        display={{ base: 'none', md: 'flex' }}
        flexDirection='row'
        justifyContent={{ md: 'space-between', lg: 'space-between' }}
        w='100%'
      >
        <Flex flexDirection='column' w={{ md: '60%', lg: '71%', xl: '71%' }}>
          <Card px='0px' mb='15px'>
            <Flex
              direction='column'
              px={{ md: '15px', lg: '15px', xl: '30px', '2xl': '30px' }}
              align='end'
            >
              <HStack
                spacing='1rem'
                mt='20px'
                mb={{ md: '0px', lg: '0px', xl: '10px', '2xl': '10px' }}
                direction={{
                  base: 'column',
                  sm: 'column',
                  md: 'column',
                  lg: 'row',
                }}
                align={{ base: 'start', md: 'center' }}
                justifyContent='flex-start'
                w='100%'
                zIndex='base'
              >
                <Flex>
                  <InputGroup w={{ base: '100%', md: '220px', lg: '250px' }}>
                    <InputLeftElement
                      children={
                        <IconButton
                          bg='inherit'
                          borderRadius='inherit'
                          _hover='none'
                          _active={{
                            bg: 'inherit',
                            transform: 'none',
                            borderColor: 'transparent',
                          }}
                          _focus={{
                            boxShadow: 'none',
                          }}
                          icon={
                            <SearchIcon color={'gray.700'} w='15px' h='15px' />
                          }
                        ></IconButton>
                      }
                    />
                    <Input
                      variant='search'
                      fontSize='sm'
                      bg={'secondaryGray.300'}
                      color={'gray.700'}
                      fontWeight='500'
                      _placeholder={{
                        color: 'gray.400',
                        fontSize: '14px',
                        opacity: 1,
                      }}
                      borderRadius={'30px'}
                      placeholder={'Search...'}
                      onChange={(e) => {
                        setOptionsSearch({
                          ...optionsSearch,
                          name: e.target.value,
                        })
                      }}
                    />
                    <InputRightAddon
                      w='max-content'
                      bg={'secondaryGray.300'}
                      borderRadius='30px'
                      border='0px'
                      children={
                        <IconButton
                          // onClick={qrScanOnOpen}
                          onClick={() => qrScanOnOpen()}
                          border='0px'
                          borderLeft='1px solid #E0E5F2'
                          borderLeftRadius='0px'
                          bg={'secondaryGray.300'}
                          _hover='none'
                          w='max-content'
                          _focus={{
                            boxShadow: 'none',
                          }}
                        >
                          <AiOutlineScan
                            bg={'secondaryGray.300'}
                            color={'gray.700'}
                            fontSize='22px'
                          />
                        </IconButton>
                      }
                    />
                    {/* <InputRightAddon
                      bg={'secondaryGray.300'}
                      borderRadius={'30px'}
                      border='none'
                      color={'#707070'} 
                      _hover='none'
                      _focus={{
                        boxShadow: 'none',
                      }}
                      icon={
                        <AiOutlineScan color={'#707070'} w='15px' h='15px' />
                      }
                    /> */}
                  </InputGroup>
                </Flex>
                <Flex
                  align='center'
                  me='20px'
                  w={{ lg: '15rem', xl: '12rem', '2xl': '15rem' }}
                >
                  <Select
                    color={'gray.700'}
                    w='100%'
                    bg={'secondaryGray.300'}
                    border='0px'
                    fontWeight='500'
                    borderRadius={'30px'}
                    defaultValue=''
                    variant='main'
                    placeholder={'สินค้าทั้งหมด'}
                    _placeholder={{
                      fontWeight: '400',
                    }}
                    value={optionsSearch?.category}
                    h='44px'
                    maxh='44px'
                    onChange={(e) => {
                      setOptionsSearch({
                        ...optionsSearch,
                        category: e.target.value,
                      })
                    }}
                  >
                    {category?.map((item, i) => {
                      return (
                        <option value={item?.id} key={i}>
                          {item?.name}
                        </option>
                      )
                    })}
                  </Select>
                </Flex>
              </HStack>
            </Flex>
          </Card>
          <SimpleGrid
            columns={{ base: 1, md: 3, lg: 4, xl: 4 }}
            gap={{ md: '10px', lg: '10px', xl: '15px', '2xl': '20px' }}
          >
            {!productIsLoading
              ? optionsSearch.name !== '' || optionsSearch.category !== ''
                ? SearchResults?.length > 0 &&
                  SearchResults?.map((item, i) => {
                    return (
                      <PosProductCard
                        cartItems={_.indexBy(cartItems, 'id')}
                        total={item?.total}
                        nameOption={item?.nameOption}
                        img={item?.img}
                        title={item?.name}
                        price={item?.price}
                        id={item?.id}
                        key={i}
                        item={item}
                        addCart={addCart}
                      />
                    )
                  })
                : product?.length > 0 &&
                  product?.map((item, i) => {
                    return (
                      <PosProductCard
                        cartItems={_.indexBy(cartItems, 'id')}
                        total={item?.total}
                        nameOption={item?.nameOption}
                        img={item?.img}
                        title={item?.name}
                        price={item?.price}
                        id={item?.id}
                        item={item}
                        key={i}
                        addCart={addCart}
                      />
                    )
                  })
              : new Array(8).fill(null).map((i, key) => {
                  return (
                    <Stack key={key}>
                      <Skeleton isLoading={true}>
                        <Image src='blah' w={'100px'} h={'100px'} />
                      </Skeleton>
                      <SkeletonText mt='2' noOfLines={2} spacing='2' />
                    </Stack>
                  )
                })}
          </SimpleGrid>
        </Flex>
        <Flex
          flexDirection={{ md: 'column', lg: 'column', xl: 'column' }}
          gridArea={{ lg: 'auto', xl: '1 / 3 / 2 / 4', '2xl': '1 / 2 / 2 / 3' }}
          justifyContent='flex-start'
          w={{ md: '38%', lg: '27%', xl: '27%' }}
        >
          <Card
            px='0px'
            h='auto'
            borderTopRadius='30px'
            borderBottomRadius='30px'
            pt='0px'
            border='0px'
          >
            <Flex
              align={{ sm: 'flex-start', lg: 'center' }}
              justify='space-between'
              w='100%'
              px='22px'
              py='18px'
              bg='pos.100'
              h='5rem'
              borderTopRadius={'20px'}
            >
              {paymentStep.cart && (
                <Text
                  color='white'
                  fontSize={'xl'}
                  fontWeight='normal'
                  userSelect='none'
                  display='flex'
                >
                  การสั่งซื้อ&#160;
                  {cartItems?.length > 0 && (
                    <Box>
                      {currency(
                        parseFloat(calItemCartTotalAmount(cartItems)),
                        0
                      )}
                      &nbsp;รายการ
                    </Box>
                  )}
                </Text>
              )}
              {!paymentStep.cart && !paymentStep.successPayment && (
                <HStack
                  w='100%'
                  mt={{
                    base: '-2rem',
                    sm: '-2rem',
                    md: '-2rem',
                    lg: '-0.5rem',
                    xl: '0rem',
                  }}
                >
                  <IoChevronBackOutline
                    fontSize='28px'
                    color='white'
                    cursor={'pointer'}
                    onClick={() => {
                      backStep()
                    }}
                  />
                  {paymentStep.selectCoupons && (
                    <Text
                      color='white'
                      fontSize={'xl'}
                      fontWeight='normal'
                      userSelect='none'
                      display='flex'
                    >
                      โปรโมชั่นส่วนลด
                    </Text>
                  )}
                  {paymentStep.promotionsProduct && (
                    <Text
                      color='white'
                      fontSize={'xl'}
                      fontWeight='normal'
                      userSelect='none'
                      display='flex'
                    >
                      ส่วนลดสินค้า
                    </Text>
                  )}

                  {!paymentStep.selectCoupons &&
                    !paymentStep.promotionsProduct && (
                      <Box w='75%'>
                        <Center w='100%' h='7rem' flexDirection='column'>
                          <Text fontSize='30px' color={'white'}>
                            {/* {posUseDiscount
                              ? currency(
                                  calAmount(cartItems, posUseDiscount) -
                                    calPromotionAmount(cartItems),
                                  2,
                                  true
                                )
                              : currency(
                                  calAmount(cartItems) -
                                    calPromotionAmount(cartItems),
                                  2,
                                  true
                                )} */}
                            {currency(
                              calPromotionItems(cartItems, posUseDiscount)?.sum,
                              2,
                              true
                            )}
                          </Text>
                          <Text fontWeight='normal' color={'white'}>
                            จำนวนเงินที่ต้องชำระ
                          </Text>
                        </Center>
                      </Box>
                    )}
                </HStack>
              )}
            </Flex>
            {/* ตะกร้าสินค้า */}
            {paymentStep.cart && <PaymentFirstStep {...props} />}
            {/* เลือกช่องทางการชำระเงิน */}
            {paymentStep.selectPayment && <PosSelectPayment {...props} />}
            {/*คูปองส่วนลด*/}
            {paymentStep.selectCoupons && (
              <PosCoupons {...props} stockProduct={productData} />
            )}
            {/* จ่ายเดี่ยว */}
            {paymentStep.cashReceived && <PosCashReceived {...props} />}
            {paymentStep.bankReceived && <PosBankReceived {...props} />}
            {paymentStep.bankSelect && <PosBankSelect {...props} />}
            {paymentStep.bankGetQR && <PosbankGetRQ {...props} />}
            {paymentStep.bankTakeSlip && <PosBankTakeSlip {...props} />}
            {paymentStep.laterPayment && <DateLaterPayment {...props} />}
            {paymentStep.successPayment && (
              <PosSuccessCashSinglePayment {...props} />
            )}
            {/* แยกจ่าย */}
            {paymentStep.multiplePayment &&
              paymentStep.multiplePaymentCount && (
                <PosMultiplePaymentCount {...props} />
              )}
          </Card>
        </Flex>
      </Flex>
    </>
  )
}

export default MainPos

const PaymentFirstStep = (props) => {
  const { cartItems, setPaymentStep, posUseDiscount, removePromotionItem } =
    props
  return (
    <>
      <Flex flexDirection='column'>
        <Box maxH='50vh' overflowX='auto' pb='5rem'>
          <SimpleGrid
            columns={{ base: 1, md: 1 }}
            gap={{ lg: '10px', xl: '15px' }}
            p={{ md: '1.25rem', lg: '1.25rem' }}
          >
            {cartItems?.length < 1 ? (
              <Box>ไม่มีข้อมูลการสั่งซื้อ</Box>
            ) : (
              <>
                {cartItems?.map((item, i) => {
                  return (
                    <PosProductCardSelect
                      total={item.total}
                      key={i}
                      item={item}
                      {...props}
                    />
                  )
                })}
              </>
            )}
          </SimpleGrid>
        </Box>

        <Box mt='2rem' px='1rem' color='pos.500'>
          <Box mb={'10px'}>
            <HStack justifyContent={'space-between'}>
              <Text>รวมทั้งหมด</Text>
              <Text>
                {currency(
                  calAmount(cartItems) - calPromotionAmount(cartItems),
                  2
                )}
              </Text>
            </HStack>
          </Box>

          <Flex
            mt={'.5rem'}
            justifyContent={'space-between'}
            align='center'
            onClick={() => {
              setPaymentStep({
                selectCoupons: true,
              })
            }}
            cursor={'pointer'}
          >
            <HStack>
              <Text fontSize={'lg'} bg='#FFF3E7' p='5px' borderRadius='full'>
                <IoPricetagsOutline color='#FF6501' />
              </Text>
              <Text fontSize={'lg'}>โปรโมชั่น</Text>
            </HStack>
            <Text color={'#A3AED0'}>
              <IoChevronForwardOutline />
            </Text>
          </Flex>

          <Box>
            {/* โปรโมชั่นส่วนลด */}
            {posUseDiscount?.length > 0 && (
              <>
                <Divider mt={'10px'} mb={'10px'} />
                <Box mt={'.5rem'}>
                  {_.map(posUseDiscount, (i, itemIndex) => {
                    return (
                      <HStack justifyContent={'space-between'} key={itemIndex}>
                        <HStack>
                          <Text
                            fontSize={'md'}
                            bg='#FFF3E7'
                            p='5px'
                            borderRadius='full'
                            color={'red'}
                            cursor={'pointer'}
                            onClick={() => {
                              removePromotionItem(itemIndex, i)
                            }}
                          >
                            <IoTrashBin />
                          </Text>
                          <Text fontSize={'md'} color='#FF6501'>
                            {i.discountName}
                          </Text>
                        </HStack>
                        <Text fontSize={'md'} color='#FF6501'>
                          {i.type === 'discount'
                            ? i.valueDiscountType === 'Bath'
                              ? currency(
                                  parseFloat(decodeUnit(i.valueDiscount)),
                                  2,
                                  true
                                )
                              : i.valueDiscountType === 'Percentage'
                              ? `${currency(
                                  parseFloat(decodeUnit(i.valueDiscount)),
                                  0
                                )}%`
                              : null
                            : i.type === 'free'
                            ? i.categoryFree === null && i.productFree === null
                              ? calPromotionItems(cartItems, [i])?.discount
                              : `${currency(0, 2)}`
                            : '-'}
                        </Text>
                      </HStack>
                    )
                  })}
                </Box>
              </>
            )}

            <Divider mt={'10px'} mb={'10px'} />
            {posUseDiscount?.length > 0 && (
              <Flex flexDirection={'row'} justify='space-between'>
                <Text fontSize={'md'}>รวมส่วนลด</Text>
                <Text fontSize={'md'} color='#FF6501'>
                  {currency(
                    calPromotionItems(cartItems, posUseDiscount)?.discount,
                    2
                  )}
                </Text>
              </Flex>
            )}

            <Flex flexDirection={'row'} justify='space-between'>
              <Text
                fontSize={{ md: 'xl', lg: '18px', xl: '18px', '2xl': 'xl' }}
              >
                รวมเป็นเงิน
              </Text>
              <Text
                fontSize={{ md: 'xl', lg: '18px', xl: '18px', '2xl': 'xl' }}
              >
                {calPromotionItems(cartItems, posUseDiscount)?.sum > 0
                  ? currency(
                      calPromotionItems(cartItems, posUseDiscount)?.sum,
                      2,
                      true
                    )
                  : 0}
              </Text>
            </Flex>
          </Box>
          <Button
            colorScheme={'btn'}
            w='full'
            mt={'1.25rem'}
            fontSize={'xl'}
            borderRadius={'5px'}
            disabled={
              cartItems?.length < 1 ||
              calPromotionItems(cartItems, posUseDiscount)?.sum < 0
            }
            onClick={() => {
              setPaymentStep({
                selectPayment: true,
              })
            }}
          >
            ชำระเงิน
          </Button>
        </Box>
      </Flex>
    </>
  )
}
