import { Stack } from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'

function SettingsInfomation() {
  return (
    <Stack>
      <Card>SettingsInfomation</Card>
    </Stack>
  )
}

export default SettingsInfomation
