import {
  Box,
  Button,
  Flex,
  useColorModeValue,
  Text,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormErrorMessage,
  Fade,
  Center,
  Icon,
  Image,
  Spinner,
  VStack,
} from '@chakra-ui/react'
import Select from 'react-select'
import Card from 'Components/Horizons/card/Card'
import InputField from 'Components/Horizons/fields/InputField'
import { useBranch, usePayment } from 'Hooks'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ImQrcode } from 'react-icons/im'
import { useNavigate } from 'react-router-dom'
import Dropzone from '../components/dropzone'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import RadioField from 'Components/Horizons/fields/RadioField'
import makeAnimated from 'react-select/animated'
import _ from 'underscore'

function FormSettingPaymentBank({ id, data, paymentThumbnail, user }) {
  const { createBankPayment, updateBankPaymentById } = usePayment()
  const [isFadePage, setIsFadePage] = useState(true)
  const [file, setFile] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)
  const Navigate = useNavigate()
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100')
  const brand = useColorModeValue('payment.500', 'payment.500')
  const bg = useColorModeValue('gray.100', 'navy.700')
  const [isLoading, setIsLoading] = useState(true)
  const animatedComponents = makeAnimated()
  const { Branch, getBranchData } = useBranch()
  const [allBranch, setAllBranch] = useState([])
  const [options, setOptions] = useState([])
  const [bank, setBank] = useState()
  const [allBank, setAllBank] = useState([])

  useEffect(() => {
    getBranchData()
  }, [getBranchData])

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      accountingNumber: '',
      bankBranch: '',
      accountName: '',
      typeOfAccount: '',
    },
  })

  function handleChange(event) {
    const value = event
    setOptions(value?.map((item) => ({ id: item.value, name: item.label })))
    clearErrors()
  }
  function handleChangeBank(event) {
    setBank(event)
    clearErrors()
  }

  async function onSubmit(val) {
    setIsSubmit(true)
    if (!bank && bank === undefined) {
      setError('bank', { type: 'required', message: 'กรุณาเลือกธนาคาร' })
      setIsSubmit(false)
    } else if (options?.length < 1 && val?.paymentBranchType === 'branch') {
      setError('branch', { type: 'required', message: 'กรุณาเลือกสาขา' })
      setIsSubmit(false)
    } else {
      const filterBank = _.filter(paymentThumbnail, (i) => {
        return i.id === bank.value
      })
      if (val.paymentBranchType === 'default') {
        setOptions([])
        val.branchs = null
      } else {
        val.branchs = options
      }
      val.bank = filterBank[0]
      const image = file || ''
      if (id) {
        await updateBankPaymentById(id, val, image)
          .then(() => {
            setIsSubmit(false)
            Navigate('/settings/payment/list')
          })
          .catch((error) => {
            console.log(error)
            setIsSubmit(false)
          })
      } else {
        await createBankPayment(val, image)
          .then(() => {
            setIsSubmit(false)
            Navigate('/settings/payment/list')
          })
          .catch((err) => {
            console.log(err)
            setIsSubmit(false)
          })
      }
    }
  }
  const typeBranch = watch('paymentBranchType')
  useEffect(() => {
    if (data) {
      setValue('accountingNumber', data?.accountingNumber)
      setValue('bankBranch', data?.bankBranch)
      setValue('accountName', data?.accountName)
      setValue('typeOfAccount', data?.typeOfAccount)
      setValue('paymentBranchType', data?.paymentBranchType)
      setOptions(
        data?.branchs?.map((item) => ({
          id: item.id,
          name: item.name,
        }))
      )
      setBank({
        value: data.bank.id,
        label: data.bank.name,
      })
    }
    const BranchValue = []
    const BankValue = []

    _.forEach(
      Branch.filter((branch) => branch.id !== 'HQ'),
      (doc) => {
        BranchValue.push({
          value: doc.id,
          label: doc.name,
          isdisabled: false,
        })
      }
    )
    _.forEach(paymentThumbnail, (doc) => {
      BankValue.push({
        value: doc.id,
        label: doc.name,
        isdisabled: false,
      })
    })
    setAllBranch(BranchValue)
    setAllBank(BankValue)

    setTimeout(() => {
      setIsLoading(false)
      setTimeout(() => {
        setIsFadePage(false)
        setIsLoadingImage(false)
      }, 200)
    }, 200)
  }, [data, setValue, Branch, paymentThumbnail])
  return (
    <>
      <Flex direction='column' minH='100vh' align='center' position='relative'>
        {!isLoading ? (
          <>
            <Box
              h='25vh'
              bg='payment.700'
              position='absolute'
              w={{ sm: '100vw', md: '100%', lg: '100%' }}
              borderRadius={{ sm: '0px', md: '30px', lg: '30px' }}
            ></Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card
                p={{ base: '15px', sm: '15px', md: '30px' }}
                px={{ base: '30px', sm: '30px', md: '30px' }}
                mt={{ sm: '2.5rem', md: '4rem', lg: '4rem' }}
                w={{ sm: '100vw', md: '600px', lg: '800px' }}
                as={Fade}
                in={!isFadePage}
              >
                <Box
                  pb='0.3rem'
                  zIndex='overlay'
                  color='payment.500'
                  onClick={() => Navigate('/settings/payment/list')}
                  w='max-content'
                  display={{ sm: 'flex', md: 'none' }}
                >
                  <Text
                    display={{ sm: 'flex', md: 'none' }}
                    fontSize='24px'
                    cursor='pointer'
                  >
                    <ChevronLeftIcon />
                  </Text>
                </Box>
                <Text
                  color={textColor}
                  fontSize='2xl'
                  fontWeight='700'
                  align={'center'}
                  mb='20px'
                >
                  {id ? 'แก้ไขข้อมูลบัญชีธนาคาร' : 'เพิ่มบัญชีธนาคาร'}
                </Text>
                <Flex direction='column' w='100%'>
                  <Stack spacing='20px'>
                    <FormControl>
                      <Center w='100%'>
                        {file ? (
                          <Center>
                            <Dropzone
                              w='20rem'
                              setFile={setFile}
                              content={
                                <Box w='100%'>
                                  <Center
                                    as={Fade}
                                    in={!isLoadingImage}
                                    align='center'
                                    justify='center'
                                    bg={bg}
                                    borderColor={borderColor}
                                    maxW='100%'
                                    h='max-content'
                                    maxH='200px'
                                    cursor='pointer'
                                    flexDirection='column'
                                  >
                                    <Text
                                      fontSize='sm'
                                      fontWeight='500'
                                      color='secondaryGray.500'
                                    >
                                      {' '}
                                      <Text
                                        fontSize='sm'
                                        mt='1.5rem'
                                        fontWeight='500'
                                        color='secondaryGray.500'
                                      >
                                        {' '}
                                      </Text>
                                    </Text>
                                    <Image
                                      maxH='200px'
                                      src={file}
                                      objectFit='cover'
                                    />
                                  </Center>
                                </Box>
                              }
                            />
                          </Center>
                        ) : (
                          <Center>
                            <Dropzone
                              w='20rem'
                              setFile={setFile}
                              content={
                                data?.img ? (
                                  <Box>
                                    <Center
                                      as={Fade}
                                      in={!isLoadingImage}
                                      align='center'
                                      justify='center'
                                      bg={bg}
                                      borderColor={borderColor}
                                      w='100%'
                                      maxW='100%'
                                      h='max-content'
                                      maxH='150px'
                                      cursor='pointer'
                                      flexDirection='column'
                                    >
                                      <Text
                                        fontSize='sm'
                                        fontWeight='500'
                                        color='secondaryGray.500'
                                      >
                                        {' '}
                                        <Text
                                          fontSize='sm'
                                          mt='1.5rem'
                                          fontWeight='500'
                                          color='secondaryGray.500'
                                        >
                                          {' '}
                                        </Text>
                                      </Text>
                                      <Image
                                        maxH='150px'
                                        src={data?.img}
                                        objectFit='cover'
                                      />
                                    </Center>
                                  </Box>
                                ) : (
                                  <Center w='100%' flexDirection='column'>
                                    <Text
                                      fontSize='sm'
                                      fontWeight='500'
                                      color='secondaryGray.500'
                                    >
                                      {' '}
                                      <Text
                                        fontSize='sm'
                                        mt='1.5rem'
                                        fontWeight='500'
                                        color='secondaryGray.500'
                                      >
                                        {' '}
                                      </Text>
                                    </Text>
                                    <Icon
                                      as={ImQrcode}
                                      w='40px'
                                      h='40px'
                                      color={textColor}
                                    />
                                    <Text
                                      mx='auto'
                                      mt='12px'
                                      fontSize={{ base: '16px', md: '16px' }}
                                      fontWeight='700'
                                      whiteSpace='pre-wrap'
                                      color={'#494D51'}
                                    >
                                      อัพโหลดรูป QR Code รับชำระเงิน
                                    </Text>
                                    <Text
                                      mt='4px'
                                      mx='auto'
                                      fontSize={{ base: '16px', md: '16px' }}
                                      fontWeight='700'
                                      whiteSpace='pre-wrap'
                                      color={brand}
                                    >
                                      คลิก
                                    </Text>
                                  </Center>
                                )
                              }
                            />
                          </Center>
                        )}
                      </Center>
                    </FormControl>

                    {/* <Controller
                      name='bank'
                      control={control}
                      render={({ field }) => ( */}
                    <FormControl isInvalid={errors['bank']}>
                      <Flex direction='column'>
                        <FormLabel
                          ms='10px'
                          htmlFor='bank'
                          fontSize='sm'
                          color={textColor}
                          fontWeight='bold'
                          _hover={{ cursor: 'pointer' }}
                        >
                          ธนาคาร
                        </FormLabel>
                        <Select
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          onChange={handleChangeBank}
                          options={allBank}
                          placeholder='เลือกธนาคาร...'
                          defaultValue={bank}
                        />
                      </Flex>
                      <FormErrorMessage>
                        {errors['bank'] && errors['bank']?.message}
                      </FormErrorMessage>
                    </FormControl>
                    {/* )}
                    /> */}

                    <InputField
                      control={control}
                      errors={errors}
                      mb='0px'
                      id='accountingNumber'
                      name='accountingNumber'
                      label='เลขที่บัญชี'
                      type='text'
                      maxLength={15}
                      minLength={10}
                      required={{
                        value: true,
                        message: 'กรุณากรอกเลขที่บัญชี',
                      }}
                      pattern={{
                        value: /[0-9]/,
                        message: 'กรุณากรอกตัวเลขเท่านั้น',
                      }}
                    />

                    <InputField
                      control={control}
                      errors={errors}
                      mb='0px'
                      id='bankBranch'
                      name='bankBranch'
                      label='สาขาธนาคาร'
                      type='text'
                      required={{
                        value: true,
                        message: 'กรุณากรอกสาขาธนาคาร',
                      }}
                    />

                    <InputField
                      control={control}
                      errors={errors}
                      mb='0px'
                      id='accountName'
                      label='ชื่อบัญชีธนาคาร'
                      name='accountName'
                      type='text'
                      required={{
                        value: true,
                        message: 'กรุณากรอกชื่อบัญชีธนาคาร',
                      }}
                    />

                    <Controller
                      name={'typeOfAccount'}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'กรุณาเลือก',
                        },
                      }}
                      render={({ field }) => (
                        <FormControl isInvalid={errors['typeOfAccount']}>
                          <FormLabel
                            display='flex'
                            ms='10px'
                            htmlFor={'image'}
                            fontSize='sm'
                            color={textColorPrimary}
                            fontWeight='bold'
                            _hover={{ cursor: 'pointer' }}
                          >
                            <Text fontSize='sm' fontWeight='blod' ms='2px'>
                              ประเภทบัญชี
                            </Text>
                          </FormLabel>
                          <RadioGroup
                            pt={{
                              sm: '0.3rem',
                              md: '0.3rem',
                              lg: '0.75rem',
                            }}
                            onChange={field.onChange}
                            value={field.value}
                            colorScheme='green'
                          >
                            <Flex
                              flexDirection={{
                                sm: 'column',
                                md: 'column',
                                lg: 'row',
                              }}
                            >
                              <Radio value='ออมทรัพย์'>ออมทรัพย์</Radio>
                              <Radio
                                pl={{ sm: '0rem', md: '0rem', lg: '1rem' }}
                                value='กระแสรายวัน'
                              >
                                กระแสรายวัน
                              </Radio>
                              <Radio
                                pl={{ sm: '0rem', md: '0rem', lg: '1rem' }}
                                value='ฝากประจำ'
                              >
                                ฝากประจำ
                              </Radio>
                            </Flex>
                          </RadioGroup>
                          <FormErrorMessage>
                            {errors['typeOfBranch'] &&
                              errors['typeOfBranch']?.message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                    <VStack w='full' pt={'25px'}>
                      <Stack
                        w='full'
                        h='full'
                        pr={{
                          base: '0rem',
                          sm: '0rem',
                          md: '0.5rem',
                          lg: '1rem',
                        }}
                        justify='space-between'
                        alignItems='end'
                      >
                        <RadioField
                          name={'paymentBranchType'}
                          control={control}
                          errors={errors}
                          id={'paymentBranchType'}
                          label={'ใช้ได้กับสาขาใด'}
                          mb={'6px'}
                          options={[
                            { name: 'ทุกสาขา', value: 'default' },
                            { name: 'บางสาขา', value: 'branch' },
                          ]}
                        />
                      </Stack>
                      <Stack
                        w='full'
                        direction={{ base: 'column', md: 'row' }}
                        // pt={{ sm: '10px', md: '20px', lg: '20px' }}
                      >
                        {user.branchSelect?.id === 'HQ' &&
                          typeBranch === 'branch' && (
                            <Box pt='1em' w='100%'>
                              <FormControl isInvalid={errors['branch']}>
                                <Select
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  isMulti
                                  onChange={handleChange}
                                  options={allBranch}
                                  placeholder='เลือกสาขา...'
                                  defaultValue={options?.map((item) => ({
                                    value: item?.id,
                                    label: item?.name,
                                  }))}
                                />
                                <FormErrorMessage>
                                  {errors['branch'] &&
                                    errors['branch']?.message}
                                </FormErrorMessage>
                              </FormControl>
                            </Box>
                          )}
                      </Stack>
                    </VStack>
                    {/* <Controller
                      name={'default'}
                      id={'default'}
                      control={control}
                      render={({ field }) => (
                        <FormControl isInvalid={errors['default']}>
                          <FormLabel
                            display='flex'
                            ms='10px'
                            htmlFor={'image'}
                            fontSize='sm'
                            color={textColorPrimary}
                            fontWeight='bold'
                            _hover={{ cursor: 'pointer' }}
                          >
                            <Text fontSize='sm' fontWeight='blod' ms='2px'>
                              ค่าเริ่มต้น
                            </Text>
                          </FormLabel>
                          <Checkbox
                            colorScheme='green'
                            onChange={field.onChange}
                            isChecked={field.value}
                          >
                            ธนาคารเริ่มต้น
                          </Checkbox>
                          <FormErrorMessage>
                            {errors['default'] && errors['default']?.message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    /> */}
                  </Stack>

                  <Flex justify='space-between' mt='30px'>
                    <Button
                      variant='light'
                      fontSize='sm'
                      borderRadius='16px'
                      w={{ base: '110px', md: '148px' }}
                      h='46px'
                      onClick={() => Navigate('/settings/payment/list')}
                    >
                      ย้อนกลับ
                    </Button>
                    <Button
                      type='submit'
                      colorScheme='btn'
                      color='#ffff'
                      fontSize='sm'
                      borderRadius='16px'
                      w={{ base: '110px', md: '148px' }}
                      h='46px'
                      ms='auto'
                      isLoading={isSubmit}
                      variant='solid'
                    >
                      บันทึก
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </form>
          </>
        ) : (
          <>
            <Center>
              <Spinner />
            </Center>
          </>
        )}
      </Flex>
    </>
  )
}

export { FormSettingPaymentBank }
