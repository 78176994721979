import React from 'react'
import {
  Box,
  Flex,
  Text,
  Grid,
  GridItem,
  Center,
  Input,
  Button,
} from '@chakra-ui/react'
import { MdDateRange } from 'react-icons/md'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import th from 'date-fns/locale/th'
registerLocale('th', th)

function DateLaterPayment(props) {
  const {
    confirmLoading,
    payLaterNote,
    setPayLaterNote,
    confirmlaterPayment,
    startDate,
    setStartDate,
  } = props

  return (
    <>
      <Flex p='1rem' flexDirection='column'>
        <Text
          color='#4299E1'
          pb='0.5rem'
          pt={{ base: '2rem', sm: '2rem', md: '1rem' }}
        >
          ระบุวันที่นัดชำระ
        </Text>
        <Box
          borderTop='1px solid #E8E9E9'
          borderBottom='1px solid #E8E9E9'
          cursor='pointer'
        >
          <Grid templateColumns='repeat(2, 1fr)' h='6rem' gap={0}>
            <GridItem w='100%' h='full'>
              <Center h='full'>
                <MdDateRange color='#4299E1' fontSize='20px' />
              </Center>
            </GridItem>
            <GridItem h='full' w='100%'>
              <Center
                justifyContent='flex-start'
                h='full'
                fontSize='14px'
                color={'black'}
              >
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  minDate={new Date()}
                  locale={'th'}
                  dateFormat='d/M/yyyy'
                />
              </Center>
            </GridItem>
          </Grid>
        </Box>
        <Box borderTop='1px solid #E8E9E9' borderBottom='1px solid #E8E9E9'>
          <Grid templateColumns='repeat(2, 1fr)' h='6rem' gap={0}>
            <GridItem w='100%' h='full'>
              <Center h='full' fontSize='14px' color={'black'}>
                หมายเหตุ
              </Center>
            </GridItem>
            <GridItem w='100%' h='full'>
              <Center
                justifyContent='flex-start'
                h='full'
                fontSize='14px'
                color={'black'}
                zIndex='overlay'
              >
                <Input
                  w='85%'
                  variant='flushed'
                  placeholder=''
                  onChange={(e) => {
                    setPayLaterNote(e.target.value)
                  }}
                  value={payLaterNote}
                />
              </Center>
            </GridItem>
          </Grid>
        </Box>
      </Flex>
      <Center
        pt={{ base: '6rem', sm: '6rem', md: '2rem' }}
        display={{
          base: 'none',
          sm: 'none',
          md: 'flex',
          lg: 'flex',
          xl: 'flex',
          '2xl': 'flex',
        }}
        bottom='0px'
        w='100%'
      >
        <Box p='0.3rem' w='100%'>
          <Button
            w='100%'
            colorScheme={'btn'}
            color='white'
            py='1.7rem'
            bg='#4299E1'
            borderRadius='5px'
            disabled={confirmLoading}
            loadingText={'Loading...'}
            isLoading={confirmLoading}
            onClick={() => {
              confirmlaterPayment('payLater')
            }}
          >
            เสร็จสิ้น
          </Button>
        </Box>
      </Center>
      <Center
        display={{
          base: 'flex',
          sm: 'flex',
          md: 'none',
          lg: 'none',
          xl: 'none',
          '2xl': 'none',
        }}
        position='absolute'
        bottom='0px'
        w='100%'
        alignItems='flex-end'
      >
        <Box p='0.3rem' w='100%'>
          <Button
            w='100%'
            colorScheme={'btn'}
            color='white'
            py='1.7rem'
            bg='#4299E1'
            borderRadius='5px'
            disabled={confirmLoading}
            loadingText={'Loading...'}
            isLoading={confirmLoading}
            onClick={() => {
              confirmlaterPayment('payLater')
            }}
          >
            เสร็จสิ้น
          </Button>
        </Box>
      </Center>
    </>
  )
}

export default DateLaterPayment
