import { useState } from 'react'
import {
  Box,
  Center,
  Flex,
  HStack,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import Card from 'Components/Horizons/card/Card'
import ButtonResponsive from 'Components/Button/btn'
import { First } from './receipt/first'

function SecondReceipt({
  setReceipt,
  watch,
  discount,
  setDiscount,
  control,
  errors,
  isSubmit,
}) {
  const [first, setFirst] = useState(false)
  return (
    <>
      <Box display={first ? 'none' : 'block'}>
        <Center>
          <Card
            padding={{
              base: '10px 0px 30px 0px',
              sm: '10px 0px 30px 0px',
              md: '10px 20px 30px 20px',
            }}
            w={{ base: '100%', sm: '100%', md: '30rem' }}
            boxShadow={{
              base: 'none',
              sm: 'none',
              md: '14px 17px 40px 4px rgb(112 144 176 / 8%)',
            }}
          >
            <HStack>
              <ChevronLeftIcon
                cursor='pointer'
                color='form.500'
                fontSize={'2xl'}
                onClick={() => {
                  setReceipt(false)
                }}
              />
              <Text
                fontSize={{ base: 'md', sm: 'md', md: 'lg' }}
                fontWeight='bold'
              >
                มูลค่าส่วนลด
              </Text>
            </HStack>
            <VStack
              pb={{ base: '0rem', sm: '0rem', md: '1rem' }}
              mt='1rem'
              justifyContent='start'
              alignItems='flex-start'
            >
              <Text color='table.100'>โปรดระบุยอดการสั่งซื้อขั้นต่ำ</Text>

              <Input
                variant='main'
                type={'number'}
                step={'any'}
                min={0}
                value={discount?.minimumOrder || ''}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  e.preventDefault()
                  let value = parseFloat(parseFloat(e.target.value)?.toFixed(2))
                  setDiscount({ ...discount, minimumOrder: value })
                }}
              />
            </VStack>
            <Flex w='100%' justifyContent='flex-end'>
              <ButtonResponsive
                disabled={discount?.minimumOrder ? false : true}
                label={'ถัดไป'}
                onClick={() => {
                  setFirst(true)
                }}
              />
            </Flex>
          </Card>
        </Center>
      </Box>
      {first && (
        <First
          watch={watch}
          setFirst={setFirst}
          setReceipt={setReceipt}
          discount={discount}
          setDiscount={setDiscount}
          control={control}
          errors={errors}
          isSubmit={isSubmit}
        />
      )}
    </>
  )
}

export { SecondReceipt }
