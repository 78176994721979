import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import ButtonResponsive from 'Components/Button/btn'
import Card from 'Components/Horizons/card/Card'
import { db } from 'Config/firebase'
import { useAppContext } from 'Context'
import { doc, updateDoc } from 'firebase/firestore'
import { useEffect } from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { InfoModalCategory } from './components/categoryModal'
import {
  encodeUnit,
  switchTextPromotionDiscountTypeSecond,
  switchTextPromotionType,
} from 'Utils'
import { ChevronLeftIcon } from '@chakra-ui/icons'

export function DiscountCategory({ data, id }) {
  const [discount, setDiscount] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)
  const { user } = useAppContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      setDiscount(data)
    }
  }, [data, id])

  const handleChange = (value, data) => {
    const dataValue = data.find((item) => item.id === value)
    setDiscount({
      ...discount,
      category: dataValue,
      CategoryAmount: 1,
    })
    return discount
  }

  const { handleSubmit } = useForm()

  function onSubmit() {
    setIsSubmit(true)
    updateDoc(doc(db, 'Promotion', id), {
      ...discount,
      valueDiscount: encodeUnit(discount?.valueDiscount)?.toString(),
      updatedAt: new Date(),
      updatedBy: {
        id: user?.uid,
        name: user?.name,
      },
    }).then(() => {
      setIsSubmit(false)
      navigate('/discount/store')
    })
  }
  return (
    <Center>
      <Card
        padding={{
          base: '10px 0px 30px 0px',
          sm: '10px 0px 30px 0px',
          md: '10px 20px 30px 20px',
        }}
        w={{ base: '100%', sm: '100%', md: '40rem' }}
        boxShadow={{
          base: 'none',
          sm: 'none',
          md: '14px 17px 40px 4px rgb(112 144 176 / 8%)',
        }}
      >
        <HStack w='100%'>
          <ChevronLeftIcon
            cursor='pointer'
            color='form.500'
            fontSize={'2xl'}
            onClick={() => navigate('/discount/store')}
          />
        </HStack>
        <VStack
          alignItems='start'
          px={{ base: '0em', sm: '0em', md: '2em' }}
          borderBottom='1px'
          borderColor='#E0E5F2'
          py='2em'
        >
          <HStack alignItems='flex-start'>
            <Text minW='5.8rem'>รูปแบบส่วนลด</Text>
            <Text>:</Text>
            <Text color='discount.500'>
              {switchTextPromotionType(data?.type)}
            </Text>
          </HStack>
          <HStack alignItems='flex-start'>
            <Text minW='5.8rem'>ระดับส่วนลด</Text>
            <Text>:</Text>
            <Text color='discount.500'>
              {switchTextPromotionDiscountTypeSecond(data?.typeDiscount)}
            </Text>
          </HStack>
        </VStack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            borderBottom='1px'
            borderColor='#E0E5F2'
            py='2em'
            px={{ base: '0em', sm: '0em', md: '2em' }}
          >
            <Text
              fontSize={{ base: 'md', sm: 'md', md: 'lg' }}
              fontWeight='bold'
            >
              รายละเอียดโปรโมชั่น
            </Text>
            <Grid
              mt='1rem'
              templateColumns={{
                base: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
              gap={{ base: '15px', sm: '15px', md: '24px' }}
            >
              <GridItem w='100%'>
                <VStack alignItems='flex-start' w='100%'>
                  <Text>ชื่อโปรโมชั่น</Text>
                  <Input
                    variant='main'
                    value={discount?.discountName || null}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      e.preventDefault()
                      let value = e.target.value
                      setDiscount({ ...discount, discountName: value })
                    }}
                  />
                </VStack>
                <VStack
                  w='100%'
                  mt='1rem'
                  justifyContent='start'
                  alignItems='flex-start'
                >
                  <RadioGroup
                    onChange={(e) => {
                      setDiscount({ ...discount, valueDiscountType: e })
                    }}
                    value={discount?.valueDiscountType}
                  >
                    <Stack direction='row'>
                      <Radio colorScheme='green' value='Percentage'>
                        เปอร์เซ็นต์
                      </Radio>
                      <Radio colorScheme='green' value='Bath'>
                        บาท
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  <Input
                    variant={'main'}
                    type={'number'}
                    step={'any'}
                    min={0}
                    value={discount?.valueDiscount || null}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      e.preventDefault()
                      let value = parseFloat(
                        parseFloat(e.target.value)?.toFixed(2)
                      )
                      setDiscount({ ...discount, valueDiscount: value })
                    }}
                  />
                </VStack>
              </GridItem>
              <GridItem w='100%'>
                <VStack alignItems='flex-start' w='100%'>
                  <Text>รายละเอียดโปรโมชั่น</Text>
                  <Textarea
                    border='1px solid #e0e5f2'
                    borderRadius={{
                      base: '10px',
                      sm: '10px',
                      md: '10px',
                      lg: '16px',
                    }}
                    variant={'no-effects'}
                    value={discount?.discountDetail || null}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      e.preventDefault()
                      let value = e.target.value
                      setDiscount({ ...discount, discountDetail: value })
                    }}
                  />
                </VStack>
                <Flex justifyContent='space-between' w='100%' mt='1rem'>
                  <Text>เปิด-ปิด </Text>
                  <Switch
                    defaultChecked={discount?.status || false}
                    isChecked={discount?.status}
                    colorScheme='teal'
                    onChange={(e) => {
                      let value = e.target.checked
                      setDiscount({ ...discount, status: value })
                    }}
                  />
                </Flex>
              </GridItem>
            </Grid>
          </Box>
          <Box
            borderBottom='1px'
            borderColor='#E0E5F2'
            py='2em'
            px={{ base: '0em', sm: '0em', md: '2em' }}
          >
            <Text
              fontSize={{ base: 'md', sm: 'md', md: 'lg' }}
              fontWeight='bold'
            >
              เลือกหมวดหมู่จัดโปรโมชั่นส่วนลด
            </Text>
            <InfoModalCategory
              handleChange={handleChange}
              discount={discount}
              setDiscount={setDiscount}
              value={discount?.category?.id}
              label={discount?.category ? 'เปลี่ยน' : '+ เลือกรายการสินค้า'}
              children={
                discount?.category ? (
                  <Center>
                    <Text>หมวดหมู่ : {discount?.category?.name}</Text>
                  </Center>
                ) : null
              }
            />
          </Box>
          <Box pt='1rem'>
            <HStack
              spacing={{ base: '0rem', sm: '0rem', md: '2rem' }}
              flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
              alignItems='flex-start'
            >
              <Text>สร้างโดย : {data?.createdBy?.name} </Text>
              <Text>
                วันที่ : {data?.createdAt?.toDate().toLocaleString('en-US')}
              </Text>
            </HStack>
            <HStack
              spacing={{ base: '0rem', sm: '0rem', md: '2rem' }}
              flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
              alignItems='flex-start'
            >
              <Text>
                แก้ไขโดย : {data?.updatedBy ? data?.updatedBy?.name : '-'}
              </Text>
              <Text>
                วันที่ :{' '}
                {data?.updatedAt
                  ? data?.updatedAt?.toDate().toLocaleString('en-US')
                  : '-'}
              </Text>
            </HStack>
          </Box>
          <HStack py='2em' justifyContent='end'>
            <ButtonResponsive
              disabled={discount?.discountName ? false : true}
              label={'บันทึก'}
              type={'submit'}
              isLoading={isSubmit}
              loadingText={'กำลังบันทึก...'}
            />
          </HStack>
        </form>
      </Card>
    </Center>
  )
}
