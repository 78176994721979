import React, { useState } from 'react'
import {
  HStack,
  Input,
  Select,
  Flex,
  Text,
  Box,
  Button,
  Stack,
  Heading,
  Icon,
  Center,
  GridItem,
  Grid,
} from '@chakra-ui/react'
import DatePicker from 'react-datepicker'
import { ChevronDownIcon, ChevronUpIcon, SearchIcon } from '@chakra-ui/icons'
import 'react-datepicker/dist/react-datepicker.css'
import ExpandTable from './components/ExpandTable'
import moment from 'moment'
import { useBranch, useInventory, useOrder } from 'Hooks'
import { calStockTotal } from 'Utils/calStock'
import { currency, decodeUnit } from 'Utils/currency'
const _ = require('underscore')

function Price(props) {
  const { data: priecData } = props
  const calGrandTotal = _.reduce(
    _.pluck(priecData, 'grandTotal'),
    (memo, num) => {
      return memo + num
    },
    0
  )

  return (
    <Grid
      templateColumns={{
        sm: 'repeat(2, 1fr)',
        md: 'repeat(5, 1fr)',
        lg: 'repeat(4, 1fr)',
        xl: 'repeat(6, 1fr)',
        '2xl': 'repeat(6, 1fr)',
      }}
      justifyItems={{ sm: 'center', md: 'end' }}
      gap={{ md: 6, lg: 3, xl: 0 }}
    >
      <GridItem>
        <Stack
          alignItems={{ sm: 'center', md: 'left' }}
          w={{ sm: 'full', md: 'max-content' }}
        >
          <Box>
            <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
              ยอดขายรวม
            </Text>
          </Box>
          <Box>
            <Text fontSize={{ base: '12px', md: '16px' }} color='report.500'>
              {calGrandTotal
                ? currency(calGrandTotal, 2, true)
                : currency(0, 2, true)}
            </Text>
          </Box>
          <Box></Box>
        </Stack>
      </GridItem>
    </Grid>
  )
}

function Pricedesktop(props) {
  const { data: priecData } = props
  const calGrandTotal = _.reduce(
    _.pluck(priecData, 'grandTotal'),
    (memo, num) => {
      return memo + num
    },
    0
  )

  return (
    <HStack spacing='2rem'>
      <Box>
        <Stack
          alignItems={{ sm: 'center', md: 'left' }}
          w={{ sm: 'full', md: 'max-content' }}
        >
          <Box>
            <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
              ยอดขายรวม
            </Text>
          </Box>
          <Box>
            <Text fontSize={{ base: '12px', md: '16px' }} color='report.500'>
              {calGrandTotal
                ? currency(calGrandTotal, 2, true)
                : currency(0, 2, true)}
            </Text>
          </Box>
          <Box></Box>
        </Stack>
      </Box>
    </HStack>
  )
}

function ReportProduct() {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const { getAllOrderInHQNoLimit } = useOrder()
  const { Branch } = useBranch()
  const { Alldata: inventoryDatas } = useInventory()
  const [selectBranch, setSelectBranch] = useState('')
  const [filterData, setFilterData] = useState([])
  const [onSearch, setOnSearch] = useState(false)

  const onSubmitFilter = async () => {
    // const res = onFilterReportProduct(data, selectBranch, startDate, endDate)
    // setOnSearch(true)
    // setFilterData(res)

    return getAllOrderInHQNoLimit(startDate, endDate, selectBranch).then(
      async (u) => {
        const cal1 = await new Promise(async (resolve) => {
          const dataCal = await calData(u, inventoryDatas, Branch)
          return resolve(dataCal)
        })
        setOnSearch(true)
        setFilterData(cal1)
      }
    )
  }

  const calData = async (ordersReport, inventoryDatas, branch) => {
    const groupBranchOrder = _.groupBy(ordersReport, (item) => {
      return item['branch']['id']
    })
    const groupInventory = _.groupBy(inventoryDatas, (e) => {
      return e.branch + '#' + e.id
    })

    //คำนวณสต๊อกสินค้า
    const inventoryStockAmount =
      (await Promise.all(
        await _.map(groupInventory, async (e, i) => {
          const total = await calStockTotal(e)
          const branchSplit = i.split('#')

          return {
            branch: branchSplit[0],
            item: branchSplit[1],
            total: parseFloat(total),
          }
        })
      )) || []
    const inventoryGroup = _.groupBy(inventoryStockAmount, 'branch')

    //คำนวณผลรวมการซื้อสินค้าจาก order
    const productItemamount = await Promise.all(
      _.map(groupBranchOrder, async (orderItem, branchOrderKey) => {
        const itemsOrders = []
        _.map(orderItem, (item) => {
          if (item?.status !== 'refund') {
            return itemsOrders.push(...item.items)
          }
        })
        const groupItemsOrder = _.groupBy(itemsOrders, 'id')
        const groupItemsOrderCalAmount =
          (await Promise.all(
            _.map(groupItemsOrder, async (gio, gioi) => {
              const calculate = _.reduce(
                _.pluck(gio, 'amount'),
                (memo, num) => {
                  return memo + parseFloat(decodeUnit(num))
                },
                0
              )
              const totalData =
                (await Promise.all(
                  _.filter(inventoryGroup[branchOrderKey], (i) => {
                    return i.item === gioi
                  })
                )) || []

              return {
                idProduct: gio[0]['sku'],
                productName:
                  gio[0]?.name + (gio[0]?.nameOption ? gio[0]?.nameOption : ''),
                amount: calculate,
                cost: decodeUnit(gio[0]['cost']) * calculate,
                inventory: parseFloat(totalData[0]?.total),
                total: parseFloat(decodeUnit(gio[0]['price']) * calculate),
                grandTotal: parseFloat(decodeUnit(gio[0]['price']) * calculate),
              }
            })
          )) || []

        const filterBranch = _.filter(branch, (j) => {
          return j.id === branchOrderKey
        })
        const calGrandTotal = _.reduce(
          _.pluck(groupItemsOrderCalAmount, 'grandTotal'),
          (memo, num) => {
            return memo + num
          },
          0
        )
        const calCommission = _.reduce(
          _.pluck(groupItemsOrderCalAmount, 'commission'),
          (memo, num) => {
            return memo + num
          },
          0
        )
        const calCost = _.reduce(
          _.pluck(groupItemsOrderCalAmount, 'cost'),
          (memo, num) => {
            return memo + num
          },
          0
        )
        const sortGroupItemsOrderCalAmount = _.sortBy(
          groupItemsOrderCalAmount,
          'amount'
        ).reverse()

        return {
          branch: filterBranch[0]?.name,
          subRows: sortGroupItemsOrderCalAmount,
          discount: null,
          grandTotal: calGrandTotal,
          total: null,
          amount: null,
          cost: null,
          idProduct: null,
          inventory: null,
          productName: null,
          calCommission: calCommission,
          calCost: calCost,
        }
      })
    )
    return productItemamount
  }
  return (
    <>
      <HStack
        my='1em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='1vw'
        flexDirection={{ sm: 'column', md: 'row' }}
      >
        <Flex flexDirection='column' w={{ sm: 'full', md: '30vw', lg: '30vw' }}>
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ช่วงเวลา :</Text>
          </Box>
          <Flex bg='#F4F7FE' borderRadius='16px'>
            <Input
              cursor='pointer'
              border='0px'
              w='full'
              textAlign='center'
              as={DatePicker}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText={'วันที่เริ่มต้น'}
            />
            <Input
              cursor='pointer'
              border='0px'
              w='full'
              textAlign='center'
              as={DatePicker}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText={'วันที่สิ้นสุด'}
            />
          </Flex>
        </Flex>
        <Flex
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        >
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>สาขา :</Text>
          </Box>
          <Select
            color={'gray.700'}
            w='full'
            bg={'#F4F7FE'}
            border='0px solid #E8E9E9'
            borderRadius='16px'
            defaultValue=''
            fontWeight='500'
            variant='main'
            placeholder={'ทั้งหมด'}
            _placeholder={{
              fontWeight: '400',
            }}
            h='44px'
            maxh='44px'
            onChange={(event) => setSelectBranch(event.target.value)}
          >
            {Branch.map(
              (item, index) =>
                item.id !== 'HQ' && (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                )
            )}
          </Select>
        </Flex>
        <Flex
          display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        ></Flex>
        <Flex
          flexDirection='column'
          w={{ sm: 'full', md: '19vw', lg: '13vw', xl: '11vw' }}
          alignItems='end'
          pt={{ sm: '1rem', md: '0rem' }}
        >
          <Box
            textAlign='start'
            display={{ sm: 'none', md: 'flex' }}
            p='0.5rem'
            pl='1rem'
            pt='0rem'
          >
            <Text color='#8F9BBA'>&nbsp;</Text>
          </Box>
          <Button
            h='44px'
            maxh='44px'
            w={{ sm: '10rem', md: 'full' }}
            colorScheme='btn'
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            onClick={() => onSubmitFilter()}
          >
            <Text>
              <SearchIcon />
            </Text>
            <Text>แสดงรายงาน</Text>
          </Button>
        </Flex>
      </HStack>
      <Flex
        flexDirection='column'
        p={{ sm: '10px', md: '20px 20px 30px 20px' }}
        bg='white'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
        borderRadius='30px'
      >
        <Stack spacing={{ base: '0em', md: '4em' }}>
          <Stack>
            <Heading
              fontFamily={'Prompt'}
              color='#2B3674'
              fontSize={{ base: '24px', md: '30px' }}
            >
              รายงานยอดขายตามสินค้า
            </Heading>
            <Box>
              <HStack>
                <Text color='#2B3674'>ณ วันที่:</Text>
                <Text color='#2B3674'>
                  {moment(new Date().getTime()).format('DD/MM/YY')}
                </Text>
              </HStack>
              <HStack>
                <Text color='#2B3674'>ช่วงเวลา:</Text>
                {startDate && endDate ? (
                  <Text color='#2B3674'>
                    {moment(startDate).format('MM/DD/YYYY')} -{' '}
                    {moment(endDate).format('MM/DD/YYYY')}
                  </Text>
                ) : (
                  <Text color='#2B3674'>-</Text>
                )}
              </HStack>
            </Box>
          </Stack>{' '}
          <Box>
            <Flex
              w='100%'
              justifyContent='end'
              pr='1.5rem'
              display={{ base: 'none', sm: 'none', md: 'flex' }}
            >
              <Pricedesktop data={filterData} />
            </Flex>
            <Grid
              display={{ base: 'grid', sm: 'grid', md: 'none' }}
              pt={{ base: '2rem', sm: '2rem', md: '1rem', lg: '0rem' }}
              pr={{
                base: '0rem',
                sm: '0rem',
                md: '1rem',
                lg: '1rem',
                xl: '2rem',
              }}
              templateColumns={{
                sm: 'repeat(1, 1fr)',
                md: 'repeat(1, 1fr)',
                lg: 'repeat(2, 1fr)',
                xl: 'repeat(1, 1fr)',
                '2xl': 'repeat(2, 1fr)',
              }}
            >
              <GridItem w='100%'></GridItem>
              <GridItem w='100%'>
                <Price data={filterData} />
              </GridItem>
            </Grid>
          </Box>
        </Stack>
        <Box overflowX='auto'>
          <Box w={{ md: '1400px', lg: '1400px', xl: '1500px', '2xl': 'full' }}>
            <ExpandTable
              columnsData={columnsData}
              tableData={onSearch ? filterData : []}
            />
          </Box>
        </Box>
      </Flex>
    </>
  )
}

export default ReportProduct

const columnsData = [
  {
    // Build our expander column
    id: 'expander', // Make sure it has an ID
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      <Text {...getToggleAllRowsExpandedProps()}>
        {isAllRowsExpanded ? (
          <Icon as={ChevronUpIcon} />
        ) : (
          <Icon as={ChevronDownIcon} />
        )}
      </Text>
    ),
    Cell: ({ row }) =>
      row.canExpand ? (
        <Center>
          <Text
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            {row.isExpanded ? (
              <Icon as={ChevronUpIcon} />
            ) : (
              <Icon as={ChevronDownIcon} />
            )}
          </Text>
        </Center>
      ) : null,
  },
  {
    Header: 'สาขา',
    accessor: 'branch',
    Cell: ({ row }) => (
      <Box>
        <Text>{row.original.branch}</Text>
      </Box>
    ),
  },
  {
    Header: 'รหัสสินค้า',
    accessor: 'idProduct',
    Cell: ({ row }) => (
      <Center>
        <Text>{row.original.idProduct}</Text>
      </Center>
    ),
  },
  {
    Header: 'ชื่อสินค้า',
    accessor: 'productName',
    Cell: ({ row }) => (
      <Center>
        <Text>{row.original.productName}</Text>
      </Center>
    ),
  },
  {
    Header: 'จำนวนที่ขาย',
    accessor: 'amount',
    Cell: ({ row }) => (
      <Center>
        <Text>{row.original.amount && currency(row.original.amount, 2)}</Text>
      </Center>
    ),
  },
  {
    Header: 'คลัง',
    accessor: 'inventory',
    Cell: ({ row }) => (
      <Center>
        <Text>
          {row.original.inventory ? currency(row.original.inventory, 2) : ''}
        </Text>
      </Center>
    ),
  },
  {
    Header: 'ราคาต้นทุน',
    accessor: 'cost',
    Cell: ({ row }) => (
      <Center justifyContent='flex-end' pr='5em'>
        <Text>
          {row.original.cost ? currency(row.original.cost, 2, true) : ''}
        </Text>
      </Center>
    ),
  },
  {
    Header: 'ยอดขาย',
    accessor: 'total',
    Cell: ({ row }) => (
      <Center justifyContent='flex-end' pr='5em'>
        <Text>
          {row.original.total ? currency(row.original.total, 2, true) : ''}
        </Text>
      </Center>
    ),
  },
  // {
  //   Header: 'ยอดขายสุทธิ',
  //   accessor: 'grandTotal',
  //   Cell: ({ row }) => (
  //     <Center justifyContent='flex-end' pr='5em'>
  //       <Text>
  //         {row.original.grandTotal
  //           ? currencyDecimal(row.original.grandTotal)
  //           : ''}
  //       </Text>
  //     </Center>
  //   ),
  // },
]
