import { Stack } from '@chakra-ui/react'
import { FormStaffAdd } from 'Components/form/formStaffAdd'
import React from 'react'

function AddStaff() {
  return (
    <Stack direction='column'>
      <FormStaffAdd id={null} data={null} />
    </Stack>
  )
}

export default AddStaff
