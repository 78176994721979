import React, { useEffect, useState } from 'react'
import {
  Box,
  useToast,
  Center,
  Image,
  Text,
  Button,
  VStack,
} from '@chakra-ui/react'
import { useStoreRoute } from 'Routes'
import { useRoutes } from 'react-router-dom'
import { useAppContext } from 'Context'
import { onAuthStateChanged } from 'firebase/auth'
import { auth, db } from 'Config/firebase'
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore'
import moment from 'moment-timezone'
import './app.css'
import _ from 'underscore'
moment.tz.setDefault('Asia/Bangkok')

const version = '1.0.2'

function App() {
  const { routes } = useStoreRoute()
  const element = useRoutes(routes)
  const [versionDetect, setLastesVersion] = useState(true)
  const [dbVersion, setDBVersion] = useState({})
  const toast = useToast()
  const {
    error,
    setError,
    message,
    setMessage,
    setUser,
    mainLoading,
    setMainLoading,
  } = useAppContext()

  useEffect(() => {
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        let idToken = await authUser.getIdTokenResult()
        let claims = idToken?.claims
        onSnapshot(doc(db, 'Users', authUser?.uid), (doc) => {
          const userData = doc.data()
          delete userData?.branch
          delete userData?.branchSelect
          delete userData?.idCard
          delete userData?.tel
          delete userData?.createdAt
          delete userData?.updatedAt
          setUser({
            role: claims?.role,
            ...authUser,
            ...doc.data(),
            profile: userData,
          })
          setMainLoading(false)
        })
      } else {
        setUser(null)
        setMainLoading(false)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const queryVersion = query(
      collection(db, 'Versions'),
      orderBy('createdAt', 'desc'),
      limit(1)
    )
    const unsubscribeVersion = onSnapshot(queryVersion, (snapshot) => {
      let data = []
      snapshot?.forEach((version) => {
        data.push({
          id: version.id,
          ...version.data(),
        })
      })
      if (data[0]?.version === version) {
        setDBVersion(_.first(data))
        setLastesVersion(true)
      } else {
        setDBVersion(_.first(data))
        setLastesVersion(false)
      }
    })
    return () => {
      unsubscribeVersion()
    }
  }, [])

  useEffect(() => {
    if (error?.message) {
      toast({
        position: 'top',
        description: error.message,
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      setError({ code: '', message: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (message?.message) {
      toast({
        position: 'top',
        description: message.message,
        status: message.type,
        duration: 3000,
        isClosable: true,
      })
      setMessage({ message: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message])

  return (
    <>
      {versionDetect ? (
        !mainLoading ? (
          <Box
            minH={{ base: '100%', sm: '100%', md: '100vh' }}
            fontFamily={'Prompt'}
          >
            {element}
          </Box>
        ) : (
          <Center h={'100vh'} w='100%' bg='#FFEBD7'>
            <Center flexDirection='column'>
              <VStack>
                <Image
                  w={{ base: '15rem', sm: '15rem', md: '25rem' }}
                  objectFit='cover'
                  alt='logo'
                  src='/goMaew/LOGO_GOMAEW-02.png'
                />
                <Box
                  mt={{ base: '-60px', sm: '-60px', md: '-80px' }}
                  mx='0px'
                  className='loader'
                  position='relative'
                  color={'#821E2D'}
                />
              </VStack>
            </Center>
          </Center>
        )
      ) : (
        <Center h={'100vh'} w='100%' bg='#FFEBD7'>
          <Center flexDirection='column'>
            <Image
              w={{ base: '15rem', sm: '15rem', md: '25rem' }}
              objectFit='cover'
              alt='logo'
              src='/goMaew/LOGO_GOMAEW-02.png'
            />
            <Text color={'#821E2D'} mt={{ base: '10px', md: '15px' }}>
              System Update
            </Text>
            <Text color={'#821E2D'}>
              {Object.keys(dbVersion)?.length > 0
                ? `Your Version ${version} LastVersion ${
                    dbVersion?.version ? dbVersion?.version : 'Error'
                  }`
                : 'Please Check Network Connection!'}
            </Text>
            <Button
              bg={'#821E2D'}
              color={'#FFEBD7'}
              _hover={{
                bg: '#9D3143',
              }}
              variant={'outline'}
              onClick={() => {
                window.location.reload()
              }}
            >
              คลิกเพื่อรีเฟรช (Click To Refresh)
            </Button>
          </Center>
        </Center>
      )}
    </>
  )
}

export default App
