// Chakra imports
import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Select,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

function SelectField({
  id,
  label,
  extra,
  placeholder,
  type,
  mb,
  name,
  control,
  options,
  errors,
  validate,
  required,
  pattern,
  Length,
  path,
  defaultValue,
  disabled,
  ...rest
}) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const Navigate = useNavigate()
  return (
    <FormControl id={name} isInvalid={errors[name]} key={name}>
      <Flex direction='column' mb={mb ? mb : '30px'}>
        <FormLabel
          display='flex'
          ms='10px'
          htmlFor={id}
          fontSize='sm'
          color={textColorPrimary}
          fontWeight='bold'
          _hover={{ cursor: 'pointer' }}
        >
          {label}
          <Text fontSize='sm' fontWeight='400' ms='2px'>
            {extra}
          </Text>
        </FormLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={{
            required,
            pattern,
            validate,
            maxLength: Length,
          }}
          render={({ field: { onChange, value } }) => (
            <>
              {options?.length > 0 ? (
                <Select
                  {...rest}
                  value={value}
                  onChange={onChange}
                  type={type}
                  id={id}
                  defaultValue=''
                  fontWeight='500'
                  variant='main'
                  placeholder={placeholder}
                  _placeholder={{
                    fontWeight: '400',
                    color: 'secondaryGray.600',
                  }}
                  h='44px'
                  maxh='44px'
                  disabled={disabled}
                >
                  {options?.map((itm, index) => (
                    <option key={index} value={itm?.id ? itm?.id : itm}>
                      {itm?.name ? itm?.name : itm}
                    </option>
                  ))}
                </Select>
              ) : (
                <Box>
                  <Button
                    w={'full'}
                    h='44px'
                    maxh='44px'
                    borderRadius={'1em'}
                    border={'1px'}
                    borderColor={'product.500'}
                    color={'product.500'}
                    fontWeight={'normal'}
                    rightIcon={<Icon as={ChevronRightIcon} fontSize='1.2em' />}
                    onClick={() => Navigate(path)}
                  >
                    <HStack>
                      <Text w={'full'}>เพิ่ม{label}</Text>
                    </HStack>
                  </Button>
                  <Text position={'absolute'} fontSize={'xs'} color='red'>
                    * กรุณาเพิ่มตัวเลือก {label}
                  </Text>
                </Box>
              )}
            </>
          )}
        />
      </Flex>
      <FormErrorMessage>
        {errors[name] && errors[name]?.message}
      </FormErrorMessage>
    </FormControl>
  )
}

export default SelectField
