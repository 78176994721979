import React from 'react'
import {
  Box,
  Center,
  Text,
  HStack,
  Button,
  Image,
  VStack,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react'
const _ = require('underscore')

function PosBankSelect(props) {
  const { paymentData, setPosUseBank, posUseBank, setPaymentStep } = props
  const option = _.pluck(paymentData, 'id')
  function handleChange(value) {
    const data = paymentData.find((itm) => itm?.id === value)
    setPosUseBank(data)
  }
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'id',
    defaultValue: posUseBank ? posUseBank.id : '',
    onChange: handleChange,
  })

  const group = getRootProps()

  function RadioCard(props) {
    const data = paymentData?.find((itm) => itm?.id === props?.value)
    const { getInputProps, getCheckboxProps } = useRadio(props)
    const input = getInputProps()
    const checkbox = getCheckboxProps()

    return (
      <Box
        as='label'
        w='100%'
        zIndex={{ base: 'base', sm: 'base', md: 'base', lg: 'base' }}
      >
        <input {...input} />
        <HStack
          py='1rem'
          borderBottom='1px solid #E8E9E9'
          w='100%'
          spacing={{ base: '1rem', sm: '1rem', md: '0.5rem' }}
          justifyContent='space-between'
          px={{ base: '1rem', sm: '1rem', md: '0.5rem' }}
        >
          <Center w={{ base: '10rem', sm: '10rem', md: '6rem' }}>
            <Image
              alt='bank'
              objectFit='cover'
              w={{ base: '3.3rem', sm: '3.3rem', md: '2.5rem' }}
              h={{ base: '3.3rem', sm: '3.3rem', md: '2.5rem' }}
              borderRadius='full'
              src={data.bank.imgUrl}
            />
          </Center>
          <VStack alignItems='flex-start' w='full' spacing='0px'>
            <Text>{data.bank.name}</Text>
            <Text>{data.accountingNumber}</Text>
            <Text>{data.accountName}</Text>
          </VStack>
          <Center w={{ base: '10rem', sm: '10rem', md: '2rem' }}>
            <Center
              {...checkbox}
              w={{ base: '1.2rem', sm: '1.2rem', md: '1rem' }}
              h={{ base: '1.2rem', sm: '1.2rem', md: '1rem' }}
              cursor='pointer'
              borderWidth='1px'
              borderRadius='full'
              boxShadow='md'
              _checked={{
                bg: 'pos.500',
                color: 'white',
                borderColor: 'pos.500',
              }}
              _focus={{
                boxShadow: 'outline',
                border: 'none',
              }}
            >
              <Box
                borderRadius='full'
                w={{ base: '0.6rem', sm: '0.6rem', md: '0.5rem' }}
                h={{ base: '0.6rem', sm: '0.6rem', md: '0.5rem' }}
                bg='white'
              ></Box>
            </Center>
          </Center>
        </HStack>
      </Box>
    )
  }

  return (
    <>
      <VStack
        {...group}
        h={{ base: '70%', sm: '70%', md: '50vh' }}
        overflowX='auto'
      >
        {option.map((value) => {
          const radio = getRadioProps({ value })
          return (
            <RadioCard key={value} {...radio}>
              {value}
            </RadioCard>
          )
        })}
      </VStack>

      <Center
        pt={{ base: 'none', md: '0rem' }}
        display={{
          base: 'flex',
          sm: 'flex',
          md: 'flex',
          lg: 'flex',
          xl: 'flex',
          '2xl': 'flex',
        }}
        position={{ base: 'absolute', md: 'none' }}
        // h={{ base: '100vh', md: 'none' }}
        bottom='0px'
        w='100%'
        alignItems={{ base: 'flex-end', md: 'none' }}
      >
        <Box p='0.3rem' w='100%'>
          <Button
            w='100%'
            color='white'
            py='1.7rem'
            colorScheme='btn'
            borderRadius='5px'
            onClick={() => {
              setPaymentStep({ bankReceived: true })
            }}
          >
            ยืนยันการชำระเงิน
          </Button>
        </Box>
      </Center>
    </>
  )
}

export default PosBankSelect
