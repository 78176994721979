import { generate } from 'referral-codes'

function useGenerateId() {
  const IdOnlyNumber = (number) => {
    return generate({
      length: number,
      count: 1,
      charset: '0123456789',
    })
  }

  const IdTextUpperCase = (number) => {
    return generate({
      length: number,
      count: 1,
      charset: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    })
  }

  const IdTextLowerCase = (number) => {
    return generate({
      length: number,
      count: 1,
      charset: 'abcdefghijklmnopqrstuvwxyz',
    })
  }

  const IdTextLowerCaseUpperCase = (number) => {
    return generate({
      length: number,
      count: 1,
      charset: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
    })
  }

  const IdNumberWithText = (number) => {
    return generate({
      length: number,
      count: 1,
      charset: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
    })
  }

  const IdNumberWithTextUpperCase = (number) => {
    return generate({
      length: number,
      count: 1,
      charset: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    })
  }

  return {
    IdOnlyNumber,
    IdTextUpperCase,
    IdTextLowerCase,
    IdTextLowerCaseUpperCase,
    IdNumberWithText,
    IdNumberWithTextUpperCase,
  }
}

export { useGenerateId }
