const _ = require('underscore')
export function calculateCost(item) {
  const calSubRow = _.reduce(item, (value, item) => value + item.cost, 0)

  return calSubRow
}

export function calculateSales(item) {
  const calSubRow = _.reduce(item, (value, item) => value + item.grandTotal, 0)

  return calSubRow
}

export function calculateCommission(item) {
  const calSubRow = _.reduce(item, (value, item) => value + item.commission, 0)

  return calSubRow
}

export function calculateDiff(item) {
  const calSubRow = _.reduce(item, (value, item) => value + item.diff, 0)

  return calSubRow
}

export function calculateSalesStaff(item) {
  const subRow = _.flatten(_.pluck(item, 'subRows'))
  const calSubRow = _.reduce(subRow, (value, item) => value + item.sales, 0)

  return calSubRow
}
