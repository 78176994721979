import {
  Flex,
  Center,
  FormControl,
  FormLabel,
  Input,
  Button,
  useColorModeValue,
  Text,
  Icon,
  InputGroup,
  InputRightElement,
  Image,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { RiEyeCloseLine } from 'react-icons/ri'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from 'Config/firebase'
import { useAppContext } from 'Context'
const schema = yup.object().shape({
  email: yup.string().required('กรุณากรอกอีเมล'),
  password: yup.string().required('กรุณากรอกรหัสผ่าน'),
})

export default function Login() {
  const textColor = useColorModeValue('navy.700', '#FFEBD7')
  const textColorSecondary = 'gray.400'
  const [isLoading, setIsLoading] = useState(false)
  const { setError, user } = useAppContext()
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)
  const { handleSubmit, register } = useForm({
    resolver: yupResolver(schema),
  })

  async function onSubmit({ email, password }) {
    setIsLoading(true)
    await signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        if (user) {
          setIsLoading(false)
        }
      })
      .catch((errors) => {
        if (errors) {
          switch (errors.code) {
            case 'auth/user-not-found':
              setError({ code: 'auth-login', message: `ไม่พบผู้ใช้งาน` })
              break
            case 'auth/wrong-password':
              setError({ code: 'auth-login', message: `รหัสผ่านไม่ถูกต้อง` })
              break
            case 'auth/user-disabled':
              setError({
                code: 'auth-login',
                message: `ผู้ใช้นี้ถูกปิดการใช้งาน`,
              })
              break
            case 'auth/invalid-email':
              setError({ code: 'auth-login', message: `ไม่พบอีเมล` })
              break
            default:
              setError({ code: 'auth-login', message: errors.message })
          }
        }
        setIsLoading(false)
      })
  }

  return (
    <Center w='100%' h='100vh' justifyContent='center' flexDirection='column'>
      <Center h='max-content' flexDirection='column'>
        <Center>
          <Image
            w={{ base: '40%', sm: '40%', md: '30%', lg: '20%', xl: '20%' }}
            h='auto'
            alt='Logo'
            objectFit='cover'
            src='/goMaew/LOGO_GOMAEW-02.png'
          />
        </Center>
        <Center w='100%' flexDirection='column' mt='1rem'>
          <Text
            w='100%'
            textAlign='center'
            color='login.500'
            fontFamily={'Prompt'}
            fontSize='34px'
            mb='10px'
          >
            เข้าสู่ระบบ
          </Text>
          <Text
            w='100%'
            textAlign='center'
            mb='36px'
            color={'login.500'}
            fontWeight='400'
            fontSize='md'
          >
            กรุณากรอกอีเมลและรหัสผ่าน
          </Text>
        </Center>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: '19.5rem', sm: '19.5rem', md: '420px' }}
          maxW='100%'
          h='max-content'
          background='transparent'
          borderRadius='15px'
          mx={{ base: 'auto', lg: 'unset' }}
          me='auto'
          mb={{ base: '20px', md: 'auto' }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                อีเมล<Text color='#03A9F4'>*</Text>
              </FormLabel>
              <Input
                borderRadius='30px'
                {...register('email')}
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                type='email'
                placeholder='Enter your email address'
                mb='24px'
                fontWeight='500'
                size='lg'
                bg='white'
              />
            </FormControl>
            <FormControl mt='-0.5rem'>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                isRequired={true}
                display='flex'
              >
                รหัสผ่าน<Text color='#03A9F4'>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  bg='white'
                  borderRadius='30px'
                  {...register('password')}
                  isRequired={true}
                  fontSize='sm'
                  ms={{ base: '0px', md: '4px' }}
                  placeholder='Enter your password'
                  mb='24px'
                  size='lg'
                  type={show ? 'text' : 'password'}
                  variant='auth'
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Button
              mt='1.3rem'
              type='submit'
              fontSize='sm'
              fontWeight='500'
              w='100%'
              h='50'
              colorScheme='btn'
              mb='24px'
              borderRadius='30px'
              loadingText='Signing in...'
              isLoading={isLoading}
              disabled={isLoading}
            >
              เข้าสู่ระบบ
            </Button>
          </form>
          <Flex justifyContent='end' mb='24px'>
            <NavLink to='/resetpassword'>
              <Text color='login.600' fontSize='sm' w='auto' fontWeight='500'>
                ลืมรหัสผ่าน ?
              </Text>
            </NavLink>
          </Flex>
        </Flex>
      </Center>
    </Center>
  )
}
