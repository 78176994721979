import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
} from '@chakra-ui/react'
import { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import DateTime from './dateTime'

export default function SearchDate({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  month,
  setMonth,
  setPrevMonth,
  setBetween,
  setCurrentDate,
  setCurrentMonth,
  setStartDateTime,
  setEndDateTime,
  setDateTime,
  startDateTime,
  endDateTime,
}) {
  const [selectValue, setSelectValue] = useState('currentDate')
  const [showMonth, setShowMonth] = useState(false)
  const [showBetween, setShowBetween] = useState(false)
  const [showBetweenTimeLocale, setShowBetweenTimeLocale] = useState(false)
  return (
    <Flex flexDirection='column' w='100%'>
      <Select
        bg='#F4F7FE'
        borderRadius='16px'
        cursor='pointer'
        border='0px'
        variant={'no-effects'}
        _focus={{ border: '0px solid #F4F7FE' }}
        w='100%'
        value={selectValue}
        onChange={(e) => {
          const value = e.target.value
          setSelectValue(value)
          if (value === 'previousMonth') {
            setShowMonth(true)
            setShowBetween(false)
            setPrevMonth(true)
            setBetween(false)
            setDateTime(false)
            setCurrentMonth(false)
            setCurrentDate(false)
            setShowBetweenTimeLocale(false)
          } else if (value === 'between') {
            setCurrentDate(false)
            setShowMonth(false)
            setShowBetween(true)
            setPrevMonth(false)
            setBetween(true)
            setDateTime(false)
            setShowBetweenTimeLocale(false)
          } else if (value === 'currentDate') {
            setShowMonth(false)
            setShowBetween(false)
            setPrevMonth(false)
            setBetween(false)
            setCurrentDate(true)
            setCurrentMonth(false)
            setDateTime(false)
            setShowBetweenTimeLocale(false)
          } else if (value === 'currentMonth') {
            setShowMonth(false)
            setShowBetween(false)
            setPrevMonth(false)
            setBetween(false)
            setCurrentDate(false)
            setCurrentMonth(true)
            setDateTime(false)
            setShowBetweenTimeLocale(false)
          } else if (value === 'betweenTime') {
            setShowMonth(false)
            setShowBetween(false)
            setPrevMonth(false)
            setBetween(false)
            setCurrentDate(false)
            setCurrentMonth(false)
            setDateTime(true)
            setShowBetweenTimeLocale(true)
          }
        }}
      >
        <option value='currentDate'>วันนี้</option>
        <option value='currentMonth'>เดือนนี้</option>
        <option value='previousMonth'>เดือนก่อน</option>
        <option value='between'>ช่วงวันที่</option>
        <option value='betweenTime'>ช่วงวันที่เวลา</option>
      </Select>
      {showMonth && (
        <InputGroup mt='0.5rem'>
          <Input
            bg='#F4F7FE'
            borderRadius='16px'
            cursor='pointer'
            border='0px'
            variant={'no-effects'}
            _focus={{ border: '0px solid #F4F7FE' }}
            w='full'
            textAlign='center'
            dateFormat='MM/yyyy'
            as={ReactDatePicker}
            selected={month}
            onChange={(date) => setMonth(date)}
            placeholderText={' '}
            showMonthYearPicker
          />
          <InputRightElement
            zIndex='base'
            children={<ChevronDownIcon zIndex='base' color='#8F9BB' />}
          />
        </InputGroup>
      )}
      {showBetween && (
        <HStack spacing='0.5rem' w='full' mt='0.5rem'>
          <InputGroup>
            <Input
              bg='#F4F7FE'
              variant={'no-effects'}
              _focus={{ border: '0px solid #F4F7FE' }}
              borderRadius='16px'
              cursor='pointer'
              border='0px'
              w='full'
              textAlign='center'
              as={ReactDatePicker}
              dateFormat='MM/dd/yyyy'
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText={' '}
            />
            <InputRightElement
              zIndex='base'
              children={<ChevronDownIcon color='#8F9BB' zIndex='base' />}
            />
          </InputGroup>
          <InputGroup>
            <Input
              bg='#F4F7FE'
              borderRadius='16px'
              cursor='pointer'
              border='0px'
              variant={'no-effects'}
              _focus={{ border: '0px solid #F4F7FE' }}
              w='full'
              textAlign='center'
              dateFormat='MM/dd/yyyy'
              as={ReactDatePicker}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText={' '}
            />
            <InputRightElement
              zIndex='base'
              children={<ChevronDownIcon zIndex='base' color='#8F9BB' />}
            />
          </InputGroup>
        </HStack>
      )}
      {showBetweenTimeLocale && (
        <DateTime
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          startDateTime={startDateTime}
          endDateTime={endDateTime}
        />
      )}
    </Flex>
  )
}
