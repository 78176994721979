import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  Switch,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import InputField from 'Components/Horizons/fields/InputField'
import { useCRM } from 'Hooks'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { HiPencil } from 'react-icons/hi'
import DatePicker from 'react-datepicker'
import { doc, onSnapshot } from 'firebase/firestore'
import { db } from 'Config/firebase'

function SettingCRM() {
  const { createCRM, updateCRM } = useCRM()
  const [switchValue, setSwitchValue] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [edit, setEdit] = useState(true)
  const [data, setData] = useState()
  const [startDate, setStartDate] = useState(
    data ? new Date(data?.startDate?.toDate()?.toString()) : null
  )
  const [endDate, setEndDate] = useState(
    data ? new Date(data?.endDate?.toDate()?.toString()) : null
  )
  const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100')

  const {
    handleSubmit: handleSubmitEdit,
    control: controlEdit,
    formState: { errors: errorsEdit },
    setValue,
  } = useForm()

  useEffect(() => {
    onSnapshot(doc(db, 'CRM', 'HQ'), (doc) => {
      setData(doc.data())
      setSwitchValue(doc.data()?.status)
      setValue('price', doc.data()?.price)
      setValue('point', doc.data()?.point)
      setValue('firstPoint', doc.data()?.firstPoint)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onSubmit(value) {
    setIsSubmit(true)
    if (data) {
      return updateCRM(value).then(() => {
        setIsSubmit(false)
        setEdit(true)
      })
    } else {
      return createCRM(value).then(() => {
        setIsSubmit(false)
        setEdit(true)
      })
    }
  }
  if (!edit) {
    return (
      <VStack spacing='2em'>
        <Box w='full' border='1px' borderRadius='1em' borderColor={borderColor}>
          <HStack justifyContent={'space-between'} p='2em'>
            <Text>เปิด-ปิด ระบบสะสมแต้ม</Text>
            <Box>
              <FormControl h='full'>
                <HStack h='full' alignItems='end'>
                  <Switch
                    onChange={() => {
                      if (switchValue === false) {
                        setSwitchValue(true)
                        if (data) {
                          return updateCRM({ status: true })
                        } else {
                          return createCRM({ status: true })
                        }
                      } else if (switchValue === true) {
                        setSwitchValue(false)
                        if (data) {
                          return updateCRM({ status: false })
                        } else {
                          return createCRM({ status: false })
                        }
                      }
                    }}
                    isChecked={switchValue}
                  />
                </HStack>
              </FormControl>
            </Box>
          </HStack>
        </Box>
        <Box w='full' border='1px' borderRadius='1em' borderColor={borderColor}>
          <form onSubmit={handleSubmitEdit(onSubmit)}>
            <VStack w='full' p='2em'>
              <HStack w='full' justifyContent='space-between'>
                <HStack>
                  <Text
                    bg='#4299E1'
                    borderRadius='full'
                    w='2em'
                    h='2em'
                    p='.3em'
                    textAlign='center'
                    fontSize='xs'
                    fontWeight='bold'
                    color='#ffff'
                  >
                    P
                  </Text>
                  <Text fontSize='md' fontWeight='bold' color='#2B3674'>
                    การสะสมแต้ม
                  </Text>
                </HStack>
                <Button colorScheme='btn' type='submit' isLoading={isSubmit}>
                  บันทึก
                </Button>
              </HStack>
              <VStack w='full' alignItems='start'>
                <HStack>
                  <InputField
                    control={controlEdit}
                    errors={errorsEdit}
                    name='price'
                    placeholder={'บาท'}
                    type='number'
                    required={{
                      value: true,
                      message: 'กรุณากรอกจำนวนบาท',
                    }}
                  />
                  <InputField
                    control={controlEdit}
                    errors={errorsEdit}
                    name='point'
                    placeholder={'แต้ม'}
                    type='number'
                    required={{
                      value: true,
                      message: 'กรุณากรอกจำนวนแต้ม',
                    }}
                  />
                </HStack>
                <Box>
                  <Text fontSize='md' fontWeight='bold' color='#2B3674'>
                    อายุการใช้งาน
                  </Text>
                  <HStack>
                    <FormControl isInvalid={!!errorsEdit['startDate']}>
                      <Input
                        cursor='pointer'
                        border='0px'
                        px='0px'
                        w='100%'
                        textAlign='center'
                        as={DatePicker}
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date)
                        }}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText={'วันที่เริ่มเติม'}
                      />
                      <FormErrorMessage>
                        {errorsEdit['startDate'] &&
                          errorsEdit['startDate']?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errorsEdit['endDate']}>
                      <Input
                        cursor='pointer'
                        border='0px'
                        px='0px'
                        w='100%'
                        textAlign='center'
                        as={DatePicker}
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        placeholderText={'วันที่สิ้นสุด'}
                      />
                      <FormErrorMessage>
                        {errorsEdit['endDate'] &&
                          errorsEdit['endDate']?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>
                </Box>
                <Box>
                  <Text fontSize='md' fontWeight='bold' color='#2B3674'>
                    โบนัสแกต้มแรกเข้า
                  </Text>
                  <InputField
                    control={controlEdit}
                    errors={errorsEdit}
                    name='firstPoint'
                    placeholder={'แต้ม'}
                    type='number'
                    required={{
                      value: true,
                      message: 'กรุณากรอกจำนวนแต้มแรกเข้า',
                    }}
                  />
                </Box>
              </VStack>
            </VStack>
          </form>
        </Box>
      </VStack>
    )
  }
  return (
    <VStack spacing='2em'>
      <Box w='full' border='1px' borderRadius='1em' borderColor={borderColor}>
        <HStack justifyContent={'space-between'} p='2em'>
          <Text>เปิด-ปิด ระบบสะสมแต้ม</Text>
          <Box>
            <FormControl h='full'>
              <HStack h='full' alignItems='end'>
                <Switch
                  onChange={() => {
                    if (switchValue === false) {
                      setSwitchValue(true)
                      if (data) {
                        return updateCRM({ status: true })
                      } else {
                        return createCRM({ status: true })
                      }
                    } else if (switchValue === true) {
                      setSwitchValue(false)
                      if (data) {
                        return updateCRM({ status: false })
                      } else {
                        return createCRM({ status: false })
                      }
                    }
                  }}
                  isChecked={switchValue}
                />
              </HStack>
            </FormControl>
          </Box>
        </HStack>
      </Box>
      <Box w='full' border='1px' borderRadius='1em' borderColor={borderColor}>
        <VStack p='2em'>
          <HStack w='full' justifyContent='space-between'>
            <HStack>
              <Text
                bg='#4299E1'
                borderRadius='full'
                w='2em'
                h='2em'
                p='.3em'
                textAlign='center'
                fontSize='xs'
                fontWeight='bold'
                color='#ffff'
              >
                P
              </Text>
              <Text fontSize='md' fontWeight='bold' color='#2B3674'>
                การสะสมแต้ม
              </Text>
            </HStack>
            <Button
              variant='no-effects'
              color='#4299E1'
              onClick={() => setEdit(false)}
            >
              <Icon as={HiPencil} fontSize='20px' />
            </Button>
          </HStack>
          <VStack w='full' alignItems='start'>
            <Text fontSize='sm' fontWeight='normal' color='#2B3674'>
              {data?.price ? data?.price : 0} บาท ={' '}
              {data?.point ? data?.point : 0} แต้ม
            </Text>
            <Box>
              <Text fontSize='md' fontWeight='bold' color='#2B3674'>
                อายุการใช้งาน
              </Text>
              <Text fontSize='sm' fontWeight='normal' color='#2B3674'>
                {data?.startDate} - {data?.endDate}
              </Text>
            </Box>
            <Box>
              <Text fontSize='md' fontWeight='bold' color='#2B3674'>
                โบนัสแกต้มแรกเข้า
              </Text>
              <Text fontSize='sm' fontWeight='normal' color='#2B3674'>
                {data?.firstPoint} แต้ม
              </Text>
            </Box>
          </VStack>
        </VStack>
      </Box>
    </VStack>
  )
}

export default SettingCRM
