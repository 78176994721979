import React, { useState } from 'react'
import {
  Center,
  VStack,
  HStack,
  Text,
  Button,
  Flex,
  Input,
} from '@chakra-ui/react'

const _ = require('underscore')
export function RefundItem({
  data,
  addCart,
  removeCart,
  setSecond,
  setFirst,
  handleIsCheck,
  order,
  orderRef,
  handleCancelCheck,
  isSubmit,
}) {
  const [items, setItems] = useState(data)
  const onOrderAmount = _.reduce(
    _.filter(order, (itm) => itm.id === items.id),
    (value, itm) => value + parseFloat(itm.amount),
    0
  )
  const flatten = _.flatten(orderRef)
  const filter = _.filter(flatten, (itm) => itm.id === data.id)
  const amountRefund = _.reduce(filter, (value, itm) => value + itm.amount, 0)
  const maxAmount = onOrderAmount - amountRefund

  function handleChange(event) {
    const value = event.target.value
    addCart(data, value)
  }
  return (
    <>
      <Center maxH='50vh' h='50vh'>
        <VStack spacing='1rem'>
          <Center color='#1B2559'>{items.name}</Center>
          <Center flexDirection='column'>
            {items?.unit === 'piece' && (
              <Flex justifyContent={{ md: 'space-evenly', lg: 'flex-start' }}>
                <Button
                  color='#818182'
                  borderRadius='full'
                  w='2.5rem'
                  h='2.5rem'
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  disabled={
                    items?.promotion ? true : items.amount <= 1 ? true : false
                  }
                  onClick={async () => {
                    if (items.amount >= 1) {
                      removeCart(data)
                      setItems({ ...items, amount: items.amount - 1 })
                    }
                  }}
                >
                  <Text position='absolute' fontWeight='normal' fontSize='34px'>
                    -
                  </Text>
                </Button>
                <HStack
                  spacing={'30px'}
                  w={{ sm: '2.8rem', md: '2.8rem', lg: '2.8rem', xl: '2.8rem' }}
                  justifyContent='center'
                >
                  <Text
                    userSelect='none'
                    fontSize={{
                      base: '14px',
                      md: '14px',
                      lg: '14px',
                      xl: 'md',
                    }}
                  >
                    x {items.amount}
                  </Text>
                </HStack>
                <Button
                  color='#818182'
                  borderRadius='full'
                  w='2.5rem'
                  h='2.5rem'
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  disabled={items.amount === maxAmount ? true : false}
                  onClick={() => {
                    if (items.amount <= maxAmount) {
                      addCart(data)
                      setItems({ ...items, amount: items.amount + 1 })
                    }
                  }}
                >
                  <Text position='absolute' fontWeight='normal' fontSize='34px'>
                    +
                  </Text>
                </Button>
              </Flex>
            )}
            {items?.unit === 'weight' && (
              <>
                <Input
                  type={'number'}
                  textAlign={'center'}
                  defaultValue={items.amount}
                  onChange={handleChange}
                  readOnly
                />
              </>
            )}

            <Text color='pos.500'>
              {items?.unit === 'weight' ? 'กรัม' : 'จำนวน'}
            </Text>
            {items?.promotion && (
              <Text color={'orange.400'}>
                เนื่องจากรายการมีส่วนลด หากต้องการคืนเงินต้องคืนทั้งรายการ
              </Text>
            )}
          </Center>
        </VStack>
      </Center>
      <HStack
        alignItems='center'
        display={{ base: 'none', sm: 'none', md: 'flex' }}
      >
        <Button
          w='full'
          borderRadius={'5px'}
          fontSize={'16px'}
          onClick={() => {
            setFirst(true)
            setSecond(false)
            handleCancelCheck(items.id)
          }}
          border='1px solid #E0E5F2'
        >
          ยกเลิก
        </Button>
        <Button
          colorScheme='btn'
          w='full'
          borderRadius={'5px'}
          fontSize={'16px'}
          disabled={isSubmit}
          onClick={() => {
            setFirst(true)
            setSecond(false)
            handleIsCheck(items)
          }}
        >
          บันทึก
        </Button>
      </HStack>
      <HStack
        alignItems='center'
        display={{ base: 'flex', sm: 'flex', md: 'none' }}
        position='fixed'
        bottom='0px'
        left='0px'
        p='0.3rem'
        w='100%'
      >
        <Button
          w='full'
          borderRadius={'5px'}
          fontSize={'16px'}
          onClick={() => {
            setFirst(true)
            setSecond(false)
            handleCancelCheck(items.id)
          }}
          border='1px solid #E0E5F2'
        >
          ยกเลิก
        </Button>
        <Button
          colorScheme='btn'
          w='full'
          borderRadius={'5px'}
          fontSize={'16px'}
          disabled={isSubmit}
          onClick={() => {
            setFirst(true)
            setSecond(false)
            handleIsCheck(items)
          }}
        >
          บันทึก
        </Button>
      </HStack>
    </>
  )
}
