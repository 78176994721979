import { Stack } from '@chakra-ui/react'
import FormDiscount from 'Components/form/discount/formDiscount'
// import { FormDiscount } from 'Components/form/formDiscount'
import React from 'react'

export default function AddDiscount({ user }) {
  return (
    <Stack direction='column'>
      <FormDiscount />
    </Stack>
  )
}
