import Chart from 'react-apexcharts'
import { Flex, Box, Text } from '@chakra-ui/react'

import Card from 'Components/Horizons/card/Card'
import {
  calCommissionEmp,
  calDiscount,
  calNetSales,
  calOrderCost,
  calProfit,
  calProfitCommission,
  calRefund,
  calSellingPrice,
} from './functions/card'

const _ = require('underscore')
const accounting = require('accounting')
export default function OverallRevenue({
  allData,
  Expenses,
  userList,
  work,
  ...rest
}) {
  const date = _.groupBy(allData, 'date')

  // ยอดขาย
  const totalSales = Object.keys(date).map((item) => ({
    date: item,
    item: calSellingPrice(date[item]).total,
  }))

  // ต้นทุน
  const totalCost = Object.keys(date).map((item) => ({
    data: item,
    item: calOrderCost(date[item]),
  }))

  // ยอดขายสุทธิ
  const totalNetSales = Object.keys(date).map((item) => ({
    date: item,
    item: calNetSales(date[item]).total,
  }))

  // ยอดการคืนเงิน
  const totalRefund = Object.keys(date).map((item) => ({
    date: item,
    item: calRefund(date[item]).total,
  }))

  // ส่วนลด
  const totalDiscount = Object.keys(date).map((item) => ({
    date: item,
    item: calDiscount(date[item]),
  }))

  // กำไร
  const totalProfit = Object.keys(date).map((item) => ({
    date: item,
    item: calProfit(date[item]).total,
  }))

  // ค่าคอมมิชชั่น
  const totalCommission = Object.keys(date).map((item) => ({
    date: item,
    item: calCommissionEmp(date[item], work, userList),
  }))

  // กำไรหลังหักคอมมิชชั่น
  const totalProfitCommission = Object.keys(date).map((item) => ({
    date: item,
    item: calProfitCommission(date[item], work, userList),
  }))
  const lineChartOptions = {
    chart: {
      height: 350,
      type: 'line',
      offsetY: 0,
      offsetX: 0,
      foreColor: '#2B3674',
      fontFamily: 'Prompt',
    },
    dataLabels: {
      enabled: false,
    },

    colors: [
      '#90EE90',
      '#E64136',
      '#FF6501',
      '#FFEA00',
      '#01B574',
      '#32CD32',
      '#CC5500',
      '#4F7942',
    ],
    stroke: {
      curve: 'straight',
      width: [4, 4, 4, 4, 4, 4],
    },
    markers: {
      size: 6,
    },
    plotOptions: {
      bar: {
        columnWidth: '20%',
      },
    },
    xaxis: {
      categories: Object.keys(date)?.reverse(),
      // categories: totalNetSales.map((itm) => itm.date),
    },
    yaxis: [
      {
        labels: {
          show: true,
          showAlways: true,
          showForNullSeries: true,
          seriesName: undefined,
          opposite: false,
          reversed: false,
          logarithmic: false,
          logBase: 10,
          tickAmount: 6,
          min: 6,
          max: 6,
          forceNiceScale: false,
          floating: false,
          decimalsInFloat: undefined,
          formatter: function (value) {
            return accounting.formatNumber(value, 2)
          },
          labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: [],
              fontSize: '16px',
              fontFamily: 'DM Sans',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
          },
        },
      },
    ],
    tooltip: {
      shared: false,
      intersect: true,
      x: {
        show: false,
      },
    },
  }

  const data = [
    {
      name: 'ยอดขาย',
      data: totalSales.map((itm) => itm.item)?.reverse(),
    },
    {
      name: 'คืนเงิน',
      data: totalRefund.map((itm) => itm.item)?.reverse(),
    },
    {
      name: 'ส่วนลด',
      data: totalDiscount.map((itm) => itm.item)?.reverse(),
    },
    {
      name: 'ต้นทุน',
      data: totalCost.map((itm) => itm.item)?.reverse(),
    },
    {
      name: 'ยอดขายสุทธิ',
      data: totalNetSales.map((itm) => itm.item)?.reverse(),
    },
    {
      name: 'กำไร',
      data: totalProfit.map((itm) => itm.item)?.reverse(),
    },
    {
      name: 'คอมมิชชั่น',
      data: totalCommission.map((itm) => itm.item)?.reverse(),
    },
    {
      name: 'กำไรหลังหักคอมมิชชั่น',
      data: totalProfitCommission.map((itm) => itm.item)?.reverse(),
    },
  ]

  return (
    <Card
      justifyContent='center'
      align='center'
      direction='column'
      w='100%'
      mb={{ base: '20px', lg: '0px' }}
      {...rest}
    >
      <Flex p='1rem' pb='0rem' w='full' textAlign='start'>
        <Text>ยอดขาย</Text>
      </Flex>
      <Box w='full' mt='auto' h='30rem' zIndex='base'>
        <Chart
          options={lineChartOptions}
          series={data}
          type='line'
          width='100%'
          height='100%'
        />
      </Box>
    </Card>
  )
}
