import { db } from 'Config/firebase'
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { decodeUnit } from 'Utils/currency'

const prevDate = new Date()
prevDate.setHours(0)
prevDate.setMinutes(0)
prevDate.setSeconds(0)
prevDate.setMilliseconds(0)

const nextDate = new Date()
nextDate.setDate(nextDate.getDate() + 1)
nextDate.setHours(0)
nextDate.setMinutes(0)
nextDate.setSeconds(0)
nextDate.setMilliseconds(0)

const prevMonthDate = new Date()
prevMonthDate.setDate(1)
prevMonthDate.setHours(0)
prevMonthDate.setMinutes(0)
prevMonthDate.setSeconds(0)
prevMonthDate.setMilliseconds(0)

const nextMonth = new Date()
nextMonth.setMonth(nextMonth.getMonth() + 1)
nextMonth.setDate(0)
nextMonth.setHours(23)
nextMonth.setMinutes(59)
nextMonth.setSeconds(59)
nextMonth.setMilliseconds(59)

export function queryOrderAdmin({
  month,
  startDate,
  endDate,
  between,
  prevMonth,
  setData,
  currentDate,
  currentMonth,
  dateTime,
  startDateTime,
  endDateTime,
  setIsLoading,
}) {
  const start = new Date(startDate)
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  start.setMilliseconds(0)

  const dateEnd = new Date(endDate)
  dateEnd.setDate(dateEnd.getDate() + 1)
  dateEnd.setHours(0)
  dateEnd.setMinutes(0)
  dateEnd.setSeconds(0)
  dateEnd.setMilliseconds(0)

  const startMonth = new Date(month)
  startMonth.setDate(1)
  startMonth.setHours(0)
  startMonth.setMinutes(0)
  startMonth.setSeconds(0)
  startMonth.setMilliseconds(0)

  const endMonth = new Date(month)
  endMonth.setMonth(endMonth.getMonth() + 1)
  endMonth.setDate(0)
  endMonth.setHours(23)
  endMonth.setMinutes(59)
  endMonth.setSeconds(59)
  endMonth.setMilliseconds(59)

  const qOrder = prevMonth
    ? query(
        collection(db, 'Orders'),
        where('createdAt', '>=', startMonth),
        where('createdAt', '<=', endMonth),
        orderBy('createdAt', 'desc')
      )
    : between
    ? query(
        collection(db, 'Orders'),
        where('createdAt', '>=', start),
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )
    : currentMonth
    ? query(
        collection(db, 'Orders'),
        where('createdAt', '>=', prevMonthDate),
        where('createdAt', '<=', nextMonth),
        orderBy('createdAt', 'desc')
      )
    : dateTime
    ? query(
        collection(db, 'Orders'),
        where('createdAt', '>=', startDateTime),
        where('createdAt', '<=', endDateTime),
        orderBy('createdAt', 'desc')
      )
    : currentDate &&
      query(
        collection(db, 'Orders'),
        where('createdAt', '>=', prevDate),
        where('createdAt', '<=', nextDate),
        orderBy('createdAt', 'desc')
      )

  onSnapshot(qOrder, (snapShot) => {
    let dataValue = []
    snapShot.forEach((doc) => {
      return dataValue.push({
        ...doc.data(),
        branch: doc.data()?.branch?.id,
        total:
          doc.data()?.status === 'refund'
            ? decodeUnit(doc.data()?.total) * -1
            : decodeUnit(doc.data()?.total),
        grandTotal:
          doc.data()?.status === 'refund'
            ? decodeUnit(doc.data()?.grandTotal) * -1
            : decodeUnit(doc.data()?.grandTotal),
      })
    })
    setData(dataValue)
    setIsLoading(false)
  })
}

// export function queryExpensesAdmin({
//   month,
//   startDate,
//   endDate,
//   between,
//   prevMonth,
//   setDataExpenses,
//   currentDate,
//   currentMonth,
//   Branch,
//   dateTime,
//   startDateTime,
//   endDateTime,
// }) {
//   const start = new Date(startDate)
//   start.setHours(0)
//   start.setMinutes(0)
//   start.setSeconds(0)
//   start.setMilliseconds(0)

//   const dateEnd = new Date(endDate)
//   dateEnd.setDate(dateEnd.getDate() + 1)
//   dateEnd.setHours(0)
//   dateEnd.setMinutes(0)
//   dateEnd.setSeconds(0)
//   dateEnd.setMilliseconds(0)

//   const startMonth = new Date(month)
//   startMonth.setDate(1)
//   startMonth.setHours(0)
//   startMonth.setMinutes(0)
//   startMonth.setSeconds(0)
//   startMonth.setMilliseconds(0)

//   const endMonth = new Date(month)
//   endMonth.setMonth(endMonth.getMonth() + 1)
//   endMonth.setDate(0)
//   endMonth.setHours(23)
//   endMonth.setMinutes(59)
//   endMonth.setSeconds(59)
//   endMonth.setMilliseconds(59)

//   const qExpenses = prevMonth
//     ? query(
//         collection(db, 'Expenses'),
//         where('createdAt', '>=', startMonth),
//         where('createdAt', '<=', endMonth),
//         // where('status', '==', 'approvel'),
//         orderBy('createdAt', 'desc')
//       )
//     : between
//     ? query(
//         collection(db, 'Expenses'),
//         where('createdAt', '>=', start),
//         where('createdAt', '<=', dateEnd),
//         // where('status', '==', 'approvel'),
//         orderBy('createdAt', 'desc')
//       )
//     : currentMonth
//     ? query(
//         collection(db, 'Expenses'),
//         where('createdAt', '>=', prevMonthDate),
//         where('createdAt', '<=', nextMonth),
//         // where('status', '==', 'approvel'),
//         orderBy('createdAt', 'desc')
//       )
//     : dateTime
//     ? query(
//         collection(db, 'Expenses'),
//         where('createdAt', '>=', startDateTime),
//         where('createdAt', '<=', endDateTime),
//         // where('status', '==', 'approvel'),
//         orderBy('createdAt', 'desc')
//       )
//     : currentDate &&
//       query(
//         collection(db, 'Expenses'),
//         where('createdAt', '>=', prevDate),
//         where('createdAt', '<=', nextDate),
//         // where('status', '==', 'approvel'),
//         orderBy('createdAt', 'desc')
//       )

//   return onSnapshot(qExpenses, (snapShot) => {
//     let dataValue = []
//     snapShot.forEach((doc) => {
//       return dataValue.push({
//         ...doc.data(),
//         total: decodeUnit(doc.data()?.total),
//         totalVat: decodeUnit(doc.data()?.totalVat),
//         grandTotal: decodeUnit(doc.data()?.grandTotal),
//         branch:
//           doc.data()?.branch === 'HQ'
//             ? 'คลังกลาง'
//             : Branch.find((value) => value.id === doc.data()?.branch)?.name,
//       })
//     })
//     setDataExpenses(dataValue)
//   })
// }
