import { Button, HStack, Input, Text } from '@chakra-ui/react'

export function AmountProduct({
  unit,
  handleAdd,
  handleRemove,
  label,
  disabled,
  value,
  handleChangeInput,
}) {
  if (unit === 'weight') {
    return (
      <HStack spacing='2em'>
        <Input
          textAlign='end'
          type={'number'}
          step={'any'}
          min={0}
          value={value}
          onWheel={(e) => e.target.blur()}
          onChange={handleChangeInput}
        />
        <Text>กรัม</Text>
      </HStack>
    )
  }

  return (
    <HStack spacing='20px'>
      <Button
        border='1px'
        borderColor='#E0E5F2'
        w='2.5rem'
        borderRadius='100%'
        disabled={disabled}
        onClick={handleRemove}
      >
        <Text fontSize='26px' color='#767262'>
          -
        </Text>
      </Button>
      <Text fontSize='20px'>{label}</Text>
      <Button
        border='1px'
        borderColor='#E0E5F2'
        w='2.5rem'
        borderRadius='100%'
        disabled={disabled}
        onClick={handleAdd}
      >
        <Text fontSize='26px' color='#767262'>
          +
        </Text>
      </Button>
    </HStack>
  )
}
