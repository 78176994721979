import { Stack } from '@chakra-ui/react'
import { FormBranch } from 'Components/form'
import React from 'react'

function AddBranch({ user }) {
  return (
    <Stack direction='column'>
      <FormBranch id={null} />
    </Stack>
  )
}

export default AddBranch
