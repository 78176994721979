import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Fade,
  Flex,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  useColorModeValue,
  VStack,
  SkeletonText,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import { useBranch, useProduct } from 'Hooks'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { BiMinus } from 'react-icons/bi'
import { BsArrowRepeat, BsPlus } from 'react-icons/bs'
import { Link, useParams } from 'react-router-dom'
import { ArrowBackIcon, ChevronLeftIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'
import { AiOutlineQrcode } from 'react-icons/ai'
import QRCodeSVG from 'qrcode.react'
import { currency, decodeUnit } from 'Utils/currency'

function ViewInventory({ user }) {
  const { id } = useParams()
  const { Branch } = useBranch()
  const [isloadData, setIsLoadData] = useState(true)
  const [productData, setProductData] = useState({})
  const { getProductAndStockByProductId } = useProduct()
  const [isFadePage, setIsFadePage] = useState(true)
  const [isLoadingHistory, setIsLoadingHistory] = useState(true)
  const qrRef = useRef()
  const Navigate = useNavigate()
  const endPoint = process.env.REACT_APP_ENDPOINT || ''
  const [isLoading, setIsLoading] = useState(true)
  const QrCodeGen = () => {
    var link = document.createElement('a')
    link.download = `${(productData?.product?.name).replace(
      / /g,
      ''
    )}_${(productData?.product?.sku).replace(/ /g, '-')}.png`
    link.href = document.getElementById('canvas').toDataURL()
    link.click()
  }

  const getItemData = useCallback(async () => {
    const datas = await getProductAndStockByProductId(id)
    setProductData(datas)
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    getItemData()
    setIsFadePage(false)
    setIsLoadData(false)
    setTimeout(() => {
      setIsLoadingHistory(false)
      setIsLoading(false)
    }, 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getItemData])
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  return (
    <>
      {!isLoading ? (
        <>
          <Box ref={qrRef} display={'none'}>
            <QRCodeSVG
              value={`${endPoint}/view/product/${id}`}
              size={128}
              renderAs='canvas'
              id='canvas'
              level='L'
              title={productData?.product?.name}
            ></QRCodeSVG>
          </Box>
          <Stack direction='column' spacing={'2em'}>
            <Flex direction='column' align='center' position='relative'>
              <Box
                h={{ base: '0px', sm: '0px', md: '25vh' }}
                bg='inventory.700'
                position='absolute'
                w={{ sm: '100vw', md: '100%', lg: '100%' }}
                borderRadius={{ sm: '0px', md: '30px', lg: '30px' }}
              ></Box>
              <Box
                variant='unstyled'
                mt={{ sm: '0rem', md: '4rem', lg: '4rem' }}
                zIndex='0'
                display='flex'
                flexDirection='column'
              >
                <Box
                  mt={{ base: '-10px', sm: '-10px', md: '24px' }}
                  maxW={{ md: '90%', lg: '100%' }}
                  mx={{ base: 'auto', md: '0px', lg: 'auto' }}
                >
                  <Box
                    w={{ sm: '100vw', md: '670px', lg: '850px' }}
                    p='0px'
                    mx={{ base: 'auto', md: '0px', lg: 'auto' }}
                  >
                    <Card
                      p={{ base: '15px', sm: '15px', md: '30px' }}
                      px={{ base: '30px', sm: '30px', md: '30px' }}
                      as={Fade}
                      in={!isFadePage && productData}
                    >
                      <HStack justifyContent={'space-between'}>
                        <Box
                          pb='0.3rem'
                          zIndex='overlay'
                          color='inventory.500'
                          onClick={() => {
                            if (user?.branchSelect?.id === 'HQ') {
                              Navigate('/inventory/list')
                            } else {
                              Navigate('/inventory/branch')
                            }
                          }}
                          w='max-content'
                        >
                          <Text
                            display={{ sm: 'none', md: 'flex' }}
                            fontSize='18px'
                            cursor='pointer'
                          >
                            <ArrowBackIcon />
                          </Text>
                          <Text
                            display={{ sm: 'flex', md: 'none' }}
                            fontSize='24px'
                            cursor='pointer'
                          >
                            <ChevronLeftIcon />
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            // display={{ sm: 'flex', md: 'none' }}
                            fontSize={{ sm: '18px', md: '24px' }}
                            cursor='pointer'
                            onClick={() => {
                              QrCodeGen(id, productData?.product?.name)
                            }}
                          >
                            <AiOutlineQrcode />
                          </Text>
                        </Box>
                      </HStack>
                      <Text
                        color={textColor}
                        fontSize='2xl'
                        fontWeight='700'
                        textAlign={{
                          base: 'center',
                          sm: 'center',
                          md: 'start',
                        }}
                        mb='20px'
                        ml={{ sm: '0.5rem', md: '0rem' }}
                      >
                        รายละเอียด
                      </Text>
                      <Center>
                        <VStack>
                          <Avatar
                            src={
                              productData?.product?.img ||
                              '/product_Thumbnail.png'
                            }
                            size={'2xl'}
                            borderRadius='20px'
                          />
                          <VStack>
                            <Heading
                              mt='0.5rem'
                              fontFamily={'Prompt'}
                              size={'lg'}
                              color={
                                productData?.stock < 0 ? 'red.500' : '#1B2559'
                              }
                            >
                              {productData?.product?.unit === 'piece'
                                ? `${currency(productData?.stock)} ชิ้น`
                                : `${currency(productData?.stock, 2)} กรัม`}
                            </Heading>
                            <VStack alignItems={'start'} pt='0.2rem'>
                              <Text>
                                รหัสสินค้า : {productData?.product?.sku}
                              </Text>
                              <Text>
                                ชื่อสินค้า : {productData?.product?.name}{' '}
                                {productData?.product?.nameOption}
                              </Text>
                              <Text>
                                หน่วยสินค้า :{' '}
                                {productData?.product?.unit === 'piece'
                                  ? 'ชิ้น'
                                  : productData?.product?.unit === 'weight' &&
                                    'น้ำหนัก'}
                              </Text>
                            </VStack>
                          </VStack>
                          {(user?.role === 'Administrator' ||
                            user?.role === 'Manager') && (
                            <HStack spacing={'3em'}>
                              {user?.branchSelect.id === 'HQ' && (
                                <>
                                  <VStack>
                                    <Button
                                      as={Link}
                                      to={`/inventory/deposit/${productData?.product?.id}`}
                                      w={'50px'}
                                      h={'50px'}
                                      border={'1px'}
                                      bg={'#ffff'}
                                      borderColor={'blackAlpha.50'}
                                      borderRadius={'full'}
                                      color={'#01B574'}
                                    >
                                      <Icon as={BsPlus} fontSize={'24px'} />
                                    </Button>
                                    <Text fontSize={'14px'}>นำเข้า</Text>
                                  </VStack>
                                </>
                              )}
                              <VStack>
                                <Button
                                  as={Link}
                                  to={`/inventory/transfer/${productData?.product?.id}`}
                                  w={'50px'}
                                  h={'50px'}
                                  border={'1px'}
                                  bg={'#ffff'}
                                  borderColor={'blackAlpha.50'}
                                  borderRadius={'full'}
                                  color={'#4318FF'}
                                >
                                  <Icon as={BsArrowRepeat} fontSize={'24px'} />
                                </Button>
                                <Text fontSize={'14px'}>โอนย้าย</Text>
                              </VStack>

                              {user?.branchSelect?.id === 'HQ' && (
                                <>
                                  <VStack>
                                    <Button
                                      as={Link}
                                      to={`/inventory/withdrawal/${productData?.product?.id}`}
                                      w={'50px'}
                                      h={'50px'}
                                      border={'1px'}
                                      bg={'#ffff'}
                                      borderColor={'blackAlpha.50'}
                                      borderRadius={'full'}
                                      color={'#FFB547'}
                                    >
                                      <Icon as={BiMinus} fontSize={'20px'} />
                                    </Button>
                                    <Text fontSize={'14px'}>นำออก</Text>
                                  </VStack>
                                </>
                              )}
                            </HStack>
                          )}
                        </VStack>
                      </Center>
                    </Card>
                  </Box>
                </Box>
              </Box>
            </Flex>
            <Card
              p={{ base: '15px', sm: '15px', md: '30px' }}
              px={{ base: '0px', sm: '0px', md: '30px' }}
              h='full'
              as={Fade}
              in={!isFadePage && productData}
            >
              <Container
                maxW={{ base: 'full', sm: 'full', md: 'container.lg' }}
              >
                <Box>
                  <HStack justifyContent='space-between' pb='0.5rem'>
                    <Heading size={'md'} fontFamily={'Prompt'}>
                      ประวัติรายการ
                    </Heading>
                  </HStack>
                  <Divider />
                </Box>
                {!isloadData && Branch ? (
                  productData?.stockData?.length > 0 ? (
                    productData?.stockData
                      .sort((a, b) => b?.createdAt - a?.createdAt)
                      ?.map((item, index) => (
                        <HStack
                          my={'1em'}
                          px={{ base: '0em', sm: '0rem', lg: '2em' }}
                          key={index}
                          justifyContent={'space-between'}
                          alignItems={'start'}
                        >
                          {!isLoadingHistory ? (
                            <>
                              <Box maxW={{ sm: 'full', md: '30em' }}>
                                <HStack h='full'>
                                  <Text
                                    fontSize={{
                                      base: '14px',
                                      sm: '14px',
                                      md: '16px',
                                    }}
                                    fontWeight={{
                                      sm: 'normal',
                                      md: 'bold',
                                    }}
                                  >
                                    {item?.description}
                                  </Text>
                                  <Box maxW={'15em'}>
                                    <Text
                                      noOfLines={1}
                                      display={{ base: 'none', md: 'block' }}
                                    >
                                      {
                                        Branch.find(
                                          (itm) => itm.id === item?.description
                                        )?.name
                                      }
                                    </Text>
                                  </Box>
                                </HStack>
                                <Text fontSize={'xs'} color={'#A3AED0'}>
                                  {item?.createdAt
                                    ?.toDate()
                                    .toLocaleString('en-US', {
                                      hour12: false,
                                    })}{' '}
                                  ({item?.createdBy?.name})
                                </Text>
                                {item?.note && (
                                  <Text fontSize={'xs'} color={'#A3AED0'}>
                                    หมายเหตุ : {item?.note}
                                  </Text>
                                )}
                              </Box>
                              <Box>
                                <HStack
                                  spacing={'0px'}
                                  color={
                                    item?.method === 'deposit'
                                      ? '#01B574'
                                      : 'blue.600'
                                  }
                                >
                                  <Icon
                                    as={
                                      item?.method === 'deposit'
                                        ? BsPlus
                                        : BiMinus
                                    }
                                  />
                                  <Text>
                                    {item?.unit === 'weight'
                                      ? currency(decodeUnit(item?.stock), 2)
                                      : currency(decodeUnit(item?.stock))}
                                  </Text>
                                </HStack>
                              </Box>
                            </>
                          ) : (
                            <>
                              <SkeletonText noOfLines={2}>
                                <Box maxW={'15em'}>
                                  <HStack h='full'>
                                    <Text fontWeight={'600'}>
                                      {item?.description}
                                    </Text>
                                    <Box maxW={'10em'}>
                                      <Text
                                        noOfLines={1}
                                        display={{ base: 'none', md: 'block' }}
                                      >
                                        {
                                          Branch?.find(
                                            (itm) =>
                                              itm?.id === item?.description
                                          )?.name
                                        }
                                      </Text>
                                    </Box>
                                  </HStack>
                                  <Text fontSize={'xs'} color={'#A3AED0'}>
                                    {moment(item?.createdAt).format(
                                      'DD MMMM YYYY'
                                    )}
                                  </Text>
                                </Box>
                              </SkeletonText>
                              <SkeletonText noOfLines={1}>
                                <Box>
                                  <HStack
                                    spacing={'0px'}
                                    color={
                                      item?.method === 'deposit'
                                        ? '#01B574'
                                        : 'blue.600'
                                    }
                                  >
                                    <Icon
                                      as={
                                        item?.method === 'deposit'
                                          ? BsPlus
                                          : BiMinus
                                      }
                                    />
                                    <Text>
                                      {currency(decodeUnit(item?.stock))}
                                    </Text>
                                  </HStack>
                                </Box>
                              </SkeletonText>
                            </>
                          )}
                        </HStack>
                      ))
                  ) : (
                    <Center mt={'2em'}>
                      <Text>ไม่มีรายการ</Text>
                    </Center>
                  )
                ) : (
                  <Center
                    h={{ base: '70vh', sm: '70vh', md: '50vh', lg: '50vh' }}
                    flexDirection='column'
                    w='100%'
                  >
                    <Center flexDirection='column'>
                      <Text pb='1rem' fontSize='xl' color='loading.100'>
                        รอดำเนินการ
                      </Text>
                      <Box className='loading' color='loading.100'></Box>
                    </Center>
                  </Center>
                )}
              </Container>
            </Card>
          </Stack>
        </>
      ) : (
        <>
          <Center
            h={{ base: '70vh', sm: '70vh', md: '50vh', lg: '50vh' }}
            flexDirection='column'
            w='100%'
          >
            <Center flexDirection='column'>
              <Text pb='1rem' fontSize='xl' color='loading.100'>
                รอดำเนินการ
              </Text>
              <Box className='loading' color='loading.100'></Box>
            </Center>
          </Center>
        </>
      )}
    </>
  )
}

export default ViewInventory
