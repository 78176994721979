import { Box, HStack, Text } from '@chakra-ui/react'
import React from 'react'
import { useState } from 'react'
import { CustomTable } from './components/CustomTable'

function StaffCommissionView(props) {
  const [data, setData] = useState([])
  const [onSearch, setOnSearch] = useState(false)
  console.log(setData)
  console.log(setOnSearch)
  return (
    <>
      <HStack
        my='1em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='0vw'
        flexDirection={{ sm: 'column', md: 'column' }}
        alignItems='flex-start'
      >
        <Box fontSize='xl' fontWeight='700'>
          <Text color='employees.100'>พนักงานขาย1</Text>
          <Text color='employees.100'>(สาขา 1)</Text>
        </Box>
        <CustomTable
          columnsData={columnsData}
          tableData={onSearch ? data : []}
          closeSearch={true}
        />
      </HStack>
    </>
  )
}

export default StaffCommissionView

const columnsData = [
  {
    Header: 'ช่วงวันที่',
    accessor: 'date',
    // extra: (data, row) => <Box> <Text>name</Text> </Box>,
  },
  {
    Header: (
      <Text w='100%' textAlign='end'>
        ค่าจ้าง
      </Text>
    ),
    accessor: 'wages',
    // extra: (data, row) => (
    //   <Center w='100%' justifyContent='flex-end'>
    //      <Text>30000</Text>
    //   </Center>
    // ),
  },
  {
    Header: (
      <Text w='100%' textAlign='end'>
        ค่าคอมมิชชั่น
      </Text>
    ),
    accessor: 'commission',
    // extra: (data, row) => (
    //   <Center w='100%' justifyContent='flex-end'>
    //      <Text>2000000</Text>
    //   </Center>
    // ),
  },
  {
    Header: (
      <Text w='100%' textAlign='end'>
        ค่าจ้างรวม
      </Text>
    ),
    accessor: 'grosswages',
    // extra: (data, row) => (
    //   <Center w='100%' justifyContent='flex-end'>
    //      <Text>90000</Text>
    //   </Center>
    // ),
  },
]
