import { DeleteIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  Box,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { usePromotion } from 'Hooks/promotion'
import { HiPencil } from 'react-icons/hi'
import { IoPricetagsOutline } from 'react-icons/io5'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { currency } from 'Utils/currency'
import { typeOfDiscount } from 'Utils/discount'

function AlertModal({ id, setIsLoading }) {
  const { deletePromotion } = usePromotion()
  const { isOpen, onClose, onOpen } = useDisclosure()

  function handleDelete() {
    setIsLoading(true)
    return deletePromotion(id).then(() => {
      onClose()
      setIsLoading(false)
    })
  }
  return (
    <>
      <Flex align='center' onClick={onOpen} w='full'>
        <HStack>
          <Icon as={DeleteIcon} h='16px' w='16px' me='8px' />
          <Text fontSize='sm' fontWeight='400'>
            ลบ
          </Text>
        </HStack>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>ลบโปรโมชั่นส่วนลด</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>คุณต้องการที่จะลบส่วนลดโปรโมชั่นใช่หรือไม่ ?</Text>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' onClick={onClose}>
              ไม่
            </Button>
            <Button colorScheme='table' mr={3} onClick={() => handleDelete()}>
              ใช่
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function CardDiscount({ itm, setIsLoading }) {
  const navigate = useNavigate()
  const textColor2 = useColorModeValue('secondaryGray.500', 'white')
  const iconColor = useColorModeValue('#494D51', 'white')
  const bgList = useColorModeValue('white', 'whiteAlpha.100')
  const bgShadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    'unset'
  )
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' }
  )

  function handleLink() {
    return navigate(`/discount/edit/${itm.id}`)
  }
  return (
    <Box
      w='100%'
      borderBottom='1px solid #E0E5F2'
      mt={{ sm: '0.5rem', md: '1rem' }}
      py={{ sm: '0.5rem', md: '.5rem' }}
      cursor='pointer'
      _hover={{
        bg: '#E0E5F2',
        transition: 'all 0.4s',
      }}
      transition='all 0.4s'
    >
      <Grid
        templateColumns={{ sm: 'repeat(3, 1fr)', md: 'repeat(4, 1fr)' }}
        gap={0}
        h={{ base: 'auto', sm: 'auto', md: 'auto', lg: '5rem' }}
      >
        <GridItem
          h='full'
          w={{
            base: '9rem',
            sm: '9rem',
            md: '15rem',
            lg: '15rem',
            xl: '17rem',
            '2xl': '20rem',
          }}
          onClick={() => handleLink()}
          opacity={itm.status === true ? '1' : '0.5'}
        >
          {/* <VStack
            h={{ base: 'auto', sm: 'auto', md: 'auto', lg: '7.5rem' }}
            justifyContent='flex-end'
            position='absolute'
            display={{ base: 'none', sm: 'none', md: 'none', lg: 'flex' }}
          >
            <Text>สร้างโดย : {itm?.createdBy?.name}</Text>
          </VStack> */}
          <HStack
            spacing={{ base: '0.8rem', sm: '0.8rem', md: '0.5rem', lg: '1rem' }}
            h='full'
            px={{ sm: '0.5em', md: '0.5em' }}
          >
            <Box display={{ base: 'flex', sm: 'flex', md: 'none' }}>
              {itm.status === true ? (
                <Text
                  w={{ base: '0.5rem', sm: '0.5rem', md: '0.8rem' }}
                  h={{ base: '0.5rem', sm: '0.5rem', md: '0.8rem' }}
                  borderRadius='100%'
                  bg='green.500'
                ></Text>
              ) : (
                <Text
                  w={{ base: '0.5rem', sm: '0.5rem', md: '0.8rem' }}
                  h={{ base: '0.5rem', sm: '0.5rem', md: '0.8rem' }}
                  borderRadius='100%'
                  bg='#BFBFBF'
                ></Text>
              )}
            </Box>
            <Center
              w='4rem'
              h='4rem'
              display={{ base: 'none', sm: 'none', md: 'flex' }}
            >
              <Center
                w='4rem'
                bg='discount.100'
                color='white'
                borderRadius='100%'
                h='4rem'
              >
                <Icon as={IoPricetagsOutline} w='2.5rem' h='2.5rem' />
              </Center>
            </Center>
            <Flex
              flexDirection='column'
              justifyContent='space-around'
              h='full'
              textAlign='left'
            >
              <Text fontWeight='700' fontSize='sm'>
                {itm.discountName}
              </Text>
            </Flex>
          </HStack>
        </GridItem>
        <GridItem
          display={{ sm: 'none', md: 'GridItem' }}
          h='full'
          w={{ base: '10rem', sm: '10rem', md: '100%' }}
          onClick={() => handleLink()}
          opacity={itm.status === true ? '1' : '0.5'}
        >
          <Center h='full' px='0.5rem'>
            <Text fontSize={{ sm: '10px', md: '16px' }}>{`${typeOfDiscount(
              itm.type
            )}`}</Text>
          </Center>
        </GridItem>
        <GridItem
          h='full'
          w={{ base: '5rem', sm: '5rem', md: '100%' }}
          onClick={() => handleLink()}
          opacity={itm.status === true ? '1' : '0.5'}
        >
          <VStack
            h='full'
            fontSize={{ sm: '14px', md: '16px' }}
            color='discount.500'
            fontWeight='500'
            textAlign='right'
            alignItems='flex-end'
            justifyContent='center'
          >
            {itm.minimumOrder && (
              <Text>{currency(itm.minimumOrder, 2, true)}</Text>
            )}
            {itm.valueDiscount && (
              <Text>
                {itm.valueDiscountType === 'Bath'
                  ? `${currency(itm.valueDiscount, 2, true)}`
                  : `${itm.valueDiscount} %`}
              </Text>
            )}
          </VStack>
        </GridItem>
        <GridItem h='full' w='auto'>
          <HStack
            h='full'
            justifyContent='flex-end'
            w='100%'
            px={{ sm: '0rem', md: '2rem' }}
          >
            <HStack spacing={{ base: '0rem', sm: '0rem', md: '2rem' }}>
              <Menu>
                <MenuButton
                  as={Button}
                  align='center'
                  justifyContent='center'
                  bg='none'
                  _focus={bgFocus}
                  _active={bgFocus}
                  w='37px'
                  h='37px'
                  lineHeight='100%'
                  borderRadius='10px'
                  p='0px'
                >
                  <Icon
                    as={MdOutlineMoreHoriz}
                    color={iconColor}
                    w='24px'
                    h='24px'
                  />
                </MenuButton>
                <MenuList
                  w='150px'
                  minW='unset'
                  maxW='150px !important'
                  border='transparent'
                  backdropFilter='blur(63px)'
                  bg={bgList}
                  boxShadow={bgShadow}
                  borderRadius='20px'
                  p='15px'
                >
                  <MenuItem
                    transition='0.2s linear'
                    color={textColor2}
                    p='0px'
                    borderRadius='8px'
                    _active={{
                      bg: 'transparent',
                    }}
                    _focus={{
                      bg: 'transparent',
                    }}
                    mb='10px'
                    _hover={{
                      color: 'discount.500',
                    }}
                    cursor='pointer'
                    onClick={() => navigate(`/discount/edit/${itm.id}`)}
                  >
                    <Flex align='center'>
                      <HStack>
                        <Icon as={HiPencil} h='16px' w='16px' me='8px' />
                        <Text fontSize='sm' fontWeight='400'>
                          แก้ไข
                        </Text>
                      </HStack>
                    </Flex>
                  </MenuItem>
                  <MenuItem
                    transition='0.2s linear'
                    color={textColor2}
                    p='0px'
                    borderRadius='8px'
                    _active={{
                      bg: 'transparent',
                    }}
                    _focus={{
                      bg: 'transparent',
                    }}
                    _hover={{
                      color: 'red',
                    }}
                    cursor='pointer'
                  >
                    <AlertModal id={itm.id} setIsLoading={setIsLoading} />
                  </MenuItem>
                </MenuList>
              </Menu>
              <Box display={{ base: 'none', sm: 'none', md: 'flex' }}>
                {itm.status === true ? (
                  <Text
                    w={{ base: '0.5rem', sm: '0.5rem', md: '0.8rem' }}
                    h={{ base: '0.5rem', sm: '0.5rem', md: '0.8rem' }}
                    borderRadius='100%'
                    bg='green.500'
                  ></Text>
                ) : (
                  <Text
                    w={{ base: '0.5rem', sm: '0.5rem', md: '0.8rem' }}
                    h={{ base: '0.5rem', sm: '0.5rem', md: '0.8rem' }}
                    borderRadius='100%'
                    bg='#BFBFBF'
                  ></Text>
                )}
              </Box>
            </HStack>
          </HStack>
        </GridItem>
      </Grid>
      <VStack
        // h={{ base: 'auto', sm: 'auto', md: 'auto', lg: '7.5rem' }}
        alignItems='flex-end'
        // position='absolute'
        w='100%'
        fontSize='md'
        display={{ base: 'none', sm: 'none', md: 'none', lg: 'flex' }}
      >
        <Text>สร้างโดย : {itm?.createdBy?.name}</Text>
      </VStack>
    </Box>
  )
}

export default CardDiscount
