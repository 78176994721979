import { db } from 'Config/firebase'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import moment from 'moment'

// import { calPromotionDecodeUnitAmountPerItem } from 'Utils/calStock'
import { decodeUnit } from 'Utils/currency'
export async function QueryReportWarehouseDateMonth({
  month,
  endDate,
  between,
  prevMonth,
  currentDate,
  currentMonth,
  user,
  endDateTime,
  dateTime,
}) {
  const dateEnd = endDate
    ? new Date(moment(new Date(endDate)).endOf('D'))
    : new Date(moment(new Date()).endOf('D'))

  const endMonth = month
    ? new Date(moment(month).endOf('M'))
    : new Date(moment(month).endOf('M'))

  const qInventory = prevMonth
    ? query(
        collection(db, 'Inventory'),
        where('branch', '==', user?.branchSelect?.id),
        where('createdAt', '<=', endMonth),
        orderBy('createdAt', 'desc')
      )
    : between
    ? query(
        collection(db, 'Inventory'),
        where('branch', '==', user?.branchSelect?.id),
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )
    : dateTime
    ? query(
        collection(db, 'Inventory'),
        where('branch', '==', user?.branchSelect?.id),
        where('createdAt', '<=', endDateTime),
        orderBy('createdAt', 'desc')
      )
    : currentDate &&
      query(
        collection(db, 'Inventory'),
        where('branch', '==', user?.branchSelect?.id),
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )

  const dataValue = await (
    await getDocs(qInventory)
  ).docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
    productId: doc.data().id,
  }))
  const res = dataValue.map((item) => ({
    ...item,
    idProduct: item.sku,
    productName: `${item.name} ${item?.nameOption ? item?.nameOption : ''}`,
    description: item.description,
    amount:
      item.method === 'withdrawal'
        ? decodeUnit(item.stock) * -1
        : decodeUnit(item.stock),
    method: item.method,
    createdAt: moment(item.createdAt.toDate()).format('MM/DD/YYYY HH:mm:ss'),
  }))
  return res
}

export async function QueryReportWarehouseHQ({
  month,
  endDate,
  between,
  prevMonth,
  currentDate,
  user,
  endDateTime,
  dateTime,
}) {
  const dateEnd = endDate
    ? new Date(moment(new Date(endDate)).endOf('D'))
    : new Date(moment(new Date()).endOf('D'))

  const endMonth = month
    ? new Date(moment(month).endOf('M'))
    : new Date(moment(month).endOf('M'))

  const qInventory = prevMonth
    ? query(
        collection(db, 'Inventory'),
        where('branch', '==', user?.branchSelect?.id),
        where('createdAt', '<=', endMonth),
        orderBy('createdAt', 'desc')
      )
    : between
    ? query(
        collection(db, 'Inventory'),
        where('branch', '==', user?.branchSelect?.id),
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )
    : dateTime
    ? query(
        collection(db, 'Inventory'),
        where('branch', '==', user?.branchSelect?.id),
        where('createdAt', '<=', endDateTime),
        orderBy('createdAt', 'desc')
      )
    : currentDate &&
      query(
        collection(db, 'Inventory'),
        where('branch', '==', user?.branchSelect?.id),
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )

  const dataValue = await (
    await getDocs(qInventory)
  ).docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
    productId: doc.data().id,
  }))
  const res = dataValue.map((item) => ({
    ...item,
    idProduct: item.sku,
    productName: `${item.name} ${item?.nameOption ? item?.nameOption : ''}`,
    description: item.description,
    amount:
      item.method === 'withdrawal'
        ? decodeUnit(item.stock) * -1
        : decodeUnit(item.stock),
    method: item.method,
    createdAt: moment(item.createdAt.toDate()).format('MM/DD/YYYY HH:mm:ss'),
  }))
  return res
}
