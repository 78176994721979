export function onFilterReportBranchDiscount(data, startDate, endDate, select) {
  const SD = startDate ? startDate.getTime() : new Date()
  const ED = endDate ? endDate.getTime() : new Date()

  const NextSD = SD + 24 * 60 * 60 * 1000
  const PrevSD = SD - 24 * 60 * 60 * 1000
  const NextED = ED + 24 * 60 * 60 * 1000
  const PrevED = ED - 24 * 60 * 60 * 1000
  if (select) {
    const slDiscount = data.filter((item) => item.discountName === select)
    if (startDate) {
      if (endDate) {
        return slDiscount.filter(
          (item) =>
            item.date.getTime() >= PrevSD && item.date.getTime() <= NextED
        )
      }
      return slDiscount.filter(
        (item) => item.date.getTime() >= PrevSD && item.date.getTime() <= NextSD
      )
    }
    if (endDate) {
      return slDiscount.filter(
        (item) => item.date.getTime() >= PrevED && item.date.getTime() <= NextED
      )
    }
    return slDiscount
  }
  if (startDate) {
    if (endDate) {
      return data.filter(
        (item) => item.date.getTime() >= PrevSD && item.date.getTime() <= NextED
      )
    }
    return data.filter(
      (item) => item.date.getTime() >= PrevSD && item.date.getTime() <= NextSD
    )
  }
  if (endDate) {
    return data.filter(
      (item) => item.date.getTime() >= PrevED && item.date.getTime() <= NextED
    )
  }
  return data
}
