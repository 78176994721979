import { ChevronLeftIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import ButtonResponsive from 'Components/Button/btn'
import Card from 'Components/Horizons/card/Card'
import React from 'react'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import CategoryCatFree from './components/category'
import { List } from './components/list'
import { ReceipCatFree } from './components/receipt'

function RadioField({
  id,
  control,
  errors,
  options,
  name,
  required,
  label,
  mb,
  ...rest
}) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  return (
    <FormControl id={name} isInvalid={errors[name]} key={name}>
      <Flex direction='column' mb={mb ? mb : '30px'}>
        <FormLabel
          display='flex'
          ms='10px'
          htmlFor={id}
          fontSize='sm'
          color={textColorPrimary}
          fontWeight='bold'
          _hover={{ cursor: 'pointer' }}
        >
          {label}
        </FormLabel>
        <Controller
          name={name}
          control={control}
          rules={{
            required: required,
          }}
          render={({ field: { value, onChange } }) => (
            <RadioGroup
              value={value}
              onChange={onChange}
              {...rest}
              colorScheme='green'
              w='100%'
            >
              <Stack
                direction={{ base: 'column', md: 'column' }}
                justifyContent='center'
                spacing='1em'
                w='full'
              >
                {options.map((itm, index) => (
                  <Box
                    w='100%'
                    pt='0.5rem'
                    pb='1.2rem'
                    borderBottom='1px solid #E8E9E9'
                    key={index}
                  >
                    <Radio pl='1.5rem' value={itm.value} w='full'>
                      {itm.label ? (
                        <VStack
                          pl='1rem'
                          alignItems='start'
                          spacing={'0xp'}
                          w='full'
                        >
                          <Text w='full'>{itm.name}</Text>
                          <Text color={'gray'} fontSize='sm' w='full'>
                            {itm.label}
                          </Text>
                        </VStack>
                      ) : (
                        <Text>{itm.name}</Text>
                      )}
                    </Radio>
                  </Box>
                ))}
              </Stack>
            </RadioGroup>
          )}
        />
      </Flex>
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
    </FormControl>
  )
}

function First({
  control,
  errors,
  discount,
  setDiscount,
  watch,
  isSubmit,
  setFirst,
}) {
  const [list, setList] = useState(false)
  const [category, setCategory] = useState(false)
  const [receipt, setReceipt] = useState(false)
  const type = watch('typeFree')
  return (
    <>
      <Box display={list || category || receipt ? 'none' : 'block'}>
        <Center>
          <Card
            padding={{
              base: '10px 0px 30px 0px',
              sm: '10px 0px 30px 0px',
              md: '10px 20px 30px 20px',
            }}
            w={{ base: '100%', sm: '100%', md: '30rem' }}
            boxShadow={{
              base: 'none',
              sm: 'none',
              md: '14px 17px 40px 4px rgb(112 144 176 / 8%)',
            }}
          >
            <HStack>
              <ChevronLeftIcon
                cursor='pointer'
                color='form.500'
                fontSize={'2xl'}
                onClick={() => setFirst(false)}
              />
              <Text
                fontSize={{ base: 'md', sm: 'md', md: 'lg' }}
                fontWeight='bold'
              >
                ของแถม
              </Text>
            </HStack>
            <Text
              mt='1rem'
              w='100%'
              borderBottom='1px solid #E8E9E9'
              pb='0.5rem'
              color={'table.100'}
            >
              กรุณาเลือกรูปแบบของแถม
            </Text>
            <Center>
              <Box w='100%'>
                <Stack direction={{ base: 'column', md: 'column' }} w='100%'>
                  <RadioField
                    name={'typeFree'}
                    control={control}
                    errors={errors}
                    options={[
                      {
                        name: 'รายการสินค้า',
                        value: 'list',
                      },
                      {
                        name: 'หมวดหมู่สินค้า',
                        value: 'category',
                      },
                      {
                        name: 'ส่วนลด บาท, เปอร์เซ็นต์',
                        value: 'receipt',
                      },
                    ]}
                  />
                </Stack>
              </Box>
            </Center>
            <Flex w='100%' justifyContent='flex-end'>
              <ButtonResponsive
                disabled={type ? false : true}
                label={'ถัดไป'}
                onClick={() => {
                  if (type === 'list') {
                    setList(true)
                    setCategory(false)
                    setReceipt(false)
                  } else if (type === 'category') {
                    setCategory(true)
                    setList(false)
                    setReceipt(false)
                  } else if (type === 'receipt') {
                    setReceipt(true)
                    setCategory(false)
                    setList(false)
                    setDiscount({ ...discount, status: true })
                  }
                }}
              />
            </Flex>
          </Card>
        </Center>
      </Box>
      {list && (
        <List
          setList={setList}
          setDiscount={setDiscount}
          discount={discount}
          isSubmit={isSubmit}
        />
      )}
      {category && (
        <CategoryCatFree
          setCategory={setCategory}
          setDiscount={setDiscount}
          discount={discount}
          isSubmit={isSubmit}
        />
      )}
      {receipt && (
        <ReceipCatFree
          setReceipt={setReceipt}
          setDiscount={setDiscount}
          discount={discount}
          isSubmit={isSubmit}
        />
      )}
    </>
  )
}

export { First }
