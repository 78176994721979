import { db, storage } from 'Config/firebase'
import { useAppContext } from 'Context'
import {
  collection,
  addDoc,
  writeBatch,
  doc,
  updateDoc,
  query,
  orderBy,
  where,
  getDoc,
  getDocs,
} from 'firebase/firestore'
import { getDownloadURL, ref, uploadString } from 'firebase/storage'
import moment from 'moment'
import { useCallback, useState } from 'react'
import _ from 'underscore'
export function useOrder() {
  const { setError, user } = useAppContext()
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingBranch, setIsLoadingBranch] = useState(true)
  const [isLoadingAllOrderHistory, setIsLoadingAllOrderHistory] = useState(true)
  const [orders, setOrders] = useState([])
  const [ordersReport, setOrdersReport] = useState([])
  const [ordersBranch, setOrdersBranch] = useState([])
  const [orderLastWeek, setOrderLastWeek] = useState([])
  const [isLoadingOrderLastWeek, setIsLoadingOrderLastWeek] = useState(true)
  const InventoryRef = collection(db, 'Inventory')
  const OrderRef = collection(db, 'Orders')

  const statusSuccessOrder = (order) => {
    return new Promise((resolve, reject) => {
      const orderRef = doc(db, 'Orders', order.id)
      updateDoc(orderRef, {
        success: order.success,
      })
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          return reject(err)
        })
    })
  }

  const getAllOrders = useCallback(async () => {
    const getAllOrdersSnapshotRef = query(
      OrderRef,
      orderBy('createdAt', 'desc')
    )
    const getData = await getDocs(getAllOrdersSnapshotRef)
    const data = _.map(getData?.docs, (doc) => {
      return {
        id: doc?.id,
        ...doc?.data(),
        createdAt: doc?.data()?.createdAt,
      }
    })
    setOrders(data)
    setIsLoading(false)
    //eslint-disable-next-line
  }, [])

  const addOrderCustomer = ({ docId, customer, note, customerType }) => {
    const orderDocRef = doc(db, 'Orders', docId)
    return updateDoc(orderDocRef, {
      customer: customer ? customer : '',
      note: note ? note : '',
      customerType: customerType ? customerType : '',
    })
  }

  const getAllOrdersWithReport = useCallback(async () => {
    const getAllOrdersSnapshotRef = query(
      OrderRef,
      orderBy('createdAt', 'desc')
      // limit(25)
    )
    const getData = await getDocs(getAllOrdersSnapshotRef)
    const data = _.map(getData?.docs, (doc) => {
      return {
        id: doc?.id,
        ...doc?.data(),
        createdAt: doc?.data()?.createdAt,
      }
    })
    setOrdersReport(data)
    setIsLoading(false)
    //eslint-disable-next-line
  }, [])

  const getAllOrdersByBranchId = useCallback(async () => {
    const getAllOrdersSnapshotRef = query(
      OrderRef,
      where('branch.id', '==', user?.branchSelect?.id),
      orderBy('createdAt', 'desc')
    )
    const data = await _.map(
      (
        await getDocs(getAllOrdersSnapshotRef)
      )?.docs,
      (doc) => {
        return {
          id: doc?.id,
          ...doc?.data(),
        }
      }
    )
    setOrdersBranch(data)
    setIsLoadingBranch(false)
    //eslint-disable-next-line
  }, [])

  const getOrderByRefMultiple = async (refArr) => {
    return new Promise(async (resolve, reject) => {
      if (refArr.lenght <= 0) {
        return reject({
          error: true,
        })
      }
      const ref = query(OrderRef, where('ref', 'in', refArr))
      const data = await (
        await getDocs(ref)
      ).docs.map((e) => ({
        id: e.id,
        ...e.data(),
      }))
      return resolve(data)
    })
  }

  const getOrderById = async (id) => {
    const paymentDoc = await doc(OrderRef, id)
    return (await getDoc(paymentDoc)).data()
  }

  const getAllOrder = async (startDate, endDate) => {
    if (!startDate) {
      startDate = new Date()
    }
    if (!endDate) {
      endDate = new Date()
    }
    const start = new Date(startDate)
    start.setHours(0)
    start.setMinutes(0)
    start.setSeconds(0)
    start.setMilliseconds(0)

    const dateEnd = new Date(endDate)
    dateEnd.setDate(dateEnd.getDate() + 1)
    dateEnd.setHours(0)
    dateEnd.setMinutes(0)
    dateEnd.setSeconds(0)
    dateEnd.setMilliseconds(0)

    const getOrderLastWeek = query(
      OrderRef,
      where('branch.id', '==', user?.branchSelect?.id),
      where('createdAt', '>=', start),
      where('createdAt', '<=', dateEnd),
      orderBy('createdAt', 'desc')
    )

    const data = await (
      await getDocs(getOrderLastWeek)
    ).docs.map((e) => ({
      id: e.id,
      ...e.data(),
      date: e.data().createdAt.toDate().toLocaleString('th-TH').split(' ')[0],
    }))
    return data
  }

  const getAllOrderInHQ = async (startDate, endDate) => {
    if (!startDate) {
      startDate = new Date()
    }
    if (!endDate) {
      endDate = new Date()
    }
    const start = new Date(startDate)
    start.setHours(0)
    start.setMinutes(0)
    start.setSeconds(0)
    start.setMilliseconds(0)

    const dateEnd = new Date(endDate)
    dateEnd.setDate(dateEnd.getDate() + 1)
    dateEnd.setHours(0)
    dateEnd.setMinutes(0)
    dateEnd.setSeconds(0)
    dateEnd.setMilliseconds(0)

    const getAllOrder = query(
      OrderRef,
      where('createdAt', '>=', start),
      where('createdAt', '<=', dateEnd),
      orderBy('createdAt', 'desc')
    )
    const data = await (
      await getDocs(getAllOrder)
    ).docs.map((e) => ({
      id: e.id,
      createdAt: e.data()?.createdAt,
      ...e.data(),
      date: e.data().createdAt.toDate().toLocaleString('th-TH').split(' ')[0],
    }))
    return data
  }

  const getAllOrderInHQNoLimit = async (startDate, endDate, branch) => {
    let getOrder = query(OrderRef, orderBy('createdAt', 'desc'))
    if (branch) {
      if (startDate && endDate) {
        const start = new Date(startDate)
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)
        start.setMilliseconds(0)

        const dateEnd = new Date(endDate)
        dateEnd.setDate(dateEnd.getDate() + 1)
        dateEnd.setHours(0)
        dateEnd.setMinutes(0)
        dateEnd.setSeconds(0)
        dateEnd.setMilliseconds(0)
        getOrder = query(
          OrderRef,
          where('createdAt', '>=', start),
          where('createdAt', '<=', dateEnd),
          where('branch.id', '==', branch),
          orderBy('createdAt', 'desc')
        )
      } else if (startDate) {
        const start = new Date(startDate)
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)
        start.setMilliseconds(0)
        getOrder = query(
          OrderRef,
          where('createdAt', '>=', start),
          where('branch.id', '==', branch),
          orderBy('createdAt', 'desc')
        )
      } else if (endDate) {
        const dateEnd = new Date(endDate)
        dateEnd.setHours(0)
        dateEnd.setMinutes(0)
        dateEnd.setSeconds(0)
        dateEnd.setMilliseconds(0)
        getOrder = query(
          OrderRef,
          where('createdAt', '<=', dateEnd),
          where('branch.id', '==', branch),
          orderBy('createdAt', 'desc')
        )
      } else {
        getOrder = query(
          OrderRef,
          where('branch.id', '==', branch),
          orderBy('createdAt', 'desc')
        )
      }
    } else if (startDate) {
      if (startDate && endDate) {
        const start = new Date(startDate)
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)
        start.setMilliseconds(0)

        const dateEnd = new Date(endDate)
        dateEnd.setDate(dateEnd.getDate() + 1)
        dateEnd.setHours(0)
        dateEnd.setMinutes(0)
        dateEnd.setSeconds(0)
        dateEnd.setMilliseconds(0)
        getOrder = query(
          OrderRef,
          where('createdAt', '>=', start),
          where('createdAt', '<=', dateEnd),
          orderBy('createdAt', 'desc')
        )
      } else if (endDate) {
        const dateEnd = new Date(endDate)
        dateEnd.setDate(dateEnd.getDate() + 1)
        dateEnd.setHours(0)
        dateEnd.setMinutes(0)
        dateEnd.setSeconds(0)
        dateEnd.setMilliseconds(0)
        getOrder = query(
          OrderRef,
          where('createdAt', '<=', dateEnd),
          orderBy('createdAt', 'desc')
        )
      } else {
        const start = new Date(startDate)
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)
        start.setMilliseconds(0)
        getOrder = query(
          OrderRef,
          where('createdAt', '>=', start),
          orderBy('createdAt', 'desc')
        )
      }
    } else if (endDate) {
      const dateEnd = new Date(endDate)
      dateEnd.setHours(0)
      dateEnd.setMinutes(0)
      dateEnd.setSeconds(0)
      dateEnd.setMilliseconds(0)
      getOrder = query(
        OrderRef,
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )
    }

    return await (
      await getDocs(getOrder)
    ).docs.map((e) => ({
      id: e.id,
      createdAt: e.data()?.createdAt,
      ...e.data(),
    }))
  }

  const getOrderLastWeek = useCallback(async () => {
    setIsLoadingOrderLastWeek(true)
    var date = new Date()
    date.setDate(date.getDate() - 6)
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    const getOrderLastWeek = query(
      OrderRef,
      where('branch.id', '==', user?.branchSelect?.id),
      where('createdAt', '>=', date),
      orderBy('createdAt', 'desc')
    )

    const data = await _.map((await getDocs(getOrderLastWeek))?.docs, (doc) => {
      return {
        id: doc?.id,
        ...doc?.data(),
        date: moment(doc?.data().createdAt?.toDate()).format('MM/DD/YYYY'),
      }
    })
    setOrderLastWeek(data)
    setIsLoadingOrderLastWeek(false)
    //eslint-disable-next-line
  }, [])

  const getOrderByDate = async (startDate, endDate) => {
    if (!startDate) {
      startDate = new Date().setDate(new Date().getDate() - 30)
    }
    if (!endDate) {
      endDate = new Date()
    }
    const start = new Date(startDate)
    start.setHours(0)
    start.setMinutes(0)
    start.setSeconds(0)
    start.setMilliseconds(0)

    const dateEnd = new Date(endDate)
    dateEnd.setDate(dateEnd.getDate() + 1)
    dateEnd.setHours(0)
    dateEnd.setMinutes(0)
    dateEnd.setSeconds(0)
    dateEnd.setMilliseconds(0)

    const getOrderFilter = query(
      OrderRef,
      where('branch.id', '==', user?.branchSelect?.id),
      where('createdAt', '>=', start),
      where('createdAt', '<=', dateEnd),
      orderBy('createdAt', 'desc')
    )

    const data = await (
      await getDocs(getOrderFilter)
    ).docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      date: moment(doc.data().createdAt?.toDate()).format('MM/DD/YYYY'),
    }))
    setIsLoadingAllOrderHistory(false)
    return data
  }

  const orderCreated = async (data, image) => {
    const batch = writeBatch(db)
    return await addDoc(collection(db, 'Orders'), data).then(async (e) => {
      if (image) {
        const orderDocRef = doc(db, 'Orders', e.id)
        try {
          const storageRef = ref(
            storage,
            `Orders/Payment/${data.orderId}/${new Date().getTime()}`
          )
          data.items.forEach(async (e) => {
            const itemsData = {
              branch: data.branch.id,
              createdAt: new Date(),
              createdBy: data.createdBy,
              id: e.id,
              price: e.price,
              sku: e.sku,
              name: e.name,
              cost: e.cost,
              stock: e.amount,
              nameOption: e.nameOption || null,
              commission: e.commission,
              method: 'withdrawal',
              description: `จำหน่ายโดยใบสั่งซื้อ: ${data.orderId}`,
              category: e.category,
              commissionCalType: e.commissionCalType,
              index: e.index,
              minimum: e.minimum,
              ref: e.ref,
              typeCommission: e.typeCommission,
              vat: e.vat,
              orderId: data.orderId,
            }
            batch.set(doc(InventoryRef), itemsData)
          })
          await batch.commit()

          return uploadString(storageRef, image, 'data_url').then(async () => {
            const downloadURL = await getDownloadURL(storageRef)
            return updateDoc(orderDocRef, { img: downloadURL }).then(() => {
              return {
                docId: e.id,
              }
            })
          })
        } catch (error) {
          setError({
            code: 'Order',
            message: error.message,
          })
        }
      } else {
        data.items.forEach(async (e) => {
          const itemsData = {
            branch: data.branch.id,
            createdAt: new Date(),
            createdBy: data.createdBy,
            id: e.id,
            price: e.price,
            sku: e.sku,
            name: e.name,
            cost: e.cost,
            stock: e.amount,
            nameOption: e.nameOption || null,
            commission: e.commission,
            method: 'withdrawal',
            description: `จำหน่ายโดยใบสั่งซื้อ: ${data.orderId}`,
            category: e.category,
            commissionCalType: e.commissionCalType,
            index: e.index,
            minimum: e.minimum,
            ref: e.ref,
            typeCommission: e.typeCommission,
            vat: e.vat,
            orderId: data.orderId,
          }
          batch.set(doc(InventoryRef), itemsData)
        })
        await batch.commit()
        return {
          docId: e.id,
        }
      }
    })
  }

  async function refundOrder(data) {
    const DataToInventory = data.items.map((item) => ({
      branch: user?.branchSelect?.id,
      commission: item.commission,
      cost: item.cost,
      createdAt: new Date(),
      createdBy: {
        id: user?.uid,
        name: user?.name,
      },
      description: `คืนเงินคืนสินค้าจาก: ${data.orderId}`,
      id: item.id,
      method: 'deposit',
      name: item.name,
      price: item.price,
      sku: item.sku,
      stock: item.amount,
      orderId: data.orderId,
    }))
    return await addDoc(collection(db, 'Orders'), data).then(() => {
      return DataToInventory.map((item) => {
        return addDoc(collection(db, 'Inventory'), item)
      })
    })
  }

  const getOrderByRefId = async (id) => {
    const getOrderRef = query(OrderRef, where('ref', '==', id))
    const getData = await (
      await getDocs(getOrderRef)
    ).docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    return getData
  }

  const getAllOrdersWithDate = async ({
    month,
    startDate,
    endDate,
    between,
    prevMonth,
    currentDate,
    currentMonth,
    dateTime,
  }) => {
    var querryRef
    if (currentDate) {
      // วันนี้
      querryRef = query(
        collection(db, 'Orders'),
        where('createdAt', '>=', new Date(moment().startOf('D'))),
        where('createdAt', '<=', new Date(moment().endOf('D'))),
        orderBy('createdAt', 'desc')
      )
    } else if (currentMonth) {
      //เดือนนี้
      querryRef = query(
        collection(db, 'Orders'),
        where('createdAt', '>=', new Date(moment().startOf('M'))),
        where('createdAt', '<=', new Date(moment().endOf('M'))),
        orderBy('createdAt', 'desc')
      )
    } else if (prevMonth) {
      //เดือนก่อน currentMonth,month
      const startMonth = new Date(moment(month).startOf('M'))
      const endMonth = new Date(moment(month).endOf('M'))
      querryRef = query(
        collection(db, 'Orders'),
        where('createdAt', '>=', startMonth),
        where('createdAt', '<=', endMonth),
        orderBy('createdAt', 'desc')
      )
    } else if (between) {
      // between, startDate, endDate
      querryRef = query(
        collection(db, 'Orders'),
        where('createdAt', '>=', new Date(moment(endDate).startOf('D'))),
        where('createdAt', '<=', new Date(moment(endDate).endOf('D'))),
        orderBy('createdAt', 'desc')
      )
    } else if (dateTime) {
      //dateTime , startDateTime, endDateTime
      querryRef = query(
        collection(db, 'Orders'),
        where(
          'createdAt',
          '>=',
          new Date(moment(endDate ? endDate : new Date()).startOf('D'))
        ),
        where(
          'createdAt',
          '<=',
          new Date(moment(endDate ? endDate : new Date()).endOf('D'))
        ),
        orderBy('createdAt', 'desc')
      )
    }

    const getData = await (await getDocs(querryRef)).docs
    const data = await _.map(getData, (doc) => {
      return {
        id: doc?.id,
        ...doc?.data(),
        date: moment(doc.data().createdAt?.toDate()).format('MM/DD/YYYY'),
        empId: doc?.data()?.createdBy?.id,
      }
    })
    return data
  }

  const getAllOrdersWithDateByUserId = async ({
    userId,
    month,
    endDate,
    type,
    startDateTime,
    endDateTime,
  }) => {
    var querryRef
    if (type === 'currentDate') {
      // วันนี้
      querryRef = query(
        collection(db, 'Orders'),
        where('createdAt', '>=', new Date(moment().startOf('D'))),
        where('createdAt', '<=', new Date(moment().endOf('D'))),
        where('createdBy.id', '==', userId),
        orderBy('createdAt', 'desc')
      )
    } else if (type === 'currentMonth') {
      //เดือนนี้
      querryRef = query(
        collection(db, 'Orders'),
        where('createdAt', '>=', new Date(moment().startOf('M'))),
        where('createdAt', '<=', new Date(moment().endOf('M'))),
        where('createdBy.id', '==', userId),
        orderBy('createdAt', 'desc')
      )
    } else if (type === 'prevMonth') {
      //เดือนก่อน currentMonth,month
      const startMonth = new Date(moment(month).startOf('M'))
      const endMonth = new Date(moment(month).endOf('M'))
      querryRef = query(
        collection(db, 'Orders'),
        where('createdAt', '>=', startMonth),
        where('createdAt', '<=', endMonth),
        where('createdBy.id', '==', userId),
        orderBy('createdAt', 'desc')
      )
    } else if (type === 'between') {
      // between, startDate, endDate
      querryRef = query(
        collection(db, 'Orders'),
        where('createdAt', '>=', new Date(moment(endDate).startOf('D'))),
        where('createdAt', '<=', new Date(moment(endDate).endOf('D'))),
        where('createdBy.id', '==', userId),
        orderBy('createdAt', 'desc')
      )
    } else if (type === 'dateTime') {
      //dateTime , startDateTime, endDateTime
      querryRef = query(
        collection(db, 'Orders'),
        where('createdAt', '>=', new Date(startDateTime)),
        where('createdAt', '<=', new Date(endDateTime)),
        where('createdBy.id', '==', userId),
        orderBy('createdAt', 'desc')
      )
    }

    const getData = await (await getDocs(querryRef)).docs
    const data = await _.map(getData, (doc) => {
      return {
        id: doc?.id,
        ...doc?.data(),
        date: moment(doc.data().createdAt?.toDate()).format('MM/DD/YYYY'),
        empId: doc?.data()?.createdBy?.id,
      }
    })
    return data
  }

  return {
    orders,
    ordersReport,
    isLoadingBranch,
    ordersBranch,
    orderLastWeek,
    isLoading,
    isLoadingAllOrderHistory,
    isLoadingOrderLastWeek,
    getAllOrdersWithDateByUserId,
    getOrderByRefId,
    orderCreated,
    getOrderById,
    refundOrder,
    getAllOrder,
    getAllOrderInHQ,
    getOrderByDate,
    getAllOrderInHQNoLimit,
    getAllOrders,
    getAllOrdersByBranchId,
    getOrderLastWeek,
    getAllOrdersWithReport,
    getAllOrdersWithDate,
    addOrderCustomer,
    statusSuccessOrder,
    getOrderByRefMultiple,
  }
}
