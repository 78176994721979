import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Fade,
  HStack,
  IconButton,
  Stack,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import CustomTable from 'Components/Horizons/table/customTable'
import { AiFillEdit } from 'react-icons/ai'
import { BsPlus } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import { usePayment } from 'Hooks'
import { useEffect, useRef, useState } from 'react'
import { DeleteIcon } from '@chakra-ui/icons'

function SettingsBranchPayment() {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const { allData: paymentAllData, removeBankPaymentById } = usePayment()
  const [selectDeleteItem, setSelectDeleteItem] = useState()

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 100)
  }, [])

  const deleteRef = useRef()
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure()

  const handleDelete = async (itemId) => {
    await removeBankPaymentById(itemId).then(() => {
      deleteOnClose()
      setSelectDeleteItem()
    })
  }
  const data = paymentAllData.map((item) => ({
    id: item.id,
    default: item.default,
    paymentType: item.paymentType,
    accountName: item.accountName,
    bank: item.bank?.name,
    moreDetails: item.typeOfAccount,
    typeOfAccount: item.typeOfAccount,
    disabled: item.disabled,
    edit: item.edit,
  }))
  const columnsData = [
    // {
    //   Header: 'ค่าเริ่มต้น',
    //   accessor: 'default',
    //   extra: (data, row) => {
    //     return (
    //       row?.default && (
    //         <Center h='full'>
    //           <Box bg='#38A169' borderRadius='full' w='0.7rem' h='0.7rem'></Box>
    //         </Center>
    //       )
    //     )
    //   },
    // },
    {
      Header: 'ช่องทางการชำระ',
      accessor: 'paymentType',
    },
    {
      Header: 'ชื่อบัญชี',
      accessor: 'accountName',
    },
    {
      Header: 'ธนาคาร',
      accessor: 'bank',
      extra: (data, row) => {
        return (
          <Text fontSize={'sm'} color={'black'}>
            {row?.bank === undefined ? '-' : row?.bank}
          </Text>
        )
      },
    },
    {
      Header: 'รายละเอียดเพิ่มเติม',
      accessor: 'moreDetails',
      extra: (data, row) => {
        return (
          <HStack>
            <Text fontSize={'sm'} color={'black'}>
              {row?.accountDetail !== undefined
                ? '-'
                : row?.paymentType === 'บัญชีธนาคาร'
                ? `ประเภทบัญชี: ${row?.typeOfAccount}`
                : row?.accountDetail}
            </Text>
          </HStack>
        )
      },
    },
    {
      Header: 'การจัดการ',
      accessor: 'management',
      extra: (data, row) => {
        return (
          <HStack justifyContent='space-between'>
            <HStack spacing={'1.5rem'}>
              {/* <Switch
                isChecked={row?.disabled === false}
                colorScheme={'green'}
                onChange={() => {
                  updateDisabledById(row, !row?.disabled)
                }}
              /> */}

              {row?.edit === true ? (
                <Button
                  w='3rem'
                  h='3rem'
                  borderRadius='full'
                  onClick={() => {
                    if (row?.paymentType === 'บัญชีธนาคาร') {
                      navigate(`/settings/payment/branch/edit/bank/${row?.id}`)
                    } else {
                      navigate(`/settings/payment/branch/edit/cash/${row?.id}`)
                    }
                  }}
                >
                  <AiFillEdit fontSize={'20px'} color={'payment.500'} />
                </Button>
              ) : (
                <Button
                  w='3rem'
                  h='3rem'
                  borderRadius='full'
                  disabled={row?.edit === false && true}
                >
                  <AiFillEdit fontSize={'20px'} color={'payment.500'} />
                </Button>
              )}

              <IconButton
                icon={<DeleteIcon />}
                color={'red'}
                disabled={row?.default}
                onClick={() => {
                  setSelectDeleteItem(row?.id)
                  deleteOnOpen()
                }}
              />
            </HStack>
          </HStack>
        )
      },
    },
  ]

  return (
    <>
      <Stack as={Fade} in={!isLoading} direction='column' initialScale={0.9}>
        {paymentAllData && (
          <Card w={'full'}>
            <AlertDialog
              motionPreset='slideInBottom'
              leastDestructiveRef={deleteRef}
              onClose={deleteOnClose}
              isOpen={deleteIsOpen}
              isCentered
              size='auto'
            >
              <AlertDialogOverlay />
              <AlertDialogContent
                w={{ sm: '20.5rem', md: '25rem' }}
                fontFamily={'Prompt'}
              >
                <AlertDialogHeader>คุณแน่ใจ?</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                  คุณแน่ใจที่จะลบธนาคารนี้ใช่หรือไม่ ?
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={deleteRef} onClick={deleteOnClose}>
                    ไม่
                  </Button>
                  <Button
                    colorScheme='red'
                    ml={3}
                    onClick={() => {
                      handleDelete(selectDeleteItem)
                      //
                    }}
                  >
                    ใช่
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
            <CustomTable columnsData={columnsData} tableData={data} />
          </Card>
        )}
      </Stack>
      <Box position='fixed' right={5} bottom={5}>
        <Button
          as={Link}
          colorScheme='btn'
          color='#ffff'
          borderRadius={'full'}
          w={'50px'}
          h={'50px'}
          to={'/settings/payment/branch/add/bank'}
        >
          <Icon as={BsPlus} fontSize={'45px'} />
        </Button>
      </Box>
    </>
  )
}

export default SettingsBranchPayment
