import {
  Box,
  Button,
  Center,
  Fade,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { MdOutlineCloudUpload } from 'react-icons/md'
import Dropzone from '../../../../Components/form/components/dropzone'

function PosBankTakeSlip(props) {
  const { setPaymentStep, file, setFile } = props
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const bg = useColorModeValue('gray.100', 'navy.700')
  const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100')
  const brand = useColorModeValue('brand.500', 'brand.400')
  return (
    <>
      <Center pt='2rem' h='auto' w='full'></Center>
      <Center>
        {file ? (
          <Dropzone
            setFile={setFile}
            content={
              <Box>
                <Center
                  as={Fade}
                  in={0.6}
                  align='center'
                  justify='center'
                  bg={bg}
                  borderColor={borderColor}
                  w='100%'
                  maxW='100%'
                  h='max-content'
                  minH='130px'
                  cursor='pointer'
                >
                  <Image w='150px' src={file} objectFit='cover' />
                </Center>
              </Box>
            }
          />
        ) : (
          <Dropzone
            setFile={setFile}
            content={
              <Box>
                <Icon
                  as={MdOutlineCloudUpload}
                  w='80px'
                  h='80px'
                  color={textColor}
                />
                <Text
                  mx='auto'
                  mb='12px'
                  fontSize='lg'
                  fontWeight='700'
                  whiteSpace='pre-wrap'
                  color={textColor}
                >
                  Drop your files here, or{' '}
                  <Text as='span' fontSize='lg' fontWeight='700' color={brand}>
                    browse
                  </Text>
                </Text>
                <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                  PNG, JPG and GIF files are allowed
                </Text>
              </Box>
            }
          />
        )}
      </Center>
      <Center
        pt={{ base: '6rem', sm: '6rem', md: '2rem' }}
        display={{
          base: 'none',
          sm: 'none',
          md: 'flex',
          lg: 'flex',
          xl: 'flex',
          '2xl': 'flex',
        }}
        bottom='0px'
        w='100%'
      >
        <Box p='0.3rem' w='100%'>
          <Button
            w='100%'
            colorScheme='btn'
            py='1.7rem'
            borderRadius='5px'
            onClick={() => {
              setPaymentStep({ bankReceived: true })
            }}
          >
            กลับหน้าชำระเงิน
          </Button>
        </Box>
      </Center>
      <Center
        display={{
          base: 'flex',
          sm: 'flex',
          md: 'none',
          lg: 'none',
          xl: 'none',
          '2xl': 'none',
        }}
        bottom='0px'
        w='100%'
        h='100vh'
        alignItems='flex-end'
        zIndex='overlay'
      >
        <Box p='0.3rem' w='100%'>
          <Button
            w='100%'
            py='1.7rem'
            colorScheme='btn'
            borderRadius='5px'
            onClick={() => {
              setPaymentStep({ bankReceived: true })
            }}
          >
            กลับหน้าชำระเงิน
          </Button>
        </Box>
      </Center>
    </>
  )
}

export default PosBankTakeSlip
