import _ from 'underscore'
import { decodeUnit } from 'Utils/currency'

// คำนวน ราคาสินค้า ทั้งหมด (total)
// ถ้ามีส่วนลดจะคำนวนส่วนลดด้วย
export const calAmount = (items, discount) => {
  let subTotal = _.map(items, (item) => {
    if (item.promotion) {
      return parseFloat(calPromotion(item))
    } else {
      return parseFloat(decodeUnit(item.amount) * item.price)
    }
  }).reduce((memo, num) => {
    return memo + num
  }, 0)
  if (discount) {
    if (discount.discountType === 'Bath') {
      return parseFloat(subTotal - discount.discount).toFixed(2)
    } else if (discount.discountType === 'Percentage') {
      return parseFloat(
        subTotal - (subTotal * discount.discount) / 100
      ).toFixed(2)
    }
  } else {
    return parseFloat(subTotal)
  }
}

export const calAmountTotal = (items, discount) => {
  let subTotal = _.map(items, (item) => {
    return parseFloat((item.amount / 100) * item.price)
  }).reduce((memo, num) => {
    return memo + num
  }, 0)
  if (discount) {
    if (discount.discountType === 'Bath') {
      return parseFloat(subTotal - discount.discount / 100).toFixed(2)
    } else if (discount.discountType === 'Percentage') {
      return parseFloat(
        subTotal - (subTotal * (discount.discount / 100)) / 100
      ).toFixed(2)
    }
  } else {
    return parseFloat(subTotal).toFixed(2)
  }
}

// คำนวนเฉพาะส่วนลดว่าลดไปกี่บาท
export const calDiscount = (items, discount) => {
  let subTotal = _.map(items, (item) => {
    return parseFloat(item.amount * item.price)
  }).reduce((memo, num) => {
    return memo + num
  }, 0)
  if (discount) {
    if (discount.discountType === 'Bath') {
      return parseFloat(discount.discount).toFixed(2)
    } else if (discount.discountType === 'Percentage') {
      return parseFloat((subTotal * discount.discount) / 100).toFixed(2)
    }
  } else {
    return parseFloat(subTotal).toFixed(2)
  }
}

export const calGrandTotal = (items, discount, vat) => {
  const amount = calAmount(items, discount)
  let withVat = discount > 0 ? (amount * 7) / 100 : amount
  const grandTotal = amount + (vat ? withVat : 0)
  return grandTotal
}

// คำนวณการทอนเงิน
export const calReceived = (total, priceReceived) => {
  if (priceReceived >= total) {
    return parseFloat(priceReceived - total).toFixed(2)
  }
  return 0
}

//คำนวณโปรโมชั่น
export const calPromotion = (item) => {
  // Refactor ไปเป็นฟังชั่นกลาง
  //โปรโมชั่นชิ้น
  if (!item) {
    return 0
  }
  if (item.promotion.typeDiscount === 'piece') {
    if (item.promotion.discountType === 'Percentage') {
      return parseFloat(
        ((decodeUnit(item.price) * item.promotion.discount) / 100) * item.amount
      ).toFixed(2)
    } else if (item.promotion.discountType === 'Bath') {
      return parseFloat(item.amount * item.promotion.discount).toFixed(2)
    }
    //โปรโมชั่นจากยอดขาย
  } else if (item.promotion.typeDiscount === 'receipt') {
    if (item.promotion.discountType === 'Percentage') {
      return parseFloat(
        (item.amount * decodeUnit(item.price) * item.promotion.discount) / 100
      ).toFixed(2)
    } else if (item.promotion.discountType === 'Bath') {
      return parseFloat(item.promotion.discount).toFixed(2)
    }
  }
}
