import { db } from 'Config/firebase'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { decodeUnit } from 'Utils'

const prevDate = new Date()
prevDate.setHours(0)
prevDate.setMinutes(0)
prevDate.setSeconds(0)
prevDate.setMilliseconds(0)

const nextDate = new Date()
nextDate.setDate(nextDate.getDate() + 1)
nextDate.setHours(0)
nextDate.setMinutes(0)
nextDate.setSeconds(0)
nextDate.setMilliseconds(0)

const prevMonthDate = new Date()
prevMonthDate.setDate(1)
prevMonthDate.setHours(0)
prevMonthDate.setMinutes(0)
prevMonthDate.setSeconds(0)
prevMonthDate.setMilliseconds(0)

const nextMonth = new Date()
nextMonth.setMonth(nextMonth.getMonth() + 1)
nextMonth.setDate(0)
nextMonth.setHours(23)
nextMonth.setMinutes(59)
nextMonth.setSeconds(59)
nextMonth.setMilliseconds(59)

export async function QueryExpenses({
  month,
  startDate,
  endDate,
  between,
  prevMonth,
  currentDate,
  currentMonth,
  user,
  startDateTime,
  endDateTime,
  dateTime,
  select,
}) {
  const start = new Date(startDate)
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  start.setMilliseconds(0)

  const dateEnd = new Date(endDate)
  dateEnd.setDate(dateEnd.getDate() + 1)
  dateEnd.setHours(0)
  dateEnd.setMinutes(0)
  dateEnd.setSeconds(0)
  dateEnd.setMilliseconds(0)

  const startMonth = new Date(month)
  startMonth.setDate(1)
  startMonth.setHours(0)
  startMonth.setMinutes(0)
  startMonth.setSeconds(0)
  startMonth.setMilliseconds(0)

  const endMonth = new Date(month)
  endMonth.setMonth(endMonth.getMonth() + 1)
  endMonth.setDate(0)
  endMonth.setHours(23)
  endMonth.setMinutes(59)
  endMonth.setSeconds(59)
  endMonth.setMilliseconds(59)

  const qExpenses = prevMonth
    ? query(
        collection(db, 'Expenses'),
        where('branch', '==', user?.branchSelect?.id),
        where('status', '==', 'approvel'),
        where('createdAt', '>=', startMonth),
        where('createdAt', '<=', endMonth),
        orderBy('createdAt', 'desc')
      )
    : between
    ? query(
        collection(db, 'Expenses'),
        where('branch', '==', user?.branchSelect?.id),
        where('status', '==', 'approvel'),
        where('createdAt', '>=', start),
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )
    : currentMonth
    ? query(
        collection(db, 'Expenses'),
        where('branch', '==', user?.branchSelect?.id),
        where('status', '==', 'approvel'),
        where('createdAt', '>=', prevMonthDate),
        where('createdAt', '<=', nextMonth),
        orderBy('createdAt', 'desc')
      )
    : dateTime
    ? query(
        collection(db, 'Expenses'),
        where('branch', '==', user?.branchSelect?.id),
        where('status', '==', 'approvel'),
        where('createdAt', '>=', startDateTime),
        where('createdAt', '<=', endDateTime),
        orderBy('createdAt', 'desc')
      )
    : currentDate &&
      query(
        collection(db, 'Expenses'),
        where('branch', '==', user?.branchSelect?.id),
        where('status', '==', 'approvel'),
        where('createdAt', '>=', prevDate),
        where('createdAt', '<=', nextDate),
        orderBy('createdAt', 'desc')
      )

  const res = await (
    await getDocs(qExpenses)
  ).docs.map((doc) => ({
    ...doc.data(),
    id: doc?.id,
    total: decodeUnit(doc.data()?.total),
    grandTotal: decodeUnit(doc.data()?.grandTotal),
    totalVat: decodeUnit(doc.data()?.totalVat),
    createdAt: doc
      .data()
      ?.createdAt?.toDate()
      .toLocaleString('en-US', { hour12: false }),
  }))

  const results = select ? res?.filter((f) => f.category === select) : res
  return results
}
