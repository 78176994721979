import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import {
  IoMenuOutline,
  IoSettingsSharp,
  IoPricetagsOutline,
} from 'react-icons/io5'
import { MdMonitor, MdSpaceDashboard } from 'react-icons/md'
import { GiBoxUnpacking } from 'react-icons/gi'
import { HiOutlineDocumentReport, HiSortAscending } from 'react-icons/hi'
import SidebarContent from './Components/content'
import { RiGitBranchFill, RiTeamFill } from 'react-icons/ri'
import { useRef, useState } from 'react'
import { FaWarehouse } from 'react-icons/fa'

const LinkItems = [
  {
    name: 'ภาพรวม',
    icon: MdSpaceDashboard,
    href: '/dashboard',
    collection: true,
    role: ['Administrator', 'Manager'],
    branch: ['HQ', 'สาขา', 'สำนักงานใหญ่'],
    items: [
      {
        name: 'ภาพรวมทั้งหมด',
        href: '/admin',
        role: ['Administrator'],
        branch: ['HQ'],
      },
      {
        name: 'ภาพรวมสาขา',
        href: '/branch',
        role: ['Administrator', 'Manager'],
        branch: ['สาขา', 'สำนักงานใหญ่'],
      },
    ],
  },
  {
    name: 'POS',
    icon: MdMonitor,
    href: '/pos',
    collection: true,
    role: ['Administrator', 'Manager', 'Cashier'],
    branch: ['สาขา', 'สำนักงานใหญ่'],
    items: [
      {
        name: 'ขายสินค้า',
        href: '/cashier',
        role: ['Administrator', 'Manager', 'Cashier'],
        branch: ['สาขา', 'สำนักงานใหญ่'],
      },
      {
        name: 'รายการขาย',
        href: '/history',
        role: ['Administrator', 'Manager', 'Cashier'],
        branch: ['สาขา', 'สำนักงานใหญ่'],
      },
    ],
  },
  {
    name: 'สินค้า',
    icon: GiBoxUnpacking,
    href: '/product',
    collection: true,
    role: ['Administrator', 'Manager'],
    branch: ['HQ'],
    items: [
      {
        name: 'รายการสินค้า',
        href: '/list',
        role: ['Administrator', 'Manager'],
        branch: ['HQ'],
      },
      {
        name: 'หมวดหมู่สินค้า',
        href: '/category',
        role: ['Administrator', 'Manager'],
        branch: ['HQ'],
      },
    ],
  },
  {
    name: 'โปรโมชั่นส่วนลด',
    icon: IoPricetagsOutline,
    href: '/discount',
    collection: true,
    role: ['Administrator'],
    branch: ['HQ'],
    items: [
      {
        name: 'โปรโมชั่นส่วนลด',
        href: '/store',
        role: ['Administrator'],
        branch: ['HQ'],
      },
    ],
  },
  // {
  //   name: 'CRM',
  //   icon: IoGiftOutline,
  //   href: '/crm',
  //   collection: true,
  //   role: ['Administrator', 'Manager'],
  //   branch: ['HQ'],
  //   items: [
  //     {
  //       name: 'ตั้งค่าสะสมแต้ม',
  //       href: '/setting',
  //       role: ['Administrator', 'Manager'],
  //       branch: ['HQ'],
  //     },
  //     {
  //       name: 'สิทธิพิเศษ',
  //       href: '/priority',
  //       role: ['Administrator', 'Manager'],
  //       branch: ['HQ'],
  //     },
  //     {
  //       name: 'สมาชิก',
  //       href: '/member',
  //       role: ['Administrator', 'Manager'],
  //       branch: ['HQ'],
  //     },
  //   ],
  // },
  {
    name: 'คลังสินค้า',
    icon: FaWarehouse,
    href: '/inventory',
    collection: true,
    role: ['Administrator', 'Manager'],
    branch: ['HQ', 'สาขา', 'สำนักงานใหญ่'],
    items: [
      {
        name: 'คลังสินค้า',
        href: '/list',
        role: ['Administrator', 'Manager'],
        branch: ['HQ'],
      },
      {
        name: 'คลังสินค้าสาขา',
        href: '/branch',
        role: ['Administrator', 'Manager'],
        branch: ['สาขา', 'สำนักงานใหญ่'],
      },
    ],
  },
  {
    name: 'รายจ่าย',
    icon: HiSortAscending,
    href: '/expenses',
    collection: true,
    branch: ['HQ', 'สาขา', 'สำนักงานใหญ่'],
    role: ['Administrator', 'Manager', 'Cashier'],
    items: [
      {
        name: 'รายจ่าย',
        href: '/list',
        role: ['Administrator', 'Manager', 'Cashier'],
        branch: ['HQ', 'สาขา', 'สำนักงานใหญ่'],
      },
      {
        name: 'หมวดหมู่รายจ่าย',
        href: '/category/list',
        role: ['Administrator', 'Manager', 'Cashier'],
        branch: ['HQ', 'สาขา', 'สำนักงานใหญ่'],
      },
    ],
  },
  {
    name: 'พนักงาน',
    icon: RiTeamFill,
    href: '/employees',
    collection: true,
    role: ['Administrator'],
    branch: ['HQ'],
    items: [
      {
        name: 'พนักงาน',
        href: '/list',
        role: ['Administrator'],
        branch: ['HQ'],
      },
      {
        name: 'ค่าจ้าง/คอมมิชชั่น พนักงานรายวัน',
        href: '/commission/daily',
        role: ['Administrator'],
        branch: ['HQ'],
      },
      {
        name: 'ค่าจ้าง/คอมมิชชั่น พนักงานรายเดือน',
        href: '/commission/monthly',
        role: ['Administrator'],
        branch: ['HQ'],
      },
    ],
  },
  {
    name: 'สาขา',
    icon: RiGitBranchFill,
    href: '/branchs',
    collection: true,
    role: ['Administrator', 'Manager'],
    branch: ['HQ'],
    items: [
      {
        name: 'สาขาทั้งหมด',
        href: '/list',
        role: ['Administrator', 'Manager'],
        branch: ['HQ'],
      },
    ],
  },
  {
    name: 'ตั้งค่าธุรกิจ',
    icon: IoSettingsSharp,
    href: '/settings',
    collection: true,
    role: ['Administrator'],
    branch: ['HQ'],
    items: [
      {
        name: 'ช่องทางการเงิน',
        href: '/payment/list',
        branch: ['HQ'],
        role: ['Administrator', 'Manager'],
      },
    ],
  },
  {
    name: 'รายงาน',
    icon: HiOutlineDocumentReport,
    href: '/report',
    collection: true,
    branch: ['HQ'],
    role: ['Administrator'],
    items: [
      // {
      //   name: 'รายงานยอดขายตามสาขา',
      //   href: '/branch',
      //   role: ['Administrator'],
      //   branch: ['HQ'],
      // },
      // {
      //   name: 'รายงานขายตามสินค้า',
      //   href: '/product',
      //   role: ['Administrator'],
      //   branch: ['HQ'],
      // },
      // {
      //   name: 'รายงานยอดขายตาม พนักงาน',
      //   href: '/staff',
      //   role: ['Administrator'],
      //   branch: ['HQ'],
      // },
      // {
      //   name: 'รายงานรายจ่ายตามสาขา',
      //   href: '/expenses',
      //   role: ['Administrator'],
      //   branch: ['HQ'],
      // },
      {
        name: 'รายงานรายจ่าย',
        href: '/branch/expenses',
        role: ['Administrator'],
        branch: ['HQ'],
      },
      // {
      //   name: 'รายงานการคืนเงิน',
      //   href: '/refund',
      //   role: ['Administrator'],
      //   branch: ['HQ'],
      // },
      // {
      //   name: 'รายงานส่วนลด',
      //   href: '/discount',
      //   role: ['Administrator'],
      //   branch: ['HQ'],
      // },
      {
        name: 'รายงานคลังสินค้า',
        href: '/warehouse',
        role: ['Administrator'],
        branch: ['HQ'],
      },
      {
        name: 'รายงานการเข้า-ออกพนักงาน',
        href: '/staffwork',
        role: ['Administrator'],
        branch: ['HQ'],
      },
      // {
      //   name: 'รายงาน login logout พนักงาน',
      //   href: '/staffwork',
      //   role: ['Administrator'],
      //   branch: ['HQ'],
      // },
    ],
  },
  // {
  //   name: 'สั่งสินค้า',
  //   icon: BsBoxSeam,
  //   href: '/purchase-order',
  //   collection: true,
  //   branch: ['สำนักงานใหญ่', 'สาขา'],
  //   role: ['Administrator', 'Manager', 'Cashier'],
  //   items: [
  //     {
  //       name: 'รายการสั่งสินค้า',
  //       href: '/list',
  //       role: ['Administrator', 'Manager', 'Cashier'],
  //       branch: ['สำนักงานใหญ่', 'สาขา'],
  //     },
  //   ],
  // },
  // {
  //   name: 'ใบสั่งสินค้า',
  //   icon: BsBoxSeam,
  //   href: '/purchase-order',
  //   collection: true,
  //   branch: ['HQ'],
  //   role: ['Administrator'],
  //   items: [
  //     {
  //       name: 'จัดการใบสั่งสินค้า',
  //       href: '/manage',
  //       role: ['Administrator'],
  //       branch: ['HQ'],
  //     },
  //   ],
  // },
  {
    name: 'รายงาน',
    icon: HiOutlineDocumentReport,
    href: '/report',
    collection: true,
    branch: ['สำนักงานใหญ่', 'สาขา'],
    role: ['Administrator', 'Manager'],
    items: [
      {
        name: 'รายงานรายการขาย',
        href: '/branch/sales',
        role: ['Administrator'],
        branch: ['สำนักงานใหญ่', 'สาขา'],
      },
      {
        name: 'รายงานยอดขายตามสินค้า',
        href: '/branch/product',
        role: ['Administrator'],
        branch: ['สำนักงานใหญ่', 'สาขา'],
      },
      {
        name: 'รายงานยอดขายตามพนักงาน',
        href: '/branch/employee',
        role: ['Administrator', 'Manager'],
        branch: ['สำนักงานใหญ่', 'สาขา'],
      },
      {
        name: 'รายงานค่าจ้างพนักงาน',
        href: '/branch/employeewages',
        role: ['Administrator', 'Manager'],
        branch: ['สำนักงานใหญ่', 'สาขา'],
      },
      {
        name: 'รายงานยอดขายตามประเภทการชำระเงิน',
        href: '/branch/payment',
        role: ['Administrator', 'Manager'],
        branch: ['สำนักงานใหญ่', 'สาขา'],
      },
      // {
      //   name: 'รายงานยอดขายสาขา',
      //   href: '/branch/sales',
      //   role: ['Administrator'],
      //   branch: ['สำนักงานใหญ่', 'สาขา'],
      // },
      // {
      //   name: 'รายงานยอดขายตามสินค้า',
      //   href: '/product/sales',
      //   role: ['Administrator'],
      //   branch: ['สำนักงานใหญ่', 'สาขา'],
      // },
      // {
      //   name: 'รายงานยอดขายตามพนักงาน',
      //   href: '/staff/sales',
      //   role: ['Administrator'],
      //   branch: ['สำนักงานใหญ่', 'สาขา'],
      // },
      {
        name: 'รายงานรายจ่ายสาขา',
        href: '/branch/expense',
        role: ['Administrator', 'Manager'],
        branch: ['สำนักงานใหญ่', 'สาขา'],
      },
      // {
      //   name: 'รายงานการคืนเงิน',
      //   href: '/refund/expense',
      //   role: ['Administrator'],
      //   branch: ['สำนักงานใหญ่', 'สาขา'],
      // },
      // {
      //   name: 'รายงานส่วนลดร้านค้า',
      //   href: '/discount/expense',
      //   role: ['Administrator'],
      //   branch: ['สำนักงานใหญ่', 'สาขา'],
      // },
      // {
      //   name: 'รายงานส่วนลดสินค้า',
      //   href: '/discount/expense/product',
      //   role: ['Administrator'],
      //   branch: ['สำนักงานใหญ่', 'สาขา'],
      // },
      {
        name: 'รายงานคลังสินค้า',
        href: '/warehouse/expense',
        role: ['Administrator', 'Manager'],
        branch: ['สำนักงานใหญ่', 'สาขา'],
      },
      {
        name: 'รายงาน login logout พนักงาน',
        href: '/staff/work',
        role: ['Administrator', 'Manager'],
        branch: ['สำนักงานใหญ่', 'สาขา'],
      },
    ],
  },
]

export default function Sidebar({ onClose, user, ...rest }) {
  let variantChange = '0.2s linear'
  let shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    'unset'
  )
  const [openStage, setOpenStage] = useState([])

  let sidebarBg = useColorModeValue('white', 'navy.800')
  let sidebarRadius = '30px'
  let sidebarMargins = '0px'
  return (
    <Box display={{ sm: 'none', xl: 'block' }} position='fixed' minH='100%'>
      <Box
        bg={sidebarBg}
        transition={variantChange}
        w='285px'
        ms={{
          sm: '16px',
        }}
        my={{
          sm: '16px',
        }}
        h='calc(100vh - 32px)'
        m={sidebarMargins}
        borderRadius={sidebarRadius}
        minH='100%'
        overflowX='hidden'
        boxShadow={shadow}
      >
        <SidebarContent
          user={user}
          links={LinkItems}
          setOpenStage={setOpenStage}
          openStage={openStage}
        />
      </Box>
    </Box>
  )
}

export function SidebarResponsive(props) {
  const { user } = props
  let sidebarBackgroundColor = useColorModeValue('white', 'navy.800')
  let menuColor = useColorModeValue('gray.400', 'white')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [openStage, setOpenStage] = useState([])
  const btnRef = useRef()

  return (
    <Flex display={{ sm: 'flex', xl: 'none' }} alignItems='center'>
      <Flex ref={btnRef} w='max-content' h='max-content' onClick={onOpen}>
        <Icon
          as={IoMenuOutline}
          color={menuColor}
          my='auto'
          w='20px'
          h='20px'
          me='10px'
          _hover={{ cursor: 'pointer' }}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === 'rtl' ? 'right' : 'left'}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          w='285px'
          maxW='285px'
          ms={{
            sm: '16px',
          }}
          my={{
            sm: '16px',
          }}
          borderRadius='16px'
          bg={sidebarBackgroundColor}
        >
          <DrawerCloseButton
            color='white'
            zIndex='3'
            onClose={onClose}
            _focus={{ boxShadow: 'none' }}
            _hover={{ boxShadow: 'none' }}
          />
          <DrawerBody maxW='285px' px='0rem' pb='0'>
            <SidebarContent
              user={user}
              onClose={onClose}
              links={LinkItems}
              responsive={true}
              setOpenStage={setOpenStage}
              openStage={openStage}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  )
}
