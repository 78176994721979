import {
  Center,
  HStack,
  Flex,
  Box,
  Text,
  Input,
  Button,
  Stack,
  Heading,
  VStack,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { CustomTable } from '../components/customTable'
import { SearchIcon } from '@chakra-ui/icons'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { useQueryReportExpenses } from './functions/query'

const accounting = require('accounting')
const _ = require('underscore')

function Price(props) {
  const { grandTotal } = props
  return (
    <HStack justifyContent='end' spacing='1em'>
      <Stack>
        <Box>
          <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
            คืนเงินรวม
          </Text>
        </Box>
        <Box>
          <Text fontSize={{ base: '12px', md: '16px' }} color='report.500'>
            {accounting.formatNumber(grandTotal, 2)}
          </Text>
        </Box>
      </Stack>
    </HStack>
  )
}

function RefundBranchExpense({ user }) {
  const { QueryRefund } = useQueryReportExpenses()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [data, setData] = useState([])
  const [onSearch, setOnSearch] = useState(false)

  function calGrandTotal(item) {
    const value = _.reduce(item, (v, i) => v + parseFloat(i.grandTotal), 0)
    return value
  }
  async function onSubmitFilter() {
    const res = await QueryRefund({
      startDate: startDate,
      endDate: endDate,
      user: user,
    })
    setData(res)
    setOnSearch(true)
  }

  //   setData(
  //     res.map((item) => ({
  //       ...item,
  //       discount:
  //         item.status === 'refund'
  //           ? _.reduce(
  //               item.items,
  //               (v, i) =>
  //                 i.promotion ? v + parseFloat(i.promotion?.discount) : v + 0,
  //               0
  //             ) * -1
  //           : _.reduce(
  //               item.items,
  //               (v, i) =>
  //                 i.promotion ? v + parseFloat(i.promotion?.discount) : v + 0,
  //               0
  //             ),
  //       grandTotal:
  //         item.status === 'refund'
  //           ? item.grandTotal +
  //             _.reduce(
  //               item.items,
  //               (v, i) =>
  //                 i.promotion ? v + parseFloat(i.promotion?.discount) : v + 0,
  //               0
  //             )
  //           : item.grandTotal -
  //             _.reduce(
  //               item.items,
  //               (v, i) =>
  //                 i.promotion ? v + parseFloat(i.promotion?.discount) : v + 0,
  //               0
  //             ),
  //     }))
  //   )
  // }, [user])
  // useEffect(() => {
  //   fetchData()
  // }, [fetchData])
  return (
    <>
      <HStack
        my='1em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='1vw'
        flexDirection={{ sm: 'column', md: 'row' }}
      >
        <Flex flexDirection='column' w={{ sm: 'full', md: '30vw', lg: '30vw' }}>
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ช่วงเวลา :</Text>
          </Box>
          <Flex bg='#F4F7FE' borderRadius='16px'>
            <Input
              cursor='pointer'
              border='0px'
              w='full'
              textAlign='center'
              as={DatePicker}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText={'วันที่เริ่มต้น'}
            />
            <Input
              cursor='pointer'
              border='0px'
              w='full'
              textAlign='center'
              as={DatePicker}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText={'วันที่สิ้นสุด'}
            />
          </Flex>
        </Flex>
        <Flex
          display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        ></Flex>
        <Flex
          display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        ></Flex>
        <Flex
          flexDirection='column'
          w={{ sm: 'full', md: '19vw', lg: '13vw', xl: '11vw' }}
          alignItems='end'
          pt={{ sm: '1rem', md: '0rem' }}
        >
          <Box
            textAlign='start'
            display={{ sm: 'none', md: 'flex' }}
            p='0.5rem'
            pl='1rem'
            pt='0rem'
          >
            <Text color='#8F9BBA'>&nbsp;</Text>
          </Box>
          <Button
            h='44px'
            maxh='44px'
            w={{ sm: '10rem', md: 'full' }}
            colorScheme='btn'
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            onClick={async () => onSubmitFilter()}
          >
            <Text>
              <SearchIcon />
            </Text>
            <Text>แสดงรายงาน</Text>
          </Button>
        </Flex>
      </HStack>
      <Flex
        flexDirection='column'
        p={{ sm: '10px', md: '20px 20px 30px 20px' }}
        bg='white'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
        borderRadius='30px'
      >
        <Stack spacing={{ base: '0em', md: '4em' }}>
          <Stack>
            <Heading
              fontFamily={'Prompt'}
              color='#2B3674'
              fontSize={{ base: '24px', md: '30px' }}
            >
              รายงานการคืนเงิน
            </Heading>
            <Box>
              <HStack>
                <Text color='#2B3674'>ณ วันที่:</Text>
                <Text color='#2B3674'>
                  {moment(new Date().getTime()).format('MM/DD/YYYY')}
                </Text>
              </HStack>
              <HStack>
                <Text color='#2B3674'>ช่วงเวลา:</Text>
                {startDate && endDate && (
                  <Text color='#2B3674'>
                    {moment(startDate).format('MM/DD/YYYY')} -{' '}
                    {moment(endDate).format('MM/DD/YYYY')}
                  </Text>
                )}
              </HStack>
            </Box>
          </Stack>
          <Box
            pt={{ base: '1rem', sm: '1rem', md: '0rem', lg: '0rem' }}
            pr={{
              base: '0rem',
              sm: '0rem',
              md: '1rem',
              lg: '1rem',
              xl: '6rem',
            }}
          >
            <Price grandTotal={onSearch ? calGrandTotal(data) : 0} />
          </Box>
        </Stack>
        <Box mt={{ base: '1rem', sm: '1rem', md: '2rem', lg: '2rem' }}>
          <CustomTable
            columnsData={columnsDataOrders}
            tableData={onSearch ? data : []}
          />
        </Box>
      </Flex>
    </>
  )
}

export default RefundBranchExpense

const columnsDataOrders = [
  {
    Header: 'วันที่',
    accessor: 'createdAt',
    extra: (data, row) => (
      <Center key={row} w='full'>
        <Text>{row.createdAt}</Text>
      </Center>
    ),
  },
  {
    Header: 'เลขที่รายการขาย',
    accessor: 'orderId',
    extra: (data, row) => <Center key={row}>{row.orderId}</Center>,
  },
  {
    Header: 'รายการคืนเงิน',
    accessor: 'items',
    extra: (data, row) => (
      <Center key={row}>
        <VStack>
          {row.items?.map((item, index) => (
            <Text key={index}>
              {item.name} x {item.amount / 100}
            </Text>
          ))}
        </VStack>
      </Center>
    ),
  },
  {
    Header: 'ส่วนลด',
    accessor: 'discount',
    extra: (data, row) => (
      <Center pl='1rem' justifyContent='flex-end' textAlign='end' w={'100px'}>
        {accounting.formatNumber(row.discount, 2)}
      </Center>
    ),
  },
  {
    Header: 'คืนเงิน',
    accessor: 'total',
    extra: (data, row) => (
      <Center pl='1rem' justifyContent='flex-end' textAlign='end' w={'100px'}>
        {accounting.formatNumber(row.grandTotal, 2)}
      </Center>
    ),
  },
]
