import { decodeUnit } from 'Utils/currency'

const _ = require('underscore')

export function calOrderCostPaidRefund(orders) {
  let refundItemsCost = []
  _.map(orders, (order) => {
    _.map(order.items, (orderItem) => {
      const calCost = parseFloat(
        decodeUnit(orderItem.amount) * decodeUnit(orderItem.cost)
      )
      refundItemsCost.push(calCost)
    })
  })

  const calRefundCost = _.reduce(refundItemsCost, (memo, num) => memo + num, 0)
  return parseFloat(calRefundCost)
}
