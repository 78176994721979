import { Box, Center, SimpleGrid, Flex, Image } from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import { useBranch } from 'Hooks'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Info from './components/info'
import AddressDetail from './components/address'
import Contact from './components/contact'

function ViewBranch({ user }) {
  const { id } = useParams()
  const [data, setData] = useState({})
  const { getBranch } = useBranch()
  const navigate = useNavigate()
  function LinkToEdit() {
    navigate(`/branchs/edit/${id}`)
  }
  useEffect(() => {
    let isMounted = true
    const fetchValue = () => {
      if (isMounted) {
        if (id === 'HQ') {
          navigate('/branchs/list')
        }
        getBranch(id).then((res) => {
          setData(res)
        })
      }
    }
    fetchValue()
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Box>
      <Box mb='20px' display={{ base: 'block', lg: 'grid' }}>
        <SimpleGrid
          columns={{ sm: 1, xl: 2 }}
          spacing={{ base: '20px', xl: '20px' }}
          py={'1em'}
        >
          <Flex direction='column'>
            <Card mb='20px'>
              <Center>
                <Image
                  borderRadius='20px'
                  h={{ base: 'auto', xl: '250px', '2xl': 'auto' }}
                  w={{ base: 'auto', xl: '250px', '2xl': 'auto' }}
                  src={data?.img}
                  objectFit='cover'
                />
              </Center>
            </Card>
            <Info data={data} />
          </Flex>
          <Flex direction='column' h='full'>
            <AddressDetail data={data} />
          </Flex>
        </SimpleGrid>
        <Contact data={data} LinkToEdit={LinkToEdit} />
      </Box>
    </Box>
  )
}

export default ViewBranch
