import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  useColorModeValue,
  Text,
  Center,
} from '@chakra-ui/react'
import { useAuth } from 'Hooks'
import { useForm } from 'react-hook-form'
import { NavLink, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object().shape({
  email: yup.string().required('กรุณากรอกอีเมล'),
})

export default function ResetPassword() {
  const textColor = useColorModeValue('navy.700', 'white')
  const textColorSecondary = 'gray.400'
  const { resetPassword } = useAuth()
  const brandStars = useColorModeValue('brand.500', 'brand.400')
  const navigate = useNavigate()
  const { handleSubmit, register } = useForm({
    resolver: yupResolver(schema),
  })

  async function onSubmit(value) {
    const { email } = value
    await resetPassword(email).then(() => {
      navigate('/login')
    })
  }

  return (
    <Center w='100%' h='100vh' justifyContent='center' flexDirection='column'>
      <Center h='max-content' flexDirection='column'>
        <Center w='100%' flexDirection='column'>
          <Center w='100%' pb='10px'>
            <Text fontSize='40px' fon>
              ลืมรหัสผ่าน
            </Text>
          </Center>
          <Text
            w='100%'
            textAlign='center'
            mb='10px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'
          >
            กรุณากรอกอีเมล
          </Text>
        </Center>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: '19.5rem', sm: '19.5rem', md: '420px' }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: 'auto', lg: 'unset' }}
          me='auto'
          mb={{ base: '20px', md: 'auto' }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                อีเมล<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                borderRadius='30px'
                {...register('email')}
                bg='white'
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                type='email'
                placeholder='Enter your email address'
                mb='24px'
                fontWeight='500'
                size='lg'
              />
            </FormControl>
            <Button
              type='submit'
              fontSize='sm'
              fontWeight='500'
              colorScheme='btn'
              w='100%'
              h='50'
              mb='24px'
              borderRadius='30px'
            >
              ลืมรหัสผ่าน
            </Button>
          </form>
          <Flex justifyContent='end' mb='24px'>
            <NavLink to='/login'>
              <Text color='login.600' fontSize='sm' w='auto' fontWeight='500'>
                เข้าสู่ระบบ ?
              </Text>
            </NavLink>
          </Flex>
        </Flex>
      </Center>
    </Center>
  )
}
