import { db, functions, storage } from 'Config/firebase'
import { useAppContext } from 'Context'
import { calStockTotal } from 'Utils/calStock'
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore'
import { getDownloadURL, ref, uploadString } from 'firebase/storage'
import { useCallback, useState } from 'react'
import _ from 'underscore'
import { useGenerateId } from './IdGenerate'
import { httpsCallable } from 'firebase/functions'

const getStock = httpsCallable(functions, 'getInventoryStocks')

export function useProduct() {
  const [Product, setProduct] = useState([])
  const { IdNumberWithTextUpperCase } = useGenerateId()
  const { setError, setMessage, user } = useAppContext()
  const [isLoading, setIsLoading] = useState(true)
  const [branchProductStock, setBranchProductStock] = useState([])
  const [branchStockIsLoading, setBranchStockIsLoading] = useState(true)
  //------- ref -------//
  const ProductRef = collection(db, 'Products')
  //------- ref -------//

  const getProductInStock = useCallback(async () => {
    const productsQuerry = query(ProductRef)
    const productsData = await _.map(
      (
        await getDocs(productsQuerry)
      )?.docs,
      (doc) => {
        return {
          id: doc?.id,
          ...doc?.data(),
        }
      }
    )

    const getStockData = await getStock({ branch: user.branchSelect?.id })
    const productStockData = await Promise.all(
      _.map(productsData, async (product) => {
        const getStock = await _.find(getStockData.data, (i) => {
          return product.id === i.product
        })
        if (getStock) {
          return {
            ...product,
            total: getStock.total,
          }
        } else {
          return {
            ...product,
            total: 0,
          }
        }
      }),
      []
    )
    setBranchProductStock(_.sortBy(productStockData, 'createdAt'))
    setBranchStockIsLoading(false)

    //eslint-disable-next-line
  }, [])

  const getAllProducts = useCallback(async () => {
    setIsLoading(true)
    const getData = await getDocs(ProductRef)
    const data = await _.map(getData?.docs, (doc) => {
      return {
        id: doc?.id,
        ...doc?.data(),
      }
    })
    setProduct(_.sortBy(data, 'createdAt'))
    setIsLoading(false)
    //eslint-disable-next-line
  }, [])

  async function createUploadImageOptions(options, id) {
    let data = []
    return options?.map(async (item, index) => {
      const ImageRef = ref(
        storage,
        `Product/${id}/${new Date().getTime() + index}`
      )
      await uploadString(ImageRef, item.img, 'data_url').then(async () => {
        await getDownloadURL(ImageRef)
          .then((URLs) => {
            return data.push({ ...item, img: URLs })
          })
          .then(() => {
            return updateDoc(doc(db, 'Products', id), { options: data })
          })
      })
    })
  }

  async function createProduct(data, productImage, options) {
    const idGen = `${IdNumberWithTextUpperCase(16)}`
    const ProductRefence = doc(db, 'Products', idGen)
    try {
      return setDoc(ProductRefence, {
        ...data,
        createdAt: new Date(),
        createdBy: {
          id: user?.uid,
          name: user?.name,
        },
      })
        .then(() => {
          if (productImage) {
            try {
              const ImageRef = ref(
                storage,
                `Product/${idGen}/${new Date().getTime()}`
              )
              return uploadString(ImageRef, productImage, 'data_url').then(
                async () => {
                  const downloadURL = await getDownloadURL(ImageRef)
                  return updateDoc(ProductRefence, { img: downloadURL }).then(
                    async () => {
                      setMessage({
                        type: 'success',
                        message: 'เพิ่มสินค้าสำเร็จ.',
                      })
                    }
                  )
                }
              )
            } catch (error) {
              setError({
                code: 'Product',
                message: error.message,
              })
            }
          } else {
            setMessage({
              type: 'success',
              message: 'เพิ่มสินค้าสำเร็จ.',
            })
          }
        })
        .then(() => {
          if (options) {
            return createUploadImageOptions(options, idGen)
          }
        })
    } catch (error) {
      setError({
        code: 'Product',
        message: error.message,
      })
    }
  }

  async function updateUploadImageOptions(options, id) {
    return _.forEach(options, async (item, index) => {
      let data = []
      if (item?.img?.slice(0, 4) !== 'http') {
        const ImageRef = ref(
          storage,
          `Product/${id}/${new Date().getTime() + index}`
        )
        await uploadString(ImageRef, item.img, 'data_url').then(async () => {
          await getDownloadURL(ImageRef).then((URLs) => {
            return data.push({ ...item, img: URLs })
          })
        })
      } else {
        return data.push({ ...item })
      }
    })
  }

  function updateProduct(data, productImage, id, options) {
    const ProductDoc = doc(db, 'Products', id)
    try {
      return updateDoc(ProductDoc, {
        ...data,
        updatedAt: new Date(),
        updatedBy: {
          id: user?.uid,
          name: user?.name,
        },
      })
        .then(async (res) => {
          if (productImage) {
            try {
              const ImageRef = ref(storage, `Product/${id}/${new Date()}`)
              uploadString(ImageRef, productImage, 'data_url').then(
                async () => {
                  const downloadURL = await getDownloadURL(ImageRef)
                  updateDoc(ProductDoc, { img: downloadURL }).then(async () => {
                    setMessage({
                      type: 'success',
                      message: 'แก้ไขสินค้าสำเร็จ',
                    })
                  })
                }
              )
            } catch (error) {
              setError({
                code: 'Product',
                message: error.message,
              })
            }
          } else {
            setMessage({
              type: 'success',
              message: 'Product has been updated.',
            })
          }
        })
        .then(() => {
          if (options) {
            return updateUploadImageOptions(options, id).then((res) => {
              return updateDoc(doc(db, 'Products', id), { options: res })
            })
          }
        })
    } catch (error) {
      setError({
        code: 'Product',
        message: error.message,
      })
    }
  }

  function deleteProduct(id) {
    const ProductDoc = doc(db, 'Products', id)
    try {
      return deleteDoc(ProductDoc).then(() => {
        setMessage({
          type: 'success',
          message: 'ลบสินค้าสำเร็จ',
        })
      })
    } catch (error) {
      setError({
        code: 'Product',
        message: error.message,
      })
    }
  }

  async function getProductId(id) {
    const ProductDoc = doc(db, 'Products', id)
    return (await getDoc(ProductDoc)).data()
  }

  function getInventoryId(id) {
    return Product.filter((item) => item?.id === id)
  }

  const getProductAndStockByProductId = useCallback(
    async (id) => {
      const inventoryQueryWithItemIdAndUserBranch = query(
        collection(db, 'Inventory'),
        where('branch', '==', user?.branchSelect?.id),
        where('id', '==', id),
        orderBy('createdAt', 'desc')
      )
      const productDocRef = doc(db, 'Products', id)
      const inventoryData = await _.map(
        (
          await getDocs(inventoryQueryWithItemIdAndUserBranch)
        )?.docs,
        (doc) => {
          return {
            inventoryId: doc?.id,
            ...doc?.data(),
          }
        }
      )
      const productData = await getDoc(productDocRef).then((e) => {
        return {
          id: e.id,
          ...e.data(),
        }
      })
      const calStockTotalAmount = parseFloat(await calStockTotal(inventoryData))
      return {
        stock: calStockTotalAmount,
        product: productData,
        stockData: inventoryData,
      }
    },
    [user]
  )

  async function createProductOption(value) {
    const batch = writeBatch(db)
    await Promise.all(
      value.map(async (item) => {
        const idGen = `${IdNumberWithTextUpperCase(16)}`
        const ProductOptionRef = doc(db, 'Products', idGen)

        if (item.img) {
          let ImageURL = []
          const ImageRef = ref(storage, `Product/${idGen}/${new Date()}`)
          const uploadImg = uploadString(ImageRef, item.img, 'data_url')

          ImageURL.push(
            uploadImg
              .then((uploadResult) => {
                return getDownloadURL(uploadResult.ref)
              })
              .then((url) => url)
          )

          const downloadURL = await Promise.all(ImageURL)

          const data = {
            ...item,
            img: downloadURL ? downloadURL[0] : '',
            createdAt: new Date(),
            createdBy: {
              id: user?.uid,
              name: user?.name,
            },
          }
          return batch.set(ProductOptionRef, data)
        } else {
          const data = {
            ...item,
            createdAt: new Date(),
            createdBy: {
              id: user?.uid,
              name: user?.name,
            },
          }

          return batch.set(ProductOptionRef, data)
        }
      })
    )
    return await batch
      .commit()
      .then(() => {
        setMessage({
          type: 'success',
          message: 'เพิ่มสินค้าสำเร็จ',
        })
      })
      .catch((errors) => {
        setError({
          code: 'product-add-error',
          message: 'เพิ่มสินค้าไม่สำเร็จ',
        })
      })
  }

  async function updateProductOption(value) {
    const batch = writeBatch(db)
    await Promise.all(
      value.map(async (item) => {
        const idGen = `${IdNumberWithTextUpperCase(16)}`
        if (item.id) {
          const ProductOptionRef = doc(db, 'Products', item.id)

          if (item.img?.slice(0, 4) !== 'http') {
            let ImageURL = []
            const ImageRef = ref(storage, `Product/${idGen}/${new Date()}`)
            const uploadImg = uploadString(ImageRef, item.img, 'data_url')

            ImageURL.push(
              uploadImg
                .then((uploadResult) => {
                  return getDownloadURL(uploadResult.ref)
                })
                .then((url) => url)
            )

            const downloadURL = await Promise.all(ImageURL)
            delete item.id
            const data = {
              ...item,
              img: downloadURL ? downloadURL[0] : '',
              updatedAt: new Date(),
              updatedBy: {
                id: user?.uid,
                name: user?.name,
              },
            }
            return batch.set(ProductOptionRef, data)
          } else {
            delete item.id
            const data = {
              ...item,
              updatedAt: new Date(),
              updatedBy: {
                id: user?.uid,
                name: user?.name,
              },
            }

            return batch.set(ProductOptionRef, data)
          }
        } else {
          const ProductOptionRef = doc(db, 'Products', idGen)

          if (item.img) {
            let ImageURL = []
            const ImageRef = ref(storage, `Product/${idGen}/${new Date()}`)
            const uploadImg = uploadString(ImageRef, item.img, 'data_url')

            ImageURL.push(
              uploadImg
                .then((uploadResult) => {
                  return getDownloadURL(uploadResult.ref)
                })
                .then((url) => url)
            )

            const downloadURL = await Promise.all(ImageURL)

            const data = {
              ...item,
              img: downloadURL ? downloadURL[0] : '',
              createdAt: new Date(),
              createdBy: {
                id: user?.uid,
                name: user?.name,
              },
            }
            return batch.set(ProductOptionRef, data)
          } else {
            const data = {
              ...item,
              createdAt: new Date(),
              createdBy: {
                id: user?.uid,
                name: user?.name,
              },
            }

            return batch.set(ProductOptionRef, data)
          }
        }
      })
    )
    return await batch
      .commit()
      .then(() => {
        setMessage({
          type: 'success',
          message: 'อัปเดตสินค้าสำเร็จ',
        })
      })
      .catch((errors) => {
        setError({
          code: 'product-update-error',
          message: 'อัปเดตสินค้าไม่สำเร็จ',
        })
      })
  }

  async function deleteProductOption(value) {
    const batch = writeBatch(db)
    await Promise.all(
      value.map(async (item) => {
        const ProductOptionRef = doc(db, 'Products', item)
        return batch.delete(ProductOptionRef)
      })
    )

    return await batch
      .commit()
      .then(() => {
        setMessage({
          type: 'success',
          message: 'ลบสินค้าสำเร็จ',
        })
      })
      .catch((errors) => {
        setError({
          code: 'product-delete-error',
          message: 'ลบสินค้าไม่สำเร็จ',
        })
      })
  }
  return {
    Product,
    isLoading,
    branchProductStock,
    branchStockIsLoading,
    getProductAndStockByProductId,
    createProduct,
    updateProduct,
    deleteProduct,
    getProductId,
    getInventoryId,
    createProductOption,
    updateProductOption,
    deleteProductOption,
    getAllProducts,
    getProductInStock,
    setIsLoading,
  }
}
