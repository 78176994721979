import { ChevronLeftIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import ButtonResponsive from 'Components/Button/btn'
import Card from 'Components/Horizons/card/Card'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { SecondCategory } from './components/category'
import { SecondList } from './components/list'
import { SecondReceipt } from './components/receipt'

function RadioField({
  id,
  control,
  errors,
  options,
  name,
  required,
  label,
  mb,
  ...rest
}) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  return (
    <FormControl id={name} isInvalid={errors[name]} key={name}>
      <Flex direction='column' mb={mb ? mb : '30px'}>
        <FormLabel
          w='100%'
          display='flex'
          ms='10px'
          htmlFor={id}
          fontSize='sm'
          color={textColorPrimary}
          fontWeight='bold'
          _hover={{ cursor: 'pointer' }}
        >
          {label}
        </FormLabel>
        <Controller
          w='100%'
          name={name}
          control={control}
          rules={{
            required: required,
          }}
          render={({ field: { value, onChange } }) => (
            <RadioGroup
              value={value}
              onChange={onChange}
              {...rest}
              colorScheme='green'
              w='100%'
            >
              <Stack
                direction={{ base: 'column', md: 'column' }}
                justifyContent='center'
                spacing='1em'
                w='full'
              >
                {options.map((itm, index) => (
                  <Box
                    w='100%'
                    pt='0.5rem'
                    pb='1.2rem'
                    borderBottom='1px solid #E8E9E9'
                    key={index}
                  >
                    <Radio pl='1.5rem' value={itm.value} w='full'>
                      {itm.label ? (
                        <VStack
                          pl='1rem'
                          alignItems='start'
                          spacing={'0xp'}
                          w='full'
                        >
                          <Text w='full'>{itm.name}</Text>
                          <Text color={'gray'} fontSize='sm' w='full'>
                            {itm.label}
                          </Text>
                        </VStack>
                      ) : (
                        <Text>{itm.name}</Text>
                      )}
                    </Radio>
                  </Box>
                ))}
              </Stack>
            </RadioGroup>
          )}
        />
      </Flex>
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
    </FormControl>
  )
}

export default function SecondFreeForm({
  id,
  control,
  errors,
  discount,
  setDiscount,
  watch,
  isSubmit,
  setFreeForm,
  reset,
}) {
  const [list, setList] = useState(false)
  const [category, setCategory] = useState(false)
  const [receipt, setReceipt] = useState(false)
  const type = watch('typeDiscount')
  return (
    <>
      <Box display={list || category || receipt ? 'none' : 'block'}>
        <Center>
          <Card
            padding={{
              base: '10px 0px 30px 0px',
              sm: '10px 0px 30px 0px',
              md: '10px 20px 30px 20px',
            }}
            w={{ base: '100%', sm: '100%', md: '30rem' }}
            boxShadow={{
              base: 'none',
              sm: 'none',
              md: '14px 17px 40px 4px rgb(112 144 176 / 8%)',
            }}
          >
            <HStack>
              <ChevronLeftIcon
                cursor='pointer'
                color='form.500'
                fontSize={'2xl'}
                onClick={() => {
                  setFreeForm(false)
                  if (!id) {
                    setDiscount(null)
                    reset()
                  }
                }}
              />
              <Text
                fontSize={{ base: 'md', sm: 'md', md: 'lg' }}
                fontWeight='bold'
              >
                สร้างโปรโมชั่น
              </Text>
            </HStack>
            <Text
              mt='2rem'
              color={'table.100'}
              borderBottom='1px solid #E8E9E9'
              pb='0.5rem'
            >
              คุณต้องการใช้โปรโมชั่นส่วนลดระดับไหน
            </Text>
            <Box>
              <Stack direction={{ base: 'column', md: 'row' }} w='100%'>
                <RadioField
                  name={'typeDiscount'}
                  control={control}
                  errors={errors}
                  options={[
                    {
                      name: 'รายการสินค้า',
                      label: 'แถมเมื่อซื้อรายการสินค้านี้',
                      value: 'list',
                    },
                    {
                      name: 'หมวดหมู่สินค้า',
                      label: 'แถมเมื่อซื้อสินค้าในหมวดหมู่นี้',
                      value: 'category',
                    },
                    {
                      name: 'รายการขายทั้งบิลตามยอดขั้นต่ำที่กำหนด',
                      label: 'แถมเมื่อซื้อครบตามยอดขั้นต่ำ',
                      value: 'receipt',
                    },
                  ]}
                />
              </Stack>
            </Box>
            <Flex w='100%' justifyContent='flex-end'>
              <ButtonResponsive
                disabled={type ? false : true}
                label={'ถัดไป'}
                onClick={() => {
                  if (type === 'list') {
                    setList(true)
                    setCategory(false)
                    setReceipt(false)
                  } else if (type === 'category') {
                    setCategory(true)
                    setList(false)
                    setReceipt(false)
                  } else if (type === 'receipt') {
                    setReceipt(true)
                    setCategory(false)
                    setList(false)
                  }
                }}
              />
            </Flex>
          </Card>
        </Center>
      </Box>
      {list && (
        <SecondList
          control={control}
          errors={errors}
          setList={setList}
          setDiscount={setDiscount}
          discount={discount}
          watch={watch}
          isSubmit={isSubmit}
        />
      )}
      {category && (
        <SecondCategory
          setCategory={setCategory}
          setDiscount={setDiscount}
          discount={discount}
          isSubmit={isSubmit}
          control={control}
          errors={errors}
          watch={watch}
        />
      )}
      {receipt && (
        <SecondReceipt
          control={control}
          errors={errors}
          discount={discount}
          setDiscount={setDiscount}
          setReceipt={setReceipt}
          watch={watch}
          isSubmit={isSubmit}
        />
      )}
    </>
  )
}
