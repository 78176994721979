import { Stack } from '@chakra-ui/react'
import { FormReExpenses } from 'Components/form/formReExpenses'
import { useExpenses, useGenerateId } from 'Hooks'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

function ReExpenses({ user }) {
  const { IdOnlyNumber } = useGenerateId()
  const ids = `EXP${IdOnlyNumber(10)}`
  const { id } = useParams()
  const { getExpensesId } = useExpenses()
  const [data, setData] = useState()

  const fetchData = useCallback(async () => {
    await getExpensesId(id).then((res) => {
      setData({ ...res, Data: moment(new Date()).format('yyyy-MM-dd') })
    })
  }, [getExpensesId, id])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack direction='column'>
      <FormReExpenses data={data} ids={ids} />
    </Stack>
  )
}

export default ReExpenses
