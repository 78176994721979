import { mode } from '@chakra-ui/theme-tools'
export const globalStyles = {
  colors: {
    form: {
      100: '#FF8093',
      500: '#821E2D',
      600: '#60101C',
    },
    login: {
      100: '#4299E1',
      500: '#821E2D',
      600: '#821E2D',
    },
    btn: {
      100: '#FF8093',
      500: '#821E2D',
      600: '#60101C',
    },
    table: {
      100: '#821E2D',
      500: '#821E2D',
      600: '#821E2D',
    },
    navbar: {
      100: '#821E2D',
      500: '#821E2D',
      600: '#4299E1',
      800: '#4299E1',
    },
    pos: {
      100: '#821E2D',
      500: '#821E2D',
      600: '#4299E1',
    },
    product: {
      100: '#821E2D',
      500: '#821E2D',
      600: '#821E2D',
      700: 'linear-gradient(135deg, #821E2D 0%, #821E2D 100%)',
    },
    discount: {
      100: '#821E2D',
      500: '#821E2D',
      600: '#821E2D',
      700: 'linear-gradient(135deg, #821E2D 0%, #821E2D 100%)',
    },
    inventory: {
      100: '#821E2D',
      500: '#821E2D',
      600: '#821E2D',
      700: 'linear-gradient(135deg, #821E2D 0%, #821E2D 100%)',
    },
    expenses: {
      100: '#821E2D',
      500: '#821E2D',
      600: '#821E2D',
      700: 'linear-gradient(135deg, #821E2D 0%, #821E2D 100%)',
      800: '#821E2D',
      900: 'linear-gradient(135deg, #821E2D 0%, #821E2D 100%)',
    },
    employees: {
      100: '#821E2D',
      500: '#821E2D',
      600: '#821E2D',
      700: 'linear-gradient(135deg, #821E2D 0%, #821E2D 100%)',
      800: '#375C38',
      900: 'linear-gradient(135deg, #821E2D 0%, #821E2D 100%)',
    },
    branchs: {
      100: '#821E2D',
      500: '#821E2D',
      600: '#821E2D',
      700: 'linear-gradient(135deg, #821E2D 0%, #821E2D 100%)',
      800: '#821E2D',
      900: 'linear-gradient(135deg, #821E2D 0%, #821E2D 100%)',
    },
    payment: {
      100: '#4299E1',
      500: '#4299E1',
      600: '#4299E1',
      700: 'linear-gradient(135deg, #821E2D 0%, #821E2D 100%)',
      800: '#8476FF',
    },
    report: {
      100: '#4299E1',
      500: 'black',
      600: '#4299E1',
      700: 'linear-gradient(135deg, #821E2D 0%, #821E2D 100%)',
    },
    branchDropdown: {
      100: '#821E2D',
    },

    brand: {
      100: '#E9E3FF',
      200: '#422AFB',
      300: '#422AFB',
      400: '#7551FF',
      500: '#422AFB',
      600: '#3311DB',
      700: '#02044A',
      800: '#190793',
      900: '#11047A',
    },
    brandScheme: {
      100: '#E9E3FF',
      200: '#7551FF',
      300: '#7551FF',
      400: '#7551FF',
      500: '#422AFB',
      600: '#3311DB',
      700: '#02044A',
      800: '#190793',
      900: '#02044A',
    },
    brandTabs: {
      100: '#E9E3FF',
      200: '#422AFB',
      300: '#422AFB',
      400: '#422AFB',
      500: '#422AFB',
      600: '#3311DB',
      700: '#02044A',
      800: '#190793',
      900: '#02044A',
    },
    secondaryGray: {
      100: '#E0E5F2',
      200: '#E1E9F8',
      300: '#F4F7FE',
      400: '#E9EDF7',
      500: '#8F9BBA',
      600: '#A3AED0',
      700: '#707EAE',
      800: '#707EAE',
      900: '#1B2559',
    },
    red: {
      100: '#FEEFEE',
      500: '#EE5D50',
      600: '#E31A1A',
    },
    blue: {
      50: '#EFF4FB',
      500: '#3965FF',
    },
    orange: {
      100: '#FFF6DA',
      500: '#FFB547',
    },
    green: {
      100: '#E6FAF5',
      500: '#821E2D',
    },
    navy: {
      50: '#d0dcfb',
      100: '#aac0fe',
      200: '#a3b9f8',
      300: '#728fea',
      400: '#3652ba',
      500: '#1b3bbb',
      600: '#24388a',
      700: '#1B254B',
      800: '#111c44',
      900: '#0b1437',
    },
    gray: {
      100: '#FAFCFE',
    },
    loading: {
      100: '#821E2D',
    },
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: 'hidden',
        bg: mode('rgba(253, 254, 255)', 'navy.900')(props),
        fontFamily: 'Prompt',
        letterSpacing: '-0.5px',
      },
      input: {
        color: 'gray.700',
      },
      html: {
        fontFamily: 'Prompt',
      },
    }),
  },
}

/*
200: '#BEBEBE',
      300: '#B8B8B8',
      400: '#B0B0B0',
      500: '#A9A9A9',
      600: '#A8A8A8',
      700: '#A0A0A0',
      800: '#989898',
      900: '#909090',
      1000: '#888888',
      1100: '#808080',
      1200: '#787878',

*/
