import { SearchIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
  useColorModeValue,
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { MdChevronRight, MdChevronLeft } from 'react-icons/md'
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

function SearchBar(props) {
  // Pass the computed styles into the `__css` prop
  const { variant, background, children, placeholder, borderRadius, ...rest } =
    props
  // Chakra Color Mode
  const searchIconColor = useColorModeValue('gray.700', 'white')
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900')
  const inputText = useColorModeValue('gray.700', 'gray.100')
  return (
    <InputGroup w={{ base: '100%', md: '200px' }} {...rest}>
      <InputLeftElement
        children={
          <IconButton
            bg='inherit'
            borderRadius='inherit'
            _hover='none'
            _active={{
              bg: 'inherit',
              transform: 'none',
              borderColor: 'transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}
            icon={<SearchIcon color={searchIconColor} w='15px' h='15px' />}
          ></IconButton>
        }
      />
      <Input
        variant='search'
        fontSize='sm'
        bg={background ? background : inputBg}
        color={inputText}
        fontWeight='500'
        _placeholder={{ color: 'gray.400', fontSize: '14px' }}
        borderRadius={{ base: '10px', sm: '10px', md: '10px', lg: '30px' }}
        placeholder={placeholder ? placeholder : 'Search...'}
      />
    </InputGroup>
  )
}

function CategoryTable({ columnsData, tableData, pages }) {
  const columns = useMemo(() => columnsData, [columnsData])
  const data = useMemo(() => tableData, [tableData])

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
    state,
  } = tableInstance

  const createPages = (count) => {
    let arrPageCount = []

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i)
    }

    return arrPageCount
  }

  const { pageIndex, pageSize } = state
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')
  const brandColor = useColorModeValue('brand', 'brand')
  return (
    <Flex
      direction='column'
      w='100%'
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex
        align={{ sm: 'flex-start', lg: 'flex-start' }}
        justify={{ sm: 'space-between', lg: 'space-between' }}
        w='100%'
        mb='36px'
      >
        <Box zIndex='base'>
          <SearchBar
            onChange={(e) => setGlobalFilter(e.target.value)}
            h='44px'
            w={{ base: 'full', lg: '390px' }}
            borderRadius='16px'
            mr={'10px'}
          />
        </Box>
      </Flex>
      <Table {...getTableProps()}>
        <Thead bg='table.100'>
          {headerGroups.map((headerGroup, index) => (
            <Tr
              fontFamily={'Prompt'}
              {...headerGroup.getHeaderGroupProps()}
              key={index}
            >
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  borderColor={borderColor}
                  fontFamily={'Prompt'}
                  textAlign='center'
                  align='center'
                  fontSize={{ sm: '14px', lg: '12px', xl: '14px' }}
                  color='white'
                >
                  {column.render('Header')}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} bg='white'>
          {page.map((row, index) => {
            prepareRow(row)
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, i) => {
                  let data = ''
                  if (cell.column?.extra) {
                    data = cell.column?.extra(
                      cell.value,
                      tableData[row.getRowProps().key.split('_')[1]]
                    )
                  } else {
                    data = (
                      <Center maxW={'auto'}>
                        <Text
                          color={textColor}
                          fontSize='md'
                          fontWeight='500'
                          noOfLines={1}
                        >
                          {cell.value}
                        </Text>
                      </Center>
                    )
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={i}
                      fontSize={{ sm: '12px' }}
                      minW={{ sm: '180px', md: '200px', lg: 'auto' }}
                      borderColor={borderColor}
                    >
                      <Center>{data}</Center>
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Flex
        mt='24px'
        direction={{ sm: 'column', md: 'row' }}
        justify='space-between'
        align='center'
        w='100%'
        px={{ md: '22px' }}
      >
        <Text
          fontSize='sm'
          color='gray.500'
          fontWeight='normal'
          mb={{ sm: '24px', md: '0px' }}
        >
          Showing {pageSize * pageIndex + 1} to{' '}
          {pageSize * (pageIndex + 1) <= tableData.length
            ? pageSize * (pageIndex + 1)
            : tableData.length}{' '}
          of {tableData.length} entries
        </Text>
        <Stack direction='row' alignSelf='flex-end' spacing='4px' ms='auto'>
          <Button
            variant='no-effects'
            onClick={() => previousPage()}
            transition='all .5s ease'
            w='40px'
            h='40px'
            borderRadius='50%'
            bg='transparent'
            border='1px solid'
            borderColor={useColorModeValue('gray.200', 'white')}
            display={
              pageSize === 5 ? 'none' : canPreviousPage ? 'flex' : 'none'
            }
            _hover={{
              bg: 'whiteAlpha.100',
              opacity: '0.7',
            }}
          >
            <Icon as={MdChevronLeft} w='16px' h='16px' color={'textColor'} />
          </Button>
          {pageSize === 5 ? (
            <NumberInput
              max={pageCount - 1}
              min={1}
              w='75px'
              mx='6px'
              defaultValue='1'
              onChange={(e) => gotoPage(e)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper onClick={() => nextPage()} />
                <NumberDecrementStepper onClick={() => previousPage()} />
              </NumberInputStepper>
            </NumberInput>
          ) : (
            createPages(pageCount).map((pageNumber, index) => {
              return (
                <Button
                  variant='no-effects'
                  transition='all .5s ease'
                  onClick={() => gotoPage(pageNumber - 1)}
                  w='40px'
                  h='40px'
                  borderRadius='50%'
                  bg={pageNumber === pageIndex + 1 ? brandColor : 'transparent'}
                  border={
                    pageNumber === pageIndex + 1
                      ? 'none'
                      : '1px solid lightgray'
                  }
                  _hover={
                    pageNumber === pageIndex + 1
                      ? {
                          opacity: '0.7',
                        }
                      : {
                          bg: 'whiteAlpha.100',
                        }
                  }
                  key={index}
                >
                  <Text
                    fontSize='sm'
                    color={pageNumber === pageIndex + 1 ? '#fff' : textColor}
                  >
                    {pageNumber}
                  </Text>
                </Button>
              )
            })
          )}
          <Button
            variant='no-effects'
            onClick={() => nextPage()}
            transition='all .5s ease'
            w='40px'
            h='40px'
            borderRadius='50%'
            bg='transparent'
            border='1px solid'
            borderColor={useColorModeValue('gray.200', 'white')}
            display={pageSize === 5 ? 'none' : canNextPage ? 'flex' : 'none'}
            _hover={{
              bg: 'whiteAlpha.100',
              opacity: '0.7',
            }}
          >
            <Icon as={MdChevronRight} w='16px' h='16px' color={'textColor'} />
          </Button>
        </Stack>
      </Flex>
    </Flex>
  )
}

export default CategoryTable
