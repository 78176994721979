import { Center, Fade, Spinner, Stack } from '@chakra-ui/react'
import { FormOptionProduct } from 'Components/form/Product/formOptionProduct'
import { useProduct } from 'Hooks'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const _ = require('underscore')
export default function EditOptionProduct({ user }) {
  const { skuId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [isFade, setIsFade] = useState(true)
  const [data, setData] = useState([])
  const { Product, getAllProducts } = useProduct()

  useEffect(() => {
    getAllProducts()
  }, [getAllProducts])
  //   const skuId = `${String(Product.length + 1).padStart(4, '0')}`
  useEffect(() => {
    setData(_.filter(Product, (item) => item.ref === skuId))
    setTimeout(() => {
      setIsLoading(false)
      setIsFade(false)
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skuId, Product])
  return (
    <Stack direction='column'>
      {!isLoading ? (
        <Fade in={!isFade}>
          <FormOptionProduct skuId={skuId} data={data} refId={skuId} />
        </Fade>
      ) : (
        <Center>
          <Spinner size='md' />
        </Center>
      )}
    </Stack>
  )
}
