import { ChevronLeftIcon } from '@chakra-ui/icons'
import Card from 'Components/Horizons/card/Card'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Dropzone from '../components/dropzone'
import _ from 'underscore'
import {
  Box,
  Button,
  Center,
  Fade,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Image,
  Radio,
  RadioGroup,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useCategory, useProduct } from 'Hooks'
import { MdOutlineCloudUpload } from 'react-icons/md'
import InputField from 'Components/Horizons/fields/InputField'
import SelectField from 'Components/Horizons/fields/SelectField'
import ProductInfo from '../components/product'
import { decodeUnit, encodeUnit } from 'Utils/currency'

export function FormOneProduct({ id, data, skuId }) {
  const { category, getCategoryAll } = useCategory()
  const { createProduct, updateProduct, Product, getAllProducts } = useProduct()
  const [file, setFile] = useState()
  const [info, setInfo] = useState({
    price: null,
    typeCommission: 'Bath',
  })
  const [isSubmit, setIsSubmit] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  //   const { IdNumberWithTextUpperCase } = useGenerateId()
  const Navigate = useNavigate()

  // -- style -- //
  const brand = useColorModeValue('brand.500', 'brand.400')
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const bg = useColorModeValue('gray.100', 'navy.700')
  const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100')
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  // -- style -- //
  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      unit: 'piece',
      commissionCalType: 'receipt',
      typeCommission: 'Bath',
      sku: '',
    },
  })

  useEffect(() => {
    getCategoryAll()
    getAllProducts()
  }, [getCategoryAll, getAllProducts])

  useEffect(() => {
    if (!skuId) {
      const resRef = _.groupBy(
        Product?.filter((item) => item.ref !== undefined),
        'ref'
      )
      const resNoRef = Product?.filter((item) => item.ref === undefined).map(
        (item) => item?.sku
      )
      const allProduct = [...resNoRef, ...Object.keys(resRef)]
      const refNumber = allProduct
        .map((item) => parseFloat(item))
        .sort((a, b) => a - b)
      const skuIdString = `${String(
        refNumber.length > 0 ? refNumber.slice(-1)[0] + 1 : 1
      ).padStart(4, '0')}`
      setValue('sku', skuIdString)
    } else {
      setValue('sku', skuId)
    }

    if (data) {
      const findCategory = _.find(category, (i) => {
        return data?.category === i?.id || data?.category === i?.name
      })

      setValue('sku', data?.sku)
      setValue('name', data?.name)
      setValue('category', findCategory ? findCategory?.id : '')
      setValue('options', data?.options)
      setValue('price', decodeUnit(data?.price))
      setValue('cost', decodeUnit(data?.cost))
      setValue('minimum', decodeUnit(data?.minimum))
      setValue('commission', decodeUnit(data?.commission))
      setValue('unit', data?.unit)
      setValue('vat', data?.vat)
      setValue('typeCommission', data?.typeCommission)
      setValue('commissionCalType', data?.commissionCalType)
      setInfo({
        price: decodeUnit(data?.price),
        typeCommission: data.typeCommission,
      })
    }
    setIsLoading(false)
    //eslint-disable-next-line
  }, [data, setValue, Product, category])

  function onSubmit(value) {
    // setIsSubmit(true)
    const productImage = file || null
    const {
      sku,
      name,
      category,
      vat,
      unit,
      cost,
      price,
      commission,
      minimum,
      typeCommission,
      commissionCalType,
    } = value
    setIsSubmit(true)
    if (id) {
      const allValue = {
        name: name,
        category: category,
        vat: vat,
        sku: sku,
        cost: encodeUnit(cost).toString(),
        price: encodeUnit(price).toString(),
        commission: encodeUnit(commission).toString(),
        minimum: encodeUnit(minimum).toString(),
        typeCommission: typeCommission,
        unit: unit,
        commissionCalType: commissionCalType,
      }

      updateProduct(
        allValue,
        data?.img === productImage ? '' : productImage,
        id
      ).then((res) => {
        setIsSubmit(false)
        Navigate('/product/list')
      })
    } else {
      const allValue = {
        name: name,
        category: category,
        vat: vat,
        sku: sku,
        cost: encodeUnit(cost).toString(),
        price: encodeUnit(price).toString(),
        commission: encodeUnit(commission).toString(),
        minimum: encodeUnit(minimum).toString(),
        typeCommission: typeCommission,
        unit: unit,
        commissionCalType: commissionCalType,
      }

      createProduct(allValue, productImage).then((res) => {
        setIsSubmit(false)
        Navigate('/product/list')
      })
    }
  }
  return (
    <Flex direction='column' minH='100vh' align='center' position='relative'>
      <Box
        h={{ base: '0px', sm: '0px', md: '25vh' }}
        bg='product.700'
        position='absolute'
        w={{ sm: '100vw', md: '100%', lg: '100%' }}
        borderRadius={{ sm: '0px', md: '30px', lg: '30px' }}
      ></Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          mt={{ base: '-30px', sm: '-30px', md: '24px', lg: '24px' }}
          maxW={{ md: '90%', lg: '100%' }}
          mx={{ base: 'auto', md: '0px', lg: 'auto' }}
          as={Fade}
          in={!isLoading}
          initialScale={1}
        >
          <Box
            w={{ sm: '100vw', md: '670px', lg: '850px' }}
            p='0px'
            mx={{ base: 'auto', md: '0px', lg: 'auto' }}
          >
            <Card
              mt={{ base: '-40px', sm: '-40px', md: '0px', lg: '0px' }}
              p={{ base: '15px', sm: '15px', md: '30px' }}
              px={{ base: '30px', sm: '30px', md: '30px' }}
              pb={{ base: '40px', sm: '40px', md: '30px' }}
            >
              <Box
                pb='0.3rem'
                zIndex='base'
                color='product.500'
                onClick={() => Navigate(-1)}
                w='max-content'
                display={{ sm: 'flex', md: 'none' }}
              >
                <Text
                  display={{ sm: 'flex', md: 'none' }}
                  fontSize='24px'
                  cursor='pointer'
                >
                  <ChevronLeftIcon />
                </Text>
              </Box>
              <Text
                textAlign={{
                  base: 'center',
                  sm: 'center',
                  md: 'left',
                  lg: 'left',
                }}
                color='#1B2559'
                fontSize='2xl'
                fontWeight='700'
                mb='20px'
              >
                ข้อมูลสินค้า
              </Text>
              <Flex direction='column' w='100%'>
                {file ? (
                  <Center>
                    <Dropzone
                      w='20rem'
                      setFile={setFile}
                      content={
                        <Box>
                          <Center
                            // as={Fade}
                            // in={!isLoadingImage}
                            align='center'
                            justify='center'
                            bg={bg}
                            borderColor={borderColor}
                            w='100%'
                            maxW='100%'
                            h='max-content'
                            maxH='200px'
                            cursor='pointer'
                            flexDirection='column'
                          >
                            <Text
                              fontSize='sm'
                              fontWeight='500'
                              color='secondaryGray.500'
                            >
                              {' '}
                              <Text
                                fontSize='sm'
                                pt='1.5rem'
                                fontWeight='500'
                                color='secondaryGray.500'
                              >
                                {' '}
                              </Text>
                            </Text>
                            <Image maxH='200px' src={file} objectFit='cover' />
                          </Center>
                        </Box>
                      }
                    />
                  </Center>
                ) : (
                  <Center>
                    <Dropzone
                      w='20rem'
                      setFile={setFile}
                      content={
                        data?.img ? (
                          <Box>
                            <Center
                              align='center'
                              justify='center'
                              bg={bg}
                              borderColor={borderColor}
                              w='100%'
                              maxW='100%'
                              maxH='150px'
                              cursor='pointer'
                              flexDirection='column'
                            >
                              <Text
                                fontSize='sm'
                                fontWeight='500'
                                color='secondaryGray.500'
                              >
                                {' '}
                                <Text
                                  fontSize='sm'
                                  pt='1.5rem'
                                  fontWeight='500'
                                  color='secondaryGray.500'
                                >
                                  {' '}
                                </Text>
                              </Text>
                              <Image
                                maxH='150px'
                                src={data?.img}
                                objectFit='cover'
                              />
                            </Center>
                          </Box>
                        ) : (
                          <Box>
                            <Text
                              fontSize='sm'
                              fontWeight='500'
                              color='secondaryGray.500'
                            >
                              {' '}
                              <Text
                                fontSize='sm'
                                mt='1.5rem'
                                fontWeight='500'
                                color='secondaryGray.500'
                              >
                                {' '}
                              </Text>
                            </Text>
                            <Icon
                              as={MdOutlineCloudUpload}
                              w='80px'
                              h='80px'
                              color={textColor}
                            />
                            <Text
                              mx='auto'
                              mb='4px'
                              fontSize='lg'
                              fontWeight='700'
                              whiteSpace='pre-wrap'
                              color={textColor}
                            >
                              อัพโหลดรูปภาพสินค้า
                            </Text>
                            <Text
                              mx='auto'
                              fontSize={{ base: '16px', md: '16px' }}
                              fontWeight='700'
                              whiteSpace='pre-wrap'
                              color={brand}
                            >
                              คลิก
                            </Text>
                          </Box>
                        )
                      }
                    />
                  </Center>
                )}
                <Box>
                  <SimpleGrid
                    pt={'1em'}
                    columns={{ base: '1', md: '2' }}
                    gap='20px'
                    color='#1B2559'
                    alignItems='end'
                  >
                    <InputField
                      control={control}
                      errors={errors}
                      readOnly
                      mb='0px'
                      id='sku'
                      label='รหัสสินค้า'
                      name='sku'
                      type='text'
                      defaultValue={''}
                      required={{
                        value: true,
                        message: 'กรุณากรอกรหัสสินค้า',
                      }}
                    />

                    <InputField
                      control={control}
                      errors={errors}
                      mb='0px'
                      id='name'
                      label='ชื่อสินค้า'
                      name='name'
                      type='text'
                      defaultValue={''}
                      required={{
                        value: true,
                        message: 'กรุณากรอกชื่อสินค้า',
                      }}
                    />

                    <SelectField
                      control={control}
                      errors={errors}
                      label={'หมวดหมู่สินค้า'}
                      name={'category'}
                      id={'category'}
                      mb={'0px'}
                      borderRadius={{
                        base: '10px',
                        sm: '10px',
                        md: '10px',
                        lg: '16px',
                      }}
                      placeholder={'เลือกหมวดหมู่'}
                      path={'/product/category/add'}
                      options={
                        category?.length > 0
                          ? category?.map((item) => ({
                              id: item?.id,
                              name: item?.name,
                            }))
                          : []
                      }
                      required={{
                        value: true,
                        message: 'กรุณาเลือกหมวดหมู่สินค้า',
                      }}
                    />

                    <SelectField
                      control={control}
                      errors={errors}
                      label={'ภาษีมูลค่าเพิ่ม'}
                      name={'vat'}
                      id={'vat'}
                      borderRadius={{
                        base: '10px',
                        sm: '10px',
                        md: '10px',
                        lg: '16px',
                      }}
                      mb={'0px'}
                      defaultValue={'VAT 0%'}
                      placeholder={'เลือกภาษี'}
                      options={[
                        'ยังไม่รวมภาษี',
                        'ราคารวม VAT แล้ว',
                        'VAT 0%',
                        'สินค้าได้รับการยกเว้นภาษี',
                        'ไม่มีภาษีมูลค่าเพิ่ม',
                      ]}
                      required={{
                        value: true,
                        message: 'กรุณาเลือกภาษี',
                      }}
                    />
                    <FormControl isInvalid={errors['unit']}>
                      <FormLabel
                        display='flex'
                        ms='10px'
                        fontSize='sm'
                        color={textColorPrimary}
                        fontWeight='bold'
                        _hover={{ cursor: 'pointer' }}
                      >
                        หน่วยสินค้า
                      </FormLabel>
                      <Controller
                        control={control}
                        errors={errors}
                        name={'unit'}
                        rules={{
                          required: {
                            value: true,
                            message: 'กรุณาเลือกหน่วยสินค้า',
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            onChange={onChange}
                            value={value}
                            colorScheme='green'
                          >
                            <HStack>
                              <Radio value={'piece'}>ชิ้น</Radio>
                              <Radio value={'weight'}>น้ำหนัก</Radio>
                            </HStack>
                          </RadioGroup>
                        )}
                      />
                      <FormErrorMessage>
                        {errors['statusPo'] && errors['statusPo']?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </SimpleGrid>
                </Box>

                <ProductInfo
                  info={info}
                  setInfo={setInfo}
                  control={control}
                  errors={errors}
                  option={[]}
                  register={register}
                  setValue={setValue}
                  data={data}
                />
                <Flex justify='space-between' mt='30px'>
                  <Button
                    variant='light'
                    fontSize='sm'
                    borderRadius='16px'
                    w={{ base: '128px', md: '148px' }}
                    h='46px'
                    onClick={() => Navigate(-1)}
                  >
                    ย้อนกลับ
                  </Button>
                  <Button
                    type='submit'
                    colorScheme='btn'
                    fontSize='sm'
                    borderRadius='16px'
                    w={{ base: '128px', md: '148px' }}
                    h='46px'
                    ms='auto'
                    isLoading={isSubmit}
                    variant='solid'
                    loadingText={'Loading...'}
                  >
                    บันทึก
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </Box>
        </Box>
      </form>
    </Flex>
  )
}
