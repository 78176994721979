import { db } from 'Config/firebase'
import { useAppContext } from 'Context'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { useCallback, useEffect, useState } from 'react'
import _ from 'underscore'

export function usePromotion() {
  const { setMessage, setError, user } = useAppContext()
  const [activePromotion, setActivePromotion] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getAllPromotion = useCallback(async () => {
    const PromotionRef = query(
      collection(db, 'Promotion'),
      orderBy('createdAt', 'desc')
    )
    const getData = await getDocs(PromotionRef)
    const data = await _.map(getData?.docs, (doc) => {
      return {
        id: doc?.id,
        ...doc?.data(),
      }
    })
    setActivePromotion(data)
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getActivePromotion = useCallback(async () => {
    const promotionRef = query(
      collection(db, 'Promotion'),
      where('status', '==', true),
      orderBy('createdAt', 'desc')
    )

    return await new Promise(async (resolve) => {
      const getData = await getDocs(promotionRef)
      const data = await _.map(getData?.docs, (doc) => {
        return { id: doc.id, ...doc.data() }
      })
      return resolve(data)
    })
  }, [])

  function createPromotion(value) {
    try {
      return addDoc(collection(db, 'Promotion'), {
        ...value,
        createdAt: new Date(),
        createdBy: {
          id: user?.uid,
          name: user?.name,
        },
      }).then(() => {
        setMessage({
          type: 'success',
          message: 'เพิ่มส่วนลดสินค้าสำเร็จ',
        })
      })
    } catch (error) {
      setError({
        code: 'Promotion-create',
        message: error.message,
      })
    }
  }

  function updatePromotion(id, value) {
    try {
      return updateDoc(doc(db, 'Promotion', id), {
        ...value,
        updatedAt: new Date(),
        updatedBy: {
          id: user?.uid,
          name: user?.name,
        },
      }).then(() => {
        setMessage({
          type: 'success',
          message: 'อัปเดตส่วนลดสินค้าสำเร็จ',
        })
      })
    } catch (error) {
      setError({
        code: 'Promotion-update',
        message: error.message,
      })
    }
  }

  function deletePromotion(id) {
    try {
      return deleteDoc(doc(db, 'Promotion', id)).then(() => {
        setMessage({
          type: 'success',
          message: 'ลบส่วนลดสินค้าสำเร็จ',
        })
      })
    } catch (error) {
      setError({
        code: 'Promotion-delete',
        message: error.message,
      })
    }
  }

  async function getPromotionId(id) {
    const PromotionDoc = doc(db, 'Promotion', id)
    return (await getDoc(PromotionDoc)).data()
  }

  useEffect(() => {
    getAllPromotion()
  }, [getAllPromotion])

  return {
    createPromotion,
    updatePromotion,
    deletePromotion,
    getPromotionId,
    getActivePromotion,
    activePromotion,
    isLoading,
  }
}
