import { Stack, useBreakpoint, useDisclosure } from '@chakra-ui/react'
import { useCategory, useGenerateId, useOrder, useProduct } from 'Hooks'
import { usePromotion } from 'Hooks/promotion'
import {
  promotionCheckDiscountCondition,
  promotionCheckFreeCondition,
} from 'Utils/promotion'
import { vatIncluding } from 'Utils/vat'
import { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'underscore'
import { decodeUnit, encodeUnit } from '../../Utils/currency'
import { calPromotionItems, rejectFreeItemCategory } from './Utils/calPromotion'
import { calAmount, calDiscount } from './Utils/calcurate'
import MainPos from './components'
import MobilePos from './components/mobile'

function Pos({ user }) {
  // const { setError } = useAppContext()
  const { Product, getAllProducts, isLoading } = useProduct()
  const { getActivePromotion } = usePromotion()
  const [activePromotion, setActivePromotion] = useState([])
  const { category, getCategoryAll } = useCategory()
  // const { allBankForBranch } = usePayment()
  const { orderCreated } = useOrder()
  const { IdOnlyNumber } = useGenerateId()
  const [payLaterNote, setPayLaterNote] = useState()
  const [posHistory, setPosHistory] = useState(null)
  const breakpoints = useBreakpoint()
  const [paymentStep, setPaymentStep] = useState({
    cart: true,
    selectPayment: false,
    cashReceived: false,
    bankReceived: false,
    bankSelect: false,
    successPayment: false,
    selectBank: false,
    bankGetQR: false,
    bankTakeSlip: false,
    laterPayment: false,
    multiplePayment: false,
    multiplePaymentCount: false,
    selectCoupons: false,
    promotionsProduct: false,
  })
  const [cartItems, setCartItems] = useState([])
  const [received, setReceived] = useState(null)
  const [startDate, setStartDate] = useState(new Date())
  // const [posUseBank, setPosUseBank] = useState(null)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [posUseDiscount, setPosUseDiscount] = useState([])
  const [file, setFile] = useState(null)
  const [successType, setSuccessType] = useState()
  const {
    isOpen: mobileIsOpen,
    onOpen: mobileOnOpen,
    onClose: mobileOnClose,
  } = useDisclosure()

  const fetchPromotion = useCallback(async () => {
    const getPromo = await getActivePromotion()
    setActivePromotion(getPromo)
  }, [getActivePromotion])

  useEffect(() => {
    getAllProducts()
    getCategoryAll()
    fetchPromotion()
  }, [getAllProducts, getCategoryAll, fetchPromotion])

  useEffect(() => {
    if (cartItems?.length > 0) {
      const calReceive = calPromotionItems(cartItems, posUseDiscount)?.sum
      setReceived(calReceive)
    } else {
      setReceived(0)
    }
  }, [cartItems, posUseDiscount])

  const categoryData = useMemo(() => {
    return category
  }, [category])

  // const paymentData = useMemo(() => {
  //   return allBankForBranch
  // }, [allBankForBranch])

  const backStep = () => {
    const {
      selectPayment,
      cashReceived,
      bankReceived,
      bankSelect,
      bankGetQR,
      bankTakeSlip,
      multiplePayment,
      multiplePaymentCount,
      laterPayment,
      selectCoupons,
    } = paymentStep

    selectPayment &&
      setPaymentStep({
        cart: true,
      })
    selectCoupons &&
      setPaymentStep({
        cart: true,
      })
    cashReceived &&
      setPaymentStep({
        selectPayment: true,
      })
    bankReceived &&
      setPaymentStep({
        selectPayment: true,
      })
    bankSelect &&
      setPaymentStep({
        bankReceived: true,
      })
    bankGetQR &&
      setPaymentStep({
        bankReceived: true,
      })
    bankTakeSlip &&
      setPaymentStep({
        bankReceived: true,
      })
    laterPayment &&
      setPaymentStep({
        selectPayment: true,
      })
    multiplePayment &&
      multiplePaymentCount &&
      setPaymentStep({
        selectPayment: true,
      })
  }

  /**
   * @interface
   * @param {number} promotionItemIndex
   * @param {object} promotion
   * @param {boolean} removeInCart
   */
  const removePromotionItem = (promotionItemIndex, promotion) => {
    // return
    if (promotionCheckFreeCondition(promotion)) {
      // if (removeInCart) {
      let carts = []
      if (
        promotion.typeDiscount === 'category' &&
        promotion.typeFree === 'category'
      ) {
        carts = rejectFreeItemCategory(promotion, cartItems)
        setCartItems(carts)
      } else if (
        promotion.typeDiscount === 'list' &&
        promotion.typeFree === 'list'
      ) {
        let carts = cartItems
        const filterCartItem = _.find(carts, (i) => {
          return i.id === promotion.productFree.id && i.free
        })
        carts = _.reduce(
          carts,
          (val, item) => {
            if (item?.id === filterCartItem?.id && item.free) {
              if (item.amount === 1 || item.amount <= 0) {
                return val
              }
              return [
                ...val,
                {
                  ...item,
                  amount:
                    item.amount -
                    parseFloat(decodeUnit(promotion?.freeProductAmount)),
                },
              ]
            } else {
              return [...val, item]
            }
          },
          []
        )
        setCartItems(carts)
      } else if (
        promotion.typeDiscount === 'category' &&
        promotion.typeFree === 'list'
      ) {
        let carts = cartItems
        const filterCartItem = _.find(carts, (i) => {
          return i.id === promotion.productFree.id && i.free
        })
        carts = _.reduce(
          carts,
          (val, item) => {
            if (item?.id === filterCartItem?.id && item.free) {
              if (item.amount === 1 || item.amount <= 0) {
                return val
              }
              return [
                ...val,
                {
                  ...item,
                  amount:
                    item.amount -
                    parseFloat(decodeUnit(promotion?.freeProductAmount)),
                },
              ]
            } else {
              return [...val, item]
            }
          },
          []
        )
        setCartItems(carts)
      }

      const rejectPromotionWithIndex = posUseDiscount.filter(
        (item, index) => index !== promotionItemIndex
      )
      setPosUseDiscount(rejectPromotionWithIndex)

      // return setCartItems(carts)
      // } else {
      //   const rejectPromotionWithIndex = posUseDiscount.filter(
      //     (item, index) => index !== promotionItemIndex
      //   )
      //   setPosUseDiscount(rejectPromotionWithIndex)
      // }
    } else {
      const rejectPromotionWithIndex = posUseDiscount.filter(
        (item, index) => index !== promotionItemIndex
      )
      setPosUseDiscount(rejectPromotionWithIndex)
    }
  }

  //เพิ่มสินค้าเข้าตะกร้าโดยจำนวน
  /**
   * @param {object} product
   * @param {number | string} pAmount
   */
  const addCartCount = (product, pAmount) => {
    const filterInCart = _.filter(cartItems, (i) => {
      return !i.free
    })
    let inCart = _.pluck(filterInCart, 'id')

    setPaymentStep({
      cart: true,
    })
    if (pAmount) {
      if (_.contains(inCart, product.id)) {
        setCartItems(
          cartItems.map((item) => {
            if (item.id === product.id) {
              return { ...item, amount: parseFloat(pAmount) }
              // if (parseFloat(item.amount) <= parseFloat(item.total)) {
              //   return { ...item, amount: parseFloat(pAmount) }
              // } else {
              //   return { ...item, amount: parseFloat(product.total) }
              // }
            } else {
              return { ...item }
            }
          })
        )
      }
    }
  }

  const removePromotionCartFreeItem = (product, free, amount) => {
    // const findPromotion = _.find(posUseDiscount, (i) => {
    //   return product.promotionId === i.id
    // })
    // if (
    //   findPromotion.type === 'free' &&
    //   findPromotion.typeDiscount === 'category' &&
    //   findPromotion.typeFree === 'category'
    // ) {
    //   const findItemIndex = _.findIndex(posUseDiscount, findPromotion)
    //   if (findItemIndex) {
    //     removePromotionItem(findItemIndex, findPromotion)
    //   }
    // } else if (
    //   findPromotion.type === 'free' &&
    //   findPromotion.typeDiscount === 'list' &&
    //   findPromotion.typeFree === 'list'
    // ) {
    // }
    /*
        ลบสินค้าที่เป็นสินค้าแถมฟรี
      */
    const findPromotions = _.find(posUseDiscount, (i) => {
      return i.id === product.promotionId
    })
    const findItemIndex = _.findIndex(posUseDiscount, findPromotions)
    if (findPromotions) {
      removePromotionItem(findItemIndex, findPromotions, false)
    }
    // const carts = cartItems

    // return
    // const setItemCart = _.reduce(
    //   carts,
    //   (val, item) => {
    //     if (item.id === product.id && item.free) {
    //       if (item.amount === 1 || item.amount <= 0) {
    //         return val
    //       }
    //       return [...val, { ...item, amount: item.amount - amount }]
    //     } else {
    //       return [...val, item]
    //     }
    //   },
    //   []
    // )
    // return setCartItems(setItemCart)
  }

  const addPromotionCartFreeItems = (promotionCheck, product, free, amount) => {
    let carts = cartItems
    //เพิ่มสินค้า free เข้าตะกร้าสินค้า
    const filterInCart = _.filter(carts, (i) => {
      return i.free
    })
    let inCart = _.pluck(filterInCart, 'id')
    if (_.contains(inCart, product.id)) {
      let items = carts.map((item) => {
        if (item.id === product.id && item.free) {
          return product?.unit === 'piece'
            ? { ...item, amount: item.amount + amount }
            : { ...item, amount: item.amount + amount }
        } else {
          return { ...item }
        }
      })
      carts = items
    } else {
      const dataToState =
        product?.unit === 'piece'
          ? [...carts, { ...product, amount: amount, free: true }]
          : [...carts, { ...product, amount: amount, free: true }]
      carts = dataToState
    }

    if (
      promotionCheck?.promotion?.typeDiscount !== 'free' &&
      promotionCheck?.promotion?.typeDiscount !== 'list' &&
      promotionCheck?.promotion?.typeFree !== 'list'
    ) {
      const setItemCart = _.reduce(
        carts,
        (val, item) => {
          if (item?.id === product?.id && !item.free) {
            if (item.amount === 1 || item.amount <= 0) {
              return val
            }
            return [...val, { ...item, amount: item.amount - amount }]
          } else {
            return [...val, item]
          }
        },
        []
      )
      carts = setItemCart
    }

    return setCartItems(carts)
  }

  //เพิ่มสินค้าเข้าตะกร้า
  const addCart = (product, free, amount) => {
    if (free) {
      //เพิ่มสินค้า free เข้าตะกร้าสินค้า
      const filterInCart = _.filter(cartItems, (i) => {
        return i.free
      })
      let inCart = _.pluck(filterInCart, 'id')
      setPaymentStep({
        cart: true,
      })

      if (_.contains(inCart, product.id)) {
        let items = cartItems.map((item) => {
          if (item.id === product.id && item.free) {
            return product?.unit === 'piece'
              ? { ...item, amount: item.amount + amount }
              : { ...item, amount: item.amount + amount }
          } else {
            return { ...item }
          }
        })
        return setCartItems(items)
      } else {
        const dataToState =
          product?.unit === 'piece'
            ? [...cartItems, { ...product, amount: amount, free: true }]
            : [...cartItems, { ...product, amount: amount, free: true }]
        return setCartItems(dataToState)
      }
    } else {
      /*
        เพิ่มสินค้า ที่ไม่ free เข้าตะกร้าสินค้า

        step:
          1. เช็คสินค้าในสต๊อกก่อน
          2. ถ้าสินค้าเพียงพอให้เพิ่มในตะกร้าสินค้า แต่ถ้าไม่พอให้แจ้งว่าสินค้าไม่เพียงพอ
      */

      // const findItemInv = _.findWhere(Product, { id: product.id })
      // if (parseFloat(findItemInv.total) > 0) {
      const filterInCart = _.filter(cartItems, (i) => {
        return !i.free
      })
      let inCart = _.pluck(filterInCart, 'id')
      setPaymentStep({
        cart: true,
      })

      if (_.contains(inCart, product.id)) {
        let items = cartItems.map((item) => {
          if (item.id === product.id && !item.free) {
            return product?.unit === 'piece'
              ? { ...item, amount: item.amount + 1 }
              : { ...item, amount: item.amount + 0.5 }
          } else {
            return { ...item }
          }
        })
        setCartItems(items)
      } else {
        const dataToState =
          product?.unit === 'piece'
            ? [...cartItems, { ...product, amount: 1, free: false }]
            : [...cartItems, { ...product, amount: 0.5, free: false }]
        setCartItems(dataToState)
      }
      // } else {
      //   setError({
      //     code: 'POS',
      //     message: 'ไม่มีสินค้าในคลังสินค้า',
      //   })
      // }
    }
  }
  /*
  ลบสินค้าทีละชิ้น
  */
  const removeCart = async (product) => {
    /*
        ลบสินค้าที่เป็นสินค้าปกติ
      */

    const setItemCart = _.reduce(
      cartItems,
      (val, item) => {
        if (item.id === product.id && !item.free) {
          if (item.amount === 1 || item.amount <= 0) {
            return val
          }
          return [...val, { ...item, amount: item.amount - 1 }]
        } else {
          return [...val, item]
        }
      },
      []
    )
    return setCartItems(setItemCart)
  }

  const removeItemCart = (product) => {
    let inCart = _.pluck(cartItems, 'id')
    if (_.contains(inCart, product.id)) {
      let removeItem = _.reject(cartItems, (item) => item.id === product.id)
      return setCartItems(removeItem)
    }
  }

  const confirmPayment = async (type) => {
    setConfirmLoading(true)
    setSuccessType(type)

    const itemsPromo = calPromotionItems(cartItems, posUseDiscount)?.items

    const itemsAll = _.map(cartItems, (e) => {
      const filterItemDiscount = _.filter(itemsPromo, (i) => {
        return e.id === i.productId
      })
      if (filterItemDiscount.length > 0) {
        const pluckDiscount = _.pluck(filterItemDiscount, 'discount')
        const sumDiscount = _.reduce(pluckDiscount, (memo, num) => {
          return memo + num
        })
        const mixDiscount = _.map(filterItemDiscount, (i) => {
          return i.promotion
        })

        return {
          id: e.id,
          amount: encodeUnit(parseFloat(e.amount)).toString(),
          cost: e.cost,
          commission: e.commission,
          name: e.name,
          nameOption: e.nameOption || null,
          sku: e.sku,
          price: e.price,
          img: e.img || null,
          unit: e.unit,
          category: e.category,
          commissionCalType: e.commissionCalType,
          index: e.index || null,
          minimum: e.minimum,
          ref: e.ref || null,
          typeCommission: e.typeCommission,
          vat: e.vat,
          free: e.free || false,
          total: encodeUnit(
            (parseFloat(decodeUnit(e.price)) * e.amount).toFixed(2)
          ).toString(),
          discount: mixDiscount || null,
          totalDiscount: sumDiscount ? encodeUnit(sumDiscount).toString() : '0',
          grandTotal: sumDiscount
            ? encodeUnit(
                (
                  e.amount * parseFloat(decodeUnit(e.price)) -
                  sumDiscount
                ).toFixed(2)
              ).toString()
            : encodeUnit(
                (e.amount * parseFloat(decodeUnit(e.price))).toFixed(2)
              ).toString(),
        }
      } else {
        return {
          id: e.id,
          amount: encodeUnit(parseFloat(e.amount)).toString(),
          cost: e.cost,
          commission: e.commission,
          name: e.name,
          nameOption: e.nameOption || null,
          sku: e.sku,
          price: e.price,
          img: e.img || null,
          unit: e.unit,
          category: e.category,
          commissionCalType: e.commissionCalType,
          index: e.index || null,
          minimum: e.minimum,
          ref: e.ref || null,
          typeCommission: e.typeCommission,
          vat: e.vat,
          free: e.free || false,
          total: encodeUnit(
            (parseFloat(decodeUnit(e.price)) * e.amount).toFixed(2)
          ).toString(),
          discount: null,
          totalDiscount: '0',
          grandTotal: encodeUnit(
            (e.amount * parseFloat(decodeUnit(e.price))).toFixed(2)
          ).toString(),
        }
      }
    })
    // const promotionsData = getPromotions(activePromotion, posUseDiscount)

    let data = {}
    if (type === 'bank') {
      // const useBank = posUseBank
      // delete useBank.createdAt
      // delete useBank.branchs
      // delete useBank.updatedAt
      // delete useBank.updatedBy
      // delete useBank.img
      // delete useBank.paymentBranchType
      // delete useBank.default
      // delete useBank.edit
      // delete useBank.typeOfAccount
      // delete useBank.disabled
      // delete useBank.paymentType

      const idGen = await IdOnlyNumber(10)
      const filterDiscount = _.filter(posUseDiscount, (item) => {
        if (!promotionCheckDiscountCondition(item)) {
          return item
        }
      })
      data = {
        orderId: idGen['0'],
        total: encodeUnit(calAmount(cartItems)).toString(),
        // grandTotal: encodeUnit(calAmount(cartItems, posUseDiscount)).toString(),
        // grandTotal: parseFloat(
        //   encodeUnit(calPromotionItems(cartItems, posUseDiscount)?.sum).toFixed(
        //     2
        //   )
        // ).toString(),
        discount: filterDiscount?.length > 0 ? filterDiscount : null,
        totalDiscount: parseFloat(
          encodeUnit(
            calPromotionItems(cartItems, posUseDiscount)?.discount * -1
          ).toFixed(2)
        ).toString(),
        items: itemsAll,
        // received: encodeUnit(calDiscount(cartItems, posUseDiscount)).toString(),
        received: received ? encodeUnit(received) : null,
        collectReceived: encodeUnit(
          calDiscount(cartItems)
          // calDiscount(cartItems, posUseDiscount)
        ).toString(),
        createdAt: new Date(),
        createdBy: { id: user?.uid, name: user?.name, profile: user?.profile },
        status: 'paid',
        branch: {
          id: user?.branchSelect?.id,
          name: user?.branchSelect?.name,
        },
        payment: type,
        // bank: useBank || null,
        vat: encodeUnit(
          vatIncluding(calPromotionItems(cartItems, posUseDiscount)?.sum).vat
        ).toString(),
        subTotal: encodeUnit(
          vatIncluding(calPromotionItems(cartItems, posUseDiscount)?.sum).total
        ).toString(),
        grandTotal: encodeUnit(
          vatIncluding(calPromotionItems(cartItems, posUseDiscount)?.sum)
            .total +
            vatIncluding(calPromotionItems(cartItems, posUseDiscount)?.sum).vat
        ).toString(),
      }
    } else {
      const idGen = await IdOnlyNumber(10)

      const filterDiscount = _.filter(posUseDiscount, (item) => {
        if (!promotionCheckDiscountCondition(item)) {
          return item
        }
      })
      data = {
        orderId: idGen['0'],
        total: encodeUnit(calAmount(cartItems)).toString(),
        // grandTotal: encodeUnit(calAmount(cartItems, posUseDiscount)).toString(),
        // grandTotal: parseFloat(
        //   encodeUnit(calPromotionItems(cartItems, posUseDiscount)?.sum).toFixed(
        //     2
        //   )
        // ).toString(),
        discount: filterDiscount?.length > 0 ? filterDiscount : null,
        totalDiscount: parseFloat(
          encodeUnit(
            calPromotionItems(cartItems, posUseDiscount)?.discount * -1
          ).toFixed(2)
        ).toString(),
        items: itemsAll,
        received: encodeUnit(received),
        // received: encodeUnit(calDiscount(cartItems, posUseDiscount)).toString(),
        collectReceived: encodeUnit(parseFloat(received)).toString(),
        createdAt: new Date(),
        createdBy: { id: user?.uid, name: user?.name, profile: user?.profile },
        branch: {
          id: user?.branchSelect?.id,
          name: user?.branchSelect?.name,
        },
        status: 'paid',
        payment: type,
        vat: encodeUnit(
          vatIncluding(calPromotionItems(cartItems, posUseDiscount)?.sum).vat
        ).toString(),
        subTotal: encodeUnit(
          vatIncluding(calPromotionItems(cartItems, posUseDiscount)?.sum).total
        ).toString(),
        grandTotal: encodeUnit(
          vatIncluding(calPromotionItems(cartItems, posUseDiscount)?.sum)
            .total +
            vatIncluding(calPromotionItems(cartItems, posUseDiscount)?.sum).vat
        ).toString(),
      }
    }
    try {
      setPaymentStep({
        successPayment: true,
      })
      // setPosHistory(data)
      await orderCreated(data, file).then((e) => {
        setPosHistory({
          ...data,
          ...e,
        })
        getAllProducts()
        setPaymentStep({
          successPayment: true,
        })
        setPosUseDiscount([])
        setReceived(null)
        setCartItems([])
        setFile(null)
        setConfirmLoading(false)
      })
    } catch (error) {
      setConfirmLoading(false)
    }
  }

  // ชำระเงินภายหลัง
  const confirmlaterPayment = async (type) => {
    setConfirmLoading(true)
    setSuccessType(type)

    const itemsPromo = calPromotionItems(cartItems, posUseDiscount)?.items
    const itemsAll = _.map(cartItems, (e) => {
      const filterItemDiscount = _.filter(itemsPromo, (i) => {
        return e.id === i.productId
      })
      if (filterItemDiscount.length > 0) {
        const pluckDiscount = _.pluck(filterItemDiscount, 'discount')
        const sumDiscount = _.reduce(pluckDiscount, (memo, num) => {
          return memo + num
        })
        const mixDiscount = _.map(filterItemDiscount, (i) => {
          return i.promotion
        })

        return {
          id: e.id,
          amount: encodeUnit(parseFloat(e.amount)).toString(),
          cost: e.cost,
          commission: e.commission,
          name: e.name,
          nameOption: e.nameOption || null,
          sku: e.sku,
          price: e.price,
          img: e.img || null,
          unit: e.unit,
          category: e.category,
          commissionCalType: e.commissionCalType,
          index: e.index || null,
          minimum: e.minimum,
          ref: e.ref || null,
          typeCommission: e.typeCommission,
          // vat: e.vat,
          free: e.free || false,
          total: encodeUnit(
            (e.amount * parseFloat(decodeUnit(e.price))).toFixed(2)
          ).toString(),
          discount: mixDiscount || null,
          totalDiscount: sumDiscount ? encodeUnit(sumDiscount).toString() : '0',
          // grandTotal: sumDiscount
          //   ? encodeUnit(
          //       (
          //         e.amount * parseFloat(decodeUnit(e.price)) -
          //         sumDiscount
          //       ).toFixed(2)
          //     ).toString()
          //   : encodeUnit(
          //       (e.amount * parseFloat(decodeUnit(e.price))).toFixed(2)
          //     ).toString(),
          vat: encodeUnit(
            vatIncluding(calPromotionItems(cartItems, posUseDiscount)?.sum).vat
          ).toString(),
          subTotal: encodeUnit(
            vatIncluding(calPromotionItems(cartItems, posUseDiscount)?.sum)
              .total
          ).toString(),
          grandTotal: encodeUnit(
            vatIncluding(calPromotionItems(cartItems, posUseDiscount)?.sum)
              .total +
              vatIncluding(calPromotionItems(cartItems, posUseDiscount)?.sum)
                .vat
          ).toString(),
        }
      } else {
        return {
          id: e.id,
          amount: encodeUnit(parseFloat(e.amount)).toString(),
          cost: e.cost,
          commission: e.commission,
          name: e.name,
          nameOption: e.nameOption || null,
          sku: e.sku,
          price: e.price,
          img: e.img || null,
          unit: e.unit,
          category: e.category,
          commissionCalType: e.commissionCalType,
          index: e.index || null,
          minimum: e.minimum,
          ref: e.ref || null,
          typeCommission: e.typeCommission,
          // vat: e.vat,
          free: e.free || false,
          total: encodeUnit(
            (e.amount * parseFloat(decodeUnit(e.price))).toFixed(2)
          ).toString(),
          discount: null,
          totalDiscount: '0',
          // grandTotal: encodeUnit(
          //   (e.amount * parseFloat(decodeUnit(e.price))).toFixed(2)
          // ).toString(),
          vat: encodeUnit(
            vatIncluding(calPromotionItems(cartItems, posUseDiscount)?.sum).vat
          ).toString(),
          subTotal: encodeUnit(
            vatIncluding(calPromotionItems(cartItems, posUseDiscount)?.sum)
              .total
          ).toString(),
          grandTotal: encodeUnit(
            vatIncluding(calPromotionItems(cartItems, posUseDiscount)?.sum)
              .total +
              vatIncluding(calPromotionItems(cartItems, posUseDiscount)?.sum)
                .vat
          ).toString(),
        }
      }
    })

    const idGen = await IdOnlyNumber(10)
    const filterDiscount = _.filter(posUseDiscount, (item) => {
      if (!promotionCheckDiscountCondition(item)) {
        return item
      }
    })
    const data = {
      orderId: idGen['0'],
      total: encodeUnit(calAmount(cartItems)).toString(),
      // grandTotal: encodeUnit(calAmount(cartItems, posUseDiscount)).toString(),
      grandTotal: parseFloat(
        encodeUnit(calPromotionItems(cartItems, posUseDiscount)?.sum).toFixed(2)
      ).toString(),
      discount: filterDiscount?.length > 0 ? filterDiscount : null,
      totalDiscount: parseFloat(
        encodeUnit(
          calPromotionItems(cartItems, posUseDiscount)?.discount * -1
        ).toFixed(2)
      ).toString(),
      items: itemsAll,
      received: parseFloat(0).toString(),
      collectReceived: parseFloat(0).toString(),
      createdAt: new Date(),
      createdBy: { id: user?.uid, name: user?.name, profile: user?.profile },
      branch: {
        id: user?.branchSelect?.id,
        name: user?.branchSelect?.name,
      },
      status: 'payLater',
      payment: type,
      note: payLaterNote || null,
      dueDate: startDate,
    }
    try {
      setPaymentStep({
        successPayment: true,
      })
      setPosHistory(data)
      await orderCreated(data, file).then(() => {
        setPaymentStep({
          successPayment: true,
        })
        setReceived(null)
        setPosUseDiscount([])
        setCartItems([])
        setPayLaterNote(null)
        setStartDate(new Date())
        setConfirmLoading(false)
      })
    } catch (error) {
      setConfirmLoading(false)
    }
  }

  const orderSuccessPayment = (mobile) => {
    if (mobile) {
      mobileOnClose()
    }
    setPaymentStep({
      cart: true,
    })
  }

  if (breakpoints === '2sm' || breakpoints === 'sm' || breakpoints === 'base') {
    return (
      <>
        <Stack>
          <MobilePos
            user={user}
            category={categoryData}
            removeItemCart={removeItemCart}
            addCart={addCart}
            removeCart={removeCart}
            cartItems={cartItems}
            confirmPayment={confirmPayment}
            paymentStep={paymentStep}
            setReceived={setReceived}
            received={received}
            // paymentData={paymentData}
            setPaymentStep={setPaymentStep}
            backStep={backStep}
            orderSuccessPayment={orderSuccessPayment}
            mobile={true}
            isOpen={mobileIsOpen}
            onOpen={mobileOnOpen}
            onClose={mobileOnClose}
            confirmLoading={confirmLoading}
            // posUseBank={posUseBank}
            // setPosUseBank={setPosUseBank}
            setFile={setFile}
            file={file}
            successType={successType}
            confirmlaterPayment={confirmlaterPayment}
            payLaterNote={payLaterNote}
            setPayLaterNote={setPayLaterNote}
            startDate={startDate}
            setStartDate={setStartDate}
            //ส่วนลดร้านค้า
            posUseDiscount={posUseDiscount}
            setPosUseDiscount={setPosUseDiscount}
            posHistory={posHistory}
            addCartCount={addCartCount}
            activePromotion={activePromotion}
            removePromotionItem={removePromotionItem}
            addPromotionCartFreeItems={addPromotionCartFreeItems}
            removePromotionCartFreeItem={removePromotionCartFreeItem}
            branchProductStock={Product}
            branchStockIsLoading={isLoading}
          />
        </Stack>
      </>
    )
  }
  return (
    <>
      <Stack>
        <MainPos
          user={user}
          removeItemCart={removeItemCart}
          category={categoryData}
          addCart={addCart}
          removeCart={removeCart}
          cartItems={cartItems}
          confirmPayment={confirmPayment}
          paymentStep={paymentStep}
          setPaymentStep={setPaymentStep}
          backStep={backStep}
          received={received}
          setReceived={setReceived}
          orderSuccessPayment={orderSuccessPayment}
          confirmLoading={confirmLoading}
          // paymentData={paymentData}
          // posUseBank={posUseBank}
          // setPosUseBank={setPosUseBank}
          setFile={setFile}
          file={file}
          successType={successType}
          confirmlaterPayment={confirmlaterPayment}
          payLaterNote={payLaterNote}
          setPayLaterNote={setPayLaterNote}
          startDate={startDate}
          setStartDate={setStartDate}
          //ส่วนลดร้านค้า
          posUseDiscount={posUseDiscount}
          setPosUseDiscount={setPosUseDiscount}
          posHistory={posHistory}
          addCartCount={addCartCount}
          activePromotion={activePromotion}
          removePromotionItem={removePromotionItem}
          addPromotionCartFreeItems={addPromotionCartFreeItems}
          removePromotionCartFreeItem={removePromotionCartFreeItem}
          branchProductStock={Product}
          branchStockIsLoading={isLoading}
        />
      </Stack>
    </>
  )
}

export default Pos
