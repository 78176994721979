import {
  Center,
  HStack,
  Flex,
  Box,
  Text,
  Button,
  Stack,
  Heading,
  Select,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import CustomTable from 'Components/Horizons/table/customTable'
import { SearchIcon } from '@chakra-ui/icons'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { collection, onSnapshot, query } from 'firebase/firestore'
import { db } from 'Config/firebase'
import { currency, decodeUnit } from 'Utils/currency'
import { calPromotionDecodeUnitAmountMultipleItems } from 'Utils/calStock'
import SearchDate from 'Components/Search/date'
import { employeeSaleReport } from './query'
import { billStatus } from 'Utils'
import CSVDownloadForm from 'Components/csvDownload'

const _ = require('underscore')
// const { paymentMessage } = billStatus()

function Price(props) {
  const { grandTotal } = props
  return (
    <HStack justifyContent='end' spacing='4em'>
      <Stack>
        <Box>
          <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
            ยอดขายสุทธิ
          </Text>
        </Box>
        <Box>
          <Text
            fontSize={{ base: '12px', md: '16px' }}
            color='report.500'
            textAlign='right'
          >
            {currency(grandTotal, 2, true)}
          </Text>
        </Box>
      </Stack>
      {/* <Stack>
        <Box>
          <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
            คอมมิชชั่นสุทธิ
          </Text>
        </Box>
        <Box>
          <Text
            fontSize={{ base: '12px', md: '16px' }}
            color='report.500'
            textAlign='right'
          >
            {currency(grandCommission, 2, true)}
          </Text>
        </Box>
      </Stack> */}
    </HStack>
  )
}

function EmployeeSales({ user }) {
  const { paymentMessage } = billStatus()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [filterData, setFilterData] = useState([])
  const [onSearch, setOnSearch] = useState(false)
  const [month, setMonth] = useState(new Date())
  const [between, setBetween] = useState(false)
  const [prevMonth, setPrevMonth] = useState(false)
  const [currentDate, setCurrentDate] = useState(true)
  const [currentMonth, setCurrentMonth] = useState(false)
  const [selectEmp, setSelectEmp] = useState('')
  const [userList, setUserList] = useState([])
  const [startDateTime, setStartDateTime] = useState(null)
  const [endDateTime, setEndDateTime] = useState(null)
  const [dateTime, setDateTime] = useState(false)
  const [dataToCSV, setDataToCSV] = useState([])
  const [select, setSelect] = useState('')
  const [allUserList, setAllUserList] = useState([])

  useEffect(() => {
    const qUserList = query(collection(db, 'Users'))
    const unsubscribe = onSnapshot(qUserList, (snapShot) => {
      let userListValue = []
      snapShot?.forEach((doc) => {
        return userListValue.push({
          ...doc?.data(),
          id: doc.id,
        })
      })
      const findBranch = userListValue.map((item) => ({
        ...item,
        branch: item?.branch?.find((e) => e.id === user?.branchSelect?.id),
      }))

      const res = findBranch.filter(
        (e) => e.branch?.id === user?.branchSelect?.id
      )
      setAllUserList(res)
      const selectResult = select
        ? res?.filter((f) => f?.salaryType === select)
        : res
      const result = selectResult?.map((item) => ({
        id: item?.id,
        name: item?.name,
        commission: item?.commission,
      }))
      setUserList(result)
    })
    return () => {
      unsubscribe()
    }
  }, [user, select])

  function calGrandTotal(item) {
    const arrGrandTotal = _.pluck(item, 'grandTotal')
    return _.reduce(arrGrandTotal, (memo, num) => memo + num, 0)
  }

  // function calGrandCommission(item) {
  //   const arrGrandTotal = _.pluck(item, 'grandCommission')
  //   return _.reduce(arrGrandTotal, (memo, num) => memo + num, 0)
  // }

  const csvHeaders = [
    { label: 'รหัสใบสั่งซื้อ', key: 'orderId' },
    { label: 'วันที่', key: 'date' },
    { label: 'สาขา', key: 'branch' },
    { label: 'ชื่อพนักงาน', key: 'name' },
    { label: 'ยอดขาย', key: 'total' },
    { label: 'ยอดคืนเงิน', key: 'refundAmount' },
    { label: 'ยอดขายสุทธิ', key: 'grandTotal' },
    { label: 'ช่องทางการชำระ', key: 'payment' },
    { label: 'คอมมิชชั่น', key: 'grandCommission' },
  ]

  async function onSubmitFilter() {
    const res = await employeeSaleReport({
      startDate: startDate,
      endDate: endDate,
      between: between,
      prevMonth: prevMonth,
      currentDate: currentDate,
      currentMonth: currentMonth,
      emp: selectEmp,
      user: user,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      dateTime: dateTime,
      month: month,
    })

    if (startDateTime && endDateTime) {
      setStartDate(startDateTime)
      setEndDate(endDateTime)
    } else {
      setStartDateTime(null)
      setEndDateTime(null)
    }
    const resIndex = _.indexBy(res, 'id')
    const dataCal = await Promise.all(
      _.map(resIndex, (order) => {
        const amount = calPromotionDecodeUnitAmountMultipleItems(order.items)
        const discount = decodeUnit(order.discountBalance)
        const cal = discount > 0 ? amount + discount : amount
        const salaryType = allUserList.find(
          (f) => f?.id === order?.createdBy.id
        )?.salaryType
        return {
          ...order,
          salaryType: salaryType,
          total: order.total,
          promotionAndDiscount: cal,
          grandTotal: order.grandTotal,
        }
      })
    )
    const dataCSV = _.map(dataCal, (items) => {
      return {
        name: items.createdBy.name,
        date: items.date,
        orderId: items.orderId,
        refundAmount: items.refundAmount,
        total: currency(items.total, 2),
        payment: paymentMessage(items.payment),
        grandCommission: currency(items.grandCommission, 2),
        branch: items.branch,
        paymentAmount: currency(items.paymentAmount, 2),
        grandTotal: currency(items.grandTotal, 2),
      }
    })
    const userCheckCommission = await Promise.all(
      await _.map(dataCal, async (order) => {
        const findUser = await _.find(userList, (user) => {
          return order?.createdBy?.id === user?.id
        })
        if (findUser?.commission) {
          return {
            ...order,
            grandCommission: order.grandCommission,
          }
        } else {
          return {
            ...order,
            grandCommission: 0,
          }
        }
      })
    )
    setDataToCSV(dataCSV)
    setOnSearch(true)
    setFilterData(
      select
        ? await userCheckCommission?.filter((f) => f?.salaryType === select)
        : await userCheckCommission
    )
  }

  const columnsDataOrders = [
    // {
    //   Header: 'ลำดับ',
    //   accessor: 'item',
    //   extra: (data, row) => <Center key={row}>{row.item}</Center>,
    // },
    {
      Header: 'พนักงานขาย',
      accessor: 'createdBy.name',
      extra: (data, row) => <Center key={row}>{row.createdBy?.name}</Center>,
    },
    {
      Header: 'วันที่',
      accessor: 'date',
      extra: (data, row) => <Center key={row}>{row.date}</Center>,
    },
    {
      Header: ' เลขที่รายการขาย',
      accessor: 'orderId',
      extra: (data, row) => <Center key={row}>{row.orderId}</Center>,
    },
    {
      Header: (
        <Text w='100%' textAlign='end'>
          ยอดการขาย
        </Text>
      ),
      accessor: 'paymentAmount',
      extra: (data, row) => (
        <Center key={row} justifyContent='end' w='full'>
          {currency(row.paymentAmount, 2)}
        </Center>
      ),
    },
    {
      Header: (
        <Text w='100%' textAlign='end'>
          ยอดการคืนเงิน
        </Text>
      ),
      accessor: 'refundAmount',
      extra: (data, row) => (
        <Center key={row} justifyContent='end' w='full' color='#FF6501'>
          {row.refundAmount > 0 ? `- ${currency(row.refundAmount, 2)}` : '-'}
        </Center>
      ),
    },
    {
      Header: (
        <Text w='100%' textAlign='end'>
          ยอดขายสุทธิ
        </Text>
      ),
      accessor: 'grandTotal',
      extra: (data, row) => (
        <Center key={row} justifyContent='end' w='full'>
          {currency(row.grandTotal, 2)}
        </Center>
      ),
    },
    {
      Header: (
        <Text w='100%' textAlign='center'>
          ช่องทางการชำระ
        </Text>
      ),
      accessor: 'payment',
      extra: (data, row) => (
        <Center key={row} w='full'>
          {paymentMessage(row.payment)}
        </Center>
      ),
    },
    {
      Header: (
        <Text w='100%' textAlign='end'>
          คอมมิชชั่น
        </Text>
      ),
      accessor: 'grandCommission',
      extra: (data, row) => (
        <Center key={row} justifyContent='end' w='full'>
          {currency(row.grandCommission, 2)}
        </Center>
      ),
    },
  ]

  return (
    <>
      <HStack
        mt='1rem'
        mb='0.5em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='1vw'
        flexDirection={{ sm: 'column', md: 'row' }}
        alignItems='start'
      >
        <Flex flexDirection='column' w={{ sm: 'full', md: '30vw', lg: '30vw' }}>
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ช่วงเวลา :</Text>
          </Box>
          <SearchDate
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            month={month}
            setMonth={setMonth}
            setBetween={setBetween}
            setPrevMonth={setPrevMonth}
            setCurrentDate={setCurrentDate}
            setCurrentMonth={setCurrentMonth}
            setStartDateTime={setStartDateTime}
            setEndDateTime={setEndDateTime}
            setDateTime={setDateTime}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
          />
        </Flex>
        <Flex
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '20vw', lg: '29vw' }}
        >
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ประเภทพนักงาน :</Text>
          </Box>
          <Select
            color={'gray.700'}
            w='full'
            bg={'#F4F7FE'}
            border='0px solid #E8E9E9'
            borderRadius='16px'
            defaultValue=''
            fontWeight='500'
            variant='main'
            placeholder={'ทั้งหมด'}
            _placeholder={{
              fontWeight: '400',
            }}
            h='44px'
            maxh='44px'
            onChange={(event) => setSelect(event.target.value)}
          >
            <option value={'daily'}>รายวัน</option>
            <option value={'monthly'}>รายเดือน</option>
          </Select>
        </Flex>
        {select && (
          <Flex
            pt={{ sm: '1rem', md: '0rem' }}
            flexDirection='column'
            w={{ sm: 'full', md: '20vw', lg: '29vw' }}
          >
            <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
              <Text color='#8F9BBA'>พนักงาน :</Text>
            </Box>
            <Select
              color={'gray.700'}
              w='full'
              bg={'#F4F7FE'}
              border='0px solid #E8E9E9'
              borderRadius='16px'
              defaultValue=''
              fontWeight='500'
              variant='main'
              placeholder={'ทั้งหมด'}
              _placeholder={{
                fontWeight: '400',
              }}
              h='44px'
              maxh='44px'
              onChange={(event) => setSelectEmp(event.target.value)}
            >
              {userList?.map((item, index) => (
                <option value={item.id} key={index}>
                  {item?.name}
                </option>
              ))}
            </Select>
          </Flex>
        )}
        <Flex
          display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        ></Flex>
        <Flex
          flexDirection='column'
          w={{ sm: 'full', md: '19vw', lg: '13vw', xl: '11vw' }}
          alignItems='end'
          pt={{ sm: '1rem', md: '0rem' }}
        >
          <Box
            textAlign='start'
            display={{ sm: 'none', md: 'flex' }}
            p='0.5rem'
            pl='1rem'
            pt='0rem'
          >
            <Text color='#8F9BBA'>&nbsp;</Text>
          </Box>
          <Button
            h='44px'
            maxh='44px'
            w={{ sm: '10rem', md: 'full' }}
            colorScheme='btn'
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            onClick={() => onSubmitFilter()}
          >
            <Text>
              <SearchIcon />
            </Text>
            <Text>แสดงรายงาน</Text>
          </Button>
        </Flex>
      </HStack>
      <Flex
        flexDirection='column'
        p={{ sm: '10px', md: '20px 20px 30px 20px' }}
        bg='white'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
        borderRadius='30px'
      >
        <Stack spacing={{ base: '0em', md: '4em' }}>
          <HStack
            justifyContent={'space-between'}
            w='full'
            display={{ sm: 'block', md: 'flex' }}
          >
            <Stack>
              <Heading
                fontFamily={'Prompt'}
                color='#2B3674'
                fontSize={{ base: '24px', md: '30px' }}
              >
                รายงานยอดขายตามพนักงาน
              </Heading>
              <Box>
                <HStack>
                  <Text color='#2B3674'>ณ วันที่ :</Text>
                  <Text color='#2B3674'>
                    {moment(new Date().getTime()).format('MM/DD/YYYY')}
                  </Text>
                </HStack>
                <HStack>
                  <Text color='#2B3674'>ช่วงเวลา :</Text>
                  {startDate && endDate && (
                    <Text color='#2B3674'>
                      {moment(startDate).format('MM/DD/YYYY')} -{' '}
                      {moment(endDate).format('MM/DD/YYYY')}
                    </Text>
                  )}
                </HStack>
              </Box>
            </Stack>
            <HStack justifyContent={{ sm: 'start', md: 'end' }}>
              <CSVDownloadForm
                mt={{ sm: '10px', md: '0px' }}
                buttonTitle={'ดาวน์โหลดไฟล์ยอดขายตามพนักงาน'}
                data={dataToCSV}
                filename={'productSalesReport'}
                headers={csvHeaders}
              />
            </HStack>
          </HStack>
          <Box
            pt={{ base: '1rem', sm: '1rem', md: '0rem', lg: '0rem' }}
            pr={{
              base: '0rem',
              sm: '0rem',
              md: '1rem',
              lg: '1rem',
              xl: '1rem',
            }}
          >
            <Price
              grandTotal={onSearch ? calGrandTotal(filterData) : 0}
              // grandCommission={onSearch ? calGrandCommission(filterData) : 0}
            />
          </Box>
        </Stack>
        <CustomTable
          columnsData={columnsDataOrders}
          tableData={onSearch ? filterData : []}
          closeSearch={true}
        />
      </Flex>
    </>
  )
}

export default EmployeeSales
