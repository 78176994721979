import _ from 'underscore'
import { decodeUnit } from 'Utils'
import { calAmount } from './calcurate'

export const calPromotionItems = (carts, promotions) => {
  const calDiscount = _.reduce(
    promotions,
    (res, item) => {
      if (item?.type === 'discount') {
        if (item?.typeDiscount === 'receipt') {
          if (item?.valueDiscountType === 'Bath') {
            // return decodeUnit(item.valueDiscount)
            return {
              ...res,
              sum: parseFloat(
                res.sum + parseFloat(decodeUnit(item.valueDiscount)) * -1
              ),
              discount: parseFloat(
                res.discount + parseFloat(decodeUnit(item.valueDiscount)) * -1
              ),
            }
          } else if (item?.valueDiscountType === 'Percentage') {
            const calTotal = parseFloat(
              (
                (calAmount(carts) * decodeUnit(item.valueDiscount)) /
                100
              ).toFixed(2)
            )
            return {
              ...res,
              sum: parseFloat(res.sum + calTotal * -1),
              discount: parseFloat(res.discount + calTotal * -1),
            }
          } else {
            return {
              ...res,
              sum: parseFloat(res.sum + 0 * -1),
              discount: parseFloat(res.discount + 0 * -1),
            }
          }
        } else if (item?.typeDiscount === 'list') {
          const filterItems = _.filter(carts, (i) => {
            return !i.free
          })
          const findCartItem = _.find(filterItems, (i) => {
            return i.id === item.product.id
          })
          if (findCartItem) {
            if (item?.valueDiscountType === 'Bath') {
              return {
                ...res,
                sum: parseFloat(res.sum + decodeUnit(item.valueDiscount) * -1),
                discount: parseFloat(
                  res.discount + decodeUnit(item.valueDiscount) * -1
                ),
                items: [
                  ...res.items,
                  {
                    productId: findCartItem.id,
                    promotion: { ...item },
                    discount: parseFloat(decodeUnit(item.valueDiscount)),
                  },
                ],
              }
            } else if (item?.valueDiscountType === 'Percentage') {
              const findCartItem = _.find(carts, (i) => {
                return item.product.id === i.id
              })
              if (findCartItem) {
                const calTotal = parseFloat(
                  (
                    (findCartItem.amount *
                      decodeUnit(findCartItem.price) *
                      decodeUnit(item.valueDiscount)) /
                    100
                  ).toFixed(2)
                )
                return {
                  ...res,
                  sum: parseFloat(res.sum + calTotal * -1),
                  discount: parseFloat(res.discount + calTotal * -1),
                  items: [
                    ...res.items,
                    {
                      productId: findCartItem.id,
                      promotion: { ...item },
                      discount: calTotal,
                    },
                  ],
                }
              }
            } else {
              return {
                ...res,
                sum: parseFloat(res.sum + 0 * -1),
                discount: parseFloat(res.discount + 0 * -1),
              }
            }
          } else {
            return { ...res }
          }
        } else if (item?.typeDiscount === 'category') {
          const filterCartCategory = _.filter(carts, (i) => {
            return (
              item.category.id === i.category ||
              item.category.name === i.category
            )
          })
          if (filterCartCategory) {
            if (item?.valueDiscountType === 'Bath') {
              // return decodeUnit(item.valueDiscount)
              return {
                ...res,
                sum: parseFloat(res.sum + decodeUnit(item.valueDiscount) * -1),
                discount: parseFloat(
                  res.discount + decodeUnit(item.valueDiscount) * -1
                ),
              }
            } else if (item?.valueDiscountType === 'Percentage') {
              const calTotal = parseFloat(
                (
                  (calAmount(filterCartCategory) *
                    decodeUnit(item.valueDiscount)) /
                  100
                ).toFixed(2)
              )
              // return calTotal
              return {
                ...res,
                sum: parseFloat(res.sum + calTotal * -1),
                discount: parseFloat(res.discount + calTotal * -1),
              }
            } else {
              return {
                ...res,
                sum: parseFloat(res.sum + 0 * -1),
                discount: parseFloat(res.discount + 0 * -1),
              }
            }
          } else {
            return { ...res }
          }
        }
      } else if (item?.type === 'free') {
        if (item?.typeDiscount === 'list') {
          if (item.typeFree === 'receipt') {
            const checkItemInCart = _.find(carts, (i) => {
              return i.id === item.product.id
            })
            if (
              checkItemInCart?.amount >=
              parseFloat(decodeUnit(item.productAmount))
            ) {
              if (item?.valueDiscountType === 'Bath') {
                const cal = parseFloat(decodeUnit(item.valueDiscount))
                return {
                  ...res,
                  sum: parseFloat(res.sum + cal * -1),
                  discount: parseFloat(res.discount + cal * -1),
                  items: [
                    ...res.items,
                    {
                      productId: checkItemInCart.id,
                      promotion: { ...item },
                      discount: cal,
                    },
                  ],
                }
              } else if (item?.valueDiscountType === 'Percentage') {
                const cal = parseFloat(
                  (
                    (checkItemInCart?.amount *
                      parseFloat(decodeUnit(checkItemInCart?.price)) *
                      parseFloat(decodeUnit(item.valueDiscount))) /
                    100
                  ).toFixed(2)
                )

                return {
                  ...res,
                  sum: parseFloat(res.sum + cal * -1),
                  discount: parseFloat(res.discount + cal * -1),
                  items: [
                    ...res.items,
                    {
                      productId: checkItemInCart.id,
                      promotion: { ...item },
                      discount: cal,
                    },
                  ],
                }
              } else {
                return {
                  ...res,
                  sum: parseFloat(res.sum + 0 * -1),
                  discount: parseFloat(res.discount + 0 * -1),
                }
              }
            } else {
              return { ...res }
            }
          } else {
            return { ...res }
          }
        } else if (item?.typeDiscount === 'receipt') {
          if (item.typeFree === 'receipt') {
            if (
              parseFloat(calAmount(carts)) >=
              parseFloat(decodeUnit(item.minimumOrder))
            ) {
              if (item?.valueDiscountType === 'Bath') {
                const cal = parseFloat(decodeUnit(item.valueDiscount))
                return {
                  ...res,
                  sum: parseFloat(res.sum + cal * -1),
                  discount: parseFloat(res.discount + cal * -1),
                }
              } else if (item?.valueDiscountType === 'Percentage') {
                const cal = parseFloat(
                  (
                    (res.sum * parseFloat(decodeUnit(item.valueDiscount))) /
                    100
                  ).toFixed(2)
                )
                return {
                  ...res,
                  sum: parseFloat(res.sum + cal * -1),
                  discount: parseFloat(res.discount + cal * -1),
                }
              } else {
                return {
                  ...res,
                  sum: parseFloat(res.sum + 0 * -1),
                  discount: parseFloat(res.discount + 0 * -1),
                }
              }
            }
            return { ...res }
            // } else if (item.typeFree === 'list') {
          } else {
            return { ...res }
          }
        } else if (item?.typeDiscount === 'category') {
          if (item.typeFree === 'receipt') {
            const filtercategoryInCart = _.filter(carts, (i) => {
              return (
                item.category.id === i.category ||
                item.category.name === i.category
              )
            })
            const pluckData = _.pluck(filtercategoryInCart, 'amount')
            const checkAmountItems = _.reduce(pluckData, (memo, num) => {
              return memo + num
            })

            if (
              checkAmountItems >= parseFloat(decodeUnit(item.categoryAmount))
            ) {
              if (item?.valueDiscountType === 'Percentage') {
                const itemAmount = _.reduce(
                  filtercategoryInCart,
                  (memo, cartItem) => {
                    return (
                      memo +
                      cartItem.amount * parseFloat(decodeUnit(cartItem.price))
                    )
                  },
                  0
                )
                const calItemTotalDiscount = parseFloat(
                  (
                    (itemAmount * parseFloat(decodeUnit(item.valueDiscount))) /
                    100
                  ).toFixed(2)
                )
                return {
                  ...res,
                  sum: parseFloat(res.sum + calItemTotalDiscount * -1),
                  // sum: res.sum - calItemTotalDiscount,
                  discount: parseFloat(
                    res.discount + calItemTotalDiscount * -1
                  ),
                }
              } else if (item?.valueDiscountType === 'Bath') {
                return {
                  // sum: res.sum - parseFloat(decodeUnit(item.valueDiscount)),
                  ...res,
                  sum: parseFloat(
                    res.sum + parseFloat(decodeUnit(item.valueDiscount)) * -1
                  ),
                  discount: parseFloat(
                    res.discount +
                      parseFloat(decodeUnit(item.valueDiscount)) * -1
                  ),
                }
              } else {
                return {
                  ...res,
                  sum: parseFloat(res.sum + 0 * -1),
                  discount: parseFloat(res.discount + 0 * -1),
                }
              }
            } else {
              return { ...res }
            }
          } else {
            return { ...res }
          }
        } else {
          return { ...res }
        }
      } else {
        return { ...res }
      }
    },
    {
      sum: parseFloat(calAmount(carts)) || 0,
      discount: 0,
      items: [],
    }
  )

  return { ...calDiscount, discount: calDiscount?.discount }
}

export const rejectFreeItemCategory = (promotion, cartItems) => {
  let carts = cartItems
  const filterItemCartCategory = _.find(carts, (i) => {
    return (
      (i.category === promotion.category.id ||
        i.category === promotion.category.name) &&
      i.free
    )
  })
  carts = _.reduce(
    carts,
    (val, item) => {
      if (item?.id === filterItemCartCategory?.id && item.free) {
        if (item.amount === 1 || item.amount <= 0) {
          return val
        }
        return [
          ...val,
          {
            ...item,
            amount:
              item.amount -
              parseFloat(decodeUnit(promotion?.freeCategoryAmount)),
          },
        ]
      } else {
        return [...val, item]
      }
    },
    []
  )

  const filterInCart = _.filter(carts, (i) => {
    return !i.free
  })
  let inCart = _.pluck(filterInCart, 'id')
  if (_.contains(inCart, filterItemCartCategory.id)) {
    let items = carts?.map((item) => {
      if (item?.id === filterItemCartCategory?.id && !item.free) {
        return {
          ...item,
          amount:
            item.amount + parseFloat(decodeUnit(promotion?.freeCategoryAmount)),
        }
      } else {
        return { ...item }
      }
    })
    carts = items
  } else {
    const itemAmount = parseFloat(decodeUnit(promotion?.freeCategoryAmount))
    const dataToState = [
      ...carts,
      { ...filterItemCartCategory, amount: itemAmount, free: false },
    ]
    carts = dataToState
  }

  return carts
}

// export const rejectFreeItem = (promotion, cartItems) => {
//   let carts = cartItems
//   const filterCartItem = _.find(carts, (i) => {
//     return i.id === promotion.productFree.id && i.free
//   })
//   carts = _.reduce(
//     carts,
//     (val, item) => {
//       if (item?.id === filterCartItem?.id && item.free) {
//         if (item.amount === 1 || item.amount <= 0) {
//           return val
//         }
//         return [
//           ...val,
//           {
//             ...item,
//             amount:
//               item.amount -
//               parseFloat(decodeUnit(promotion?.freeProductAmount)),
//           },
//         ]
//       } else {
//         return [...val, item]
//       }
//     },
//     []
//   )

//   const filterInCart = _.filter(carts, (i) => {
//     return !i.free
//   })
//   let inCart = _.pluck(filterInCart, 'id')
//   if (_.contains(inCart, filterCartItem.id)) {
//     let items = carts?.map((item) => {
//       if (item?.id === filterCartItem?.id && !item.free) {
//         return {
//           ...item,
//           amount:
//             item.amount + parseFloat(decodeUnit(promotion?.freeProductAmount)),
//         }
//       } else {
//         return { ...item }
//       }
//     })
//     carts = items
//   } else {
//     const itemAmount = parseFloat(decodeUnit(promotion?.freeProductAmount))
//     const dataToState = [
//       ...carts,
//       { ...filterCartItem, amount: itemAmount, free: false },
//     ]
//     carts = dataToState
//   }

//   return carts
// }
