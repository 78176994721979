import { Flex } from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'

export default function AuthLayout({ children, cardTop, cardBottom }) {
  return (
    <Flex
      direction='column'
      alignSelf='center'
      justifySelf='center'
      overflow='hidden'
      w='100%'
      h={'100vh'}
      justifyContent='center'
      bg='#FFEBD7'
    >
      <Card h='100vh' w='100%' boxShadow='none' bg='#FFEBD7'>
        {children}
      </Card>
    </Flex>
  )
}
