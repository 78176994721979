import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Tabs,
  Text,
  HStack,
  Fade,
  Input,
  Avatar,
  FormControl,
  FormLabel,
  useColorModeValue,
  FormErrorMessage,
  useRadioGroup,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
  Image,
  Divider,
  VStack,
} from '@chakra-ui/react'
import { ArrowBackIcon, ChevronLeftIcon } from '@chakra-ui/icons'
import Card from 'Components/Horizons/card/Card'
import InputField from 'Components/Horizons/fields/InputField'
import TextField from 'Components/Horizons/fields/TextField'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { decodeUnit } from 'Utils/currency'

function ModalImage({ item, handleRemoveItem }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Center
        position='relative'
        overflow='hidden'
        height='auto'
        borderRadius='20px'
        box-shadow='0 3px 10px rgb(0 0 0 / 0.3)'
        cursor='pointer'
      >
        <Avatar
          src={item}
          w={'100px'}
          h={'100px'}
          borderRadius={'1em'}
          onClick={onOpen}
        />
        <Modal
          m='0px'
          p='0px'
          isOpen={isOpen}
          onClose={onClose}
          overflowY='scroll'
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <Image src={item} />
          </ModalContent>
        </Modal>
      </Center>
    </>
  )
}

export default function ViewExpensesForm({ id, data, ids }) {
  const [vat, setVat] = useState(0)
  const [grandTotal, setGrandTotal] = useState(0)
  const [file, setFile] = useState([])
  const [total, setTotal] = useState()
  const [vatType, setVatType] = useState()
  const [isFadePage, setIsFadePage] = useState(true)
  const Navigate = useNavigate()

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      detail: '',
      reason: '',
    },
  })

  function onSubmit(value) {}

  function CalVat({ total, vatType }) {
    if (vatType === 'รวมภาษี') {
      const cal = (total * 7) / 107
      const calGrand =
        parseFloat(parseFloat(total)?.toFixed(2)) - parseFloat(cal?.toFixed(2))

      setVat(`${cal?.toFixed(2)}`)
      setGrandTotal(`${parseFloat(calGrand)?.toFixed(2)}`)
      setValue(`totalVat`, cal?.toFixed(2))
      setValue(`grandTotal`, parseFloat(calGrand)?.toFixed(2))
    } else if (vatType === 'ไม่รวมภาษี') {
      const cal = (total * 7) / 100
      const calGrand =
        parseFloat(parseFloat(total)?.toFixed(2)) + parseFloat(cal?.toFixed(2))

      setVat(`${cal?.toFixed(2)}`)
      setGrandTotal(`${parseFloat(calGrand)?.toFixed(2)}`)
      setValue(`totalVat`, cal?.toFixed(2))
      setValue(`grandTotal`, parseFloat(calGrand)?.toFixed(2))
    } else if (vatType === 'ไม่มีภาษี') {
      const cal = total * 0
      const calGrand =
        parseFloat(parseFloat(total)?.toFixed(2)) + parseFloat(cal?.toFixed(2))

      setVat(`${cal?.toFixed(2)}`)
      setGrandTotal(`${parseFloat(calGrand)?.toFixed(2)}`)
      setValue(`totalVat`, cal?.toFixed(2))
      setValue(`grandTotal`, parseFloat(calGrand)?.toFixed(2))
    }
  }

  function handleChange(e) {
    setValue('vatType', e)
    setVatType(e)
    CalVat({ total: total, vatType: e })
  }

  const { getRootProps } = useRadioGroup({
    defaultValue: data?.vatType,
    onChange: handleChange,
    value: data?.vatType,
  })
  const group = getRootProps()

  useEffect(() => {
    if (data) {
      setValue('category', data?.category)
      setValue('Date', data?.Date)
      setValue('name', data?.name)
      setValue('detail', data?.detail)
      setValue('reason', data?.reason)
      setValue('vatType', data?.vatType)
      setValue('total', decodeUnit(data?.total))
      setValue('grandTotal', decodeUnit(data?.grandTotal))
      setValue('totalVat', decodeUnit(data?.totalVat))
      setValue('branch', data?.branch ? data?.branch : '')
      setFile(data?.image)
      setTotal(decodeUnit(data?.total))
      setVatType(data?.vatType)
      setVat(decodeUnit(data?.totalVat))
      setGrandTotal(decodeUnit(data?.grandTotal))
    }

    CalVat({ total: total, vatType: vatType })
    setIsFadePage(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  const options = ['รวมภาษี', 'ไม่รวมภาษี', 'ไม่มีภาษี']
  return (
    <>
      <Flex direction='column' minH='100vh' align='center' position='relative'>
        <Box
          h='25vh'
          bg='linear-gradient(135deg, #03A9F4 0%, #4318FF 100%)'
          position='absolute'
          w={{ sm: '100vw', md: '100%' }}
          borderRadius={{ sm: '0px', md: '30px' }}
        ></Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box w={{ sm: '100vw', md: '670px', lg: '850px' }} p='0px' mx='auto'>
            <Tabs
              variant='unstyled'
              mt='4rem'
              zIndex='0'
              display='flex'
              flexDirection='column'
            >
              <Card
                p={{ base: '15px', sm: '15px', md: '30px' }}
                px={{ base: '30px', sm: '30px', md: '30px' }}
                as={Fade}
                in={!isFadePage}
              >
                <Box
                  pb='0.3rem'
                  zIndex='overlay'
                  color='#4299E1'
                  onClick={() => Navigate('/expenses/list')}
                  w='max-content'
                >
                  <Text
                    display={{ sm: 'none', md: 'flex' }}
                    fontSize='18px'
                    cursor='pointer'
                  >
                    <ArrowBackIcon />
                  </Text>
                  <Text
                    display={{ sm: 'flex', md: 'none' }}
                    fontSize='24px'
                    cursor='pointer'
                  >
                    <ChevronLeftIcon />
                  </Text>
                </Box>
                <HStack>
                  <Box>
                    <Text fontSize={'xl'} color={'#4299E1'}>
                      ค่าใช้จ่าย
                    </Text>
                    <Text fontSize={'sm'} color={'blackAlpha.600'}>
                      {id ? id : ids}
                    </Text>
                  </Box>
                </HStack>
                <Box mt={'1em'}>
                  <FormControl
                    id={'total'}
                    isInvalid={!total}
                    key={'total'}
                    mb={'1em'}
                  >
                    <Flex direction='column' mb={'0px'}>
                      <FormLabel
                        display='flex'
                        ms='10px'
                        htmlFor={id}
                        fontSize='sm'
                        color={textColorPrimary}
                        fontWeight='bold'
                        _hover={{ cursor: 'pointer' }}
                      >
                        ยอดรวมทั้งหมด
                      </FormLabel>
                      <Input
                        readOnly
                        borderRadius={{
                          base: '10px',
                          sm: '10px',
                          md: '10px',
                          lg: '16px',
                        }}
                        value={total}
                        onChange={(e) => {
                          const value = e.target.value
                          setValue('total', value)
                          setTotal(value)
                          CalVat({ total: value, vatType: vatType })
                        }}
                        type={'number'}
                        id={id}
                        defaultValue=''
                        fontWeight='500'
                        variant='main'
                        _placeholder={{
                          fontWeight: '400',
                          color: 'secondaryGray.600',
                        }}
                        h='44px'
                        maxh='44px'
                      />
                    </Flex>
                    <FormErrorMessage>
                      {!total && 'กรุณากรอกยอดรวมทั้งหมด'}
                    </FormErrorMessage>
                  </FormControl>
                  <SimpleGrid columns={{ base: 1, md: 2 }} gap={'1em'}>
                    <HStack
                      w='full'
                      justifyContent={{ base: 'center', md: 'start' }}
                      {...group}
                      mt={{ sm: '0rem', md: '2rem' }}
                    >
                      {options?.map((value) => (
                        <Box
                          borderWidth='1px'
                          borderColor={
                            value === data?.vatType
                              ? '#BEE3F8'
                              : 'blackAlpha.300'
                          }
                          bgColor={
                            value === data?.vatType ? '#BEE3F8' : 'white'
                          }
                          fontSize={{ base: '14px', md: '16px', lg: '16px' }}
                          color={
                            value === data?.vatType ? '#2C5282' : '#1B2559'
                          }
                          textAlign={'center'}
                          borderRadius={{
                            base: '5px',
                            sm: '5px',
                            md: '5px',
                            lg: '10px',
                          }}
                          p={'.45em'}
                          px={'1em'}
                        >
                          {value}
                        </Box>
                      ))}
                    </HStack>
                    <FormControl>
                      <Flex direction='column' mb={'0px'}>
                        <FormLabel
                          display='flex'
                          ms='10px'
                          htmlFor={id}
                          fontSize='sm'
                          color={textColorPrimary}
                          fontWeight='bold'
                          _hover={{ cursor: 'pointer' }}
                        >
                          หมวดหมู่
                        </FormLabel>

                        <Input
                          readOnly
                          value={data?.category}
                          id={id}
                          borderRadius={{
                            base: '10px',
                            sm: '10px',
                            md: '10px',
                            lg: '16px',
                          }}
                          defaultValue=''
                          fontWeight='500'
                          variant='main'
                          _placeholder={{
                            fontWeight: '400',
                            color: 'secondaryGray.600',
                          }}
                          h='44px'
                          maxh='44px'
                        />
                      </Flex>
                    </FormControl>
                  </SimpleGrid>
                  <Box w={{ sm: '100%', md: '50%' }} mt='1rem'>
                    <HStack justifyContent={'space-between'}>
                      <Text fontSize={'sm'}>ภาษีมูลค่าเพิ่ม 7%</Text>
                      <Text fontSize={'sm'}>{vat > 1 ? vat : `0.00`}</Text>
                    </HStack>
                    <HStack justifyContent={'space-between'}>
                      <Text fontSize={'sm'}>ยอดไม่รวมภาษีมูลค่าเพิ่ม</Text>
                      <Text fontSize={'sm'} fontWeight={'extrabold'}>
                        {grandTotal > 1 ? grandTotal : `0.00`}
                      </Text>
                    </HStack>
                  </Box>
                </Box>
                <Divider mt={'20px'} mb={'20px'} />
                <Box>
                  <Text fontSize={'xl'} color={'#4299E1'}>
                    รายละเอียดค่าใช้จ่าย
                  </Text>
                  <Box>
                    <SimpleGrid
                      mt='0.5em'
                      columns={{ base: 1, md: 2 }}
                      gap={{ sm: '0rem', md: '1em', lg: '1em' }}
                    >
                      <InputField
                        control={control}
                        errors={errors}
                        type={'date'}
                        required={{
                          value: true,
                          message: 'กรุณากรอกวัน',
                        }}
                        readOnly
                        label={'วันที่'}
                        name={'Date'}
                      />
                      <InputField
                        control={control}
                        errors={errors}
                        type={'text'}
                        name={'name'}
                        label={'ชื่อผู้เบิกจ่าย/ชื่อโปรเจค'}
                        required={{
                          value: true,
                          message: 'กรุณากรอกชื่อผู้เบิกจ่าย/ชื่อโปรเจค',
                        }}
                        readOnly
                      />
                      <TextField
                        control={control}
                        errors={errors}
                        variant='no-effects'
                        name={'detail'}
                        label={'รายละเอียด'}
                        required={{
                          value: false,
                        }}
                        readOnly
                      />
                      <TextField
                        control={control}
                        errors={errors}
                        name={'reason'}
                        variant='no-effects'
                        label={'หมายเหตุ'}
                        required={{
                          value: false,
                        }}
                        readOnly
                      />
                    </SimpleGrid>
                  </Box>
                  <SimpleGrid
                    columns={{ base: 2, md: 5, lg: 7 }}
                    justifyItems={{ base: 'center', md: 'start' }}
                    gap={'1em'}
                  >
                    {file?.length > 0 &&
                      file?.map((item, index) => (
                        <>
                          <ModalImage key={index} item={item} />
                        </>
                      ))}
                  </SimpleGrid>
                </Box>
                <VStack
                  mt={{ base: '1.5rem', sm: '1.5rem', md: '1rem', lg: '1rem' }}
                  spacing='10px'
                  flexDirection='column'
                  alignItems='start'
                >
                  <HStack
                    textAlign='left'
                    flexDirection={{
                      base: 'column',
                      sm: 'column',
                      md: 'row',
                      lg: 'row',
                    }}
                    spacing={{
                      base: '0rem',
                      sm: '0rem',
                      md: '1.5rem',
                      lg: '1.5rem',
                    }}
                  >
                    <Text>Create by : {data?.createdBy?.name}</Text>
                    <Text pr='0.7rem'>
                      Date :{' '}
                      {data?.createdAt
                        ? moment(data?.createdAt?.toDate()).format(
                            'DD-MM-YYYY HH:mm:ss'
                          )
                        : '-'}
                    </Text>
                  </HStack>
                  <HStack
                    textAlign='left'
                    flexDirection={{
                      base: 'column',
                      sm: 'column',
                      md: 'row',
                      lg: 'row',
                    }}
                    spacing={{
                      base: '0rem',
                      sm: '0rem',
                      md: '1.5rem',
                      lg: '1.5rem',
                    }}
                  >
                    <Text>Modify by : {data?.updatedBy?.name} </Text>
                    <Text pr='0.7rem'>
                      Date :{' '}
                      {data?.updatedAt
                        ? moment(data?.updatedAt?.toDate()).format(
                            'DD-MM-YYYY HH:mm:ss'
                          )
                        : '-'}
                    </Text>
                  </HStack>
                </VStack>
                <Flex justify='end' mt='30px'>
                  <Button
                    variant='light'
                    fontSize='sm'
                    borderRadius='16px'
                    w={{ base: '128px', md: '148px' }}
                    h='46px'
                    onClick={() => Navigate('/expenses/list')}
                  >
                    ปิด
                  </Button>
                </Flex>
              </Card>
            </Tabs>
          </Box>
        </form>
      </Flex>
    </>
  )
}
