export const typeOfDiscount = (message) => {
  switch (message) {
    case 'free':
      return 'โปรโมชั่นส่วนลดแบบมีเงื่อนไข'
    case 'discount':
      return 'ส่วนลดเป็นเปอร์เซ็นต์, จำนวนเงิน'
    default:
      return message
  }
}
