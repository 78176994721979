import { Box } from '@chakra-ui/react'
import { FormSettingPaymentBank } from 'Components/form/Payment/formPaymentBank'
import { paymentThumbnail } from '../../Utils/paymentThumbnail'
function SettingPaymentAddBank({ user }) {
  return (
    <Box>
      <FormSettingPaymentBank paymentThumbnail={paymentThumbnail} user={user} />
    </Box>
  )
}

export default SettingPaymentAddBank
