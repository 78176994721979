import { Stack } from '@chakra-ui/react'
import { usePromotion } from 'Hooks/promotion'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ViewForm } from '../components/view'

export default function ViewDiscountProduct({ user }) {
  const { id } = useParams()
  const { getPromotionId } = usePromotion()
  const [data, setData] = useState()

  useEffect(() => {
    getPromotionId(id).then((res) => {
      setData(res)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  return (
    <Stack direction='column'>
      {data && id && <ViewForm data={data} id={id} />}
    </Stack>
  )
}
