import { Center, Flex, HStack, Icon, Text } from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import React from 'react'

function CardReport({ colorIcon, colorText, icon, value, label }) {
  return (
    <Card py='2rem' pl={{ base: '60px', sm: '60px', md: '0px' }} pr='0px'>
      <Center
        justifyContent={{
          base: 'flex-start',
          sm: 'flex-start',
          md: 'center',
        }}
      >
        <HStack spacing='1.5rem'>
          <Icon
            fontSize={{
              base: '5xl',
              sm: '5xl',
              md: '5xl',
              lg: '5xl',
              xl: '4xl',
              '2xl': '5xl',
            }}
            as={icon}
            color={colorIcon}
          />
          <Flex flexDirection='column' alignItems='flex-start' spacing='0px'>
            <Text fontSize='md' mt='0.5rem'>
              {label}
            </Text>
            <Text
              fontSize={{
                base: '2xl',
                sm: '2xl',
                md: '3xl',
                lg: '3xl',
                xl: '2xl',
                '2xl': '3xl',
              }}
              mt='-0.5rem'
              color={colorText}
            >
              {new Intl.NumberFormat('th-TH').format(parseFloat(value))}
            </Text>
          </Flex>
        </HStack>
      </Center>
    </Card>
  )
}

export { CardReport }
