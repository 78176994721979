import _ from 'underscore'
import { decodeUnit } from 'Utils'

// คำนวน ราคาสินค้า ทั้งหมด (total)
// ถ้ามีส่วนลดจะคำนวนส่วนลดด้วย
export const calAmount = (items, discount) => {
  let subTotal = _.map(items, (item) => {
    return parseFloat(item.amount * decodeUnit(item.price))
  }).reduce((memo, num) => {
    return memo + num
  }, 0)
  if (discount) {
    if (discount.discountType === 'Bath') {
      return parseFloat(subTotal - decodeUnit(discount.discount)).toFixed(2)
    } else if (discount.discountType === 'Percentage') {
      return parseFloat(
        subTotal - (subTotal * decodeUnit(discount.discount)) / 100
      ).toFixed(2)
    }
  } else {
    return parseFloat(subTotal).toFixed(2)
  }
}

// คำนวนเฉพาะส่วนลดว่าลดไปกี่บาท
export const calDiscount = (items, discount) => {
  let subTotal = _.map(items, (item) => {
    return parseFloat(item.amount * decodeUnit(item.price))
  }).reduce((memo, num) => {
    return memo + num
  }, 0)
  if (discount) {
    if (discount.discountType === 'Bath') {
      return parseFloat(decodeUnit(discount.discount)).toFixed(2)
    } else if (discount.discountType === 'Percentage') {
      return parseFloat(
        (subTotal * decodeUnit(discount.discount)) / 100
      ).toFixed(2)
    }
  } else {
    return parseFloat(subTotal).toFixed(2)
  }
}

export const calGrandTotal = (items, discount, vat) => {
  const fnDiscount = calAmount(items, discount)
  let subTotal = _.map(items, (item) => {
    // if (item.promotion) {
    //   return parseFloat(calPromotion(item))
    // } else {
    return parseFloat(item.amount * decodeUnit(item.price))
    // }
  }).reduce((memo, num) => {
    return memo + num
  }, 0)
  let withVat =
    discount > 0 ? ((subTotal - fnDiscount) * 7) / 100 : (subTotal * 7) / 100

  return subTotal - fnDiscount + (vat ? withVat : 0)
}

// คำนวณการทอนเงิน
export const calReceived = (total, priceReceived) => {
  if (priceReceived >= total) {
    return parseFloat(priceReceived - total).toFixed(2)
  }
  return 0
}

// คำนวน ราคาสินค้า ทั้งหมด (total)
// ถ้ามีโปรโมชั่นจะคำนวนโปรโมชั่นด้วย
export const calPromotionAmount = (items) => {
  let subTotal = _.map(items, (item) => {
    if (item?.promotion) {
      return parseFloat(calPromotion(item))
    } else {
      return 0
    }
  }).reduce((memo, num) => {
    return memo + num
  }, 0)
  return parseFloat(subTotal).toFixed(2)
}

//คำนวณโปรโมชั่น
export const calPromotion = (item) => {
  //โปรโมชั่นชิ้น
  if (!item) {
    return 0
  }
  if (item.promotion.typeDiscount === 'piece') {
    if (item.promotion.discountType === 'Percentage') {
      return parseFloat(
        ((decodeUnit(item.price) * decodeUnit(item.promotion.discount)) / 100) *
          item.amount
      ).toFixed(2)
    } else if (item.promotion.discountType === 'Bath') {
      return parseFloat(
        item.amount * decodeUnit(item.promotion.discount)
      ).toFixed(2)
    }
    //โปรโมชั่นจากยอดขาย
  } else if (item.promotion.typeDiscount === 'receipt') {
    if (item.promotion.discountType === 'Percentage') {
      return parseFloat(
        (item.amount *
          decodeUnit(item.price) *
          decodeUnit(item.promotion.discount)) /
          100
      ).toFixed(2)
    } else if (item.promotion.discountType === 'Bath') {
      return parseFloat(decodeUnit(item.promotion.discount)).toFixed(2)
    }
  }
}
