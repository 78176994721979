import { Stack } from '@chakra-ui/react'
import { FormCategory } from 'Components/form/formCategory'
import { useCategory } from 'Hooks/category'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

function EditCategory() {
  const { id } = useParams()
  const { getCategory } = useCategory()
  const [data, setData] = useState()
  const fetchData = useCallback(async () => {
    await getCategory(id).then((res) => {
      setData(res)
    })
  }, [getCategory, id])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Stack direction='column'>
      <FormCategory id={id} data={data} />
    </Stack>
  )
}

export default EditCategory
