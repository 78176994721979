/**
 * @interface
 * @param {string|number|float} balance
 * @param {boolean} decimal
 * @param {boolean} symbol
 * @return {string} ex: 10.00 | ฿10.00
 */

export const currency = (balance, decimal, symbol) => {
  if (decimal && symbol) {
    return new Intl.NumberFormat('th-th', {
      style: 'currency',
      currency: 'THB',
      minimumFractionDigits: decimal,
    }).format(parseFloat(balance))
  } else if (decimal) {
    return new Intl.NumberFormat('th-TH', {
      minimumFractionDigits: decimal,
    }).format(parseFloat(balance))
  } else if (!decimal) {
    return new Intl.NumberFormat('th-TH').format(parseFloat(balance))
  } else if (symbol) {
    return new Intl.NumberFormat('th-TH', {
      style: 'currency',
      currency: 'THB',
    }).format(parseFloat(balance))
  } else if (!symbol) {
    return new Intl.NumberFormat('th-TH').format(parseFloat(balance))
  } else {
    return null
  }
}

/**
 * @interface
 * @param {string|number|float} balance
 * @return {number} ex: 10.00 | ฿10.00
 */
// แปลง unit เงิน 1.23 -> 123 โดยการ * 100
export const encodeUnit = (balance) => {
  return parseInt(balance * 100)
}

/**
 * @interface
 * @param {string|number|float} balance
 * @return {number} ex: 10.00 | ฿10.00
 */
// แปลง unit เงิน 123 -> 1.23 โดยการ / 100
export const decodeUnit = (balance) => {
  return parseFloat(balance / 100)
}
