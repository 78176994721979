import _ from 'underscore'
import { decodeUnit } from './currency'

export const calDeposit = (items) => {
  return _.reduce(
    _.pluck(
      _.filter(items, (method) => method.method === 'deposit'),
      'stock'
    ),
    (value, num) => parseFloat(value) + parseFloat(decodeUnit(num)),
    0
  )
}

export const calWithdrawal = (items) => {
  return _.reduce(
    _.pluck(
      _.filter(items, (method) => method.method === 'withdrawal'),
      'stock'
    ),
    (value, num) => parseFloat(value) + parseFloat(decodeUnit(num)),
    0
  )
}

export const calStockTotal = async (items) => {
  return new Promise(async (resolve) => {
    const deposit = await calDeposit(items).toFixed(2)
    const withdrawal = await calWithdrawal(items).toFixed(2)
    const total = parseFloat(deposit) - parseFloat(withdrawal)
    resolve(total.toFixed(2))
  })
}

export const calStockGetTotal = async (items) => {
  return new Promise(async (resolve) => {
    const deposit = await calDeposit(items).toFixed(2)
    const withdrawal = await calWithdrawal(items).toFixed(2)
    const total = parseFloat(deposit) - parseFloat(withdrawal)
    resolve({
      total: total.toFixed(2),
      deposit: deposit,
      withdrawal: withdrawal,
    })
  })
}

export const calPromotionDecodeUnitAmountMultipleItems = (items) => {
  let subTotal = _.map(items, (item) => {
    if (item?.promotion) {
      return parseFloat(calPromotionDecodeUnitAmountPerItem(item))
    } else {
      return 0
    }
  }).reduce((memo, num) => {
    return memo + num
  }, 0)
  return parseFloat(subTotal)
}

export const calPromotionDecodeUnitAmountPerItem = (item) => {
  //โปรโมชั่นชิ้น
  if (!item) {
    return 0
  }
  if (item.promotion.typeDiscount === 'piece') {
    if (item.promotion.discountType === 'Percentage') {
      return parseFloat(
        ((decodeUnit(item.price) * decodeUnit(item.promotion.discount)) / 100) *
          decodeUnit(item.amount)
      ).toFixed(2)
    } else if (item.promotion.discountType === 'Bath') {
      return parseFloat(
        decodeUnit(item.amount) * decodeUnit(item.promotion.discount)
      ).toFixed(2)
    }
    //โปรโมชั่นจากยอดขาย
  } else if (item.promotion.typeDiscount === 'receipt') {
    if (item.promotion.discountType === 'Percentage') {
      return parseFloat(
        (decodeUnit(item.amount) *
          decodeUnit(item.price) *
          decodeUnit(item.promotion.discount)) /
          100
      ).toFixed(2)
    } else if (item.promotion.discountType === 'Bath') {
      return parseFloat(decodeUnit(item.promotion.discount)).toFixed(2)
    }
  }
}

// export function calCommission(item) {
//   if (item?.typeCommission === 'Bath') {
//     const commission = decodeUnit(item?.commission)

//     if (item?.commissionCalType === 'receipt') {
//       return commission
//     } else if (item?.commissionCalType === 'piece') {
//       const amount = decodeUnit(item?.amount)
//       return commission * amount
//     }
//   } else if (item?.typeCommission === 'Percentage') {
//     const price = decodeUnit(item?.price)
//     const amount = decodeUnit(item?.amount)
//     const commission = decodeUnit(item?.commission)

//     if (item?.commissionCalType === 'receipt') {
//       const total = price * amount
//       return total * (commission / 100)
//     } else if (item?.commissionCalType === 'piece') {
//       const calCom = price * (commission / 100)
//       return calCom * amount
//     }
//   }
// }
