import { db } from 'Config/firebase'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import moment from 'moment'

// import { calPromotionDecodeUnitAmountPerItem } from 'Utils/calStock'
import { decodeUnit } from 'Utils/currency'

const _ = require('underscore')

const prevDate = new Date()
prevDate.setHours(0)
prevDate.setMinutes(0)
prevDate.setSeconds(0)
prevDate.setMilliseconds(0)

const nextDate = new Date()
nextDate.setDate(nextDate.getDate() + 1)
nextDate.setHours(0)
nextDate.setMinutes(0)
nextDate.setSeconds(0)
nextDate.setMilliseconds(0)

const prevMonthDate = new Date()
prevMonthDate.setDate(1)
prevMonthDate.setHours(0)
prevMonthDate.setMinutes(0)
prevMonthDate.setSeconds(0)
prevMonthDate.setMilliseconds(0)

const nextMonth = new Date()
nextMonth.setMonth(nextMonth.getMonth() + 1)
nextMonth.setDate(0)
nextMonth.setHours(23)
nextMonth.setMinutes(59)
nextMonth.setSeconds(59)
nextMonth.setMilliseconds(59)

export async function QueryReportWarehouse({
  month,
  startDate,
  endDate,
  between,
  prevMonth,
  currentDate,
  currentMonth,
  user,
  startDateTime,
  endDateTime,
  dateTime,
}) {
  const start = new Date(startDate)
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  start.setMilliseconds(0)

  const dateEnd = new Date(endDate)
  dateEnd.setDate(dateEnd.getDate() + 1)
  dateEnd.setHours(0)
  dateEnd.setMinutes(0)
  dateEnd.setSeconds(0)
  dateEnd.setMilliseconds(0)

  const startMonth = new Date(month)
  startMonth.setDate(1)
  startMonth.setHours(0)
  startMonth.setMinutes(0)
  startMonth.setSeconds(0)
  startMonth.setMilliseconds(0)

  const endMonth = new Date(month)
  endMonth.setMonth(endMonth.getMonth() + 1)
  endMonth.setDate(0)
  endMonth.setHours(23)
  endMonth.setMinutes(59)
  endMonth.setSeconds(59)
  endMonth.setMilliseconds(59)

  const qInventory = prevMonth
    ? query(
        collection(db, 'Inventory'),
        where('branch', '==', user?.branchSelect?.id),
        where('createdAt', '>=', startMonth),
        where('createdAt', '<=', endMonth),
        orderBy('createdAt', 'desc')
      )
    : between
    ? query(
        collection(db, 'Inventory'),
        where('branch', '==', user?.branchSelect?.id),
        where('createdAt', '>=', start),
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )
    : currentMonth
    ? query(
        collection(db, 'Inventory'),
        where('branch', '==', user?.branchSelect?.id),
        where('createdAt', '>=', prevMonthDate),
        where('createdAt', '<=', nextMonth),
        orderBy('createdAt', 'desc')
      )
    : dateTime
    ? query(
        collection(db, 'Inventory'),
        where('branch', '==', user?.branchSelect?.id),
        where('createdAt', '>=', startDateTime),
        where('createdAt', '<=', endDateTime),
        orderBy('createdAt', 'desc')
      )
    : currentDate &&
      query(
        collection(db, 'Inventory'),
        where('branch', '==', user?.branchSelect?.id),
        where('createdAt', '>=', prevDate),
        where('createdAt', '<=', nextDate),
        orderBy('createdAt', 'desc')
      )

  const dataValue = await (
    await getDocs(qInventory)
  ).docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
    productId: doc.data().id,
  }))
  const res = dataValue.map((item) => ({
    ...item,
    idProduct: item.sku,
    productName: `${item.name} ${item?.nameOption ? item?.nameOption : ''}`,
    description: item.description,
    amount:
      item.method === 'withdrawal'
        ? decodeUnit(item.stock) * -1
        : decodeUnit(item.stock),
    method: item.method,
    createdAt: moment(item.createdAt.toDate()).format('MM/DD/YYYY HH:mm:ss'),
  }))
  return res
  // return Object.keys(AllBranch).map((item) =>
  //   AllBranch[item].map((itm) => ({
  //     branch: item,
  //     idProduct: itm?.idProduct,
  //     productName: itm?.productName,
  //     description: itm?.description,
  //     date: itm?.createdAt,
  //     method: itm?.method,
  //     amount: parseFloat(itm?.amount),
  //   }))
  // )
}

export async function QueryReportRefund({ select, startDate, endDate }) {
  const start = new Date(startDate)
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  start.setMilliseconds(0)

  const dateEnd = new Date(endDate)
  dateEnd.setDate(dateEnd.getDate() + 1)
  dateEnd.setHours(0)
  dateEnd.setMinutes(0)
  dateEnd.setSeconds(0)
  dateEnd.setMilliseconds(0)

  const qOrder = select
    ? startDate
      ? startDate && endDate
        ? query(
            collection(db, 'Orders'),
            where('status', '==', 'refund'),
            where('branch.id', '==', select),
            where('createdAt', '>=', start),
            where('createdAt', '<=', dateEnd),
            orderBy('createdAt', 'desc')
          )
        : query(
            collection(db, 'Orders'),
            where('status', '==', 'refund'),
            where('branch.id', '==', select),
            where('createdAt', '>=', start),
            orderBy('createdAt', 'desc')
          )
      : endDate
      ? query(
          collection(db, 'Orders'),
          where('status', '==', 'refund'),
          where('branch.id', '==', select),
          where('createdAt', '<=', dateEnd),
          orderBy('createdAt', 'desc')
        )
      : query(
          collection(db, 'Orders'),
          where('status', '==', 'refund'),
          where('branch.id', '==', select),
          where('createdAt', '>=', prevDate),
          where('createdAt', '<=', nextDate),
          orderBy('createdAt', 'desc')
        )
    : startDate
    ? startDate && endDate
      ? query(
          collection(db, 'Orders'),
          where('status', '==', 'refund'),
          where('createdAt', '>=', start),
          where('createdAt', '<=', dateEnd),
          orderBy('createdAt', 'desc')
        )
      : query(
          collection(db, 'Orders'),
          where('status', '==', 'refund'),
          where('createdAt', '>=', start),
          orderBy('createdAt', 'desc')
        )
    : endDate
    ? query(
        collection(db, 'Orders'),
        where('status', '==', 'refund'),
        where('createdAt', '<=', endDate),
        orderBy('createdAt', 'desc')
      )
    : query(
        collection(db, 'Orders'),
        where('status', '==', 'refund'),
        where('createdAt', '>=', prevDate),
        where('createdAt', '<=', nextDate),
        orderBy('createdAt', 'desc')
      )
  const res = await (
    await getDocs(qOrder)
  ).docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
    branch: doc.data()?.branch?.name,
    discountBalance: decodeUnit(doc.data().discountBalance),
    total: decodeUnit(doc.data().total),
    grandTotal: decodeUnit(doc.data().grandTotal),
  }))

  const AllBranch = _.groupBy(res, 'branch')

  return Object.keys(AllBranch).map((item) => {
    const totalDiscount = _.reduce(
      AllBranch[item],
      (v, it) =>
        v +
        _.reduce(
          it.items,
          (vi, ti) =>
            ti?.promotion ? vi + parseFloat(ti.promotion?.discount) : vi + 0,
          0
        ),
      0
    )
    return {
      branch: item,
      createdAt: null,
      orderId: null,
      items: null,
      discount: totalDiscount,
      grandTotal: _.reduce(AllBranch[item], (v, i) => v + i.grandTotal, 0),
      subRows: AllBranch[item].map((itm) => ({
        createdAt: itm.createdAt
          .toDate()
          .toLocaleString('en-US', { hour12: false }),
        date: itm.createdAt.toDate(),
        orderId: itm.orderId,
        items: itm.items,
        discount: _.reduce(
          itm.items,
          (v, i) =>
            i.promotion ? v + parseFloat(i.promotion?.discount) : v + 0,
          0
        ),
        grandTotal: itm.grandTotal,
      })),
    }
  })
}

export async function QueryReportExpensesExpenses({
  month,
  startDate,
  endDate,
  between,
  prevMonth,
  currentDate,
  currentMonth,
  user,
  startDateTime,
  endDateTime,
  dateTime,
  select,
}) {
  const start = new Date(startDate)
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  start.setMilliseconds(0)

  const dateEnd = new Date(endDate)
  dateEnd.setDate(dateEnd.getDate() + 1)
  dateEnd.setHours(0)
  dateEnd.setMinutes(0)
  dateEnd.setSeconds(0)
  dateEnd.setMilliseconds(0)

  const startMonth = new Date(month)
  startMonth.setDate(1)
  startMonth.setHours(0)
  startMonth.setMinutes(0)
  startMonth.setSeconds(0)
  startMonth.setMilliseconds(0)

  const endMonth = new Date(month)
  endMonth.setMonth(endMonth.getMonth() + 1)
  endMonth.setDate(0)
  endMonth.setHours(23)
  endMonth.setMinutes(59)
  endMonth.setSeconds(59)
  endMonth.setMilliseconds(59)

  const qExpenses = prevMonth
    ? query(
        collection(db, 'Expenses'),
        // where('branch', '==', user?.branchSelect?.id),
        // where('createdAt', '>=', startMonth),
        // where('createdAt', '<=', endMonth),
        // where('status', '<=', 'approvel'),
        orderBy('createdAt', 'desc')
      )
    : between
    ? query(
        collection(db, 'Expenses'),
        // where('branch', '==', user?.branchSelect?.id),
        // where('createdAt', '>=', start),
        // where('createdAt', '<=', dateEnd),
        // where('status', '<=', 'approvel'),
        orderBy('createdAt', 'desc')
      )
    : currentMonth
    ? query(
        collection(db, 'Expenses'),
        // where('branch', '==', user?.branchSelect?.id),
        // where('createdAt', '>=', prevMonthDate),
        // where('createdAt', '<=', nextMonth),
        // where('status', '<=', 'approvel'),
        orderBy('createdAt', 'desc')
      )
    : dateTime
    ? query(
        collection(db, 'Expenses'),
        // where('branch', '==', user?.branchSelect?.id),
        // where('createdAt', '>=', startDateTime),
        // where('createdAt', '<=', endDateTime),
        // where('status', '<=', 'approvel'),
        orderBy('createdAt', 'desc')
      )
    : currentDate &&
      query(
        collection(db, 'Expenses'),
        // where('branch', '==', user?.branchSelect?.id),
        // where('createdAt', '>=', prevDate),
        // where('createdAt', '<=', nextDate),
        // where('status', '<=', 'approvel'),
        orderBy('createdAt', 'desc')
      )

  const res = await (
    await getDocs(qExpenses)
  ).docs.map((doc) => ({
    ...doc.data(),
    id: doc?.id,
    total: decodeUnit(doc.data()?.total),
    grandTotal: decodeUnit(doc.data()?.grandTotal),
    totalVat: decodeUnit(doc.data()?.totalVat),
    createdAt: doc
      .data()
      ?.createdAt?.toDate()
      .toLocaleString('en-US', { hour12: false }),
  }))

  const results = select ? res?.filter((f) => f.category === select) : res

  return results
}

export async function QueryReportExpensesBranch({
  slBranch,
  slCategory,
  startDate,
  endDate,
  Branch,
}) {
  const start = new Date(startDate)
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  start.setMilliseconds(0)

  const dateEnd = new Date(endDate)
  dateEnd.setDate(dateEnd.getDate() + 1)
  dateEnd.setHours(0)
  dateEnd.setMinutes(0)
  dateEnd.setSeconds(0)
  dateEnd.setMilliseconds(0)

  const qExpenses = slBranch
    ? slBranch && slCategory
      ? startDate
        ? startDate && endDate
          ? query(
              collection(db, 'Expenses'),
              where('status', '==', 'approvel'),
              where('branch', '==', slBranch),
              where('category', '==', slCategory),
              where('createdAt', '>=', start),
              where('createdAt', '<=', dateEnd),
              orderBy('createdAt', 'desc')
            )
          : query(
              collection(db, 'Expenses'),
              where('status', '==', 'approvel'),
              where('branch', '==', slBranch),
              where('category', '==', slCategory),
              where('createdAt', '>=', start),
              orderBy('createdAt', 'desc')
            )
        : endDate
        ? query(
            collection(db, 'Expenses'),
            where('status', '==', 'approvel'),
            where('branch', '==', slBranch),
            where('category', '==', slCategory),
            where('createdAt', '<=', dateEnd),
            orderBy('createdAt', 'desc')
          )
        : query(
            collection(db, 'Expenses'),
            where('status', '==', 'approvel'),
            where('branch', '==', slBranch),
            where('category', '==', slCategory),
            where('createdAt', '>=', prevDate),
            where('createdAt', '<=', nextDate),
            orderBy('createdAt', 'desc')
          )
      : startDate
      ? startDate && endDate
        ? query(
            collection(db, 'Expenses'),
            where('status', '==', 'approvel'),
            where('branch', '==', slBranch),
            where('createdAt', '>=', start),
            where('createdAt', '<=', dateEnd),
            orderBy('createdAt', 'desc')
          )
        : query(
            collection(db, 'Expenses'),
            where('status', '==', 'approvel'),
            where('branch', '==', slBranch),
            where('createdAt', '>=', start),
            orderBy('createdAt', 'desc')
          )
      : endDate
      ? query(
          collection(db, 'Expenses'),
          where('status', '==', 'approvel'),
          where('branch', '==', slBranch),
          where('createdAt', '<=', dateEnd),
          orderBy('createdAt', 'desc')
        )
      : query(
          collection(db, 'Expenses'),
          where('status', '==', 'approvel'),
          where('branch', '==', slBranch),
          where('createdAt', '>=', prevDate),
          where('createdAt', '<=', nextDate),
          orderBy('createdAt', 'desc')
        )
    : slCategory
    ? startDate
      ? startDate && endDate
        ? query(
            collection(db, 'Expenses'),
            where('status', '==', 'approvel'),
            where('category', '==', slCategory),
            where('createdAt', '>=', start),
            where('createdAt', '<=', dateEnd),
            orderBy('createdAt', 'desc')
          )
        : query(
            collection(db, 'Expenses'),
            where('status', '==', 'approvel'),
            where('category', '==', slCategory),
            where('createdAt', '>=', start),
            orderBy('createdAt', 'desc')
          )
      : endDate
      ? query(
          collection(db, 'Expenses'),
          where('status', '==', 'approvel'),
          where('category', '==', slCategory),
          where('createdAt', '<=', dateEnd),
          orderBy('createdAt', 'desc')
        )
      : query(
          collection(db, 'Expenses'),
          where('status', '==', 'approvel'),
          where('category', '==', slCategory),
          where('createdAt', '>=', prevDate),
          where('createdAt', '<=', nextDate),
          orderBy('createdAt', 'desc')
        )
    : startDate
    ? startDate && endDate
      ? query(
          collection(db, 'Expenses'),
          where('status', '==', 'approvel'),
          where('createdAt', '>=', start),
          where('createdAt', '<=', dateEnd),
          orderBy('createdAt', 'desc')
        )
      : query(
          collection(db, 'Expenses'),
          where('status', '==', 'approvel'),
          where('createdAt', '>=', start),
          orderBy('createdAt', 'desc')
        )
    : endDate
    ? query(
        collection(db, 'Expenses'),
        where('status', '==', 'approvel'),
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )
    : query(
        collection(db, 'Expenses'),
        where('status', '==', 'approvel'),
        where('createdAt', '>=', prevDate),
        where('createdAt', '<=', nextDate),
        orderBy('createdAt', 'desc')
      )

  const res = await (
    await getDocs(qExpenses)
  ).docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
    total: decodeUnit(doc.data().total),
    grandTotal: decodeUnit(doc.data().grandTotal),
    totalVat: decodeUnit(doc.data().totalVat),
    createdAt: doc
      .data()
      ?.createdAt.toDate()
      .toLocaleString('en-US', { hour12: false }),
  }))

  const AllBranch = _.groupBy(
    res.map((item) => ({
      ...item,
      branch: Branch.find((itm) => itm.id === item.branch).name,
    })),
    'branch'
  )

  const filterBranch = Object.keys(AllBranch).map((item) => ({
    branch: item,
    createdAt: null,
    expId: null,
    name: null,
    grandTotal: _.reduce(
      AllBranch[item],
      (v, i) => v + parseFloat(i.grandTotal),
      0
    ),
    subRows: AllBranch[item].map((itm) => ({
      createdAt: itm.createdAt,
      expId: itm.id,
      name: itm.name,
      category: itm.category,
      grandTotal: parseFloat(itm.grandTotal),
    })),
  }))

  return filterBranch
}
