import React from 'react'
import { Box } from '@chakra-ui/react'

function PosMultiplePaymentCount(props) {
  return (
    <>
      <Box h='20rem' w='full' bg='black' color='white'>
        test
      </Box>
    </>
  )
}

export default PosMultiplePaymentCount
