import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import InputField from 'Components/Horizons/fields/InputField'
import SelectField from 'Components/Horizons/fields/SelectField'
import { useOrder } from 'Hooks'
import { currency, decodeUnit } from 'Utils/currency'
import { posType } from 'Utils/json'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { BsCheckCircle } from 'react-icons/bs'
import './loading.css'

function PosSuccessCashSinglePayment(props) {
  const { addOrderCustomer } = useOrder()
  const { orderSuccessPayment, mobile, confirmLoading, posHistory } = props
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    register,
  } = useForm({
    defaultValues: {
      tel: '',
      note: '',
      customerType: 'local',
    },
  })
  const addCustomer = async () => {
    if (getValues('tel') || getValues('note') || getValues('customerType')) {
      await addOrderCustomer({
        docId: posHistory.docId,
        customer: getValues('tel'),
        note: getValues('note'),
        customerType: getValues('customerType'),
      }).then(() => {
        setValue('tel', '')
        setValue('note', '')
        setValue('customerType', 'local')
      })
    }
  }

  return (
    <Box overflow={'auto'}>
      {!confirmLoading ? (
        <>
          <Center
            h={{ base: '50vh', sm: '50vh', md: '35rem', lg: '35rem' }}
            mt={{ base: '40px', sm: '70px', md: '30px' }}
            flexDirection='column'
            w='full'
          >
            <Box mt='-2rem'>
              <Center flexDirection='column' color='#01B574'>
                <Text fontSize='32px'>
                  <BsCheckCircle />
                </Text>
                <Text fontSize='20px' mt='1.5rem'>
                  {posHistory.payment === 'payLater'
                    ? 'การขายสำเร็จ'
                    : 'การชำระเงินเสร็จสมบูรณ์'}
                </Text>
              </Center>
              <Center flexDirection='column'>
                <Text mt='1.5' color='#767262' fontSize='16px'>
                  {posHistory.payment === 'payLater'
                    ? 'นัดชำระวันที่ ('
                    : 'ยอดที่ได้รับ ('}
                  {posHistory.payment === 'bank'
                    ? 'ธนาคาร'
                    : posHistory.payment === 'cash'
                    ? 'เงินสด'
                    : posHistory.payment === 'payLater'
                    ? `${moment(posHistory?.dueDate).format('DD/MM/YYYY')}`
                    : 'อื่นๆ'}
                  &#41;
                </Text>
                <Text fontSize='16px'>
                  {currency(decodeUnit(posHistory?.grandTotal), 2, true)}
                </Text>
              </Center>
            </Box>

            <Center position={'relative'} mt={'20px'} w='80%'>
              <VStack w='full' spacing={5}>
                <Box w='full'>
                  <Text fontSize={'12px'} ml={'10px'}>
                    ประเภทผู้ซื้อ
                  </Text>
                  <Controller
                    name={'customerType'}
                    control={control}
                    render={({ field }) => (
                      <FormControl isInvalid={errors['customerType']}>
                        <Flex direction='column'>
                          <SelectField
                            control={control}
                            errors={errors}
                            id={'customerType'}
                            defaultValue={'local'}
                            name={'customerType'}
                            options={posType?.default}
                            mb='0px'
                          />
                        </Flex>
                        <FormErrorMessage>
                          {errors['customerType'] &&
                            errors['customerType']?.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </Box>

                <Box w='full'>
                  {/* <Text fontSize={'12px'} ml={'10px'}> */}
                  <Text fontSize={'12px'}>สำหรับสะสมคะแนนสมาชิก</Text>
                  <Controller
                    name='tel'
                    control={control}
                    rules={{
                      validate: {
                        pattern: {
                          value: /[0-9]/,
                          message: 'กรุณากรอกตัวเลขเท่านั้น',
                        },
                      },
                      maxLength: {
                        value: 10,
                        message: 'เบอร์โทรศัพท์ 10 หลักเท่านั้น',
                      },
                      minLength: {
                        value: 10,
                        message: 'เบอร์โทรศัพท์ 10 หลักเท่านั้น',
                      },
                    }}
                    render={({ field }) => (
                      <FormControl isInvalid={errors['tel']}>
                        <Flex direction='column'>
                          <InputField
                            control={control}
                            errors={errors}
                            mb='0px'
                            id='tel'
                            placeholder={'หมายเลขสมาชิก'}
                            name='tel'
                            type='text'
                            maxLength={10}
                            pattern={{
                              value: /[0-9]/,
                              message: 'กรุณากรอกตัวเลขเท่านั้น',
                            }}
                          />
                        </Flex>
                        <FormErrorMessage>
                          {errors['tel'] && errors['tel']?.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </Box>

                <Box w='full'>
                  <Textarea
                    borderRadius='20px'
                    mb='0px'
                    id='note'
                    placeholder={'Note เช่น: คุณข้าวต้ม'}
                    name='note'
                    type='textarea'
                    {...register('note')}
                  />
                </Box>
              </VStack>
            </Center>

            <Center
              position='fixed'
              display={{
                base: 'flex',
                sm: 'flex',
                md: 'none',
                lg: 'none',
                xl: 'none',
                '2xl': 'none',
              }}
              bottom='0px'
              w='100%'
            >
              <Box p='0.3rem' w='100%'>
                <Button
                  w='100%'
                  py='1.7rem'
                  colorScheme={'btn'}
                  borderRadius='5px'
                  onClick={() => {
                    orderSuccessPayment(mobile)
                    addCustomer()
                  }}
                >
                  เสร็จสิ้น
                </Button>
              </Box>
            </Center>
          </Center>
          <Center
            display={{
              base: 'none',
              sm: 'none',
              md: 'flex',
              lg: 'flex',
              xl: 'flex',
              '2xl': 'flex',
            }}
            bottom='0px'
            w='100%'
          >
            <Box p='0.3rem' w='100%'>
              <Button
                w='100%'
                colorScheme={'btn'}
                py='1.7rem'
                borderRadius='5px'
                onClick={() => {
                  orderSuccessPayment()
                  addCustomer()
                }}
              >
                เสร็จสิ้น
              </Button>
            </Box>
          </Center>
        </>
      ) : (
        <>
          <Center
            h={{ base: '70vh', sm: '70vh', md: '50vh', lg: '50vh' }}
            flexDirection='column'
            w='100%'
          >
            <Center flexDirection='column'>
              <Text pb='1rem' fontSize='xl' color='loading.100'>
                รอดำเนินการ
              </Text>
              <Box className='loading' color='loading.100'></Box>
            </Center>
          </Center>
        </>
      )}
    </Box>
  )
}

export default PosSuccessCashSinglePayment
