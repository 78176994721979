import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { db } from 'Config/firebase'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { search } from 'ss-search'
import { BtnModal } from './btnmodal'

export function InfoModalCategory({
  discount,
  handleChange,
  children,
  value,
  label,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [onSearch, setOnSearch] = useState('')
  const [category, setCategory] = useState([])
  // const [data, setData] = useState(null)

  function handleClickChange(e) {
    handleChange(e, category)
    onClose()
  }
  useEffect(() => {
    const qProduct = query(
      collection(db, 'Category'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(qProduct, (snapShot) => {
      let productValue = []
      snapShot.docs.forEach((doc) => {
        return productValue.push({
          ...doc.data(),
          id: doc.id,
        })
      })
      const searchText = onSearch
      const searchKey = ['name']

      const results = search(productValue, searchKey, searchText)
      setCategory(results)
    })
    return () => {
      unsubscribe()
    }
  }, [onSearch])

  return (
    <Grid templateColumns='repeat(2, 1fr)' gap={6} mt='1rem'>
      <GridItem h='100%'>
        <Center h='100%'>{children}</Center>
      </GridItem>
      <GridItem>
        <Center>
          <BtnModal
            color='form.500'
            onClick={() => {
              setOnSearch('')
              onOpen()
            }}
            label={label}
          />
        </Center>
      </GridItem>
      <Modal isOpen={isOpen} onClose={onClose} size='auto'>
        <ModalOverlay />
        <ModalContent w={{ base: '22rem', sm: '22rem', md: '30rem' }}>
          <ModalHeader color='form.500'>
            เลือกหมวดหมู่สินค้าจัดโปรโมชั่น
            <Box w='100%'>
              <Flex
                spacing='1rem'
                mt='20px'
                direction={{
                  base: 'column',
                  sm: 'column',
                  md: 'column',
                  lg: 'row',
                }}
                align={{ base: 'start', md: 'center' }}
                justifyContent='space-between'
                w='100%'
                zIndex='base'
              >
                <Flex w='100%'>
                  <InputGroup w='100%'>
                    <InputLeftElement
                      children={
                        <IconButton
                          bg='inherit'
                          borderRadius='inherit'
                          _hover='none'
                          _active={{
                            bg: 'inherit',
                            transform: 'none',
                            borderColor: 'transparent',
                          }}
                          _focus={{
                            boxShadow: 'none',
                          }}
                          icon={
                            <SearchIcon color={'gray.700'} w='15px' h='15px' />
                          }
                        ></IconButton>
                      }
                    />
                    <Input
                      variant='search'
                      fontSize='sm'
                      bg={'secondaryGray.300'}
                      color={'gray.700'}
                      fontWeight='500'
                      _placeholder={{
                        color: 'gray.400',
                        fontSize: '14px',
                        opacity: 1,
                      }}
                      borderRadius={'30px'}
                      placeholder={'Search...'}
                      onChange={(e) => {
                        setOnSearch(e.target.value)
                      }}
                    />
                  </InputGroup>
                </Flex>
              </Flex>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            overflowY='auto'
            maxH={{ base: '25rem', sm: '25rem', md: '35rem' }}
          >
            <Box h={{ base: '25rem', sm: '25rem', md: '35rem' }}>
              <RadioGroup value={value} onChange={handleClickChange}>
                <VStack alignItems='flex-start' w='100%' direction='row'>
                  {category.map((item, index) => (
                    <Flex w='100%' borderBottom='1px' borderColor='#E8E9E9'>
                      <Radio
                        colorScheme='green'
                        value={item.id}
                        key={index}
                        w='100%'
                        h='5rem'
                      >
                        {item.name}
                      </Radio>
                    </Flex>
                  ))}
                </VStack>
              </RadioGroup>
            </Box>
          </ModalBody>

          <ModalFooter w='100%'></ModalFooter>
        </ModalContent>
      </Modal>
    </Grid>
  )
}
