import {
  Box,
  HStack,
  Stack,
  Text,
  VStack,
  Center,
  useColorModeValue,
  InputGroup,
  InputLeftElement,
  IconButton,
  Input,
  Spinner,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import Card from 'Components/Horizons/card/Card'
import { SearchIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'
import _ from 'underscore'
import { search } from 'ss-search'
import { OrderCard } from './Components/orderCard'
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { db } from 'Config/firebase'
import moment from 'moment'
// import { useOrder } from 'Hooks'
import { decodeUnit } from 'Utils'

function PosHistory({ user }) {
  const [isLoadingOrderLastWeek, setisLoadingOrderLastWeek] = useState(true)
  const navigate = useNavigate()
  const [SearchResults, setSearchResults] = useState({})
  const [orders, setOrders] = useState([])
  const searchIconColor = useColorModeValue('gray.700', 'white')
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900')
  const inputText = useColorModeValue('gray.700', 'gray.100')
  const [optionsSearch, setOptionsSearch] = useState('')
  // const { getOrderByRefMultiple } = useOrder()

  const getOrderByRef = useCallback(async (orderList) => {
    // const filterItems = _.filter(orderList, (i) => {
    //   return i.status !== 'refund'
    // })
    // const pluckId = _.pluck(filterItems, 'id')
    // if (pluckId) {
    // const getData = await getOrderByRefMultiple(pluckId)
    const filterPaidOrder = await _.filter(orderList, (i) => {
      return i.status === 'paid'
    })
    const filterRefundrder = await _.filter(orderList, (i) => {
      return i.status === 'refund'
    })
    const mapData = await Promise.all(
      await _.map(filterPaidOrder, async (item) => {
        const filterItemsData = await _.filter(filterRefundrder, (i) => {
          return i.ref === item.id
        })
        const pluckItems = _.pluck(item.items, 'amount')
        const getItemAmount = await _.reduce(
          pluckItems,
          (memo, num) => {
            return memo + decodeUnit(parseFloat(num))
          },
          0
        )
        if (filterItemsData.length > 0) {
          const pluckItemAmount = await _.map(filterItemsData, (i) => {
            const pluckItems = _.pluck(i.items, 'amount')
            return pluckItems
          })
          const pluckAmount = _.flatten(pluckItemAmount)
          const getAmount = await _.reduce(
            pluckAmount,
            (memo, num) => {
              return memo + decodeUnit(parseFloat(num))
            },
            0
          )
          return {
            ...item,
            refundAmount: getAmount,
            totalAmount: getItemAmount,
          }
        } else {
          return { ...item, refundAmount: 0, totalAmount: getItemAmount }
        }
      }),
      []
    )
    const sortItem = _.sortBy(
      [...mapData, ...filterRefundrder],
      'createdAt'
    ).reverse()
    setOrders(sortItem)
    setisLoadingOrderLastWeek(false)
    // } else {
    //   setOrders([])
    //   setisLoadingOrderLastWeek(false)
    // }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const orderRef = query(
      collection(db, 'Orders'),
      where('createdAt', '>=', new Date(moment().startOf('day'))),
      orderBy('createdAt', 'desc')
    )

    const unsubscribe = onSnapshot(orderRef, async (onSnap) => {
      let data = []
      onSnap.docs.forEach((doc) => {
        return data.push({
          id: doc.id,
          ...doc.data(),
          date: moment(doc?.data().createdAt?.toDate()).format('MM/DD/YYYY'),
        })
      })
      getOrderByRef(data)
    })

    return () => {
      unsubscribe()
    }
  }, [getOrderByRef])

  useEffect(() => {
    const searchText = optionsSearch
    const searchKey = ['createdBy.name', 'orderId']

    if (orders?.length > 0) {
      const results = search(orders, searchKey, searchText)
      if (results?.length > 0) {
        const ordersGroup = _.groupBy(results, 'date')
        return setSearchResults(ordersGroup)
      } else {
        return setSearchResults([])
      }
    } else {
      return setSearchResults([])
    }
    //eslint-disable-next-line
  }, [optionsSearch, orders])

  return (
    <Stack>
      <Box>
        <Card>
          <VStack spacing={'20px'} w='full'>
            <Box
              w='full'
              px={{ base: '0em', sm: '0em', md: '1em' }}
              pt='.25em'
              borderRadius={'1.2em'}
              bg={'#ffff'}
            >
              <Box pb={{ base: '0.5rem', sm: '0.5rem', md: '1.5rem' }}>
                <HStack justifyContent={'space-between'} alignItems='end'>
                  <InputGroup
                    h='44px'
                    w={{ base: '100%', sm: '100%', md: '390px', lg: '390px' }}
                    borderRadius='16px'
                    zIndex='base'
                  >
                    <InputLeftElement
                      children={
                        <IconButton
                          bg='inherit'
                          borderRadius='inherit'
                          _hover='none'
                          _active={{
                            bg: 'inherit',
                            transform: 'none',
                            borderColor: 'transparent',
                          }}
                          _focus={{
                            boxShadow: 'none',
                          }}
                          icon={
                            <SearchIcon
                              color={searchIconColor}
                              w='15px'
                              h='15px'
                            />
                          }
                        ></IconButton>
                      }
                    />
                    <Input
                      variant='search'
                      fontSize='sm'
                      bg={inputBg}
                      color={inputText}
                      fontWeight='500'
                      _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                      borderRadius={{
                        base: '10px',
                        sm: '10px',
                        md: '10px',
                        lg: '30px',
                      }}
                      placeholder={'Search...'}
                      onChange={(e) => {
                        let keyword = e.target.value
                        setOptionsSearch(keyword)
                      }}
                    />
                  </InputGroup>
                </HStack>
              </Box>
              <Box>
                <Text fontSize='22px' fontWeight='bold' color='#1B2559'>
                  รายการขาย
                </Text>
              </Box>
              <HStack justifyContent={'space-between'}>
                <Box>
                  <Text
                    fontSize={{ sm: '14px', md: '16px' }}
                    display={{ base: 'flex', sm: 'flex', md: 'none' }}
                    color={'#4299E1'}
                  >
                    ล่าสุด
                  </Text>
                </Box>
                {user?.role === 'Administrator' && (
                  <Box>
                    <Text
                      fontSize={{ sm: '14px', md: '16px' }}
                      color={'pos.500'}
                      onClick={() => {
                        navigate('/pos/history/all')
                      }}
                      cursor='pointer'
                    >
                      ดูประวัติเพิ่มเติม
                    </Text>
                  </Box>
                )}
              </HStack>
              {!isLoadingOrderLastWeek ? (
                _.keys(SearchResults).length > 0 ? (
                  _.keys(SearchResults).map((item, i) => {
                    const itemsData = SearchResults[item]
                    return (
                      <>
                        <Box key={i} mt={'20px'}>
                          <Text>วันที่: {item}</Text>
                        </Box>
                        <Box>
                          {itemsData?.map((inItem, indexInItem) => {
                            return (
                              <OrderCard
                                inItem={inItem}
                                indexInItem={indexInItem}
                                key={indexInItem}
                              />
                            )
                          })}
                        </Box>
                      </>
                    )
                  })
                ) : (
                  <>
                    <Center>
                      <Text>ไม่มีรายการขาย</Text>
                    </Center>
                  </>
                )
              ) : (
                <Center>
                  <Spinner />
                </Center>
              )}
            </Box>
          </VStack>
        </Card>
      </Box>
    </Stack>
  )
}

export default PosHistory
