import { Flex, Box, Text, Center, Spinner } from '@chakra-ui/react'
import Chart from 'react-apexcharts'

const _ = require('underscore')
const accounting = require('accounting')
export default function OverallRevenue(props) {
  const { allData, dataOnCall, dataToCallIsLoading, Branch } = props
  const branch = _.groupBy(allData, 'branch')

  // ยอดขาย
  const totalNetSales =
    allData?.length > 0
      ? Object.keys(branch).map((item) => ({
          branch: Branch.find((branch) => branch.id === item)?.name,
          item: _.reduce(branch[item], (v, i) => v + i.grandTotal, 0),
        }))
      : dataOnCall?.totalNetSales
      ? dataOnCall?.totalNetSales
      : []

  const branchName = _.pluck(
    allData?.length > 0 ? totalNetSales : dataOnCall?.totalNetSales,
    'branch'
  )

  const chartOptions = {
    legend: {
      show: false,
    },
    colors: ['#60101C'],
    fontFamily: 'Prompt',
    chart: {
      id: 'basic-bar',
      animations: {
        speed: 200,
      },
      fontFamily: 'Prompt',
    },

    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    xaxis: {
      categories: branchName,
      colors: '#60101C',
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return accounting.formatNumber(value, 2)
        },
      },
    },
  }

  return (
    <Box w='full'>
      <Flex p='1rem' pb='0rem' w='full' textAlign='start'>
        <Text as='b'>ยอดขายสุทธิ</Text>
      </Flex>
      <Box w='full' mt='auto' h='30rem' zIndex='base'>
        {!dataToCallIsLoading ? (
          <Chart
            options={chartOptions}
            series={[
              {
                name: '',
                data: totalNetSales.map((itm) => itm.item),
              },
            ]}
            type='bar'
            width='100%'
            height='100%'
          />
        ) : (
          <Center>
            <Spinner color='#60101C' size={'lg'} />
          </Center>
        )}
      </Box>
    </Box>
  )
}
