import { Box, Text } from '@chakra-ui/react'
import { currency } from 'Utils'

export function CardDashboard({ label, balance, colorBalance, colorText }) {
  return (
    <>
      <Box pl='1.5rem'>
        <Text
          fontWeight='700'
          fontSize={{
            md: '14px',
            lg: '16px',
            xl: '14px',
            '2xl': '16px',
          }}
          color={colorText ? colorText : 'black'}
          as='b'
        >
          {label ? label : ''}
        </Text>

        <Text
          fontSize={{
            md: '20px',
            lg: '22px',
            xl: '22px',
            '2xl': '24px',
          }}
          color={colorBalance ? colorBalance : 'black'}
        >
          {currency(balance ? balance : 0, 2, true)}
        </Text>
      </Box>
    </>
  )
}
