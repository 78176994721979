import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Select,
} from '@chakra-ui/react'
import { useState } from 'react'
import ReactDatePicker from 'react-datepicker'

export default function SearchDateMonthSelect({
  month,
  setMonth,
  setPrevMonth,
  setCurrentMonth,
}) {
  const [selectValue, setSelectValue] = useState('currentMonth')
  const [showMonth, setShowMonth] = useState(false)
  return (
    <Flex flexDirection='column' w='100%'>
      <Select
        bg='#F4F7FE'
        borderRadius='16px'
        cursor='pointer'
        border='0px'
        variant={'no-effects'}
        _focus={{ border: '0px solid #F4F7FE' }}
        w='100%'
        value={selectValue}
        onChange={(e) => {
          const value = e.target.value
          setSelectValue(value)
          if (value === 'previousMonth') {
            setShowMonth(true)
            setPrevMonth(true)
            setCurrentMonth(false)
          } else if (value === 'currentMonth') {
            setShowMonth(false)
            setPrevMonth(false)
            setCurrentMonth(true)
          }
        }}
      >
        {/* <option value='currentDate'>วันนี้</option> */}
        <option value='currentMonth'>เดือนนี้</option>
        <option value='previousMonth'>เดือนก่อน</option>
        {/* <option value='between'>วันที่</option> */}
      </Select>
      {showMonth && (
        <InputGroup mt='0.5rem'>
          <Input
            bg='#F4F7FE'
            borderRadius='16px'
            cursor='pointer'
            border='0px'
            variant={'no-effects'}
            _focus={{ border: '0px solid #F4F7FE' }}
            w='full'
            textAlign='center'
            dateFormat='MM/yyyy'
            as={ReactDatePicker}
            selected={month}
            onChange={(date) => setMonth(date)}
            placeholderText={' '}
            showMonthYearPicker
          />
          <InputRightElement
            zIndex='base'
            children={<ChevronDownIcon zIndex='base' color='#8F9BB' />}
          />
        </InputGroup>
      )}
    </Flex>
  )
}
