import { Button, HStack, Text } from '@chakra-ui/react'

export function AmountCategory({ label, handleAdd, handleRemove, disabled }) {
  return (
    <HStack spacing='20px'>
      <Button
        border='1px'
        borderColor='#E0E5F2'
        w='2.5rem'
        borderRadius='100%'
        disabled={disabled}
        onClick={handleRemove}
      >
        <Text fontSize='26px' color='#767262'>
          -
        </Text>
      </Button>
      <Text fontSize='20px'>{label}</Text>
      <Button
        border='1px'
        borderColor='#E0E5F2'
        w='2.5rem'
        borderRadius='100%'
        disabled={disabled}
        onClick={handleAdd}
      >
        <Text fontSize='26px' color='#767262'>
          +
        </Text>
      </Button>
    </HStack>
  )
}
