import { useState, useContext, createContext } from 'react'

const AppContext = createContext()

const ContextProvider = ({ children }) => {
  const [error, setError] = useState()
  const [message, setMessage] = useState()
  const [MerchantId, setMerchantId] = useState()
  const [inventoryBranchList, setInventoryBranchList] = useState([])
  const [user, setUser] = useState()
  const [mainLoading, setMainLoading] = useState(true)

  const { Provider } = AppContext

  const value = {
    user,
    setUser,
    error,
    setError,
    message,
    setMessage,
    MerchantId,
    setMerchantId,
    inventoryBranchList,
    setInventoryBranchList,
    mainLoading,
    setMainLoading,
  }

  return <Provider value={value}>{children}</Provider>
}

const useAppContext = () => useContext(AppContext)

export { ContextProvider, AppContext, useAppContext }
