import { ChevronLeftIcon } from '@chakra-ui/icons'
import {
  Center,
  Flex,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import ButtonResponsive from 'Components/Button/btn'
import Card from 'Components/Horizons/card/Card'

function First({ setFirst, discount, setDiscount, isSubmit }) {
  return (
    <>
      {/* <Box display={second ? 'none' : 'block'}> */}
      <Center>
        <Card
          padding={{
            base: '10px 0px 30px 0px',
            sm: '10px 0px 30px 0px',
            md: '10px 20px 30px 20px',
          }}
          w={{ base: '100%', sm: '100%', md: '30rem' }}
          boxShadow={{
            base: 'none',
            sm: 'none',
            md: '14px 17px 40px 4px rgb(112 144 176 / 8%)',
          }}
        >
          <HStack>
            <ChevronLeftIcon
              cursor='pointer'
              color='form.500'
              fontSize={'2xl'}
              onClick={() => setFirst(false)}
            />
            <Text
              fontSize={{ base: 'md', sm: 'md', md: 'lg' }}
              fontWeight='bold'
            >
              มูลค่าส่วนลด
            </Text>
          </HStack>{' '}
          <Text color='table.100' mt='1rem'>
            โปรดระบุส่วนลด
          </Text>
          <VStack alignItems='flex-start' w='100%' mt='1rem'>
            <Text>ชื่อโปรโมชั่น</Text>
            <Input
              variant='main'
              value={discount.discountName || null}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                e.preventDefault()
                let value = e.target.value
                setDiscount({ ...discount, discountName: value })
              }}
            />
          </VStack>
          <VStack mt='1rem' justifyContent='start' alignItems='flex-start'>
            <RadioGroup
              onChange={(e) => {
                setDiscount({ ...discount, valueDiscountType: e })
              }}
              value={discount?.valueDiscountType}
            >
              <Stack direction='row'>
                <Radio colorScheme='green' value='Percentage'>
                  เปอร์เซ็นต์
                </Radio>
                <Radio colorScheme='green' value='Bath'>
                  บาท
                </Radio>
              </Stack>
            </RadioGroup>
            <Input
              variant={'main'}
              type={'number'}
              step={'any'}
              min={0}
              value={discount?.valueDiscount || null}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                e.preventDefault()
                let value = parseFloat(parseFloat(e.target.value)?.toFixed(2))
                setDiscount({ ...discount, valueDiscount: value })
              }}
            />
          </VStack>
          <Center w='100%' mt='1rem'>
            <VStack spacing='25px' w='100%'>
              <VStack alignItems='flex-start' w='100%'>
                <Text>รายละเอียดโปรโมชั่น</Text>
                <Textarea
                  border='1px solid #e0e5f2'
                  borderRadius={{
                    base: '10px',
                    sm: '10px',
                    md: '10px',
                    lg: '16px',
                  }}
                  variant={'no-effects'}
                  value={discount.discountDetail || null}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    e.preventDefault()
                    let value = e.target.value
                    setDiscount({ ...discount, discountDetail: value })
                  }}
                />
              </VStack>
              {/* <VStack alignItems='flex-start' w='100%'>
                <HStack>
                  <Text>ตั้งค่ารหัสส่วนลด</Text>
                  <Switch
                    defaultChecked={discount.discountCodeStatus || false}
                    colorScheme='teal'
                    onChange={(e) => {
                      let value = e.target.checked
                      setDiscount({ ...discount, discountCodeStatus: value })
                    }}
                  />
                </HStack>
                <Input
                  variant='main'
                  value={discount.discountCode || null}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    e.preventDefault()
                    let value = e.target.value
                    setDiscount({ ...discount, discountCode: value })
                  }}
                />
              </VStack> */}
              <Flex justifyContent='space-between' w='100%'>
                <Text>เปิด-ปิด </Text>
                <Switch
                  defaultChecked={discount.status || false}
                  colorScheme='teal'
                  onChange={(e) => {
                    let value = e.target.checked
                    setDiscount({ ...discount, status: value })
                  }}
                />
              </Flex>
            </VStack>
          </Center>
          <Flex
            mt={{ base: '0rem', sm: '0rem', md: '1.5rem' }}
            w='100%'
            justifyContent='flex-end'
          >
            <ButtonResponsive
              disabled={discount?.discountName ? false : true}
              label={'บันทึก'}
              type={'submit'}
              isLoading={isSubmit}
              loadingText={'กำลังบันทึก...'}
            />
          </Flex>
          {/* <Flex w='100%' justifyContent='flex-end'>
              <ButtonResponsive
                disabled={
                  discount.valueDiscountType && discount.valueDiscount
                    ? false
                    : true
                }
                label={'ถัดไป'}
                onClick={() => {
                  setSecond(true)
                }}
              />
            </Flex> */}
        </Card>
      </Center>
      {/* </Box> */}
      {/* {second && ( */}
      {/* <Second
          setSecond={setSecond}
          discount={discount}
          setDiscount={setDiscount}
          isSubmit={isSubmit}
        /> */}
      {/* )} */}
    </>
  )
}

export { First }
