// Chakra imports
import { Button, Flex, Input, useColorModeValue } from '@chakra-ui/react'
import DropZone from 'react-dropzone'
// import { useDropzone } from "react-dropzone";
// Assets
import React from 'react'
import Resizer from 'react-image-file-resizer'

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      'base64'
    )
  })

function DropzoneOption({ content, setOption, option, i, name, ...rest }) {
  const bg = useColorModeValue('gray.100', 'navy.700')
  const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100')
  async function handleDrop(acceptedFiles) {
    const file = acceptedFiles[0]
    const image = await resizeFile(file)
    setOption(
      option.map((item, index) =>
        index === i ? { ...item, img: image } : item
      )
    )
  }
  return (
    <>
      <DropZone
        onDrop={handleDrop}
        accept={{ 'image/*': ['.jpeg', '.jpg', '.png'] }}
      >
        {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => {
          const additionalClass = isDragAccept
            ? 'accept'
            : isDragReject
            ? 'reject'
            : ''

          return (
            <Flex
              align='center'
              justify='center'
              bg={bg}
              border='1px dashed'
              borderColor={borderColor}
              borderRadius='16px'
              w='100%'
              maxW='100%'
              h='max-content'
              minH='130px'
              cursor='pointer'
              {...getRootProps({ className: `dropzone ${additionalClass}` })}
              pt='80px !important'
              pb='105px !important'
              {...rest}
            >
              <Input variant='main' {...getInputProps()} />
              <Button variant='no-effects'>{content}</Button>
            </Flex>
          )
        }}
      </DropZone>
    </>
  )
}

export default DropzoneOption
