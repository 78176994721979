import React, { useEffect, useState } from 'react'
import {
  HStack,
  Flex,
  Text,
  Box,
  Button,
  Heading,
  Stack,
  Center,
  Select,
} from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import CustomTable from 'Components/Horizons/table/customTable'
import { currency } from 'Utils'
import SearchDateMonth from 'Components/Search/dateMonth'
import { useCategory, useInventory } from 'Hooks'
import _ from 'underscore'
import CSVDownloadForm from 'Components/csvDownload'

function WarehouseBranchExpense({ user }) {
  const [endDate, setEndDate] = useState(null)
  const [data, setData] = useState([])
  const [onSearch, setOnSearch] = useState(false)
  const { getInventoryInOutTotal } = useInventory()
  const [startDateTime, setStartDateTime] = useState(null)
  const [endDateTime, setEndDateTime] = useState(null)
  const [currentDate, setCurrentDate] = useState(true)
  const [currentMonth, setCurrentMonth] = useState(false)
  const [between, setBetween] = useState(false)
  const [prevMonth, setPrevMonth] = useState(false)
  const [month, setMonth] = useState(new Date())
  const [searchCategory, setSearchCategory] = useState('')
  const { category, getCategoryAll } = useCategory()
  const [csvData, setCSVData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    getCategoryAll()
  }, [getCategoryAll])

  const csvHeaders = [
    { label: 'รหัสสินค้า', key: 'sku' },
    { label: 'ชื่อสินค้า', key: 'name' },
    { label: 'ชื่อรองสินค้า', key: 'nameOption' },
    { label: 'สินค้าเข้า', key: 'deposit' },
    { label: 'สินค้าออก', key: 'withdrawal' },
    { label: 'คงคลัง', key: 'total' },
  ]

  async function onSubmitFilter() {
    setIsLoading(true)
    const getStock = await getInventoryInOutTotal({
      branch: user.branchSelect?.id,
      month: month,
      between: between,
      prevMonth: prevMonth,
      currentDate: currentDate,
      currentMonth: currentMonth,
      endDate: endDate,
    })

    if (getStock.data.length > 0) {
      if (searchCategory !== '' || searchCategory) {
        const findCategoryById = _.find(category, (item) => {
          return item.id === searchCategory
        })
        const productData = await _.filter(getStock.data, (item) => {
          return (
            item.category === findCategoryById.id ||
            item.category === findCategoryById.name
          )
        })
        if (productData.length > 0) {
          const sortProductSku = await _.sortBy(productData, 'sku')
          const dataToCSV = _.map(sortProductSku, (items) => {
            return {
              sku: items.sku,
              name: items.name,
              nameOption: items.nameOption ? items.nameOption : '-',
              category: findCategoryById?.name ? findCategoryById?.name : '',
              deposit: currency(parseFloat(items.total.deposit), 2),
              withdrawal: currency(parseFloat(items.total.withdrawal), 2),
              total: currency(parseFloat(items.total.total), 2),
            }
          })
          setCSVData(dataToCSV)
          setData(sortProductSku.length > 0 ? sortProductSku : [])
        } else {
          setData([])
        }
      } else {
        if (getStock.data.length > 0) {
          const filterUndefined = await _.filter(getStock.data, (product) => {
            return product.id
          })
          const sortProductSku = await _.sortBy(filterUndefined, 'sku')
          const dataToCSV = _.map(sortProductSku, (items) => {
            const findCategoryById = _.find(category, (item) => {
              return item.id === items.category || item.name === items.category
            })
            return {
              sku: items.sku,
              name: items.name,
              nameOption: items.nameOption ? items.nameOption : '-',
              category: findCategoryById?.name ? findCategoryById?.name : '',
              deposit: currency(parseFloat(items.total.deposit), 2),
              withdrawal: currency(parseFloat(items.total.withdrawal), 2),
              total: currency(parseFloat(items.total.total), 2),
            }
          })
          setCSVData(dataToCSV)
          setData(sortProductSku.length > 0 ? sortProductSku : [])
        } else {
          setData([])
        }
      }
    } else {
      setData([])
    }
    setIsLoading(false)
    return setOnSearch(true)
  }

  const columnsData = [
    {
      Header: 'รหัสสินค้า',
      accessor: 'sku',
      extra: (data, row) => <Center key={row}>{row.sku}</Center>,
    },
    {
      Header: 'ชื่อสินค้า',
      accessor: 'nameOption',
      extra: (data, row) => (
        <Center key={row}>
          {row.name} {row.nameOption ? row.nameOption : ''}
        </Center>
      ),
    },
    {
      Header: 'จำนวนที่เข้าคลัง',
      accessor: 'deposit',
      extra: (data, row) => (
        <Center key={row}>{currency(parseInt(row.total?.deposit), 2)}</Center>
      ),
    },
    {
      Header: 'จำนวนที่ออกคลัง',
      accessor: 'withdrawal',
      extra: (data, row) => (
        <Center key={row}>
          <Text color={parseInt(row.total?.withdrawal) < 0 ? 'red' : 'black'}>
            {currency(parseInt(row.total?.withdrawal), 2)}
          </Text>
        </Center>
      ),
    },
    {
      Header: 'คงคลัง',
      accessor: 'total',
      extra: (data, row) => (
        <Center key={row}>
          <Text color={parseInt(row.total?.total) < 0 ? 'red' : 'black'}>
            {currency(parseInt(row.total?.total), 2)}
          </Text>
        </Center>
      ),
    },
  ]

  return (
    <>
      <HStack
        my='1em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='1vw'
        flexDirection={{ sm: 'column', md: 'row' }}
        justifyContent='space-between'
      >
        <HStack
          w={'full'}
          spacing={{ base: '0px', sm: '0px', md: '10px' }}
          p={{ sm: '0px', md: '20px 20px 30px 20px' }}
          justifyContent='flex-start'
          flexDirection={{
            base: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          }}
        >
          <Box w={'full'}>
            <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
              <Text color='#8F9BBA'>ช่วงเวลา :</Text>
            </Box>
            <SearchDateMonth
              endDate={endDate}
              setEndDate={setEndDate}
              month={month}
              setMonth={setMonth}
              setBetween={setBetween}
              setPrevMonth={setPrevMonth}
              setCurrentDate={setCurrentDate}
              setCurrentMonth={setCurrentMonth}
              setStartDateTime={setStartDateTime}
              setEndDateTime={setEndDateTime}
              startDateTime={startDateTime}
              endDateTime={endDateTime}
            />
          </Box>
          <Box w={'full'}>
            <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
              <Text color='#8F9BBA'>หมวดหมู่สินค้า :</Text>
            </Box>
            <Select
              pt={{ base: '15px', sm: '15px', md: '0rem' }}
              w={{ base: '100%', md: '250px' }}
              h='44px'
              maxh='44px'
              variant='search'
              border={{
                base: '1px solid #E8E9E9',
                sm: '1px solid #E8E9E9',
                md: '0px',
              }}
              bg={{ base: 'white', sm: 'white', md: 'secondaryGray.300' }}
              fontSize='sm'
              color={'gray.700'}
              fontWeight='500'
              _placeholder={{
                color: 'gray.400',
                fontSize: '14px',
                opacity: 1,
              }}
              borderRadius={{
                base: '10px',
                sm: '10px',
                md: '30px',
                lg: '30px',
              }}
              onChange={(e) => {
                setSearchCategory(e.target.value)
              }}
            >
              <option value={''}>หมวดหมู่ทั้งหมด</option>
              {category?.map((categoryItem) => {
                return (
                  <option value={categoryItem.id}>{categoryItem.name}</option>
                )
              })}
            </Select>
          </Box>
        </HStack>

        <Flex
          flexDirection='column'
          w={{ sm: 'full', md: '10rem', lg: '10rem', xl: '10rem' }}
          alignItems='end'
          pt={{ sm: '1rem', md: '0rem' }}
        >
          <Box
            textAlign='start'
            display={{ sm: 'none', md: 'flex' }}
            p='0.5rem'
            pl='1rem'
            pt='0rem'
          >
            <Text color='#8F9BBA'>&nbsp;</Text>
          </Box>

          <Button
            h='44px'
            maxh='44px'
            w={{ sm: '10rem', md: 'full' }}
            colorScheme='btn'
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            onClick={() => onSubmitFilter()}
            disabled={isLoading}
            isLoading={isLoading}
          >
            <Text>
              <SearchIcon />
            </Text>
            <Text>แสดงรายงาน</Text>
          </Button>
        </Flex>
      </HStack>
      <Flex
        flexDirection='column'
        p={{ sm: '10px', md: '20px 20px 30px 20px' }}
        bg='white'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
        borderRadius='30px'
      >
        <Stack spacing={{ base: '0em', md: '4em' }}>
          <HStack
            justifyContent={'space-between'}
            w='full'
            display={{ sm: 'block', md: 'flex' }}
          >
            <Stack>
              <Heading
                fontFamily={'Prompt'}
                color='#2B3674'
                fontSize={{ base: '24px', md: '30px' }}
              >
                รายงานคลังสินค้า
              </Heading>
              <Box>
                <HStack>
                  <Text color='#2B3674'>ณ วันที่ :</Text>
                  <Text color='#2B3674'>
                    {moment(new Date().getTime()).format('MM/DD/YYYY')}
                  </Text>
                </HStack>
              </Box>
            </Stack>
            <HStack justifyContent={{ sm: 'start', md: 'end' }}>
              <CSVDownloadForm
                mt={{ sm: '10px', md: '0px' }}
                buttonTitle={'ดาวน์โหลดไฟล์รายงานคลังสินค้า'}
                data={csvData}
                filename={`${user?.branchSelect?.name}_warehouseReport`}
                headers={csvHeaders}
              />
            </HStack>
          </HStack>
        </Stack>
        <CustomTable
          columnsData={columnsData}
          tableData={onSearch ? data : []}
          closeSearch={true}
          pages={[10, 20, 30, 40, 50, 100, 200]}
        />
      </Flex>
    </>
  )
}

export default WarehouseBranchExpense

// function SwitchColorText(status) {
//   switch (status) {
//     case 'withdrawal':
//       return 'blue.600'
//     case 'deposit':
//       return '#01B574'
//     default:
//       return 'black'
//   }
// }
