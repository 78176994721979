import {
  Center,
  HStack,
  Flex,
  Box,
  Text,
  Select,
  Button,
  Stack,
  Heading,
} from '@chakra-ui/react'
import { useState } from 'react'
import CustomTable from 'Components/Horizons/table/customTable'
import { SearchIcon } from '@chakra-ui/icons'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { useExpenses } from 'Hooks'
import { useQueryReportExpenses } from './functions/query'
import SearchDate from 'Components/Search/date'
import CSVDownloadForm from 'Components/csvDownload'
import { currency } from 'Utils'

const accounting = require('accounting')
const _ = require('underscore')

function Price(props) {
  const { grandTotal } = props
  return (
    <HStack justifyContent='end' spacing='1em'>
      <Stack>
        <Box>
          <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
            รวมรายจ่าย
          </Text>
        </Box>
        <Box>
          <Text fontSize={{ base: '12px', md: '16px' }} color='report.500'>
            {accounting.formatNumber(grandTotal, 2)}
          </Text>
        </Box>
      </Stack>
    </HStack>
  )
}

function ReportBranchExpense({ user }) {
  const { Category } = useExpenses()
  const { QueryExpenses } = useQueryReportExpenses()
  const [select, setSelect] = useState(null)
  const [data, setData] = useState([])
  const [onSearch, setOnSearch] = useState(false)
  const [dataCSV, setDataCSV] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [startDateTime, setStartDateTime] = useState(null)
  const [endDateTime, setEndDateTime] = useState(null)
  const [dateTime, setDateTime] = useState(false)
  const [currentDate, setCurrentDate] = useState(true)
  const [currentMonth, setCurrentMonth] = useState(false)
  const [between, setBetween] = useState(false)
  const [prevMonth, setPrevMonth] = useState(false)
  const [month, setMonth] = useState(new Date())

  const headers = [
    { label: 'วันที่', key: 'date' },
    { label: 'เลขที่ใบจ่าย', key: 'id' },
    { label: 'หมวดหมู่', key: 'category' },
    { label: 'ชื่อผู้เบิกจ่าย/โปรเจค', key: 'name' },
    { label: 'รายจ่าย', key: 'grandTotal' },
  ]

  function calGrandTotal(item) {
    const value = _.reduce(item, (v, i) => v + parseFloat(i.grandTotal), 0)
    return value
  }
  async function onSubmitFilter() {
    const res = await QueryExpenses({
      startDate: startDate,
      endDate: endDate,
      month: month,
      between: between,
      prevMonth: prevMonth,
      currentDate: currentDate,
      currentMonth: currentMonth,
      user: user,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      dateTime: dateTime,
      select: select,
    })

    if (startDateTime && endDateTime) {
      setStartDate(startDateTime)
      setEndDate(endDateTime)
    } else {
      setStartDateTime(null)
      setEndDateTime(null)
    }
    const dataToCSV = _.map(res, (items) => {
      return {
        date: items.Date,
        id: items.id,
        name: items.name,
        category: items.category,
        grandTotal: currency(items.grandTotal, 2),
      }
    })
    setDataCSV(dataToCSV)
    setData(res)
    setOnSearch(true)
  }

  return (
    <>
      <HStack
        my='1em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='1vw'
        flexDirection={{ sm: 'column', md: 'row' }}
        alignItems='start'
      >
        <Flex flexDirection='column' w={{ sm: 'full', md: '30vw', lg: '30vw' }}>
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ช่วงเวลา :</Text>
          </Box>
          <SearchDate
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            month={month}
            setMonth={setMonth}
            setBetween={setBetween}
            setPrevMonth={setPrevMonth}
            setCurrentDate={setCurrentDate}
            setCurrentMonth={setCurrentMonth}
            setStartDateTime={setStartDateTime}
            setEndDateTime={setEndDateTime}
            setDateTime={setDateTime}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
          />
        </Flex>
        <Flex
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '21vw', lg: '29vw' }}
        >
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>หมวดหมู่ :</Text>
          </Box>
          <Select
            color={'gray.700'}
            w='full'
            bg={'#F4F7FE'}
            border='0px solid #E8E9E9'
            borderRadius='16px'
            defaultValue=''
            fontWeight='500'
            variant='main'
            placeholder={'ทั้งหมด'}
            _placeholder={{
              fontWeight: '400',
            }}
            h='44px'
            maxh='44px'
            onChange={(event) => setSelect(event.target.value)}
          >
            {Category.map((item, index) => (
              <option value={item.name} key={index}>
                {item.name}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex
          display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '15vw', lg: '29vw' }}
        ></Flex>
        <Flex
          flexDirection='column'
          w={{ sm: 'full', md: '19vw', lg: '13vw', xl: '11vw' }}
          alignItems='end'
          pt={{ sm: '1rem', md: '0rem' }}
        >
          <Box
            textAlign='start'
            display={{ sm: 'none', md: 'flex' }}
            p='0.5rem'
            pl='1rem'
            pt='0rem'
          >
            <Text color='#8F9BBA'>&nbsp;</Text>
          </Box>
          <Button
            h='44px'
            maxh='44px'
            w={{ sm: '10rem', md: 'full' }}
            colorScheme='btn'
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            onClick={() => onSubmitFilter()}
          >
            <Text>
              <SearchIcon />
            </Text>
            <Text>แสดงรายงาน</Text>
          </Button>
        </Flex>
      </HStack>
      <Flex
        flexDirection='column'
        p={{ sm: '10px', md: '20px 20px 30px 20px' }}
        bg='white'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
        borderRadius='30px'
      >
        <Stack spacing={{ base: '0em', md: '4em' }}>
          <HStack
            justifyContent={'space-between'}
            w='full'
            display={{ sm: 'block', md: 'flex' }}
          >
            <Stack>
              <Heading
                fontFamily={'Prompt'}
                color='#2B3674'
                fontSize={{ base: '24px', md: '30px' }}
              >
                รายงานรายจ่าย
              </Heading>
              <Box>
                <HStack>
                  <Text color='#2B3674'>ณ วันที่:</Text>
                  <Text color='#2B3674'>
                    {moment(new Date().getTime()).format('MM/DD/YYYY')}
                  </Text>
                </HStack>
                <HStack>
                  <Text color='#2B3674'>ช่วงเวลา:</Text>
                  {startDate && endDate && (
                    <Text color='#2B3674'>
                      {moment(startDate).format('MM/DD/YYYY')} -{' '}
                      {moment(endDate).format('MM/DD/YYYY')}
                    </Text>
                  )}
                </HStack>
              </Box>
            </Stack>
            <HStack justifyContent={{ sm: 'start', md: 'end' }}>
              <CSVDownloadForm
                mt={{ sm: '10px', md: '0px' }}
                buttonTitle={'ดาวน์โหลดไฟล์รายงานรายจ่าย'}
                data={dataCSV}
                filename={'expenseReport'}
                headers={headers}
              />
            </HStack>
          </HStack>
          <Box
            pt={{ base: '1rem', sm: '1rem', md: '0rem', lg: '0rem' }}
            pr={{
              base: '0rem',
              sm: '0rem',
              md: '1rem',
              lg: '1rem',
              xl: '4rem',
            }}
          >
            <Price grandTotal={onSearch ? calGrandTotal(data) : 0} />
          </Box>
        </Stack>
        <CustomTable
          columnsData={columnsDataOrders}
          tableData={onSearch ? data : []}
          closeSearch={true}
        />
      </Flex>
    </>
  )
}

export default ReportBranchExpense

const columnsDataOrders = [
  {
    Header: 'วันที่',
    accessor: 'createdAt',
    extra: (data, row) => (
      <Center key={row} w='full'>
        <Text>{row.createdAt}</Text>
      </Center>
    ),
  },
  {
    Header: 'เลขที่ใบจ่าย',
    accessor: 'options',
    extra: (data, row) => <Center key={row}>{row.id}</Center>,
  },
  {
    Header: 'ชื่อผู้เบิกจ่าย/ชื่อโปรเจค',
    accessor: 'name',
    extra: (data, row) => (
      <Center key={row} maxW={'145px'}>
        {row.name}
      </Center>
    ),
  },
  {
    Header: 'หมวดหมู่',
    accessor: 'category',
    extra: (data, row) => (
      <Center key={row} maxW={'145px'}>
        {row.category}
      </Center>
    ),
  },

  {
    Header: (
      <Text w='100%' textAlign='end'>
        รายจ่าย
      </Text>
    ),
    accessor: 'grandTotal',
    extra: (data, row) => (
      <Center pl='1rem' justifyContent='flex-end' textAlign='end' w={'100px'}>
        {accounting.formatNumber(row.grandTotal, 2)}
      </Center>
    ),
  },
]
