/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react'
import { HamburgerIcon, SearchIcon } from '@chakra-ui/icons'

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Stack,
  InputGroup,
  Input,
  InputRightElement,
  Spinner,
  Fade,
  IconButton,
  SimpleGrid,
  Tag,
  TagLabel,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Card from 'Components/Horizons/card/Card'
import { search } from 'ss-search'
import CustomTable from 'Components/Horizons/table/customTable'
import CSVDownloadForm from 'Components/csvDownload'
import { usePurchaseOrder, useBranch } from 'Hooks'
import moment from 'moment'
import { BsFillPrinterFill } from 'react-icons/bs'
import { AiFillSetting } from 'react-icons/ai'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'Config/firebase'
import { URL } from 'Utils/downloadWithURL'

const Status = {
  pending: 'รอดำเนินการ',
  processing: 'ดำเนินการ',
  completed: 'เสร็จสิ้น',
  rejected: 'ถูกยกเลิก',
}
const createPoPDF = httpsCallable(functions, 'printPDFPo')

const StatusCouter = (data, status) =>
  data.reduce((a, d) => (d.orderStatus === status ? a + 1 : a), 0)

const PurchaseOrderPage = ({ user }) => {
  const navigate = useNavigate()
  const { PurchaseOrder, getAllPurchaseOrders } = usePurchaseOrder()

  const { BranchNoHQ, getBranchData } = useBranch()
  const [isFadePage, setIsFadePage] = useState(true)
  const [selectedBranch, setBranchOption] = useState('')
  const [optionsSearch, setOptionsSearch] = useState({
    search: '',
    status: '',
  })
  const tableData = useMemo(() => {
    const { status, search: searchText } = optionsSearch
    let array = PurchaseOrder
    if (status !== '') {
      array = array.filter((p) => p.orderStatus === status)
    }

    if (searchText !== '') {
      const searchKey = ['id', 'createdBy.name']
      array = search(array, searchKey, searchText)
    }

    return array
  }, [PurchaseOrder, optionsSearch])

  async function createPDF(id) {
    const create = await createPoPDF({ id: id })
    create.data.pdfRef?.map((i) => {
      return URL(i)
    })
  }

  const csvData = useMemo(() => {
    const { status, search: searchText } = optionsSearch
    let array = PurchaseOrder.flatMap((order) =>
      order?.products.map((product) => ({
        ...product,
        ...order,
        createdAt: moment(order.createdAt.toDate()).format('MM/DD/YYYY'),
        updatedAt: order.updatedAt
          ? moment(order.updatedAt.toDate()).format('MM/DD/YYYY')
          : '',
      }))
    )

    if (status !== '') {
      array = array.filter((p) => p.orderStatus === status)
    }

    if (searchText !== '') {
      const searchKey = ['id', 'createdBy.name']
      array = search(array, searchKey, searchText)
    }

    return array
  }, [PurchaseOrder, optionsSearch])

  useEffect(() => {
    getBranchData()
    getAllPurchaseOrders(selectedBranch)
    setIsFadePage(false)
  }, [selectedBranch])

  const columnsDataOrders = [
    {
      Header: 'เลขที่ใบสั่งสินค้า',
      accessor: 'name',
      extra: (id, row) => {
        return row?.id
      },
    },
    {
      Header: 'ผู้สั่ง',
      accessor: 'createdBy.name',
    },
    {
      Header: 'สาขา',
      accessor: 'branch.name',
    },
    {
      Header: 'วันที่สั่ง',
      accessor: 'createdAt',
      extra: (date) => {
        return date?.toDate().toLocaleDateString()
      },
    },
    {
      Header: 'วันที่อัปเดต',
      accessor: 'updatedAt',
      extra: (date) => {
        return date?.toDate().toLocaleDateString()
      },
    },
    {
      Header: 'สถานะ',
      accessor: 'orderStatus',
      extra: (status, row) => {
        return (
          <Center fontSize={16} fontWeight={500}>
            {Status[status]}
          </Center>
        )
      },
    },
    {
      Header: 'จัดการ',
      accessor: 'id',
      extra: (id, row) => {
        return (
          <>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label='Options'
                icon={<HamburgerIcon />}
                variant='outline'
              />
              <MenuList>
                <MenuItem
                  icon={<AiFillSetting />}
                  onClick={() => navigate(`/purchase-order/manage/${id}`)}
                >
                  จัดการ
                </MenuItem>
                <MenuItem
                  // isDisabled={true}
                  icon={<BsFillPrinterFill />}
                  onClick={() => {
                    createPDF(id)
                  }}
                >
                  ปริ้น
                </MenuItem>
              </MenuList>
            </Menu>
            {/*             
            <Button variant='link' colorScheme='blackAlpha' onClick={() => {}}>
              ปริ้น
            </Button>
            <Button
              variant='link'
              colorScheme='blackAlpha'
              onClick={() => navigate(`/purchase-order/manage/${id}`)}
            >
              จัดการ
            </Button> 
            */}
          </>
        )
      },
    },
  ]

  const Headers = [
    { label: 'เลขที่ใบสั่งสินค้า', key: 'id' },
    { label: 'ผู้สั่ง', key: 'createdBy.name' },
    { label: 'สาขาที่สั่ง', key: 'branch.name' },
    { label: 'วันที่สั่ง', key: 'createdAt' },
    { label: 'วันที่อัปเดทล่าสุด', key: 'updatedAt' },
    { label: 'ชื่อสินค้า', key: 'name' },
    { label: 'ชื่อตัวเลือกสินค้า', key: 'nameOption' },
    { label: 'หมวดหมู่', key: 'category' },
    { label: 'จำนวน', key: 'amount' },
    { label: 'หน่วยสินค้า', key: 'unit' },
    { label: 'สถานะสินค้า', key: 'status' },
    { label: 'ส่งจากสาขา', key: 'sendingFromBranch.name' },
    { label: 'สถานะใบสั่งสินค้า', key: 'orderStatus' },
  ]

  return (
    <>
      <Stack as={Fade} in={!isFadePage}>
        {!isFadePage ? (
          <>
            <Card>
              <Box zIndex='base'>
                <HStack
                  my='1em'
                  spacing={{ base: '0px', sm: '0px', md: '10px' }}
                  justifyContent='space-between'
                  flexDirection={{
                    base: 'column',
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                  }}
                >
                  <InputGroup w={{ base: '100%', md: '440px', lg: '500px' }}>
                    <InputRightElement
                      children={
                        <IconButton
                          bg='inherit'
                          borderRadius='inherit'
                          _hover='none'
                          _active={{
                            bg: 'inherit',
                            transform: 'none',
                            borderColor: 'transparent',
                          }}
                          _focus={{
                            boxShadow: 'none',
                          }}
                          icon={
                            <SearchIcon
                              _hover='none'
                              color={'gray.700'}
                              w='15px'
                              h='15px'
                            />
                          }
                        />
                      }
                    />
                    <Input
                      variant='search'
                      fontSize='sm'
                      bg={'secondaryGray.300'}
                      color={'gray.700'}
                      fontWeight='500'
                      _placeholder={{
                        color: 'gray.400',
                        fontSize: '14px',
                        opacity: 1,
                      }}
                      value={optionsSearch.search}
                      borderRadius={'30px'}
                      placeholder={'ค้นหา'}
                      onChange={(e) => {
                        setOptionsSearch({
                          ...optionsSearch,
                          search: e.target.value,
                        })
                      }}
                    />
                  </InputGroup>
                  <Flex
                    pt={{ sm: '1rem', md: '0rem' }}
                    flexDirection='column'
                    w={{ base: '100%', md: '440px', lg: '500px' }}
                  >
                    <Select
                      bg='#F4F7FE'
                      borderRadius='16px'
                      cursor='pointer'
                      border='0px'
                      variant={'no-effects'}
                      _focus={{ border: '0px solid #F4F7FE' }}
                      w='full'
                      placeholder='สาขาทั้งหมด'
                      value={selectedBranch}
                      onChange={(e) => {
                        const value = e.target.value
                        setBranchOption(value)
                      }}
                    >
                      {BranchNoHQ.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                  <Flex
                    w={{ sm: 'full', md: 'auto' }}
                    justifyContent='flex-end'
                    pt={{ sm: '1rem', md: '0rem' }}
                  >
                    <CSVDownloadForm
                      buttonTitle={'ดาวน์โหลดไฟล์รายงาน'}
                      data={csvData}
                      filename={'purchaseOrderReport.csv'}
                      headers={Headers}
                    />
                  </Flex>
                </HStack>
              </Box>
              <SimpleGrid
                minChildWidth='125px'
                spacing='10px'
                mb={4}
                maxW={{ sm: 'full', lg: '800px', xl: '850px' }}
              >
                <Button
                  colorScheme='product'
                  variant={optionsSearch.status === '' ? 'solid' : 'outline'}
                  onClick={() =>
                    setOptionsSearch({ ...optionsSearch, status: '' })
                  }
                >
                  ทั้งหมด
                  <Tag
                    size='sm'
                    colorScheme='green'
                    variant={optionsSearch.status === '' ? 'subtle' : 'solid'}
                    borderRadius='full'
                    ml={2}
                  >
                    <TagLabel>{PurchaseOrder.length}</TagLabel>
                  </Tag>
                </Button>
                <Button
                  colorScheme='product'
                  variant={
                    optionsSearch.status === 'pending' ? 'solid' : 'outline'
                  }
                  onClick={() =>
                    setOptionsSearch({ ...optionsSearch, status: 'pending' })
                  }
                >
                  รอดำเนินการ
                  <Tag
                    size='sm'
                    colorScheme='green'
                    variant={
                      optionsSearch.status === 'pending' ? 'subtle' : 'solid'
                    }
                    borderRadius='full'
                    ml={2}
                  >
                    <TagLabel>
                      {StatusCouter(PurchaseOrder, 'pending')}
                    </TagLabel>
                  </Tag>
                </Button>
                <Button
                  colorScheme='product'
                  variant={
                    optionsSearch.status === 'processing' ? 'solid' : 'outline'
                  }
                  onClick={() =>
                    setOptionsSearch({ ...optionsSearch, status: 'processing' })
                  }
                >
                  ดำเนินการ
                  <Tag
                    size='sm'
                    colorScheme='green'
                    variant={
                      optionsSearch.status === 'processing' ? 'subtle' : 'solid'
                    }
                    borderRadius='full'
                    ml={2}
                  >
                    <TagLabel>
                      {StatusCouter(PurchaseOrder, 'processing')}
                    </TagLabel>
                  </Tag>
                </Button>
                <Button
                  colorScheme='product'
                  variant={
                    optionsSearch.status === 'completed' ? 'solid' : 'outline'
                  }
                  onClick={() =>
                    setOptionsSearch({ ...optionsSearch, status: 'completed' })
                  }
                >
                  เสร็จสิ้น
                  <Tag
                    size='sm'
                    colorScheme='green'
                    variant={
                      optionsSearch.status === 'completed' ? 'subtle' : 'solid'
                    }
                    borderRadius='full'
                    ml={2}
                  >
                    <TagLabel>
                      {StatusCouter(PurchaseOrder, 'completed')}
                    </TagLabel>
                  </Tag>
                </Button>
                <Button
                  colorScheme='product'
                  variant={
                    optionsSearch.status === 'rejected' ? 'solid' : 'outline'
                  }
                  onClick={() =>
                    setOptionsSearch({ ...optionsSearch, status: 'rejected' })
                  }
                >
                  ถูกยกเลิก
                  <Tag
                    size='sm'
                    colorScheme='green'
                    variant={
                      optionsSearch.status === 'rejected' ? 'subtle' : 'solid'
                    }
                    borderRadius='full'
                    ml={2}
                  >
                    <TagLabel>
                      {StatusCouter(PurchaseOrder, 'rejected')}
                    </TagLabel>
                  </Tag>
                </Button>
              </SimpleGrid>
              <CustomTable
                columnsData={columnsDataOrders}
                tableData={tableData}
                disabledSearch
              />
            </Card>
          </>
        ) : (
          <Card>
            <Center>
              <Spinner size='lg' />
            </Center>
          </Card>
        )}
      </Stack>
    </>
  )
}

export default PurchaseOrderPage
