import {
  Center,
  HStack,
  Flex,
  Box,
  Text,
  Button,
  Stack,
  Heading,
  Select,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import CustomTable from 'Components/Horizons/table/customTable'
import { SearchIcon } from '@chakra-ui/icons'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { currency, decodeUnit } from 'Utils/currency'
import { productReport } from './query'
import { useCategory } from 'Hooks'
import SearchDate from 'Components/Search/date'
import CSVDownloadForm from 'Components/csvDownload'

const _ = require('underscore')

function Price(props) {
  const { grandTotal } = props
  return (
    <HStack justifyContent='end' spacing='6em'>
      <Stack>
        <Box>
          <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
            กำไรสุทธิรวม
          </Text>
        </Box>
        <Box>
          <Text
            fontSize={{ base: '12px', md: '16px' }}
            color='report.500'
            textAlign='right'
          >
            {currency(grandTotal, 2, true)}
          </Text>
        </Box>
      </Stack>
    </HStack>
  )
}

function BranchProduct({ user }) {
  const [currentDate, setCurrentDate] = useState(true)
  const [currentMonth, setCurrentMonth] = useState(false)
  const [between, setBetween] = useState(false)
  const [prevMonth, setPrevMonth] = useState(false)
  const [month, setMonth] = useState(new Date())
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const { category, getCategoryAll } = useCategory()
  const [filterData, setFilterData] = useState([])
  const [onSearch, setOnSearch] = useState(false)
  const [select, setSelect] = useState('')
  const [startDateTime, setStartDateTime] = useState(null)
  const [endDateTime, setEndDateTime] = useState(null)
  const [dateTime, setDateTime] = useState(false)
  const [dataDownload, setDataDownload] = useState([])

  useEffect(() => {
    getCategoryAll()
  }, [getCategoryAll])

  function calGrandTotal(item) {
    const arrGrandTotal = _.pluck(item, 'profit')
    return _.reduce(arrGrandTotal, (memo, num) => memo + num, 0)
  }

  const columnsDataOrders = [
    {
      Header: 'รหัสสินค้า',
      accessor: 'idProduct',
      extra: (data, row) => <Center key={row}>{row.idProduct}</Center>,
    },
    {
      Header: 'หมวดหมู่สินค้า',
      accessor: 'category',
      extra: (data, row) => <Center key={row}>{row.category}</Center>,
    },
    {
      Header: 'ชื่อสินค้า',
      accessor: 'product',
      extra: (data, row) => <Center key={row}>{row.productName}</Center>,
    },
    {
      Header: ' จำนวนที่ขาย',
      accessor: 'amount',
      extra: (data, row) => (
        <Center key={row}>{currency(row.amount, 0)}</Center>
      ),
    },
    {
      Header: (
        <Text w='100%' textAlign='end'>
          ยอดขายสุทธิ
        </Text>
      ),
      accessor: 'grandTotal',
      extra: (data, row) => (
        <Text key={row} textAlign='end' w='100%'>
          {currency(row.grandTotal, 2)}
        </Text>
      ),
    },
    {
      Header: (
        <Text w='100%' textAlign='end'>
          ต้นทุน
        </Text>
      ),
      accessor: 'cost',
      extra: (data, row) => (
        <Text key={row} textAlign='end' w='100%'>
          {currency(row.cost, 2)}
        </Text>
      ),
    },
    {
      Header: (
        <Text w='100%' textAlign='end'>
          กำไรรวม
        </Text>
      ),
      accessor: 'profit',
      extra: (data, row) => (
        <Center key={row} w='100%' justifyContent='end'>
          {currency(row.profit, 2)}
        </Center>
      ),
    },
  ]

  const csvHeaders = [
    { label: 'รหัสสินค้า', key: 'idProduct' },
    { label: 'ชื่อสินค้า', key: 'productName' },
    { label: 'หมวดหมู่สินค้า', key: 'category' },
    { label: 'จำนวนที่ขาย', key: 'amount' },
    { label: 'ยอดขายสุทธิ', key: 'grandTotal' },
    { label: 'ต้นทุน', key: 'cost' },
    { label: 'กำไรรวม', key: 'profit' },
  ]

  async function onSubmitFilter() {
    const res = await productReport({
      month: month,
      startDate: startDate,
      endDate: endDate,
      between: between,
      prevMonth: prevMonth,
      currentDate: currentDate,
      currentMonth: currentMonth,
      user: user,
      category: null,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      dateTime: dateTime,
    })
    if (startDateTime && endDateTime) {
      setStartDate(startDateTime)
      setEndDate(endDateTime)
    } else {
      setStartDateTime(null)
      setEndDateTime(null)
    }

    const itemsOrders = []
    _.map(res, (item) => {
      return itemsOrders.push(...item.items)
    })
    const groupOrderItem = _.groupBy(itemsOrders, 'id')
    const resOrder = await Promise.all(
      _.map(groupOrderItem, (e) => {
        const paid = _.filter(e, (f) => f.status === 'paid')
        const refund = _.filter(e, (f) => f.status === 'refund')
        const price = decodeUnit(e[0]['price'])
        const calAmountPaid = _.reduce(
          _.pluck(paid, 'amount'),
          (memo, num) => memo + parseFloat(num),
          0
        )
        const calAmountRefund = _.reduce(
          _.pluck(refund, 'amount'),
          (memo, num) => memo + parseFloat(num),
          0
        )

        const calGrandTotalPaid = _.reduce(
          paid,
          (memo, num) => memo + parseFloat(num.grandTotal),
          0
        )
        const calGrandTotalRefund = _.reduce(
          refund,
          (memo, num) => memo + parseFloat(num.grandTotal),
          0
        )

        // const discountPaid = _.pluck(paid, 'totalDiscount')
        // const calDiscountPaid = _.reduce(
        //   discountPaid,
        //   (memo, num) => memo + parseFloat(num),
        //   0
        // )
        // const discountRefund = _.pluck(refund, 'totalDiscount')
        // const calDiscountRefund = _.reduce(
        //   discountRefund,
        //   (memo, num) => memo + parseFloat(num),
        //   0
        // )

        // const totalDiscount = calDiscountPaid - calDiscountRefund
        const calPriceTotal = calGrandTotalPaid - calGrandTotalRefund

        const grandTotal = calPriceTotal

        const amount = calAmountPaid - calAmountRefund
        const cost = parseFloat(e[0]['cost'] * amount)
        const categoryData = _.find(category, (i) => {
          return i.id === e[0]?.category || i.name === e[0]?.category
        })
        return {
          idProduct: e[0]['sku'],
          category: categoryData.name,
          amount: amount,
          total: parseFloat(price * amount),
          cost: cost,
          productName: e[0]?.name + (e[0]?.nameOption ? e[0]?.nameOption : ''),
          commission: parseFloat(decodeUnit(e[0]['commission']) * amount),
          grandTotal: grandTotal,
          profit: grandTotal - cost,
        }
      })
    )
    const dataToDownload = _.map(
      select ? resOrder.filter((item) => item.category === select) : resOrder,
      (items) => {
        return {
          ...items,
          amount: currency(items.amount, 2),
          total: currency(items.total, 2),
          cost: currency(items.cost, 2),
          grandTotal: currency(items.grandTotal, 2),
          profit: currency(items.profit, 2),
        }
      }
    )
    setDataDownload(dataToDownload)
    setOnSearch(true)
    setFilterData(
      select ? resOrder.filter((item) => item.category === select) : resOrder
    )
  }

  return (
    <>
      <HStack
        mt='1rem'
        mb='0.5em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='1vw'
        flexDirection={{ sm: 'column', md: 'row' }}
        alignItems='flex-start'
      >
        <Flex flexDirection='column' w={{ sm: 'full', md: '30vw', lg: '30vw' }}>
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ช่วงเวลา :</Text>
          </Box>
          <SearchDate
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            month={month}
            setMonth={setMonth}
            setBetween={setBetween}
            setPrevMonth={setPrevMonth}
            setCurrentDate={setCurrentDate}
            setCurrentMonth={setCurrentMonth}
            setStartDateTime={setStartDateTime}
            setEndDateTime={setEndDateTime}
            setDateTime={setDateTime}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
          />
        </Flex>
        <Flex
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        >
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>หมวดหมู่สินค้า :</Text>
          </Box>
          <Select
            color={'gray.700'}
            w='full'
            bg={'#F4F7FE'}
            border='0px solid #E8E9E9'
            borderRadius='16px'
            defaultValue=''
            fontWeight='500'
            variant='main'
            placeholder={'ทั้งหมด'}
            _placeholder={{
              fontWeight: '400',
            }}
            h='44px'
            maxh='44px'
            onChange={(event) => setSelect(event.target.value)}
          >
            {category.map((item, index) => (
              <option value={item.name} key={index}>
                {item.name}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex
          display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: '18vw', lg: '29vw' }}
        ></Flex>
        <Flex
          flexDirection='column'
          w={{ sm: 'full', md: '19vw', lg: '13vw', xl: '11vw' }}
          alignItems='end'
          pt={{ sm: '1rem', md: '0rem' }}
        >
          <Box
            textAlign='start'
            display={{ sm: 'none', md: 'flex' }}
            p='0.5rem'
            pl='1rem'
            pt='0rem'
          >
            <Text color='#8F9BBA'>&nbsp;</Text>
          </Box>
          <Button
            h='44px'
            maxh='44px'
            w={{ sm: '10rem', md: 'full' }}
            colorScheme='btn'
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            onClick={() => onSubmitFilter()}
          >
            <Text>
              <SearchIcon />
            </Text>
            <Text>แสดงรายงาน</Text>
          </Button>
        </Flex>
      </HStack>
      <Flex
        flexDirection='column'
        p={{ sm: '10px', md: '20px 20px 30px 20px' }}
        bg='white'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
        borderRadius='30px'
      >
        <Stack spacing={{ base: '0em', md: '4em' }}>
          <HStack
            justifyContent={'space-between'}
            w='full'
            display={{ sm: 'block', md: 'flex' }}
          >
            <Stack>
              <Heading
                fontFamily={'Prompt'}
                color='#2B3674'
                fontSize={{ base: '24px', md: '30px' }}
              >
                รายงานยอดขายตามสินค้า
              </Heading>
              <Box>
                <HStack>
                  <Text color='#2B3674'>ณ วันที่ :</Text>
                  <Text color='#2B3674'>
                    {moment(new Date().getTime()).format('MM/DD/YYYY')}
                  </Text>
                </HStack>
                <HStack>
                  <Text color='#2B3674'>ช่วงเวลา :</Text>
                  {startDate && endDate && (
                    <Text color='#2B3674'>
                      {moment(startDate).format('MM/DD/YYYY')} -{' '}
                      {moment(endDate).format('MM/DD/YYYY')}
                    </Text>
                  )}
                </HStack>
              </Box>
            </Stack>
            <HStack justifyContent={{ sm: 'start', md: 'end' }}>
              <CSVDownloadForm
                mt={{ sm: '10px', md: '0px' }}
                buttonTitle={'ดาวน์โหลดไฟล์ยอดขายตามสินค้า'}
                data={dataDownload}
                filename={'productSalesReport'}
                headers={csvHeaders}
              />
            </HStack>
          </HStack>
          <Box
            pt={{ base: '1rem', sm: '1rem', md: '0rem', lg: '0rem' }}
            pr={{
              base: '0rem',
              sm: '0rem',
              md: '1rem',
              lg: '1rem',
              xl: '1rem',
            }}
          >
            <Price grandTotal={onSearch ? calGrandTotal(filterData) : 0} />
          </Box>
        </Stack>
        <CustomTable
          columnsData={columnsDataOrders}
          tableData={onSearch ? filterData : []}
          closeSearch={true}
        />
      </Flex>
    </>
  )
}

export default BranchProduct
