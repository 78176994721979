import { ChevronLeftIcon } from '@chakra-ui/icons'
import Card from 'Components/Horizons/card/Card'
import { useEffect, useState } from 'react'
import { Controller, useForm, useFieldArray } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Center,
  Flex,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useColorModeValue,
  IconButton,
  Input,
  Grid,
  GridItem,
  HStack,
  Image,
} from '@chakra-ui/react'
import { useProduct, usePurchaseOrder } from 'Hooks'
import ReactSelect from 'react-select'
import { FiTrash } from 'react-icons/fi'
import { useAppContext } from 'Context'

const CurrencyFormat = require('react-currency-format')
const SingleValue = ({ data, children, ...props }) => {
  return (
    <HStack mt={'-28px'} {...props}>
      <Image maxW={'35px'} maxH={'35px'} src={data.value.img} />
      <Text>{`${data.value.name} ${data.value?.nameOption || ''}`}</Text>
      {/* {children} */}
    </HStack>
  )
}

const reactSelectCustomStyles = {
  option: (provided, state) => ({
    ...provided,
  }),
  control: (provided, state) => {
    return {
      ...provided,
      width: '100%',
      border: '1px solid #E0E5F2',
      ':hover': {
        border: '1px solid #E0E5F2',
        boxShadow: 'none',
      },
      ':active': {
        border: '1px solid #E0E5F2',
        boxShadow: 'none',
      },
      boxShadow: 'none',
      borderRadius: '13px',
      height: '44px',
      maxHeight: '44px',
    }
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#B0B0B0',
      borderRadius: '5px',
    }
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#F87B7B',
    ':hover': {
      color: '#FB0000',
    },
  }),
}

export default function FormPurchaseOrder({ user }) {
  const { setMessage, setError } = useAppContext()

  const { createPurchaseOrder } = usePurchaseOrder()
  const { Product, getAllProducts } = useProduct()
  const [isSubmit, setIsSubmit] = useState(false)
  const Navigate = useNavigate()
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      list: [
        {
          product: '',
          amount: '',
        },
      ],
    },
  })

  function customFilter(option, searchText) {
    if (
      option.data.value.name
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      option.data.value.nameOption
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      option.data.value.category
        ?.toLowerCase()
        .includes(searchText.toLowerCase())
    ) {
      return true
    } else {
      return false
    }
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'list',
  })

  useEffect(() => {
    getAllProducts()
  }, [getAllProducts])

  function onSubmit(value) {
    setIsSubmit(true)

    const submitData = {
      products: value.list.map((item) => ({
        amount: item.amount,
        id: item?.product?.id,
        name: item?.product?.name,
        nameOption: item?.product?.nameOption || '',
        unit: item?.product?.unit,
        sku: item?.product?.sku,
        status: 'pending',
        category: item?.product?.category,
        ref: item?.product?.ref || '',
      })),
      orderStatus: 'pending',
      createdAt: new Date(),
      createdBy: { id: user?.uid, name: user?.name },
      branch: { id: user?.branchSelect?.id, name: user?.branchSelect?.name },
      note: '',
    }

    createPurchaseOrder(submitData)
      .then(() => {
        Navigate('/purchase-order/list')
        setMessage({
          type: 'success',
          message: 'เพิ่มรายการสำเร็จ',
        })
        setIsSubmit(false)
      })
      .catch((e) => {
        setError({
          code: 'Create Purchase Order',
          message: e?.message,
        })
        setIsSubmit(false)
      })
  }

  return (
    <Flex direction='column' minH='100vh' align='center' position='relative'>
      <Box
        h={{ base: '0px', sm: '0px', md: '25vh' }}
        bg='product.700'
        position='absolute'
        w={{ sm: '100vw', md: '100%', lg: '100%' }}
        borderRadius={{ sm: '0px', md: '30px', lg: '30px' }}
      ></Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          mt={{ base: '-30px', sm: '-30px', md: '24px', lg: '24px' }}
          maxW={{ md: '90%', lg: '100%' }}
          mx={{ base: 'auto', md: '0px', lg: 'auto' }}
        >
          <Box
            w={{ sm: '100vw', md: '670px', lg: '850px' }}
            p='0px'
            mx={{ base: 'auto', md: '0px', lg: 'auto' }}
          >
            <Card
              mt={{ base: '-40px', sm: '-40px', md: '0px', lg: '0px' }}
              p={{ base: '15px', sm: '15px', md: '30px' }}
              px={{ base: '30px', sm: '30px', md: '30px' }}
              pb={{ base: '40px', sm: '40px', md: '30px' }}
            >
              <Box
                pb='0.3rem'
                zIndex='base'
                color='product.500'
                onClick={() => Navigate(-1)}
                w='max-content'
                display={{ sm: 'flex', md: 'none' }}
              >
                <Text
                  display={{ sm: 'flex', md: 'none' }}
                  fontSize='24px'
                  cursor='pointer'
                >
                  <ChevronLeftIcon />
                </Text>
              </Box>
              <Text
                textAlign={{
                  base: 'center',
                  sm: 'center',
                  md: 'left',
                  lg: 'left',
                }}
                color='#1B2559'
                fontSize='2xl'
                fontWeight='700'
                mb='20px'
              >
                สร้างใบสั่งสินค้า
              </Text>
              <Flex direction='column' w='100%'>
                <Box>
                  {fields.map((field, i) => {
                    return (
                      <Grid
                        key={i}
                        pt={'1em'}
                        gap='10px'
                        color='#1B2559'
                        alignItems='end'
                        templateColumns='repeat(13, 1fr)'
                      >
                        <Flex
                          w={{ base: 'full', md: 'auto' }}
                          justifyContent='center'
                          alignItems='center'
                          as={GridItem}
                          colSpan={{ base: 13, md: 1 }}
                        >
                          <FormLabel
                            fontSize='md'
                            color={textColor}
                            fontWeight='bold'
                          >
                            {i + 1}
                          </FormLabel>
                        </Flex>
                        <FormControl
                          as={GridItem}
                          colSpan={{ base: 13, md: 6 }}
                          isInvalid={Boolean(
                            errors?.list && errors?.list[i]?.product?.message
                          )}
                        >
                          <FormLabel
                            ms='10px'
                            htmlFor='role'
                            fontSize='sm'
                            color={textColor}
                            fontWeight='bold'
                          >
                            สินค้า
                          </FormLabel>
                          <Controller
                            render={({ field: { onChange } }) => (
                              <ReactSelect
                                {...register(`list.${i}.product`, {
                                  required: 'กรุณาเลือกสินค้า',
                                })}
                                styles={reactSelectCustomStyles}
                                // styles={{
                                //   singleValue: (provided, state) => ({
                                //     ...provided,
                                //     // display: 'flex',
                                //     // alignItems: 'center',
                                //   }),
                                // }}
                                // styles={{ singleValue }}
                                // components={animatedComponents}
                                components={{ SingleValue }}
                                // components={{ Control }}
                                onChange={(e) => onChange(e.value)}
                                menuPlacement='auto'
                                isSearchable={true}
                                filterOption={customFilter}
                                options={Product.map((p) => ({
                                  label: (
                                    <HStack>
                                      <Image
                                        maxW={'100px'}
                                        maxH={'100px'}
                                        src={p.img}
                                        className='productImage'
                                      />
                                      <Text>{`${p.name} ${
                                        p?.nameOption || ''
                                      }`}</Text>
                                    </HStack>
                                  ),
                                  value: p,
                                }))}
                                placeholder='เลือกสินค้า...'
                                borderRadius={{
                                  base: '10px',
                                  sm: '10px',
                                  md: '10px',
                                  lg: '16px',
                                }}
                              />
                            )}
                            name={`list.${i}.product`}
                            control={control}
                            defaultValue=''
                          />

                          <FormErrorMessage>
                            {errors?.list && errors?.list[i]?.product?.message}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          as={GridItem}
                          colSpan={{ base: 8, md: 4 }}
                          isInvalid={Boolean(
                            errors?.list && errors?.list[i]?.amount?.message
                          )}
                        >
                          <FormLabel
                            ms='10px'
                            htmlFor='role'
                            fontSize='sm'
                            color={textColor}
                            fontWeight='bold'
                          >
                            จำนวณ / ปริมาณ
                          </FormLabel>
                          <Input
                            as={CurrencyFormat}
                            {...register(`list.${i}.amount`, {
                              required: 'กรุณากรอกข้อมูล',
                              validate: (value) =>
                                value > 0 || 'จำนวณ / ปริมาณต้องมากกว่า 0',
                            })}
                            variant='main'
                            _placeholder={{
                              fontWeight: '400',
                              color: 'secondaryGray.600',
                            }}
                            h='44px'
                            maxh='44px'
                            onWheel={(e) => e.target.blur()}
                            borderRadius={{
                              base: '10px',
                              sm: '10px',
                              md: '10px',
                              lg: '16px',
                            }}
                            thousandSeparator={true}
                            decimalScale={
                              watch(`list.${i}.product`).unit === 'piece'
                                ? 0
                                : undefined
                            }
                            onValueChange={({ value }) => {
                              setValue(`list.${i}.amount`, value)
                              clearErrors(`list.${i}.amount`)
                            }}
                          />
                          <FormErrorMessage>
                            {errors?.list && errors?.list[i]?.amount?.message}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          w='auto'
                          minW='70px'
                          as={GridItem}
                          colSpan={{ base: 4, md: 1 }}
                        >
                          <FormLabel
                            mx='10px'
                            textAlign='center'
                            htmlFor='role'
                            fontSize='sm'
                            color={textColor}
                            fontWeight='bold'
                          >
                            หน่วย
                          </FormLabel>
                          <Center h='44px' maxh='44px'>
                            <FormLabel
                              textAlign='center'
                              mx='10px'
                              htmlFor='role'
                              fontSize='sm'
                              color={textColor}
                            >
                              {watch(`list.${i}.product`).unit === 'piece'
                                ? 'ชิ้น'
                                : watch(`list.${i}.product`).unit ===
                                    'weight' && 'กรัม'}
                            </FormLabel>
                          </Center>
                        </FormControl>
                        <Flex
                          w='auto'
                          alignItems='flex-end'
                          h='full'
                          as={GridItem}
                          colSpan={1}
                        >
                          <IconButton onClick={() => remove(i)}>
                            <FiTrash />
                          </IconButton>
                        </Flex>
                      </Grid>
                    )
                  })}
                </Box>
                <Flex mt='2em' w='full' justifyContent={{ base: 'center' }}>
                  <Button
                    border='1px'
                    borderColor='product.100'
                    fontWeight='normal'
                    onClick={() =>
                      append({
                        product: '',
                        amount: '',
                      })
                    }
                  >
                    + เพิ่มสินค้า
                  </Button>
                </Flex>
                <Flex justify='space-between' mt='30px'>
                  <Button
                    variant='light'
                    fontSize='sm'
                    borderRadius='16px'
                    w={{ base: '128px', md: '148px' }}
                    h='46px'
                    onClick={() => Navigate(-1)}
                  >
                    ย้อนกลับ
                  </Button>
                  <Button
                    type='submit'
                    colorScheme='btn'
                    fontSize='sm'
                    borderRadius='16px'
                    w={{ base: '128px', md: '148px' }}
                    h='46px'
                    ms='auto'
                    isLoading={isSubmit}
                    variant='solid'
                    loadingText={'Loading...'}
                  >
                    บันทึก
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </Box>
        </Box>
      </form>
    </Flex>
  )
}
