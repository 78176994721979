import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  useColorModeValue,
  Stack,
  Icon,
  Center,
  Fade,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  Avatar,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import { InputDoBField } from 'Components/Horizons/fields/InputDobField'
import InputField from 'Components/Horizons/fields/InputField'
import TextField from 'Components/Horizons/fields/TextField'
import { useAuth, useBranch } from 'Hooks'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BiEdit } from 'react-icons/bi'
import Resizer from 'react-image-file-resizer'
import { useNavigate } from 'react-router-dom'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import ReactSelect from 'react-select'
import makeAnimated from 'react-select/animated'
import { useAppContext } from 'Context'
import { decodeUnit } from 'Utils'

const _ = require('underscore')

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      'base64'
    )
  })

function FormStaff({ id, data }) {
  const { user } = useAppContext()
  const { createUser, updateUser } = useAuth()
  const { BranchNoHQ, Branch, getBranchData } = useBranch()
  const Navigate = useNavigate()
  const [file, setFile] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isFadePage, setIsFadePage] = useState(true)
  const [isLoadingImage, setIsLoadingImage] = useState(true)
  const [isFadeImage, setIsFadeImage] = useState(true)
  const [branch, setBranch] = useState([])
  const [allBranch, setAllBranch] = useState([])
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const scroll = useRef(null)
  const animatedComponents = makeAnimated()
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      tel: '',
      idCard: '',
      address: '',
      DOBDay: '',
      DOBMonth: '',
      DOBYear: '',
      salary: 0,
      salaryType: '',
      branch: [{ id: '' }],
    },
  })

  useEffect(() => {
    getBranchData()
  }, [getBranchData])

  const hiddenImageInput = useRef(null)

  function handleUploadClick() {
    hiddenImageInput.current?.click()
  }

  const reactSelectCustomStyles = {
    option: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => {
      return {
        ...provided,
        width: '100%',
        border: '1px solid #60101C',
        ':hover': {
          border: '1px solid #60101C',
        },
        ':active': {
          border: '1px solid #60101C',
        },
        borderRadius: '13px',
      }
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: '#B0B0B0',
        borderRadius: '5px',
      }
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: '#F87B7B',
      ':hover': {
        color: '#FB0000',
      },
    }),
  }

  async function handleUploadChange(event) {
    const target = event.target
    const Thisfile = target.files[0]
    const image = await resizeFile(Thisfile)
    setFile(image)
  }

  function handleChange(event) {
    const value = event
    setBranch(value?.map((item) => ({ id: item.value, name: item.label })))
  }

  const roles = watch('role')
  async function onSubmit(value) {
    setIsSubmit(true)
    value.DOBDay = value.DOBDay ? value.DOBDay : null
    value.DOBMonth = value.DOBMonth ? value.DOBMonth : null
    value.DOBYear = value.DOBYear ? value.DOBYear : null
    value.address = value.address ? value.address : null
    value.idCard = value.idCard ? value.idCard : null
    value.tel = value.tel ? value.tel : null
    value.name = value.name ? value.name : null
    value.salaryType = value.salaryType ? value.salaryType : 'noSalary'
    value.salary = value.salary ? value.salary : 0
    value.commission = value.commission === 'true' ? true : false
    const avatarImage = file || ''
    const branches =
      roles === 'Administrator'
        ? allBranch?.map((item) => ({ id: item.value, name: item.label }))
        : branch?.length > 0
        ? branch?.map((item) => ({ id: item.id, name: item.name }))
        : []
    const allValue = { ...value, branch: branches }
    delete allValue.ConfirmPassword

    if (branches.length < 1) {
      setError('branch', { type: 'required', message: 'กรุณาเลือกสาขา' })
      setIsSubmit(false)
    } else if (branches.length > 0) {
      if (id) {
        delete allValue.password
        const oldimg = data?.avatar
        try {
          updateUser(allValue, avatarImage, id, oldimg)
            .then(() => {
              Navigate('/employees/list')
              setIsSubmit(false)
            })
            .catch((e) => {
              setIsSubmit(false)
            })
        } catch (error) {
          setIsSubmit(false)
        }
      } else {
        try {
          createUser(allValue, avatarImage)
            .then(() => {
              Navigate('/employees/list')
              setIsSubmit(false)
            })
            .catch((e) => {
              setIsSubmit(false)
            })
        } catch (error) {
          setIsSubmit(false)
          setError('branch', 'กรุณาเลือกสาขา')
        }
      }
    }
  }

  useEffect(() => {
    if (data) {
      _.keys(data)?.map((item) => {
        return setValue(
          item,
          item === 'salary' ? decodeUnit(data[item]) : data[item]
        )
      })

      setBranch(
        data?.branch?.map((item) => Branch?.find((itm) => itm?.id === item?.id))
      )
      setTimeout(() => {
        setIsFadeImage(false)
      }, 100)
    }
    if (file) {
      setTimeout(() => {
        setIsLoadingImage(false)
        setTimeout(() => {
          setIsFadeImage(false)
        }, 100)
      }, 100)
    }
    const BranchValue = []

    _.forEach(
      BranchNoHQ.filter((branch) => branch.id !== 'HQ'),
      (doc) => {
        BranchValue.push({
          value: doc.id,
          label: doc.name,
          isdisabled: false,
        })
      }
    )
    setAllBranch(BranchValue)
    setTimeout(() => {
      setIsFadePage(false)
    }, 500)
  }, [file, data, setValue, BranchNoHQ, Branch, user])

  return (
    <Fade in={!isFadePage}>
      <Flex direction='column' minH='100vh' align='center' position='relative'>
        <Box
          h='25vh'
          bg='employees.700'
          position='absolute'
          w={{ sm: '100vw', md: '100%', lg: '100%' }}
          borderRadius={{ sm: '0px', md: '30px', lg: '30px' }}
        ></Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box w={{ sm: '100vw', md: '670px', lg: '850px' }} p='0px' mx='auto'>
            <Card
              p={{ base: '15px', sm: '15px', md: '30px' }}
              px={{ base: '30px', sm: '30px', md: '30px' }}
              mt={{ sm: '2.5rem', md: '4rem', lg: '4rem' }}
            >
              <Box
                pb='0.3rem'
                zIndex='overlay'
                color='employees.500'
                onClick={() => Navigate('/employees/list')}
                w='max-content'
                display={{ sm: 'flex', md: 'none' }}
              >
                <Text
                  display={{ sm: 'flex', md: 'none' }}
                  fontSize='24px'
                  cursor='pointer'
                >
                  <ChevronLeftIcon />
                </Text>
              </Box>
              <Text
                fontSize='2xl'
                color='#1B2559'
                fontWeight='700'
                pb={{ base: '1rem', lg: '0rem' }}
              >
                ข้อมูลพนักงาน
              </Text>
              <Center>
                <Box onClick={handleUploadClick}>
                  {!isLoadingImage ? (
                    <Fade in={!isFadeImage}>
                      <Avatar bg={'blackAlpha.300'} size={'2xl'} src={file} />
                      <Icon
                        position={'absolute'}
                        color={'employees.500'}
                        top={'7em'}
                        right={{ base: '4em', md: '11.7em', lg: '15em' }}
                        fontSize={'2xl'}
                      >
                        <BiEdit />
                      </Icon>
                    </Fade>
                  ) : (
                    <>
                      <Avatar
                        bg={'blackAlpha.300'}
                        size={'2xl'}
                        src={data?.avatar ? data?.avatar : ''}
                      />
                      <Icon
                        position={'absolute'}
                        color={'employees.500'}
                        top={'7em'}
                        right={{ base: '4em', md: '11.7em', lg: '15em' }}
                        fontSize={'2xl'}
                      >
                        <BiEdit />
                      </Icon>
                    </>
                  )}
                </Box>
              </Center>
              <Input
                type='file'
                display='none'
                ref={hiddenImageInput}
                onChange={handleUploadChange}
              />
              <Flex direction='column' w='100%' pt={'1em'}>
                <Stack direction='column' spacing='20px'>
                  <Text
                    pt={'1em'}
                    color='employees.500'
                    fontWeight='bold'
                    fontSize='lg'
                  >
                    ข้อมูลส่วนตัว
                  </Text>
                  <SimpleGrid
                    columns={{ base: '1', md: '2' }}
                    gap='20px'
                    color='#1B2559'
                    borderBottom='1px'
                    borderColor='#E8E9E9'
                    pb='2rem'
                  >
                    <Stack direction='column' gap='20px'>
                      <InputField
                        control={control}
                        errors={errors}
                        mb='0px'
                        id='name'
                        label='ชื่อ'
                        name='name'
                        type='text'
                      />
                      <InputField
                        control={control}
                        errors={errors}
                        mb='0px'
                        id='tel'
                        label='เบอร์มือถือ'
                        name='tel'
                        type='text'
                        maxLength={10}
                        required={{
                          value: false,
                          message: 'กรุณากรอกเบอร์มือถือ',
                        }}
                        pattern={{
                          value: /[0-9]/,
                          message: 'กรุณากรอกตัวเลขเท่านั้น',
                        }}
                      />
                      <InputField
                        control={control}
                        errors={errors}
                        mb='0px'
                        id='idCard'
                        label='บัตรประชาชน'
                        name='idCard'
                        type='text'
                        maxLength={13}
                        required={{
                          value: false,
                          message: 'กรุณากรอกเลขบัตรประชาชน.',
                        }}
                        pattern={{
                          value: /[0-9]/,
                          message: 'กรุณากรอกตัวเลขเท่านั้น',
                        }}
                      />
                      {/* <Box pt={{ base: '0rem', sm: '0rem', md: '1.5rem' }}> */}

                      {/* </Box> */}
                    </Stack>
                    <Stack direction='column' gap='20px'>
                      <InputField
                        control={control}
                        errors={errors}
                        mb='0px'
                        id='Fax'
                        label='อีเมล'
                        name='email'
                        type='email'
                        readOnly={id ? true : false}
                        required={{
                          value: true,
                          message: 'กรุณากรอกอีเมล',
                        }}
                      />
                      <InputDoBField
                        label='วัน /เดือน /ปีเกิด'
                        control={control}
                        errors={errors}
                        name='DOB'
                        data={data}
                        required={{
                          value: false,
                        }}
                      />
                      <TextField
                        control={control}
                        errors={errors}
                        mb='0px'
                        id='address'
                        label='ที่อยู่'
                        name='address'
                        type='text'
                        required={{
                          value: false,
                        }}
                      />
                    </Stack>
                  </SimpleGrid>

                  {!id && (
                    <Stack
                      direction={{ base: 'column', lg: 'row' }}
                      gap='20px'
                      color='#1B2559'
                    >
                      <InputField
                        control={control}
                        errors={errors}
                        mb='0px'
                        id='password'
                        label='รหัสผ่าน'
                        name='password'
                        type='password'
                        required={id ? false : true}
                        validate={{
                          match: (v) =>
                            v === watch('ConfirmPassword') ||
                            'รหัสผ่านไม่ตรงกัน',
                          lenght: (v) =>
                            toString(v)?.length > 5 ||
                            'รหัสผ่านต้องมีมากกว่า 6 ตัวอักษร',
                        }}
                      />
                      <InputField
                        control={control}
                        errors={errors}
                        mb='0px'
                        id='password'
                        label='ยืนยันรหัสผ่าน'
                        name='ConfirmPassword'
                        type='password'
                        required={id ? false : true}
                        validate={{
                          match: (v) =>
                            v === watch('password') || 'รหัสผ่านไม่ตรงกัน',
                          lenght: (v) =>
                            toString(v)?.length > 5 ||
                            'รหัสผ่านต้องมีมากกว่า 6 ตัวอักษร',
                        }}
                      />
                    </Stack>
                  )}
                </Stack>

                <Text
                  pt={'1.5em'}
                  color='employees.500'
                  fontWeight='bold'
                  fontSize='lg'
                >
                  สิทธิ์การเข้าถึง
                </Text>
                <VStack
                  gap='20px'
                  color='#1B2559'
                  pb={
                    (watch('role') === 'Administrator' ||
                      watch('role') === '') &&
                    watch('role') !== undefined
                      ? '2rem'
                      : '0px'
                  }
                  borderBottom={
                    (watch('role') === 'Administrator' ||
                      watch('role') === '') &&
                    watch('role') !== undefined
                      ? '1px'
                      : '0px'
                  }
                  borderColor='#E8E9E9'
                >
                  <Controller
                    name='role'
                    control={control}
                    rules={{
                      required: {
                        value: id ? false : true,
                        message: 'กรุณาเลือกสิทธิ์การเข้าถึง',
                      },
                    }}
                    render={({ field }) => (
                      <FormControl isInvalid={errors['role']}>
                        <Flex direction='column'>
                          <Select
                            fontSize='sm'
                            id='role'
                            variant='main'
                            borderRadius={{
                              base: '10px',
                              sm: '10px',
                              md: '10px',
                              lg: '16px',
                            }}
                            h='44px'
                            maxh='44px'
                            placeholder='เลือกสิทธิ์การเข้าถึง'
                            value={field.value}
                            onChange={field.onChange}
                          >
                            {user.role === 'Manager' ? (
                              <>
                                <option value='Manager'>Manager</option>
                                <option value='Cashier'>Cashier</option>
                              </>
                            ) : user?.role === 'Administrator' ? (
                              <>
                                <option value='Administrator'>
                                  Administrator
                                </option>
                                <option value='Manager'>Manager</option>
                                <option value='Cashier'>Cashier</option>
                              </>
                            ) : (
                              <>
                                <option value='Owner'>Owner</option>
                                <option value='HR'>Human Resources</option>
                                <option value='Administrator'>
                                  Administrator
                                </option>
                                <option value='Manager'>Manager</option>
                                <option value='Cashier'>Cashier</option>
                              </>
                            )}
                          </Select>
                        </Flex>
                        <FormErrorMessage>
                          {errors['role'] && errors['role']?.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />

                  {watch('role') !== 'Administrator' &&
                    watch('role') !== undefined &&
                    watch('role') !== '' && (
                      <Stack w={'full'}>
                        <FormControl isInvalid={errors['branch']}>
                          <FormLabel
                            ms='10px'
                            htmlFor='role'
                            fontSize='sm'
                            color={textColor}
                            fontWeight='bold'
                            _hover={{ cursor: 'pointer' }}
                          >
                            ระบุสาขา
                          </FormLabel>
                          <ReactSelect
                            styles={reactSelectCustomStyles}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            onChange={handleChange}
                            options={allBranch}
                            placeholder='เลือกสาขา...'
                            borderRadius={{
                              base: '10px',
                              sm: '10px',
                              md: '10px',
                              lg: '16px',
                            }}
                            defaultValue={
                              branch
                                ? branch?.map((item) => ({
                                    value: item.id,
                                    label: item.name,
                                  }))
                                : []
                            }
                          />
                          <FormErrorMessage>
                            {errors['branch'] && errors['branch']?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Stack>
                    )}
                </VStack>

                <Text
                  pt={'1.5em'}
                  color='employees.500'
                  fontWeight='bold'
                  fontSize='lg'
                >
                  ค่าจ้าง
                </Text>
                <Stack
                  direction={{ base: 'column', lg: 'row' }}
                  gap='20px'
                  color='#1B2559'
                  pb={'0.75rem'}
                >
                  <Controller
                    name='salaryType'
                    control={control}
                    rules={{
                      required: {
                        value: id ? false : true,
                        message: 'กรุณาเลือกค่าจ้าง',
                      },
                    }}
                    render={({ field }) => (
                      <FormControl isInvalid={errors['salaryType']}>
                        <Flex direction='column'>
                          <FormLabel
                            ms='10px'
                            htmlFor='salaryType'
                            fontSize='sm'
                            color={textColor}
                            fontWeight='bold'
                            _hover={{ cursor: 'pointer' }}
                          >
                            ค่าจ้าง
                          </FormLabel>
                          <Select
                            fontSize='sm'
                            id='salaryType'
                            variant='main'
                            h='44px'
                            borderRadius={{
                              base: '10px',
                              sm: '10px',
                              md: '10px',
                              lg: '16px',
                            }}
                            maxh='44px'
                            placeholder='เลือกค่าจ้างของพนักงาน'
                            value={field.value}
                            onChange={field.onChange}
                          >
                            {user?.role === 'Administrator' && (
                              <>
                                <option value='noSalary'>ไม่มี</option>
                                <option value='daily'>รายวัน</option>
                                <option value='monthly'>รายเดือน</option>
                              </>
                            )}
                          </Select>
                        </Flex>
                        <FormErrorMessage>
                          {errors['salaryType'] &&
                            errors['salaryType']?.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />

                  {watch('salaryType') !== 'noSalary' &&
                    watch('salaryType') !== undefined &&
                    watch('salaryType') !== '' && (
                      <FormControl
                        id={'salary'}
                        isInvalid={errors['salary']}
                        key={'salary'}
                      >
                        <FormLabel
                          ms='10px'
                          htmlFor='salaryType'
                          fontSize='sm'
                          color={textColor}
                          fontWeight='bold'
                          _hover={{ cursor: 'pointer' }}
                        >
                          ค่าจ้าง
                        </FormLabel>
                        <Controller
                          name='salary'
                          control={control}
                          rules={{
                            pattern: {
                              value: /[0-9]/,
                              message: 'กรุณากรอกตัวเลขเท่านั้น',
                            },
                            required: {
                              value:
                                watch('salaryType') !== 'noSalary' &&
                                watch('salaryType') !== undefined &&
                                watch('salaryType') !== '',
                              message: 'กรุณากรอกค่าจ้าง',
                            },
                          }}
                          render={({ field: { value } }) => (
                            <Input
                              borderColor={'#60101C'}
                              value={value}
                              min={0}
                              step={'any'}
                              onWheel={(e) => e.target.blur()}
                              onChange={(e) => {
                                if (e.target.value !== '') {
                                  let value = _.isNaN(e.target.value)
                                    ? 0
                                    : parseFloat(
                                        parseFloat(e.target.value)?.toFixed(2)
                                      )
                                  setValue('salary', value)
                                } else {
                                  setValue('salary', '')
                                }
                              }}
                              type={'number'}
                              id='salary'
                              borderRadius={{
                                base: '10px',
                                sm: '10px',
                                md: '10px',
                                lg: '16px',
                              }}
                              defaultValue=''
                              fontWeight='500'
                              variant='main'
                              _placeholder={{
                                fontWeight: '400',
                                color: 'secondaryGray.600',
                              }}
                              h='44px'
                              maxh='44px'
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {errors['salary'] && errors['salary']?.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                </Stack>

                <Stack
                  direction={{ base: 'column', lg: 'row' }}
                  gap='20px'
                  color='#1B2559'
                  pb={'2rem'}
                  borderBottom={'1px'}
                  borderColor='#E8E9E9'
                >
                  <Controller
                    name='commission'
                    control={control}
                    rules={{
                      required: {
                        value: id ? false : true,
                        message: 'กรุณาเลือกคอมมิชชั่น',
                      },
                    }}
                    render={({ field }) => (
                      <FormControl isInvalid={errors['commission']}>
                        <Flex direction='column'>
                          <FormLabel
                            ms='10px'
                            htmlFor='commission'
                            fontSize='sm'
                            color={textColor}
                            fontWeight='bold'
                            _hover={{ cursor: 'pointer' }}
                          >
                            คอมมิชชั่น
                          </FormLabel>
                          <Select
                            fontSize='sm'
                            id='commission'
                            variant='main'
                            h='44px'
                            borderRadius={{
                              base: '10px',
                              sm: '10px',
                              md: '10px',
                              lg: '16px',
                            }}
                            maxh='44px'
                            // placeholder='เลือกคอมมิชชั่นของพนักงาน'
                            value={field.value}
                            onChange={field.onChange}
                          >
                            <option value='false'>ไม่คิดคอมมิชชั่น</option>
                            <option value='true'>คิดคอมมิชชั่น</option>
                          </Select>
                        </Flex>
                        <FormErrorMessage>
                          {errors['commission'] &&
                            errors['commission']?.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </Stack>
              </Flex>

              <Flex direction='column' w='100%' pt={'1em'}>
                <Flex justify='space-between' mt='30px'>
                  <Button
                    variant='light'
                    fontSize='sm'
                    borderRadius='16px'
                    w={{ base: '128px', md: '148px' }}
                    h='46px'
                    onClick={() => Navigate('/employees/list')}
                  >
                    ย้อนกลับ
                  </Button>
                  <Button
                    type='submit'
                    colorScheme='btn'
                    color='#ffff'
                    fontSize='sm'
                    borderRadius='16px'
                    w={{ base: '128px', md: '148px' }}
                    h='46px'
                    ms='auto'
                    isLoading={isSubmit}
                    variant='solid'
                    loadingText={'Loading...'}
                  >
                    บันทึก
                  </Button>
                </Flex>
              </Flex>
              <Box ref={scroll}></Box>
            </Card>
          </Box>
        </form>
      </Flex>
    </Fade>
  )
}

export { FormStaff }
