import { DeleteIcon, SearchIcon } from '@chakra-ui/icons'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  Center,
  Fade,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import CustomTable from 'Components/Horizons/table/customTable'
import { useCategory, useProduct } from 'Hooks'
import { useEffect, useMemo, useRef, useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { HiPencil } from 'react-icons/hi'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { currency, decodeUnit } from 'Utils/currency'
import { FaBox, FaBoxes } from 'react-icons/fa'
import { search } from 'ss-search'

const _ = require('underscore')

function Manage({ value, pages, getAllProducts }) {
  const Navigate = useNavigate()
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure()

  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure()
  const cancelRef = useRef()
  const { deleteProduct, setIsLoading, deleteProductOption } = useProduct()

  async function handleDelete(id) {
    await deleteOnClose()
    await setIsLoading(true)
    await deleteProduct(id).then(() => {
      getAllProducts()
    })
  }

  async function handleDeleteOption(id) {
    await deleteOnClose()
    await setIsLoading(true)
    await deleteProductOption(id).then(() => {
      getAllProducts()
    })
  }

  const textColor2 = useColorModeValue('secondaryGray.500', 'white')
  const textHover = useColorModeValue(
    { color: 'secondaryGray.900', bg: 'unset' },
    { color: 'secondaryGray.500', bg: 'unset' }
  )
  const iconColor = useColorModeValue('#494D51', 'white')
  const bgList = useColorModeValue('white', 'whiteAlpha.100')
  const bgShadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    'unset'
  )
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100')
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' }
  )
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' }
  )
  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={deleteOnClose}
        isOpen={deleteIsOpen}
        isCentered
        size='auto'
      >
        <AlertDialogOverlay />

        <AlertDialogContent
          w={{ sm: '20.5rem', md: '25rem' }}
          fontFamily={'Prompt'}
        >
          <AlertDialogHeader>คุณแน่ใจ?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            หากคุณต้องการลบรายการสินค้า คลังสินค้ารายการนี้จะถูกลบ
            คุณยืนยันลบรายการสินค้านี้ใช่หรือไม่ ?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={deleteOnClose}>
              ไม่
            </Button>
            <Button
              colorScheme='red'
              ml={3}
              onClick={() => {
                if (value.ref) {
                  let data = value.options?.map((item) => item.id)
                  handleDeleteOption(data)
                } else {
                  handleDelete(value.id)
                }
              }}
            >
              ใช่
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Menu isOpen={isOpen1} onClose={onClose1}>
        <MenuButton
          align='center'
          justifyContent='center'
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w='37px'
          h='37px'
          lineHeight='100%'
          onClick={onOpen1}
          borderRadius='10px'
        >
          <Icon as={MdOutlineMoreHoriz} color={iconColor} w='24px' h='24px' />
        </MenuButton>
        <MenuList
          w='150px'
          minW='unset'
          maxW='150px !important'
          border='transparent'
          backdropFilter='blur(63px)'
          bg={bgList}
          boxShadow={bgShadow}
          borderRadius='20px'
          p='15px'
        >
          <MenuItem
            transition='0.2s linear'
            color={textColor2}
            _hover={textHover}
            p='0px'
            borderRadius='8px'
            onClick={() => {
              if (value.ref) {
                return Navigate(`${pages}/op/${value.ref}`)
              } else {
                return Navigate(`${pages}/o/${value.id}`)
              }
            }}
            _active={{
              bg: 'transparent',
            }}
            _focus={{
              bg: 'transparent',
            }}
            mb='10px'
          >
            <Flex align='center'>
              <HStack
                id={value}
                cursor='pointer'
                transition='all 0.3s'
                _hover={{ color: 'table.500', transition: 'all 0.3s' }}
              >
                <Icon as={HiPencil} h='16px' w='16px' me='8px' />
                <Text fontSize='sm' fontWeight='400'>
                  แก้ไข
                </Text>
              </HStack>
            </Flex>
          </MenuItem>
          <MenuItem
            transition='0.2s linear'
            p='0px'
            borderRadius='8px'
            color={textColor2}
            _hover={textHover}
            _active={{
              bg: 'transparent',
            }}
            _focus={{
              bg: 'transparent',
            }}
            mb='10px'
            onClick={deleteOnOpen}
          >
            <Flex align='center'>
              <HStack
                transition='all 0.3s'
                _hover={{ color: 'red', transition: 'all 0.3s' }}
              >
                <Icon as={DeleteIcon} h='16px' w='16px' me='8px' />
                <Text fontSize='sm' fontWeight='400'>
                  ลบ
                </Text>
              </HStack>
            </Flex>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}

function Products() {
  const Navigate = useNavigate()
  const { category, getCategoryAll } = useCategory()
  const { Product, isLoading: productLoading, getAllProducts } = useProduct()
  const [isFadePage, setIsFadePage] = useState(true)
  const [isFadeCard, setIsFadeCard] = useState(true)
  const [SearchResults, setSearchResults] = useState([])
  const [optionsSearch, setOptionsSearch] = useState({
    search: '',
    category: '',
  })

  const categoryList = useMemo(() => category, [category])

  const filterSearchData = (data) => {
    const searchKey = ['name', 'sku']
    const result = search(data, searchKey, optionsSearch.search)
    setSearchResults(result)
  }

  const filterCategory = (category, products) => {
    const findCategory = _.find(categoryList, (i) => {
      return i?.id === category || i?.name === category
    })
    const filterProductCategory = _.filter(products, (i) => {
      return (
        i?.category === findCategory?.id || i?.category === findCategory?.name
      )
    })
    return filterProductCategory
  }

  useEffect(() => {
    if (Product) {
      const resNoRef = _.filter(Product, (item) => item?.ref === undefined)
      const resRef = _.groupBy(
        _.filter(Product, (item) => item?.ref !== undefined),
        'ref'
      )
      const allRef = Object.keys(resRef).map((item, i) => {
        const data = resRef[item]
        return {
          ref: item,
          createdAt: data[0]?.createdAt,
          img: data.sort((a, b) => a.index - b.index)[0]?.img,
          name: data[0]?.name,
          category: data[0]?.category,
          options: data.sort((a, b) => a.index - b.index),
        }
      })

      const allData = _.flatten([resNoRef, allRef]).sort(
        (a, b) => a?.createdAt?.toDate() - b?.createdAt?.toDate()
      )

      if (optionsSearch?.search) {
        if (optionsSearch?.category) {
          return filterSearchData(
            filterCategory(optionsSearch?.category, allData)
          )
        } else {
          return filterSearchData(allData)
        }
      } else if (optionsSearch?.category) {
        return setSearchResults(
          filterCategory(optionsSearch?.category, allData)
        )
      } else {
        return setSearchResults(allData)
      }
    }
    //eslint-disable-next-line
  }, [Product, optionsSearch, categoryList])

  useEffect(() => {
    getAllProducts()
    getCategoryAll()
    setIsFadeCard(false)
    setIsFadePage(false)
  }, [getAllProducts, getCategoryAll])

  const textColor = useColorModeValue('secondaryGray.900', 'white')

  const columnsDataOrders = [
    {
      Header: 'ลำดับ',
      extra: (data, row, cell) => {
        return (
          <>
            <Text fontSize='md' fontWeight='500'>
              {`${parseFloat(cell?.row?.id) + 1}`}
            </Text>
          </>
        )
      },
    },
    {
      Header: 'สินค้า',
      accessor: 'name',
      extra: (data, row) => (
        <HStack key={row} w='full'>
          <Avatar src={row?.img} name={row?.name} />
          <VStack spacing={0} pl='0.5rem' alignItems='flex-start'>
            <Text
              textAlign='start'
              color={textColor}
              fontSize='md'
              fontWeight='500'
            >
              {row?.name}
            </Text>
            {row?.options?.length === undefined ? (
              <Text
                textAlign='start'
                color={textColor}
                fontSize='xs'
                fontWeight='500'
              >
                {row?.sku}
              </Text>
            ) : (
              <Text
                textAlign='start'
                color={textColor}
                fontSize='xs'
                fontWeight='500'
              >
                {row?.ref}
              </Text>
            )}
          </VStack>
        </HStack>
      ),
    },
    {
      Header: 'ตัวเลือกสินค้า',
      accessor: 'options',
      extra: (data, row) => (
        <Center key={row}>
          {row?.options ? (
            <Center>
              <VStack spacing='15px' alignItems='flex-start'>
                {row?.options?.map((item, index) => (
                  <Text
                    color={textColor}
                    fontSize='md'
                    fontWeight='500'
                    key={index}
                  >
                    {item?.nameOption}
                  </Text>
                ))}
              </VStack>
            </Center>
          ) : (
            <Text color={textColor} fontSize='md' fontWeight='500'>
              -
            </Text>
          )}
        </Center>
      ),
    },
    {
      Header: 'หมวดหมู่',
      accessor: 'category',
      extra: (data, row) => {
        const nameId = categoryList?.find((item) => item?.id === data)?.name
        const name = categoryList?.find((item) => item?.name === data)?.name
        return (
          <Center fontSize='md' fontWeight='500'>
            <Text>{nameId ? nameId : name}</Text>
          </Center>
        )
      },
    },
    {
      Header: 'ราคาต้นทุน',
      accessor: 'cost',
      extra: (data, row) => (
        <Center key={row} w={'145px'} justifyContent='flex-end'>
          {row?.options ? (
            <Flex w={'full'} justifyContent='flex-end'>
              <VStack spacing='15px' alignItems='flex-end'>
                {row?.options?.map((item, index) => (
                  <Text
                    key={index}
                    color={textColor}
                    fontSize='md'
                    fontWeight='500'
                  >
                    {currency(decodeUnit(item?.cost), 2, true)}
                  </Text>
                ))}
              </VStack>
            </Flex>
          ) : (
            <Text color={textColor} fontSize='md' fontWeight='500'>
              {currency(decodeUnit(row?.cost), 2, true)}
            </Text>
          )}
        </Center>
      ),
    },
    {
      Header: 'ราคาขาย',
      accessor: 'price',
      extra: (data, row) => (
        <Center key={row} w={'145px'} justifyContent='flex-end'>
          {row?.options ? (
            <Center w={'full'} justifyContent='flex-end'>
              <VStack spacing='15px' alignItems='flex-end'>
                {row?.options?.map((item, index) => (
                  <Text
                    key={index}
                    color={'table.500'}
                    fontSize='md'
                    fontWeight='500'
                  >
                    {currency(decodeUnit(item?.price), 2, true)}
                  </Text>
                ))}
              </VStack>
            </Center>
          ) : (
            <Text color={'table.500'} fontSize='md' fontWeight='500'>
              {currency(decodeUnit(row?.price), 2, true)}
            </Text>
          )}
        </Center>
      ),
    },
    {
      Header: 'คอมมิชชั่นในการขาย',
      accessor: 'commission',
      extra: (data, row) => (
        <Center key={row} maxW={'auto'}>
          {row?.options ? (
            <Center w={'full'}>
              <VStack spacing='15px' alignItems='flex-start'>
                {row?.options?.map((item, index) => (
                  <Text
                    key={index}
                    color={textColor}
                    fontSize='md'
                    fontWeight='500'
                  >
                    {item.typeCommission === 'Percentage'
                      ? `${currency(decodeUnit(item?.commission))}%`
                      : currency(decodeUnit(item?.commission), 2, true)}
                  </Text>
                ))}
              </VStack>
            </Center>
          ) : (
            <Text color={textColor} fontSize='md' fontWeight='500'>
              {row.typeCommission === 'Percentage'
                ? `${currency(decodeUnit(row?.commission))}%`
                : currency(decodeUnit(row?.commission), 2, true)}
            </Text>
          )}
        </Center>
      ),
    },
    {
      Header: 'จัดการ',
      accessor: 'manage',
      extra: (data, row) => (
        <Center pl='1rem'>
          <Manage
            value={row}
            pages={'/product/edit'}
            getAllProducts={getAllProducts}
          />
        </Center>
      ),
    },
  ]

  return (
    <>
      <Stack as={Fade} in={!isFadePage}>
        <Box>
          {!productLoading ? (
            <>
              <Box zIndex='base'>
                <HStack
                  my='1em'
                  spacing={{ base: '0px', sm: '0px', md: '10px' }}
                  p={{ sm: '0px', md: '20px 20px 30px 20px' }}
                  background={{ sm: 'none', md: '#FFFFFF' }}
                  boxShadow={{
                    sm: '0px',
                    md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
                  }}
                  borderRadius={{ sm: '10px', md: '30px' }}
                  justifyContent='flex-start'
                  flexDirection={{
                    base: 'column',
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                  }}
                >
                  <InputGroup w={{ base: '100%', md: '220px', lg: '250px' }}>
                    <InputLeftElement
                      children={
                        <IconButton
                          bg='inherit'
                          borderRadius='inherit'
                          _hover='none'
                          _active={{
                            bg: 'inherit',
                            transform: 'none',
                            borderColor: 'transparent',
                          }}
                          _focus={{
                            boxShadow: 'none',
                          }}
                          icon={
                            <SearchIcon color={'gray.700'} w='15px' h='15px' />
                          }
                        ></IconButton>
                      }
                    />
                    <Input
                      variant='search'
                      fontSize='sm'
                      bg={'secondaryGray.300'}
                      color={'gray.700'}
                      fontWeight='500'
                      _placeholder={{
                        color: 'gray.400',
                        fontSize: '14px',
                        opacity: 1,
                      }}
                      value={optionsSearch.search}
                      borderRadius={'30px'}
                      placeholder={'น้ำสมุนไพร, ชา'}
                      onChange={(e) => {
                        setOptionsSearch({
                          ...optionsSearch,
                          search: e.target.value,
                        })
                      }}
                    />
                  </InputGroup>

                  {/* <HStack spacing={'5px'}> */}
                  <Select
                    pt={{ base: '15px', sm: '15px', md: '0rem' }}
                    w={{ base: '100%', md: '250px' }}
                    h='44px'
                    maxh='44px'
                    variant='search'
                    border={{
                      base: '1px solid #E8E9E9',
                      sm: '1px solid #E8E9E9',
                      md: '0px',
                    }}
                    bg={{
                      base: 'white',
                      sm: 'white',
                      md: 'secondaryGray.300',
                    }}
                    fontSize='sm'
                    color={'gray.700'}
                    fontWeight='500'
                    _placeholder={{
                      color: 'gray.400',
                      fontSize: '14px',
                      opacity: 1,
                    }}
                    borderRadius={{
                      base: '10px',
                      sm: '10px',
                      md: '30px',
                      lg: '30px',
                    }}
                    onChange={(e) => {
                      setOptionsSearch({
                        ...optionsSearch,
                        category: e.target.value,
                      })
                    }}
                  >
                    <option value={''}>หมวดหมู่ทั้งหมด</option>
                    {categoryList?.map((categoryItem) => {
                      return (
                        <option value={categoryItem?.id}>
                          {categoryItem?.name}
                        </option>
                      )
                    })}
                  </Select>
                </HStack>
              </Box>
              <Card as={Fade} in={!isFadeCard} mt={'1rem'}>
                <CustomTable
                  columnsData={columnsDataOrders}
                  tableData={SearchResults}
                  pages={'/product/edit'}
                  disabledSearch={true}
                />
              </Card>
            </>
          ) : (
            <Card>
              <Center>
                <Spinner size='lg' />
              </Center>
            </Card>
          )}
        </Box>
      </Stack>
      <Menu>
        <MenuButton
          as={Button}
          colorScheme='btn'
          borderRadius='full'
          position='fixed'
          right={5}
          bottom={5}
          w={'50px'}
          h={'50px'}
          px='0'
        >
          <Center>
            <Icon as={BsPlus} fontSize={'45px'} />
          </Center>
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => Navigate('/product/add/o')}>
            <HStack>
              <Text>
                <FaBox />
              </Text>
              <Text>สินค้าตัวเลือกเดียว</Text>
            </HStack>
          </MenuItem>
          <MenuItem onClick={() => Navigate('/product/add/op')}>
            <HStack>
              <Text>
                <FaBoxes />
              </Text>
              <Text> สินค้าหลายตัวเลือก</Text>
            </HStack>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}

export default Products
