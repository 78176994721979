import { Stack } from '@chakra-ui/react'
import { FormExpensesCategory } from 'Components/form/formExpensesCategory'
import { useExpenses } from 'Hooks'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

function EditExpensesCategory() {
  const { id } = useParams()
  const { getExpensesCategoryId } = useExpenses()
  const [data, setData] = useState()
  const fetchData = useCallback(async () => {
    await getExpensesCategoryId(id).then((res) => {
      setData(res)
    })
  }, [getExpensesCategoryId, id])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Stack direction='column'>
      <FormExpensesCategory id={id} data={data} />
    </Stack>
  )
}

export default EditExpensesCategory
