import { ChevronLeftIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Flex,
  HStack,
  Input,
  Switch,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import ButtonResponsive from 'Components/Button/btn'
import Card from 'Components/Horizons/card/Card'
import { db } from 'Config/firebase'
import { useAppContext } from 'Context'
import { doc, updateDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  encodeUnit,
  switchTextPromotionType,
  switchTextPromotionTypeSecond,
  switchTextPromotionTypeThird,
} from 'Utils'
import { AmountCategory } from './components/amountCategory'

import { InfoModalCategory } from './components/categoryModal'

export default function FreeCategoryCategory({ data, id }) {
  const [discount, setDiscount] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)
  const { user } = useAppContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      setDiscount(data)
    }
  }, [data, id])

  const handleChange = (value, data) => {
    const dataValue = data.find((item) => item.id === value)
    setDiscount({
      ...discount,
      category: dataValue,
      categoryAmount: 1,
    })
    return discount
  }
  const handleChangeFree = (value, data) => {
    const dataValue = data.find((item) => item.id === value)
    setDiscount({
      ...discount,
      categoryFree: dataValue,
      freeCategoryAmount: 1,
    })
    return discount
  }

  function handleClickAdd() {
    setDiscount({
      ...discount,
      categoryAmount: discount?.categoryAmount + 1,
    })
  }

  function handleClickAddFree() {
    setDiscount({
      ...discount,
      freeCategoryAmount: discount?.freeCategoryAmount + 1,
    })
  }

  function handleClickRemove() {
    setDiscount({
      ...discount,
      categoryAmount:
        discount?.categoryAmount < 0 ? 0 : discount?.categoryAmount - 1,
    })
  }

  function handleClickRemoveFree() {
    setDiscount({
      ...discount,
      freeCategoryAmount:
        discount?.freeCategoryAmount <= 0
          ? 0
          : discount?.freeCategoryAmount - 1,
    })
  }

  const { handleSubmit } = useForm()

  function onSubmit() {
    setIsSubmit(true)
    updateDoc(doc(db, 'Promotion', id), {
      ...discount,
      categoryAmount: encodeUnit(discount?.categoryAmount)?.toString(),
      freeCategoryAmount: encodeUnit(discount?.freeCategoryAmount)?.toString(),
      updatedAt: new Date(),
      updatedBy: {
        id: user?.uid,
        name: user?.name,
      },
    }).then(() => {
      setIsSubmit(false)
      navigate('/discount/store')
    })
  }
  return (
    <Center>
      <Card
        padding={{
          base: '10px 0px 30px 0px',
          sm: '10px 0px 30px 0px',
          md: '10px 20px 30px 20px',
        }}
        w={{ base: '100%', sm: '100%', md: '40rem' }}
        boxShadow={{
          base: 'none',
          sm: 'none',
          md: '14px 17px 40px 4px rgb(112 144 176 / 8%)',
        }}
      >
        <HStack w='100%'>
          <ChevronLeftIcon
            cursor='pointer'
            color='form.500'
            fontSize={'2xl'}
            onClick={() => navigate('/discount/store')}
          />
        </HStack>
        <VStack
          alignItems='start'
          px={{ base: '0em', sm: '0em', md: '2em' }}
          borderBottom='1px'
          borderColor='#E0E5F2'
          py='2em'
        >
          <HStack alignItems='flex-start'>
            <Text minW='5.8rem'>รูปแบบส่วนลด</Text>
            <Text>:</Text>
            <Text color='discount.500'>
              {switchTextPromotionType(data?.type)}
            </Text>
          </HStack>
          <HStack alignItems='flex-start'>
            <Text minW='5.8rem'>ระดับส่วนลด</Text>
            <Text>:</Text>
            <Text color='discount.500'>
              {switchTextPromotionTypeSecond(data?.typeDiscount)}
            </Text>
          </HStack>
          <HStack alignItems='flex-start'>
            <Text minW='5.8rem'>ฟรีสินค้าประเภท</Text>
            <Text>:</Text>
            <Text color='discount.500'>
              {switchTextPromotionTypeThird(data?.typeFree)}
            </Text>
          </HStack>
        </VStack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            borderBottom='1px'
            borderColor='#E0E5F2'
            py='2em'
            px={{ base: '0em', sm: '0em', md: '2em' }}
          >
            <Text
              fontSize={{ base: 'md', sm: 'md', md: 'lg' }}
              fontWeight='bold'
            >
              รายละเอียดโปรโมชั่น
            </Text>
            <Center mt='1rem' w='100%'>
              <VStack spacing='25px' w='100%'>
                <VStack alignItems='flex-start' w='100%'>
                  <Text>ชื่อโปรโมชั่น</Text>
                  <Input
                    variant='main'
                    value={discount?.discountName || null}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      e.preventDefault()
                      let value = e.target.value
                      setDiscount({ ...discount, discountName: value })
                    }}
                  />
                </VStack>
                <VStack alignItems='flex-start' w='100%'>
                  <Text>รายละเอียดโปรโมชั่น</Text>
                  <Textarea
                    border='1px solid #e0e5f2'
                    borderRadius={{
                      base: '10px',
                      sm: '10px',
                      md: '10px',
                      lg: '16px',
                    }}
                    variant={'no-effects'}
                    value={discount?.discountDetail || null}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      e.preventDefault()
                      let value = e.target.value
                      setDiscount({ ...discount, discountDetail: value })
                    }}
                  />
                </VStack>
                <Flex justifyContent='space-between' w='100%'>
                  <Text>เปิด-ปิด </Text>
                  <Switch
                    defaultChecked={discount?.status || false}
                    isChecked={discount?.status}
                    colorScheme='teal'
                    onChange={(e) => {
                      let value = e.target.checked
                      setDiscount({ ...discount, status: value })
                    }}
                  />
                </Flex>
              </VStack>
            </Center>
          </Box>

          <Box
            borderBottom='1px'
            borderColor='#E0E5F2'
            py='2em'
            px={{ base: '0em', sm: '0em', md: '2em' }}
          >
            <Text
              fontSize={{ base: 'md', sm: 'md', md: 'lg' }}
              fontWeight='bold'
            >
              เงื่อนไข
            </Text>
            <InfoModalCategory
              handleChange={handleChange}
              discount={discount}
              setDiscount={setDiscount}
              value={discount?.category?.id}
              label={discount?.category ? 'เปลี่ยน' : '+ เลือกรายการหมวดหมู่'}
              children={
                discount?.category ? (
                  <Center>
                    <Text>หมวดหมู่ : {discount?.category?.name}</Text>
                  </Center>
                ) : null
              }
            />
            <Center mt='1.5rem'>
              <AmountCategory
                disabled={discount?.category ? false : true}
                handleAdd={handleClickAdd}
                handleRemove={handleClickRemove}
                label={
                  discount?.categoryAmount > 0 ? discount?.categoryAmount : 0
                }
              />
            </Center>
          </Box>

          <Box
            borderBottom='1px'
            borderColor='#E0E5F2'
            py='2em'
            px={{ base: '0em', sm: '0em', md: '2em' }}
          >
            <Text
              fontSize={{ base: 'md', sm: 'md', md: 'lg' }}
              fontWeight='bold'
            >
              แถมฟรีรายการ
            </Text>
            <InfoModalCategory
              handleChange={handleChangeFree}
              discount={discount}
              setDiscount={setDiscount}
              value={discount?.categoryFree?.id}
              label={
                discount?.categoryFree ? 'เปลี่ยน' : '+ เลือกรายการหมวดหมู่'
              }
              children={
                discount?.categoryFree ? (
                  <Center>
                    <Text>หมวดหมู่ : {discount?.categoryFree?.name}</Text>
                  </Center>
                ) : null
              }
            />
            <Center mt='1.5rem'>
              <AmountCategory
                disabled={discount?.categoryFree ? false : true}
                handleAdd={handleClickAddFree}
                handleRemove={handleClickRemoveFree}
                label={
                  discount?.freeCategoryAmount > 0
                    ? discount?.freeCategoryAmount
                    : 0
                }
              />
            </Center>
          </Box>
          <Box pt='1rem'>
            <HStack
              spacing={{ base: '0rem', sm: '0rem', md: '2rem' }}
              flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
              alignItems='flex-start'
            >
              <Text>สร้างโดย : {data?.createdBy?.name} </Text>
              <Text>
                วันที่ : {data?.createdAt?.toDate().toLocaleString('en-US')}
              </Text>
            </HStack>
            <HStack
              spacing={{ base: '0rem', sm: '0rem', md: '2rem' }}
              flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
              alignItems='flex-start'
            >
              <Text>
                แก้ไขโดย : {data?.updatedBy ? data?.updatedBy?.name : '-'}
              </Text>
              <Text>
                วันที่ :{' '}
                {data?.updatedAt
                  ? data?.updatedAt?.toDate().toLocaleString('en-US')
                  : '-'}
              </Text>
            </HStack>
          </Box>
          <HStack py='2em' justifyContent='end'>
            <ButtonResponsive
              disabled={discount?.discountName ? false : true}
              label={'บันทึก'}
              type={'submit'}
              isLoading={isSubmit}
              loadingText={'กำลังบันทึก...'}
            />
          </HStack>
        </form>
      </Card>
    </Center>
  )
}
