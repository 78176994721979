import { Button, HStack, Text } from '@chakra-ui/react'
import { CSVLink } from 'react-csv'
import { AiOutlineDownload } from 'react-icons/ai'

function CSVDownloadForm(props) {
  const { data, headers, filename, buttonTitle } = props
  return (
    <HStack {...props}>
      {data?.length > 0 ? (
        <CSVLink
          data={data}
          disable={!data?.length > 0 ? true : false}
          headers={headers}
          filename={`${filename}_${new Date().getTime()}.csv`}
        >
          <Button
            h='44px'
            maxh='44px'
            // w={{ sm: '10rem', md: 'full' }}
            colorScheme={'twitter'}
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            disabled={!data?.length > 0}
          >
            <HStack>
              <Text>
                <AiOutlineDownload />
              </Text>
              <Text>{buttonTitle}</Text>
            </HStack>
          </Button>
        </CSVLink>
      ) : (
        <Button
          h='44px'
          maxh='44px'
          // w={{ sm: '10rem', md: 'full' }}
          colorScheme={'twitter'}
          borderRadius='16px'
          display='flex'
          justifyContent={'space-around'}
          color='white'
          disabled={!data?.length > 0}
        >
          <HStack>
            <Text>
              <AiOutlineDownload />
            </Text>
            <Text>{buttonTitle}</Text>
          </HStack>
        </Button>
      )}
    </HStack>
  )
}

export default CSVDownloadForm
