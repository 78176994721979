import { Stack } from '@chakra-ui/react'
import { useExpenses } from 'Hooks'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ViewExpensesForm from './components/form'

function ViewExpenses({ user }) {
  const { id } = useParams()
  const { getExpensesId } = useExpenses()
  const [data, setData] = useState()

  const fetchData = useCallback(async () => {
    await getExpensesId(id).then((res) => {
      setData(res)
    })
  }, [getExpensesId, id])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Stack direction='column'>
      <ViewExpensesForm id={id} data={data} />
    </Stack>
  )
}

export default ViewExpenses
