import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useAuth, useBranch, useOrder } from 'Hooks'
import { useCallback, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import _ from 'underscore'
import {
  calCommissionEmployeeDailyWithEncode,
  currency,
  decodeUnit,
} from 'Utils'
import { useWork } from 'Hooks/work'
import CustomTable from 'Components/Horizons/table/customTable'
import Card from 'Components/Horizons/card/Card'
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import { BiCoin } from 'react-icons/bi'
import { GiMoneyStack } from 'react-icons/gi'
import moment from 'moment'
import CSVDownloadForm from 'Components/csvDownload'

function StaffCommissionEmployee(props) {
  const [search] = useSearchParams()
  const { getAllOrdersWithDateByUserId } = useOrder()
  const { getUser } = useAuth()
  const { getBranchs } = useBranch()
  const { getWorkByUserId } = useWork()
  const [isLoading, setIsLoading] = useState(true)
  const [userData, setUserData] = useState()
  const { id, type } = useParams()
  const [data, setData] = useState([])
  const [csvData, setCSVData] = useState([])

  const csvHeaders = [
    { label: 'ชื่อพนักงาน', key: 'name' },
    { label: 'วันที่', key: 'date' },
    { label: 'ค่าจ้าง', key: 'salary' },
    { label: 'ค่าคอมมิชชั่น', key: 'commission' },
    { label: 'ค่าจ้างรวม', key: 'totalWage' },
    { label: 'เวลาเข้างาน', key: 'workIn' },
    { label: 'เวลาออกงาน', key: 'workOut' },
    { label: 'ชั่วโมงการทำงาน', key: 'work' },
  ]

  const calCommssionData = async (orders, users, branchs, workTime) => {
    const calCom = calCommissionEmployeeDailyWithEncode(orders, users, workTime)
    const mapBranchData = await Promise.all(
      _.map(calCom, async (orderCalCom) => {
        const filterBranch = await _.find(branchs, (branchData) => {
          return orderCalCom.branch === branchData.id
        })
        return {
          ...orderCalCom,
          branchName: filterBranch?.name,
        }
      }),
      []
    )
    return mapBranchData
  }

  const dataCard = [
    {
      label: 'ค่าจ้าง',
      value: userData?.salary ? currency(userData?.salary, 2) : 0,
      colorIcon: '#2B3674',
      colorText: '#2B3674',
      icon: FaRegMoneyBillAlt,
    },
    {
      label: 'ค่าคอมมิชชั่น',
      value: userData?.commission ? currency(userData?.commission, 2) : 0,
      colorIcon: '#01B574',
      colorText: '#2B3674',
      icon: BiCoin,
    },
    {
      label: 'รวม',
      value: userData?.total ? currency(userData?.total, 2) : 0,
      colorIcon: '#FF5722',
      colorText: '#2B3674',
      icon: GiMoneyStack,
    },
  ]

  const fetchOrders = useCallback(async () => {
    const getUserData = await getUser(id)
    const getBranchData = await getBranchs()
    const getWorkTime = await getWorkByUserId({
      userId: id,
      type: type,
      month: search.get('month'),
      endDate: search.get('endDate'),
      dateTime: search.get('dateTime'),
    })
    const getOrders = await getAllOrdersWithDateByUserId({
      userId: id,
      type: type,
      month: search.get('month'),
      endDate: search.get('endDate'),
      dateTime: search.get('dateTime'),
    })
    const calCom = await calCommssionData(
      getOrders,
      [{ ...getUserData, id: id, salary: decodeUnit(getUserData.salary) }],
      getBranchData,
      getWorkTime
    )
    const pluckCommission = _.pluck(calCom, 'grandCommission')
    const sumCommission = _.reduce(
      pluckCommission,
      (memo, num) => memo + num,
      0
    )
    // const pluckTotal = _.pluck(calCom, 'totalWage')
    // const sumTotal = _.reduce(pluckTotal, (memo, num) => memo + num, 0)
    const pluckSalary = _.pluck(calCom, 'salary')
    const sumSalary = _.reduce(pluckSalary, (memo, num) => memo + num, 0)
    setUserData({
      ...getUserData,
      commission: sumCommission,
      // total: sumTotal,
      total: sumCommission + sumSalary,
      salary: sumSalary,
    })
    const dataToCSV = _.map(calCom, (items) => {
      return {
        ...items,
        name: getUserData.name,
        branch: items.branchName,
        work: items.work.diff,
        workIn: items.work.in
          ? moment(items.work.in).format('MM/DD/YYYY HH:mm:ss')
          : '-',
        workOut: items.work.out
          ? moment(items.work.out).format('MM/DD/YYYY HH:mm:ss')
          : '-',
        salary: currency(items.salary, 2),
        commission: currency(items.commission, 2),
        totalWage: currency(items.totalWage, 2),
      }
    })
    setCSVData(dataToCSV)
    setData(calCom)
    setIsLoading(false)
    return
    //eslint-disable-next-line
  }, [search, id])

  useEffect(() => {
    fetchOrders()
    //eslint-disable-next-line
  }, [])

  return (
    <>
      {!isLoading ? (
        <HStack
          my='1em'
          p={{ sm: '0px', md: '20px 20px 30px 20px' }}
          background={{ sm: 'none', md: '#FFFFFF' }}
          boxShadow={{
            sm: '0px',
            md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
          }}
          borderRadius={{ sm: '10px', md: '30px' }}
          spacing='0vw'
          flexDirection={{ sm: 'column', md: 'column' }}
          w={'full'}
        >
          <VStack
            alignItems={{ sm: 'flex-end', md: 'start' }}
            spacing={{ sm: '0vh', md: '1vw' }}
            w='full'
          >
            <Box w='full'>
              <HStack justifyContent={'space-between'}>
                <Text fontSize={'xl'} fontWeight={'bold'} color={'#358142'}>
                  พนักงาน: {userData?.name}
                </Text>
                <CSVDownloadForm
                  data={csvData}
                  headers={csvHeaders}
                  filename={id}
                  buttonTitle={'ดาวน์โหลดไฟล์พนักงาน'}
                />
              </HStack>
            </Box>
            <Grid
              pb='0.5rem'
              templateColumns={{
                base: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(2, 1fr)',
                xl: 'repeat(3, 1fr)',
                '2xl': 'repeat(3, 1fr)',
              }}
              w={'full'}
              gap={{ base: '12px', sm: '12px', md: '20px' }}
            >
              {dataCard?.map((item, index) => (
                <GridItem key={index}>
                  <CardShow
                    value={item?.value}
                    colorIcon={item?.colorIcon}
                    colorText={item?.colorText}
                    icon={item?.icon}
                    label={item?.label}
                  />
                </GridItem>
              ))}
            </Grid>
          </VStack>

          <CustomTable
            columnsData={columnsData}
            tableData={data.length > 0 ? data : []}
            closeSearch={true}
          />
        </HStack>
      ) : (
        <>
          <Center
            h={{ base: '70vh', sm: '70vh', md: '50vh', lg: '50vh' }}
            flexDirection='column'
            w='100%'
          >
            <Center flexDirection='column'>
              <Text pb='1rem' fontSize='xl' color='loading.100'>
                รอดำเนินการ
              </Text>
              <Box className='loading' color='loading.100'></Box>
            </Center>
          </Center>
        </>
      )}
    </>
  )
}

export default StaffCommissionEmployee

const columnsData = [
  {
    Header: 'วันที่',
    accessor: 'date',
    extra: (data, row) => (
      <Box>
        <Text>{row?.date}</Text>
      </Box>
    ),
  },
  {
    Header: 'สาขา',
    accessor: 'branchName',
    extra: (data, row) => (
      <Center>
        <Text>{row?.branchName}</Text>
      </Center>
    ),
  },
  {
    Header: 'ชั่วโมงการทำงาน',
    accessor: 'workTime',
    extra: (data, row) => (
      <Center>
        <Text>{row?.work?.diff ? row?.work?.diff : 0.0} ชั่วโมง</Text>
      </Center>
    ),
  },
  {
    Header: 'เวลาทำงาน',
    accessor: 'workTime1',
    extra: (data, row) => (
      <Center>
        <Text>
          {row?.work?.in ? moment(row?.work?.in).format('HH:mm:ss') : ''}
          &nbsp;-&nbsp;
          {row?.work?.out ? moment(row?.work?.out).format('HH:mm:ss') : ''}
        </Text>
      </Center>
    ),
  },
  {
    Header: (
      <Text w='100%' textAlign='end'>
        ค่าจ้าง
      </Text>
    ),
    accessor: 'salary',
    extra: (data, row) => (
      <Center w='100%' justifyContent='flex-end'>
        <Text>
          {row?.work?.diff >= 1
            ? row?.salary
              ? currency(parseFloat(row?.salary), 2)
              : 0.0
            : 0}
        </Text>
      </Center>
    ),
  },
  {
    Header: (
      <Text w='100%' textAlign='end'>
        ค่าคอมมิชชั่น
      </Text>
    ),
    accessor: 'grandCommission',
    extra: (data, row) => (
      <Center w='100%' justifyContent='flex-end'>
        <Text>{currency(row?.grandCommission, 2)}</Text>
      </Center>
    ),
  },
  {
    Header: (
      <Text w='100%' textAlign='end'>
        ค่าจ้างรวม
      </Text>
    ),
    accessor: 'totalWage',
    extra: (data, row) => (
      <Center w='100%' justifyContent='flex-end'>
        <Text>{currency(parseFloat(row?.totalWage), 2)}</Text>
      </Center>
    ),
  },
]

const CardShow = ({ icon, colorIcon, label, colorText, value }) => {
  return (
    <Card py='2rem' pl={{ base: '60px', sm: '60px', md: '0px' }} pr='0px'>
      <Center
        justifyContent={{
          base: 'flex-start',
          sm: 'flex-start',
          md: 'center',
        }}
      >
        <HStack spacing='1.5rem'>
          <Icon
            fontSize={{
              base: '5xl',
              sm: '5xl',
              md: '5xl',
              lg: '5xl',
              xl: '4xl',
              '2xl': '5xl',
            }}
            as={icon}
            color={colorIcon}
          />
          <Flex flexDirection='column' alignItems='flex-start' spacing='0px'>
            <Text fontSize='md' mt='0.5rem'>
              {label}
            </Text>
            <Text
              fontSize={{
                base: '2xl',
                sm: '2xl',
                md: '3xl',
                lg: '3xl',
                xl: '2xl',
                '2xl': '3xl',
              }}
              mt='-0.5rem'
              color={colorText}
            >
              {value}
            </Text>
          </Flex>
        </HStack>
      </Center>
    </Card>
  )
}
