import { calOrderCostPaidRefund } from './cost'

const _ = require('underscore')

export function calSellingPriceOrder(order) {
  const refund = _.filter(order, (itm) => itm.status === 'refund')
  const totalRefund = _.pluck(refund, 'total').reduce(
    (memo, num) => memo + num,
    0
  )

  const filterRefund = _.filter(order, (itm) => itm.status !== 'refund')
  const totalOrder = _.pluck(filterRefund, 'total').reduce(
    (memo, num) => memo + num,
    0
  )
  const refundCost = calOrderCostPaidRefund(refund)
  const filterRefundCost = calOrderCostPaidRefund(filterRefund)
  return {
    total: totalOrder - totalRefund,
    cost: filterRefundCost - refundCost,
  }
}

export function calSellingPriceOrderNoRefund(order) {
  const paid = _.filter(order, (itm) => itm.status === 'paid')
  const totalOrder = _.pluck(paid, 'total').reduce((memo, num) => memo + num, 0)
  const paidCost = calOrderCostPaidRefund(paid)
  return {
    total: totalOrder,
    cost: paidCost,
  }
}

export function calSP(order) {
  const totalOrder = _.pluck(order, 'total').reduce(
    (memo, num) => memo + num,
    0
  )
  const Cost = calOrderCostPaidRefund(order)

  return {
    total: totalOrder,
    cost: Cost,
  }
}
