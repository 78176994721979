import { DeleteIcon } from '@chakra-ui/icons'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Fade,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import CategoryTable from 'Components/Horizons/table/categoryTable'
import { useProduct } from 'Hooks'
import { useCategory } from 'Hooks/category'
import { useEffect, useRef, useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { HiPencil } from 'react-icons/hi'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

function LinkToEdit({ id, pages }) {
  const Navigate = useNavigate()

  return (
    <HStack
      id={id}
      cursor='pointer'
      onClick={() => {
        Navigate(`${pages}/${id}`)
      }}
      transition='all 0.3s'
      _hover={{ color: 'product.500', transition: 'all 0.3s' }}
    >
      <Icon as={HiPencil} h='16px' w='16px' me='8px' />
      <Text fontSize='sm' fontWeight='400'>
        แก้ไข
      </Text>
    </HStack>
  )
}

function AlertDialogButton({ id, getCategoryAll }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const { deleteCategory } = useCategory()

  async function handleDelete(id) {
    await deleteCategory(id).then(() => {
      getCategoryAll()
    })
    await onClose()
  }
  return (
    <>
      <HStack
        onClick={onOpen}
        transition='all 0.3s'
        _hover={{ color: 'red', transition: 'all 0.3s' }}
      >
        <Icon as={DeleteIcon} h='16px' w='16px' me='8px' />
        <Text fontSize='sm' fontWeight='400'>
          ลบ
        </Text>
      </HStack>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size='auto'
      >
        <AlertDialogOverlay />

        <AlertDialogContent
          fontFamily={'Prompt'}
          w={{ sm: '20.5rem', md: '25rem' }}
        >
          <AlertDialogHeader>คุณแน่ใจ?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody fontFamily={'Prompt'}>
            คุณแน่ใจที่จะลบหมวดหมู่นี้ใช่หรือไม่ ?
          </AlertDialogBody>
          <AlertDialogFooter fontFamily={'Prompt'}>
            <Button ref={cancelRef} onClick={onClose}>
              ไม่
            </Button>
            <Button
              colorScheme='red'
              ml={3}
              onClick={() => {
                handleDelete(id)
              }}
            >
              ใช่
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

function Manage({ value, pages, getCategoryAll }) {
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure()

  const textColor2 = useColorModeValue('secondaryGray.500', 'white')
  const textHover = useColorModeValue(
    { color: 'secondaryGray.900', bg: 'unset' },
    { color: 'secondaryGray.500', bg: 'unset' }
  )
  const iconColor = useColorModeValue('#494D51', 'white')
  const bgList = useColorModeValue('white', 'whiteAlpha.100')
  const bgShadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    'unset'
  )
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100')
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' }
  )
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' }
  )
  return (
    <Menu isOpen={isOpen1} onClose={onClose1}>
      <MenuButton
        align='center'
        justifyContent='center'
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        w='37px'
        h='37px'
        lineHeight='100%'
        onClick={onOpen1}
        borderRadius='10px'
      >
        <Icon as={MdOutlineMoreHoriz} color={iconColor} w='24px' h='24px' />
      </MenuButton>
      <MenuList
        w='150px'
        minW='unset'
        maxW='150px !important'
        border='transparent'
        backdropFilter='blur(63px)'
        bg={bgList}
        boxShadow={bgShadow}
        borderRadius='20px'
        p='15px'
      >
        <MenuItem
          transition='0.2s linear'
          color={textColor2}
          _hover={textHover}
          p='0px'
          borderRadius='8px'
          _active={{
            bg: 'transparent',
          }}
          _focus={{
            bg: 'transparent',
          }}
          mb='10px'
        >
          <Flex align='center'>
            <LinkToEdit id={value} pages={pages} />
          </Flex>
        </MenuItem>
        <MenuItem
          transition='0.2s linear'
          p='0px'
          borderRadius='8px'
          color={textColor2}
          _hover={textHover}
          _active={{
            bg: 'transparent',
          }}
          _focus={{
            bg: 'transparent',
          }}
          mb='10px'
        >
          <Flex align='center'>
            <AlertDialogButton id={value} getCategoryAll={getCategoryAll} />
          </Flex>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

function ProductCategory({ user }) {
  const { category, isLoading, getCategoryAll } = useCategory()
  const { Product, getAllProducts } = useProduct()
  const [isFadePage, setIsFadePage] = useState(true)
  const [isFadeCard, setIsFadeCard] = useState(true)
  const Navigate = useNavigate()
  const textColor = useColorModeValue('secondaryGray.900', 'white')

  const data = category.map((item) => ({
    ...item,
    total: Product.filter(
      (product) =>
        product.category === item?.id || product.category === item?.name
    )?.length,
  }))
  useEffect(() => {
    getCategoryAll()
    getAllProducts()
    setTimeout(() => {
      setIsFadePage(false)
      setTimeout(() => {
        setIsFadeCard(false)
      }, 300)
    }, 200)
  }, [getCategoryAll, getAllProducts])

  const columnsDataOrders = [
    {
      Header: 'รหัสหมวดหมู่',
      accessor: 'idCategory',
    },
    {
      Header: 'ชื่อหมวดหมู่',
      accessor: 'name',
    },
    {
      Header: 'จำนวน',
      accessor: 'total',
      extra: (data, row) => {
        return (
          <HStack justifyContent='space-between'>
            <Box maxW={'145px'}>
              <Text
                color={textColor}
                fontSize='md'
                fontWeight='500'
                noOfLines={1}
              >
                {row.total}
              </Text>
            </Box>
          </HStack>
        )
      },
    },
    {
      Header: 'จัดการ',
      accessor: 'manage',
      extra: (data, row) => (
        <Manage
          value={row?.id}
          pages={'/product/category/edit'}
          getCategoryAll={getCategoryAll}
        />
      ),
    },
  ]
  return (
    <>
      <Stack as={Fade} in={!isFadePage}>
        <Box>
          {!isLoading ? (
            <Card as={Fade} in={!isFadeCard}>
              <CategoryTable
                columnsData={columnsDataOrders}
                tableData={data}
                pages={''}
              />
            </Card>
          ) : (
            <Card>
              <Center>
                <Spinner size='lg' />
              </Center>
            </Card>
          )}
        </Box>
      </Stack>
      <Button
        colorScheme='btn'
        to='/branchs/add'
        borderRadius='full'
        position='fixed'
        right={5}
        bottom={5}
        w={'50px'}
        h={'50px'}
        onClick={() => {
          Navigate('/product/category/add')
        }}
      >
        <Icon as={BsPlus} fontSize={'45px'} />
      </Button>
    </>
  )
}

export default ProductCategory
