import { db, storage } from 'Config/firebase'
import { useAppContext } from 'Context'
import {
  addDoc,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { getDownloadURL, ref, uploadString } from 'firebase/storage'
import moment from 'moment'
import { useCallback, useState } from 'react'
import _ from 'underscore'

export const useWork = () => {
  const [workList, setWorkList] = useState()
  const { user } = useAppContext()
  const [isLoading, setIsLoading] = useState(true)
  const workRef = collection(db, 'Works')

  const userOnWork = (image) => {
    const storageRef = ref(
      storage,
      `Work/In/${user.uid}/${moment().format('DD-MM-YYYY--HH-mm-ss')}`
    )

    if (image) {
      return addDoc(collection(db, 'Works'), {
        user: { id: user?.uid, name: user?.name, email: user?.email },
        createdAt: new Date(),
        role: user?.role,
        branch: {
          id: user?.branchSelect?.id,
          name: user?.branchSelect?.name,
        },
        method: 'in',
      }).then((e) => {
        const workTimeRef = doc(db, 'Works', e.id)
        return uploadString(storageRef, image, 'data_url').then(async () => {
          const downloadURL = await getDownloadURL(storageRef)
          updateDoc(workTimeRef, { img: downloadURL }).then(() => {
            // return {
            //   docId: e.id,
            // }
            return updateDoc(doc(db, 'Users', user?.uid), {
              workRef: e.id,
            })
          })
        })
      })
    }
  }

  const userOffWork = (image) => {
    const storageRef = ref(
      storage,
      `Work/Out/${user.uid}/${moment().format('DD-MM-YYYY--HH-mm-ss')}`
    )

    return addDoc(collection(db, 'Works'), {
      user: { id: user?.uid, name: user?.name, email: user?.email },
      createdAt: new Date(),
      role: user?.role,
      branch: {
        id: user?.branchSelect?.id,
        name: user?.branchSelect?.name,
      },
      ref: user?.workRef,
      method: 'out',
    }).then((e) => {
      if (image) {
        const workTimeRef = doc(db, 'Works', e.id)
        return uploadString(storageRef, image, 'data_url').then(async () => {
          const downloadURL = await getDownloadURL(storageRef)
          updateDoc(workTimeRef, { img: downloadURL }).then((e) => {
            return updateDoc(doc(db, 'Users', user?.uid), {
              workRef: deleteField(),
            })
          })
        })
      } else {
        return updateDoc(doc(db, 'Users', user?.uid), {
          workRef: deleteField(),
        })
      }
    })
  }

  const getWorkById = async (workId) => {
    const workDoc = await doc(workRef, workId)
    const getDocData = await getDoc(workDoc)
    return getDocData.data()
  }

  const getUserWork = useCallback(async () => {
    const getSnapAllWorkRef = query(
      workRef,
      where('role', '!=', 'Administrator'),
      orderBy('createdAt', 'desc')
    )
    const getData = await getDocs(getSnapAllWorkRef)
    const data = await _.map(getData?.docs, (doc) => {
      return {
        id: doc?.id,
        ...doc?.data(),
      }
    })
    setWorkList(data)
    setIsLoading(false)
    //eslint-disable-next-line
  }, [])

  const getWorkListWithBranchTime = async (startDate, endDate, branch) => {
    let getWork = query(workRef, orderBy('createdAt', 'desc'))
    if (branch) {
      if (startDate && endDate) {
        const start = new Date(moment(new Date(startDate)).startOf('day'))

        const dateEnd = new Date(moment(new Date(endDate)).endOf('day'))
        getWork = query(
          workRef,
          where('createdAt', '>=', start),
          where('createdAt', '<=', dateEnd),
          where('branch.id', '==', branch),
          orderBy('createdAt', 'desc')
        )
      } else if (startDate) {
        const start = new Date(startDate)
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)
        start.setMilliseconds(0)
        getWork = query(
          workRef,
          where('createdAt', '>=', start),
          where('branch.id', '==', branch),
          orderBy('createdAt', 'desc')
        )
      } else if (endDate) {
        const dateEnd = new Date(endDate)
        dateEnd.setDate(dateEnd.getDate() + 1)
        dateEnd.setHours(0)
        dateEnd.setMinutes(0)
        dateEnd.setSeconds(0)
        dateEnd.setMilliseconds(0)
        getWork = query(
          workRef,
          where('createdAt', '<=', dateEnd),
          where('branch.id', '==', branch),
          orderBy('createdAt', 'desc')
        )
      } else {
        getWork = query(
          workRef,
          where('branch.id', '==', branch),
          orderBy('createdAt', 'desc')
        )
      }
    } else if (startDate) {
      if (endDate) {
        const dateEnd = new Date(endDate)
        dateEnd.setDate(dateEnd.getDate() + 1)
        dateEnd.setHours(0)
        dateEnd.setMinutes(0)
        dateEnd.setSeconds(0)
        dateEnd.setMilliseconds(0)
        getWork = query(
          workRef,
          where('createdAt', '<=', dateEnd),
          orderBy('createdAt', 'desc')
        )
      } else {
        const start = new Date(startDate)
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)
        start.setMilliseconds(0)
        getWork = query(
          workRef,
          where('createdAt', '>=', start),
          orderBy('createdAt', 'desc')
        )
      }
    } else {
      const dateEnd = new Date(endDate)
      dateEnd.setDate(dateEnd.getDate() + 1)
      dateEnd.setHours(0)
      dateEnd.setMinutes(0)
      dateEnd.setSeconds(0)
      dateEnd.setMilliseconds(0)
      getWork = query(
        workRef,
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )
    }
    return await (
      await getDocs(getWork)
    ).docs?.map((e) => ({
      id: e?.id,
      createdAt: e?.data()?.createdAt,
      ...e?.data(),
    }))
  }

  const getUserWorkDataWithBranch = async (
    startDate,
    endDate,
    month,
    between,
    prevMonth,
    currentDate,
    currentMonth,
    startDateTime,
    endDateTime,
    dateTime
  ) => {
    let getWork
    let getWorkOut
    const toDay = new Date(moment().startOf('D'))
    if (currentDate) {
      getWork = query(
        workRef,
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', toDay),
        where('method', '==', 'in'),
        orderBy('createdAt', 'desc')
      )

      getWorkOut = query(
        workRef,
        where('branch.id', '==', user?.branchSelect?.id),
        where(
          'createdAt',
          '>=',
          new Date(moment(toDay).startOf('D').subtract(1, 'd'))
        ),
        where('method', '==', 'out'),
        orderBy('createdAt', 'desc')
      )
    } else if (currentMonth) {
      if (prevMonth) {
        getWork = query(
          workRef,
          where('branch.id', '==', user?.branchSelect?.id),
          where('createdAt', '>=', new Date(moment(month).startOf('month'))),
          where('createdAt', '<=', new Date(moment(month).endOf('month'))),
          where('method', '==', 'in'),
          orderBy('createdAt', 'desc')
        )
        getWorkOut = query(
          workRef,
          where('branch.id', '==', user?.branchSelect?.id),
          where(
            'createdAt',
            '>=',
            new Date(moment(month).startOf('M').subtract(1, 'd'))
          ),
          where('createdAt', '<=', new Date(moment(month).endOf('M'))).add(
            1,
            'd'
          ),
          where('method', '==', 'out'),
          orderBy('createdAt', 'desc')
        )
      } else {
        getWork = query(
          workRef,
          where('branch.id', '==', user?.branchSelect?.id),
          where('createdAt', '>=', new Date(moment().startOf('month'))),
          where('method', '==', 'in'),
          orderBy('createdAt', 'desc')
        )
        getWorkOut = query(
          workRef,
          where('branch.id', '==', user?.branchSelect?.id),
          where(
            'createdAt',
            '>=',
            new Date(moment().startOf('M').subtract(1, 'd'))
          ),
          where('method', '==', 'out'),
          orderBy('createdAt', 'desc')
        )
      }
    } else if (dateTime) {
      getWork = query(
        workRef,
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', new Date(moment(startDateTime))),
        where('createdAt', '<=', new Date(moment(endDateTime))),
        where('method', '==', 'in'),
        orderBy('createdAt', 'desc')
      )
      getWorkOut = query(
        workRef,
        where('branch.id', '==', user?.branchSelect?.id),
        where(
          'createdAt',
          '>=',
          new Date(moment(startDateTime).subtract(1, 'd'))
        ),
        where('createdAt', '<=', new Date(moment(endDateTime).add(1, 'd'))),
        where('method', '==', 'out'),
        orderBy('createdAt', 'desc')
      )
    } else if (prevMonth) {
      getWork = query(
        workRef,
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', new Date(moment(month).startOf('month'))),
        where('createdAt', '<=', new Date(moment(month).endOf('month'))),
        where('method', '==', 'in'),
        orderBy('createdAt', 'desc')
      )
      getWorkOut = query(
        workRef,
        where('branch.id', '==', user?.branchSelect?.id),
        where(
          'createdAt',
          '>=',
          new Date(moment(month).startOf('M').subtract(1, 'd'))
        ),
        where(
          'createdAt',
          '<=',
          new Date(moment(month).endOf('M').add(1, 'd'))
        ),
        where('method', '==', 'out'),
        orderBy('createdAt', 'desc')
      )
    } else if (between) {
      getWork = query(
        workRef,
        where('branch.id', '==', user?.branchSelect?.id),
        where('createdAt', '>=', new Date(moment(startDate).startOf('D'))),
        where('createdAt', '<=', new Date(moment(endDate).endOf('D'))),
        where('method', '==', 'in'),
        orderBy('createdAt', 'desc')
      )
      getWorkOut = query(
        workRef,
        where('branch.id', '==', user?.branchSelect?.id),
        where(
          'createdAt',
          '>=',
          new Date(moment(startDate).startOf('D').subtract(1, 'd'))
        ),
        where(
          'createdAt',
          '<=',
          new Date(moment(endDate).endOf('D').add(1, 'd'))
        ),
        where('method', '==', 'out'),
        orderBy('createdAt', 'desc')
      )
    }

    const data = await (
      await getDocs(getWork)
    )?.docs?.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
        date: doc
          .data()
          .createdAt.toDate()
          .toLocaleString('th-TH')
          .split(' ')[0],
      }
    })

    const workOutDBData = await (
      await getDocs(getWorkOut)
    )?.docs?.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
        date: doc
          .data()
          .createdAt.toDate()
          .toLocaleString('th-TH')
          .split(' ')[0],
      }
    })

    const matchInOutWorkTime = await Promise.all(
      await _.map(data, async (item) => {
        const getOffWork = await _.find(workOutDBData, (i) => {
          return item.id === i.ref
        })

        const workMinute = moment(getOffWork?.createdAt?.toDate()).diff(
          item?.createdAt?.toDate(),
          'minute'
        )
        const workHours = moment(getOffWork?.createdAt?.toDate()).diff(
          item?.createdAt?.toDate(),
          'hours'
        )
        const getDiffMinute = workMinute - workHours * 60

        return {
          outTime: getOffWork?.createdAt ? getOffWork?.createdAt : null,
          inTime: item?.createdAt,
          branch: {
            id: item?.branch?.id,
            name: item?.branch?.name,
          },
          img: item?.img,
          imgLogout: getOffWork?.img,
          role: item?.role,
          user: {
            id: item?.user?.id,
            name: item?.user?.name,
            email: item?.user?.email,
          },
          detailLogout: getOffWork?.detail,
          date: item?.date,
          diff: getOffWork?.createdAt ? (workHours > 8 ? 8 : workHours) : 0,
          diffMinute: workHours > 8 ? 0 : getDiffMinute > 0 ? getDiffMinute : 0,
          rawDiff: getOffWork?.createdAt
            ? moment(getOffWork?.createdAt?.toDate()).diff(
                item?.createdAt?.toDate(),
                'hours'
              )
            : 0,
        }
      }),
      []
    )
    return (await matchInOutWorkTime) ? matchInOutWorkTime : []
  }

  const getUserAllWorkData = async ({
    selectBranch,
    startDate,
    endDate,
    month,
    between,
    prevMonth,
    currentDate,
    currentMonth,
    startDateTime,
    endDateTime,
    dateTime,
  }) => {
    let getWork
    let getWorkOut
    const toDay = new Date(moment().startOf('D'))

    if (currentDate) {
      if (between) {
        if (selectBranch) {
          getWork = query(
            workRef,
            where('branch.id', '==', selectBranch),
            where('createdAt', '>=', new Date(moment(startDate).startOf('D'))),
            where('createdAt', '<=', new Date(moment(endDate).endOf('D'))),
            where('method', '==', 'in'),
            orderBy('createdAt', 'desc')
          )

          getWorkOut = query(
            workRef,
            where('branch.id', '==', selectBranch),
            where(
              'createdAt',
              '>=',
              new Date(moment(startDate).startOf('D').subtract(1, 'd'))
            ),
            where(
              'createdAt',
              '<=',
              new Date(moment(endDate).endOf('D').add(1, 'd'))
            ),
            where('method', '==', 'out'),
            orderBy('createdAt', 'desc')
          )
        } else {
          getWork = query(
            workRef,
            where('createdAt', '>=', new Date(moment(startDate).startOf('D'))),
            where('createdAt', '<=', new Date(moment(endDate).endOf('D'))),
            where('method', '==', 'in'),
            orderBy('createdAt', 'desc')
          )

          getWorkOut = query(
            workRef,
            where(
              'createdAt',
              '>=',
              new Date(moment(startDate).startOf('D').subtract(1, 'd'))
            ),
            where(
              'createdAt',
              '<=',
              new Date(moment(endDate).endOf('D').add(1, 'd'))
            ),
            where('method', '==', 'out'),
            orderBy('createdAt', 'desc')
          )
        }
      } else {
        if (selectBranch) {
          getWork = query(
            workRef,
            where('branch.id', '==', selectBranch),
            where('createdAt', '>=', toDay),
            where('method', '==', 'in'),
            orderBy('createdAt', 'desc')
          )

          getWorkOut = query(
            workRef,
            where('branch.id', '==', selectBranch),
            where(
              'createdAt',
              '>=',
              new Date(moment(toDay).startOf('D').subtract(1, 'd'))
            ),
            where('method', '==', 'out'),
            orderBy('createdAt', 'desc')
          )
        } else {
          getWork = query(
            workRef,
            where('createdAt', '>=', toDay),
            where('method', '==', 'in'),
            orderBy('createdAt', 'desc')
          )

          getWorkOut = query(
            workRef,
            where(
              'createdAt',
              '>=',
              new Date(moment(toDay).startOf('D').subtract(1, 'd'))
            ),
            where('method', '==', 'out'),
            orderBy('createdAt', 'desc')
          )
        }
      }
    } else if (currentMonth) {
      if (selectBranch) {
        getWork = query(
          workRef,
          where('branch.id', '==', selectBranch),
          where('createdAt', '>=', new Date(moment().startOf('month'))),
          where('method', '==', 'in'),
          orderBy('createdAt', 'desc')
        )

        getWorkOut = query(
          workRef,
          where('branch.id', '==', selectBranch),
          where(
            'createdAt',
            '>=',
            new Date(moment(startDate).startOf('M').subtract(1, 'd'))
          ),
          where('method', '==', 'out'),
          orderBy('createdAt', 'desc')
        )
      } else {
        getWork = query(
          workRef,
          where('createdAt', '>=', new Date(moment().startOf('month'))),
          where('method', '==', 'in'),
          orderBy('createdAt', 'desc')
        )

        getWorkOut = query(
          workRef,
          where(
            'createdAt',
            '>=',
            new Date(moment().startOf('M').subtract(1, 'd'))
          ),
          where('method', '==', 'out'),
          orderBy('createdAt', 'desc')
        )
      }
    } else if (dateTime) {
      if (selectBranch) {
        getWork = query(
          workRef,
          where('branch.id', '==', selectBranch),
          where('createdAt', '>=', new Date(startDateTime)),
          where('method', '==', 'in'),
          where('createdAt', '<=', new Date(endDateTime)),
          orderBy('createdAt', 'desc')
        )

        getWorkOut = query(
          workRef,
          where('branch.id', '==', selectBranch),
          where(
            'createdAt',
            '>=',
            new Date(moment(startDateTime).subtract(1, 'd'))
          ),
          where('createdAt', '<=', new Date(moment(endDateTime).add(1, 'd'))),
          where('method', '==', 'out'),
          orderBy('createdAt', 'desc')
        )
      } else {
        getWork = query(
          workRef,
          where('createdAt', '>=', new Date(startDateTime)),
          where('createdAt', '<=', new Date(endDateTime)),
          where('method', '==', 'in'),
          orderBy('createdAt', 'desc')
        )

        getWorkOut = query(
          workRef,
          where(
            'createdAt',
            '>=',
            new Date(moment(startDateTime).subtract(1, 'd'))
          ),
          where('createdAt', '<=', new Date(moment(endDateTime).add(1, 'd'))),
          where('method', '==', 'out'),
          orderBy('createdAt', 'desc')
        )
      }
    } else if (prevMonth) {
      if (selectBranch) {
        getWork = query(
          workRef,
          where('branch.id', '==', selectBranch),
          where('createdAt', '>=', new Date(moment(month).startOf('month'))),
          where('createdAt', '<=', new Date(moment(month).endOf('month'))),
          where('method', '==', 'in'),
          orderBy('createdAt', 'desc')
        )

        getWorkOut = query(
          workRef,
          where('branch.id', '==', selectBranch),
          where(
            'createdAt',
            '>=',
            new Date(moment(month).startOf('M').subtract(1, 'd'))
          ),
          where(
            'createdAt',
            '<=',
            new Date(moment(month).endOf('M').add(1, 'd'))
          ),
          where('method', '==', 'out'),
          orderBy('createdAt', 'desc')
        )
      } else {
        getWork = query(
          workRef,
          where('createdAt', '>=', new Date(moment(month).startOf('month'))),
          where('createdAt', '<=', new Date(moment(month).endOf('month'))),
          where('method', '==', 'in'),
          orderBy('createdAt', 'desc')
        )

        getWorkOut = query(
          workRef,
          where(
            'createdAt',
            '>=',
            new Date(moment(month).startOf('M').subtract(1, 'd'))
          ),
          where(
            'createdAt',
            '<=',
            new Date(moment(month).endOf('M').add(1, 'd'))
          ),
          where('method', '==', 'out'),
          orderBy('createdAt', 'desc')
        )
      }
    } else if (between) {
      if (selectBranch) {
        getWork = query(
          workRef,
          where('branch.id', '==', selectBranch),
          where('createdAt', '>=', new Date(moment(startDate).startOf('D'))),
          where('createdAt', '<=', new Date(moment(endDate).endOf('D'))),
          where('method', '==', 'in'),
          orderBy('createdAt', 'desc')
        )
        getWorkOut = query(
          workRef,
          where('branch.id', '==', selectBranch),
          where(
            'createdAt',
            '>=',
            new Date(moment(startDate).startOf('D').subtract(1, 'd'))
          ),
          where(
            'createdAt',
            '<=',
            new Date(moment(endDate).endOf('D').add(1, 'd'))
          ),
          where('method', '==', 'out'),
          orderBy('createdAt', 'desc')
        )
      } else {
        getWork = query(
          workRef,
          where('createdAt', '>=', new Date(moment(startDate).startOf('D'))),
          where('createdAt', '<=', new Date(moment(endDate).endOf('D'))),
          where('method', '==', 'in'),
          orderBy('createdAt', 'desc')
        )
        getWorkOut = query(
          workRef,
          where(
            'createdAt',
            '>=',
            new Date(moment(startDate).startOf('D').subtract(1, 'd'))
          ),
          where(
            'createdAt',
            '<=',
            new Date(moment(endDate).endOf('D').add(1, 'd'))
          ),
          where('method', '==', 'out'),
          orderBy('createdAt', 'desc')
        )
      }
    }

    const data = await (
      await getDocs(getWork)
    )?.docs?.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
        date: doc
          .data()
          .createdAt.toDate()
          .toLocaleString('th-TH')
          .split(' ')[0],
      }
    })

    const workOutDBData = await (
      await getDocs(getWorkOut)
    )?.docs?.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
        date: doc
          .data()
          .createdAt.toDate()
          .toLocaleString('th-TH')
          .split(' ')[0],
      }
    })

    const matchInOutWorkTime = await Promise.all(
      await _.map(data, async (item) => {
        const getOffWork = await _.find(workOutDBData, (i) => {
          return item.id === i.ref
        })

        const workMinute = moment(getOffWork?.createdAt?.toDate()).diff(
          item?.createdAt?.toDate(),
          'minute'
        )
        const workHours = moment(getOffWork?.createdAt?.toDate()).diff(
          item?.createdAt?.toDate(),
          'hours'
        )
        const getDiffMinute = workMinute - workHours * 60

        return {
          outTime: getOffWork?.createdAt ? getOffWork?.createdAt : null,
          inTime: item?.createdAt,
          branch: {
            id: item?.branch?.id,
            name: item?.branch?.name,
          },
          img: item?.img,
          imgLogout: getOffWork?.img,
          role: item?.role,
          user: {
            id: item?.user?.id,
            name: item?.user?.name,
            email: item?.user?.email,
          },
          date: item?.date,
          detailLogout: getOffWork?.detail,
          diff: getOffWork?.createdAt ? (workHours > 8 ? 8 : workHours) : 0,
          diffMinute: workHours > 8 ? 0 : getDiffMinute > 0 ? getDiffMinute : 0,
          rawDiff: getOffWork?.createdAt
            ? moment(getOffWork?.createdAt?.toDate()).diff(
                item?.createdAt?.toDate(),
                'hours'
              )
            : 0,
        }
      }),
      []
    )
    return (await matchInOutWorkTime) ? matchInOutWorkTime : []
  }

  const getWorkByUserId = async ({
    userId,
    month,
    endDate,
    type,
    startDateTime,
    endDateTime,
  }) => {
    var querryRef
    if (type === 'currentDate') {
      // วันนี้
      querryRef = query(
        collection(db, 'Works'),
        where('createdAt', '>=', new Date(moment().startOf('D'))),
        where('createdAt', '<=', new Date(moment().endOf('D'))),
        where('user.id', '==', userId),
        orderBy('createdAt', 'desc')
      )
    } else if (type === 'currentMonth') {
      //เดือนนี้
      querryRef = query(
        collection(db, 'Works'),
        where('createdAt', '>=', new Date(moment().startOf('M'))),
        where('createdAt', '<=', new Date(moment().endOf('M'))),
        where('user.id', '==', userId),
        orderBy('createdAt', 'desc')
      )
    } else if (type === 'prevMonth') {
      //เดือนก่อน currentMonth,month
      const startMonth = new Date(moment(new Date(month)).startOf('M'))
      const endMonth = new Date(moment(new Date(month)).endOf('M'))
      querryRef = query(
        collection(db, 'Works'),
        where('createdAt', '>=', startMonth),
        where('createdAt', '<=', endMonth),
        where('user.id', '==', userId),
        orderBy('createdAt', 'desc')
      )
    } else if (type === 'between') {
      // between, startDate, endDate
      querryRef = query(
        collection(db, 'Works'),
        where('createdAt', '>=', new Date(moment(endDate).startOf('D'))),
        where('createdAt', '<=', new Date(moment(endDate).endOf('D'))),
        where('user.id', '==', userId),
        orderBy('createdAt', 'desc')
      )
    } else if (type === 'dateTime') {
      //dateTime , startDateTime, endDateTime
      querryRef = query(
        collection(db, 'Works'),
        where('createdAt', '>=', new Date(startDateTime)),
        where('createdAt', '<=', new Date(endDateTime)),
        where('user.id', '==', userId),
        orderBy('createdAt', 'desc')
      )
    }

    const getData = await (await getDocs(querryRef)).docs
    const data = await _.map(getData, (doc) => {
      return {
        id: doc?.id,
        ...doc?.data(),
        date: moment(doc.data().createdAt?.toDate()).format('MM/DD/YYYY'),
      }
    })

    const filterRef = _.filter(data, (i) => {
      return i.ref
    })
    const filterNoRef = _.filter(data, (i) => {
      return !i.ref
    })
    const matchInOutWorkTime = await Promise.all(
      await _.map(filterNoRef, async (item) => {
        const getOffWork = await _.find(filterRef, (i) => {
          return item.id === i.ref
        })

        const workMinute = moment(getOffWork?.createdAt?.toDate()).diff(
          item?.createdAt?.toDate(),
          'minute'
        )
        const workHours = moment(getOffWork?.createdAt?.toDate()).diff(
          item?.createdAt?.toDate(),
          'hours'
        )
        const getDiffMinute = workMinute - workHours * 60

        return {
          outTime: getOffWork?.createdAt ? getOffWork?.createdAt : null,
          inTime: item?.createdAt,
          branch: {
            id: item?.branch?.id,
            name: item?.branch?.name,
          },
          role: item?.role,
          user: {
            id: item?.user?.id,
            name: item?.user?.name,
            email: item?.user?.email,
          },
          detailLogout: getOffWork?.detail,
          date: item?.date,
          diff: getOffWork?.createdAt ? (workHours > 8 ? 8 : workHours) : 0,
          diffMinute: workHours > 8 ? 0 : getDiffMinute > 0 ? getDiffMinute : 0,
          rawDiff: getOffWork?.createdAt
            ? moment(getOffWork?.createdAt?.toDate()).diff(
                item?.createdAt?.toDate(),
                'hours'
              )
            : 0,
        }
      }),
      []
    )
    return matchInOutWorkTime
  }

  const getWorkByDate = async ({
    month,
    endDate,
    type,
    startDateTime,
    endDateTime,
  }) => {
    var querryRef
    if (type === 'currentDate') {
      // วันนี้
      querryRef = query(
        collection(db, 'Works'),
        where('createdAt', '>=', new Date(moment().startOf('D'))),
        where('createdAt', '<=', new Date(moment().endOf('D'))),
        orderBy('createdAt', 'desc')
      )
    } else if (type === 'currentMonth') {
      //เดือนนี้
      querryRef = query(
        collection(db, 'Works'),
        where('createdAt', '>=', new Date(moment().startOf('M'))),
        where('createdAt', '<=', new Date(moment().endOf('M'))),
        orderBy('createdAt', 'desc')
      )
    } else if (type === 'prevMonth') {
      //เดือนก่อน currentMonth,month
      const startMonth = new Date(moment(new Date(month)).startOf('M'))
      const endMonth = new Date(moment(new Date(month)).endOf('M'))
      querryRef = query(
        collection(db, 'Works'),
        where('createdAt', '>=', startMonth),
        where('createdAt', '<=', endMonth),
        orderBy('createdAt', 'desc')
      )
    } else if (type === 'between') {
      // between, startDate, endDate
      querryRef = query(
        collection(db, 'Works'),
        where('createdAt', '>=', new Date(moment(endDate).startOf('D'))),
        where('createdAt', '<=', new Date(moment(endDate).endOf('D'))),
        orderBy('createdAt', 'desc')
      )
    } else if (type === 'dateTime') {
      //dateTime , startDateTime, endDateTime
      querryRef = query(
        collection(db, 'Works'),
        where('createdAt', '>=', new Date(startDateTime)),
        where('createdAt', '<=', new Date(endDateTime)),
        orderBy('createdAt', 'desc')
      )
    }

    const getData = await (await getDocs(querryRef)).docs
    const data = await _.map(getData, (doc) => {
      return {
        id: doc?.id,
        ...doc?.data(),
        date: moment(doc.data().createdAt?.toDate()).format('MM/DD/YYYY'),
      }
    })

    const filterRef = _.filter(data, (i) => {
      return i.ref
    })
    const filterNoRef = _.filter(data, (i) => {
      return !i.ref
    })
    const matchInOutWorkTime = await Promise.all(
      await _.map(filterNoRef, async (item) => {
        const getOffWork = await _.find(filterRef, (i) => {
          return item.id === i.ref
        })

        const workMinute = moment(getOffWork?.createdAt?.toDate()).diff(
          item?.createdAt?.toDate(),
          'minute'
        )
        const workHours = moment(getOffWork?.createdAt?.toDate()).diff(
          item?.createdAt?.toDate(),
          'hours'
        )
        const getDiffMinute = workMinute - workHours * 60

        return {
          outTime: getOffWork?.createdAt ? getOffWork?.createdAt : null,
          inTime: item?.createdAt,
          branch: {
            id: item?.branch?.id,
            name: item?.branch?.name,
          },
          role: item?.role,
          user: {
            id: item?.user?.id,
            name: item?.user?.name,
            email: item?.user?.email,
          },
          detailLogout: getOffWork?.detail,
          date: item?.date,
          diff: getOffWork?.createdAt ? (workHours > 8 ? 8 : workHours) : 0,
          diffMinute: workHours > 8 ? 0 : getDiffMinute > 0 ? getDiffMinute : 0,
        }
      }),
      []
    )
    return matchInOutWorkTime
  }

  return {
    getWorkById,
    userOnWork,
    userOffWork,
    workList,
    isLoading,
    getUserWork,
    getWorkListWithBranchTime,
    getUserWorkDataWithBranch,
    getUserAllWorkData,
    getWorkByUserId,
    getWorkByDate,
  }
}
