import { SearchIcon } from '@chakra-ui/icons'
import {
  Avatar,
  Badge,
  Box,
  Button,
  Fade,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  SkeletonCircle,
  SkeletonText,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import { ViewFinder } from 'Components/viewFinder'
import { useCategory, useProduct } from 'Hooks'
import { useEffect, useMemo, useState } from 'react'
import { AiOutlineScan } from 'react-icons/ai'
import { RiPencilFill } from 'react-icons/ri'
import { QrReader } from 'react-qr-reader'
import { Link, useNavigate } from 'react-router-dom'
import { search } from 'ss-search'
import { currency, decodeUnit } from 'Utils/currency'
import _ from 'underscore'
import { poStatus } from 'Utils'

function Inventory({ user }) {
  const {
    branchProductStock,
    branchStockIsLoading: isLoading,
    getProductInStock,
  } = useProduct()
  const { category: DBCategory, getCategoryAll } = useCategory()
  const Navigate = useNavigate()
  const [allData, setAllData] = useState([])
  const [isFade, setIsFade] = useState(true)
  const [filterOptions, setFilterOptions] = useState({
    category: '',
    search: '',
    stock: 'all',
  })
  const [stockData, setStockData] = useState({
    all: 0,
    stock: 0,
    almostOver: 0,
    outOfStock: 0,
  })

  const Product = useMemo(() => {
    return branchProductStock
  }, [branchProductStock])

  const {
    isOpen: qrScanIsOpen,
    onOpen: qrScanOnOpen,
    onClose: qrScanOnClose,
  } = useDisclosure()

  const filterSearchData = (data) => {
    const searchKey = ['name', 'nameOption', 'category', 'sku']

    const result = search(data, searchKey, filterOptions.search)
    setAllData(result)
  }
  const filterCategory = (category, product) => {
    const findCategory = _.find(DBCategory, (i) => {
      return i.id === category || i.name === category
    })

    const filterCategoryData = _.filter(product, (item) => {
      return (
        item?.category === findCategory?.id ||
        item?.category === findCategory?.name
      )
    })
    return filterCategoryData
  }

  useEffect(() => {
    getProductInStock()
    getCategoryAll()
  }, [getProductInStock, getCategoryAll])

  async function calTotalStock(pData) {
    if (pData?.length > 0) {
      let checkTotal = await _.countBy(
        pData,
        (i) => {
          return parseFloat(i.total) > parseFloat(decodeUnit(i.minimum))
            ? 'stock'
            : parseFloat(i.total) <= parseFloat(decodeUnit(i.minimum)) &&
              parseFloat(i.total) > 0
            ? 'almostOver'
            : 'outOfStock'
        },
        0
      )
      const stockAll =
        (checkTotal?.stock ? checkTotal?.stock : 0) +
        (checkTotal?.almostOver ? checkTotal?.almostOver : 0) +
        (checkTotal?.outOfStock ? checkTotal?.outOfStock : 0)
      const stockTotal =
        (checkTotal?.stock ? checkTotal?.stock : 0) +
        (checkTotal?.almostOver ? checkTotal?.almostOver : 0)
      setStockData({
        all: stockAll,
        stock: stockTotal,
        almostOver: checkTotal.almostOver ? checkTotal.almostOver : 0,
        outOfStock: checkTotal.outOfStock ? checkTotal.outOfStock : 0,
      })
    } else {
      setStockData({
        all: 0,
        stock: 0,
        almostOver: 0,
        outOfStock: 0,
      })
    }
  }

  useEffect(() => {
    if (Product?.length > 0) {
      // calTotalStock(Product)
      if (filterOptions.stock === 'all') {
        if (filterOptions.category !== '') {
          calTotalStock(filterCategory(filterOptions.category, Product))
          filterSearchData(filterCategory(filterOptions.category, Product))
        } else {
          calTotalStock(Product)
          filterSearchData(Product)
        }
      } else if (filterOptions.stock === 'almostOver') {
        if (filterOptions.category !== '') {
          const productfilter = Product.filter(
            (item) =>
              parseFloat(item.total) <= decodeUnit(item.minimum) &&
              parseFloat(item.total) > 0
          )
          calTotalStock(filterCategory(filterOptions.category, Product))
          filterSearchData(
            filterCategory(filterOptions.category, productfilter)
          )
        } else {
          const productfilter = Product.filter((item) => {
            return parseFloat(item.total) <= 0
          })
          calTotalStock(productfilter)
          filterSearchData(productfilter)
        }
      } else if (filterOptions.stock === 'out') {
        if (filterOptions.category !== '') {
          const productfilter = Product.filter(
            (item) => parseFloat(item.total) <= 0
          )
          calTotalStock(filterCategory(filterOptions.category, Product))
          filterSearchData(
            filterCategory(filterOptions.category, productfilter)
          )
        } else {
          const productfilter = Product.filter(
            (item) => parseFloat(item.total) <= 0
          )
          calTotalStock(productfilter)
          filterSearchData(productfilter)
        }
      } else if (filterOptions.stock === 'stock') {
        if (filterOptions.category !== '') {
          const productfilter = Product.filter(
            (item) => parseFloat(item.total) > 0
          )
          calTotalStock(filterCategory(filterOptions.category, Product))
          filterSearchData(
            filterCategory(filterOptions.category, productfilter)
          )
        } else {
          const productfilter = Product.filter(
            (item) => parseFloat(item.total) > 0
          )
          calTotalStock(productfilter)
          filterSearchData(productfilter)
        }
      }
      setIsFade(false)
    }
    //eslint-disable-next-line
  }, [Product, filterOptions])

  const ItemCard = (props) => {
    const { data } = props
    return data?.map((item, itemKey) => {
      return (
        <>
          <Card
            px={{ xl: '1rem', '2xl': '1.3em' }}
            py={'1.5em'}
            key={itemKey}
            as={Link}
            to={
              user?.branchSelect?.id === 'HQ'
                ? `/inventory/view/${item?.id}`
                : `/inventory/branch/view/${item?.id}`
            }
            variant={'none'}
            borderRadius={'.75em'}
          >
            <Badge
              ml='1'
              colorScheme={
                parseFloat(item?.total) <= decodeUnit(item?.minimum) &&
                parseFloat(item?.total) !== 0
                  ? 'orange'
                  : 'red'
              }
              position={'absolute'}
              alignSelf={'end'}
              top={'6px'}
              right={'6px'}
              display={
                parseFloat(item?.total) <= decodeUnit(item?.minimum)
                  ? 'block'
                  : 'none'
              }
            >
              {parseFloat(item?.total) <= decodeUnit(item?.minimum) &&
              parseFloat(item?.total) > 0
                ? 'สินค้าใกล้จะหมด'
                : 'สินค้าหมด'}
            </Badge>

            <HStack justifyContent={'space-between'}>
              <HStack spacing={'.75em'}>
                <Avatar
                  src={item?.img && item?.img}
                  size={'lg'}
                  name={item?.name}
                />
                <VStack alignItems={'start'} spacing='-0.5'>
                  <Box
                    maxW={{
                      md: '150px',
                      lg: '130px',
                      xl: '120px',
                      '2xl': '130px',
                    }}
                  >
                    <Text
                      fontWeight={'bold'}
                      fontSize={{
                        base: '12px',
                        md: '14px',
                        lg: '16px',
                      }}
                      color={'#1B2559'}
                      noOfLines='2'
                    >
                      {item.name ? item.name : ''}{' '}
                      {item.nameOption ? item.nameOption : ''}
                    </Text>
                  </Box>
                  <HStack>
                    <Text
                      color={'blackAlpha.700'}
                      fontSize={{
                        base: '10px',
                        md: '12px',
                        lg: '16px',
                      }}
                    >
                      {item.sku}
                    </Text>
                  </HStack>
                </VStack>
              </HStack>
              <HStack>
                <Text>คลัง</Text>
                <Text
                  color={
                    parseFloat(item.total) < 0 ? 'red.500' : 'inventory.500'
                  }
                >
                  {item.unit === 'weight'
                    ? currency(item.total, 2)
                    : `${currency(item.total)}`}
                </Text>
              </HStack>
            </HStack>

            <Badge
              ml='1'
              colorScheme={
                item?.statusPo === 'backOrder'
                  ? 'yellow'
                  : item?.statusPo === 'discontinues'
                  ? 'gray.900'
                  : 'white'
              }
              position={'absolute'}
              alignSelf={'end'}
              top={'75px'}
              right={'6px'}
              display={
                parseFloat(item?.total) <= decodeUnit(item?.minimum) &&
                item?.statusPo
                  ? 'block'
                  : 'none'
              }
            >
              {poStatus(item?.statusPo)}
            </Badge>
          </Card>
        </>
      )
    })
  }

  return (
    <>
      <Modal size='sm' isOpen={qrScanIsOpen} onClose={qrScanOnClose}>
        <ModalOverlay />
        <ModalContent minHeight='450px'>
          <ModalHeader>SCAN QR</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <QrReader
              constraints={{ facingMode: 'environment' }}
              ViewFinder={() => <ViewFinder />}
              onResult={(result, error) => {
                if (result) {
                  let itemId = (result?.text).split('/')
                  if (itemId) {
                    if (user?.branchSelect?.id === 'HQ') {
                      Navigate(`/inventory/view/${itemId[5]}`)
                    } else {
                      Navigate(`/inventory/branch/view/${itemId[5]}`)
                    }
                  }
                }
              }}
              style={{ width: '100%' }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Stack borderTop={{ sm: '1px solid #E8E9E9', md: '0px' }}>
        <Box zIndex='base'>
          <HStack
            my='1em'
            spacing={{ base: '0px', sm: '0px', md: '10px' }}
            p={{ sm: '0px', md: '20px 20px 30px 20px' }}
            background={{ sm: 'none', md: '#FFFFFF' }}
            boxShadow={{
              sm: '0px',
              md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
            }}
            borderRadius={{ sm: '10px', md: '30px' }}
            justifyContent='flex-start'
            flexDirection={{
              base: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
            }}
          >
            <InputGroup w={{ base: '100%', md: '220px', lg: '250px' }}>
              <InputLeftElement
                children={
                  <IconButton
                    bg='inherit'
                    borderRadius='inherit'
                    _hover='none'
                    _active={{
                      bg: 'inherit',
                      transform: 'none',
                      borderColor: 'transparent',
                    }}
                    _focus={{
                      boxShadow: 'none',
                    }}
                    icon={<SearchIcon color={'gray.700'} w='15px' h='15px' />}
                  ></IconButton>
                }
              />
              <Input
                variant='search'
                fontSize='sm'
                bg={'secondaryGray.300'}
                color={'gray.700'}
                fontWeight='500'
                _placeholder={{
                  color: 'gray.400',
                  fontSize: '14px',
                  opacity: 1,
                }}
                value={filterOptions.search}
                borderRadius={'30px'}
                placeholder={'น้ำสมุนไพร, ชา'}
                onChange={(e) => {
                  setFilterOptions({ ...filterOptions, search: e.target.value })
                }}
              />
              <InputRightAddon
                w='max-content'
                bg={'secondaryGray.300'}
                borderRadius='30px'
                border='0px'
                children={
                  <IconButton
                    onClick={() => qrScanOnOpen()}
                    border='0px'
                    borderLeft='1px solid #E0E5F2'
                    borderLeftRadius='0px'
                    bg={'secondaryGray.300'}
                    _hover='none'
                    w='max-content'
                    _focus={{
                      boxShadow: 'none',
                    }}
                  >
                    <AiOutlineScan
                      bg={'secondaryGray.300'}
                      color={'gray.700'}
                      fontSize='22px'
                    />
                  </IconButton>
                }
              />
            </InputGroup>

            {/* <HStack spacing={'5px'}> */}
            <Select
              pt={{ base: '15px', sm: '15px', md: '0rem' }}
              w={{ base: '100%', md: '250px' }}
              h='44px'
              maxh='44px'
              variant='search'
              border={{
                base: '1px solid #E8E9E9',
                sm: '1px solid #E8E9E9',
                md: '0px',
              }}
              bg={{ base: 'white', sm: 'white', md: 'secondaryGray.300' }}
              fontSize='sm'
              color={'gray.700'}
              fontWeight='500'
              _placeholder={{
                color: 'gray.400',
                fontSize: '14px',
                opacity: 1,
              }}
              borderRadius={{
                base: '10px',
                sm: '10px',
                md: '30px',
                lg: '30px',
              }}
              onChange={(e) => {
                setFilterOptions({
                  ...filterOptions,
                  category: e.target.value,
                })
              }}
            >
              <option value={''}>หมวดหมู่ทั้งหมด</option>
              {DBCategory?.map((categoryItem) => {
                return (
                  <option value={categoryItem.id}>{categoryItem.name}</option>
                )
              })}
            </Select>

            <Select
              pt={{ base: '15px', sm: '15px', md: '0rem' }}
              w={{ base: '100%', md: '250px' }}
              h='44px'
              maxh='44px'
              variant='search'
              border={{
                base: '1px solid #E8E9E9',
                sm: '1px solid #E8E9E9',
                md: '0px',
              }}
              bg={{ base: 'white', sm: 'white', md: 'secondaryGray.300' }}
              fontSize='sm'
              color={'gray.700'}
              fontWeight='500'
              _placeholder={{
                color: 'gray.400',
                fontSize: '14px',
                opacity: 1,
              }}
              borderRadius={{
                base: '10px',
                sm: '10px',
                md: '30px',
                lg: '30px',
              }}
              onChange={(e) => {
                setFilterOptions({ ...filterOptions, stock: e.target.value })
              }}
            >
              <option value={'all'}>
                ทั้งหมด&nbsp;
                {`(${stockData?.all})`}
              </option>
              <option value={'stock'}>
                คงคลัง&nbsp;
                {`(${stockData?.stock})`}
              </option>
              <option value={'almostOver'}>
                ใกล้จะหมด&nbsp;
                {`(${stockData?.almostOver})`}
              </option>
              <option value={'out'}>
                หมด&nbsp;
                {`(${stockData?.outOfStock})`}
              </option>
            </Select>
            {/* allData */}
          </HStack>
          {/* </HStack> */}
        </Box>

        <Box>
          {!isLoading ? (
            <>
              <SimpleGrid
                as={Fade}
                in={!isFade}
                columns={{ base: 1, md: 2, lg: 3, xl: 3, '2xl': 4 }}
                // columns={{ base: 2, md: 2, lg: 2, xl: 3 }}
                // gap={'2'}
                spacing={'2'}
              >
                <ItemCard data={allData} />
              </SimpleGrid>
            </>
          ) : (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} gap={'2'}>
              {new Array(10).fill(null).map((itm, index) => (
                <Card
                  px={'2em'}
                  py={'1.5em'}
                  key={index}
                  border={'1px'}
                  borderColor={'blackAlpha.200'}
                  borderRadius={'.75em'}
                >
                  <HStack justifyContent={'space-between'}>
                    <HStack spacing={'.75em'}>
                      <SkeletonCircle>
                        <Avatar src={''} />
                      </SkeletonCircle>
                      <VStack alignItems={'start'} spacing='2'>
                        <SkeletonText noOfLines={1} w={'full'}>
                          <Text
                            fontWeight={'bold'}
                            fontSize={{ base: '12px', md: '14px', lg: '16px' }}
                          >
                            xxxx
                          </Text>
                        </SkeletonText>
                        <HStack>
                          <SkeletonText noOfLines={1} w={'75%'}>
                            <Text
                              color={'blackAlpha.500'}
                              fontSize={{
                                base: '10px',
                                md: '12px',
                                lg: '14px',
                              }}
                            >
                              ตัวเลือกสินค้า: {itm?.name ? itm.name : '-'}
                            </Text>
                          </SkeletonText>
                        </HStack>
                      </VStack>
                    </HStack>
                    <SkeletonCircle>
                      <Button variant={'none'}>
                        <Icon
                          as={RiPencilFill}
                          color={'blackAlpha.500'}
                          fontSize={{ base: '16px', md: '14px', lg: '16px' }}
                        />
                      </Button>
                    </SkeletonCircle>
                  </HStack>
                </Card>
              ))}
            </SimpleGrid>
          )}
        </Box>
      </Stack>
    </>
  )
}

export default Inventory
