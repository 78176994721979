import {
  Avatar,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  Box,
  Center,
  HStack,
  Grid,
  GridItem,
  Spinner,
  Divider,
} from '@chakra-ui/react'
import { SidebarResponsive } from 'Components/Core/sidebar'
import { useAuth, useBranch } from 'Hooks'
import { MdNotificationsNone } from 'react-icons/md'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  AiOutlineLogout,
  AiOutlineUnorderedList,
  AiOutlineUser,
} from 'react-icons/ai'
import { useAppContext } from 'Context'
import { BiTimeFive } from 'react-icons/bi'

function ClientNav(props) {
  const { secondary, user } = props
  const { logout, userSignOutBranch } = useAuth()
  const { Branch, getBranchData } = useBranch()
  const [branchList, setBranchList] = useState()
  const [isLoadingBranch, setIsLoadingBranch] = useState(true)
  const navbarIcon = useColorModeValue('gray.400', 'white')
  let menuBg = useColorModeValue('white', 'navy.800')
  const { pathname } = useLocation()
  const Navigate = useNavigate()

  const { setMainLoading } = useAppContext()
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  )
  const borderColor = useColorModeValue(
    'white !important',
    '#0b1437 !important'
  )

  const BranchImage = Branch.find(
    (item) => item?.id === user?.branchSelect?.id
  )?.img

  const handleLogout = () => {
    setMainLoading(true)
    logout()
  }

  const getBranch = () => {
    setIsLoadingBranch(true)
    if (Branch) {
      if (user?.role === 'Administrator') {
        setBranchList(Branch)
      } else {
        setBranchList(
          user?.branch?.map(
            (item) => Branch?.filter((itm) => itm.id === item.id)[0]
          )
        )
      }
      setIsLoadingBranch(false)
    }
  }

  const signOutBranch = () => {
    setIsLoadingBranch(true)
    userSignOutBranch().then(() => {
      Navigate('/')
    })
  }

  useEffect(() => {
    getBranchData()
  }, [getBranchData])

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems='center'
      flexDirection='row'
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p='10px'
      borderRadius={{ base: '10px', sm: '10px', md: '30px' }}
      boxShadow={shadow}
      zIndex='overlay'
    >
      <Flex
        justifyContent='space-between'
        w='100%'
        align='center'
        zIndex='overlay'
      >
        <Center
          display={{
            base: 'flex',
            sm: 'flex',
            md: 'flex',
            lg: 'flex',
            xl: 'none',
          }}
          pl={{
            base: '0.5rem',
            sm: '0.5rem',
            md: '0.5rem',
            lg: '0.5rem',
            xl: '0rem',
            '2xl': '1rem',
          }}
        >
          <SidebarResponsive user={user} />
        </Center>
        <Flex
          flexDirection='column'
          justifyContent='center'
          alignItems={{ lg: 'end', xl: 'start' }}
          w={{
            base: 'full',
            sm: 'full',
            md: '50%',
            lg: '50%',
            xl: '50%',
            '2xl': 'full',
          }}
          textAlign={{ sm: 'end', md: 'end', lg: 'end', xl: 'start' }}
          fontFamily='Prompt'
          px={{
            base: '0.5rem',
            sm: '0.5rem',
            md: '0rem',
            lg: '0.2rem',
            xl: '0rem',
          }}
          ml={{ base: '0rem', sm: '0rem', md: '0rem', lg: '0rem', xl: '1rem' }}
        >
          <Text
            color='navbar.500'
            w={{
              base: 'auto',
              sm: 'auto',
              md: '100%',
              lg: '100%',
              xl: '100%',
              '2xl': 'auto',
            }}
            noOfLines={1}
            fontSize={{ base: '14px', sm: '14px', md: '14px', lg: '16px' }}
          >
            {user?.branchSelect?.id === 'HQ'
              ? 'คลังกลาง'
              : `สาขา ${user?.branchSelect?.name}`}
          </Text>
          <Text
            mt={{ base: '-0.5rem', sm: '-0.5rem', md: '-0.5rem' }}
            fontSize={{ base: '12px', sm: '12px', md: '12px', lg: '14px' }}
            color='#494D51'
            w={{
              base: 'auto',
              sm: 'auto',
              md: '100%',
              lg: '100%',
              xl: '100%',
              '2xl': 'auto',
            }}
            noOfLines={1}
          >
            {user.email}
          </Text>
        </Flex>
        <Center w='max-content'>
          <Box>
            <Icon
              mt='6px'
              as={MdNotificationsNone}
              color={navbarIcon}
              w='18px'
              h='18px'
              me='10px'
            />
          </Box>

          <Menu
            onOpen={() => {
              getBranch()
            }}
          >
            <MenuButton p='0px' m='0px'>
              <Avatar
                _hover={{ cursor: 'pointer' }}
                color='white'
                name={user?.branchSelect?.name}
                src={BranchImage}
                bg='#11047A'
                size='sm'
                w='40px'
                h='40px'
              />
            </MenuButton>
            <MenuList
              boxShadow={shadow}
              p='0px'
              mt='10px'
              borderRadius='10px'
              bg={menuBg}
              border='none'
            >
              <Flex flexDirection='column' fontFamily={'Prompt'} minW='200px'>
                {user?.role === 'Administrator' && (
                  <>
                    <Box h='max-content'>
                      <Box py='0.5rem'>
                        <Text pl='1rem' fontWeight='bold' color='#2B3674'>
                          สาขาใหญ่
                        </Text>
                      </Box>
                    </Box>
                    <Flex borderBottom='1px solid #E9EDF7' pb='0.5rem'>
                      {Branch.length > 0 ? (
                        Branch?.filter((itm) => itm?.id === 'HQ')?.map(
                          (item, i) => (
                            <Flex key={i}>
                              <MenuItem
                                w='320px'
                                p='0px'
                                m='0px'
                                key={i}
                                transition='0.4s'
                                _hover={{ bg: '#EFEFEF', transition: '0.4s' }}
                              >
                                <Grid
                                  w='100%'
                                  py='0.5rem'
                                  templateColumns='repeat(3, 1fr)'
                                  gap={0}
                                  cursor='pointer'
                                  onClick={() => {
                                    if (item?.id !== user?.branchSelect?.id) {
                                      // selecedBranch(item?.id)
                                      signOutBranch()
                                    }
                                  }}
                                >
                                  <GridItem
                                    w='100%'
                                    h='auto'
                                    alignItems='center'
                                  >
                                    <Center h='full'>
                                      <Avatar
                                        src={item?.img}
                                        h={{ base: '40px' }}
                                        w={{ base: '40px' }}
                                        borderColor={borderColor}
                                      />
                                    </Center>
                                  </GridItem>
                                  <GridItem w='100%' h='auto'>
                                    <Flex
                                      flexDirection='column'
                                      h='full'
                                      color='#2B3674'
                                      maxW='160px'
                                    >
                                      <Text>B4</Text>
                                      <Text>คลังกลาง</Text>
                                    </Flex>
                                  </GridItem>
                                  <GridItem w='100%' h='auto'>
                                    {item?.id === user?.branchSelect?.id && (
                                      <Center h='full'>
                                        <Box
                                          bg='#38A169'
                                          borderRadius='full'
                                          w='0.7rem'
                                          h='0.7rem'
                                        ></Box>
                                      </Center>
                                    )}
                                  </GridItem>
                                </Grid>
                              </MenuItem>
                            </Flex>
                          )
                        )
                      ) : (
                        <Center>
                          <Spinner size='lg' />
                        </Center>
                      )}
                    </Flex>
                  </>
                )}
                <Flex
                  flexDirection='column'
                  borderBottom='1px solid #E9EDF7'
                  py='0.5rem'
                  zIndex='overlay'
                >
                  <Box py='0.5rem'>
                    <Text pl='1rem' fontWeight='bold' color='#2B3674'>
                      สาขาปัจจุบัน
                    </Text>
                  </Box>
                  <Box
                    maxH='15rem'
                    overflowY='auto'
                    w='auto'
                    overflowX='hidden'
                  >
                    {!isLoadingBranch ? (
                      branchList?.length > 0 && (
                        <>
                          {branchList
                            ?.filter(
                              (itm) =>
                                itm?.id !== 'HQ' &&
                                itm?.id === user?.branchSelect?.id
                            )
                            ?.map((item, i) => {
                              return (
                                <MenuItem
                                  w='320px'
                                  p='0px'
                                  m='0px'
                                  key={i}
                                  transition='0.4s'
                                  _hover={{ bg: '#EFEFEF', transition: '0.4s' }}
                                >
                                  <Grid
                                    py='0.5rem'
                                    templateColumns='repeat(3, 1fr)'
                                    gap={0}
                                    cursor='pointer'
                                    onClick={() => {
                                      if (item?.id !== user?.branchSelect?.id) {
                                        // selecedBranch(item?.id)
                                      }
                                    }}
                                  >
                                    <GridItem
                                      w='100%'
                                      h='auto'
                                      alignItems='center'
                                    >
                                      <Center h='full'>
                                        <Avatar
                                          src={item?.img}
                                          h={{ base: '40px' }}
                                          w={{ base: '40px' }}
                                          borderColor={borderColor}
                                        />
                                      </Center>
                                    </GridItem>
                                    <GridItem w='100%' h='auto'>
                                      <Flex
                                        flexDirection='column'
                                        h='full'
                                        color='#2B3674'
                                        maxW='160px'
                                      >
                                        <Text>โกแมวต้มข้าว</Text>
                                        <Text>สาขา{`${item.name}`}</Text>
                                      </Flex>
                                    </GridItem>
                                    <GridItem w='100%' h='auto'>
                                      {item?.id === user?.branchSelect?.id && (
                                        <Center h='full'>
                                          <Box
                                            bg='#38A169'
                                            borderRadius='full'
                                            w='0.7rem'
                                            h='0.7rem'
                                          ></Box>
                                        </Center>
                                      )}
                                    </GridItem>
                                  </Grid>
                                </MenuItem>
                              )
                            })}
                        </>
                      )
                    ) : (
                      <>
                        <Center>
                          <Spinner />
                        </Center>
                      </>
                    )}
                  </Box>
                </Flex>
                <Flex flexDirection='column' py='0.3rem'>
                  <MenuItem
                    transition='all 0.4s'
                    _hover={{
                      bg: 'none',
                      color: 'red',
                      transition: 'all 0.4s',
                    }}
                    _focus={{ bg: 'none' }}
                    borderRadius='8px'
                    px='14px'
                    onClick={() => signOutBranch()}
                    w='100%'
                  >
                    <HStack spacing='15px'>
                      <Text fontSize='22px'>
                        <AiOutlineUnorderedList />
                      </Text>
                      <Text fontSize='16px'>เลือกสาขา</Text>
                    </HStack>
                  </MenuItem>
                </Flex>
                <Divider />
                <Flex flexDirection='column'>
                  <MenuItem
                    transition='all 0.4s'
                    _hover={{
                      bg: 'none',
                      color: 'navbar.100',
                      transition: 'all 0.4s',
                    }}
                    _focus={{ bg: 'none' }}
                    borderRadius='8px'
                    px='14px'
                    onClick={() => {
                      if (pathname !== '/working/hours') {
                        Navigate('/working/hours')
                      }
                    }}
                    w='100%'
                  >
                    <HStack spacing='15px'>
                      <Text fontSize='22px'>
                        <BiTimeFive />
                      </Text>
                      <Text fontSize='16px'>ลงเวลาเข้างาน</Text>
                    </HStack>
                  </MenuItem>
                </Flex>
                <Flex flexDirection='column'>
                  <MenuItem
                    transition='all 0.4s'
                    _hover={{
                      bg: 'none',
                      color: 'navbar.600',
                      transition: 'all 0.4s',
                    }}
                    _focus={{ bg: 'none' }}
                    borderRadius='8px'
                    px='14px'
                    onClick={() => {
                      if (pathname !== '/account/setting') {
                        Navigate('/account/setting')
                      }
                    }}
                    w='100%'
                  >
                    <HStack spacing='15px'>
                      <Text fontSize='22px'>
                        <AiOutlineUser />
                      </Text>
                      <Text fontSize='16px'>แก้ไขข้อมูลส่วนตัว</Text>
                    </HStack>
                  </MenuItem>
                </Flex>

                <Flex flexDirection='column' py='0.3rem'>
                  <MenuItem
                    transition='all 0.4s'
                    _hover={{
                      bg: 'none',
                      color: 'red',
                      transition: 'all 0.4s',
                    }}
                    _focus={{ bg: 'none' }}
                    borderRadius='8px'
                    px='14px'
                    onClick={() => handleLogout()}
                    w='100%'
                  >
                    <HStack spacing='15px'>
                      <Text fontSize='22px'>
                        <AiOutlineLogout />
                      </Text>
                      <Text fontSize='16px'>ออกจากระบบ</Text>
                    </HStack>
                  </MenuItem>
                </Flex>
              </Flex>
            </MenuList>
          </Menu>
        </Center>
      </Flex>
    </Flex>
  )
}

export default ClientNav
