import { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Image,
  Select,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react'
import { CardDashboard } from './Components/card'
import SearchDate from 'Components/Search/date'
import { queryOrderAdmin } from './query'
import ReportTotal from './Components/reportTotal'
import Card from 'Components/Horizons/card/Card'
import OverallRevenue from './Components/OverallRevenue'
import _ from 'underscore'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'Config/firebase'
import ExpensesGraph from './Components/ExpensesGraph'
import { queryExpensesAdmin } from './query/qExpenses'
import { useBranch } from 'Hooks'
import { currency } from 'Utils'
import { BiRefresh } from 'react-icons/bi'
import SkeletonImage from 'antd/lib/skeleton/Image'

const getHQDashboard = httpsCallable(functions, 'getHQDashboard')
const getBestSeller = httpsCallable(functions, 'getProductBestSeller')

function DashboardAdmin({ user }) {
  const [option, setOption] = useState('all')
  const [isLoading, setIsLoading] = useState(true)
  const [currentDate, setCurrentDate] = useState(true)
  const [currentMonth, setCurrentMonth] = useState(false)
  const [between, setBetween] = useState(false)
  const [prevMonth, setPrevMonth] = useState(false)
  const [month, setMonth] = useState(new Date())
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(new Date())
  const [allData, setAllData] = useState([])
  const [dataOnCall, setDataOnCall] = useState([])
  const [startDateTime, setStartDateTime] = useState(null)
  const [endDateTime, setEndDateTime] = useState(null)
  const [dateTime, setDateTime] = useState(false)
  const [allExpenses, setAllExpenses] = useState([])
  const [expensesTotal, setExpensesTotal] = useState(0)
  const [bestSeller, setBestSeller] = useState([])
  const [bestSellerIsLoading, setBestSellerIsLoading] = useState(true)
  const { Branch, getBranchData } = useBranch()

  function calOrderGrandTotal(order) {
    const data = _.pluck(order, 'grandTotal')
    const res = _.reduce(data, (memo, num) => memo + num, 0)
    return res
  }

  function calOrderTotal(order) {
    const data = _.pluck(order, 'total')
    const res = _.reduce(data, (memo, num) => memo + num, 0)
    return res
  }

  const fetchBestSeller = useCallback(async () => {
    setBestSellerIsLoading(true)
    const getProduct = await getBestSeller()
    setBestSeller(getProduct.data)
    setBestSellerIsLoading(false)
  }, [])

  async function handleOnGetData() {
    setIsLoading(true)
    setAllData([])
    await getHQDashboard({
      month: month,
      startDate: startDate,
      endDate: endDate,
      between: between, //bool
      prevMonth: prevMonth, //bool
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      dateTime: dateTime, //bool
    }).then((e) => {
      setIsLoading(false)
      setDataOnCall(e.data)
    })
  }

  const fetchData = useCallback(() => {
    setIsLoading(true)

    if (
      (option === 'all' || option === 'netSales') &&
      (currentMonth || currentDate)
    ) {
      setAllExpenses([])
      queryOrderAdmin({
        month: month,
        startDate: startDate,
        endDate: endDate,
        between: between,
        prevMonth: prevMonth,
        setData: setAllData,
        currentDate: currentDate,
        currentMonth: currentMonth,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        dateTime: dateTime,
        setIsLoading: setIsLoading,
      })
    } else if (
      (option === 'all' || option === 'netSales') &&
      (!currentMonth || !currentDate)
    ) {
      setAllExpenses([])
      handleOnGetData()
    } else if (option === 'expenses') {
      setAllData([])
      queryExpensesAdmin({
        month: month,
        startDate: startDate,
        endDate: endDate,
        between: between,
        prevMonth: prevMonth,
        currentDate: currentDate,
        currentMonth: currentMonth,
        Branch: Branch,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        dateTime: dateTime,
        setDataExpenses: setAllExpenses,
        setIsLoading: setIsLoading,
      })
    }

    //eslint-disable-next-line
  }, [
    option,
    month,
    startDate,
    endDate,
    currentDate,
    currentMonth,
    between,
    prevMonth,
    dateTime,
    startDateTime,
    endDateTime,
  ])

  useEffect(() => {
    fetchData()
    //eslint-disable-next-line
  }, [
    option,
    month,
    startDate,
    endDate,
    currentDate,
    currentMonth,
    between,
    prevMonth,
    dateTime,
    startDateTime,
    endDateTime,
  ])

  useEffect(() => {
    if (allExpenses.length > 0) {
      const pluckGrandTotal = _.pluck(allExpenses, 'grandTotal')
      const reducTotal = _.reduce(pluckGrandTotal, (memo, num) => memo + num, 0)
      setExpensesTotal(reducTotal)
    } else {
      setExpensesTotal(0)
    }
  }, [allExpenses])

  useEffect(() => {
    getBranchData()
    fetchBestSeller()
  }, [getBranchData, fetchBestSeller])

  return (
    <VStack
      display={{
        base: 'flex',
        sm: 'flex',
        md: 'flex',
        lg: 'flex',
        xl: 'flex',
        '2xl': 'flex',
      }}
      spacing='2rem'
      direction='column'
      width='stretch'
    >
      {/* <Button onClick={() => handleOnGetData()}>get data</Button> */}
      <Card direction='column'>
        <HStack
          p={{ sm: '0px', md: '20px 20px 30px 20px' }}
          spacing='1vw'
          flexDirection={{ sm: 'column', md: 'row' }}
          alignItems={{ sm: 'center', md: 'start' }}
        >
          <Flex flexDirection='column' w='100%'>
            <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
              <Text color='#8F9BBA'>เลือกช่วงเวลา :</Text>
            </Box>
            <SearchDate
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              month={month}
              setMonth={setMonth}
              setBetween={setBetween}
              setPrevMonth={setPrevMonth}
              setCurrentDate={setCurrentDate}
              setCurrentMonth={setCurrentMonth}
              setStartDateTime={setStartDateTime}
              setEndDateTime={setEndDateTime}
              setDateTime={setDateTime}
              startDateTime={startDateTime}
              endDateTime={endDateTime}
            />
          </Flex>
          <Flex pt={{ sm: '1rem', md: '0rem' }} flexDirection='column' w='100%'>
            <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
              <Text color='#8F9BBA'>เลือกข้อมูลตาม :</Text>
            </Box>
            <Select
              bg='#F4F7FE'
              borderRadius='16px'
              cursor='pointer'
              border='0px'
              variant={'no-effects'}
              _focus={{ border: '0px solid #F4F7FE' }}
              w='full'
              value={option}
              onChange={(e) => {
                let value = e.target.value
                setOption(value)
              }}
            >
              <option value={'all'}>ยอดขาย</option>
              <option value={'netSales'}>ยอดขายสุทธิ</option>
              <option value={'expenses'}>รายจ่าย</option>
            </Select>
          </Flex>
        </HStack>

        <Flex
          pt={{ base: '1.5rem', sm: '1.5rem', md: '0rem', lg: '0rem' }}
          w='100%'
          // pl='2rem'
          justifyContent='flex-start'
        >
          {option === 'all' ? (
            <>
              <CardDashboard
                colorBalance={'#60101C'}
                balance={
                  allData?.length > 0
                    ? calOrderTotal(allData)
                    : dataOnCall?.total
                }
                label={'ยอดขายรวมทุกสาขา'}
              />
            </>
          ) : option === 'netSales' ? (
            <>
              <CardDashboard
                colorBalance={'#60101C'}
                balance={
                  allData?.length > 0
                    ? calOrderGrandTotal(allData)
                    : dataOnCall?.grandTotal
                }
                label={'ยอดขายสุทธิ'}
              />
            </>
          ) : option === 'expenses' ? (
            <>
              <CardDashboard
                colorBalance={'#60101C'}
                balance={expensesTotal}
                label={'รายจ่าย'}
              />
            </>
          ) : (
            <></>
          )}
        </Flex>
      </Card>
      <Grid
        templateColumns={'repeat(3, 1fr)'}
        gap={4}
        w='full'
        display={{ base: 'block', lg: 'grid' }}
        // h={'577px'}
        h={'auto'}
      >
        <GridItem colSpan={2} w='full' h='full'>
          <Card
            justifyContent='center'
            align='center'
            direction='column'
            w='100%'
            mb={{ base: '20px', lg: '0px' }}
            h='full'
          >
            {!isLoading ? (
              // <GridItem mb='20px' w='100%' display={{ base: 'block', lg: 'grid' }}>
              <Flex w='100%'>
                {option === 'all' ? (
                  <ReportTotal
                    allData={allData}
                    dataOnCall={dataOnCall}
                    Branch={Branch}
                  />
                ) : option === 'netSales' ? (
                  <OverallRevenue
                    allData={allData}
                    dataOnCall={dataOnCall}
                    Branch={Branch}
                  />
                ) : (
                  <ExpensesGraph allData={allExpenses} Branch={Branch} />
                )}
              </Flex>
            ) : (
              <Center
                h={{ base: '70vh', sm: '70vh', md: '50vh', lg: '50vh' }}
                flexDirection='column'
                w='100%'
              >
                <Center flexDirection='column'>
                  <Text pb='1rem' fontSize='xl' color='loading.100'>
                    รอดำเนินการ
                  </Text>
                  <span className='loading'></span>
                </Center>
              </Center>
            )}
          </Card>
        </GridItem>
        <GridItem colSpan={1} w='full'>
          <Card
            justifyContent='center'
            align='center'
            direction='column'
            w='100%'
            h='full'
            mb={{ base: '20px', lg: '0px' }}
          >
            {/* <Flex p='1rem' pb='0rem' w='full' textAlign='start'> */}
            <HStack justifyContent={'space-between'} w='full' h='auto'>
              <Text as={'b'}>Best Seller (current)</Text>
              <IconButton
                disabled={bestSellerIsLoading}
                isLoading={bestSellerIsLoading}
                icon={<BiRefresh />}
                onClick={() => {
                  fetchBestSeller()
                }}
              />
            </HStack>
            {/* </Flex> */}
            <Box w='full' mt='10px' h='auto'>
              {!bestSellerIsLoading ? (
                <VStack w='full' mt='10px'>
                  {bestSeller?.map((item, index) => {
                    return (
                      <HStack
                        alignContent={'start'}
                        // borderBottom={'1px'}
                        w='full'
                        key={index}
                      >
                        <Box w='20%'>
                          <Image
                            maxH={'50px'}
                            maxW={'50px'}
                            src={
                              item?.img ? item?.img : '/product_Thumbnail.png'
                            }
                          />
                        </Box>
                        <Box w='65%'>
                          <Text align={'start'}>
                            {item?.name ? item?.name : ''}&nbsp;
                            {item?.nameOption ? item?.nameOption : ''}
                          </Text>
                        </Box>
                        <Box w='15%' align={'end'}>
                          <Text>
                            {item?.amount ? currency(item?.amount) : ''}
                          </Text>
                        </Box>
                      </HStack>
                    )
                  })}
                </VStack>
              ) : (
                <VStack w='full' mt='10px'>
                  {new Array(10).fill(null).map((_d, index) => (
                    <HStack alignContent={'start'} w='full' key={index}>
                      <Box w='20%'>
                        <Image maxH={'23px'} maxW={'23px'} as={SkeletonImage} />
                      </Box>
                      <Box w='65%'>
                        <Text>
                          <Skeleton height='20px' />
                        </Text>
                      </Box>
                      <Box w='15%'>
                        <Skeleton height='20px' />
                      </Box>
                    </HStack>
                  ))}
                </VStack>
              )}
            </Box>
          </Card>
        </GridItem>
      </Grid>
    </VStack>
  )
  // return option === 'expenses' ? (
  //   <OverviewExpenses option={option} setOption={setOption} />
  // ) : option === 'all' ? (
  //   <OverviewTotal option={option} setOption={setOption} />
  // ) : (
  //   <OverviewSales option={option} setOption={setOption} />
  // )
}

export default DashboardAdmin
