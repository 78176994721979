import { Center, Text } from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import React from 'react'
import { FiPower } from 'react-icons/fi'

function TimeOutWorking() {
  return (
    <Center w='100%'>
      <Card w={{ base: '100%', sm: '100%', md: '30rem' }} p='0px' h='27rem'>
        <Center w='100%' h='100%' flexDirection='column' color='black'>
          <Text fontSize='7xl'>
            <FiPower />
          </Text>
          <Text fontSize={{ base: 'lg', sm: 'lg', md: 'xl' }} pt='1rem'>
            ลงเวลาออกงานเรียบร้อยแล้ว
          </Text>
        </Center>
      </Card>
    </Center>
  )
}

export default TimeOutWorking
