import {
  Center,
  HStack,
  Flex,
  Box,
  Text,
  Button,
  Stack,
  Heading,
  Select,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import CustomTable from 'Components/Horizons/table/customTable'
import { SearchIcon } from '@chakra-ui/icons'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { currency, decodeUnit } from 'Utils/currency'
import { calPromotionDecodeUnitAmountMultipleItems } from 'Utils/calStock'
import { AiOutlineFileText } from 'react-icons/ai'
import { BiTransfer } from 'react-icons/bi'
import { BsCashCoin } from 'react-icons/bs'
import SearchDate from 'Components/Search/date'
import { branchPaymentReport } from './query'
import { collection, onSnapshot, query } from 'firebase/firestore'
import { db } from 'Config/firebase'
import { billStatus } from 'Utils'
import { CardCardpayment } from 'Components/CardReport/cardpayment'
import CSVDownloadForm from 'Components/csvDownload'

const _ = require('underscore')

function Price(props) {
  const { grandTotal } = props
  return (
    <HStack justifyContent='end' spacing='6em'>
      <Stack>
        <Box>
          <Text fontSize={{ base: '14px', md: '18px' }} color='#2B3674'>
            ยอดขายสุทธิ
          </Text>
        </Box>
        <Box>
          <Text
            fontSize={{ base: '12px', md: '16px' }}
            color='report.500'
            textAlign='right'
          >
            {currency(grandTotal, 2, true)}
          </Text>
        </Box>
      </Stack>
    </HStack>
  )
}

function BranchPayment({ user }) {
  const { paymentMessage } = billStatus()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [filterData, setFilterData] = useState([])
  const [onSearch, setOnSearch] = useState(false)
  const [month, setMonth] = useState(new Date())
  const [currentDate, setCurrentDate] = useState(true)
  const [currentMonth, setCurrentMonth] = useState(false)
  const [between, setBetween] = useState(false)
  const [prevMonth, setPrevMonth] = useState(false)
  const [bankSelect, setBankSelect] = useState('')
  const [typePayment, setTypePayment] = useState('')
  const [payment, setPayment] = useState([])
  const [allOrder, setAllOrder] = useState(0)
  const [csvData, setCSVData] = useState([])

  const [startDateTime, setStartDateTime] = useState(null)
  const [endDateTime, setEndDateTime] = useState(null)
  const [dateTime, setDateTime] = useState(false)

  useEffect(() => {
    const qPayment = query(collection(db, 'Payment'))
    const unsubscribe = onSnapshot(qPayment, (snapShot) => {
      let paymentList = []
      snapShot?.forEach((doc) => {
        return paymentList?.push({
          ...doc?.data(),
          id: doc?.id,
        })
      })
      setPayment(paymentList)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  function calGrandTotal(item) {
    const arrGrandTotal = _.pluck(item, 'grandTotal')
    return _.reduce(arrGrandTotal, (memo, num) => memo + num, 0)
  }

  const csvHeaders = [
    { label: 'ประเภท', key: 'payment' },
    { label: 'บัญชีธนาคาร', key: 'bankAccount' },
    { label: 'วันที่', key: 'date' },
    { label: 'เลขที่รายการ', key: 'orderId' },
    { label: 'ยอดชำระเงิน', key: 'paymentAmount' },
    { label: 'ยอดคืนเงิน', key: 'refundAmount' },
    { label: 'ยอดขายสุทธิ', key: 'grandTotal' },
  ]

  async function onSubmitFilter() {
    const res = await branchPaymentReport({
      month: month,
      startDate: startDate,
      endDate: endDate,
      between: between,
      prevMonth: prevMonth,
      currentDate: currentDate,
      currentMonth: currentMonth,
      user: user,
      bankSelect: bankSelect,
      typePayment: typePayment,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      dateTime: dateTime,
    })
    if (startDateTime && endDateTime) {
      setStartDate(startDateTime)
      setEndDate(endDateTime)
    } else {
      setStartDateTime(null)
      setEndDateTime(null)
    }
    const resIndex = _.indexBy(res?.searchResults, 'id')
    const dataCal = await Promise.all(
      _.map(resIndex, (order) => {
        const amount = calPromotionDecodeUnitAmountMultipleItems(order.items)
        const discount = decodeUnit(order.discountBalance)
        const cal = discount > 0 ? amount + discount : amount
        return {
          ...order,
          total: order.total,
          promotionAndDiscount: cal,
          grandTotal: order.grandTotal,
        }
      })
    )
    const dataToCSV = _.map(dataCal, (items) => {
      return {
        payment: paymentMessage(items.payment),
        bankAccount: items.bankAccount ? items.bankAccount : '-',
        branch: items.branch,
        date: items.date,
        orderId: items.orderId,
        paymentAmount: currency(items.paymentAmount, 2),
        refundAmount: currency(items.refundAmount, 2),
        grandTotal: currency(items.grandTotal, 2),
      }
    })
    setCSVData(dataToCSV)
    setAllOrder(res?.results?.length)
    setOnSearch(true)
    setFilterData(dataCal)
  }

  const cash = _.filter(filterData, (e) => e.payment === 'cash')
  const bank = _.filter(filterData, (e) => e.payment === 'bank')
  const calOrder = onSearch ? calGrandTotal(filterData) : 0
  const calCash = onSearch ? calGrandTotal(cash) : 0
  const caBank = onSearch ? calGrandTotal(bank) : 0

  const dataCard = [
    {
      label: 'รายการขายทั้งหมด',
      balance: calOrder,
      value: allOrder,
      colorIcon: '#2B3674',
      colorText: '#2B3674',
      icon: AiOutlineFileText,
    },
    {
      label: 'เงินสด',
      balance: calCash,
      value: cash?.length,
      colorIcon: '#2B3674',
      colorText: '#2B3674',
      icon: BsCashCoin,
    },
    {
      label: 'ธนาคาร',
      balance: caBank,
      value: bank?.length,
      colorIcon: '#2B3674',
      colorText: '#2B3674',
      icon: BiTransfer,
    },
  ]

  const columnsDataOrders = [
    {
      Header: 'ประเภทการชำระเงิน',
      accessor: 'payment',
      extra: (data, row) => (
        <Center key={row}>{paymentMessage(row.payment)}</Center>
      ),
    },
    {
      Header: 'บัญชีธนาคาร',
      accessor: 'bankAccount',
      extra: (data, row) => <Center key={row}>{row.bankAccount}</Center>,
    },
    {
      Header: 'วันที่',
      accessor: 'date',
      extra: (data, row) => <Center key={row}>{row.date}</Center>,
    },
    {
      Header: 'เลขที่รายการขาย',
      accessor: 'orderId',
      extra: (data, row) => <Center key={row}>{row.orderId}</Center>,
    },
    {
      Header: (
        <Text w='100%' textAlign='end'>
          ยอดการชำระเงิน
        </Text>
      ),
      accessor: 'paymentAmount',
      extra: (data, row) => (
        <Center key={row} justifyContent='end' w='full'>
          {currency(row.paymentAmount, 2)}
        </Center>
      ),
    },
    {
      Header: (
        <Text w='100%' textAlign='end'>
          ยอดการคืนเงิน
        </Text>
      ),
      accessor: 'refundAmount',
      extra: (data, row) => (
        <Center key={row} justifyContent='end' w='full'>
          {row.refundAmount > 0 ? currency(row.refundAmount, 2) : '-'}
        </Center>
      ),
    },
    {
      Header: (
        <Text w='100%' textAlign='end'>
          ยอดขายสุทธิ
        </Text>
      ),
      accessor: 'grandTotal',
      extra: (data, row) => (
        <Center key={row} justifyContent='end' w='full'>
          {currency(row.grandTotal, 2)}
        </Center>
      ),
    },
  ]

  return (
    <>
      <HStack
        mt='1rem'
        mb='0.5em'
        p={{ sm: '0px', md: '20px 20px 30px 20px' }}
        background={{ sm: 'none', md: '#FFFFFF' }}
        boxShadow={{
          sm: '0px',
          md: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        }}
        borderRadius={{ sm: '10px', md: '30px' }}
        spacing='1vw'
        flexDirection={{ sm: 'column', md: 'row' }}
        alignItems='flex-start'
      >
        {user?.role !== 'Cashier' && (
          <Flex
            flexDirection='column'
            w={{ sm: 'full', md: 'full', lg: '30vw' }}
          >
            <>
              <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
                <Text color='#8F9BBA'>ช่วงเวลา :</Text>
              </Box>
              <SearchDate
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                month={month}
                setMonth={setMonth}
                setBetween={setBetween}
                setPrevMonth={setPrevMonth}
                setCurrentDate={setCurrentDate}
                setCurrentMonth={setCurrentMonth}
                setStartDateTime={setStartDateTime}
                setEndDateTime={setEndDateTime}
                setDateTime={setDateTime}
                startDateTime={startDateTime}
                endDateTime={endDateTime}
              />
            </>
          </Flex>
        )}
        <Flex
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: 'full', lg: '29vw' }}
        >
          <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
            <Text color='#8F9BBA'>ช่องทางการชำระเงิน : </Text>
          </Box>
          <Select
            color={'gray.700'}
            w='full'
            bg={'#F4F7FE'}
            border='0px solid #E8E9E9'
            borderRadius='16px'
            defaultValue=''
            fontWeight='500'
            variant='main'
            placeholder={'ทั้งหมด'}
            _placeholder={{
              fontWeight: '400',
            }}
            h='44px'
            maxh='44px'
            onChange={(event) => setTypePayment(event.target.value)}
          >
            <option value='cash'>เงินสด</option>
            <option value='bank'>ธนาคาร</option>
          </Select>
        </Flex>
        <Flex
          pt={{ sm: '1rem', md: '0rem' }}
          flexDirection='column'
          w={{ sm: 'full', md: 'full', lg: '29vw' }}
          alignItems='start'
        >
          {typePayment === 'bank' && (
            <>
              <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
                <Text color='#8F9BBA'>บัญชีธนาคาร : </Text>
              </Box>
              <Select
                color={'gray.700'}
                w='full'
                bg={'#F4F7FE'}
                border='0px solid #E8E9E9'
                borderRadius='16px'
                defaultValue=''
                fontWeight='500'
                variant='main'
                placeholder={'ทั้งหมด'}
                _placeholder={{
                  fontWeight: '400',
                }}
                h='44px'
                maxh='44px'
                onChange={(event) => setBankSelect(event.target.value)}
              >
                {payment?.map((item, index) => (
                  <option
                    key={index}
                    value={item?.id}
                  >{`${item?.bank?.name}-${item?.accountingNumber}`}</option>
                ))}
              </Select>
            </>
          )}
        </Flex>
        <Flex
          flexDirection='column'
          w={{ sm: 'full', md: '19vw', lg: '13vw', xl: '11vw' }}
          alignItems='end'
          pt={{ sm: '1rem', md: '0rem' }}
        >
          <Box
            textAlign='start'
            display={{ sm: 'none', md: 'flex' }}
            p='0.5rem'
            pl='1rem'
            pt='0rem'
          >
            <Text color='#8F9BBA'>&nbsp;</Text>
          </Box>
          <Button
            h='44px'
            maxh='44px'
            w={{ sm: '10rem', md: 'full' }}
            colorScheme='btn'
            borderRadius='16px'
            display='flex'
            justifyContent={'space-around'}
            color='white'
            onClick={() => onSubmitFilter()}
          >
            <Text>
              <SearchIcon />
            </Text>
            <Text>แสดงรายงาน</Text>
          </Button>
        </Flex>
      </HStack>
      <Grid
        pb='0.5rem'
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(2, 1fr)',
          xl: 'repeat(3, 1fr)',
          '2xl': 'repeat(3, 1fr)',
        }}
        gap={{ base: '12px', sm: '12px', md: '20px' }}
      >
        {dataCard?.map((item, index) => (
          <GridItem key={index}>
            <CardCardpayment
              value={item?.value}
              colorIcon={item?.colorIcon}
              colorText={item?.colorText}
              icon={item?.icon}
              label={item?.label}
              balance={item?.balance}
            />
          </GridItem>
        ))}
      </Grid>
      <Flex
        flexDirection='column'
        p={{ sm: '10px', md: '20px 20px 30px 20px' }}
        bg='white'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
        borderRadius='30px'
      >
        <Stack spacing={{ base: '0em', md: '4em' }}>
          <HStack
            justifyContent={'space-between'}
            w='full'
            display={{ sm: 'block', md: 'flex' }}
          >
            <Stack>
              <Heading
                fontFamily={'Prompt'}
                color='#2B3674'
                fontSize={{ base: '24px', md: '30px' }}
              >
                รายงานยอดขายตามประเภทการชำระเงิน
              </Heading>
              <Box>
                <HStack>
                  <Text color='#2B3674'>ณ วันที่ :</Text>
                  <Text color='#2B3674'>
                    {moment(new Date().getTime()).format('MM/DD/YYYY')}
                  </Text>
                </HStack>
                <HStack>
                  <Text color='#2B3674'>ช่วงเวลา :</Text>
                  {startDate && endDate && (
                    <Text color='#2B3674'>
                      {moment(startDate).format('MM/DD/YYYY')} -{' '}
                      {moment(endDate).format('MM/DD/YYYY')}
                    </Text>
                  )}
                </HStack>
              </Box>
            </Stack>
            <HStack justifyContent={{ sm: 'start', md: 'end' }}>
              <CSVDownloadForm
                mt={{ sm: '10px', md: '0px' }}
                buttonTitle={'ดาวน์โหลดไฟล์ประเภทการชำระเงิน'}
                data={csvData}
                filename={'paymentReport'}
                headers={csvHeaders}
              />
            </HStack>
          </HStack>
          <Box
            pt={{ base: '1rem', sm: '1rem', md: '0rem', lg: '0rem' }}
            pr={{
              base: '0rem',
              sm: '0rem',
              md: '1rem',
              lg: '1rem',
              xl: '1rem',
            }}
          >
            <Price grandTotal={onSearch ? calGrandTotal(filterData) : 0} />
          </Box>
        </Stack>
        <CustomTable
          columnsData={columnsDataOrders}
          tableData={onSearch ? filterData : []}
          closeSearch={true}
        />
      </Flex>
    </>
  )
}

export default BranchPayment
