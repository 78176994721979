import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'

const _ = require('underscore')

function SidebarLinks(props) {
  const navigate = useNavigate()
  const { links, user, onClose, responsive, openStage, setOpenStage } = props
  let activeColor = useColorModeValue('gray.700', 'white')
  let activeIcon = useColorModeValue('navbar.500', 'white')
  // const [openStage, setOpenStage] = useState([])

  return (
    <>
      <Box>
        {/* <Accordion allowToggle allowMultiple> */}
        <Accordion
          allowToggle
          allowMultiple
          onChange={(e) => {
            setOpenStage(e)
          }}
          index={openStage}
        >
          {links.map((item, index) => {
            const roleLinkReturn =
              _.contains(item.role, user?.role) &&
              _.contains(item.branch, user?.branchSelect?.type)
            return (
              <Box key={index} fontFamily={'Prompt'}>
                {roleLinkReturn && (
                  <NewNav
                    href={item.href}
                    name={item.name}
                    items={item.items}
                    icon={item.icon}
                    onClose={onClose}
                    responsive={responsive}
                  />
                )}
              </Box>
            )
          })}
        </Accordion>
      </Box>
    </>
  )

  function NewNav({ href, name, items, icon, onClose }) {
    const { pathname } = useLocation()
    const splitPath = pathname.split('/')
    const hrefReplace = href.replace(/\//g, '')

    return (
      <>
        <AccordionItem borderBottom='0px' borderTop='0px'>
          <AccordionButton
            display='flex'
            align='center'
            justify='center'
            _hover={{
              bg: 'unset',
            }}
            _focus={{
              boxShadow: 'none',
            }}
            borderRadius='0.5rem'
            w={{
              sm: '100%',
              xl: '100%',
              '2xl': '95%',
            }}
            px={icon ? null : '0rem'}
            py='0rem'
            bg={'transparent'}
            ms={0}
          >
            <Box flex='1' textAlign='left'>
              <HStack
                mb={user?.role === 'Cashier' ? '0rem' : '0.375rem'}
                alignItems='center'
              >
                <Flex
                  w='100%'
                  alignItems='center'
                  fontSize={{ md: '16px', lg: '16px' }}
                  justifyContent='center'
                >
                  <Box
                    color={
                      splitPath &&
                      splitPath[1].toLowerCase() === hrefReplace.toLowerCase()
                        ? activeIcon
                        : 'secondaryGray.600'
                    }
                    me='0.75rem'
                    mt='0.375rem'
                  >
                    <Icon
                      as={icon}
                      width='1.5625rem'
                      height='1.5625rem'
                      color='inherit'
                      transition='all 0.6s'
                    ></Icon>
                  </Box>
                  <Text
                    me='auto'
                    color={
                      splitPath && splitPath[1] === hrefReplace
                        ? activeColor
                        : 'secondaryGray.600'
                    }
                    fontWeight='500'
                  >
                    {name}
                  </Text>
                </Flex>
              </HStack>
            </Box>
            <AccordionIcon
              color={
                splitPath && splitPath[1] === hrefReplace
                  ? activeColor
                  : 'secondaryGray.600'
              }
            />
          </AccordionButton>

          <AccordionPanel px='2.5rem'>
            <Stack>
              {items?.map((value, index) => {
                const itemsData =
                  _.contains(value.role, user?.role) &&
                  _.contains(value.branch, user?.branchSelect?.type)
                return (
                  itemsData && (
                    <Text
                      onClick={() => {
                        if (href + value.href !== pathname) {
                          if (href !== '/#') {
                            navigate(`${href + value.href}`)
                            if (responsive) {
                              onClose()
                            }
                          }
                        }
                      }}
                      cursor={'pointer'}
                      key={index}
                      fontSize='md'
                      color={
                        pathname === `${href + value.href}`
                          ? activeColor
                          : 'secondaryGray.600'
                      }
                      fontWeight={value?.href === pathname ? 'bold' : ''}
                    >
                      {value?.name}
                    </Text>
                  )
                )
              })}
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </>
    )
  }
}

export default SidebarLinks
