// Chakra imports
import { Flex, FormControl, Text, useColorModeValue } from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import InputField from 'Components/Horizons/fields/InputField'
import TextField from 'Components/Horizons/fields/TextField'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

export default function AddressDetail({ data, ...rest }) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const {
    setValue,
    control,
    formState: { errors },
  } = useForm()
  // Chakra Color Mode
  useEffect(() => {
    let isMounted = true
    const fetchValue = () => {
      if (isMounted) {
        setValue('address', data?.address)
        setValue('province', data?.province)
        setValue('amphoe', data?.amphoe)
        setValue('district', data?.district)
        setValue('zipcode', data?.zipcode)
      }
    }
    fetchValue()
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <FormControl h='full'>
      <Card mb='20px' h='full' {...rest}>
        <Flex direction='column' mb='30px' ms='10px'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            รายละเอียดที่อยู่
          </Text>
        </Flex>
        <InputField
          control={control}
          errors={errors}
          name='province'
          mb='25px'
          id='province'
          label='จังหวัด'
          placeholder='จังหวัด'
          readOnly
        />
        <InputField
          control={control}
          errors={errors}
          name='amphoe'
          mb='25px'
          id='amphoe'
          label='อำเภอ'
          placeholder='อำเภอ'
          readOnly
        />
        <InputField
          control={control}
          errors={errors}
          name='district'
          mb='25px'
          id='district'
          label='ตำบล'
          placeholder='ตำบล'
          readOnly
        />
        <InputField
          control={control}
          errors={errors}
          name='zipcode'
          mb='25px'
          id='zipcode'
          label='รหัสไปรษณีย์'
          placeholder='รหัสไปรษณีย์'
          readOnly
        />
        <TextField
          control={control}
          errors={errors}
          name='address'
          id='address'
          label='รายละเอียดที่อยู่'
          mb='0px'
          h='100%'
          placeholder='รายละเอียดที่อยู่'
          readOnly
        />
      </Card>
    </FormControl>
  )
}
