import { Flex, Grid, Box, Text, HStack, VStack, Center } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import Card from 'Components/Horizons/card/Card'
import { DashboardCard } from './Components/card'
import OverallRevenue from './Components/OverallRevenue'
import { queryOrderBranch } from './query'
import SearchDate from 'Components/Search/date'

function DashboardBranch({ user }) {
  const [currentDate, setCurrentDate] = useState(true)
  const [currentMonth, setCurrentMonth] = useState(false)
  const [between, setBetween] = useState(false)
  const [prevMonth, setPrevMonth] = useState(false)
  const [month, setMonth] = useState(new Date())
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [startDateTime, setStartDateTime] = useState(null)
  const [endDateTime, setEndDateTime] = useState(null)
  const [dateTime, setDateTime] = useState(false)

  const [work, setWork] = useState([])
  const [expenses, setExpenses] = useState([])
  const [userList, setUserList] = useState([])

  useEffect(() => {
    queryOrderBranch({
      month: month,
      startDate: startDate,
      endDate: endDate,
      between: between,
      prevMonth: prevMonth,
      setData: setData,
      currentDate: currentDate,
      currentMonth: currentMonth,
      user: user,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      dateTime: dateTime,
      setWork: setWork,
      setExpenses: setExpenses,
      setUserList: setUserList,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startDate,
    endDate,
    month,
    between,
    prevMonth,
    currentDate,
    currentMonth,
    dateTime,
    startDateTime,
    endDateTime,
    user,
  ])

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {!isLoading ? (
        <VStack spacing='2rem' direction='column' width='stretch'>
          <Card direction='column'>
            <HStack
              p={{ sm: '0px', md: '20px 20px 30px 20px' }}
              spacing='1vw'
              flexDirection={{ sm: 'column', md: 'row' }}
            >
              <Flex
                flexDirection='column'
                w={{ base: '100%', sm: '100%', md: '30rem' }}
              >
                <Box textAlign='start' p='0.5rem' pl='1rem' pt='0rem'>
                  <Text color='#8F9BBA'>เลือกช่วงเวลา :</Text>
                </Box>
                <SearchDate
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  month={month}
                  setMonth={setMonth}
                  setBetween={setBetween}
                  setPrevMonth={setPrevMonth}
                  setCurrentDate={setCurrentDate}
                  setCurrentMonth={setCurrentMonth}
                  setStartDateTime={setStartDateTime}
                  setEndDateTime={setEndDateTime}
                  setDateTime={setDateTime}
                  startDateTime={startDateTime}
                  endDateTime={endDateTime}
                />
              </Flex>
            </HStack>

            <DashboardCard
              order={data}
              userList={userList}
              work={work}
              expenses={expenses}
            />
          </Card>

          <Grid
            mb='20px'
            w='100%'
            display={{ base: 'none', sm: 'none', md: 'grid', lg: 'grid' }}
          >
            <Flex w='100%'>
              <OverallRevenue allData={data} userList={userList} work={work} />
            </Flex>
          </Grid>
        </VStack>
      ) : (
        <Center
          h={{ base: '70vh', sm: '70vh', md: '50vh', lg: '50vh' }}
          flexDirection='column'
          w='100%'
        >
          <Center flexDirection='column'>
            <Text pb='1rem' fontSize='xl' color='loading.100'>
              รอดำเนินการ
            </Text>
            <Box className='loading' color='loading.100'></Box>
          </Center>
        </Center>
      )}
    </>
  )
}

export default DashboardBranch
