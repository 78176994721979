import { Box } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { BiBasket } from 'react-icons/bi'
import './cart.css'

function CardAnimation(props) {
  const { shake } = props
  const ref = useRef()

  useEffect(() => {
    shake
      ? ref.current.classList.add('shake')
      : ref.current.classList.remove('shake')
  }, [shake])
  return (
    <Box className='cart-container' ref={ref}>
      <BiBasket className='cart' />
    </Box>
  )
}

export { CardAnimation }
