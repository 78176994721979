import {
  Box,
  Button,
  Flex,
  Image,
  SimpleGrid,
  TabList,
  Tabs,
  Tab,
  useColorModeValue,
  Text,
  TabPanel,
  TabPanels,
  Stack,
  Icon,
  Center,
  HStack,
  Fade,
  FormLabel,
  Select,
  FormControl,
  RadioGroup,
  Radio,
  FormErrorMessage,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import InputField from 'Components/Horizons/fields/InputField'
import TextField from 'Components/Horizons/fields/TextField'
import { useBranch } from 'Hooks'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { MdOutlineCloudUpload } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import Dropzone from './components/dropzone'
import Address from 'Mock/address.json'
import SelectField from 'Components/Horizons/fields/SelectField'

const _ = require('underscore')
function FormBranch({ id, data }) {
  const { createBranch, updateBranch } = useBranch()
  const [allAddress, setAllAddress] = useState([])
  const [file, setFile] = useState(null)
  const [isFadePage, setIsFadePage] = useState(true)
  const Navigate = useNavigate()
  const [isSubmit, setIsSubmit] = useState(false)
  const [isLoadingImage, setIsLoadingImage] = useState(true)
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const bg = useColorModeValue('gray.100', 'navy.700')
  const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100')
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const [activeBullets, setActiveBullets] = useState({
    product: true,
    media: false,
    pricing: false,
  })

  const productTab = useRef()
  const mediaTab = useRef()
  const pricingTab = useRef()
  const brand = useColorModeValue('brand.500', 'brand.400')

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      province: '',
      amphoe: '',
      zipcode: '',
      taxNumber: '',
      branchTel: '',
      fax: '',
      webSite: '',
      wholeSale: false,
    },
  })

  const province = _.pluck(allAddress, 'name')
  const amphoe = _.pluck(
    _.find(allAddress, (itm) => itm.name === watch('province'))?.items,
    'name'
  )
  const district = _.pluck(
    _.find(
      _.find(allAddress, (itm) => itm.name === watch('province'))?.items,
      (itm) => itm.name === watch('amphoe')
    )?.items,
    'name'
  )
  const zipcode = _.pluck(
    _.filter(
      _.find(
        _.find(allAddress, (itm) => itm.name === watch('province'))?.items,
        (itm) => itm.name === watch('amphoe')
      )?.items,
      (itm) => itm.name === watch('district')
    ),
    'zipcode'
  )

  function onSubmit(value) {
    setIsSubmit(true)
    const branchImage = file || ''
    if (id) {
      updateBranch(id, value, branchImage).then(() => {
        Navigate('/branchs/list')
        setIsSubmit(false)
      })
    } else {
      createBranch(value, branchImage).then(() => {
        setIsSubmit(false)
        Navigate('/branchs/list')
      })
    }
  }
  useEffect(() => {
    if (id === 'HQ') {
      Navigate('/branchs/list')
    }
    if (data) {
      setValue('branchTel', data?.branchTel)
      setValue('fax', data?.fax)
      setValue('name', data?.name)
      setValue('webSite', data?.webSite)
      setValue('address', data?.address)
      setValue('taxNumber', data?.taxNumber)
      setValue('tel', data?.tel)
      setValue('typeOfBranch', data?.typeOfBranch)
      setValue('typeOfBusiness', data?.typeOfBusiness)
      setValue('vat', data?.vat)
      setValue('province', data?.province)
      setValue('amphoe', data?.amphoe)
      setValue('district', data?.district)
      setValue('zipcode', data?.zipcode)
      setValue('wholeSale', data?.wholeSale)
    }
    if (data || file) {
      setTimeout(() => {
        setIsLoadingImage(false)
      }, 500)
    }
    setTimeout(() => {
      setIsFadePage(false)
    }, 200)
    setAllAddress(Address)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, data])
  return (
    <Fade in={!isFadePage}>
      <Flex direction='column' minH='100vh' align='center' position='relative'>
        <Box
          h='45vh'
          bg='branchs.700'
          position='absolute'
          w={{ sm: '100vw', md: '100%', lg: '100%' }}
          borderRadius={{ sm: '0px', md: '30px', lg: '30px' }}
        ></Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            variant='unstyled'
            mt={{ base: '60px', md: '165px' }}
            zIndex='base'
            display='flex'
            flexDirection='column'
            w='100%'
          >
            <TabList
              display='flex'
              align='center'
              alignSelf='center'
              justifySelf='center'
            >
              <Tab
                ref={productTab}
                _focus='none'
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    product: true,
                    media: false,
                    pricing: false,
                  })
                }
              >
                <Flex
                  direction='column'
                  justify='center'
                  align='center'
                  position='relative'
                  zIndex='base'
                  _before={{
                    content: "''",
                    width: { sm: '120px', md: '250px', lg: '380px' },
                    height: '3px',
                    bg: activeBullets.media ? 'white' : 'branchs.800',
                    left: { sm: '12px', md: '40px' },
                    top: {
                      sm: activeBullets.product ? '6px' : '4px',
                      md: null,
                    },
                    position: 'absolute',
                    bottom: activeBullets.product ? '40px' : '38px',

                    transition: 'all .3s ease',
                  }}
                >
                  <Box
                    zIndex='1'
                    border='2px solid'
                    borderColor={activeBullets.product ? 'white' : 'white'}
                    bg='branchs.900'
                    w='16px'
                    h='16px'
                    mb='8px'
                    borderRadius='50%'
                  />
                  <Text
                    color={activeBullets.product ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.product ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    ข้อมูลสาขา
                  </Text>
                </Flex>
              </Tab>

              <Tab
                disabled
                ref={mediaTab}
                _focus='none'
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    product: true,
                    media: true,
                    pricing: false,
                  })
                }
              >
                <Flex
                  zIndex='base'
                  direction='column'
                  justify='center'
                  align='center'
                  position='relative'
                  _before={{
                    content: "''",
                    width: {
                      sm: '120px',
                      md: '250px',
                      lg: '300px',
                    },
                    height: '3px',
                    bg: activeBullets.pricing ? 'white' : 'branchs.800',
                    left: { sm: '12px', md: '60px', lg: '58px' },
                    top: '6px',
                    position: 'absolute',
                    bottom: activeBullets.media ? '40px' : '38px',

                    transition: 'all .3s ease',
                  }}
                >
                  <Box
                    zIndex='1'
                    border='2px solid'
                    borderColor={activeBullets.media ? 'white' : 'white'}
                    bg='branchs.900'
                    w='16px'
                    h='16px'
                    mb='8px'
                    borderRadius='50%'
                  />
                  <Text
                    color={activeBullets.media ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.media ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    รายละเอียดธุรกิจ
                  </Text>
                </Flex>
              </Tab>

              <Tab
                ref={pricingTab}
                _focus='none'
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    product: true,
                    media: true,
                    pricing: true,
                  })
                }
              >
                <Flex
                  zIndex='base'
                  direction='column'
                  justify='center'
                  align='center'
                  position='relative'
                >
                  <Box
                    zIndex='1'
                    border='2px solid'
                    borderColor={activeBullets.pricing ? 'white' : 'white'}
                    bg='branchs.900'
                    w='16px'
                    h='16px'
                    mb='8px'
                    borderRadius='50%'
                  />
                  <Text
                    color={activeBullets.pricing ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.pricing ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    ข้อมูลการติดต่อ
                  </Text>
                </Flex>
              </Tab>
            </TabList>
            <TabPanels
              mt='24px'
              w={{ base: '100%', sm: '100%', md: '100%', lg: '850px' }}
              mx='auto'
            >
              <TabPanel
                w={{ sm: '100vw', md: '700px', lg: '850px' }}
                p='0px'
                mx='auto'
              >
                <Card
                  p={{ base: '15px', sm: '15px', md: '30px' }}
                  px={{ base: '30px', sm: '30px', md: '30px' }}
                >
                  <Text
                    fontSize='2xl'
                    fontWeight='700'
                    mb='20px'
                    color='#1B2559'
                  >
                    ข้อมูลสาขา
                  </Text>
                  <Flex direction='column' w='100%'>
                    <Controller
                      name='typeOfBusiness'
                      control={control}
                      defaultValue={''}
                      rules={{
                        required: {
                          value: true,
                          message: 'กรุณาเลือกประเภทธุรกิจ',
                        },
                      }}
                      render={({ field }) => (
                        <FormControl isInvalid={errors['typeOfBusiness']}>
                          <Flex
                            direction='column'
                            mb={errors['typeOfBusiness'] ? '0px' : '34px'}
                            color='#1B2559'
                          >
                            <FormLabel
                              ms='10px'
                              htmlFor='typeOfBusiness'
                              fontSize='sm'
                              color={textColor}
                              fontWeight='bold'
                              _hover={{ cursor: 'pointer' }}
                            >
                              ประเภทธุรกิจ
                            </FormLabel>
                            <Select
                              border='1px'
                              borderColor='gray.300'
                              _focus={{
                                border: '1px',
                                borderColor: 'gray.300',
                              }}
                              _selected={{
                                border: '1px',
                                borderColor: 'gray.300',
                              }}
                              variant={'no-effects'}
                              borderRadius={{
                                base: '10px',
                                sm: '10px',
                                md: '10px',
                                lg: '16px',
                              }}
                              fontSize='sm'
                              id='typeOfBusiness'
                              color={'#1B2559'}
                              h='44px'
                              maxh='44px'
                              placeholder='กรุณาเลือกประเภทธุรกิจ'
                              value={field.value}
                              onChange={field.onChange}
                            >
                              <option value='บริษัท'>บริษัท</option>
                              <option value='ห้างหุ้นส่วน'>ห้างหุ้นส่วน</option>
                              <option value='บุคคลธรรมดา/ฟรีแลนด์'>
                                บุคคลธรรมดา/ฟรีแลนด์
                              </option>
                            </Select>
                          </Flex>
                          <FormErrorMessage mb={'9px'}>
                            {errors['typeOfBusiness'] &&
                              errors['typeOfBusiness']?.message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                    <Controller
                      name='vat'
                      control={control}
                      defaultValue={''}
                      rules={{
                        required: {
                          value: true,
                          message: 'กรุณาระบุการจดภาษีมูลค่าเพิ่ม',
                        },
                      }}
                      render={({ field }) => (
                        <FormControl isInvalid={errors['vat']}>
                          <Flex
                            color='#1B2559'
                            direction='column'
                            mb={errors['vat'] ? '0px' : '34px'}
                          >
                            <FormLabel
                              ms='10px'
                              htmlFor='vat'
                              fontSize='sm'
                              color={textColor}
                              fontWeight='bold'
                              _hover={{ cursor: 'pointer' }}
                            >
                              จดภาษีมูลค่าเพิ่ม
                            </FormLabel>
                            <Select
                              border='1px'
                              borderColor='gray.300'
                              _focus={{
                                border: '1px',
                                borderColor: 'gray.300',
                              }}
                              _selected={{
                                border: '1px',
                                borderColor: 'gray.300',
                              }}
                              variant={'no-effects'}
                              fontSize='sm'
                              id='vat'
                              color={'#1B2559'}
                              borderRadius={{
                                base: '10px',
                                sm: '10px',
                                md: '10px',
                                lg: '16px',
                              }}
                              h='44px'
                              maxh='44px'
                              placeholder='กรุณาระบุ'
                              value={field.value}
                              onChange={field.onChange}
                            >
                              <option value='จดภาษีมูลค่าเพิ่มแล้ว'>
                                จดภาษีมูลค่าเพิ่มแล้ว
                              </option>
                              <option value='ยังไม่ได้เข้าระบบภาษีมูลค่าเพิ่ม'>
                                ยังไม่ได้เข้าระบบภาษีมูลค่าเพิ่ม
                              </option>
                            </Select>
                          </Flex>
                          <FormErrorMessage>
                            {errors['vat'] && errors['vat']?.message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                    <Flex justify='space-between' mt='30px'>
                      <Button
                        variant='light'
                        fontSize='sm'
                        borderRadius='16px'
                        w={{ base: '128px', md: '148px' }}
                        h='46px'
                        onClick={() => Navigate('/branchs/list')}
                      >
                        ย้อนกลับ
                      </Button>
                      <Button
                        colorScheme='btn'
                        color='#ffff'
                        fontSize='sm'
                        borderRadius='16px'
                        w={{ base: '128px', md: '148px' }}
                        h='46px'
                        ms='auto'
                        onClick={async () => {
                          const resultType = await trigger('typeOfBusiness')
                          const resultVat = await trigger('vat')
                          if (!resultType || !resultVat) {
                            return errors
                          } else {
                            return mediaTab.current.click()
                          }
                        }}
                      >
                        ถัดไป
                      </Button>
                    </Flex>
                  </Flex>
                </Card>
              </TabPanel>
              <TabPanel
                w={{ sm: '100vw', md: '700px', lg: '850px' }}
                p='0px'
                mx='auto'
              >
                <Card
                  p={{ base: '15px', sm: '15px', md: '30px' }}
                  px={{ base: '30px', sm: '30px', md: '30px' }}
                >
                  <HStack justifyContent={file ? 'space-between' : 'start'}>
                    <Text
                      fontSize='2xl'
                      fontWeight='700'
                      mb='20px'
                      color='#1B2559'
                    >
                      รายละเอียดธุรกิจ
                    </Text>
                  </HStack>
                  <FormControl>
                    {file ? (
                      <Center>
                        <Dropzone
                          w='20rem'
                          setFile={setFile}
                          content={
                            <Box>
                              <Center
                                as={Fade}
                                in={!isLoadingImage}
                                align='center'
                                justify='center'
                                bg={bg}
                                borderColor={borderColor}
                                w='100%'
                                maxW='100%'
                                h='max-content'
                                maxH='200px'
                                cursor='pointer'
                                flexDirection='column'
                              >
                                <Text
                                  fontSize='sm'
                                  fontWeight='500'
                                  color='secondaryGray.500'
                                >
                                  {' '}
                                  <Text
                                    fontSize='sm'
                                    mt='1.5rem'
                                    fontWeight='500'
                                    color='secondaryGray.500'
                                  >
                                    {' '}
                                  </Text>
                                </Text>
                                <Image
                                  maxH='200px'
                                  src={file}
                                  objectFit='cover'
                                />
                              </Center>
                            </Box>
                          }
                        />
                      </Center>
                    ) : (
                      <Center>
                        <Dropzone
                          w='20rem'
                          setFile={setFile}
                          content={
                            data?.img ? (
                              <Box>
                                <Center
                                  as={Fade}
                                  in={!isLoadingImage}
                                  align='center'
                                  justify='center'
                                  bg={bg}
                                  borderColor={borderColor}
                                  w='100%'
                                  maxW='100%'
                                  h='max-content'
                                  minH='150px'
                                  cursor='pointer'
                                  flexDirection='column'
                                >
                                  <Text
                                    fontSize='sm'
                                    fontWeight='500'
                                    color='secondaryGray.500'
                                  >
                                    {' '}
                                    <Text
                                      fontSize='sm'
                                      mt='1.5rem'
                                      fontWeight='500'
                                      color='secondaryGray.500'
                                    >
                                      {' '}
                                    </Text>
                                  </Text>
                                  <Image
                                    maxH='150px'
                                    src={data?.img}
                                    objectFit='cover'
                                  />
                                </Center>
                              </Box>
                            ) : (
                              <Box>
                                <Text
                                  fontSize='sm'
                                  fontWeight='500'
                                  color='secondaryGray.500'
                                >
                                  {' '}
                                  <Text
                                    fontSize='sm'
                                    mt='1.5rem'
                                    fontWeight='500'
                                    color='secondaryGray.500'
                                  >
                                    {' '}
                                  </Text>
                                </Text>
                                <Icon
                                  as={MdOutlineCloudUpload}
                                  w='80px'
                                  h='80px'
                                  color={textColor}
                                />
                                <Text
                                  mx='auto'
                                  mb='4px'
                                  fontSize='lg'
                                  fontWeight='700'
                                  whiteSpace='pre-wrap'
                                  color={textColor}
                                >
                                  อัพโหลดรูปภาพโลโก้สาขา
                                </Text>
                                <Text
                                  mx='auto'
                                  fontSize={{ base: '16px', md: '16px' }}
                                  fontWeight='700'
                                  whiteSpace='pre-wrap'
                                  color={brand}
                                >
                                  คลิก
                                </Text>
                              </Box>
                            )
                          }
                        />
                      </Center>
                    )}
                  </FormControl>
                  <SimpleGrid
                    pt={'1em'}
                    columns={{ base: '1', md: '2' }}
                    gap='20px'
                    color='#1B2559'
                  >
                    <Stack direction='column' gap='20px'>
                      <InputField
                        control={control}
                        errors={errors}
                        mb='0px'
                        id='name'
                        label='ชื่อสาขา'
                        name='name'
                        type='text'
                        defaultValue={''}
                        required={{
                          value: true,
                          message: 'กรุณากรอกชื่อสาขา',
                        }}
                      />
                      <InputField
                        control={control}
                        errors={errors}
                        mb='0px'
                        id='taxNumber'
                        label='เลขประจำตัวผู้เสียภาษี'
                        name='taxNumber'
                        type='text'
                        defaultValue={''}
                        pattern={{
                          value: /[0-9]/,
                          message: 'กรุณากรอกตัวเลขเท่านั้น',
                        }}
                      />
                      <Controller
                        name={'typeOfBranch'}
                        control={control}
                        defaultValue={''}
                        rules={{
                          required: {
                            value: true,
                            message: 'กรุณาเลือก',
                          },
                        }}
                        render={({ field }) => (
                          <FormControl isInvalid={errors['typeOfBranch']}>
                            <FormLabel
                              display='flex'
                              ms='10px'
                              htmlFor={'image'}
                              fontSize='sm'
                              color={textColorPrimary}
                              fontWeight='bold'
                              _hover={{ cursor: 'pointer' }}
                            >
                              <Text fontSize='sm' fontWeight='blod' ms='2px'>
                                สำนักงาน / สาขา
                              </Text>
                            </FormLabel>
                            <RadioGroup
                              pt='.75em'
                              onChange={field.onChange}
                              value={field.value}
                            >
                              <HStack direction='row'>
                                <Radio value='สำนักงานใหญ่'>สำนักงานใหญ่</Radio>
                                <Radio value='สาขา'>สาขา</Radio>
                              </HStack>
                            </RadioGroup>
                            <FormErrorMessage>
                              {errors['typeOfBranch'] &&
                                errors['typeOfBranch']?.message}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      />

                      {/* <Controller
                        name={'wholeSale'}
                        id={'wholeSale'}
                        control={control}
                        render={({ field }) => (
                          <FormControl isInvalid={errors['wholeSale']}>
                            <FormLabel
                              display='flex'
                              ms='10px'
                              htmlFor={'image'}
                              fontSize='sm'
                              color={textColorPrimary}
                              fontWeight='bold'
                              _hover={{ cursor: 'pointer' }}
                            >
                              <Text fontSize='sm' fontWeight='blod' ms='2px'>
                                ขายส่ง
                              </Text>
                            </FormLabel>
                            <Checkbox
                              onChange={field?.onChange}
                              isChecked={field?.value}
                              defaultValue={false}
                            >
                              ขายส่ง
                            </Checkbox>
                            <FormErrorMessage>
                              {errors['wholeSale'] &&
                                errors['wholeSale']?.message}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      /> */}
                    </Stack>
                    <Stack direction='column' gap='20px' h='full'>
                      <Stack direction={{ base: 'column', md: 'row' }}>
                        <SelectField
                          control={control}
                          errors={errors}
                          id={'province'}
                          name={'province'}
                          placeholder='จังหวัด'
                          label='จังหวัด'
                          mb='0px'
                          options={
                            province?.length > 0
                              ? province.map((itm) => itm)
                              : []
                          }
                          required={{
                            value: true,
                            message: 'กรุณาเลือกจังหวัด',
                          }}
                        />
                        {watch('province') && (
                          <SelectField
                            control={control}
                            errors={errors}
                            id={'amphoe'}
                            name={'amphoe'}
                            placeholder='อำเภอ'
                            label='อำเภอ'
                            mb='0px'
                            options={
                              amphoe?.length > 0
                                ? amphoe?.map((itm) => itm)
                                : []
                            }
                            required={{
                              value: true,
                              message: 'กรุณาเลือกอำเภอ',
                            }}
                          />
                        )}
                      </Stack>
                      {watch('amphoe') && (
                        <Stack direction={{ base: 'column', md: 'row' }}>
                          <SelectField
                            control={control}
                            errors={errors}
                            id={'district'}
                            name={'district'}
                            placeholder='ตำบล'
                            label='ตำบล'
                            mb='0px'
                            options={
                              district?.length > 0
                                ? district?.map((itm) => itm)
                                : []
                            }
                            required={{
                              value: true,
                              message: 'กรุณาเลือกตำบล',
                            }}
                          />
                          {watch('district') && (
                            <SelectField
                              control={control}
                              errors={errors}
                              id={'zipcode'}
                              name={'zipcode'}
                              placeholder='รหัสไปรษณีย์'
                              label='รหัสไปรษณีย์'
                              mb='0px'
                              options={
                                zipcode?.length > 0
                                  ? zipcode?.map((itm) => itm)
                                  : []
                              }
                              required={{
                                value: true,
                                message: 'กรุณาเลือกรหัสไปรษณีย์',
                              }}
                            />
                          )}
                        </Stack>
                      )}
                      <TextField
                        control={control}
                        errors={errors}
                        h='146px'
                        mb='0px'
                        id='Address'
                        variant={'no-effects'}
                        label='รายละเอียดที่อยู่'
                        name='address'
                        defaultValue={''}
                      />
                    </Stack>
                  </SimpleGrid>
                  <Flex justify='space-between' mt='30px'>
                    <Button
                      variant='light'
                      fontSize='sm'
                      borderRadius='16px'
                      w={{ base: '128px', md: '148px' }}
                      h='46px'
                      onClick={() => productTab.current.click()}
                    >
                      ย้อนกลับ
                    </Button>
                    <Button
                      colorScheme='btn'
                      color='#ffff'
                      fontSize='sm'
                      borderRadius='16px'
                      w={{ base: '128px', md: '148px' }}
                      h='46px'
                      onClick={async () => {
                        const resultName = await trigger('Name')
                        const resultBranch = await trigger('typeOfBranch')
                        const resultAddress = await trigger('address')
                        const resultTax = await trigger('taxNumber')
                        const resultProvince = await trigger('province')
                        const resultDistrict = await trigger('district')
                        const resultAmphoe = await trigger('amphoe')
                        const resultzipCode = await trigger('zipcode')
                        if (
                          !resultName ||
                          !resultBranch ||
                          !resultAddress ||
                          !resultTax ||
                          !resultProvince ||
                          !resultDistrict ||
                          !resultAmphoe ||
                          !resultzipCode
                        ) {
                          return errors
                        } else {
                          return pricingTab.current.click()
                        }
                      }}
                    >
                      ถัดไป
                    </Button>
                  </Flex>
                </Card>
              </TabPanel>
              <TabPanel
                w={{ sm: '100vw', md: '700px', lg: '850px' }}
                p='0px'
                mx='auto'
              >
                <Card
                  p={{ base: '15px', sm: '15px', md: '30px' }}
                  px={{ base: '30px', sm: '30px', md: '30px' }}
                >
                  <Text
                    color='#1B2559'
                    fontSize='2xl'
                    fontWeight='700'
                    mb='20px'
                  >
                    ข้อมูลการติดต่อ
                  </Text>
                  <Flex direction='column' w='100%' color='#1B2559'>
                    <Stack direction='column' spacing='20px'>
                      <SimpleGrid
                        pt={'1em'}
                        columns={{ base: '1', md: '2' }}
                        gap='20px'
                      >
                        <Stack direction='column' gap='20px'>
                          <InputField
                            control={control}
                            errors={errors}
                            mb='0px'
                            id='branchTel'
                            label='เบอร์สำนักงาน'
                            name='branchTel'
                            type='text'
                            maxLength={10}
                            defaultValue={''}
                            pattern={{
                              value: /[0-9]/,
                              message: 'กรุณากรอกตัวเลขเท่านั้น',
                            }}
                          />
                          <InputField
                            control={control}
                            errors={errors}
                            mb='0px'
                            id='tel'
                            label='เบอร์มือถือ'
                            name='tel'
                            type='text'
                            maxLength={10}
                            defaultValue={''}
                            required={{
                              value: false,
                              message: 'กรุณากรอกเบอร์มือถือ',
                            }}
                            pattern={{
                              value: /[0-9]/,
                              message: 'กรุณากรอกตัวเลขเท่านั้น',
                            }}
                          />
                        </Stack>
                        <Stack direction='column' gap='20px'>
                          <InputField
                            control={control}
                            errors={errors}
                            mb='0px'
                            id='fax'
                            label='เบอร์โทรสาร'
                            name='fax'
                            type='text'
                            maxLength={10}
                            defaultValue=''
                            pattern={{
                              value: /[0-9]/,
                              message: 'กรุณากรอกตัวเลขเท่านั้น',
                            }}
                          />
                          <InputField
                            control={control}
                            errors={errors}
                            mb='0px'
                            id='webSite'
                            label='เว็บไซต์'
                            name='webSite'
                            type='text'
                            defaultValue=''
                          />
                        </Stack>
                      </SimpleGrid>
                    </Stack>
                    <Flex justify='space-between' mt='30px'>
                      <Button
                        variant='light'
                        fontSize='sm'
                        borderRadius='16px'
                        w={{ base: '128px', md: '148px' }}
                        h='46px'
                        onClick={() => mediaTab.current.click()}
                      >
                        ย้อนกลับ
                      </Button>
                      <Button
                        type='submit'
                        colorScheme='btn'
                        color='#ffff'
                        fontSize='sm'
                        borderRadius='16px'
                        w={{ base: '128px', md: '148px' }}
                        h='46px'
                        ms='auto'
                        isLoading={isSubmit}
                        variant='solid'
                        loadingText={'Loading...'}
                      >
                        บันทึก
                      </Button>
                    </Flex>
                  </Flex>
                </Card>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </form>
      </Flex>
    </Fade>
  )
}

export { FormBranch }
