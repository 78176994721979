import { Navigate, useLocation } from 'react-router-dom'
import Login from 'Pages/Auth/login'
import { AuthLayout, Layout } from 'Layouts'
import DashboardAdmin from 'Pages/Dashboard/Admin'
import Products from 'Pages/Products'
import Branch from 'Pages/Branch'
import Pos from 'Pages/Pos'
import AddBranch from 'Pages/Branch/add/add'
import EditBranch from 'Pages/Branch/edit/edit'
import StaffPage from 'Pages/Staff'
import AddStaff from 'Pages/Staff/add/add'
import EditStaff from 'Pages/Staff/edit/edit'
import ProductCategory from 'Pages/Products/Category'
import Inventory from 'Pages/Inventory'
import Expenses from 'Pages/Expenses'
import AddCategory from 'Pages/Products/Category/add/add'
import EditCategory from 'Pages/Products/Category/edit/edit'
import SettingsInfomation from 'Pages/Settings/infomation'
import SettingsBranchPayment from 'Pages/Settings/Payment/branchPayment'
import ViewInventory from 'Pages/Inventory/view/view'
import TransferInventory from 'Pages/Inventory/view/transfer'
import DepositInventory from 'Pages/Inventory/view/deposit'
import WithdrawalInventory from 'Pages/Inventory/view/withdrawal'
import SettingPaymentAddBank from 'Pages/Settings/Payment/add/bank'
import AddExpenses from 'Pages/Expenses/add/add'
import ExpensesCategory from 'Pages/Expenses/Category'
import AddExpensesCategory from 'Pages/Expenses/Category/add/add'
import EditExpensesCategory from 'Pages/Expenses/Category/edit/edit'
import SettingPaymentEditBank from 'Pages/Settings/Payment/edit/bank'
import EditExpenses from 'Pages/Expenses/edit/edit'
import ViewBranch from 'Pages/Branch/view/view'
import UserProfile from 'Pages/Auth/profile'
import ChooseMerchant from 'Pages'
import ResetPassword from 'Pages/Auth/resetPassword'
import PosHistory from 'Pages/PosHistory'
import DashboardBranch from 'Pages/Dashboard/Branch'
import ReportBranch from 'Pages/ReportSales/ReportBranch'
import Refund from 'Pages/PosHistory/Refund'
import ReportProduct from 'Pages/ReportSales/ReportProduct'
import ReportStaff from 'Pages/ReportSales/ReportStaff'
import ExpenseReport from 'Pages/ReportExpense/ExpenseBranch'
import ExpenseExpenses from 'Pages/ReportExpense/ExpenseExpenses'
import DataHistoty from 'Pages/PosHistory/DataHistoty'
import Discount from 'Pages/Discount'
import { useAppContext } from 'Context'
import ViewExpenses from 'Pages/Expenses/view'
import ReExpenses from 'Pages/Expenses/re'
import AddDiscount from 'Pages/Discount/add/add'
import Expenseranch from 'Pages/ReportExpense/ExpenseBranch'
import ReportExpense from 'Pages/ReportExpense/ExpenseExpenses'
import ExpenseRefund from 'Pages/ReportExpense/ExpenseRefund'
import ExpenseDiscount from 'Pages/ReportExpense/ExpenseDiscount'
import ExpenseWarehouse from 'Pages/ReportExpense/ExpenseWarehouse'
import EditDiscount from 'Pages/Discount/edit/edit'
import SettingCRM from 'Pages/CRM/setting'
import ProductSales from 'Pages/ReportBranch/ReportSales/productsales'
import StaffSales from 'Pages/ReportBranch/ReportSales/staffsales'
import ReportBranchExpense from 'Pages/ReportBranch/ReportExpense/expenses'
import RefundBranchExpense from 'Pages/ReportBranch/ReportExpense/refund'
import DiscountBranchExpense from 'Pages/ReportBranch/ReportExpense/discount'
import WarehouseBranchExpense from 'Pages/ReportBranch/ReportExpense/warehouse'
import SaffWork from 'Pages/ReportBranch/ReportExpense/saffwork'
import PosAllHistory from 'Pages/PosHistory/posAllHistory'
import ExpensesSaffWork from 'Pages/ReportExpense/ExpensesSaffWork'
import AddOneProduct from 'Pages/Products/add/addOne'
import AddOptionProduct from 'Pages/Products/add/addOption'
import EditOnProduct from 'Pages/Products/edit/editOne'
import EditOptionProduct from 'Pages/Products/edit/editOption'
import DiscountProduct from 'Pages/DiscountProduct'
import AddDiscountProduct from 'Pages/DiscountProduct/add/add'
import ViewDiscountProduct from 'Pages/DiscountProduct/view'
import EditDiscountProduct from 'Pages/DiscountProduct/edit'
import DiscountProductBranchExpense from 'Pages/ReportBranch/ReportExpense/discountProduct'
import AccountSettings from 'Pages/Account/accountSettings'
import Sales from 'Pages/ReportBranch/ReportSales/sales'
import BranchProduct from 'Pages/ReportBranch/ReportSales/branchproduct'
import EmployeeSales from 'Pages/ReportBranch/ReportSales/employeesales'
import EmployeeWages from 'Pages/ReportBranch/ReportSales/employeewages'
import BranchPayment from 'Pages/ReportBranch/ReportSales/branchpayment'
import StaffCommissionView from 'Pages/Staff/view'
import WorkingHours from 'Pages/workinghours'
import StaffCommissionDaily from 'Pages/Staff/commissionDaily'
import StaffCommissionMonthly from 'Pages/Staff/commissionMonthly'
import StaffCommissionEmployee from 'Pages/Staff/commissionDaily/employee'
import StaffCommissionEmployeeMonthly from 'Pages/Staff/commissionMonthly/employee'
import PurchaseOrderDetail from 'Pages/PurchaseOrder/detail'
import PurchaseOrder from 'Pages/PurchaseOrder'
import ManagePurchaseOrder from 'Pages/PurchaseOrder/manage'
import ManagePurchaseOrderDetail from 'Pages/PurchaseOrder/manageDetail'

import CreatePO from 'Pages/PurchaseOrder/create'
import ExpenseSaffWork from 'Pages/ReportExpense/ExpensesSaffWork'

const _ = require('underscore')

function RequireAuth({ children, user, role, title }) {
  const userRole = user && _.contains(role, user?.role)
  const location = useLocation()
  if (!user) {
    return <Navigate to='/login' state={{ form: location }} />
  }
  if (user && !userRole) {
    if (user?.role === 'Administrator') {
      if (user?.branchSelect?.id === 'HQ') {
        return <Navigate to='/dashboard/admin' replace />
      } else {
        return <Navigate to='/pos/cashier' replace />
      }
    } else if (user?.role === 'Manager') {
      if (user?.branchSelect === 'HQ') {
        return <Navigate to='/purchase-order/manage' replace />
      }
      return user?.branchSelect ? (
        <Navigate to='/pos/cashier' replace />
      ) : (
        <Navigate to='/' replace />
      )
    } else if (user?.role === 'Cashier') {
      return <Navigate to='/pos/cashier' replace />
    }
  }
  return location.pathname === '/' ? (
    children
  ) : (
    <Layout user={user} title={title}>
      {children}
    </Layout>
  )
}

function RequireWork({ children, user }) {
  if (
    user?.workRef ||
    user.role === 'Administrator' ||
    user.role === 'Manager'
  ) {
    return children
  } else {
    // if (user?.salaryType === 'daily') {
    return <Navigate to='/working/hours' replace />
    // } else {
    //   return <Navigate to='/pos/cashier' replace />
    // }
  }
}

function RequireBranch({ children, user, branch }) {
  const Branch = user?.branchSelect
  const userBranch = user && _.contains(branch, user?.branchSelect?.type)
  if (!Branch) {
    return <Navigate to='/' replace />
  }
  if (userBranch) {
    return children
  } else {
    return <Navigate to='/' replace />
  }
}

const PublicRoute = ({ children, user }) => {
  if (user) {
    return <Navigate to='/' replace />
  } else {
    return <AuthLayout>{children}</AuthLayout>
  }
}

export function useStoreRoute() {
  const { user } = useAppContext()
  const routes = [
    {
      path: '/',
      element: (
        <RequireAuth user={user} role={['Manager', 'Cashier', 'Administrator']}>
          {user?.branchSelect ? (
            user?.role === 'Administrator' ? (
              user?.branchSelect?.type === 'HQ' ? (
                <Navigate to={'/dashboard/admin/'} />
              ) : (
                // <Navigate to={'/dashboard/branch/'} />
                <Navigate to='/pos/cashier' replace />
              )
            ) : user?.role === 'Manager' ? (
              <Navigate to={'/pos/cashier'} />
            ) : (
              user?.role === 'Cashier' && <Navigate to={'/pos/cashier'} />
            )
          ) : (
            <ChooseMerchant user={user} />
          )}
        </RequireAuth>
      ),
    },
    {
      path: '/dashboard/admin',
      element: (
        <RequireAuth user={user} role={['Administrator']} title={['ภาพรวม']}>
          <RequireBranch user={user} branch={['HQ']}>
            <DashboardAdmin user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/dashboard/branch',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager']}
          title={['ภาพรวม']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <DashboardBranch user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/pos/cashier',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['POS']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <RequireWork user={user}>
              <Pos user={user} />
            </RequireWork>
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/pos/history',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['POS', 'รายการขาย']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <RequireWork user={user}>
              <PosHistory user={user} />
            </RequireWork>
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/pos/history/all',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['POS', 'รายการขายทั้งหมด']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <PosAllHistory user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/settings/infomation',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['ตั้งค่าธุรกิจ']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <SettingsInfomation user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/settings/payment/list',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['ตั้งค่าธุรกิจ', 'ช่องทางการเงิน']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <SettingsBranchPayment user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/settings/payment/branch/add/bank',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['ตั้งค่าธุรกิจ', 'ตั้งค่าช่องทางการเงิน', 'เพิ่มบัญชีธนาคาร']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <SettingPaymentAddBank user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/settings/payment/branch/edit/bank/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['ตั้งค่าธุรกิจ', 'ตั้งค่าช่องทางการเงิน', 'แก้ไขบัญชีธนาคาร']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <SettingPaymentEditBank user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/branchs/list',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['สาขา', 'สาขาทั้งหมด']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <Branch user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/branchs/add',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['สาขา', 'สร้างสาขา']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <AddBranch user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/branchs/edit/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['สาขา', 'แก้ไขสาขา']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <EditBranch user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/branchs/view/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager']}
          title={['สาขา', 'รายละอียดสาขา']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <ViewBranch user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/product/add/o',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['สินค้า', 'สร้างสินค้า']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <AddOneProduct user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/product/edit/o/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['สินค้า', 'แก้ไขสินค้า']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <EditOnProduct user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/product/add/op',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['สินค้า', 'สร้างสินค้า']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <AddOptionProduct user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/product/edit/op/:skuId',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['สินค้า', 'แก้ไขสินค้า']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <EditOptionProduct user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/inventory/branch',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager']}
          title={['คลังสินค้า', 'คลังสินค้าสาขา']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <Inventory user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/inventory/list',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['คลังสินค้า']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <Inventory user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/inventory/view/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['คลังสินค้า', 'รายละอียดสินค้า']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <ViewInventory user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/inventory/branch/view/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['คลังสินค้า', 'รายละอียดสินค้า']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา', 'HQ']}>
            <ViewInventory user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/inventory/transfer/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager']}
          title={['คลังสินค้า', 'ย้ายสินค้า']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา', 'HQ']}>
            <TransferInventory user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/inventory/deposit/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['คลังสินค้า', 'นำเข้าสินค้า']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <DepositInventory user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/inventory/withdrawal/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['คลังสินค้า', 'นำออกสินค้า']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <WithdrawalInventory user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/expenses/list',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['รายจ่าย']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา', 'HQ']}>
            <RequireWork user={user}>
              <Expenses user={user} />
            </RequireWork>
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/expenses/edit/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['รายจ่าย', 'หมวดหมู่รายจ่าย', 'แก้ไขหมวดหมู่รายจ่าย']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา', 'HQ']}>
            <EditExpenses user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/expenses/new/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['รายจ่าย', 'หมวดหมู่รายจ่าย', 'สร้างหมวดหมู่รายจ่ายซ้ำ']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา', 'HQ']}>
            <ReExpenses user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/expenses/view/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['รายจ่าย', 'หมวดหมู่รายจ่าย', 'รายละเอียด']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา', 'HQ']}>
            <ViewExpenses user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/expenses/category/list',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['รายจ่าย', 'หมวดหมู่รายจ่าย']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา', 'HQ']}>
            <RequireWork user={user}>
              <ExpensesCategory user={user} />
            </RequireWork>
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/expenses/category/add',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['รายจ่าย', 'เพิ่มรายจ่าย']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา', 'HQ']}>
            <AddExpensesCategory user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/expenses/category/edit/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['รายจ่าย', 'แก้ไขรายจ่าย']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา', 'HQ']}>
            <EditExpensesCategory user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/expenses/add',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['รายจ่าย']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา', 'HQ']}>
            <AddExpenses user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/product/list',
      element: (
        <RequireAuth user={user} role={['Administrator']} title={['สินค้า']}>
          <RequireBranch user={user} branch={['HQ']}>
            <Products user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/product/category',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['สินค้า', 'หมวดหมู่สินค้า']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <ProductCategory user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/product/category/add',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['สินค้า', 'หมวดหมู่สินค้า', 'เพิ่มมวดหมู่สินค้า']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <AddCategory user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/product/category/edit/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['สินค้า', 'หมวดหมู่สินค้า', 'แก้ไขหมวดหมู่สินค้า']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <EditCategory user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    // <------------------------------------------------------------------------------>
    {
      path: '/employees/commission/daily',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['ค่าจ้าง/คอมมิชชั่น พนักงานรายวัน']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <StaffCommissionDaily user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/employees/commission/daily/:id/:type',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายละเอียดค่าจ้าง/คอมมิชชั่น พนักงานรายวัน']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <StaffCommissionEmployee user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/employees/commission/monthly/:id/:type',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายละเอียดค่าจ้าง/คอมมิชชั่น พนักงานรายเดือน']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <StaffCommissionEmployeeMonthly user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/employees/commission/monthly',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['ค่าจ้าง/คอมมิชชั่นพนักงานรายเดือน']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <StaffCommissionMonthly user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/employees/commission/view',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายละเอียดค่าจ้าง/คอมมิชชั่น']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <StaffCommissionView user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/employees/list',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['ข้อมูลพนักงาน']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <StaffPage user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/employees/add',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['ข้อมูลพนักงาน', 'เพิ่มพนักงาน']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <AddStaff user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/employees/edit/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['ข้อมูลพนักงาน', 'แก้ไขข้อมูลพนักงาน']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <EditStaff user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    // <----------------------------------------------------------------------------->
    {
      path: '/report/branch',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานยอดขาย', 'รายงานยอดขายตามสาขา']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <ReportBranch user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/product',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานยอดขาย', 'รายงานยอดขายตามสินค้า']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <ReportProduct user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/staff',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานยอดขาย', 'รายงานยอดขายตามพนักงาน']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <ReportStaff user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    /*รายงานค่อา*/
    {
      path: '/report/expenses',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานรายจ่าย', 'รายงานรายจ่ายตามสาขา']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <ExpenseReport user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/category',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานรายจ่าย', 'รายงานรายจ่ายตามหมวดหมู่']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <ExpenseExpenses user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    /*              */
    {
      path: '/report/expenses',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานรายจ่าย', 'รายงานรายจ่ายตามสาขา']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <Expenseranch user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/branch/expenses',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานรายจ่าย', 'รายงานรายจ่าย']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <ReportExpense user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/refund',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานรายจ่าย', 'รายงานการคืนเงิน']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <ExpenseRefund user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/staffwork',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานการเข้า-ออกพนักงาน', 'รายงานการเข้า-ออกพนักงาน']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <ExpenseSaffWork user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/discount',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานรายจ่าย', 'รายการส่วนลด']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <ExpenseDiscount user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/warehouse',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานรายจ่าย', 'รายงานคลังสินค้า']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <ExpenseWarehouse user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/staffwork',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานรายจ่าย', 'รายงาน login logout พนักงาน']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <ExpensesSaffWork user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/pos/history/datahistory/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['รายการขาย', 'ข้อมูลรายการขาย']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <DataHistoty user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/pos/history/refund/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['รายการขาย', 'ข้อมูลรายการขาย', 'คืนเงิน']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <Refund user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/discount/product',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['ส่วนลด', 'ส่วนลดสินค้า', 'รายละเอียด']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <DiscountProduct user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/discount/product/view/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['ส่วนลด', 'ส่วนลดสินค้า', 'รายละเอียด']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <ViewDiscountProduct user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/discount/product/edit/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['ส่วนลด', 'ส่วนลดสินค้า', 'แก้ไข']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <EditDiscountProduct user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/discount/product/add',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['ส่วนลด', 'ส่วนลดสินค้า', 'เพิ่มส่วนลดสินค้า']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <AddDiscountProduct user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/discount/store',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['ส่วนลด', 'ส่วนลดร้านค้า', 'รายละเอียด']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <Discount user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/discount/add',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['ส่วนลด', 'ส่วนลดร้านค้า', 'เพิ่มส่วนลด']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <AddDiscount user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/discount/edit/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['ส่วนลด', 'ส่วนลดร้านค้า', 'แก้ไขส่วนลด']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <EditDiscount user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/crm/setting',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager']}
          title={['CRM', 'ตั้งค่าสะสมแต้ม']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <SettingCRM user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/crm/priority',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager']}
          title={['CRM', 'สิทธิพิเศษ']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <SettingCRM user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/crm/member',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager']}
          title={['CRM', 'สมาชิก']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <SettingCRM user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    //<--------------------- NEW REPORT -------------------->
    {
      path: '/report/branch/sales',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานรายการขาย', 'รายงานรายการขาย']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <Sales user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/branch/product',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานยอดขายตามสินค้า', 'รายงานยอดขายตามสินค้า']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <BranchProduct user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/branch/employee',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager']}
          title={['รายงานยอดขายตามพนักงาน', 'รายงานยอดขายตามพนักงาน']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <EmployeeSales user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/branch/employeewages',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager']}
          title={['รายงานค่าจ้างพนักงาน', 'รายงานค่าจ้างพนักงานรายวัน']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <EmployeeWages user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/branch/payment',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager']}
          title={['รายงานยอดขายตามประเภทการชำระเงิน']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <BranchPayment user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    //<--------------------- NEW REPORT -------------------->
    {
      path: '/report/product/sales',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานยอดขาย', 'รายงานยอดขายตามสินค้า']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <ProductSales user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/staff/sales',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานยอดขาย', 'รายงานยอดขายตามพนักงาน']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <StaffSales user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/branch/expense',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager']}
          title={['รายงานรายจ่าย', 'รายงานรายจ่าย']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <ReportBranchExpense user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/refund/expense',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานรายจ่าย', 'รายงานการคืนเงิน']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <RefundBranchExpense user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/discount/expense',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานรายจ่าย', 'รายการส่วนลดร้านค้า']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <DiscountBranchExpense user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/discount/expense/product',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['รายงานรายจ่าย', 'รายการส่วนลดสินค้า']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <DiscountProductBranchExpense user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/warehouse/expense',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager']}
          title={['รายงานรายจ่าย', 'รายงานคลังสินค้า']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <WarehouseBranchExpense user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/report/staff/work',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager']}
          title={['รายงานรายจ่าย', 'รายงาน login logout พนักงาน']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <SaffWork user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/working/hours',
      element: (
        <RequireAuth
          user={user}
          role={['Cashier', 'Manager']}
          title={['ลงเวลาเข้างาน']}
        >
          <RequireBranch user={user} branch={['สาขา', 'สำนักงานใหญ่']}>
            <WorkingHours user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/account/setting',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['แก้ไขข้อมูลส่วนตัว']}
        >
          <RequireBranch user={user} branch={['HQ', 'สำนักงานใหญ่', 'สาขา']}>
            <AccountSettings user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    // สร้างใบ PO //

    {
      path: '/purchase-order/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['สั่งสินค้า']}
        >
          <RequireBranch user={user} branch={['HQ', 'สำนักงานใหญ่', 'สาขา']}>
            <PurchaseOrderDetail user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },

    {
      path: '/purchase-order/create',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['สั่งสินค้า']}
        >
          <RequireBranch user={user} branch={['HQ', 'สำนักงานใหญ่', 'สาขา']}>
            <CreatePO user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },

    {
      path: '/purchase-order/list',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator', 'Manager', 'Cashier']}
          title={['สั่งสินค้า']}
        >
          <RequireBranch user={user} branch={['สำนักงานใหญ่', 'สาขา']}>
            <PurchaseOrder user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },

    {
      path: '/purchase-order/manage/:id',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['จัดการใบสั่งสินค้า']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <ManagePurchaseOrderDetail user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },

    {
      path: '/purchase-order/manage',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['จัดการใบสั่งสินค้า']}
        >
          <RequireBranch user={user} branch={['HQ']}>
            <ManagePurchaseOrder user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },

    {
      path: '/profile',
      element: (
        <RequireAuth
          user={user}
          role={['Administrator']}
          title={['ข้อมูลส่วนตัว']}
        >
          <RequireBranch user={user}>
            <UserProfile user={user} />
          </RequireBranch>
        </RequireAuth>
      ),
    },
    {
      path: '/login',
      element: (
        <PublicRoute user={user}>
          <Login />
        </PublicRoute>
      ),
    },
    {
      path: '/resetpassword',
      element: (
        <PublicRoute user={user}>
          <ResetPassword />
        </PublicRoute>
      ),
    },
    {
      path: '*',
      element: <Navigate to={'/'} />,
    },
  ]

  return { routes }
}
