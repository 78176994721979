import { db } from 'Config/firebase'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'

import { decodeUnit } from 'Utils/currency'

const _ = require('underscore')

const prevDate = new Date()
prevDate.setHours(0)
prevDate.setMinutes(0)
prevDate.setSeconds(0)
prevDate.setMilliseconds(0)

const nextDate = new Date()
nextDate.setDate(nextDate.getDate() + 1)
nextDate.setHours(0)
nextDate.setMinutes(0)
nextDate.setSeconds(0)
nextDate.setMilliseconds(0)

export async function QueryReportSalesStaff({
  select,
  selectEmp,
  startDate,
  endDate,
  Branch,
}) {
  const start = new Date(startDate)
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  start.setMilliseconds(0)

  const dateEnd = new Date(endDate)
  dateEnd.setDate(dateEnd.getDate() + 1)
  dateEnd.setHours(0)
  dateEnd.setMinutes(0)
  dateEnd.setSeconds(0)
  dateEnd.setMilliseconds(0)

  const qOrders = select
    ? select && selectEmp
      ? select && selectEmp && startDate
        ? select && selectEmp && startDate && endDate
          ? query(
              collection(db, 'Orders'),
              where('status', '==', 'paid'),
              where('branch.id', '==', select),
              where('createdBy.id', '==', selectEmp),
              where('createdAt', '>=', start),
              where('createdAt', '<=', dateEnd),
              orderBy('createdAt', 'desc')
            )
          : query(
              collection(db, 'Orders'),
              where('status', '==', 'paid'),
              where('branch.id', '==', select),
              where('createdBy.id', '==', selectEmp),
              where('createdAt', '>=', start),
              orderBy('createdAt', 'desc')
            )
        : select && selectEmp && endDate
        ? query(
            collection(db, 'Orders'),
            where('status', '==', 'paid'),
            where('branch.id', '==', select),
            where('createdBy.id', '==', selectEmp),
            where('createdAt', '<=', dateEnd),
            orderBy('createdAt', 'desc')
          )
        : query(
            collection(db, 'Orders'),
            where('status', '==', 'paid'),
            where('branch.id', '==', select),
            where('createdBy.id', '==', selectEmp),
            where('createdAt', '>=', prevDate),
            where('createdAt', '<=', nextDate),
            orderBy('createdAt', 'desc')
          )
      : select && startDate
      ? select && startDate && endDate
        ? query(
            collection(db, 'Orders'),
            where('status', '==', 'paid'),
            where('branch.id', '==', select),
            where('createdAt', '>=', start),
            where('createdAt', '<=', dateEnd),
            orderBy('createdAt', 'desc')
          )
        : query(
            collection(db, 'Orders'),
            where('status', '==', 'paid'),
            where('branch.id', '==', select),
            where('createdAt', '>=', start),
            orderBy('createdAt', 'desc')
          )
      : select && endDate
      ? query(
          collection(db, 'Orders'),
          where('status', '==', 'paid'),
          where('branch.id', '==', select),
          where('createdAt', '<=', dateEnd),
          orderBy('createdAt', 'desc')
        )
      : query(
          collection(db, 'Orders'),
          where('status', '==', 'paid'),
          where('branch.id', '==', select),
          where('createdAt', '>=', prevDate),
          where('createdAt', '<=', nextDate),
          orderBy('createdAt', 'desc')
        )
    : selectEmp
    ? selectEmp && startDate
      ? selectEmp && startDate && endDate
        ? query(
            collection(db, 'Orders'),
            where('status', '==', 'paid'),
            where('createdBy.id', '==', selectEmp),
            where('createdAt', '>=', start),
            where('createdAt', '<=', dateEnd),
            orderBy('createdAt', 'desc')
          )
        : query(
            collection(db, 'Orders'),
            where('status', '==', 'paid'),
            where('createdBy.id', '==', selectEmp),
            where('createdAt', '>=', start),
            orderBy('createdAt', 'desc')
          )
      : selectEmp && endDate
      ? query(
          collection(db, 'Orders'),
          where('status', '==', 'paid'),
          where('createdBy.id', '==', selectEmp),
          where('createdAt', '<=', dateEnd),
          orderBy('createdAt', 'desc')
        )
      : query(
          collection(db, 'Orders'),
          where('status', '==', 'paid'),
          where('createdBy.id', '==', selectEmp),
          where('createdAt', '>=', prevDate),
          where('createdAt', '<=', nextDate),
          orderBy('createdAt', 'desc')
        )
    : startDate
    ? startDate && endDate
      ? query(
          collection(db, 'Orders'),
          where('status', '==', 'paid'),
          where('createdAt', '>=', start),
          where('createdAt', '<=', dateEnd),
          orderBy('createdAt', 'desc')
        )
      : query(
          collection(db, 'Orders'),
          where('status', '==', 'paid'),
          where('createdAt', '>=', start),
          orderBy('createdAt', 'desc')
        )
    : endDate
    ? query(
        collection(db, 'Orders'),
        where('status', '==', 'paid'),
        where('createdAt', '<=', dateEnd),
        orderBy('createdAt', 'desc')
      )
    : query(
        collection(db, 'Orders'),
        where('status', '==', 'paid'),
        where('createdAt', '>=', prevDate),
        where('createdAt', '<=', nextDate),
        orderBy('createdAt', 'desc')
      )

  const res = await (
    await getDocs(qOrders)
  ).docs.map((doc) => {
    return {
      ...doc.data(),
      id: doc.id,
      mBranch: doc.data()?.branch.name,
      employee: doc.data()?.createdBy.name,
      discount: decodeUnit(doc.data()?.discountBalance),
      total: decodeUnit(doc.data()?.total),
      grandTotal: decodeUnit(doc.data()?.grandTotal),
      commission: 0,
      promotion: 0,
      createdAt: doc
        .data()
        .createdAt.toDate()
        .toLocaleString('en-US', { hour12: false }),
    }
  })
  const allBranch = _.groupBy(res, 'mBranch')
  const data = Object.keys(allBranch).map((e, i) => ({
    branch: e,
    employee: null,
    createdAt: null,
    ordernumber: null,
    status: null,
    discount: null,
    commission: null,
    total: _.reduce(allBranch[e], (v, i) => v + i.total, 0),
    grandTotal: _.reduce(allBranch[e], (v, i) => v + i.grandTotal, 0),
    subRows: allBranch[e].map((itm, i) => ({
      employee: {
        id: itm.createdBy.id,
        name: itm.createdBy.name,
      },
      createdAt: itm.createdAt,
      ordernumber: itm.orderId,
      status: itm.status,
      discount: null,
      commission: null,
      total: itm.total,
      grandTotal: itm.grandTotal,
    })),
  }))

  return data
}
