import React, { useCallback, useEffect, useState } from 'react'
import { Stack, Fade, Center, Spinner } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useProduct } from 'Hooks'
import { FormOneProduct } from 'Components/form/Product/formOneProduct'

export default function EditOnProduct({ user }) {
  const { id } = useParams()
  const [data, setData] = useState()
  const { getProductId } = useProduct()
  const [isLoading, setIsLoading] = useState(true)
  const [isFade, setIsFade] = useState(true)

  const fetchData = useCallback(async () => {
    await getProductId(id).then((res) => {
      setData(res)
      setIsLoading(false)
      setIsFade(false)
    })
  }, [getProductId, id])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Stack direction='column'>
      {!isLoading && data ? (
        <Fade in={!isFade}>
          <FormOneProduct id={id} data={data} />
        </Fade>
      ) : (
        <Center>
          <Spinner size='md' />
        </Center>
      )}
    </Stack>
  )
}
