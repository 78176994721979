import { db } from 'Config/firebase'
import { useAppContext } from 'Context'
import { doc, setDoc, updateDoc } from 'firebase/firestore'

export function useCRM() {
  const { setMessage, setError } = useAppContext()
  function createCRM(value) {
    const CRMRef = doc(db, 'CRM', 'HQ')
    try {
      return setDoc(CRMRef, { ...value, createdAt: new Date() })
        .then(() => {
          setMessage({
            type: 'success',
            message: 'ตั้งค่าสำเร็จ',
          })
        })
        .catch((err) => {
          setError({
            code: 'CRM',
            message: err.message,
          })
        })
    } catch (error) {
      setError({
        code: 'CRM',
        message: error.message,
      })
    }
  }

  function updateCRM(value) {
    const CRMRef = doc(db, 'CRM', 'HQ')
    try {
      return updateDoc(CRMRef, { ...value, updatedAt: new Date() })
        .then(() => {
          setMessage({
            type: 'success',
            message: 'ตั้งค่าสำเร็จ',
          })
        })
        .catch((err) => {
          setError({
            code: 'CRM',
            message: err.message,
          })
        })
    } catch (error) {
      setError({
        code: 'CRM',
        message: error.message,
      })
    }
  }

  return { createCRM, updateCRM }
}
