import {
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'

import { QrReader } from '@blackbox-vision/react-qr-reader'
import { SearchIcon } from '@chakra-ui/icons'
import Card from 'Components/Horizons/card/Card'
import PosProductCard from 'Components/Product/Pos/card'
import PosProductCardSelect from 'Components/Product/Pos/itemSelect'
import { ViewFinder } from 'Components/viewFinder'
import { currency, decodeUnit } from 'Utils/currency'
import { AiOutlineScan } from 'react-icons/ai'
import {
  IoChevronBackOutline,
  IoChevronForwardOutline,
  IoPricetagsOutline,
  IoTrashBin,
} from 'react-icons/io5'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import {
  Type as ListType,
  SwipeAction,
  SwipeableList,
  SwipeableListItem,
  TrailingActions,
} from 'react-swipeable-list'
import 'react-swipeable-list/dist/styles.css'
import { search } from 'ss-search'
import _ from 'underscore'
import { calPromotionItems } from '../Utils/calPromotion'
import { calAmount } from '../Utils/calcurate'
import PosCoupons from './Coupons'
import PosMultiplePaymentCount from './Payment/Multiple/posMultiplePaymentCount'
import PosbankGetRQ from './Payment/bankGetRQ'
import { PosBankReceived } from './Payment/bankReceived'
import PosBankSelect from './Payment/bankSelect'
import PosBankTakeSlip from './Payment/bankTakeSlip'
import PosCashReceived from './Payment/cashReceived'
import DateLaterPayment from './Payment/dateLaterPayment'
import './Payment/loading.css'
import PosSelectPayment from './Payment/selectPayment'
import PosSuccessCashSinglePayment from './Payment/successCashSinglePayment'
import { height } from './Utils/height'
import { CardAnimation } from './card'
import './cart.css'

function MobilePos(props) {
  const {
    removeItemCart,
    cartItems,
    category,
    addCart,
    paymentStep,
    setPaymentStep,
    backStep,
    isOpen,
    onOpen,
    onClose,
    posUseDiscount,
    removePromotionItem,
    branchProductStock: products,
    mobile,
  } = props
  const [SearchResults, setSearchResults] = useState([])
  const [optionsSearch, setOptionsSearch] = useState({
    name: '',
    category: '',
  })
  const [isLoading, setIsLoading] = useState(true)
  const {
    isOpen: qrScanIsOpen,
    onOpen: qrScanOnOpen,
    onClose: qrScanOnClose,
  } = useDisclosure()

  const calItemCartTotalAmount = (carts) => {
    const pluckItemAmount = _.pluck(carts, 'amount')
    return _.reduce(pluckItemAmount, (memo, num) => {
      return memo + num
    }).toFixed(2)
  }

  const branchProductStock = useMemo(() => {
    return products
  }, [products])

  // <---------------------------Animation------------------------------>
  const [shake, setShake] = useState(false)
  const handleProductAdd = () => {
    setShake(true)
    setTimeout(() => {
      setShake(false)
    }, 500)
  }
  // <---------------------------Animation------------------------------->

  useEffect(() => {
    if (products) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    }
  }, [products])

  useEffect(() => {
    const searchText = optionsSearch.name
    const searchKey = ['name', 'nameOption', 'price']
    const findCategory = _.find(category, (i) => {
      return (
        i.id === optionsSearch?.category || i.name === optionsSearch?.category
      )
    })
    const searchQueryArr = branchProductStock?.filter((item) => {
      return optionsSearch?.category !== ''
        ? item?.category === findCategory?.id ||
            item?.category === findCategory?.name
        : item
    })

    const result = search(searchQueryArr, searchKey, searchText)
    setSearchResults(result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsSearch])

  const trailingActions = ({ item }) => (
    <TrailingActions>
      <SwipeAction
        fullSwipe={false}
        destructive={false}
        onClick={() => removeItemCart(item)}
      >
        <Box
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontWeight: '500',
            boxSizing: ' border-box',
            userSelect: 'none',
          }}
          color='#eee'
        >
          <Center bg='red' h='100%' w='4.5rem' display='flex' fontSize='14px'>
            Delete
          </Center>
        </Box>
      </SwipeAction>
    </TrailingActions>
  )

  const Cart = () => {
    return (
      <>
        <Box px='16px' pb='7rem'>
          <Flex
            flexDirection={{
              base: 'column',
              md: 'column',
              lg: 'column',
              xl: 'column',
            }}
            gridArea={{
              lg: 'none',
              xl: '1 / 3 / 2 / 4',
              '2xl': '1 / 2 / 2 / 3',
            }}
            justifyContent='space-between'
            w={{ md: '100%', lg: '27%', xl: '27%' }}
            h='max-content'
          >
            <Card
              px='0px'
              pt='0px'
              pb='10px'
              boxShadow='none'
              h='max-content'
              w='100%'
              justifyContent='space-between'
            >
              <Box mt='1rem'>
                <SimpleGrid
                  columns={{ base: 1, md: 1 }}
                  gap={{
                    base: '10px',
                    md: '10px',
                    lg: '10px',
                    xl: '15px',
                  }}
                  p={{ lg: '1.25rem', xl: '1.25rem', sxl: '1.25rem' }}
                >
                  {cartItems?.length < 1 ? (
                    <Box>ไม่มีข้อมูลการสั่งซื้อ</Box>
                  ) : (
                    cartItems?.map((item, i) => {
                      return (
                        <SwipeableList type={ListType.IOS} key={i}>
                          <SwipeableListItem
                            trailingActions={trailingActions({ item })}
                          >
                            <PosProductCardSelect
                              total={item.total}
                              key={i}
                              item={item}
                              {...props}
                            />
                          </SwipeableListItem>
                        </SwipeableList>
                      )
                    })
                  )}
                </SimpleGrid>
              </Box>
            </Card>
          </Flex>
        </Box>
        <Center
          flexDirection='column'
          w='100%'
          position='Fixed'
          bottom={0}
          pb='1rem'
          bg='white'
        >
          <Box
            mb='0rem'
            px='0rem'
            color='pos.500'
            borderTop='0px'
            w='95%'
            borderColor='#E0E5F2'
          >
            {/* <Box mt={'10px'}>
              <HStack justifyContent={'space-between'}>
                <Text>รวมทั้งหมด</Text>
                <Text>
                  {currency(
                    calAmount(cartItems) - calPromotionAmount(cartItems),
                    2
                  )}
                </Text>
              </HStack>
            </Box> */}
            <Box pt={'10px'}>
              <Flex
                justifyContent={'space-between'}
                align='center'
                onClick={() => {
                  setPaymentStep({
                    selectCoupons: true,
                  })
                }}
                cursor={'pointer'}
              >
                <HStack>
                  <Text
                    fontSize={'lg'}
                    bg='#FFF3E7'
                    p='5px'
                    borderRadius='full'
                  >
                    <IoPricetagsOutline color='#FF6501' />
                  </Text>
                  <Text fontSize={{ md: 'xl', lg: 'xl', xl: '2xl' }}>
                    โปรโมชั่น
                  </Text>
                  {posUseDiscount.length > 0 && (
                    <HStack>
                      <Text>(</Text>
                      <Text color='#FF6501'>{posUseDiscount.length}</Text>
                      <Text>)</Text>
                    </HStack>
                  )}
                </HStack>
                <Text color={'#A3AED0'}>
                  <IoChevronForwardOutline />
                </Text>
              </Flex>

              <Box>
                {/* โปรโมชั่นส่วนลด */}
                <Box maxH='8rem' overflowY='auto'>
                  {posUseDiscount?.length > 0 && (
                    <>
                      <Divider mt={'10px'} mb={'10px'} />
                      <Box mt={'.5rem'}>
                        {_.map(posUseDiscount, (i, itemIndex) => {
                          return (
                            <HStack
                              justifyContent={'space-between'}
                              key={itemIndex}
                              py='0.2rem'
                            >
                              <HStack>
                                <Text
                                  fontSize={'md'}
                                  bg='#FFF3E7'
                                  p='5px'
                                  borderRadius='full'
                                  color={'red'}
                                  cursor={'pointer'}
                                  onClick={() => {
                                    removePromotionItem(itemIndex, i)
                                  }}
                                >
                                  <IoTrashBin />
                                </Text>
                                <Text fontSize={'md'} color='#FF6501'>
                                  {i.discountName}
                                </Text>
                              </HStack>
                              <Text fontSize={'md'} color='#FF6501'>
                                {i.type === 'discount'
                                  ? i.valueDiscountType === 'Bath'
                                    ? currency(
                                        parseFloat(decodeUnit(i.valueDiscount)),
                                        2,
                                        true
                                      )
                                    : i.valueDiscountType === 'Percentage'
                                    ? `${currency(
                                        parseFloat(decodeUnit(i.valueDiscount)),
                                        0
                                      )}%`
                                    : null
                                  : i.type === 'free'
                                  ? i.categoryFree === null &&
                                    i.productFree === null
                                    ? calPromotionItems(cartItems, [i])
                                        ?.discount
                                    : `${currency(0, 2)}`
                                  : '-'}
                              </Text>
                            </HStack>
                          )
                        })}
                      </Box>
                    </>
                  )}
                </Box>
                <Divider mt={'10px'} mb={'10px'} />
                {posUseDiscount?.length > 0 && (
                  <Flex flexDirection={'row'} justify='space-between'>
                    <Text fontSize={'md'}>รวมส่วนลด</Text>
                    <Text fontSize={'md'} color='#FF6501'>
                      {currency(
                        calPromotionItems(cartItems, posUseDiscount)?.discount,
                        2
                      )}
                    </Text>
                  </Flex>
                )}

                {/* <Flex flexDirection={'row'} justify='space-between'>
                  <Text
                    fontSize={{
                      md: 'xl',
                      lg: '18px',
                      xl: '18px',
                      '2xl': 'xl',
                    }}
                  >
                    รวมเป็นเงิน
                  </Text>
                  <Text
                    fontSize={{
                      md: 'xl',
                      lg: '18px',
                      xl: '18px',
                      '2xl': 'xl',
                    }}
                  >
                    {calPromotionItems(cartItems, posUseDiscount)?.sum > 0
                      ? currency(
                          calPromotionItems(cartItems, posUseDiscount)?.sum,
                          2,
                          true
                        )
                      : 0}
                  </Text>
                </Flex> */}
              </Box>
            </Box>
            <Flex w='100%' pt='1rem'>
              <Flex
                flexDirection={'row'}
                pb='0.5rem'
                w='100%'
                justify='space-between'
              >
                <Text fontSize={{ md: 'xl', lg: 'xl', xl: '2xl' }}>
                  ยอดชำระ
                </Text>
                <Text fontSize={{ md: 'xl', lg: 'xl', xl: '2xl' }}>
                  {/* {posUseDiscount
                    ? calAmount(cartItems, posUseDiscount) > 0
                      ? currency(
                          calAmount(cartItems, posUseDiscount) -
                            calPromotionAmount(cartItems),
                          2,
                          true
                        )
                      : currency(0, 2, true)
                    : calAmount(cartItems) > 0
                    ? currency(
                        calAmount(cartItems) - calPromotionAmount(cartItems),
                        2,
                        true
                      )
                    : currency(0, 2, true)} */}
                  {calPromotionItems(cartItems, posUseDiscount)?.sum > 0
                    ? currency(
                        calPromotionItems(cartItems, posUseDiscount)?.sum,
                        2,
                        true
                      )
                    : 0}
                </Text>
              </Flex>
            </Flex>
            <Button
              w='full'
              h={'50px'}
              colorScheme={'btn'}
              fontSize={{
                md: '16px',
                lg: '2xl',
                xl: '2xl',
                '2xl': 'xl',
              }}
              disabled={
                cartItems?.length < 1 ||
                calAmount(cartItems, posUseDiscount) < 0
              }
              borderRadius={'5px'}
              onClick={() => {
                setPaymentStep({
                  selectPayment: true,
                })
              }}
            >
              ชำระเงิน
            </Button>
          </Box>
        </Center>
      </>
    )
  }

  return (
    <>
      <Modal size='sm' isOpen={qrScanIsOpen} onClose={qrScanOnClose}>
        <ModalOverlay />
        <ModalContent minHeight='450px'>
          <ModalHeader>SCAN QR</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <QrReader
              constraints={{ facingMode: 'environment' }}
              ViewFinder={() => <ViewFinder />}
              onResult={(result, error) => {
                if (result) {
                  let itemId = (result?.text).split('/')
                  if (itemId) {
                    const filterData = _.filter(branchProductStock, (item) => {
                      return item.id === itemId[5]
                    })
                    if (filterData) {
                      addCart(filterData[0])
                      qrScanOnClose()
                    }
                  }
                  // let route = (result?.text).split('passport')
                  // history.push(`/passport${route[1]}`)
                }
              }}
              style={{ width: '100%' }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box
        gap={{ base: '0px', xl: '20px' }}
        display={{ base: 'block', md: 'none', lg: 'none' }}
        bg='white'
      >
        <Flex
          alignContent={'center'}
          justifyContent={'center'}
          w='full'
          mt={{ sm: '-10px', md: '0px', lg: '10px' }}
        >
          <Flex
            flexDirection='column'
            w='full'
            borderTop='0.5px solid #E8E9E9'
            pt='0.5rem'
          >
            <Box px='0px' mb='15px' w='100%'>
              <Flex direction='column' ml={'0px'}>
                <Flex
                  mt='5px'
                  mb='5px'
                  direction={{ base: 'column', md: 'row' }}
                  align={{ base: 'start', md: 'center' }}
                  justifyContent='flex-start'
                  w='100%'
                  zIndex='base'
                >
                  <VStack px='0rem' spacing='15px' w='100%'>
                    <InputGroup
                      w={{ base: '100%', md: '250px' }}
                      h='44px'
                      maxh='44px'
                    >
                      <InputLeftElement
                        children={
                          <IconButton
                            bg='inherit'
                            borderRadius='10px'
                            _hover='none'
                            _active={{
                              bg: 'inherit',
                              transform: 'none',
                              borderColor: 'transparent',
                            }}
                            _focus={{
                              boxShadow: 'none',
                            }}
                            icon={
                              <SearchIcon
                                color={'gray.700'}
                                w='15px'
                                h='15px'
                              />
                            }
                          ></IconButton>
                        }
                      />
                      <Input
                        h='44px'
                        maxh='44px'
                        variant='search'
                        fontSize='sm'
                        bg={'white'}
                        border='1px solid #E8E9E9'
                        color={'gray.700'}
                        fontWeight='500'
                        _placeholder={{
                          color: 'gray.400',
                          fontSize: '14px',
                          opacity: 1,
                        }}
                        borderRadius={'10px'}
                        placeholder={'Search...'}
                        value={optionsSearch.name}
                        onChange={(e) => {
                          setOptionsSearch({
                            ...optionsSearch,
                            name: e.target.value,
                          })
                        }}
                      />
                      <InputRightAddon
                        w='max-content'
                        bg={'white'}
                        h='44px'
                        maxh='44px'
                        borderRadius='10px'
                        border='1px solid #E8E9E9'
                        onClick={qrScanOnOpen}
                        children={
                          <IconButton
                            border='0px solid #E8E9E9'
                            borderLeftRadius='0px'
                            bg={'white'}
                            _hover='none'
                            w='max-content'
                            _focus={{
                              boxShadow: 'none',
                            }}
                            icon={
                              <AiOutlineScan
                                bg={'white'}
                                color={'gray.700'}
                                fontSize='22px'
                              />
                            }
                          ></IconButton>
                        }
                      />
                    </InputGroup>
                    <Select
                      color={'gray.700'}
                      w='100%'
                      bg={'white'}
                      border='1px solid #E8E9E9'
                      borderRadius='10px'
                      defaultValue=''
                      fontWeight='500'
                      variant='main'
                      placeholder={'สินค้าทั้งหมด'}
                      _placeholder={{
                        fontWeight: '400',
                      }}
                      value={optionsSearch.category}
                      h='44px'
                      maxh='44px'
                      onChange={(e) => {
                        setOptionsSearch({
                          ...optionsSearch,
                          category: e.target.value,
                        })
                      }}
                    >
                      {category?.map((item, i) => {
                        return (
                          <option value={item.id} key={i}>
                            {item.name}
                          </option>
                        )
                      })}
                    </Select>
                  </VStack>
                </Flex>
              </Flex>
            </Box>
            <SimpleGrid
              w='100%'
              zIndex='base'
              columns={{ base: 2, sm: 2, md: 3, lg: 4, xl: 4 }}
              spacing='10px'
              pb='5rem'
            >
              {!isLoading
                ? optionsSearch.name !== '' || optionsSearch.category !== ''
                  ? SearchResults?.length > 0 &&
                    SearchResults?.map((item, i) => {
                      return (
                        <PosProductCard
                          handleProductAdd={handleProductAdd}
                          cartItems={_.indexBy(cartItems, 'id')}
                          total={item?.total}
                          nameOption={item?.nameOption}
                          img={item?.img}
                          title={item?.name}
                          price={item?.price}
                          id={item?.id}
                          key={i}
                          item={item}
                          addCart={addCart}
                          mobile={mobile}
                        />
                      )
                    })
                  : branchProductStock?.length > 0 &&
                    branchProductStock?.map((item, i) => {
                      return (
                        <PosProductCard
                          handleProductAdd={handleProductAdd}
                          cartItems={_.indexBy(cartItems, 'id')}
                          total={item?.total}
                          nameOption={item?.nameOption}
                          img={item?.img}
                          title={item?.name}
                          price={item?.price}
                          id={item?.id}
                          key={i}
                          item={item}
                          addCart={addCart}
                          mobile={mobile}
                        />
                      )
                    })
                : new Array(1).fill(null).map((i, index) => {
                    return (
                      <Center
                        key={index}
                        h={{ base: '60vh', sm: '60vh', md: '50vh', lg: '50vh' }}
                        flexDirection='column'
                        w='100%'
                        position='absolute'
                        left='0px'
                      >
                        <Center flexDirection='column' w='100%'>
                          <Text pb='0.5rem' fontSize='xl' color='loading.100'>
                            รอดำเนินการ
                          </Text>
                          <Box className='loading' color='loading.100'></Box>
                        </Center>
                      </Center>
                    )
                  })}
            </SimpleGrid>
          </Flex>
        </Flex>
      </Box>

      {cartItems?.length > 0 && (
        <Box
          borderTop='1px'
          bg='white'
          position='fixed'
          onClick={onOpen}
          right={5}
          left={0}
          bottom='0px'
          w={'100%'}
          h={'90px'}
          borderColor='#E8E9E9'
          px='8px'
          justifyContent='space-between'
          display={{ base: 'Flex', md: 'none', lg: 'none' }}
        >
          <Button
            colorScheme='btn'
            top='1.5rem'
            borderRadius='5px'
            px='16px'
            justifyContent='space-between'
            display={{ base: 'Flex', md: 'none', lg: 'none' }}
            color='#ffff'
            w={'100%'}
            h={'50px'}
          >
            <HStack spacing='15px' px='0rem'>
              {/* <Text>ตะกร้า</Text> */}
              {/* <Text color='white' ref={ref}>
                Test
              </Text> */}
              <CardAnimation shake={shake} />
              <Text>
                {currency(
                  parseFloat(Math.round(calItemCartTotalAmount(cartItems))),
                  0
                )}
              </Text>
              <Text>รายการ</Text>
            </HStack>
            <SimpleGrid>
              <Box>
                {calPromotionItems(cartItems, posUseDiscount)?.sum > 0
                  ? currency(
                      calPromotionItems(cartItems, posUseDiscount)?.sum,
                      2,
                      true
                    )
                  : 0}
              </Box>
            </SimpleGrid>
          </Button>
        </Box>
      )}
      <Drawer
        placement='bottom'
        display='flex'
        justifyContent='center'
        onClose={onClose}
        isOpen={isOpen}
      >
        <DrawerOverlay />

        <DrawerContent
          borderTopRadius='40px'
          w='100%'
          h={height(getMobileOperatingSystem())}
          fontFamily={'Prompt'}
        >
          <DrawerHeader
            h='3.5rem'
            zIndex='overlay'
            bg='pos.100'
            borderTopRadius='30px'
          >
            {paymentStep?.cart && (
              <Flex
                justifyContent='space-between'
                px='0px'
                h='auto'
                borderTopRadius='30px'
                borderBottomRadius='30px'
                pt='0px'
                border='0px'
              >
                {!paymentStep.selectCoupons && !paymentStep.successPayment && (
                  <Text
                    borderTopRadius={'30px'}
                    color='white'
                    fontSize={{ md: '22px', lg: '26px' }}
                    fontWeight='normal'
                    userSelect='none'
                    display='flex'
                  >
                    การสั่งซื้อ&#160;
                    {cartItems?.length > 0 && <Box>{cartItems?.length}</Box>}
                  </Text>
                )}
                <Button
                  w='2.5rem'
                  borderRadius='full'
                  colorScheme='btn'
                  onClick={onClose}
                  h='2.5rem'
                >
                  <Text color='white' fontSize={'xl'} position='absolute'>
                    <MdOutlineKeyboardArrowDown />
                  </Text>
                </Button>
              </Flex>
            )}
          </DrawerHeader>
          <DrawerBody px='0px' pt='0px' mt='0px'>
            {paymentStep.cart && <Cart />}
            {/* ตะกร้าสินค้า */}
            {!paymentStep.cart &&
              // !paymentStep.successPayment &&
              !paymentStep.selectBank && (
                <Box
                  w='100%'
                  h='7rem'
                  bg='pos.100'
                  color='white'
                  mt={{
                    base: '-2rem',
                    sm: '-2rem',
                    md: '-2rem',
                    lg: '0rem',
                    xl: '0rem',
                  }}
                >
                  {!paymentStep.successPayment && (
                    <Center
                      position={'absolute'}
                      h={{ base: '7rem', sm: '7rem', md: '7rem', lg: 'auto' }}
                      pl='1rem'
                      cursor={'pointer'}
                      onClick={() => {
                        backStep()
                      }}
                    >
                      <Text zIndex='overlay'>
                        <IoChevronBackOutline fontSize='28px' />
                      </Text>
                    </Center>
                  )}
                  <Center w='100%' h='7rem' flexDirection='column'>
                    {!paymentStep.selectCoupons &&
                      !paymentStep.successPayment && (
                        <>
                          <Text fontSize='30px'>
                            {currency(
                              calPromotionItems(cartItems, posUseDiscount)?.sum,
                              2,
                              true
                            )}
                          </Text>
                          <Text fontWeight='normal'>จำนวนเงินที่ต้องชำระ</Text>
                        </>
                      )}

                    {paymentStep.selectCoupons && (
                      <Text fontSize='20px'>โปรโมชั่นส่วนลด</Text>
                    )}
                    {paymentStep.successPayment && (
                      <Text fontSize='20px'>โกแมวต้มข้าว</Text>
                    )}
                  </Center>
                </Box>
              )}
            {/* เลือกช่องทางการชำระเงิน */}
            {paymentStep.selectPayment && <PosSelectPayment {...props} />}
            {/*คูปองส่วนลด*/}
            {paymentStep.selectCoupons && <PosCoupons {...props} />}
            {/* จ่ายเดี่ยว */}
            {paymentStep.cashReceived && <PosCashReceived {...props} />}
            {paymentStep.bankReceived && <PosBankReceived {...props} />}
            {paymentStep.bankSelect && <PosBankSelect {...props} />}
            {paymentStep.bankGetQR && <PosbankGetRQ {...props} />}
            {paymentStep.bankTakeSlip && <PosBankTakeSlip {...props} />}
            {paymentStep.laterPayment && <DateLaterPayment {...props} />}
            {paymentStep.successPayment && (
              <PosSuccessCashSinglePayment {...props} />
            )}
            {/* แยกจ่าย */}
            {paymentStep.multiplePayment &&
              paymentStep.multiplePaymentCount && (
                <PosMultiplePaymentCount {...props} />
              )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MobilePos

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return 'unknown'
}
