import { DeleteIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  Box,
  VStack,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react'
import { usePromotion } from 'Hooks/promotion'
import React, { useRef, useState } from 'react'
import { HiPencil } from 'react-icons/hi'
import { IoPricetagsOutline } from 'react-icons/io5'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { currency, decodeUnit } from 'Utils/currency'

function CardDiscount({ itm }) {
  const { deletePromotion } = usePromotion()
  const navigate = useNavigate()
  const [deleteItem, setDeleteItem] = useState()
  const textColor2 = useColorModeValue('secondaryGray.500', 'white')
  const iconColor = useColorModeValue('#494D51', 'white')
  const bgList = useColorModeValue('white', 'whiteAlpha.100')
  const bgShadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    'unset'
  )
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100')
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' }
  )
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' }
  )
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure()
  const cancelRef = useRef()

  function handleDelete() {
    return deletePromotion(deleteItem).then(() => {
      deleteOnClose()
    })
  }

  const deleteData = (id) => {
    setDeleteItem(id)
    deleteOnOpen()
  }

  function handleLink() {
    return navigate(`/discount/product/view/${itm.id}`)
  }

  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={deleteOnClose}
        isOpen={deleteIsOpen}
        isCentered
        size='auto'
      >
        <AlertDialogOverlay />

        <AlertDialogContent
          w={{ sm: '20.5rem', md: '25rem' }}
          fontFamily={'Prompt'}
        >
          <AlertDialogHeader>คุณแน่ใจ?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>หากคุณต้องการลบส่วนลดสินค้าหรือไม่</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={deleteOnClose}>
              ยกเลิก
            </Button>
            <Button
              colorScheme='red'
              ml={3}
              onClick={() => {
                handleDelete()
              }}
            >
              ยืนยัน
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Box
        w='100%'
        borderBottom='1px solid #E0E5F2'
        mt={{ sm: '0.5rem', md: '1rem' }}
        pb={{ sm: '0.5rem', md: '1rem' }}
        cursor='pointer'
        _hover={{
          bg: '#E0E5F2',
          borderRadius: '15px',
          transition: 'all 0.4s',
        }}
        transition='all 0.4s'
      >
        <Grid
          templateColumns={{ sm: 'repeat(3, 1fr)', md: 'repeat(4, 1fr)' }}
          gap={0}
          h={{ base: 'auto', sm: 'auto', md: 'auto', lg: '5rem' }}
        >
          <GridItem
            h='full'
            w={{ md: '12rem', lg: '13rem', xl: '15rem', '2xl': '20rem' }}
            onClick={() => handleLink()}
          >
            <HStack spacing='0.5rem' h='full'>
              <Center w='4rem' h='4rem' display={{ sm: 'none', md: 'flex' }}>
                <Center
                  w='4rem'
                  bg='discount.100'
                  color='white'
                  borderRadius='100%'
                  h='4rem'
                >
                  <Icon as={IoPricetagsOutline} w='2.5rem' h='2.5rem' />
                </Center>
              </Center>
              <Flex
                flexDirection='column'
                justifyContent='space-around'
                h='full'
                textAlign='left'
              >
                <Text fontWeight='700'>{itm.name}</Text>
              </Flex>
            </HStack>
          </GridItem>
          <GridItem
            h='full'
            w={{ md: '12rem', lg: '13rem', xl: '15rem', '2xl': '20rem' }}
            display={{ sm: 'none', md: 'GridItem' }}
            onClick={() => handleLink()}
          >
            <Center h='full' px='0.5rem'>
              <Text>{itm?.detail}</Text>
            </Center>
          </GridItem>
          <GridItem h='full' w='100%' onClick={() => handleLink()}>
            <VStack
              h='full'
              w='full'
              fontSize={{ sm: '14px', md: '16px' }}
              color='discount.500'
              fontWeight='500'
              textAlign='center'
              justifyContent='center'
            >
              <Text>
                {itm.discountType === 'Bath'
                  ? `${currency(decodeUnit(itm.discount), 2, true)}`
                  : `${currency(decodeUnit(itm.discount))}%`}
              </Text>
            </VStack>
          </GridItem>
          <GridItem h='full' w='100%'>
            <HStack
              h='full'
              justifyContent='end'
              spacing={{ sm: '0.5rem', md: '1.5rem' }}
              pr={{ sm: '0.2rem', md: '1rem' }}
            >
              <Box
                fontSize={{ sm: '14px', md: '16px' }}
                onClick={() => handleLink()}
                w='full'
                textAlign={'right'}
              >
                <Text color={itm.status === true ? 'discount.500' : '#A3AED0'}>
                  {itm.status === true ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}
                </Text>
              </Box>
              <Box>
                <Menu>
                  <MenuButton
                    as={Button}
                    align='center'
                    justifyContent='center'
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}
                    w='37px'
                    h='37px'
                    lineHeight='100%'
                    borderRadius='10px'
                    p='0px'
                  >
                    <Icon
                      as={MdOutlineMoreHoriz}
                      color={iconColor}
                      w='24px'
                      h='24px'
                    />
                  </MenuButton>
                  <MenuList
                    w='150px'
                    minW='unset'
                    maxW='150px !important'
                    border='transparent'
                    backdropFilter='blur(63px)'
                    bg={bgList}
                    boxShadow={bgShadow}
                    borderRadius='20px'
                    p='15px'
                  >
                    <MenuItem
                      transition='0.2s linear'
                      color={textColor2}
                      p='0px'
                      borderRadius='8px'
                      _active={{
                        bg: 'transparent',
                      }}
                      _focus={{
                        bg: 'transparent',
                      }}
                      mb='10px'
                      _hover={{
                        color: 'discount.500',
                      }}
                      cursor='pointer'
                      onClick={() =>
                        navigate(`/discount/product/edit/${itm.id}`)
                      }
                    >
                      <Flex align='center'>
                        <HStack>
                          <Icon as={HiPencil} h='16px' w='16px' me='8px' />
                          <Text fontSize='sm' fontWeight='400'>
                            แก้ไข
                          </Text>
                        </HStack>
                      </Flex>
                    </MenuItem>
                    <MenuItem
                      transition='0.2s linear'
                      color={textColor2}
                      p='0px'
                      borderRadius='8px'
                      _active={{
                        bg: 'transparent',
                      }}
                      _focus={{
                        bg: 'transparent',
                      }}
                      _hover={{
                        color: 'red',
                      }}
                      cursor='pointer'
                      onClick={() => deleteData(itm.id)}
                      // onClick={() => handleDelete(itm.id)}
                    >
                      <Flex align='center'>
                        <HStack>
                          <Icon as={DeleteIcon} h='16px' w='16px' me='8px' />
                          <Text fontSize='sm' fontWeight='400'>
                            ลบ
                          </Text>
                        </HStack>
                      </Flex>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </HStack>
          </GridItem>
        </Grid>
      </Box>
    </>
  )
}

export default CardDiscount
