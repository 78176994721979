import { ChevronLeftIcon } from '@chakra-ui/icons'
import {
  Center,
  Flex,
  HStack,
  Input,
  Switch,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import ButtonResponsive from 'Components/Button/btn'
import Card from 'Components/Horizons/card/Card'
import React from 'react'

function Second({ setSecond, discount, setDiscount, isSubmit }) {
  return (
    <>
      <Center>
        <Card
          padding={{
            base: '10px 0px 30px 0px',
            sm: '10px 0px 30px 0px',
            md: '10px 20px 30px 20px',
          }}
          w={{ base: '100%', sm: '100%', md: '30rem' }}
          boxShadow={{
            base: 'none',
            sm: 'none',
            md: '14px 17px 40px 4px rgb(112 144 176 / 8%)',
          }}
        >
          <HStack>
            <ChevronLeftIcon
              cursor='pointer'
              color='form.500'
              fontSize={'2xl'}
              onClick={() => setSecond(false)}
            />
            <Text
              fontSize={{ base: 'md', sm: 'md', md: 'lg' }}
              fontWeight='bold'
            >
              ตั้งชื่อโปรโมชั่นส่วนลด
            </Text>
          </HStack>
          <Center mt='1rem' w='100%'>
            <VStack spacing='25px' w='100%'>
              <VStack alignItems='flex-start' w='100%'>
                <Text>ชื่อโปรโมชั่น</Text>
                <Input
                  variant='main'
                  value={discount?.discountName || null}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    e.preventDefault()
                    let value = e.target.value
                    setDiscount({ ...discount, discountName: value })
                  }}
                />
              </VStack>
              <VStack alignItems='flex-start' w='100%'>
                <Text>รายละเอียดโปรโมชั่น</Text>
                <Textarea
                  border='1px solid #e0e5f2'
                  borderRadius={{
                    base: '10px',
                    sm: '10px',
                    md: '10px',
                    lg: '16px',
                  }}
                  variant={'no-effects'}
                  value={discount?.discountDetail || null}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    e.preventDefault()
                    let value = e.target.value
                    setDiscount({ ...discount, discountDetail: value })
                  }}
                />
              </VStack>
              <Flex justifyContent='space-between' w='100%'>
                <Text>เปิด-ปิด </Text>
                <Switch
                  defaultChecked={discount?.status || false}
                  colorScheme='teal'
                  onChange={(e) => {
                    let value = e.target.checked
                    setDiscount({ ...discount, status: value })
                  }}
                />
              </Flex>
            </VStack>
          </Center>
          <Flex
            mt={{ base: '0rem', sm: '0rem', md: '1.5rem' }}
            w='100%'
            justifyContent='flex-end'
          >
            <ButtonResponsive
              disabled={discount?.discountName ? false : true}
              label={'บันทึก'}
              type={'submit'}
              isLoading={isSubmit}
              loadingText={'กำลังบันทึก...'}
            />
          </Flex>
        </Card>
      </Center>
    </>
  )
}

export { Second }
