import { ChevronLeftIcon } from '@chakra-ui/icons'
import Card from 'Components/Horizons/card/Card'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Center, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import { useCategory, useProduct } from 'Hooks'
import InputField from 'Components/Horizons/fields/InputField'
import SelectField from 'Components/Horizons/fields/SelectField'
import { ProductOptions } from '../components/options'
import { decodeUnit, encodeUnit } from 'Utils/currency'

const _ = require('underscore')

export function FormOptionProduct({ data, skuId, refId }) {
  const [option, setOption] = useState([])
  const { category, getCategoryAll } = useCategory()
  const { createProductOption, updateProductOption, Product, getAllProducts } =
    useProduct()
  const [isSubmit, setIsSubmit] = useState(false)
  const Navigate = useNavigate()
  const [skuIdData, setskuIdData] = useState()

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    getCategoryAll()
    getAllProducts()
  }, [getCategoryAll, getAllProducts])

  useEffect(() => {
    if (data) {
      const fData = data[0]
      const findCategory = _.find(category, (i) => {
        return (
          fData[0]?.category === i?.category?.id ||
          fData[0]?.category === i?.category?.name
        )
      })
      setValue('name', fData?.name ? fData?.name : '')
      setValue('category', findCategory ? findCategory?.id : '')
      setValue('vat', fData?.vat ? fData?.vat : '')
      setOption(
        data
          ?.sort((a, b) => a.index - b.index)
          .map((item) => ({
            ...item,
            cost: decodeUnit(item.cost),
            price: decodeUnit(item.price),
            commission: decodeUnit(item.commission),
            minimum: decodeUnit(item.minimum),
          }))
      )
    }

    if (!skuId) {
      const resRef = _.groupBy(
        Product?.filter((item) => item.ref !== undefined),
        'ref'
      )
      const resNoRef = Product?.filter((item) => item.ref === undefined).map(
        (item) => item?.sku
      )
      const allProduct = [...resNoRef, ...Object.keys(resRef)]
      const refNumber = allProduct
        .map((item) => parseFloat(item))
        .sort((a, b) => a - b)
      const skuIdString = `${String(
        refNumber.length > 0 ? refNumber.slice(-1)[0] + 1 : 1
      ).padStart(4, '0')}`
      setskuIdData(skuIdString)

      //option
      if (option.length > 0) {
        let inCart = _.pluck(option, 'id')
        if (_.contains(inCart, option.index)) {
          setOption(
            option.map((item) => {
              if (item.id === option.index) {
                return { ...item, sku: `${skuIdString}-${item.index}` }
              } else {
                return { ...item }
              }
            })
          )
        }
      }
    } else {
      setskuIdData(skuId)
    }
    //eslint-disable-next-line
  }, [data, setValue, Product])

  function onSubmit(value) {
    setIsSubmit(true)
    const { name, category, vat } = value
    let data = []
    _.forEach(option, (item) => {
      return data.push({
        ...item,
        img: item.img ? item.img : null,
        ref: skuIdData,
        cost: encodeUnit(item.cost).toString(),
        price: encodeUnit(item.price).toString(),
        commission: encodeUnit(item.commission).toString(),
        minimum: encodeUnit(item.minimum).toString(),
        name,
        category,
        vat,
      })
    })
    if (refId) {
      updateProductOption(data).then(() => {
        setIsSubmit(false)
        Navigate('/product/list')
      })
    } else {
      createProductOption(data).then(() => {
        setIsSubmit(false)
        Navigate('/product/list')
      })
    }
  }
  return (
    <Flex direction='column' minH='100vh' align='center' position='relative'>
      <Box
        h={{ base: '0px', sm: '0px', md: '25vh' }}
        bg='product.700'
        position='absolute'
        w={{ sm: '100vw', md: '100%', lg: '100%' }}
        borderRadius={{ sm: '0px', md: '30px', lg: '30px' }}
      ></Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          mt={{ base: '-30px', sm: '-30px', md: '24px', lg: '24px' }}
          maxW={{ md: '90%', lg: '100%' }}
          mx={{ base: 'auto', md: '0px', lg: 'auto' }}
        >
          <Box
            w={{ sm: '100vw', md: '670px', lg: '850px' }}
            p='0px'
            mx={{ base: 'auto', md: '0px', lg: 'auto' }}
          >
            <Card
              mt={{ base: '-40px', sm: '-40px', md: '0px', lg: '0px' }}
              p={{ base: '15px', sm: '15px', md: '30px' }}
              px={{ base: '30px', sm: '30px', md: '30px' }}
              pb={{ base: '40px', sm: '40px', md: '30px' }}
            >
              <Box
                pb='0.3rem'
                zIndex='base'
                color='product.500'
                onClick={() => Navigate(-1)}
                w='max-content'
                display={{ sm: 'flex', md: 'none' }}
              >
                <Text
                  display={{ sm: 'flex', md: 'none' }}
                  fontSize='24px'
                  cursor='pointer'
                >
                  <ChevronLeftIcon />
                </Text>
              </Box>
              <Text
                textAlign={{
                  base: 'center',
                  sm: 'center',
                  md: 'left',
                  lg: 'left',
                }}
                color='#1B2559'
                fontSize='2xl'
                fontWeight='700'
                mb='20px'
              >
                ข้อมูลสินค้า
              </Text>
              <Flex direction='column' w='100%'>
                <Box>
                  <SimpleGrid
                    pt={'1em'}
                    columns={{ base: '1', md: '2' }}
                    gap='20px'
                    color='#1B2559'
                    alignItems='end'
                  >
                    <InputField
                      control={control}
                      errors={errors}
                      mb='0px'
                      id='name'
                      label='ชื่อสินค้า'
                      name='name'
                      type='text'
                      defaultValue={''}
                      required={{
                        value: true,
                        message: 'กรุณากรอกชื่อสินค้า',
                      }}
                    />

                    <SelectField
                      control={control}
                      errors={errors}
                      label={'หมวดหมู่สินค้า'}
                      name={'category'}
                      id={'category'}
                      mb={'0px'}
                      borderRadius={{
                        base: '10px',
                        sm: '10px',
                        md: '10px',
                        lg: '16px',
                      }}
                      placeholder={'เลือกหมวดหมู่'}
                      path={'/product/category/add'}
                      options={
                        category?.length > 0
                          ? category?.map((item) => ({
                              id: item?.id,
                              name: item?.name,
                            }))
                          : []
                      }
                      required={{
                        value: true,
                        message: 'กรุณาเลือกหมวดหมู่สินค้า',
                      }}
                    />
                    <SelectField
                      control={control}
                      errors={errors}
                      label={'ภาษีมูลค่าเพิ่ม'}
                      name={'vat'}
                      id={'vat'}
                      borderRadius={{
                        base: '10px',
                        sm: '10px',
                        md: '10px',
                        lg: '16px',
                      }}
                      mb={'0px'}
                      defaultValue={'VAT 0%'}
                      placeholder={'เลือกภาษี'}
                      options={[
                        'ยังไม่รวมภาษี',
                        'ราคารวม VAT แล้ว',
                        'VAT 0%',
                        'สินค้าได้รับการยกเว้นภาษี',
                        'ไม่มีภาษีมูลค่าเพิ่ม',
                      ]}
                      required={{
                        value: true,
                        message: 'กรุณาเลือกภาษี',
                      }}
                    />
                  </SimpleGrid>
                </Box>
                <Box mt='1em'>
                  {option?.map((item, index) => (
                    <ProductOptions
                      data={item}
                      setOption={setOption}
                      i={index}
                      option={option}
                      key={index}
                    />
                  ))}
                  <Center>
                    <Button
                      border='1px'
                      borderColor='product.100'
                      fontWeight='normal'
                      onClick={() =>
                        setOption((prev) => {
                          return [
                            ...prev,
                            {
                              img: '',
                              index: option.length + 1,
                              sku: `${skuIdData}-${option.length + 1}`,
                              cost: null,
                              price: null,
                              commission: null,
                              typeCommission: 'Bath',
                              minimum: null,
                              unit: 'piece',
                              commissionCalType: 'receipt',
                            },
                          ]
                        })
                      }
                    >
                      เพิ่มตัวเลือกสินค้า
                    </Button>
                  </Center>
                </Box>
                <Flex justify='space-between' mt='30px'>
                  <Button
                    variant='light'
                    fontSize='sm'
                    borderRadius='16px'
                    w={{ base: '128px', md: '148px' }}
                    h='46px'
                    onClick={() => Navigate(-1)}
                  >
                    ย้อนกลับ
                  </Button>
                  <Button
                    type='submit'
                    colorScheme='btn'
                    fontSize='sm'
                    borderRadius='16px'
                    w={{ base: '128px', md: '148px' }}
                    h='46px'
                    ms='auto'
                    isLoading={isSubmit}
                    variant='solid'
                    loadingText={'Loading...'}
                  >
                    บันทึก
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </Box>
        </Box>
      </form>
    </Flex>
  )
}
