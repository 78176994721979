import { calDiscountPaidRefund } from './discount'
import { calSellingPriceOrder } from './sellingPrice'

const _ = require('underscore')

export function calNetSales(order) {
  const totalSell = calSellingPriceOrder(order).total
  const totalDiscount = calDiscountPaidRefund(order).total

  return {
    total: totalSell - totalDiscount,
  }
}

export function calNetSalesPaidRefund(order) {
  const paid = _.filter(order, (f) => f.status === 'paid')
  const totalSellPaid = calSellingPriceOrder(paid).total
  const totalDiscountPaid = calDiscountPaidRefund(paid).total || 0

  const refund = _.filter(order, (f) => f.status === 'refund')
  const totalSellRefund = calSellingPriceOrder(refund).total
  const totalDiscountRefund = calDiscountPaidRefund(refund).total || 0

  const totalSell = totalSellPaid - totalSellRefund
  const totalDiscount = totalDiscountPaid - totalDiscountRefund

  const total = parseFloat((totalSell - totalDiscount).toFixed(2))

  return total
}
