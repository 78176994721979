/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'
import {
  Box,
  Button,
  Center,
  HStack,
  Stack,
  Spinner,
  Fade,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { FiCheckCircle } from 'react-icons/fi'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { ChevronDownIcon } from '@chakra-ui/icons'
import Card from 'Components/Horizons/card/Card'
import { usePurchaseOrder, useBranch } from 'Hooks'
import CustomTable from 'Components/Horizons/table/customTable'
import { useAppContext } from 'Context'

const Status = {
  pending: 'รอดำเนินการ',
  processing: 'ดำเนินการ',
  completed: 'เสร็จสิ้น',
  rejected: 'ถูกยกเลิก',
}

const PurchaseOrderDetail = ({ user }) => {
  const { setMessage, setError } = useAppContext()
  const { id } = useParams()
  const navigate = useNavigate()
  const {
    PurchaseOrderById,
    getPurchaseOrderById,
    updatePurchaseOrder,
    isLoading,
  } = usePurchaseOrder()
  const [isFadePage, setIsFadePage] = useState(true)
  const { Branch, getBranchData } = useBranch()
  const [note, setNote] = useState('')
  const [tableData, setTableData] = useState([])
  const [isSubmit, setIsSubmit] = useState(false)
  const [reason, setReason] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  useEffect(() => {
    if (id) {
      setIsFadePage(false)
      getPurchaseOrderById(id)
      getBranchData()
    }
  }, [id])

  useEffect(() => {
    if (PurchaseOrderById) {
      setNote(PurchaseOrderById?.note)
      setTableData(
        PurchaseOrderById?.products.map((p, i) => ({ ...p, no: i + 1 })) || []
      )
    }
  }, [PurchaseOrderById])

  const isAllRejected = tableData.every((data) => data.status === 'rejected')

  const isAllcompleted = tableData.every((data) => data.status === 'completed')

  function handleChangeSending(branch, no) {
    const newTableData = tableData.map((data) => {
      if (data.no === no) {
        return {
          ...data,
          sendingFromBranch: {
            id: branch.id,
            name: branch.name,
          },
          status: 'processing',
          rejectedNote: '',
        }
      }
      return data
    })

    setTableData(newTableData)
  }

  function handleCancelProduct(no) {
    const newTableData = tableData.map((data) => {
      if (data.no === no) {
        delete data.sendingFromBranch
        return { ...data, status: 'rejected' }
      }
      return data
    })

    setTableData(newTableData)
  }

  function onSubmit() {
    setIsSubmit(true)

    const submitData = {
      ...PurchaseOrderById,
      products: tableData,
      orderStatus: isAllcompleted
        ? 'completed'
        : isAllRejected
        ? 'rejected'
        : 'processing',
      updatedAt: new Date(),
      updatedBy: { id: user?.uid, name: user?.name },
      note: note,
    }

    updatePurchaseOrder(id, submitData)
      .then(() => {
        navigate('/purchase-order/manage')
        setMessage({
          type: 'success',
          message: 'แก้ไขรายการสำเร็จ',
        })
        setIsSubmit(false)
      })
      .catch((e) => {
        setError({
          code: 'Update Purchase Order',
          message: e?.message,
        })
        setIsSubmit(false)
      })
  }

  function onCancelAll() {
    const data = tableData.map((item) => ({
      ...item,
      status: 'rejected',
    }))

    setTableData(data)
  }

  const columnsDataOrders = [
    {
      Header: 'No.',
      accessor: 'no',
    },
    {
      Header: 'สินค้า',
      accessor: 'name',
      extra: (name, row) => {
        return (
          <Center fontSize={14}>{`${name} ${row?.nameOption || ''}`}</Center>
        )
      },
    },
    {
      Header: 'จำนวน/ปริมาณ',
      accessor: 'amount',
    },
    {
      Header: 'หน่วย',
      accessor: 'unit',
      extra: (unit, row) => {
        return (
          <Center fontSize={14}>
            {unit === 'piece' ? 'ชิ้น' : unit === 'weight' && 'กรัม'}
          </Center>
        )
      },
    },

    {
      Header: 'ส่งจาก',
      accessor: 'status',
      extra: (status, row) => {
        return (
          <>
            {PurchaseOrderById.orderStatus === 'processing' ||
            PurchaseOrderById.orderStatus === 'pending' ? (
              <Menu
                size='md'
                fontSize={14}
                mr={2}
                boundary='scrollParent'
                preventOverflow='true'
              >
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  isDisabled={status === 'rejected' || status === 'completed'}
                >
                  <Center fontSize={14}>
                    {row?.sendingFromBranch
                      ? row?.sendingFromBranch?.name
                      : Status[status]}
                  </Center>
                </MenuButton>
                <MenuList>
                  {Branch?.filter(
                    (branch) => branch.id !== PurchaseOrderById?.branch?.id
                  ).map((branch) => (
                    <MenuItem
                      key={branch.id}
                      onClick={() => handleChangeSending(branch, row?.no)}
                    >
                      {branch.name}
                    </MenuItem>
                  ))}
                  <MenuItem onClick={() => handleCancelProduct(row.no)}>
                    ยกเลิกรายการ
                  </MenuItem>
                </MenuList>
              </Menu>
            ) : PurchaseOrderById.orderStatus === 'completed' ||
              PurchaseOrderById.orderStatus === 'rejected' ? (
              <HStack mr={2}>
                {status === 'completed' ? (
                  <FiCheckCircle fontSize={20} />
                ) : status === 'rejected' ? (
                  <AiOutlineCloseCircle fontSize={20} />
                ) : null}
              </HStack>
            ) : null}
            {row?.rejectedNote && row?.rejectedNote !== '' && (
              <Button
                variant='link'
                onClick={() => {
                  setReason(row?.rejectedNote)
                  onOpen()
                }}
              >
                หมายเหตุ
              </Button>
            )}
          </>
        )
      },
    },
  ]

  return (
    <>
      <Stack as={Fade} in={!isFadePage} mixH='100vh'>
        {!isLoading ? (
          <>
            <AlertDialog
              motionPreset='slideInBottom'
              leastDestructiveRef={cancelRef}
              onClose={() => {
                onClose()
                setReason('')
              }}
              isOpen={isOpen}
              isCentered
              size='auto'
            >
              <AlertDialogOverlay />
              <AlertDialogContent
                w={{ sm: '20.5rem', md: '25rem' }}
                fontFamily={'Prompt'}
              >
                <AlertDialogHeader>เหตุผลที่ยกเลิก</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                  <Text fontSize={16}>{reason}</Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={cancelRef}
                    onClick={() => {
                      onClose()
                      setReason('')
                    }}
                  >
                    ปิด
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
            <Card>
              <Box zIndex='base'>
                <Text
                  textAlign={{
                    base: 'center',
                    sm: 'center',
                    md: 'left',
                    lg: 'left',
                  }}
                  color='#1B2559'
                  fontSize='2xl'
                  fontWeight='700'
                >
                  {`เลขที่ใบสั่งสินค้า ${PurchaseOrderById?.id}`}
                </Text>
                <Stack
                  my='1em'
                  spacing={{ base: '0px', sm: '0px', md: '10px' }}
                  justifyContent='space-between'
                  flexDirection={{
                    base: 'column',
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                  }}
                >
                  <SimpleGrid
                    columns={[2, 2, 2, 4]}
                    w='full'
                    gap={2}
                    alignItems='flex-end'
                  >
                    <HStack>
                      <Text fontWeight={800}>ผู้สั่ง</Text>
                      <Text>{PurchaseOrderById?.createdBy.name}</Text>
                    </HStack>
                    <HStack>
                      <Text fontWeight={800}>วันที่สั่ง</Text>
                      <Text>
                        {PurchaseOrderById?.createdAt
                          .toDate()
                          .toLocaleDateString()}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontWeight={800}>วันที่อัปเดท</Text>
                      <Text>
                        {PurchaseOrderById?.updatedAt
                          ? PurchaseOrderById?.updatedAt
                              .toDate()
                              .toLocaleDateString()
                          : PurchaseOrderById?.createdAt
                              .toDate()
                              .toLocaleDateString()}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontWeight={800}>สถานะ</Text>
                      <Text>{Status[PurchaseOrderById?.orderStatus]}</Text>
                    </HStack>
                  </SimpleGrid>
                  {PurchaseOrderById?.orderStatus !== 'completed' &&
                    PurchaseOrderById?.orderStatus !== 'rejected' && (
                      <HStack
                        flexDirection='row'
                        alignItems='center'
                        pt={{ sm: '1rem', md: '0rem' }}
                      >
                        <Button
                          h='44px'
                          maxh='44px'
                          w={{ sm: 'full', md: 'full' }}
                          borderRadius='16px'
                          display='flex'
                          colorScheme='blackAlpha'
                          justifyContent={'space-around'}
                          onClick={() => onCancelAll()}
                        >
                          ยกเลิกทั้งหมด
                        </Button>
                      </HStack>
                    )}
                </Stack>
              </Box>
              <CustomTable
                columnsData={columnsDataOrders}
                tableData={tableData}
                disabledSearch
              />
              {PurchaseOrderById?.orderStatus !== 'completed' &&
                PurchaseOrderById?.orderStatus !== 'rejected' && (
                  <FormControl mt={6} w={{ lg: '50%', xl: '30%' }}>
                    <FormLabel>หมายเหตุ</FormLabel>
                    <Textarea
                      id='note'
                      name='note'
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      border='1px solid'
                      borderColor='secondaryGray.100'
                      borderRadius='16px'
                      _focus={{
                        borderColor: 'secondaryGray.100',
                        boxShadow: 'none',
                      }}
                    />
                  </FormControl>
                )}
              <HStack mt={10} justifyContent='space-between'>
                <Button
                  borderRadius='16px'
                  colorScheme='blackAlpha'
                  variant='outline'
                  onClick={() => navigate(-1)}
                >
                  ย้อนกลับ
                </Button>
                {PurchaseOrderById?.orderStatus !== 'completed' &&
                  PurchaseOrderById?.orderStatus !== 'rejected' && (
                    <Button
                      borderRadius='16px'
                      colorScheme='btn'
                      isLoading={isSubmit}
                      loadingText='กำลังบันทึก...'
                      onClick={() => onSubmit()}
                    >
                      บันทึก
                    </Button>
                  )}
              </HStack>
            </Card>
          </>
        ) : (
          <Card>
            <Center>
              <Spinner size='lg' />
            </Center>
          </Card>
        )}
      </Stack>
    </>
  )
}

export default PurchaseOrderDetail
