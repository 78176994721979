import React, { useEffect } from 'react'
import { useState } from 'react'
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import { ChevronLeftIcon, SearchIcon } from '@chakra-ui/icons'
import ButtonResponsive from 'Components/Button/btn'
import FinalCategoryList from './category/final'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'
import { db } from 'Config/firebase'
import { search } from 'ss-search'

function InfoModal({ setDiscount, discount }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [onSearch, setOnSearch] = useState('')
  const [category, setCategory] = useState([])
  // const [data, setData] = useState(null)
  useEffect(() => {
    const qProduct = query(
      collection(db, 'Category'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(qProduct, (snapShot) => {
      let productValue = []
      snapShot.docs.forEach((doc) => {
        return productValue.push({
          ...doc.data(),
          id: doc.id,
        })
      })
      const searchText = onSearch
      const searchKey = ['name']

      const results = search(productValue, searchKey, searchText)
      setCategory(results)
    })
    return () => {
      unsubscribe()
    }
  }, [onSearch])

  const handleChange = (value) => {
    const dataValue = category.find((item) => item.id === value)
    // setData(dataValue)
    setDiscount({
      ...discount,
      categoryFree: dataValue,
      freeCategoryAmount: 1,
    })
    onClose()
  }
  return (
    <>
      <Text
        color='form.500'
        onClick={() => {
          setOnSearch('')
          onOpen()
        }}
        cursor='pointer'
        _hover={{ textDecoration: 'underline' }}
      >
        {discount?.categoryFree ? `เปลี่ยน` : `+ เลือกรายการหมวดหมู่`}
      </Text>
      <Modal isOpen={isOpen} onClose={onClose} size='auto'>
        <ModalOverlay />
        <ModalContent w={{ base: '22rem', sm: '22rem', md: '30rem' }}>
          <ModalHeader color='form.500'>
            เลือกหมวดหมู่สินค้าจัดโปรโมชั่น
            <Box w='100%'>
              <Flex
                spacing='1rem'
                mt='20px'
                direction={{
                  base: 'column',
                  sm: 'column',
                  md: 'column',
                  lg: 'row',
                }}
                align={{ base: 'start', md: 'center' }}
                justifyContent='space-between'
                w='100%'
                zIndex='base'
              >
                <Flex w='100%'>
                  <InputGroup w='100%'>
                    <InputLeftElement
                      children={
                        <IconButton
                          bg='inherit'
                          borderRadius='inherit'
                          _hover='none'
                          _active={{
                            bg: 'inherit',
                            transform: 'none',
                            borderColor: 'transparent',
                          }}
                          _focus={{
                            boxShadow: 'none',
                          }}
                          icon={
                            <SearchIcon color={'gray.700'} w='15px' h='15px' />
                          }
                        ></IconButton>
                      }
                    />
                    <Input
                      variant='search'
                      fontSize='sm'
                      bg={'secondaryGray.300'}
                      color={'gray.700'}
                      fontWeight='500'
                      _placeholder={{
                        color: 'gray.400',
                        fontSize: '14px',
                        opacity: 1,
                      }}
                      borderRadius={'30px'}
                      placeholder={'Search...'}
                      onChange={(e) => {
                        setOnSearch(e.target.value)
                      }}
                    />
                  </InputGroup>
                </Flex>
              </Flex>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            overflowY='auto'
            maxH={{ base: '25rem', sm: '25rem', md: '35rem' }}
          >
            <Box h={{ base: '25rem', sm: '25rem', md: '35rem' }}>
              <RadioGroup
                value={discount?.categoryFree?.id}
                onChange={handleChange}
              >
                <VStack alignItems='flex-start' w='100%' direction='row'>
                  {category.map((item, index) => (
                    <Flex w='100%' borderBottom='1px' borderColor='#E8E9E9'>
                      <Radio
                        colorScheme='green'
                        value={item.id}
                        key={index}
                        w='100%'
                        h='5rem'
                      >
                        {item.name}
                      </Radio>
                    </Flex>
                  ))}
                </VStack>
              </RadioGroup>
            </Box>
          </ModalBody>

          <ModalFooter w='100%'></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function DiscountFreeAmount({ setDiscount, discount }) {
  return (
    <HStack spacing='20px'>
      <Button
        border='1px'
        borderColor='#E0E5F2'
        w='2.5rem'
        borderRadius='100%'
        disabled={discount.categoryFree ? false : true}
        onClick={() => {
          setDiscount({
            ...discount,
            freeCategoryAmount:
              discount?.freeCategoryAmount < 0
                ? 0
                : discount?.freeCategoryAmount - 1,
          })
        }}
      >
        <Text fontSize='26px' color='#767262'>
          -
        </Text>
      </Button>
      <Text fontSize='20px'>
        {discount?.freeCategoryAmount ? discount?.freeCategoryAmount : 0}
      </Text>
      <Button
        border='1px'
        borderColor='#E0E5F2'
        w='2.5rem'
        borderRadius='100%'
        disabled={discount.categoryFree ? false : true}
        onClick={() => {
          setDiscount({
            ...discount,
            freeCategoryAmount: discount?.freeCategoryAmount + 1,
          })
        }}
      >
        <Text fontSize='26px' color='#767262'>
          +
        </Text>
      </Button>
    </HStack>
  )
}

export default function CategoryListFree({
  setCategory,
  discount,
  watch,
  setDiscount,
  control,
  errors,
  isSubmit,
}) {
  const [final, setFinal] = useState(false)
  return (
    <>
      {' '}
      <Box display={final ? 'none' : 'block'}>
        <Center>
          <Card
            padding={{
              base: '10px 0px 30px 0px',
              sm: '10px 0px 30px 0px',
              md: '10px 20px 30px 20px',
            }}
            w={{ base: '100%', sm: '100%', md: '30rem' }}
            boxShadow={{
              base: 'none',
              sm: 'none',
              md: '14px 17px 40px 4px rgb(112 144 176 / 8%)',
            }}
          >
            <HStack>
              <ChevronLeftIcon
                cursor='pointer'
                color='form.500'
                fontSize={'2xl'}
                onClick={() => {
                  setCategory(false)
                }}
              />
              <Text
                fontSize={{ base: 'md', sm: 'md', md: 'lg' }}
                fontWeight='bold'
              >
                เงื่อนไข
              </Text>
            </HStack>

            <Center
              display={
                discount?.categoryFree === undefined || null ? 'block' : 'none'
              }
            >
              <Grid
                borderBottom='1px'
                borderColor='#E8E9E9'
                py='2em'
                w='full'
                templateColumns='repeat(2, 1fr)'
                gap={4}
              >
                <GridItem>
                  <Center>
                    <Text>รายการสินค้าที่เลือก</Text>
                  </Center>
                </GridItem>
                <GridItem>
                  <Center>
                    <InfoModal setDiscount={setDiscount} discount={discount} />
                  </Center>
                </GridItem>
              </Grid>
            </Center>

            <Center
              display={
                discount?.categoryFree !== undefined || null ? 'block' : 'none'
              }
              mt='1rem'
            >
              <HStack>
                <Text>รายการสินค้าที่เลือก</Text>
              </HStack>
              <Grid
                borderBottom='1px'
                borderColor='#E8E9E9'
                py='2em'
                w='full'
                templateColumns='repeat(2, 1fr)'
                gap={4}
              >
                <GridItem>
                  <Center>
                    <Text>{discount?.categoryFree?.name}</Text>
                  </Center>
                </GridItem>
                <GridItem>
                  <Center>
                    <InfoModal setDiscount={setDiscount} discount={discount} />
                  </Center>
                </GridItem>
              </Grid>
            </Center>

            <Center>
              <Grid
                borderBottom='1px'
                borderColor='#E8E9E9'
                py='2em'
                w='full'
                templateColumns='repeat(2, 1fr)'
                gap={4}
              >
                <GridItem>
                  <Center h='full'>ถ้าซื้อสินค้า</Center>
                </GridItem>
                <GridItem>
                  <Center>
                    <DiscountFreeAmount
                      discount={discount}
                      setDiscount={setDiscount}
                    />
                  </Center>
                </GridItem>
              </Grid>
            </Center>
            <Flex
              w='100%'
              mt={{ base: '0rem', sm: '0rem', md: '1.5rem' }}
              justifyContent='flex-end'
            >
              <ButtonResponsive
                label={'ถัดไป'}
                disabled={
                  discount.categoryFree && discount?.freeCategoryAmount > 0
                    ? false
                    : true
                }
                onClick={() => {
                  setFinal(true)
                  setDiscount({
                    ...discount,
                    valueDiscountType: null,
                    status: true,
                  })
                }}
              />
            </Flex>
          </Card>
        </Center>
      </Box>
      {final && (
        <FinalCategoryList
          watch={watch}
          setFinal={setFinal}
          setDiscount={setDiscount}
          discount={discount}
          control={control}
          errors={errors}
          isSubmit={isSubmit}
        />
      )}
    </>
  )
}
