import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import InputField from 'Components/Horizons/fields/InputField'
import RadioField from 'Components/Horizons/fields/RadioField'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { decodeUnit } from 'Utils/currency'

const _ = require('underscore')

function ProductInfo({
  data,
  info,
  setInfo,
  control,
  errors,
  option,
  setValue,
}) {
  const [cal, setCal] = useState()
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  useEffect(() => {
    if (data) {
      setCal(decodeUnit(data?.commission))
    }
  }, [data])
  return (
    <SimpleGrid
      color='#1B2559'
      pt={'1em'}
      columns={{ base: '1', md: '2' }}
      gap='20px'
    >
      <FormControl id={'cost'} isInvalid={errors['cost']} key={'cost'}>
        <Flex direction='column' mb={'0px'}>
          <FormLabel
            display='flex'
            ms='10px'
            fontSize='sm'
            color={textColorPrimary}
            fontWeight='bold'
            _hover={{ cursor: 'pointer' }}
          >
            ราคาต้นทุน
          </FormLabel>
          <Controller
            name={'cost'}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'กรุณากรอกราคาต้นทุน',
              },
              pattern: {
                value: /[0-9]/,
                message: 'กรุณากรอกตัวเลขเท่านั้น',
              },
            }}
            render={({ field: { value } }) => (
              <Input
                value={value}
                min={0}
                step={'any'}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  let value = _.isNaN(e.target.value)
                    ? 0
                    : parseFloat(parseFloat(e.target.value)?.toFixed(2))
                  setValue('cost', value)
                }}
                type={'number'}
                id={'cost'}
                borderRadius={{
                  base: '10px',
                  sm: '10px',
                  md: '10px',
                  lg: '16px',
                }}
                defaultValue=''
                fontWeight='500'
                variant='main'
                _placeholder={{
                  fontWeight: '400',
                  color: 'secondaryGray.600',
                }}
                h='44px'
                maxh='44px'
              />
            )}
          />
        </Flex>
        <FormErrorMessage>
          {errors['cost'] && errors['cost']?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl id={'price'} isInvalid={errors['price']} key={'price'}>
        <Flex direction='column' mb={'0px'}>
          <FormLabel
            display='flex'
            ms='10px'
            fontSize='sm'
            color={textColorPrimary}
            fontWeight='bold'
            _hover={{ cursor: 'pointer' }}
          >
            ราคาขาย
          </FormLabel>
          <Controller
            name={'price'}
            control={control}
            rules={{
              required: {
                value: option?.length < 1 ? true : false,
                message: 'กรุณากรอกราคาขาย',
              },
              pattern: {
                value: /[0-9]/,
                message: 'กรุณากรอกตัวเลขเท่านั้น',
              },
            }}
            render={({ field: { value } }) => (
              <Input
                value={value}
                min={0}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  let value = _.isNaN(e.target.value)
                    ? 0
                    : parseFloat(parseFloat(e.target.value)?.toFixed(2))
                  setValue('price', value)
                  setInfo({
                    ...info,
                    price: value,
                  })
                }}
                type={'number'}
                id={'price'}
                borderRadius={{
                  base: '10px',
                  sm: '10px',
                  md: '10px',
                  lg: '16px',
                }}
                defaultValue=''
                fontWeight='500'
                variant='main'
                _placeholder={{
                  fontWeight: '400',
                  color: 'secondaryGray.600',
                }}
                h='44px'
                maxh='44px'
              />
            )}
          />
        </Flex>
        <FormErrorMessage>
          {errors['price'] && errors['price']?.message}
        </FormErrorMessage>
      </FormControl>
      <VStack alignItems='start'>
        <FormLabel
          display='flex'
          ms='10px'
          mb='0px'
          fontSize='sm'
          color={textColorPrimary}
          fontWeight='bold'
          _hover={{ cursor: 'pointer' }}
        >
          ค่าคอมมิชชั่นในการขาย
        </FormLabel>
        <HStack alignItems='end' w='full'>
          <FormControl>
            <InputGroup>
              <Input
                value={cal}
                type='number'
                step='any'
                borderRadius={{
                  base: '10px',
                  sm: '10px',
                  md: '10px',
                  lg: '16px',
                }}
                defaultValue=''
                fontWeight='500'
                variant='main'
                _placeholder={{
                  fontWeight: '400',
                  color: 'secondaryGray.600',
                }}
                h='44px'
                maxh='44px'
                min={0}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  let value = _.isNaN(e.target.value)
                    ? 0
                    : parseFloat(parseFloat(e.target.value)?.toFixed(2))

                  setCal(parseFloat(value))
                  setValue(
                    'commission',
                    parseFloat(parseFloat(parseFloat(value))?.toFixed(2)) || 0
                  )
                }}
              />
              <InputRightElement width='4.5rem' zIndex='base'>
                <Button
                  colorScheme='btn'
                  h='1.75rem'
                  fontWeight='normal'
                  size='sm'
                  onClick={() => {
                    if (info.typeCommission === 'Bath') {
                      setInfo({
                        ...info,
                        typeCommission: 'Percentage',
                      })
                      setValue('typeCommission', 'Percentage')
                    } else if (info.typeCommission === 'Percentage') {
                      setInfo({
                        ...info,
                        typeCommission: 'Bath',
                      })
                      setValue('typeCommission', 'Bath')
                    }
                  }}
                >
                  {info.typeCommission === 'Bath' ? (
                    <Text>&#3647;</Text>
                  ) : (
                    <Text>%</Text>
                  )}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl
            display={info.typeCommission === 'Percentage' ? 'flex' : 'none'}
          >
            <HStack alignItems='center'>
              <Input
                value={(info.price * (cal / 100)).toFixed(2)}
                borderRadius={{
                  base: '10px',
                  sm: '10px',
                  md: '10px',
                  lg: '16px',
                }}
                defaultValue=''
                fontWeight='500'
                variant='main'
                _placeholder={{
                  fontWeight: '400',
                  color: 'secondaryGray.600',
                }}
                readOnly
                h='44px'
                maxh='44px'
                min={0}
              />
              <Text fontWeight='bold'>&#3647;</Text>
            </HStack>
          </FormControl>
        </HStack>
        <FormControl isInvalid={!!errors.commission}>
          <FormErrorMessage>
            {errors?.commission && errors?.commission?.message}
          </FormErrorMessage>
        </FormControl>
      </VStack>
      <InputField
        control={control}
        errors={errors}
        label={'แจ้งเตือนสินค้าใกล้หมด'}
        name={'minimum'}
        id={'minimum'}
        type='text'
        mb={'0px'}
        defaultValue={''}
        min={0}
        required={{
          value: option?.length < 1 ? true : false,
          message: 'กรุณากรอกแจ้งเตือนสินค้าใกล้หมด',
        }}
        pattern={{
          value: /[0-9]/,
          message: 'กรุณากรอกตัวเลขเท่านั้น',
        }}
      />

      <RadioField
        control={control}
        errors={errors}
        label={'สถานะสินค้า'}
        name='statusPo'
        id='statusPo'
        mb='0px'
        required={{
          value: true,
          message: 'กรุณาเลือกสถานะสินค้า',
        }}
        options={[
          { name: 'วางขาย', value: 'backOrder' },
          { name: 'ยกเลิกการขาย', value: 'discontinues' },
        ]}
      />

      <RadioField
        control={control}
        errors={errors}
        label={'ประเภทคอมมิชชั่น'}
        name='commissionCalType'
        id='commissionCalType'
        mb='0px'
        required={{
          value: true,
          message: 'กรุณาเลือกประเภทคอมมิชชั่น',
        }}
        options={[
          { name: 'ต่อชิ้น', value: 'piece' },
          { name: 'ยอดขาย', value: 'receipt' },
        ]}
      />
    </SimpleGrid>
  )
}

export default ProductInfo
