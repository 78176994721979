import {
  Box,
  Button,
  Center,
  HStack,
  Input,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react'
import Card from 'Components/Horizons/card/Card'
import { useEffect, useRef } from 'react'
import { BiTimeFive } from 'react-icons/bi'
import { MdOutlineDateRange } from 'react-icons/md'
import { GoLocation } from 'react-icons/go'
import { useState } from 'react'
import { TimeInWorking } from './components/timeIn'
import TimeOutWorking from './components/TimeOutWorking'
import { useAppContext } from 'Context'
import moment from 'moment'
import { useBranch } from 'Hooks'
import _ from 'underscore'
import { useWork } from 'Hooks/work'
import { useNavigate } from 'react-router-dom'
import Resizer from 'react-image-file-resizer'
import { BsCheck } from 'react-icons/bs'
import { TbCapture } from 'react-icons/tb'

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      'base64'
    )
  })

function WorkingHours() {
  const { user } = useAppContext()
  const { getBranchData, Branch } = useBranch()
  const { userOffWork, userOnWork } = useWork()
  const [timeIn, setTimeIn] = useState(false)
  const [fileLogout, setFileLogout] = useState(null)
  const [timeOut, setTimeOut] = useState(false)
  const [working, setWorking] = useState(true)
  const [timeNow, setTimeNow] = useState(Date.now())
  const [branchSelect, setBranchSelect] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [workTimeLoading, setWorkTimeLoading] = useState({
    in: false,
    off: false,
  })
  const [file, setFile] = useState(null)
  const navigate = useNavigate()
  const hiddenFileInput = useRef(null)
  const hiddenFileInputLogout = useRef(null)

  useEffect(() => {
    getBranchData()
  }, [getBranchData])

  useEffect(() => {
    const filterBranch = _.find(Branch, (branch) => {
      return branch?.id === user?.branchSelect?.id
    })
    setBranchSelect(filterBranch)
    setIsLoading(false)
  }, [Branch, user])

  useEffect(() => {
    const interval = setInterval(() => setTimeNow(Date.now()), 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const userTimeOnWork = async () => {
    setWorkTimeLoading({ ...workTimeLoading, in: true })
    await userOnWork(file).then(() => {
      setWorkTimeLoading({ ...workTimeLoading, in: false })
      setTimeIn(true)
      setTimeOut(false)
      setWorking(false)
      setFile(null)
      setTimeout(() => {
        return navigate('/pos/cashier')
      }, 500)
    })
  }

  const userTimeOffWork = async () => {
    setWorkTimeLoading({ ...workTimeLoading, off: true })
    await userOffWork(fileLogout).then(() => {
      setWorkTimeLoading({ ...workTimeLoading, off: false })
      setTimeIn(false)
      setFileLogout(null)
      setTimeOut(true)
      setWorking(false)
    })
  }

  async function handleUploadChange(event) {
    const ThisFile = await resizeFile(event.target.files[0])
    return setFile(ThisFile)
  }

  async function handleUploadChangeLogout(event) {
    const ThisFile = await resizeFile(event.target.files[0])
    return setFileLogout(ThisFile)
  }

  return (
    <>
      {!isLoading ? (
        working ? (
          <>
            <Center w='100%'>
              <Card w={{ base: '100%', sm: '100%', md: '30rem' }} p='0px'>
                <VStack
                  bg='form.500'
                  borderTopRadius='15px'
                  color='#fff'
                  py='1rem'
                >
                  <Text fontSize='xl'>ลงเวลาเข้างาน</Text>
                  <Text fontSize='lg'>{user?.name}</Text>
                </VStack>
                <Box
                  py='1rem'
                  px={{ base: '0.5rem', sm: '0.5rem', md: '1rem' }}
                >
                  <VStack alignItems='flex-start' spacing='1rem'>
                    <Text px='1rem' color='form.500' fontSize='lg'>
                      ข้อมูลเวลา
                    </Text>
                    <VStack w='100%' alignItems='flex-start'>
                      <HStack fontSize='md' w='100%'>
                        <Center w='4rem' fontSize='xl' textAlign='center'>
                          <MdOutlineDateRange />
                        </Center>
                        <Text w={{ base: '4rem', sm: '4rem', md: '5rem' }}>
                          วันที่ :
                        </Text>
                        <Text w='100%'>
                          {moment(timeNow).format('MM/DD/YYYY')}
                        </Text>
                      </HStack>
                      <HStack fontSize='md' w='100%'>
                        <Center w='4rem' fontSize='xl' textAlign='center'>
                          <BiTimeFive />
                        </Center>
                        <Text w={{ base: '4rem', sm: '4rem', md: '5rem' }}>
                          เวลา :
                        </Text>
                        <Text w='100%'>
                          {moment(timeNow).format('HH:mm:ss')}
                        </Text>
                      </HStack>
                      <HStack fontSize='md' w='100%' alignItems='flex-start'>
                        <Center w='4rem' fontSize='xl' textAlign='center'>
                          <GoLocation />
                        </Center>
                        <Text w={{ base: '4rem', sm: '4rem', md: '5rem' }}>
                          สาขา :
                        </Text>
                        <Text w='100%' textAlign='start'>
                          {branchSelect?.name}
                        </Text>
                      </HStack>
                    </VStack>
                  </VStack>
                  <Box pt='1rem'>
                    <Text
                      py='1rem'
                      px='1rem'
                      fontSize='lg'
                      w='100%'
                      alignItems='flex-start'
                    >
                      ลงเวลาการทำงาน
                    </Text>
                    <Box>
                      <Box
                        pb={{ base: '0px', sm: '0px', md: '10px' }}
                        pl={{ base: '5px', sm: '5px', md: '0px' }}
                        w='100%'
                      >
                        <Input
                          display={'none'}
                          type={'file'}
                          onChange={handleUploadChange}
                          ref={hiddenFileInput}
                          multiple
                          capture
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        pb={{ base: '0px', sm: '0px', md: '10px' }}
                        pl={{ base: '5px', sm: '5px', md: '0px' }}
                        w='100%'
                      >
                        <Input
                          display={'none'}
                          type={'file'}
                          onChange={handleUploadChangeLogout}
                          ref={hiddenFileInputLogout}
                          multiple
                          capture
                        />
                      </Box>
                    </Box>
                    <VStack px={{ base: '0rem', sm: '0rem', md: '1rem' }}>
                      <Button
                        zIndex={{
                          base: 'overlay',
                          sm: 'overlay',
                          md: 'base',
                          lg: 'base',
                        }}
                        w='100%'
                        colorScheme={'btn'}
                        onClick={() => hiddenFileInput.current?.click()}
                        disabled={user?.workRef}
                      >
                        <Text px='0.5rem' fontSize='18px'>
                          {file ? <BsCheck /> : <TbCapture />}
                        </Text>
                        <Text>ถ่ายรูปเข้าระบบ</Text>
                      </Button>

                      <Button
                        fontWeight='normal'
                        w='100%'
                        colorScheme='btn'
                        isLoading={workTimeLoading.in}
                        disabled={workTimeLoading.in || user?.workRef || !file}
                        onClick={() => {
                          userTimeOnWork()
                        }}
                      >
                        ลงเวลาเข้างาน
                      </Button>

                      <Button
                        zIndex={{
                          base: 'overlay',
                          sm: 'overlay',
                          md: 'base',
                          lg: 'base',
                        }}
                        w='100%'
                        colorScheme={'btn'}
                        onClick={() => hiddenFileInputLogout.current?.click()}
                        disabled={workTimeLoading.off || !user?.workRef}
                      >
                        <Text px='0.5rem' fontSize='18px'>
                          {fileLogout ? <BsCheck /> : <TbCapture />}
                        </Text>
                        <Text>ถ่ายรูปออกระบบ</Text>
                      </Button>

                      <Button
                        fontWeight='normal'
                        _hover={{ bg: '#494D51', opacity: '0.7' }}
                        color='white'
                        bg='#494D51'
                        w='100%'
                        isLoading={workTimeLoading.off}
                        disabled={
                          workTimeLoading.off || !user?.workRef || !fileLogout
                        }
                        onClick={() => {
                          userTimeOffWork()
                        }}
                      >
                        ลงเวลาออกงาน
                      </Button>
                    </VStack>
                  </Box>
                </Box>
              </Card>
            </Center>
          </>
        ) : timeIn ? (
          <>
            <TimeInWorking />
          </>
        ) : (
          timeOut && (
            <>
              <TimeOutWorking />
            </>
          )
        )
      ) : (
        <>
          <Center w='100%'>
            <Card w={{ base: '100%', sm: '100%', md: '30rem' }} p='0px'>
              <VStack
                bg='form.500'
                borderTopRadius='15px'
                color='#fff'
                py='1rem'
              >
                <Text fontSize='xl'>ลงเวลาเข้างาน</Text>
                <Text fontSize='lg'>{user?.name}</Text>
              </VStack>
              <Box py='1rem' px={{ base: '0.5rem', sm: '0.5rem', md: '1rem' }}>
                <VStack alignItems='flex-start' spacing='1rem'>
                  <Text px='1rem' color='form.500' fontSize='lg'>
                    ข้อมูลเวลา
                  </Text>
                  <VStack w='100%' alignItems='flex-start'>
                    <HStack fontSize='md' w='100%'>
                      <Center w='4rem' fontSize='xl' textAlign='center'>
                        <MdOutlineDateRange />
                      </Center>
                      <Text w={{ base: '4rem', sm: '4rem', md: '5rem' }}>
                        วันที่ :
                      </Text>
                      <Skeleton w={'full'}>
                        <Text w='100%'>
                          {moment(timeNow).format('MM/DD/YYYY')}
                        </Text>
                      </Skeleton>
                    </HStack>
                    <HStack fontSize='md' w='100%'>
                      <Center w='4rem' fontSize='xl' textAlign='center'>
                        <BiTimeFive />
                      </Center>
                      <Text w={{ base: '4rem', sm: '4rem', md: '5rem' }}>
                        เวลา :
                      </Text>
                      <Skeleton w={'full'}>
                        <Text w='100%'>
                          {moment(timeNow).format('HH:mm:ss')}
                        </Text>
                      </Skeleton>
                    </HStack>
                    <HStack fontSize='md' w='100%' alignItems='flex-start'>
                      <Center w='4rem' fontSize='xl' textAlign='center'>
                        <GoLocation />
                      </Center>
                      <Text w={{ base: '4rem', sm: '4rem', md: '5rem' }}>
                        สาขา :
                      </Text>
                      <Skeleton w={'full'}>
                        <Text w='100%' textAlign='start'>
                          GO GREEN
                        </Text>
                      </Skeleton>
                    </HStack>
                  </VStack>
                </VStack>
                <Box pt='1rem'>
                  <Text
                    py='1rem'
                    px='1rem'
                    fontSize='lg'
                    w='100%'
                    alignItems='flex-start'
                  >
                    ลงเวลาการทำงาน
                  </Text>
                  <VStack px={{ base: '0rem', sm: '0rem', md: '1rem' }}>
                    <Button
                      fontWeight='normal'
                      w='100%'
                      isLoading={isLoading}
                      colorScheme='btn'
                    >
                      ลงเวลาเข้างาน
                    </Button>
                    <Button
                      fontWeight='normal'
                      _hover={{ bg: '#494D51', opacity: '0.7' }}
                      color='white'
                      bg='#494D51'
                      w='100%'
                      isLoading={isLoading}
                    >
                      ลงเวลาออกงาน
                    </Button>
                  </VStack>
                </Box>
              </Box>
            </Card>
          </Center>
        </>
      )}
    </>
  )
}

export default WorkingHours
