const _ = require('underscore')

export function onFilterReportExpensesDiscount(
  data,
  startDate,
  endDate,
  select
) {
  const SD = startDate ? startDate.getTime() : new Date()
  const ED = endDate ? endDate.getTime() : new Date()

  const NextSD = SD + 24 * 60 * 60 * 1000
  const PrevSD = SD - 24 * 60 * 60 * 1000
  const NextED = ED + 24 * 60 * 60 * 1000
  const PrevED = ED - 24 * 60 * 60 * 1000

  if (select) {
    const slDiscount = data.map((item) => ({
      ...item,
      subRows: item.subRows?.filter((item) => item.discountName === select),
    }))
    if (startDate) {
      if (endDate) {
        return slDiscount.map((item) => ({
          ...item,
          grandTotal: _.reduce(
            item.subRows?.filter(
              (sub) =>
                sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextED
            ),
            (v, i) => v + i.grandTotal,
            0
          ),
          subRows: item.subRows?.filter(
            (sub) =>
              sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextED
          ),
        }))
      }
      return slDiscount.map((item) => ({
        ...item,
        grandTotal: _.reduce(
          item.subRows?.filter(
            (sub) =>
              sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextSD
          ),
          (v, i) => v + i.grandTotal,
          0
        ),
        subRows: item.subRows?.filter(
          (sub) => sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextSD
        ),
      }))
    }
    if (endDate) {
      return slDiscount.map((item) => ({
        ...item,
        grandTotal: _.reduce(
          item.subRows?.filter(
            (sub) =>
              sub.date.getTime() >= PrevED && sub.date.getTime() <= NextED
          ),
          (v, i) => v + i.grandTotal,
          0
        ),
        subRows: item.subRows?.filter(
          (sub) => sub.date.getTime() >= PrevED && sub.date.getTime() <= NextED
        ),
      }))
    }
    return slDiscount
  }
  if (startDate) {
    if (endDate) {
      return data.map((item) => ({
        ...item,
        grandTotal: _.reduce(
          item.subRows?.filter(
            (sub) =>
              sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextED
          ),
          (v, i) => v + i.grandTotal,
          0
        ),
        subRows: item.subRows?.filter(
          (sub) => sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextED
        ),
      }))
    }
    return data.map((item) => ({
      ...item,
      grandTotal: _.reduce(
        item.subRows?.filter(
          (sub) => sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextSD
        ),
        (v, i) => v + i.grandTotal,
        0
      ),
      subRows: item.subRows?.filter(
        (sub) => sub.date.getTime() >= PrevSD && sub.date.getTime() <= NextSD
      ),
    }))
  }
  if (endDate) {
    return data.map((item) => ({
      ...item,
      grandTotal: _.reduce(
        item.subRows?.filter(
          (sub) => sub.date.getTime() >= PrevED && sub.date.getTime() <= NextED
        ),
        (v, i) => v + i.grandTotal,
        0
      ),
      subRows: item.subRows?.filter(
        (sub) => sub.date.getTime() >= PrevED && sub.date.getTime() <= NextED
      ),
    }))
  }

  return data
}
